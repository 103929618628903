/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import moment from "moment";
import validator from "validator";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

import { emptyMessage } from "actions";
import { createUser } from "actions/signupAction";
import ReactGA from "react-ga4";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function Cover() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/signup", title: "Signup" });
  }, []);

  const [nama, setNama] = useState("");
  const [telepon, setTelepon] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [agreeTerm, setAgreeTerm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [textDialog, setTextDialog] = useState({ header: "", content: "" });
  const [showError, setShowError] = useState({
    nama: false,
    telepon: false,
    validTelepon: false,
    email: false,
    validEmail: false,
    password: false,
    passwordConfirm: false,
    validPassword: false,
    validPasswordConfirm: false,
    agreeTerm: false,
  });
  const [helperMsg, setHelperMsg] = useState({
    nama: "Nama tidak boleh kosong",
    telepon: "Nomor telepon tidak boleh kosong",
    validTelepon: "Nomor telepon tidak valid",
    email: "Email tidak boleh kosong",
    validEmail: "Email tidak valid",
    password: "Kata sandi tidak boleh kosong",
    passwordConfirm: "Konfirmasi kata sandi tidak boleh kosong",
    validPassword:
      "Kata sandi harus 8 karakter dan setidaknya 1 huruf besar, 1 huruf kecil, dan 1 angka.",
    validPasswordConfirm: "Kata sandi berbeda",
    agreeTerm: "Syarat dan Ketentuan harus disetujui",
  });

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const message = useSelector((state) => {
    return state.message;
  }, shallowEqual);

  useEffect(() => {
    if (nama !== "") {
      setShowError({ ...showError, nama: false });
    }
  }, [nama]);



  useEffect(() => {
    if (telepon !== "") {
      setShowError({ ...showError, telepon: false });
    }
  }, [telepon]);



  useEffect(() => {
    if (email !== "") {
      setShowError({ ...showError, email: false });
    }
  }, [email]);

  useEffect(() => {
    if (password !== "") {
      setShowError({
        ...showError,
        password: false,
        validPassword: !validatePassword(password),
        validPasswordConfirm: !validatePasswordConfirm(passwordConfirm) && passwordConfirm !== "",
      });
    }
  }, [password]);

  useEffect(() => {
    if (passwordConfirm !== "") {
      setShowError({
        ...showError,
        passwordConfirm: false,
        validPasswordConfirm: !validatePasswordConfirm(passwordConfirm),
      });
    }
  }, [passwordConfirm]);

  useEffect(() => {
    if (message.status !== "") {
      dispatch(emptyMessage());
      switch (message.status) {
        case "SIGNUP_SUCCESS":
          setTextDialog({
            header: "Pendaftaran Berhasil",
            content:
              "Proses pendaftaran akun berhasil. Email aktivasi akun telah dikirim ke " +
              email +
              ". Anda dapat login setelah aktivasi akun.",
          });
          setOpenDialog(true);

          return;
        case "SIGNUP_FAIL":
          setTextDialog({
            header: "Pendaftaran Gagal",
            content: "Proses pendaftaran akun gagal. Error: " + message.message,
          });
          setOpenDialog(true);
          return;
        default:
          return;
      }
    }
  }, [message]);

  function validatePassword(pw) {
    return (
      /[A-Z]/.test(pw) &&
      /[a-z]/.test(pw) &&
      /[0-9]/.test(pw) &&
      pw.length >= 8
    );
  }

  function validatePasswordConfirm(pw) {
    return password === pw;
  }

  const handleChangeAgree = (event) => {
    setAgreeTerm(event.target.checked);
    setShowError({ ...showError, agreeTerm: !event.target.checked });
  };

  const handleCloseCancel = () => {
    setOpenDialog(false);
    if (textDialog.header === "Pendaftaran Berhasil") {
      navigate("/signin");
    }
  };

  const onNamaBlur = () => {
    if (nama.trim() === "") {
      setShowError({ ...showError, nama: true });
    }
  };

  const onPasswordBlur = () => {
    if (password === "") {
      setShowError({ ...showError, password: true, validPassword: false });
    } else {
      if (!validatePassword(password)) {
        setShowError({ ...showError, validPassword: true, password: false });
      } else {
        setShowError({
          ...showError,
          validPassword: false,
          password: false,
          validPasswordConfirm: !validatePasswordConfirm(passwordConfirm) && passwordConfirm !== "",
        });
      }
    }


  };

  const onPasswordConfirmBlur = () => {
    if (passwordConfirm === "") {
      setShowError({ ...showError, passwordConfirm: true, validPasswordConfirm: false });
    } else {
      if (!validatePasswordConfirm(passwordConfirm)) {
        setShowError({ ...showError, validPasswordConfirm: true, passwordConfirm: false });
      } else {
        setShowError({ ...showError, validPasswordConfirm: false, passwordConfirm: false });
      }
    }
  };

  const onEmailBlur = () => {
    if (email.trim() === "") {
      setShowError({ ...showError, email: true, validEmail: false });
    } else {
      if (!validator.isEmail(email)) {
        setShowError({ ...showError, validEmail: true, email: false });
      } else {
        setShowError({ ...showError, validEmail: false, email: false });
      }
    }
  };

  const onTelpBlur = () => {
    if (telepon.trim() === "") {
      setShowError({ ...showError, telepon: true, validTelepon: false });
    } else {
      if (!validator.isMobilePhone(telepon)) {
        setShowError({ ...showError, validTelepon: true, telepon: false });
      } else {
        setShowError({ ...showError, validTelepon: false, telepon: false });
      }
    }
  };

  const onAgree = () => {
    if (
      nama.trim() === "" ||
      telepon.trim() === "" ||

      email.trim() === "" ||
      password === "" ||
      passwordConfirm === "" ||
      !agreeTerm ||
      !validator.isEmail(email) ||
      !validator.isMobilePhone(telepon) ||
      !validatePassword(password) ||
      !validatePasswordConfirm(passwordConfirm)
    ) {
      setShowError({
        ...showError,
        nama: nama === "",

        telepon: telepon === "",
        email: email === "",
        password: password === "",
        passwordConfirm: passwordConfirm === "",
        validEmail: email === "" ? false : !validator.isEmail(email),
        validTelepon: telepon === "" ? false : !validator.isMobilePhone(telepon),
        validPassword: password === "" ? false : !validatePassword(password),
        validPasswordConfirm:
          passwordConfirm === "" ? false : !validatePasswordConfirm(passwordConfirm),
        agreeTerm: !agreeTerm,
      });
    } else {
      dispatch(
        createUser({
          name: nama,
          email,
          password,
          phone: telepon,
        })
      );
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  return (
    <BasicLayout>
      <MDBox py={10} textAlign="center" sx={{ overflow: "hidden" }}>
        <MDTypography variant="h4" fontWeight="medium" color="dark" mt={5}>
          Daftar
        </MDTypography>
        <MDTypography display="block" variant="button" color="dark" mt={1} mb={5}>
          Mari bergabung dengan Kentara dan buat bisnis Anda naik kelas!
        </MDTypography>
        <MDBox component="form" role="form" textAlign="left">
          <MDBox mb={2}>
            <MDInput
              id="nama"
              type="text"
              name="nama"
              label="Nama"
              value={nama}
              onBlur={onNamaBlur}
              onChange={(e) => setNama(e.target.value)}
              placeholder="Isi dengan nama anda"
              fullWidth
              error={showError.nama}
            />
            <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
              {showError.nama ? helperMsg.nama : ""}{" "}
            </MDTypography>
          </MDBox>
          
          <MDBox mb={2}>
            <MDInput
              id="no_telp"
              name="no_telp"
              label="Nomor Telepon"
              value={telepon}
              onBlur={onTelpBlur}
              onChange={(e) => setTelepon(e.target.value)}
              placeholder="Isi dengan nomor telepon anda"
              fullWidth
              error={showError.telepon || showError.validTelepon}
            />
            <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
              {showError.telepon ? helperMsg.telepon : ""}{" "}
            </MDTypography>
            <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
              {showError.validTelepon ? helperMsg.validTelepon : ""}{" "}
            </MDTypography>
          </MDBox>
         
          <MDBox mb={2}>
            <MDInput
              id="email"
              name="email"
              label="Email"
              value={email}
              onBlur={onEmailBlur}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Isi dengan email anda"
              fullWidth
              error={showError.email || showError.validEmail}
            />
            <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
              {showError.email ? helperMsg.email : ""}{" "}
            </MDTypography>
            <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
              {showError.validEmail ? helperMsg.validEmail : ""}{" "}
            </MDTypography>
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type={showPassword ? "text" : "password"}
              label="Kata Sandi"
              fullWidth
              value={password}
              onBlur={onPasswordBlur}
              onChange={(e) => setPassword(e.target.value)}
              error={showError.password || showError.validPassword}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
            />
            <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
              {showError.password ? helperMsg.password : ""}{" "}
            </MDTypography>
            <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
              {showError.validPassword ? helperMsg.validPassword : ""}{" "}
            </MDTypography>
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type={showConfirmPassword ? "text" : "password"}
              label="Kata Sandi (konfirmasi)"
              fullWidth
              value={passwordConfirm}
              onBlur={onPasswordConfirmBlur}
              onChange={(e) => setPasswordConfirm(e.target.value)}
              error={showError.passwordConfirm || showError.validPasswordConfirm}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownConfirmPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
            />
            <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
              {showError.passwordConfirm ? helperMsg.passwordConfirm : ""}{" "}
            </MDTypography>
            <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
              {showError.validPasswordConfirm ? helperMsg.validPasswordConfirm : ""}{" "}
            </MDTypography>
          </MDBox>
         
          <MDBox display="flex" alignItems="center" ml={-1}>
            <Checkbox checked={agreeTerm} onChange={handleChangeAgree} />
            <MDTypography
              variant="button"
              fontWeight="regular"
              color="text"
              sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
            >
              &nbsp;&nbsp;Saya setuju dengan&nbsp;
            </MDTypography>
            <MDTypography
              component="a"
              href="#"
              variant="button"
              fontWeight="bold"
              color="dark"
              textGradient
            >
              Syarat dan Ketentuan
            </MDTypography>
          </MDBox>
          <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
            {showError.agreeTerm ? helperMsg.agreeTerm : ""}{" "}
          </MDTypography>
          <MDBox mt={4} mb={1}>
            <MDButton color="info" fullWidth onClick={onAgree}>
              daftar
            </MDButton>
          </MDBox>
          <MDBox mt={3} mb={1} textAlign="center">
            <MDTypography variant="button" color="text">
              Sudah punya akun?{" "}
              <MDTypography
                component={Link}
                to="/signin"
                variant="button"
                color="info"
                fontWeight="medium"
              >
                Masuk
              </MDTypography>
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Dialog
        open={openDialog}
        onClose={handleCloseCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{textDialog.header}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{textDialog.content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCancel}>Ok</Button>
        </DialogActions>
      </Dialog>
    </BasicLayout>
  );
}

export default Cover;
