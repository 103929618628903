import { useState } from "react";
import { useLocation, useParams, useNavigate, Link } from "react-router-dom";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import Radio from "@mui/material/Radio";
import Tooltip from "@mui/material/Tooltip";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import PreviewIcon from "@mui/icons-material/Preview";
import AddIcon from "@mui/icons-material/Add";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

function LVDaftarPendana(props) {
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

  const columns = [
    {
      field: "id",
    },
    {
      field: "no",
      headerName: "No.",
      headerAlign: "left",
      align: "left",
      width: 50,
    },
    {
      field: "name",
      headerName: "Nama Peminjam",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 250,
    },
    {
      field: "type",
      headerName: "Badan Usaha",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
        /* <Link to={"/pengajuan/"+encodeURIComponent(params.row.id)}>
                  <span>{params.value}</span>
              </Link> */
      ),
      width: 180,
    },
    // {
    //   field: "deskripsi",
    //   headerName: "Deskripsi",
    //   headerAlign: "left",
    //   align: "left",
    //   renderCell: (params) => (
    //     <Tooltip title={params.value ? params.value : ""}>
    //       <span>{params.value}</span>
    //     </Tooltip>
    //     /* <Link to={"/pengajuan/"+encodeURIComponent(params.row.id)}>
    //               <span>{params.value}</span>
    //           </Link> */
    //   ),
    //   width: 230,
    // },
    {
      field: "actions",
      type: "actions",
      headerName: "Tindakan",
      sortable: false,
      align: "center",
      width: 100,
      getActions: (params) => {
        // if (params.row.status === "Belum Mendaftar" || params.row.status === "Ditolak") {
        //   return [
        //     <GridActionsCellItem
        //       icon={
        //         <Tooltip title="Daftar">
        //           <AddIcon />
        //         </Tooltip>
        //       }
        //       label="Setujui"
        //       // s howInMenu
        //       onClick={() => props.onRegister(params.row.id, params.row.name)}
        //     />,
        //   ];
        // }
        if (params.row.status === "Belum Mendaftar" || params.row.status === "Ditolak") {
          return [
            <MDButton
              sx={{ color: "#49a3f1", fontSize: "12px", fontWeight: "bold" }}
              onClick={() => props.onRegister(params.row.id, params.row.name)}
            >
              Daftar
            </MDButton>,
          ];
        } else {
          return [];
        }
      },
    },
  ];

  return (
    <MDBox
      mt={5}
      sx={{
        height: 400,
        width: "100%",
      }}
    >
      <DataGrid
        columns={columns}
        rows={props.rows}
        pageSize={5}
        rowsPerPageOptions={[5]}
        localeText={{ noRowsLabel: "Belum ada daftar pendana." }}
        // checkboxSelection
        disableSelectionOnClick
        sx={{
          bgcolor: "rgba(255, 255, 255, 1)",
          borderRadius: "16px",
          fontSize: "small",
          boxShadow: "10px 15px 27px -28px #1A73E8",
        }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => {
          newModel.id = false;
          // this.setState({ columnVisibilityModel: newModel });
          setColumnVisibilityModel(newModel);
        }}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </MDBox>
  );
}

export default LVDaftarPendana;
