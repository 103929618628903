/* eslint-disable arrow-body-style */
import { useEffect, useState, useRef } from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import LVPenggunaDataGrid from "components/LVPenggunaDataGrid";
import { fetchUserList } from "actions/userListAction";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from "@mui/material/Grid";
import MDInput from "components/MDInput";

import { transformUserList } from "common/Util";

function LVPengguna(props) {
  const dispatch = useDispatch();
  const listUser = useSelector((state) => {
    return state.listUser;
  }, shallowEqual);
  const listUserPagination = useSelector((state) => {
    return state.listUserPagination;
  }, shallowEqual);

  const [data, setDataUse] = useState(listUser);
  const [type, setType] = useState("broker");
  const [loading, setLoading] = useState(false);
  const [keywordEntry, setKeywordEntry] = useState("");

  const [queryParameter, setQueryParameter] = useState({
    type, page: 1, orderBy: "id", order: "desc", name: "", limit: 10
  })
  const timeoutRef = useRef(null);

  const previousKeyword = useRef(null);
  const message = useSelector((state) => state.message, shallowEqual);

  useEffect(() => {
    if (message.status !== "") {
      switch (message.status) {
        case "ERROR_FETCH_USERS":
          loadingCloseHandler();
        default:
      }
    }
  }, [message]);

  const handleKeywordChange = (event) => {
    const newKeyword = event.target.value;
    setKeywordEntry(newKeyword);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      if (newKeyword != previousKeyword.current) {
        setQueryParameter({ ...queryParameter, name: newKeyword, page: 1 });
      }
      else {
        setQueryParameter({ ...queryParameter, name: newKeyword });
      }
      previousKeyword.current = newKeyword;
    }, 500);
  }

  const resetFilter = () => {
    setQueryParameter({
      ...queryParameter,
      type, page: 1, orderBy: "id", order: "desc", name: "", limit: 10
    })
  }

  const loadingCloseHandler = () => {
    setLoading(false);
  }

  useEffect(() => {
    if (listUserPagination === "") {
      return;
    }
    listUserPagination.limit = parseInt(queryParameter.limit);
    try {
      let data = transformUserList(listUser, listUserPagination);
      setDataUse(data);
    } catch (e) {
      setLoading(false);
    }
    setLoading(false);

  }, [listUser, listUserPagination]);

  useEffect(() => {
    loadingCloseHandler();
  }, [data])

  useEffect(() => {
    resetFilter();
    setQueryParameter({ ...queryParameter, type: type });
  }, [type]);

  useEffect(() => {
    setLoading(true);
    if (type === "broker") {
      dispatch(fetchUserList(queryParameter));
    }
    if (type === "vendor") {
      dispatch(fetchUserList(queryParameter));
    }
    if (type === "fintech") {
      dispatch(fetchUserList(queryParameter));
    }
  }, [queryParameter]);


  const menuMap = {
    "broker": {
      title: "Kontraktor / Broker"
    },
    "vendor": {
      title: "Vendor"
    },
    "fintech": {
      title: "Fintech / Lender"
    }
  }


  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={loadingCloseHandler}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox
        my={2}
        sx={{
          width: "100%",
        }}
      >
        <Grid container alignItems="center" spacing={1} mb={2}>
          <Grid item xs="auto" mr={4}>
            <MDInput
              value={keywordEntry}
              onChange={handleKeywordChange}
              label="Cari nama pengguna..."
              sx={{ width: "300px", borderRadius: "16px" }} />
          </Grid>

          {
            Object.entries(menuMap).map(([key, params]) => (
              <Grid item xs="auto">
                <MDBox onClick={() => setType(key)} sx={{ cursor: "pointer" }}>
                  <MDTypography
                    variant="body3"
                    px={4}
                    py={2}
                    sx={{
                      mb: 2,
                      bgcolor: type === key ? "rgba(255, 255, 255, 1)" : "rgb(238, 238, 238)",
                      borderRadius: "16px",
                      border: "1px solid #e1e1e1",
                      fontWeight: "bold",
                    }}
                  >
                    {params.title}
                  </MDTypography>
                </MDBox>
              </Grid>
            ))
          }
        </Grid>
        <LVPenggunaDataGrid
          firstPage={() => setQueryParameter({ ...queryParameter, page: 1 })}
          lastPage={() => setQueryParameter({ ...queryParameter, page: listUserPagination.total_pages })}
          totalPage={listUserPagination.total_pages}
          currentPage={queryParameter.page}
          selectPage={(i) => setQueryParameter({ ...queryParameter, page: parseInt(i.target.textContent) })}
          nextPage={() => setQueryParameter({ ...queryParameter, page: listUserPagination.next_page })}
          prevPage={() => setQueryParameter({ ...queryParameter, page: listUserPagination.prev_page })}
          order={(orderBy, order) => setQueryParameter({ ...queryParameter, orderBy, order })}
          rows={data}

          type={type}
          name={(name) => setQueryParameter({ ...queryParameter, name })}
          resetFilter={(e) => resetFilter(e)}
        />
      </MDBox>
    </div>
  );
}

export default LVPengguna;
