import React, { Component, useState, useEffect, useRef  } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded';
import Radio from '@mui/material/Radio';
import Tooltip from '@mui/material/Tooltip';
import MDBox from "components/MDBox";

const LVPelunasanInvoiceDataGrid = (props) => {
    const [columnVisibilityModel, setColumnVisibilityModel] =useState({
        id: false,            
      });

    
    const columns = [
        { 
            field: 'id', 
            headerName: ' ',
            width: 40,            
        },
        { 
          field: 'no', 
          headerName: 'No.', 
          headerAlign: 'center',
          align:"center",
          width: 50,
        },
        { 
            field: 'request_number', 
            headerName: 'No. Pengajuan', 
            headerAlign: 'center',
            align:"left",            
            width: 150,
        },
        { 
            field: 'invoice_number', 
            headerName: 'No. Invoice', 
            headerAlign: 'center',
            align:"left",            
            width: 150,
        },
        { 
            field: 'due_date', 
            headerName: 'Tanggal Jatuh Tempo', 
            headerAlign: 'center',
            align:"left",            
            width: 150,
        },        
    ];

    const rows = [
        {id:1, no: '1', request_number:'SO1001',  invoice_number:'INV1001', due_date: '01-Januari-2023' },
        {id:2, no: '2', request_number:'SO1002',  invoice_number:'INV1002', due_date: '01-Januari-2023'},
        {id:3, no: '3', request_number:'SO1003',  invoice_number:'INV1003', due_date: '01-Januari-2023' },
    ]

    const newdata = {
      columns,
      rows,  
    }

    const renderSX = () => {
        if (props.flat) {
          return {
            bgcolor: 'rgba(255, 255, 255, 1)', 
            borderRadius: '16px', 
            fontSize:'small', 
            //boxShadow: '10px 15px 27px -28px #1A73E8',
          }
        } else {
          return {
            bgcolor: 'rgba(255, 255, 255, 1)', 
            borderRadius: '16px', 
            fontSize:'small', 
            boxShadow: '10px 15px 27px -28px #1A73E8',
          }
        }
    }

    return (
        <MDBox 
            //mt={3}  
            sx={{ 
                height: props.height, 
                width: '100%',          
            }} 
        
        >
            <DataGrid
                {...newdata}
                pageSize={props.pageSize}
                rowsPerPageOptions={props.rowsPerPageOptions}
                editMode="row"
                checkboxSelection={props.checkboxSelection}
                disableSelectionOnClick
                autoHeight={props.autoHeight}
                sx={renderSX()}                                
                columnVisibilityModel={columnVisibilityModel}
                //isRowSelectable={(params)=>params.row.statusInvoice==='unpaid'}
                onColumnVisibilityModelChange={(newModel) => {
                    newModel.id = false;
                    //this.setState({ columnVisibilityModel: newModel });
                    setColumnVisibilityModel(newModel);
                  }                     
                }
                experimentalFeatures={{ newEditingApi: true }}              
            />
        </MDBox>
    );
}

export default LVPelunasanInvoiceDataGrid;