import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Grid from "@mui/material/Grid";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';

import validator from 'validator';

import { parseJwt } from '../../common/Util';

import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";


import { updateStatus, sendEmailInvoice, getInvoice } from '../../actions/detailInvoiceAction';
import { getCompany, getCustomerCompany, initCustomerCompany } from "actions/companyAction";
import { emptyMessage, fetchInvoices } from '../../actions/'
import { generateVA } from '../../actions/paymentAction';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 550,
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    overflow: "auto",
    p: 4,
};

const SendEmail = (props) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const [emailDari, setEmailDari] = useState('invoice@manajemeninvoice.com');
    const [emailKe, setEmailKe] = useState('');
    const [emailCC, setEmailCC] = useState('');
    const [company, setCompany] = useState('');

    const dispatch = useDispatch('');

    const anCompany = useSelector(
        state => {
            return state.anCompany;
        },
        shallowEqual
    );

    const anCustomerCompany = useSelector(
        state => {
            return state.anCustomerCompany;
        },
        shallowEqual
    );

    const message = useSelector(
        state => {
            return state.message;
        },
        shallowEqual
    );

    const virtualAccount = useSelector(
        state => {
            return state.virtualAccount;
        },
        shallowEqual
    );

    useEffect(() => {
        dispatch(getCompany());
        dispatch(getCustomerCompany(props.customercompanyid));
        dispatch(generateVA(Number(props.customercompanyid)));
    }, []);

    useEffect(() => {

        if (anCompany.type && anCompany.name) {
            setCompany(anCompany.type + " " + anCompany.name);
           
        }
    }, [anCompany]);

    useEffect(() => {
        if (Array.isArray(anCustomerCompany.pic)) {
            var financeFound = false;
            anCustomerCompany.pic.map((item) => {
                if (item.type.toLowerCase() === 'finance') {
                    setEmailKe(item.email);
                    financeFound = true;
                }
            });
            if (!financeFound) {
                setEmailKe(anCustomerCompany.email);
            }
        }
    }, [anCustomerCompany]);

    useEffect(() => {
        if (message.status !== '') {
            dispatch(emptyMessage());
            switch (message.status) {
                case 'SEND_EMAIL_SUCCESS':
                    dispatch(getInvoice({ number: props.invoicenumber }));
                    dispatch(fetchInvoices());
                    props.onClose();
                    return;
                default:
                    return;
            }
        }

    }, [message]);

    const onKirim = () => {
        if ((validator.isEmail(emailDari) && (emailDari !== '')) && (validator.isEmail(emailKe) && (emailKe !== '')) && (validator.isEmail(emailCC) || (emailCC === ''))) {
            dispatch(sendEmailInvoice({
                invoice_id: [props.invoiceid],
                recipient: [emailKe]
            }));
        } else {
        }
    }
    return (
        <div>
            <MDBox sx={style}>
                <Grid container direction="column" justifyContent="space-around">
                    <Grid item>
                        <MDTypography variant="title" fontWeight="medium">
                            Kirim Invoice
                        </MDTypography>
                    </Grid>
                    <Grid item mt={3}>
                        <Grid container justifyContent="flex-start" alignItems="center">
                            <Grid item xs={2}>
                                <MDTypography variant="caption" fontWeight="medium">
                                    Dari
                                </MDTypography>
                            </Grid>
                            <Grid item xs={1}>
                                <MDTypography variant="caption" fontWeight="medium">
                                    :
                                </MDTypography>
                            </Grid>
                            <Grid item xs={9}>
                                <MDInput
                                    margin="dense"
                                    fullWidth
                                    id="emailDari"
                                    name="emailDari"
                                    type="email"
                                    value={emailDari}
                                    disabled
                                    onChange={(e) => setEmailDari(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="flex-start" alignItems="center">
                            <Grid item xs={2}>
                                <MDTypography variant="caption" fontWeight="medium">
                                    Kirim Ke
                                </MDTypography>
                            </Grid>
                            <Grid item xs={1}>
                                <MDTypography variant="caption" fontWeight="medium">
                                    :
                                </MDTypography>
                            </Grid>
                            <Grid item xs={9}>
                                <MDInput
                                    margin="dense"
                                    fullWidth
                                    id="emailKe"
                                    name="emailKe"
                                    type="email"
                                    value={emailKe}
                                    disabled
                                    onChange={(e) => setEmailKe(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="flex-start" alignItems="center">
                            <Grid item xs={2}>
                                <MDTypography variant="caption" fontWeight="medium">
                                    CC
                                </MDTypography>
                            </Grid>
                            <Grid item xs={1}>
                                <MDTypography variant="caption" fontWeight="medium">
                                    :
                                </MDTypography>
                            </Grid>
                            <Grid item xs={9}>
                                <MDInput
                                    margin="dense"
                                    fullWidth
                                    id="emailCC"
                                    name="emailCC"
                                    type="email"
                                    value={emailCC}
                                    disabled
                                    onChange={(e) => setEmailCC(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Paper elevation={4} p={3} px={3} mt={3}>

                            <MDTypography mx={2} mt={2} variant="caption">
                                Hi,
                            </MDTypography>
                            <MDTypography mx={2} variant="caption" paragraph>
                                Berikut ini kami lampirkan invoice {props.invoicenumber} atas nama {company} dengan jumlah {props.invoiceamount}.
                            </MDTypography>
                            <MDTypography mx={2} variant="caption" paragraph>
                                Mohon konfirmasi dengan klik Link ini jika invoice dan lampiran sudah sesuai.
                            </MDTypography>
                            <MDTypography mx={2} variant="caption" paragraph>
                                Terima kasih atas kepercayaan Anda kepada kami.
                            </MDTypography>
                            <br />
                        </Paper>
                    </Grid>
                    <Grid item>
                        <Grid container justifyContent="flex-end" alignItems="right" spacing={1} px={3} mt={1}>
                            <Grid item>
                                <MDButton variant="contained" color="info" mt={2} size="small" onClick={onKirim}>
                                    Kirim
                                </MDButton>
                            </Grid>
                            <Grid item>
                                <MDButton variant="contained" color="error" mt={2} size="small" onClick={props.onClose}>
                                    Batal
                                </MDButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MDBox>
        </div>
    );
}

export default SendEmail;