import MDTypography from "components/MDTypography";

import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import LVInvoiceDueSummary from "components/lv-invoice-due-summary";
import LVInvoiceDueBreakdownSummary from "components/lv-invoice-due-breakdown-summary";

function LVInvoiceDueSummaryCompound(props) {
  return (
    <MDBox bgColor="white" p={0.5} shadow="md" borderRadius="xl">
      <Grid container spacing={0}>
        <Grid item xs={12} p="1rem">
          <MDTypography sx={{ fontSize: "16px", fontWeight: "bold" }}>{props.title}</MDTypography>
        </Grid>
      </Grid>
      <LVInvoiceDueSummary
        data={props.data}></LVInvoiceDueSummary>
      <LVInvoiceDueBreakdownSummary
        data={props.rowData}></LVInvoiceDueBreakdownSummary>
    </MDBox>
  )
}

export default LVInvoiceDueSummaryCompound;
