export const searchItem = (object, search) => {
    let result = []
    Object.keys(object).forEach(key => {
        if (typeof object[key] === "object") {
            let searchResult = searchItem(object[key], search);
            if (searchResult.length > 0) {
                result.push(key);
            }
        }
        if (typeof object[key] === "string") {
            let searchAsRegEx = new RegExp(search, "gi");
            if (object[key].match(searchAsRegEx)) {
                result.push(key)
            }
        }
    })
    return result;
}

export const snakeCaseToSentenceCase = (snakeCaseString) => {
    if (typeof snakeCaseString !== 'string') {
        return '';
    }

    const words = snakeCaseString.toLowerCase().split('_');
    if (words.length === 0) {
        return "";
    }

    const capitalizedFirstWord = words[0].charAt(0).toUpperCase() + words[0].slice(1);
    const restOfWords = words.slice(1).join(' ');

    if (restOfWords.length > 0) {
        return capitalizedFirstWord + ' ' + restOfWords;
    } else {
        return capitalizedFirstWord;
    }
}