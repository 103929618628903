import { useState } from "react";
import { useLocation, useParams, useNavigate, Link } from "react-router-dom";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import Radio from "@mui/material/Radio";
import Tooltip from "@mui/material/Tooltip";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import PreviewIcon from "@mui/icons-material/Preview";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import RiwayatModal from "modals/viewRiwayatModal";

function LVPelanggan(props) {
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
  const [id, setId] = useState(false);
  const [name, setName] = useState(false);
  const [npwp, setNPWP] = useState(false);
  const [riwayatModal, setRiwayatModal] = useState(false);
  const handleOpen = (e) => {
    setId(e.id);
    setName(e.label);
    setNPWP(e.npwp);
    setRiwayatModal(true);
  };

  const columns = [
    {
      field: "id",
    },
    {
      field: "no",
      headerName: "No.",
      headerAlign: "center",
      align: "center",
      width: 80,
      renderCell: (params) => <span>{params.api.getRowIndex(params.row.id) + 1}</span>,
    },
    {
      field: "npwp",
      headerName: "NPWP",
      headerAlign: "center",
      align: "left",
      width: 200,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "label",
      headerName: "Nama Pelanggan",
      headerAlign: "center",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 200,
    },
    {
      field: "type",
      headerName: "Badan Usaha",
      headerAlign: "center",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 120,
    },
    {
      field: "pic_name",
      headerName: "NAMA PIC",
      headerAlign: "center",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 200,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Aksi",
      sortable: false,
      align: "center",
      width: 180,
      getActions: (params) => [
        <MDBox>
          <MDButton
            onClick={() => handleOpen(params.row)}
            sx={{ color: "#0D6ECD", fontSize: "12px", fontWeight: "bold" }}
            size="small"
          >
            Riwayat Kerjasama
          </MDButton>
        </MDBox>,
      ],
    },
  ];

  return (
    <>
      <MDBox
        mt={2}
        sx={{
          height: 750,
          width: "100%",
        }}
      >
        <DataGrid
          columns={columns}
          rows={props.rows}
          pageSize={12}
          rowsPerPageOptions={[12]}
          disableSelectionOnClick
          localeText={{ noRowsLabel: "Belum ada pelanggan." }}
          sx={{
            bgcolor: "rgba(255, 255, 255, 1)",
            borderRadius: "16px",
            fontSize: "small",
            boxShadow: "10px 15px 27px -28px #1A73E8",
          }}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) => {
            newModel.id = false;
            setColumnVisibilityModel(newModel);
          }}
          experimentalFeatures={{ newEditingApi: true }}
        />
      </MDBox>
      <RiwayatModal
        open={riwayatModal === true}
        admin={false}
        id={id}
        npwp={npwp}
        name={name}
        onClose={() => setRiwayatModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      />
    </>
  );
}

export default LVPelanggan;
