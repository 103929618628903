export default (state=[],action) => {
    switch (action.type) {
       case 'FETCH_PENGAJUAN':
         return action.payload;
      case 'INIT_PENGAJUAN':
         return state=[];
       default:
         return state;
     }

};