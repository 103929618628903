
import Modal from '@mui/material/Modal';
import PelunasanInvoice from '../layouts/pelunasanInvoice';



const PelunasanInvoiceModal = (props) => {

    return(
        <Modal
            {...props}
        >
            <div>
                <PelunasanInvoice onClose={props.onClose} datapengajuan={props.datapengajuan}/>
            </div>
        </Modal>
    );
}

export default PelunasanInvoiceModal;