import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";

function LVSettlementTotalInformation(props) {

    return (
        <Grid
            container
            direction="row"
            justifyContent="start"
            spacing={3}
            mt={1}
            ml={0}
        >
            <Grid
                width="100%"
                container
                direction="row"
                justifyContent="space-around"
                spacing={2}
                mt={1}
            >
                <Grid item width="750px">
                    <MDBox
                        justifyContent="space-between"
                        display="flex"
                        mb={3}
                        py={1}
                        px={4}
                        sx={{ borderRadius: "8px", border: "1px solid #e1e1e1" }}
                    >
                        <MDTypography fontSize="small">Dana Teralokasi / Total Dana</MDTypography>
                        <MDTypography fontSize="small" fontWeight="normal">
                            {Number(props.totalInfo).toLocaleString("id-ID", {
                                style: "currency",
                                currency: "IDR",
                            })} / {Number(props.amount).toLocaleString("id-ID", {
                                style: "currency",
                                currency: "IDR",
                            })}
                        </MDTypography>
                    </MDBox>
                </Grid>
            </Grid >
        </Grid >
    );
}

export default LVSettlementTotalInformation;
