// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `iframe#webpack-dev-server-client-overlay {
    display: none;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #e1e1e1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #49a3f1;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #1A73E8;
}

/* maintain autocomplete height */

.MuiAutocomplete-inputRoot {
    padding: 4px;
}

.MuiAutocomplete-input {
    padding: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/css/app.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA,UAAU;AACV;IACI,UAAU;IACV,WAAW;AACf;;AAEA,UAAU;AACV;IACI,mBAAmB;AACvB;;AAEA,WAAW;AACX;IACI,mBAAmB;AACvB;;AAEA,oBAAoB;AACpB;IACI,mBAAmB;AACvB;;AAEA,iCAAiC;;AAEjC;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB","sourcesContent":["iframe#webpack-dev-server-client-overlay {\n    display: none;\n}\n\n/* width */\n::-webkit-scrollbar {\n    width: 8px;\n    height: 8px;\n}\n\n/* Track */\n::-webkit-scrollbar-track {\n    background: #e1e1e1;\n}\n\n/* Handle */\n::-webkit-scrollbar-thumb {\n    background: #49a3f1;\n}\n\n/* Handle on hover */\n::-webkit-scrollbar-thumb:hover {\n    background: #1A73E8;\n}\n\n/* maintain autocomplete height */\n\n.MuiAutocomplete-inputRoot {\n    padding: 4px;\n}\n\n.MuiAutocomplete-input {\n    padding: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
