import axios from "axios";
import serverList from "../apis/serverList";
import authHeader from "../apis/authHeader";

export const postNewInvoice = (DetailInvoice) => async (dispatch) => {
  const invoiceAPI = axios.create({
    baseURL: serverList.invoice,
    headers: authHeader(),
  });

  const message = await invoiceAPI.post("/api/v1/invoice", DetailInvoice).catch((e) => {
    dispatch({
      type: "ERROR",
      payload: { status: "FAIL_NEW_INVOICE", message: e.response?.data?.message },
    });
  });

  if (message !== undefined) {
    if (message.status === 200) {
      if (message.data.status === "OK") {
        dispatch({
          type: "CREATE_NEW_INVOICE",
          payload: { status: "SUCCESS_NEW_INVOICE", message: DetailInvoice.header.number },
        });
      } else {
        dispatch({
          type: "ERROR",
          payload: { status: "FAIL_NEW_INVOICE", message: message.data.message },
        });
      }
    } else if (message.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: message.data.message },
      });
    } else {
      dispatch({
        type: "ERROR",
        payload: { status: "FAIL_NEW_INVOICE", message: message.data.message },
      });
    }
  }
};

export const putEditInvoice = (DetailInvoice) => async (dispatch) => {
  const invoiceAPI = axios.create({
    baseURL: serverList.invoice,
    headers: authHeader(),
  });

  const message = await invoiceAPI.put("/api/v1/invoice", DetailInvoice).catch((e) => {});

  if (message !== undefined) {
    if (message.status === 200) {
      if (message.data.status === "OK") {
        dispatch({
          type: "UPDATE_INVOICE",
          payload: { status: "SUCCESS_UPDATE_INVOICE", message: DetailInvoice.header.number },
        });
      } else {
        dispatch({
          type: "ERROR",
          payload: { status: "FAIL_NEW_INVOICE", message: message.data.message },
        });
      }
    } else if (message.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: "TOKEN_EXPIRE" },
      });
    }
  }
};
