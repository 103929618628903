import axios from 'axios';

const user = JSON.parse(localStorage.getItem('user'));

var authHeader = {};

if (user && user.access_token) {
  authHeader =   
  {
    Authorization: 'Bearer ' +  user.access_token
  }
}

const invoiceAPI = axios.create({
  baseURL: 'https://invoice.dev.kentara.io',
  headers:  authHeader
});

export default invoiceAPI;