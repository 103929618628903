import Modal from '@mui/material/Modal';
import NewVendor from '../layouts/newVendor';

const VendorModal = (props) => {

    return (
        <Modal
            {...props}
        >
            <div>
                <NewVendor onClose={props.onClose} mainPage={props.mainPage} />
            </div>
        </Modal>
    );
}

export default VendorModal;