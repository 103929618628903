import { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SimpleDashboardNavbar from "examples/Navbars/SimpleDashboardNavbar";

import LVSettlementRequest from "components/LVSettlementRequest";
import { paymentSettlementRequestList } from "actions/paymentAction";
import MDTypography from "components/MDTypography";
import LVSettlementRequestNonFinancing from "components/LVSettlementRequestNonFinancing";
import { paymentSettlementRequestNonFinancingList } from "actions/paymentAction";

function SettlementRequest() {
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [type, setType] = useState("financing");

  const [keywordTimeout, setKeywordTimeout] = useState();

  const anAdminSettlementRequest = useSelector(
    (state) => state.anAdminSettlementRequest,
    shallowEqual
  );

  const anAdminSettlementRequestPagination = useSelector(
    (state) => state.anAdminSettlementRequestPagination,
    shallowEqual
  );

  const anAdminSettlementRequestNonFinancing = useSelector(
    (state) => state.anAdminSettlementRequestNonFinancing,
    shallowEqual
  );

  const anAdminSettlementRequestPaginationNonFinancing = useSelector(
    (state) => state.anAdminSettlementRequestNonFinancingPagination,
    shallowEqual
  );

  const dispatch = useDispatch();

  const onChangeKeywordHandler = (e) => {
    const keyword = e.target.value;
    if (keyword.length >= 3) {
      clearTimeout(keywordTimeout);
      setKeywordTimeout(setTimeout(() => setKeyword(keyword), 500));
    }
  };

  const onKeyDownHandler = (e) => {
    if (e.keyCode == 13) {
      const keyword = e.target.value;
      setKeyword(keyword);
    }
  };

  useEffect(() => {
    setPage(1);
  }, [type]);

  useEffect(() => {
    if (type === "financing") {
      dispatch(paymentSettlementRequestList({ page, limit, keyword }));
    } else {
      dispatch(paymentSettlementRequestNonFinancingList({ page, limit, keyword }));
    }
  }, [page, limit, keyword, type]);

  return (
    <DashboardLayout>
      <SimpleDashboardNavbar name="Settlement Request" />
      <Grid container pt={3} justifyContent="space-between">
        <Grid item />
        <Grid item width="90%">
          <Grid container justifyContent="flex-end">
            <Grid item>
              <MDBox pr={2} my="auto">
                <MDInput
                  label="Cari settlement request..."
                  sx={{ width: "300px" }}
                  onChange={(e) => onChangeKeywordHandler(e)}
                  onKeyDown={(e) => onKeyDownHandler(e)}
                />
              </MDBox>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <MDBox
        mt={3}
        sx={{
          width: "100%",
          position: "relative",
        }}
      >
        <MDBox
          sx={{
            display: "flex",
            py: 2.5,
            px: 2,
            mb: 2,
            bgcolor: "rgba(255, 255, 255, 1)",
            borderRadius: "16px",
            border: "1px solid #e1e1e1",
            fontSize: "small",
            boxShadow: "10px 15px 27px -28px #1A73E8",
          }}
        >
          <MDBox onClick={() => setType("financing")} sx={{ cursor: "pointer" }}>
            <MDTypography
              variant="body3"
              sx={{
                mr: 4,
                pb: 0.5,
                fontWeight: "bold",
                borderBottom: type === "financing" ? "3px solid #0D6ECD" : "3px solid #ffffff",
              }}
            >
              Financing
            </MDTypography>
          </MDBox>
          <MDBox onClick={() => setType("non_financing")} sx={{ cursor: "pointer" }}>
            <MDTypography
              variant="body3"
              sx={{
                mr: 4,
                pb: 0.5,
                fontWeight: "bold",
                borderBottom: type === "non_financing" ? "3px solid #0D6ECD" : "3px solid #ffffff",
              }}
            >
              Non Financing
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      {type === "financing" ? (
        <LVSettlementRequest
          firstPage={() => setPage(1)}
          lastPage={() => setPage(anAdminSettlementRequestPagination.total_pages)}
          totalPage={anAdminSettlementRequestPagination.total_pages}
          currentPage={page}
          selectPage={(i) => setPage(parseInt(i.target.textContent))}
          nextPage={() => setPage(anAdminSettlementRequestPagination.next_page)}
          prevPage={() => setPage(anAdminSettlementRequestPagination.prev_page)}
          rows={anAdminSettlementRequest}
        />
      ) : (
        <LVSettlementRequestNonFinancing
          firstPage={() => setPage(1)}
          lastPage={() => setPage(anAdminSettlementRequestPaginationNonFinancing.total_pages)}
          totalPage={anAdminSettlementRequestPaginationNonFinancing.total_pages}
          currentPage={page}
          selectPage={(i) => setPage(parseInt(i.target.textContent))}
          nextPage={() => setPage(anAdminSettlementRequestPaginationNonFinancing.next_page)}
          prevPage={() => setPage(anAdminSettlementRequestPaginationNonFinancing.prev_page)}
          rows={anAdminSettlementRequestNonFinancing}
        />
      )}
    </DashboardLayout>
  );
}

export default SettlementRequest;
