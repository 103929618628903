import axios from "axios";
import { useNavigate } from "react-router-dom";
import authAPI from "../apis/authentication";
import serverList from "../apis/serverList";
import accessFeature from "../access";
import authHeader from "apis/authHeader";

export const signIn =
  ({ username, password }) =>
  async (dispatch) => {
    const tempForm = new FormData();
    tempForm.append("grant_type", "password");
    tempForm.append("username", username);
    tempForm.append("password", password);
    const response = await authAPI.post("/api/v1/oauth/token", tempForm).catch((e) => {
      if (e.response.status === 400) {
        if (e.response.data.message === "NO_COMPANY_REGISTRATION") {
          dispatch({
            type: "COMPANY_REGISTRATION",
            payload: { status: "COMPANY_REGISTRATION", message: e.response.data.data },
          });
        } else {
          dispatch({
            type: "ERROR",
            payload: { status: "LOGIN_FAILED", message: e.response.data.message },
          });
        }
      }
    });
    if (response !== undefined) {
      if (response.status === 200) {
        const tmpAuthHeader = { Authorization: `Bearer ${response.data.access_token}` };
        let tmpCompanyInfo = null;
        const companyAPI = axios.create({
          baseURL: serverList.company,
          headers: tmpAuthHeader,
        });
        const response2 = await companyAPI.get("/api/v1/company");
        if (response2) {
          if (response2.data.status === "OK") {
            tmpCompanyInfo = response2.data.data;
          }
        }
        const user = response.data;
        user.company = tmpCompanyInfo;

        const accessList = [];
        user.role?.forEach((element) => {
          accessList.push.apply(
            accessList,
            accessFeature[`${tmpCompanyInfo?.business_type}_${element}`]
          );
        });
        user.access_list = accessList;
        localStorage.setItem("user", JSON.stringify(user));
        dispatch({ type: "SIGN_IN", payload: JSON.stringify(user) });
        localStorage.removeItem("username");
        localStorage.removeItem("password");
      } else if (response.status === 400) {
        dispatch({
          type: "ERROR",
          payload: { status: "LOGIN_FAILED", message: response.data.message },
        });
      }
    }
  };

export const refreshToken = (refreshToken) => async (dispatch) => {
  let params = {
    refresh_token: refreshToken,
    grant_type: "refresh_token",
  };

  const response = await authAPI.post("/api/v1/token", params).catch((e) => {
    dispatch({
      type: "ERROR_REFRESH_TOKEN",
      payload: { status: "ERROR_REFRESH_TOKEN", message: "ERROR" },
    });
  });
  if (response !== undefined) {
    if (response.data.status === "OK") {
      let user = JSON.parse(localStorage.getItem("user"));
      user.access_token = response.data.data.access_token;
      user.expired_at = response.data.data.expired_at;
      localStorage.setItem("user", JSON.stringify(user));

      dispatch({ type: "SIGN_IN", payload: JSON.stringify(user) });
      dispatch({
        type: "SUCCESS_REFRESH_TOKEN",
        payload: { status: "SUCCESS_REFRESH_TOKEN", message: "SUCCESS" },
      });
    } 
  }
};

export const signInOdoo =
  ({ username, password, redirect_uri, scope, response_type, state, client_id }) =>
  async (dispatch) => {
    const response = await authAPI
      .post("/api/v1/oauth/client/login", {
        username,
        password,
        redirect_uri,
        scope,
        response_type,
        state,
        client_id,
      })
      .catch((e) => {});

    if (response.status === 200) {
      const tmpAuthHeader = { Authorization: `Bearer ${response.data.data.access_token}` };
      let tmpCompanyInfo = null;
      const companyAPI = axios.create({
        baseURL: serverList.company,
        headers: tmpAuthHeader,
      });
      const response2 = await companyAPI.get("/api/v1/company");
      if (response2) {
        if (response2.data.status === "OK") {
          tmpCompanyInfo = response2.data.data;
        }
      }
      const user = response.data.data;
      user.company = tmpCompanyInfo;

      const accessList = [];
      user.role?.forEach((element) => {
        accessList.push.apply(
          accessList,
          accessFeature[`${tmpCompanyInfo?.business_type}_${element}`]
        );
      });
      user.access_list = accessList;

      localStorage.setItem("user", JSON.stringify(user));
      dispatch({ type: "SIGN_IN", payload: JSON.stringify(user) });
    }
  };

export const forgetPassToken =
  ({ email }) =>
  async (dispatch) => {
    const response = await authAPI.post("/api/v1/password/forget", { email });
    if (response.data.status === "OK") {
      dispatch({
        type: "FORGET_SUCCESS",
        payload: { status: "FORGET_SUCCESS", message: response.data.data.message },
      });
    } else {
      dispatch({
        type: "FORGET_FAILED",
        payload: { status: "FORGET_FAILED", message: response.data.message },
      });
    }
  };

export const signInOdooToken =
  ({ access_token, redirect_uri, scope, response_type, state, client_id }) =>
  async (dispatch) => {
    const response = await authAPI
      .post("/api/v1/oauth/client/access", {
        access_token,
        redirect_uri,
        scope,
        response_type,
        state,
        client_id,
      })
      .catch((e) => {});

    if (response.status === 200) {
      const responseUser = response.data.data;
      const user = JSON.parse(localStorage.getItem("user"));
      user.redirect_uri = responseUser.redirect_uri;
      localStorage.setItem("user", JSON.stringify(user));
      dispatch({ type: "SIGN_IN", payload: JSON.stringify(user) });
    }
  };

export const signOut = () => {
  localStorage.removeItem("user");
  return {
    type: "SIGN_OUT",
  };
};

export const checkToken =
  ({ username, password }) =>
  async (dispatch) => {};

export const resetPassword =
  ({ password, token, email }) =>
  async (dispatch) => {
    const response = await authAPI.put("/api/v1/password", {
      password,
      token,
      email,
    });
    if (response.data.status === "OK") {
      dispatch({
        type: "APPROVE_UPDATE_PASSWORD",
        payload: { status: "APPROVE_UPDATE_PASSWORD" },
      });
    } else {
      dispatch({
        type: "FAILED_UPDATE_PASSWORD",
        payload: { status: "FAILED_UPDATE_PASSWORD" },
      });
    }
  };

export const updatePassword =
  ({ oldPassword, password }) =>
  async (dispatch) => {
    const authAPI = axios.create({
      baseURL: serverList.authentication,
      headers: authHeader(),
    });
    const response = await authAPI.put("/api/v1/password/profile", {
      old_password: oldPassword,
      password,
    });
    if (response.data.status === "OK") {
      dispatch({
        type: "EDIT_SUCCESS",
        payload: { status: "EDIT_SUCCESS", message: response.data.data },
      });
    } else {
      dispatch({
        type: "EDIT_FAILED",
        payload: { status: "EDIT_FAILED", message: response.data.message },
      });
    }
  };

export const updateUserProfile =
  ({ name }) =>
  async (dispatch) => {
    const userAPI = axios.create({
      baseURL: serverList.user,
      headers: authHeader(),
    });
    const response = await userAPI.put("/api/v1/user", {
      name,
    });
    if (response.data.status === "OK") {
      dispatch({
        type: "EDIT_SUCCESS",
        payload: { status: "EDIT_SUCCESS", message: response.data.data },
      });
    } else {
      dispatch({
        type: "EDIT_FAILED",
        payload: { status: "EDIT_FAILED", message: response.data.message },
      });
    }
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
  };

export const getUserDetail = () => async (dispatch) => {
  const userAPI = axios.create({
    baseURL: serverList.user,
    headers: authHeader(),
  });
  const response = await userAPI.get("/api/v1/oauth/user");
  if (response) {
    if (response.status === 200) {
      dispatch({
        type: "GET_USER_INFO",
        payload: response.data,
      });
    }
  }
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
};

export const updateCompanyProfile =
  ({ address }) =>
  async (dispatch) => {
    const companyAPI = axios.create({
      baseURL: serverList.company,
      headers: authHeader(),
    });
    const response = await companyAPI.put("/api/v1/company", {
      address,
    });
    if (response.data.status === "OK") {
      dispatch({
        type: "EDIT_SUCCESS",
        payload: { status: "EDIT_SUCCESS", message: response.data.data },
      });
    } else {
      dispatch({
        type: "EDIT_FAILED",
        payload: { status: "EDIT_FAILED", message: response.data.message },
      });
    }
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
  };
