import { useState, useEffect } from "react";
import { connect, useSelector, useDispatch, shallowEqual } from "react-redux";
import { useNavigate, matchPath } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SimpleDashboardNavbar from "examples/Navbars/SimpleDashboardNavbar";
import LVPengajuanDataGrid from "components/LVPengajuanDataGrid";
import PilihPendanaModal from "modals/pilihPendanaModal";
import PilihPendanaPenjualanModal from "modals/pilihPendanaPenjualanModal";

import DaftarPelunasanInvoiceModal from "modals/daftarPelunasanInvoiceModal";
import ReactGA from "react-ga4";
import { getCompanyKYCStatus } from "../../actions/companyAction";
import {
  fetchPengajuanList,
  initPengajuanList,
  getCreditLimitCompany,
  fetchPengajuanInvoiceList,
  fetchPengajuanOrderList,
} from "../../actions/fintechAction";
import { transformPengajuan } from "../../common/Util";
import MDInput from "components/MDInput";

function Pengajuan() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/invoice-financing", title: "Invoice Financing" });
  }, []);

  const user = JSON.parse(localStorage.getItem("user"));

  const [rows, setRows] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openPenjualanModal, setOpenPenjualanModal] = useState(false);
  const [openPelunasanInvoice, setOpenPelunasanInvoice] = useState(false);
  const [open, setOpen] = useState(false);
  const credit_limit = 2000000000;

  const listPengajuan = useSelector((state) => state.listPengajuan, shallowEqual);

  const anCompanyKycStatus = useSelector((state) => state.anCompanyKycStatus, shallowEqual);

  const anCreditLimitSummary = useSelector((state) => state.anCreditLimitSummary, shallowEqual);

  const match = matchPath(
    {
      path: "/pengajuan",
      exact: true,
      strict: true,
    },
    location.pathname
  );

  const matchOF = matchPath(
    {
      path: "/orderfinancing",
      exact: true,
      strict: true,
    },
    location.pathname
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCompanyKYCStatus());
    if (match) {
      dispatch(fetchPengajuanInvoiceList());
    } else if (matchOF) {
      dispatch(fetchPengajuanOrderList());
    }

    dispatch(getCreditLimitCompany());
    return () => {
      dispatch(initPengajuanList());
    };
  }, []);

  const onClickPelunasan = () => {
    setOpenPelunasanInvoice(true);
  };

  const render_pelunasan = () => {
    return (
      <Grid item>
        <MDBox>
          <MDButton variant="outlined" color="info" onClick={onClickPelunasan}>
            Pelunasan
          </MDButton>
        </MDBox>
      </Grid>
    );
  };

  useEffect(() => {
    setRows(
      transformPengajuan(listPengajuan, {
        limit: 10,
        current_page: 1,
      })
    );
  }, [listPengajuan]);

  useEffect(() => { }, [rows]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const navigateToRegisterBorrow = () => {
    navigate("/registerborrow");
  };

  const onClickPendanaan = () => {
    if (anCompanyKycStatus.status === "created") {
      handleClickOpen();
    } else if (anCompanyKycStatus.status === "approved") {
      if (user?.company?.business_type === "borrower") {
        setOpenModal(true);
      } else if (user?.company?.business_type === "broker") {
        if (match) {
          setOpenModal(true);
        } else if (matchOF) {
          setOpenPenjualanModal(true);
        }
      }
    } else {
      handleClickOpen();
    }
  };

  return (
    <div>
      <DashboardLayout>
        <SimpleDashboardNavbar name={matchOF ? "Order Financing" : "Invoice Financing"} />
        <MDBox mt={5} bgColor="white" p={0.5} shadow="md" borderRadius="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={2.4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="credit_card"
                  title="Sisa Limit (Rp)"
                  count={(
                    anCreditLimitSummary?.credit_limit - anCreditLimitSummary?.credit_limit_financing
                  ).toLocaleString("id-ID")}
                  countVariant={
                    anCreditLimitSummary?.credit_limit - anCreditLimitSummary?.credit_limit_financing <
                      1000000000
                      ? "h5"
                      : "h6"
                  }
                  percentage={{
                    color: "success",
                    amount: `Rp ${anCreditLimitSummary?.credit_limit?.toLocaleString("id-ID").length > 18
                      ? `${anCreditLimitSummary?.credit_limit
                        ?.toLocaleString("id-ID")
                        .substr(0, 14)}...`
                      : anCreditLimitSummary?.credit_limit?.toLocaleString("id-ID")
                      }`,
                    label: " Limit",
                    type: "up",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={2.4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="secondary"
                  icon="add_to_queue"
                  title="Baru"
                  count="N/A"
                  countVariant="h5"
                  percentage={{
                    color: "success",
                    amount: "N/A",
                    label: "dari minggu lalu",
                    type: "up",
                  }}
                  first
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={2.4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="thumb_up"
                  title="Dikonfirmasi"
                  count="N/A"
                  countVariant="h5"
                  percentage={{
                    color: "primary",
                    amount: "N/A",
                    label: "dari minggu lalu",
                    type: "down",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={2.4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="hourglass_bottom"
                  title="Menunggu Lunas"
                  count="N/A"
                  countVariant="h5"
                  percentage={{
                    color: "primary",
                    amount: "N/A",
                    label: "dari minggu lalu",
                    type: "down",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={2.4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="check"
                  title="Lunas"
                  count="N/A"
                  countVariant="h5"
                  percentage={{
                    color: "success",
                    amount: "N/A",
                    label: "dari minggu lalu",
                    type: "up",
                  }}
                  last
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container pt={3} spacing={3} justifyContent="space-between">
          <Grid item />
          <Grid item width="70%">
            <Grid container spacing={1} justifyContent="flex-end">
              <MDBox pt={1} pr={2} my="auto">
                <MDInput label="Cari disemua kolom.." sx={{ width: "300px" }} />
              </MDBox>
              {render_pelunasan()}
              <Grid item>
                <MDBox>
                  <MDButton variant="contained" color="info" onClick={() => onClickPendanaan()}>
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;Ajukan {matchOF ? "Order Financing" : "Invoice Financing"}
                  </MDButton>
                </MDBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <LVPengajuanDataGrid rows={rows} showFintech />
        <PilihPendanaModal
          open={openModal}
          onClose={(e) => {
            setOpenModal(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        />
        <PilihPendanaPenjualanModal
          open={openPenjualanModal}
          onClose={(e) => {
            setOpenPenjualanModal(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        />
        <DaftarPelunasanInvoiceModal
          open={openPelunasanInvoice}
          onClose={(e) => {
            setOpenPelunasanInvoice(false);
          }}
          type={matchOF ? "order-financing" : "invoice-financing"}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        />
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Informasi</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Anda harus mendaftar pengajuan dana terlebih dahulu
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={navigateToRegisterBorrow} autoFocus>
              Daftar Pengajuan Dana
            </MDButton>
            <MDButton onClick={handleClose}>Batal</MDButton>
          </DialogActions>
        </Dialog>
      </DashboardLayout>
    </div>
  );
}

export default Pengajuan;
