import Modal from '@mui/material/Modal';
import RejectKYCDocument from '../layouts/rejectKYCDocument';

const RejectKYCDocumentModal = (props) => {

    return (
        <Modal
            {...props}
        >
            <div>
                <RejectKYCDocument
                    onClose={props.onClose}
                    selected={props.selected}
                    onReject={(reason) => props.onReject(reason)}
                />
            </div>
        </Modal>
    );
}

export default RejectKYCDocumentModal;