/* eslint-disable react/no-unstable-nested-components */
import React, { Component, useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import CurrencyExchangeRoundedIcon from "@mui/icons-material/CurrencyExchangeRounded";
import Radio from "@mui/material/Radio";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Icon } from "@mui/material";
import CustomFooter from "components/LVCustomFooter";

function LVPenjualanDataGrid(props) {
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: props.radioSelection === undefined ? false : props.radioSelection,
    actions: !props.viewOnly,
    statusFinancing_readable: props.columnType === "broker",
  });

  const [selected, setSelected] = useState([]);

  const columns = [
    {
      field: "id",
      headerName: " ",
      width: 40,
      renderCell: (cellValues) => {
        if (props.radioSelection) {
          return (
            <Radio
              checked={selected.includes(cellValues.id)}
              onClick={() => onRadioChange(cellValues)}
              value={cellValues.id}
              name="radio-buttons"
              inputProps={{ "aria-label": { cellValues } }}
            />
          );
        }
      },
    },
    {
      field: "no",
      headerName: "No.",
      headerAlign: "left",
      align: "left",
      width: 50,
    },
    {
      field: "number",
      headerName: "No. Order Penjualan",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Link to={`/detailorderpenjualan/${encodeURIComponent(params.row.id)}`}>
          <MDTypography variant="body3">{params.value}</MDTypography>
        </Link>
      ),
      width: 200,
    },

    {
      field: "project_name",
      headerName: "Nama",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 200,
    },
    {
      field: "status_readable",
      headerName: "Status",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 150,
    },

    {
      field: "customer_company",
      headerName: "Pelanggan",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 150,
    },
    {
      field: "creation_date",
      headerName: "Tanggal Order",
      headerAlign: "left",
      type: "date",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 110,
    },

    {
      field: "total",
      headerName: "Total",
      headerAlign: "left",
      align: "left",
      type: "number",
      renderCell: (params) => {
        const valueFormatted = Number(params.value).toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
        });
        return (
          <Tooltip title={valueFormatted}>
            <span>{valueFormatted}</span>
          </Tooltip>
        );
      },
      width: 160,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Aksi",
      sortable: false,
      align: "center",
      width: 100,
      getActions: (params) => {
        if (

          params.row.status === "draft"
        ) {
          return [
            <MDBox sx={{ justifyContent: "center" }}>
              <MDButton
                sx={{ color: "#F63939", fontSize: "12px", fontWeight: "bold" }}

              >
                Hapus
              </MDButton>
            </MDBox>,
          ];
        }

        return [];
      },
    },
  ];

  const newdata = {
    columns,
    rows: props.rows,
  };

  const renderSX = () => {
    if (props.flat) {
      return {
        bgcolor: "rgba(255, 255, 255, 1)",
        borderRadius: "16px",
        fontSize: "small",
      };
    }
    return {
      bgcolor: "rgba(255, 255, 255, 1)",
      borderRadius: "16px",
      fontSize: "small",
      boxShadow: "10px 15px 27px -28px #1A73E8",
    };
  };

  const onRadioChange = (cellValues) => {
    if (selected.includes(cellValues.id)) {
      let array = selected;
      array = array.filter((item) => item !== cellValues.id);
      setSelected(array);
      props.onSelectionModelChange(array);
    } else {
      props.onSelectionModelChange([...selected, cellValues.id]);
      setSelected([...selected, cellValues.id]);
    }
  };

  function CustomFooterProps() {
    return (
      <CustomFooter
        page={props.totalPage}
        selectPage={props.selectPage}
        nextPage={props.nextPage}
        prevPage={props.prevPage}
        currentPage={props.currentPage}
        firstPage={props.firstPage}
        lastPage={props.lastPage}
      />
    );
  }

  return (
    <MDBox
      sx={{
        height: props.height,
        width: "100%",
      }}
    >
      <DataGrid
        {...newdata}
        pageSize={props.pageSize}
        rowsPerPageOptions={props.rowsPerPageOptions}
        editMode="row"
        checkboxSelection={props.checkboxSelection}
        localeText={{ noRowsLabel: "Belum ada order penjualan." }}
        disableSelectionOnClick
        sx={renderSX()}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => {
          newModel.id = false;
          setColumnVisibilityModel(newModel);
        }}
        experimentalFeatures={{ newEditingApi: true }}
        hideFooterPagination
        components={{ Footer: CustomFooterProps }}
      />
    </MDBox>
  );
}

export default LVPenjualanDataGrid;
