export const searchItem = (object, search) => {
    let result = []
    Object.keys(object).forEach(key => {
        if (typeof object[key] === "object") {
            let searchResult = searchItem(object[key], search);
            if (searchResult.length > 0) {
                result.push(key);
            }
        }
        if (typeof object[key] === "string") {
            let searchAsRegEx = new RegExp(search, "gi");
            if (object[key].match(searchAsRegEx)) {
                result.push(key)
            }
        }
    })
    return result;
}
