import { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SimpleDashboardNavbar from "examples/Navbars/SimpleDashboardNavbar";
import LVKycDocument from "components/LVKycDocument";
import serverList from "apis/serverList";
import FintechModal from "modals/viewFintechModal";
import {
  fetchKYCDocs,
  adminFetchKYCDocs,
  fetchCustomerDetail,
  adminFetchCustomerDetail,
  approveKYCDocsAdmin,
  approveKYCPhoneAdmin,
  rejectKYCDocsAdmin,
  rejectKYCPhoneAdmin,
} from "../../actions/kycPeminjamAction";
import { emptyMessage } from "../../actions";
import { transformKycDocsArr } from "../../common/Util";
import RejectKYCDocumentModal from "../../modals/rejectKYCDocumentModal";
import {
  Modal,
  Card,
  CircularProgress,
} from "@mui/material";

function DetailKycPeminjam() {
  const user = JSON.parse(localStorage.getItem("user"));
  const company = user?.company;

  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [fintechModal, setFintechModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selected, setSelected] = useState({});

  const [downloadLoading, setDownloadLoading] = useState(false);


  const [previewModal, setPreviewModal] = useState(false);
  const [previewPath, setPreviewPath] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const listKYCDocsArray = useSelector((state) => state.listKYCDocsArray, shallowEqual);

  const customerDetail = useSelector((state) => state.kycCompanyDetail, shallowEqual);

  const message = useSelector((state) => state.message, shallowEqual);

  const dispatch = useDispatch();
  const params = useParams();

  const id = decodeURIComponent(params.id);
  const status = decodeURIComponent(params.status);
  const batchDownloadLink = `${serverList.company}/api/v1/fintech/company/kyc/document/batch?authorization=${user.access_token}&company_id=${id}`;


  const openDocumentModal = (name, path) => {
    setPreviewTitle(name);
    setPreviewPath(path);
    setPreviewModal(true);
  }

  const closeDocumentModal = () => {
    setPreviewPath("");
    setPreviewModal(false);
  }

  useEffect(() => {
    const id = decodeURIComponent(params.id);
    if (id !== null) {
      if (company.business_type === "fintech") {
        dispatch(fetchKYCDocs(id));
        dispatch(fetchCustomerDetail(id));
      } else {
        dispatch(adminFetchKYCDocs(id));
        dispatch(adminFetchCustomerDetail(id));
      }
    }
  }, []);

  useEffect(() => { }, [customerDetail]);

  useEffect(() => {
    const id = decodeURIComponent(params.id);
    dispatch(emptyMessage());
    switch (message.status) {
      case "APPROVE_KYC_DOCUMENT":
        if (id !== null) {
          dispatch(adminFetchKYCDocs(id));
        }
        return;
      case "REJECT_KYC_DOCUMENT":
        if (id !== null) {
          dispatch(adminFetchKYCDocs(id));
        }
        return;
      case "SUCCESS_CREATE_CUSTOMER_RELATION":
        // setRiwayatModal(false);
        setFintechModal(false);
        setOpenDialog(true);
        dispatch(adminFetchKYCDocs(id));

      default:
    }
  }, [message]);

  const onApprove = ({ id, name }) => {
    setSelected({ id, name });
    setOpenApproveDialog(true);
  };

  const onReject = ({ id, name }) => {
    setSelected({ id, name });
    setOpenRejectDialog(true);
  };

  const onDialogApproveAgree = () => {
    if (selected.name === "Nomor Telepon") {
      dispatch(approveKYCPhoneAdmin(Number(selected.id)));
    } else {
      dispatch(approveKYCDocsAdmin(Number(selected.id)));
    }
    setOpenApproveDialog(false);
  };

  const onDialogRejectAgree = () => {
    if (selected.name === "Nomor Telepon") {
      dispatch(rejectKYCPhoneAdmin({ id: Number(selected.id), reason: "NA" }));
    } else {
      dispatch(rejectKYCDocsAdmin({ id: Number(selected.id), reason: "NA" }));
    }
    setOpenRejectDialog(false);
  };

  const onClickHandler = (e) => {
    const url = downloadLoading ? "#" : batchDownloadLink;
    window.open(url, "_self");
    setDownloadLoading(true);
    setTimeout(() => {
      setDownloadLoading(false);
    }, 30000);
  };

  return (
    <DashboardLayout>
      <Modal
        open={previewModal}
        onClose={closeDocumentModal}
      // aria-labelledby="modal-modal-title"
      // aria-describedby="modal-modal-description"
      >
        <Card sx={{
          margin: "auto",
          marginTop: 5, marginBottom: 3, width: '100%', maxWidth: "1200px", display: "flex",
        }}>
          <MDBox p={3} mx={3} display="flex" justifyContent="center" />
          <MDBox pb={4} px={2} textAlign="left" lineHeight={1.25} justifyContent="center" >
            <MDTypography mb={2} variant="h4" fontWeight="medium" textTransform="capitalize">
              {previewTitle}
            </MDTypography>
            <embed src={previewPath} width="100%" height="600px" />
          </MDBox>
        </Card>
      </Modal>
      <SimpleDashboardNavbar name="Detail Peminjam" />
      <Grid container direction="column" justifyContent="space-between" spacing={1} mt={5}>
        <Grid item ml={1} mr={3} mb={1}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Grid container spacing={1} justifyContent="space-around">
                <Grid item>
                  <MDTypography variant="h4">
                    {customerDetail.type} {customerDetail.name}
                  </MDTypography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {user.company.business_type === "fintech" ? (
          <Grid item xs={12} mb={-5} justifyContent="flex-end">
            <MDBox pt={2} pb={1}>
              <MDButton
                variant="contained"
                color={downloadLoading ? "white" : "info"}
                href={downloadLoading ? "#" : batchDownloadLink}
                onClick={(e) => {
                  onClickHandler(e);
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>
                  {downloadLoading ? "downloading" : "download"}
                </Icon>
                {downloadLoading ? (
                  <>
                    <MDTypography ml={3} variant="body3" color="black" fontWeight="bold">
                      SEDANG MENGUNDUH
                    </MDTypography>
                    <CircularProgress size={20} sx={{ ml: 2 }} />
                  </>
                ) : (
                  <MDTypography ml={3} variant="body3" color="white" fontWeight="bold">
                    UNDUH SEMUA
                  </MDTypography>
                )}
              </MDButton>
            </MDBox>
          </Grid>
        ) : (
          <div />
        )}

        <Grid item sx={{ mt: 0 }}>
          <LVKycDocument
            mt={0}
            rows={transformKycDocsArr(listKYCDocsArray)}
            onApprove={onApprove}
            onReject={onReject}
            businessType={user.company.business_type}
            onPreview={(name, path) => openDocumentModal(name, path)}

          />
        </Grid>
        <FintechModal
          open={fintechModal === true}
          kyc
          onClose={() => setFintechModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          id={id}
          transporterName={customerDetail.name}
        />
        {user.role.includes("admin") && status === "Sudah Disetujui" ? (
          <MDBox
            mt={5}
            position="sticky"
            bottom={0}
            width="100%"
            height="65px"
            px={3}
            display="flex"
            justifyContent="end"
            sx={{
              bgcolor: "#ffffff",
              boxShadow: "10px 15px 27px -28px #1A73E8",
              borderRadius: "16px",
              border: "1px solid #e1e1e1",
            }}
          >
            <MDBox display="flex" my="auto" sx={{ justifyContent: "end" }}>
              <MDButton onClick={() => setFintechModal(true)} size="large" color="info">
                Hubungkan
              </MDButton>
            </MDBox>
          </MDBox>
        ) : null}
        <Dialog
          open={openApproveDialog}
          onClose={() => setOpenApproveDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Setujui Dokumen KYC</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Apakah anda yakin untuk menyetujui {selected.name}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton
              onClick={onDialogApproveAgree}
              variant="contained"
              color="info"
              size="small"
              autoFocus
            >
              Setujui
            </MDButton>
            <MDButton
              onClick={() => setOpenApproveDialog(false)}
              variant="contained"
              color="error"
              size="small"
            >
              Batal
            </MDButton>
          </DialogActions>
        </Dialog>
        <RejectKYCDocumentModal
          open={openRejectDialog}
          onClose={() => setOpenRejectDialog(false)}
          selected={selected}
        />
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Berhasil</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Kerjasama telah berhasil dihubungkan
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton
              onClick={() => setOpenDialog(false)}
              variant="contained"
              color="info"
              size="small"
            >
              OK
            </MDButton>
          </DialogActions>
        </Dialog>
      </Grid>
    </DashboardLayout>
  );
}

export default DetailKycPeminjam;
