import axios from "axios";
import serverList from "../apis/serverList";
import authHeader from "../apis/authHeader";

export const fetchSelfBillingInvoices = () => async (dispatch) => {
  const invoiceAPI = axios.create({
    baseURL: serverList.invoice,
    headers: authHeader(),
  });
  const response = await invoiceAPI.get("/api/v1/invoices/self/billing");
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  dispatch({ type: "FETCH_INVOICES", payload: response.data.data });
};

export const searchSelfBillingInvoices =
  ({
    id = [],
    financing_status = [],
    document_status = [],
    invoice_status = [],
    due_date = [],
  }) =>
    async (dispatch) => {
      const invoiceAPI = axios.create({
        baseURL: serverList.invoice,
        headers: authHeader(),
      });
      const arrStatus = [];
      arrStatus.push("unpaid");
      const response = await invoiceAPI.post("/api/v1/invoices/self/billing/search", {
        id,
        financing_status,
        document_status,
        invoice_status,
        due_date,
      });
      if (response.status === 401) {
        dispatch({
          type: "ERROR",
          payload: { status: "TOKEN_EXPIRE", message: response.data.message },
        });
      }
      if (response.status === 200) {
        if (response.data.status === "OK") {
          dispatch({ type: "FETCH_INVOICES", payload: response.data.data });
        }
      }
    };
