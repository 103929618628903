import { useState, useEffect, useRef } from "react";
import { connect, useSelector, useDispatch, shallowEqual } from "react-redux";

import Grid from "@mui/material/Grid";
import LVFileInput from 'components/LVFileInput';
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import { fundTransfer, fundTransferQP } from '../../actions/fintechAction';
import {emptyMessage} from '../../actions/'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    overflow:"auto",
    p:4
  };


const FundTransfer = (props) => {
    const [buktiTransferLogo, setBuktiTransferLogo] = useState(null);
    const [buktiTransfer, setBuktiTransfer] = useState(null);
    const [title, setTitle] = useState('Bukti Transfer')
    const [showError, setShowError] = useState({
        buktiTransfer: false,
    });
    const [helperMsg, setHelperMsg] = useState({
        buktiTransfer: 'Bukti transfer belum dipilih',
    });
    const refs = {
        buktiTransfer: useRef(),
    }
    const message = useSelector(
        state => {
          return state.message;
        },
        shallowEqual
    );
  
    const dispatch = useDispatch();

    useEffect(() => {
        switch (message.status) {
            case 'FUND_TRANSFER':
                dispatch(emptyMessage());
                props.onClose();
                return;
            case 'REMOVE_BUKTI_TRANSFER':
                dispatch(emptyMessage());
                setBuktiTransferLogo(null); 
                setBuktiTransfer(null);         
                setTitle('Bukti Transfer');
                return;
            case 'ERROR':                
                setShowError(
                    {
                        ...showError,
                        fail: true
                    }
                );
                dispatch(emptyMessage());
                return;
            default:
                return;
        }
    }, [message]);

    useEffect(() => {
        if (buktiTransferLogo !== null) {
            setShowError({...showError, buktiTransfer: false});
        }        
    }, [buktiTransferLogo]);

    const onChangeBuktiTransfer = (e) => {
        var fileObj = e.target.files[0];
        setBuktiTransfer(fileObj);
        setTitle(fileObj.name);
        if (fileObj.type === "application/pdf") {
            setBuktiTransferLogo('/pdf_logo_bw.jpg');        
        } else {
            setBuktiTransferLogo(fileObj.name);        
        }
        if (refs.buktiTransfer.current !== undefined) {
            refs.buktiTransfer.current.value = null;
        }
    }

    const onAgree = () => {

        if (buktiTransferLogo !== null) {
            if (props.financingtype === 'invoice') {
                dispatch(fundTransfer({financing_id: Number(props.idfinancing), file: buktiTransfer }));
            } else if (props.financingtype === 'quickpay') {
                dispatch(fundTransferQP({financing_id: Number(props.idfinancing), file: buktiTransfer }));
            }            
        }else {
            setShowError({
                buktiTransfer: buktiTransferLogo === null,
            });
        }        
    }

    const onDelete = () => {
        dispatch({type:'REMOVE_BUKTI_TRANSFER', payload:  {status:'REMOVE_BUKTI_TRANSFER', message: 'Remove transfer proof' }});
    }
    return (
        <div>
            <MDBox sx={style} >
                <Grid container direction="column" justifyContent="space-around" spacing={2}>    
                    <Grid item>
                        <MDBox>
                            <MDTypography variant="title" fontWeight="medium">
                                Transfer Dana
                            </MDTypography>
                        </MDBox>
                    </Grid> 
                    <Grid item>                    
                        <MDBox sx={{ width: '100%' }}>
                            <MDBox display="flex" alignItems="center" justifyContent="space-around" spacing={2}>
                                
                                <MDBox mb={2}>
                                    <LVFileInput 
                                        srcImage={buktiTransferLogo}
                                        srcFile={buktiTransfer}
                                        height={200}
                                        width={240}
                                        fontSize={10}
                                        id='bukti_transfer'
                                        onChangeFile={onChangeBuktiTransfer}
                                        text1='Unggah Bukti Transfer'
                                        text2='Maksimal ukuran 5MB PNG, JPG, atau PDF'
                                        text3=''
                                        title={title}
                                        titleFontSize={12}
                                        accept=".pdf,.png,.jpg,.jpeg"
                                        disableDownload
                                        deleteFile={onDelete}
                                        refinput={refs.buktiTransfer}
                                    />
                                    
                                </MDBox>                           
                            </MDBox>                                     
                        </MDBox>                    
                    </Grid>   
                    <Grid item>
                        <MDBox justifyContent="center" px={2} alignItems="center">
                            <MDBox>
                                <MDButton fullWidth variant="contained" color="info" mt={1} size="small" onClick={onAgree}>
                                    Simpan
                                </MDButton>
                            </MDBox>
                            <MDBox mt={1}>
                                <MDButton fullWidth variant="outlined" color="info" mt={1} size="small" onClick={props.onClose}>                         
                                    Batal
                                </MDButton>
                            </MDBox>
                            <MDTypography color="error" sx={{fontSize:10, marginTop:1}}>{showError.buktiTransfer?helperMsg.buktiTransfer:''} </MDTypography>
                            <MDTypography color="error" my={1} px={1} sx={{fontSize:10}}>{showError.all?helperMsg.all:''} </MDTypography>
                            <MDTypography color="error" my={1} px={1} sx={{fontSize:10}}>{showError.fail?helperMsg.fail:''} </MDTypography>
                        </MDBox>
                    </Grid>                        
                </Grid>
                
            </MDBox>            
        </div>
    );
}

export default FundTransfer;