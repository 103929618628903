import { useState, useEffect } from 'react';
import { connect, useSelector, useDispatch, shallowEqual } from "react-redux";
import { useNavigate } from 'react-router-dom';

import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SimpleDashboardNavbar from "examples/Navbars/SimpleDashboardNavbar";
import LVPengajuanDataGrid from "components/LVPengajuanDataGrid";
import QuickPayModal from 'modals/quickPayModal';

import { getCompanyKYCStatus } from '../../actions/companyAction';
import { fetchPengajuanQPList } from '../../actions/fintechAction';
import { transformPengajuan } from '../../common/Util';

const PembayaranCepat = () => {

    const user = JSON.parse(localStorage.getItem("user"));

    const [rows, setRows] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [open, setOpen] = useState(false);


    const listPengajuan = useSelector(
        state => {
            return state.listPengajuan;
        },
        shallowEqual
    );

    /*     const anCompanyKycStatus = useSelector(
            state => {
              return state.anCompanyKycStatus;
            },
            shallowEqual
        ); */

    const dispatch = useDispatch();
    const navigate = useNavigate();

    /* const rows = [
        {id:1, no: '1', number:'INV/2022/10/01', pendana:'Gandeng Tangan', status: 'Menunggu Pendana', pengajuan: 'Rp 8.000.000,00', disetujui: 'NA', terdanai:'NA', tanggal_terdanai: 'NA', tenor: 'NA', jatuh_tempo: 'NA', usia: 'NA', sisa_pelunasan:'NA' },
        {id:2, no: '2', number:'INV/2022/10/02', pendana:'Modal Rakyat',status: 'Menunggu Transfer Dana', pengajuan: 'Rp 8.000.000,00',disetujui: 'Rp 6.000.000,00',  terdanai:'NA', tanggal_terdanai: 'NA',tenor: '30', jatuh_tempo: 'NA', usia: 'NA', sisa_pelunasan:'NA' },
        {id:3, no: '3', number:'INV/2022/10/03', pendana:'Modal Rakyat',status: 'Terdanai', pengajuan: 'Rp 8.000.000,00',disetujui: 'Rp 6.000.000,00',  terdanai:'Rp 6.000.000,00', tanggal_terdanai: '15 Agustus 2022',tenor: '30', jatuh_tempo: '14 September 2022', usia: '14 hari', sisa_pelunasan:'Rp 6.000.000,00' },
        {id:4, no: '4', number:'INV/2022/10/04', pendana:'Gandeng Tangan',status: 'Lunas', pengajuan: 'Rp 8.000.000,00', disetujui: 'Rp 6.000.000,00', terdanai:'Rp 6.000.000,00', tanggal_terdanai: '15 Juli 2022',tenor: '30', jatuh_tempo: '14 Agustus 2022', usia: '30', sisa_pelunasan:'Rp 0,00' },
        
    ]; */

    useEffect(() => {

        dispatch(fetchPengajuanQPList());
    }, []
    );

    useEffect(() => {

        setRows(transformPengajuan(listPengajuan, {
            limit: 10,
            current_page: 1,
        }));

    }, [listPengajuan]);

    useEffect(() => {
    }, [rows]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const navigateToRegisterBorrow = () => {
        navigate("/registerborrow");
    };

    const onClickPendanaan = () => {
        setOpenModal(true);


    }
    return (
        <div>
            <DashboardLayout>
                <SimpleDashboardNavbar name="Invoice Financing" />
                <MDBox mt={5} bgColor="white" p={0.5} shadow="md" borderRadius="xl">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color="secondary"
                                    icon="add_to_queue"
                                    title="Baru"
                                    count={23}
                                    percentage={{
                                        color: "success",
                                        amount: "+5%",
                                        label: "dari minggu lalu",
                                    }}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    icon="thumb_up"
                                    title="Permintaan Dikonfirmasi"
                                    count="15"
                                    percentage={{
                                        color: "primary",
                                        amount: "-2%",
                                        label: "dari minggu lalu",
                                    }}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color="primary"
                                    icon="hourglass_bottom"
                                    title="Menunggu Pelunasan"
                                    count="20"
                                    percentage={{
                                        color: "primary",
                                        amount: "-5%",
                                        label: "dari minggu lalu",
                                    }}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color="success"
                                    icon="check"
                                    title="Lunas"
                                    count="106"
                                    percentage={{
                                        color: "success",
                                        amount: "+10%",
                                        label: "dari minggu lalu",
                                    }}
                                />
                            </MDBox>
                        </Grid>
                    </Grid>
                </MDBox>
                <Grid container spacing={3} justifyContent="space-between">
                    <Grid item>
                        <MDBox pt={2}>
                        </MDBox>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={1} justifyContent="flex-end">
                            <Grid item>
                                <MDBox pt={2}>

                                </MDBox>
                            </Grid>
                            <Grid item>
                                <MDBox pt={2}>
                                    <MDButton variant="contained" color="info" onClick={() => onClickPendanaan()}>
                                        <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                                        &nbsp;Pembayaran Cepat Baru
                                    </MDButton>
                                </MDBox>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <LVPengajuanDataGrid
                    rows={rows}
                    columnType={user?.company?.business_type}
                />
                <QuickPayModal
                    open={openModal}

                    onClose={(e) => {

                        setOpenModal(false);
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                />
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Informasi"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Anda harus mendaftar pengajuan dana terlebih dahulu
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <MDButton onClick={navigateToRegisterBorrow} autoFocus>
                            Daftar Pengajuan Dana
                        </MDButton>
                        <MDButton onClick={handleClose}>Batal</MDButton>
                    </DialogActions>
                </Dialog>
            </DashboardLayout>
        </div>
    );
};

export default PembayaranCepat;