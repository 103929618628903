
import { useState, useEffect } from "react";
import { connect, useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SimpleDashboardNavbar from "examples/Navbars/SimpleDashboardNavbar";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";





import MDButton from "components/MDButton";

import LVPembelianDataGrid from "components/LVPembelianDataGrid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { fetchOrderPembelian, initOrderPembelian } from "actions/orderPembelianAction";
import ReactGA from "react-ga4";
import { transformOrderPembelian } from "../../common/Util";
import MDInput from "components/MDInput";

function OrderPembelian() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/order-pembelian", title: "Order Pembelian" });
  }, []);

  const user = JSON.parse(localStorage.getItem("user"));

  const dispatch = useDispatch();

  const listOrderPembelian = useSelector((state) => state.listOrderPembelian, shallowEqual);

  useEffect(() => {
    dispatch(fetchOrderPembelian());
    return () => {
      dispatch(initOrderPembelian());
    };
  }, []);

  useEffect(() => {

  }, [listOrderPembelian]);

  const render_order_pembelian_baru = () => {
    if (user?.access_list?.includes("new_po")) {
      return (
        <Grid item>
          <MDBox pt={2} pb={3}>
            <MDButton variant="outlined" color="info" component={Link} to="/neworderpembelian">
              <Icon sx={{ fontWeight: "bold" }}>add</Icon>
              &nbsp;Order Pembelian Baru
            </MDButton>
          </MDBox>
        </Grid>
      );
    }
  };

  return (
    <div>
      <DashboardLayout>
        <SimpleDashboardNavbar name="Order Pembelian" />
        <MDBox mt={5} bgColor="white" p={0.5} shadow="md" borderRadius="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="secondary"
                  icon="add_to_queue"
                  title="Baru"
                  count="N/A"
                  countVariant="h5"
                  percentage={{
                    color: "success",
                    amount: "N/A",
                    label: "dari minggu lalu",
                    type: "up",
                  }}
                  first
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="engineering"
                  title="Pengerjaan Berlangsung"
                  count="N/A"
                  countVariant="h5"
                  percentage={{
                    color: "success",
                    amount: "N/A",
                    label: "dari minggu lalu",
                    type: "up",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="receipt"
                  title="Pembuatan Invoice"
                  count="N/A"
                  countVariant="h5"
                  percentage={{
                    color: "primary",
                    amount: "N/A",
                    label: "dari minggu lalu",
                    type: "down",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="hourglass_bottom"
                  title="Menunggu Pembayaran"
                  count="N/A"
                  countVariant="h5"
                  percentage={{
                    color: "success",
                    amount: "N/A",
                    label: "dari minggu lalu",
                    type: "up",
                  }}
                  last
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item />
          <Grid item width="70%">
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <MDBox pt={2}>

                </MDBox>
              </Grid>
              <MDBox pr={2} my="auto">
                <MDInput label="Cari disemua kolom.." sx={{ width: "300px" }} />
              </MDBox>
              {render_order_pembelian_baru()}
            </Grid>
          </Grid>
        </Grid>
        <LVPembelianDataGrid
          height={650}
          pageSize={10}
          rowsPerPageOptions={[10]}
          checkboxSelection={false}
          radioSelection={false}
          rows={transformOrderPembelian({ orderPembelian: listOrderPembelian })}
        />
      </DashboardLayout>
    </div>
  );
}

export default OrderPembelian;
