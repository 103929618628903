/* eslint-disable no-nested-ternary */
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Icon } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import { fetchRelationPendana } from "actions/daftarPendanaAction";
import LVDaftarRelationFintech from "components/LVDaftarRelationFintech";
import { createCustomerRelation } from "actions/calonPeminjamAction";
import { useParams } from "react-router-dom";
import { fetchAllpendana } from "actions/daftarPendanaAction";
import { createCustomerRelationKyc } from "actions/calonPeminjamAction";
import { fetchConnectedPendana } from "actions/daftarPendanaAction";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "650px",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  overflowY: "scroll",
  p: 4,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <MDBox sx={{ p: 3 }}>{children}</MDBox>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function SelectFintech(props) {
  const platformFeeTypeOptions = [
    {
      "id": 1,
      "value": "value_based",
      "label": "Berdasarkan Nilai Pendanaan"
    },
    {
      "id": 2,
      "value": "admin_fee_based",
      "label": "Berdasarkan Biaya Admin"
    },
  ]

  const commissionTypeOptions = [
    {
      "id": 1,
      "value": "value_based",
      "label": "Berdasarkan Nilai Pendanaan"
    },
    {
      "id": 2,
      "value": "admin_fee_based",
      "label": "Berdasarkan Biaya Admin"
    },

  ]

  const unit = [
    {
      "id": 1,
      "value": "percentage",
      "label": "Persentase"
    },
    {
      "id": 2,
      "value": "fixed",
      "label": "Angka Tetap"
    },

  ]

  const dispatch = useDispatch();
  const params = useParams();
  const [arrFinId, setArrFinId] = useState([]);
  const [platformFee, setPlatformFee] = useState(0);
  const [platformFeeUnit, setPlatformFeeUnit] = useState(unit[0]);
  const [platformFeeType, setPlatformFeeType] = useState(platformFeeTypeOptions[0]);

  const [commissionPreFee, setCommissionPreFee] = useState(0);
  const [commissionPreFeeType, setCommissionPreFeeType] = useState(commissionTypeOptions[1]);
  const [commissionPreFeeUnit, setCommissionPreFeeUnit] = useState(unit[0]);

  const [commissionPostFee, setCommissionPostFee] = useState(0);
  const [commissionPostFeeType, setCommissionPostFeeType] = useState(commissionTypeOptions[1]);
  const [commissionPostFeeUnit, setCommissionPostFeeUnit] = useState(unit[0]);

  const [platformFeeMonthlyRecurring, setPlatformFeeMonthlyRecurring] = useState(false);

  const [page, setPage] = useState(1);
  const [error, setError] = useState(false);
  const [dialogError, setDialogError] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [transporterName, setTransporterName] = useState(props.transporterName);
  const [fintechName, setFintechName] = useState("");

  const anFintechRelationList = useSelector((state) => state.anFintechRelationList, shallowEqual);
  const anFintechRelationListPagination = useSelector((state) => state.anFintechRelationListPagination, shallowEqual);

  const message = useSelector((state) => state.message, shallowEqual);

  const onMonthlyRecurringChange = (e, v) => {
    setPlatformFeeMonthlyRecurring(v);
  }

  const onCommissionPreTypeChange = (e, v) => {
    setCommissionPreFeeType(v);
  }

  const onCommissionPostTypeChange = (e, v) => {
    setCommissionPostFeeType(v);
  }

  const onPlatformFeeUnitChange = (e, v) => {
    setPlatformFeeUnit(v);
  }

  const onCommissionPreUnitChange = (e, v) => {
    setCommissionPreFeeUnit(v);
  }

  const onCommissionPostUnitChange = (e, v) => {
    setCommissionPostFeeUnit(v);
  }


  const handleClickOpen = () => {
    if (platformFee == undefined) {
      setError("Platform Fee harus diisi");
      return
    }
    if (platformFeeUnit == undefined) {
      setError("Unit Platform Fee harus diisi");
      return
    }
    // if (platformFeeType == undefined) {
    //   setError("Jenis Platform Fee harus dipilih");
    //   return
    // }

    if (commissionPreFee == undefined) {
      setError("Komisi harus diisi");
      return
    }
    if (commissionPreFeeUnit == undefined) {
      setError("Unit Komisi harus diisi");
      return
    }
    if (commissionPreFeeType == undefined) {
      setError("Jenis Komisi harus dipilih");
      return
    }


    if (commissionPostFee == undefined) {
      setError("Komisi harus diisi");
      return
    }
    if (commissionPostFeeUnit == undefined) {
      setError("Unit Komisi harus diisi");
      return
    }
    if (commissionPostFeeType == undefined) {
      setError("Jenis Komisi harus dipilih");
      return
    }


    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogError(false);
  };


  useEffect(() => {
    const id = props.relationId
    const companyId = decodeURIComponent(params.id);
    if (props.kyc) {
      dispatch(fetchAllpendana({ companyId, page }));
    } else if (props.viewOnly) {
      dispatch(fetchConnectedPendana({ id, page }));
    } else {
      dispatch(fetchRelationPendana({ id, page }));
    }
  }, [page]);


  useEffect(() => {
    switch (message.status) {
      case "ERROR_CREATE_CUSTOMER_RELATION":
        setDialogError("Maaf, layanan kami sedang mengalami gangguan. Silakan coba kembali beberapa saat lagi.");
      default:
    }
  }, [message]);


  useEffect(() => {
  }, [arrFinId]);

  const handleSubmit = () => {

    let other = [];
    if (platformFeeMonthlyRecurring !== undefined) {
      other = [...other, {
        name: "platform_fee_monthly_recurring",
        type: "number",
        value: platformFeeMonthlyRecurring ? 1 : 0,
      }]
    }

    if (props.kyc) {
      dispatch(createCustomerRelationKyc(arrFinId[2], parseInt(params.id), {
        platform_fee_type: platformFeeType.value,
        platform_fee_unit: platformFeeUnit.value,
        platform_fee: parseFloat(platformFee),

        commission_pre_fee_type: commissionPreFeeType.value,
        commission_pre_fee_unit: commissionPreFeeUnit.value,
        commission_pre_fee: parseFloat(commissionPreFee),

        commission_post_fee_type: commissionPostFeeType.value,
        commission_post_fee_unit: commissionPostFeeUnit.value,
        commission_post_fee: parseFloat(commissionPostFee),
        other,
      }));
    } else {
      dispatch(createCustomerRelation(arrFinId));
    }
  };

  return (
    <div>
      <MDBox sx={style}>
        <Grid container direction="column" justifyContent="space-around" spacing={3}>
          <Grid item>
            <MDBox display="flex" justifyContent="space-between">
              <MDBox>
                <Icon fontSize="large" sx={{ pt: 1, ml: 3 }} color="info">
                  group
                </Icon>
                <MDTypography ml={2} variant="title" fontWeight="medium">
                  Daftar Pendana
                </MDTypography>
              </MDBox>
              <Icon sx={{ cursor: "pointer" }} onClick={props.onClose} fontSize="large">
                clear
              </Icon>
            </MDBox>
          </Grid>
          <Grid item>
            <MDBox sx={{ width: "100%" }}>
              <LVDaftarRelationFintech
                rows={anFintechRelationList}
                onSelectionModelChange={(ids) => {
                  const selectedRowsData = ids.map((id) => anFintechRelationList.find((row) => row.id === id));
                  if (selectedRowsData[2] === undefined) {
                    return;
                  }
                  setFintechName(selectedRowsData[2].name);
                  setArrFinId(ids);
                }}
                radioSelection={props.viewOnly ? false : true}
                selectPage={(i) => setPage(parseInt(i.target.textContent))}
                lastPage={() => setPage(anFintechRelationListPagination.total_pages)}
                nextPage={() => setPage(anFintechRelationListPagination.next_page)}
                prevPage={() => setPage(anFintechRelationListPagination.prev_page)}
                totalPage={anFintechRelationListPagination.total_pages}
                firstPage={() => setPage(1)}
                pageSize={5}
                currentPage={page}
                viewOnly={props.viewOnly}
                kyc={props.kyc}
              />
              {!props.viewOnly ? (
                <>
                  <MDBox display="flex" mt={3} justifyContent="space-between">
                    <MDBox borderRadius="xl" mr={1} width="100%" p={2} sx={{ border: "1px solid #e1e1e1" }}>
                      <MDTypography color="black" fontSize="16px" fontWeight="medium" pb={2}>
                        Platform Fee
                      </MDTypography>

                      {/* <MDTypography color="black" pb={1} sx={{ fontSize: 12 }}>
                        Silahkan pilih skema Platform Fee
                      </MDTypography>
                      <Grid item mb={2}>
                        <Autocomplete
                          disablePortal
                          id="platform_fee_type"
                          options={platformFeeTypeOptions}
                          value={platformFeeType}
                          isOptionEqualToValue={(option, value) =>
                            option.value === value.value
                          }
                          onChange={onPlatformFeeTypeChange}
                          sx={{ width: 300 }}
                          renderInput={(params) => <TextField {...params} label="Jenis" />}
                        />
                      </Grid> */}
                      <Grid container spacing={2}>

                        <Grid item>
                          <MDTypography color="black" pb={1} sx={{ fontSize: 12 }}>
                            Silahkan isi besaran Platform Fee
                          </MDTypography>
                          <Grid container justifyContent="start" mt={0}>
                            <Grid item>
                              <MDInput
                                id="platform_fee"
                                name="platform_fee"
                                label="Platform Fee"
                                value={platformFee}
                                required
                                onChange={(e) => {
                                  setPlatformFee(e.target.value);
                                }}
                                sx={{ width: 300 }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item>
                          <MDTypography color="black" pb={1} sx={{ fontSize: 12 }}>
                            Silahkan isi unit Platform Fee
                          </MDTypography>
                          <Grid item>
                            <Autocomplete
                              disablePortal
                              id="platform_fee_unit"
                              options={unit}
                              value={platformFeeUnit}
                              isOptionEqualToValue={(option, value) =>
                                option.value === value.value
                              }
                              onChange={onPlatformFeeUnitChange}
                              sx={{ width: 300 }}
                              renderInput={(params) => <TextField {...params} label="Unit" />}
                            />
                          </Grid>
                        </Grid>

                        <Grid item md={12}>
                          <Grid container>
                            <Grid item>
                              <MDBox borderRadius="xl" sx={{ width: "100%", px: 1, pl: 2, border: "1px solid #e1e1e1" }}>

                                <FormGroup>
                                  <FormControlLabel
                                    control={<Checkbox
                                      checked={platformFeeMonthlyRecurring}
                                      onChange={onMonthlyRecurringChange}
                                    />}
                                    label={<MDTypography color="black" fontSize="12px" pb={0}>
                                      Biaya platform dibebankan setiap bulan
                                    </MDTypography>}
                                  />
                                </FormGroup>
                              </MDBox>

                            </Grid>
                          </Grid>
                        </Grid>


                      </Grid>
                    </MDBox>
                  </MDBox>


                  <MDBox display="flex" mt={3} justifyContent="space-between">
                    <MDBox borderRadius="xl" mr={1} width="100%" p={2} sx={{ border: "1px solid #e1e1e1" }}>
                      <MDTypography color="black" fontSize="16px" fontWeight="medium" pb={2}>
                        Komisi
                      </MDTypography>

                      <Grid container spacing={2}>
                        <Grid item sm={12} md={6}>
                          <MDBox borderRadius="xl" mr={1} width="100%" p={2} sx={{ border: "1px solid #e1e1e1" }}>

                            <MDTypography color="black" fontSize="14px" pb={2}>
                              Awal Pencairan
                            </MDTypography>

                            <Grid container>
                              <Grid item>
                                <MDTypography color="black" pb={1} sx={{ fontSize: 12 }}>
                                  Silahkan pilih skema Komisi
                                </MDTypography>
                                <Grid item mb={2}>
                                  <Autocomplete
                                    disablePortal
                                    id="commission_pre_type"
                                    options={commissionTypeOptions}
                                    value={commissionPreFeeType}
                                    isOptionEqualToValue={(option, value) =>
                                      option.value === value.value
                                    }
                                    onChange={onCommissionPreTypeChange}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Jenis" />}
                                  />
                                  {/* <MDTypography color="error" sx={{ fontSize: 10 }}>{showError.top ? helperMsg.top : ''} </MDTypography> */}
                                </Grid>

                                <MDTypography color="black" pb={1} sx={{ fontSize: 12 }}>
                                  Silahkan isi unit Komisi
                                </MDTypography>
                                <Grid item mb={2}>
                                  <Autocomplete
                                    disablePortal
                                    id="commission_pre_unit"
                                    options={unit}
                                    value={commissionPreFeeUnit}
                                    isOptionEqualToValue={(option, value) =>
                                      option.value === value.value
                                    }
                                    onChange={onCommissionPreUnitChange}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Unit" />}
                                  />
                                </Grid>

                                <MDTypography color="black" pb={1} sx={{ fontSize: 12 }}>
                                  Silahkan isi besaran Komisi
                                </MDTypography>
                                <Grid container justifyContent="start" mt={0}>
                                  <Grid item>
                                    <MDInput
                                      id="commission_pre_fee"
                                      name="commission_pre_fee"
                                      label="Komisi"
                                      value={commissionPreFee}
                                      required
                                      onChange={(e) => {
                                        setCommissionPreFee(e.target.value);
                                      }}
                                      sx={{ width: "100%" }}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </MDBox>

                        </Grid>
                        <Grid item sm={12} md={6}>
                          <MDBox borderRadius="xl" mr={1} width="100%" p={2} sx={{ border: "1px solid #e1e1e1" }}>

                            <MDTypography color="black" fontSize="14px" pb={2}>
                              Akhir Pendanaan
                            </MDTypography>

                            <Grid container>
                              <Grid item>
                                <MDTypography color="black" pb={1} sx={{ fontSize: 12 }}>
                                  Silahkan pilih skema Komisi
                                </MDTypography>
                                <Grid item mb={2}>
                                  <Autocomplete
                                    disablePortal
                                    id="commission_post_type"
                                    options={commissionTypeOptions}
                                    value={commissionPostFeeType}
                                    isOptionEqualToValue={(option, value) =>
                                      option.value === value.value
                                    }
                                    onChange={onCommissionPostTypeChange}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Jenis" />}
                                  />
                                  {/* <MDTypography color="error" sx={{ fontSize: 10 }}>{showError.top ? helperMsg.top : ''} </MDTypography> */}
                                </Grid>

                                <MDTypography color="black" pb={1} sx={{ fontSize: 12 }}>
                                  Silahkan isi unit Komisi
                                </MDTypography>
                                <Grid item mb={2}>
                                  <Autocomplete
                                    disablePortal
                                    id="commission_post_unit"
                                    options={unit}
                                    value={commissionPostFeeUnit}
                                    isOptionEqualToValue={(option, value) =>
                                      option.value === value.value
                                    }
                                    onChange={onCommissionPostUnitChange}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Unit" />}
                                  />
                                </Grid>

                                <MDTypography color="black" pb={1} sx={{ fontSize: 12 }}>
                                  Silahkan isi besaran Komisi
                                </MDTypography>
                                <Grid container justifyContent="start" mt={0}>
                                  <Grid item>
                                    <MDInput
                                      id="commission_post_fee"
                                      name="commission_post_fee"
                                      label="Komisi"
                                      value={commissionPostFee}
                                      required
                                      onChange={(e) => {
                                        setCommissionPostFee(e.target.value);
                                      }}
                                      sx={{ width: "100%" }}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </MDBox>
                        </Grid>
                      </Grid>




                    </MDBox>
                  </MDBox>

                  <Grid container justifyContent="end" mt={2}>
                    <Grid item>
                      <MDButton variant="contained" color="info" onClick={() => handleClickOpen()}>
                        Submit
                      </MDButton>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="end" mt={2}>
                    <Grid item>
                      {error ? (
                        <MDTypography sx={{ fontSize: "10px", mb: 1 }} color="error">
                          {error}
                        </MDTypography>
                      ) : null}
                    </Grid>
                  </Grid>
                </>
              ) : null}
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Konfirmasi
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Hubungkan Perusahaan {transporterName} dengan Fintech {fintechName}?
          </DialogContentText>
          <Grid container justifyContent="end" mt={2}>
            <Grid item>
              <MDTypography sx={{ fontSize: "10px", mb: 1 }} color="error">
                {dialogError ? (
                  <>{dialogError}</>
                ) : null}
              </MDTypography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton variant="contained" color="error" onClick={handleCloseDialog}>Batal</MDButton>
          <MDButton variant="contained" color="info" onClick={handleSubmit} autoFocus>
            Lanjut
          </MDButton>
        </DialogActions>

      </Dialog>

    </div>
  );
}

export default SelectFintech;
