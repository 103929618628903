/* eslint-disable no-nested-ternary */
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import validator from "validator";

import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import {
  createNewCustomer,
  deleteKYCDocument,
  fetchAdminRelationDocs,
  fetchKYCDocs,
  fetchRelationDocs,
  getCompanyKYCStatus,
  saveRelationDocument,
} from "../../actions/companyAction";
import { Icon } from "@mui/material";
import LVFileInputMultiple from "components/LVFileInputMultiple";
import { useParams } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "650px",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  overflowY: "scroll",
  p: 4,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <MDBox sx={{ p: 3 }}>{children}</MDBox>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function PartnerHistory(props) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState("");
  const [isApproved, setApproved] = useState(false);
  const [npwp, setNPWP] = useState("");
  const [namaPerusahaan, setNamaPerusahaan] = useState("");
  const [spk, setSpk] = useState(null);
  const [spk2, setSpk2] = useState(null);
  const [spk3, setSpk3] = useState(null);
  const [spk4, setSpk4] = useState(null);
  const [spk5, setSpk5] = useState(null);
  const [spk6, setSpk6] = useState(null);
  const [po, setPo] = useState(null);
  const [po2, setPo2] = useState(null);
  const [po3, setPo3] = useState(null);
  const [po4, setPo4] = useState(null);
  const [po5, setPo5] = useState(null);
  const [po6, setPo6] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [invoice2, setInvoice2] = useState(null);
  const [invoice3, setInvoice3] = useState(null);
  const [invoice4, setInvoice4] = useState(null);
  const [invoice5, setInvoice5] = useState(null);
  const [invoice6, setInvoice6] = useState(null);
  const [invoice7, setInvoice7] = useState(null);
  const [invoice8, setInvoice8] = useState(null);
  const [invoice9, setInvoice9] = useState(null);
  const [invoice10, setInvoice10] = useState(null);
  const [showError, setShowError] = useState({
    npwp: false,
    tipe: false,
    namaPerusahaan: false,
    alamat: false,
    namaPIC: false,
    teleponPIC: false,
    validEmail: false,
    validTeleponPIC: false,
    validTelepon: false,
    validEmailPIC: false,
    all: false,
    fail: false,
    failDetail: false,
  });
  const [helperMsg, setHelperMsg] = useState({
    npwp: "Nomor NPWP harus diisi",
    tipe: "Badan Usaha harus dipilih",
    namaPerusahaan: "Nama Perusahaan harus diisi",
    alamat: "Alamat Perusahaan harus diisi",
    namaPIC: "Nama PIC harus diisi",
    teleponPIC: "Telepon PIC harus diisi",
    validTeleponPIC: "Format telepon tidak valid",
    validTelepon: "Format telepon tidak valid",
    validEmailPIC: "Format email tidak valid",
    validEmail: "Format email tidak valid",
    all: "Masih terdapat informasi wajib yang kosong, silakan diperiksa kembali.",
    fail: "Gagal membuat perusahaan baru. Cek apakah NPWP/nomor telepon/email pernah digunakan",
    failDetail: "",
  });

  const listRelationDocs = useSelector((state) => state.listRelationDocs, shallowEqual);
  const message = useSelector((state) => state.message, shallowEqual);
  const params = useParams();
  const { id } = props;

  useEffect(() => {
    if (props.admin) {
      dispatch(fetchAdminRelationDocs(params.id, props.relationId));
    } else {
      dispatch(fetchRelationDocs());
    }
  }, []);

  useEffect(() => {
    switch (message.status) {
      case "REJECT_RIWAYAT_DOCUMENT":
        dispatch(fetchAdminRelationDocs(params.id, props.relationId));
        return;
      case "APPROVE_RIWAYAT_DOCUMENT":
        dispatch(fetchAdminRelationDocs(params.id, props.relationId));
        return;
      case "DELETE_RELATION_DOCUMENT":
        dispatch(fetchRelationDocs());
        return;
      case "SUCCESS_SAVE_RELATION_DOCUMENT":
        setLoading("");
        dispatch(fetchRelationDocs());
      default:
    }
  }, [message]);

  const refs = {
    spk: useRef(),
    spk_2: useRef(),
    spk_3: useRef(),
    spk_4: useRef(),
    spk_5: useRef(),
    spk_6: useRef(),
    po: useRef(),
    po_2: useRef(),
    po_3: useRef(),
    po_4: useRef(),
    po_5: useRef(),
    po_6: useRef(),
    invoice: useRef(),
    invoice_2: useRef(),
    invoice_3: useRef(),
    invoice_4: useRef(),
    invoice_5: useRef(),
    invoice_6: useRef(),
  };

  useEffect(() => {
    // NAME & NPWP
    setNamaPerusahaan(props.name);
    setNPWP(props.npwp);
  }, []);

  useEffect(() => {
    const statusValues = Object.values(listRelationDocs);
    const approved = statusValues.some((item) => item.status === "approved");
    setApproved(approved);
  }, [listRelationDocs]);

  useEffect(() => {
    // SPK FILE
    if (listRelationDocs[`spk_${id}_1`] !== undefined) {
      setSpk(listRelationDocs[`spk_${id}_1`]);
    } else {
      setSpk(null);
    }
    if (listRelationDocs[`spk_${id}_2`] !== undefined) {
      setSpk2(listRelationDocs[`spk_${id}_2`]);
    } else {
      setSpk2(null);
    }
    if (listRelationDocs[`spk_${id}_3`] !== undefined) {
      setSpk3(listRelationDocs[`spk_${id}_3`]);
    } else {
      setSpk3(null);
    }
    if (listRelationDocs[`spk_${id}_4`] !== undefined) {
      setSpk4(listRelationDocs[`spk_${id}_4`]);
    } else {
      setSpk4(null);
    }
    if (listRelationDocs[`spk_${id}_5`] !== undefined) {
      setSpk5(listRelationDocs[`spk_${id}_5`]);
    } else {
      setSpk5(null);
    }
    if (listRelationDocs[`spk_${id}_6`] !== undefined) {
      setSpk6(listRelationDocs[`spk_${id}_6`]);
    } else {
      setSpk6(null);
    }
  }, [listRelationDocs]);

  useEffect(() => {
    // PO file
    if (listRelationDocs[`po_${id}_1`] !== undefined) {
      setPo(listRelationDocs[`po_${id}_1`]);
    } else {
      setPo(null);
    }
    if (listRelationDocs[`po_${id}_2`] !== undefined) {
      setPo2(listRelationDocs[`po_${id}_2`]);
    } else {
      setPo2(null);
    }
    if (listRelationDocs[`po_${id}_3`] !== undefined) {
      setPo3(listRelationDocs[`po_${id}_3`]);
    } else {
      setPo3(null);
    }
    if (listRelationDocs[`po_${id}_4`] !== undefined) {
      setPo4(listRelationDocs[`po_${id}_4`]);
    } else {
      setPo4(null);
    }
    if (listRelationDocs[`po_${id}_5`] !== undefined) {
      setPo5(listRelationDocs[`po_${id}_5`]);
    } else {
      setPo5(null);
    }
    if (listRelationDocs[`po_${id}_6`] !== undefined) {
      setPo6(listRelationDocs[`po_${id}_6`]);
    } else {
      setPo6(null);
    }
  }, [listRelationDocs]);

  useEffect(() => {
    // Invoice file
    if (listRelationDocs[`invoice_${id}_1`] !== undefined) {
      setInvoice(listRelationDocs[`invoice_${id}_1`]);
    } else {
      setInvoice(null);
    }
    if (listRelationDocs[`invoice_${id}_2`] !== undefined) {
      setInvoice2(listRelationDocs[`invoice_${id}_2`]);
    } else {
      setInvoice2(null);
    }
    if (listRelationDocs[`invoice_${id}_3`] !== undefined) {
      setInvoice3(listRelationDocs[`invoice_${id}_3`]);
    } else {
      setInvoice3(null);
    }
    if (listRelationDocs[`invoice_${id}_4`] !== undefined) {
      setInvoice4(listRelationDocs[`invoice_${id}_4`]);
    } else {
      setInvoice4(null);
    }
    if (listRelationDocs[`invoice_${id}_5`] !== undefined) {
      setInvoice5(listRelationDocs[`invoice_${id}_5`]);
    } else {
      setInvoice5(null);
    }
    if (listRelationDocs[`invoice_${id}_6`] !== undefined) {
      setInvoice6(listRelationDocs[`invoice_${id}_6`]);
    } else {
      setInvoice6(null);
    }
    if (listRelationDocs[`invoice_${id}_7`] !== undefined) {
      setInvoice7(listRelationDocs[`invoice_${id}_7`]);
    } else {
      setInvoice7(null);
    }
    if (listRelationDocs[`invoice_${id}_8`] !== undefined) {
      setInvoice8(listRelationDocs[`invoice_${id}_8`]);
    } else {
      setInvoice8(null);
    }
    if (listRelationDocs[`invoice_${id}_9`] !== undefined) {
      setInvoice9(listRelationDocs[`invoice_${id}_9`]);
    } else {
      setInvoice9(null);
    }
    if (listRelationDocs[`invoice_${id}_10`] !== undefined) {
      setInvoice10(listRelationDocs[`invoice_${id}_10`]);
    } else {
      setInvoice10(null);
    }
  }, [listRelationDocs]);

  const onChangeSpk = (e) => {
    setLoading("spk");
    if (listRelationDocs[`spk_${id}_1`] === undefined) {
      dispatch(saveRelationDocument({ type: `spk_${id}_1`, file: e.target.files[0], id }));
      if (refs.spk.current !== undefined) {
        refs.spk.current.value = "";
      }
    } else if (listRelationDocs[`spk_${id}_2`] === undefined) {
      dispatch(saveRelationDocument({ type: `spk_${id}_2`, file: e.target.files[0], id }));
      if (refs.spk_2.current !== undefined) {
        refs.spk_2.current.value = "";
      }
    } else if (listRelationDocs[`spk_${id}_3`] === undefined) {
      dispatch(saveRelationDocument({ type: `spk_${id}_3`, file: e.target.files[0], id }));
      if (refs.spk_3.current !== undefined) {
        refs.spk_3.current.value = "";
      }
    } else if (listRelationDocs[`spk_${id}_4`] === undefined) {
      dispatch(saveRelationDocument({ type: `spk_${id}_4`, file: e.target.files[0], id }));
      if (refs.spk_4.current !== undefined) {
        refs.spk_4.current.value = "";
      }
    } else if (listRelationDocs[`spk_${id}_5`] === undefined) {
      dispatch(saveRelationDocument({ type: `spk_${id}_5`, file: e.target.files[0], id }));
      if (refs.spk_5.current !== undefined) {
        refs.spk_5.current.value = "";
      }
    } else if (listRelationDocs[`spk_${id}_6`] === undefined) {
      dispatch(saveRelationDocument({ type: `spk_${id}_6`, file: e.target.files[0], id }));
      if (refs.spk_6.current !== undefined) {
        refs.spk_6.current.value = "";
      }
    }
  };

  const onChangePo = (e) => {
    setLoading("po");
    if (listRelationDocs[`po_${id}_1`] === undefined) {
      dispatch(saveRelationDocument({ type: `po_${id}_1`, file: e.target.files[0], id }));
      if (refs.po.current !== undefined) {
        refs.po.current.value = "";
      }
    } else if (listRelationDocs[`po_${id}_2`] === undefined) {
      dispatch(saveRelationDocument({ type: `po_${id}_2`, file: e.target.files[0], id }));
      if (refs.po_2.current !== undefined) {
        refs.po_2.current.value = "";
      }
    } else if (listRelationDocs[`po_${id}_3`] === undefined) {
      dispatch(saveRelationDocument({ type: `po_${id}_3`, file: e.target.files[0], id }));
      if (refs.po_3.current !== undefined) {
        refs.po_3.current.value = "";
      }
    } else if (listRelationDocs[`po_${id}_4`] === undefined) {
      dispatch(saveRelationDocument({ type: `po_${id}_4`, file: e.target.files[0], id }));
      if (refs.po_4.current !== undefined) {
        refs.po_4.current.value = "";
      }
    } else if (listRelationDocs[`po_${id}_5`] === undefined) {
      dispatch(saveRelationDocument({ type: `po_${id}_5`, file: e.target.files[0], id }));
      if (refs.po_5.current !== undefined) {
        refs.po_5.current.value = "";
      }
    } else if (listRelationDocs[`po_${id}_6`] === undefined) {
      dispatch(saveRelationDocument({ type: `po_${id}_6`, file: e.target.files[0], id }));
      if (refs.po_6.current !== undefined) {
        refs.po_6.current.value = "";
      }
    }
  };

  const onChangeInvoice = (e) => {
    setLoading("invoice");
    if (listRelationDocs[`invoice_${id}_1`] === undefined) {
      dispatch(
        saveRelationDocument({
          type: `invoice_${id}_1`,
          file: e.target.files[0],
          id,
        })
      );
      if (refs.invoice.current !== undefined) {
        refs.invoice.current.value = "";
      }
    } else if (listRelationDocs[`invoice_${id}_2`] === undefined) {
      dispatch(
        saveRelationDocument({
          type: `invoice_${id}_2`,
          file: e.target.files[0],
          id,
        })
      );
      if (refs.invoice_2.current !== undefined) {
        refs.invoice_2.current.value = "";
      }
    } else if (listRelationDocs[`invoice_${id}_3`] === undefined) {
      dispatch(
        saveRelationDocument({
          type: `invoice_${id}_3`,
          file: e.target.files[0],
          id,
        })
      );
      if (refs.invoice_3.current !== undefined) {
        refs.invoice_3.current.value = "";
      }
    } else if (listRelationDocs[`invoice_${id}_4`] === undefined) {
      dispatch(
        saveRelationDocument({
          type: `invoice_${id}_4`,
          file: e.target.files[0],
          id,
        })
      );
      if (refs.invoice_4.current !== undefined) {
        refs.invoice_4.current.value = "";
      }
    } else if (listRelationDocs[`invoice_${id}_5`] === undefined) {
      dispatch(
        saveRelationDocument({
          type: `invoice_${id}_5`,
          file: e.target.files[0],
          id,
        })
      );
      if (refs.invoice_5.current !== undefined) {
        refs.invoice_5.current.value = "";
      }
    } else if (listRelationDocs[`invoice_${id}_6`] === undefined) {
      dispatch(
        saveRelationDocument({
          type: `invoice_${id}_6`,
          file: e.target.files[0],
          id,
        })
      );
      if (refs.invoice_6.current !== undefined) {
        refs.invoice_6.current.value = "";
      }
    } else if (listRelationDocs[`invoice_${id}_7`] === undefined) {
      dispatch(
        saveRelationDocument({
          type: `invoice_${id}_7`,
          file: e.target.files[0],
          id,
        })
      );
      if (refs.invoice_7.current !== undefined) {
        refs.invoice_7.current.value = "";
      }
    } else if (listRelationDocs[`invoice_${id}_8`] === undefined) {
      dispatch(
        saveRelationDocument({
          type: `invoice_${id}_8`,
          file: e.target.files[0],
          id,
        })
      );
      if (refs.invoice_8.current !== undefined) {
        refs.invoice_8.current.value = "";
      }
    } else if (listRelationDocs[`invoice_${id}_9`] === undefined) {
      dispatch(
        saveRelationDocument({
          type: `invoice_${id}_9`,
          file: e.target.files[0],
          id,
        })
      );
      if (refs.invoice_9.current !== undefined) {
        refs.invoice_9.current.value = "";
      }
    } else if (listRelationDocs[`invoice_${id}_10`] === undefined) {
      dispatch(
        saveRelationDocument({
          type: `invoice_${id}_10`,
          file: e.target.files[0],
          id,
        })
      );
      if (refs.invoice_10.current !== undefined) {
        refs.invoice_10.current.value = "";
      }
    }
  };

  useEffect(() => {
    switch (message.status) {
      case "SUCCESS_NEW_CUSTOMER":
        props.onClose();
        return;
      case "ERROR":
        setHelperMsg({ ...helperMsg, failDetail: message.message });
        setShowError({
          ...showError,
          fail: true,
          failDetail: true,
        });

      default:
    }
  }, [message]);

  // const hasApproved = listRelationDocs.some((doc) => doc.status === "approved");

  return (
    <div>
      <MDBox sx={style}>
        <Grid container direction="column" justifyContent="space-around" spacing={3}>
          <Grid item>
            <MDBox display="flex" justifyContent="space-between">
              <MDBox>
                <Icon fontSize="large" sx={{ pt: 1, ml: 3 }} color="info">
                  group
                </Icon>
                <MDTypography ml={2} variant="title" fontWeight="medium">
                  Riwayat Kerjasama
                </MDTypography>
              </MDBox>
              <Icon sx={{ cursor: "pointer" }} onClick={props.onClose} fontSize="large">
                clear
              </Icon>
            </MDBox>
          </Grid>
          <Grid item>
            <MDBox sx={{ width: "100%" }}>
              <Grid container direction="row" justifyContent="space-around" spacing={2} mt={1}>
                <Grid item>
                  <MDInput
                    id="npwp"
                    name="npwp"
                    label="NPWP"
                    value={npwp}
                    onChange={(e) => setNPWP(e.target.value)}
                    // onBlur={onNPWPBlur}
                    placeholder="Isi dengan NPWP milik pelanggan"
                    sx={{ width: 300 }}
                    disabled
                  />
                  <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                    {showError.npwp ? helperMsg.npwp : ""}{" "}
                  </MDTypography>
                </Grid>
                <Grid item>
                  <MDInput
                    id="nama"
                    name="nama"
                    label="Nama Pelanggan"
                    value={namaPerusahaan}
                    // onBlur={onNamaBlur}
                    onChange={(e) => setNamaPerusahaan(e.target.value)}
                    placeholder="Isi dengan nama perusahaan atau perorangan"
                    sx={{ width: 300 }}
                    disabled
                  />
                  <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                    {showError.namaPerusahaan ? helperMsg.namaPerusahaan : ""}{" "}
                  </MDTypography>
                </Grid>
              </Grid>

              <Grid
                container
                // alignItems="center"
                justifyContent="space-around"
                spacing={2}
                mt={-3}
              >
                <Grid item>
                  <LVFileInputMultiple
                    srcImage={[spk, spk2, spk3, spk4, spk5, spk6]}
                    height={200}
                    relation
                    width={640}
                    fontSize={10}
                    id="SPK"
                    viewOnly={props.admin ? true : false}
                    onChangeFile={onChangeSpk}
                    text1="Unggah SPK"
                    text2="Maksimal ukuran 5MB PNG, JPEG, atau PDF"
                    text3=""
                    title="SPK"
                    titleFontSize={12}
                    accept=".png,.jpg,.pdf"
                    refinput={refs.spk}
                    approvable={props.admin ? true : false}
                    isLoading={isLoading === "spk"}
                  />
                </Grid>
                <Grid item>
                  <LVFileInputMultiple
                    srcImage={[po, po2, po3, po4, po5, po6]}
                    height={200}
                    width={640}
                    relation
                    fontSize={10}
                    id="PO"
                    viewOnly={props.admin ? true : false}
                    onChangeFile={onChangePo}
                    text1="Unggah PO"
                    text2="Maksimal ukuran 5MB PNG, JPEG, atau PDF"
                    text3=""
                    title="PO"
                    titleFontSize={12}
                    accept=".png,.jpg,.pdf"
                    refinput={refs.po}
                    approvable={props.admin ? true : false}
                    isLoading={isLoading === "po"}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                // alignItems="center"
                justifyContent="space-around"
                spacing={2}
                mt={1}
              >
                <Grid item>
                  <LVFileInputMultiple
                    srcImage={[invoice, invoice2, invoice3, invoice4, invoice5, invoice6, invoice7, invoice8, invoice9, invoice10]}
                    height={200}
                    relation
                    width={640}
                    fontSize={10}
                    id="invoice"
                    viewOnly={props.admin ? true : false}
                    onChangeFile={onChangeInvoice}
                    text1="Unggah invoice"
                    text2="Maksimal ukuran 5MB PNG, JPEG, atau PDF"
                    text3=""
                    title="Invoice"
                    titleFontSize={12}
                    accept=".png,.jpg,.pdf"
                    refinput={refs.invoice}
                    approvable={props.admin ? true : false}
                    isLoading={isLoading === "invoice"}
                  />
                </Grid>
                <Grid item>
                  <MDBox sx={{ width: 300 }}></MDBox>
                </Grid>
              </Grid>
              {props.admin && !isApproved ? (
                <MDTypography variant="body3">
                  *Button hubungkan akan muncul ketika ada dokumen yang sudah diapprove atau
                  terverifikasi
                </MDTypography>
              ) : null}

              <Grid container justifyContent="end" mt={3}>
                {props.admin ? (
                  isApproved ? (
                    <Grid item>
                      <MDButton variant="contained" color="info" onClick={props.onAjukan}>
                        Hubungkan
                      </MDButton>
                    </Grid>
                  ) : null
                ) : (
                  <Grid item>
                    <MDButton variant="contained" color="info" onClick={props.onClose}>
                      Tutup
                    </MDButton>
                  </Grid>
                )}
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </div>
  );
}

export default PartnerHistory;
