/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable react/require-default-props */
/* eslint-disable no-fallthrough */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
import { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { Autocomplete, Icon, TextField } from "@mui/material";
import LVListInvoiceSettlement from "components/LVListInvoiceSettlement";
import { createAdminSettlementRequest } from "actions/paymentAction";

import LVSettlementTotalInformation from "components/LVSettlementTotalInformation";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <MDBox sx={{ p: 3 }}>{children}</MDBox>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Settlement(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    height: props.type === "financing" ? "650px" : "500px",
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 24,
    overflowY: "scroll",
    p: 4,
  };

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [fintechReturn, setFintechReturn] = useState(0);
  const [adminFee, setAdminFee] = useState(0);
  const [totalInfo, setTotalInfo] = useState(0);
  const [contractorReturn, setContractorReturn] = useState(0);
  const [fintechAcc, setFintechAcc] = useState(null);
  const [fintechAccName, setFintechAccName] = useState("");
  const [bankFin, setBankFin] = useState("");
  const [bankAbbrFin, setBankAbbrFin] = useState("");
  const [brokerAcc, setBrokerAcc] = useState(null);
  const [brokerAccName, setBrokerAccName] = useState("");
  const [bankBroker, setBankBroker] = useState("");
  const [bankAbbrBroker, setBankAbbrBroker] = useState("");
  const [notValid, setNotValid] = useState(false);
  const [error, setError] = useState(false);
  const [fintechOwnedAcc, setFintechOwnedAcc] = useState([]);
  const [brokerOwnedAcc, setBrokerOwnedAcc] = useState([]);
  const dataDetail = props.data;

  const message = useSelector((state) => state.message, shallowEqual);
  const anBrokerAccount = useSelector((state) => state.anBrokerAccount, shallowEqual);
  const anFintechAccount = useSelector((state) => state.anFintechAccount, shallowEqual);

  useEffect(() => {
    if (anBrokerAccount !== undefined) {
      const bankAcc = anBrokerAccount?.bank_account;
      setBrokerOwnedAcc(bankAcc);
    }
  }, [anBrokerAccount]);
  useEffect(() => {
    if (anFintechAccount !== undefined) {
      const bankAcc = anFintechAccount?.bank_account;
      setFintechOwnedAcc(bankAcc);
    }
  }, [anFintechAccount]);

  const parseFloatDecimal = (number) => {
    return parseFloat(parseFloat(number).toFixed(2));
  }

  useEffect(() => {

    if (dataDetail?.status === "requested") {
      if (dataDetail) {

        let totalFintechRepayment = parseFloatDecimal(dataDetail?.total_fintech_repayment);
        let adminFee = parseFloatDecimal(dataDetail?.admin_fee);
        let contractorReturn = parseFloatDecimal(parseFloatDecimal(dataDetail?.amount) - (totalFintechRepayment + adminFee));

        setFintechReturn(totalFintechRepayment);
        setAdminFee(adminFee);
        setContractorReturn(contractorReturn);
        setTotalInfoCalculator();
      }
    } else {
      setFintechReturn(parseFloatDecimal(dataDetail?.approved_fintech_amount));
      setAdminFee(parseFloatDecimal(dataDetail?.approved_kentara_amount));
      setContractorReturn(parseFloatDecimal(dataDetail?.approved_contractor_amount));
      setTotalInfoCalculator();

    }
  }, [dataDetail]);

  useEffect(() => {
    setTotalInfoCalculator();
  }, [adminFee, fintechReturn, contractorReturn])

  const setTotalInfoCalculator = () => {
    let totalInfo = parseFloatDecimal(fintechReturn) +
      parseFloatDecimal(adminFee) + parseFloatDecimal(contractorReturn);
    setTotalInfo(totalInfo);
  }

  useEffect(() => {
    if (dataDetail?.amount) {
      if (parseFloatDecimal(fintechReturn) +
        parseFloatDecimal(adminFee) + parseFloatDecimal(contractorReturn) !==
        dataDetail?.amount
      ) {
        setNotValid(true);
      } else {
        setNotValid(false);
      }
    }
  }, [notValid, fintechReturn, adminFee, contractorReturn]);

  useEffect(() => {
    switch (message.status) {
      case "SUCCESS_CREATE_REQUEST_SETTLEMENT":
        props.onClose();
        window.location.reload();
      case "ERROR_CREATE_REQUEST_SETTLEMENT":
        setError(true);
      default:
    }
  }, [message]);

  const onAgree = () => {
    const data = {
      company_id: dataDetail?.company_id,
      financing_id: dataDetail?.financing_id,
      fintech_amount: parseFloatDecimal(fintechReturn),
      contractor_amount: parseFloatDecimal(contractorReturn),
      kentara_amount: parseFloatDecimal(adminFee),
      fintech_account: {
        name: fintechAccName,
        number: fintechAcc,
        bank: bankAbbrFin,
      },
      company_account: {
        name: brokerAccName,
        number: brokerAcc,
        bank: bankAbbrBroker,
      },
    };
    dispatch(createAdminSettlementRequest({ data }));
  };

  const bankList = [
    { id: 0, label: "BCA", abbr: "ID_BCA" },
    { id: 1, label: "Mandiri", abbr: "ID_MANDIRI" },
    { id: 2, label: "BNI", abbr: "ID_BNI" },
  ];

  const onChangeBankFintech = (select_bank) => {
    setBankFin(select_bank.label);
    setBankAbbrFin(select_bank.abbr);
  };

  const onChangeBankBroker = (select_bank) => {
    setBankBroker(select_bank.label);
    setBankAbbrBroker(select_bank.abbr);
  };

  useEffect(() => {
    if (bankFin.toUpperCase() === "BCA") {
      const bankBca = fintechOwnedAcc.filter((item) => item.bank === "BCA");
      setBankAbbrFin("ID_BCA");
      setFintechAcc(bankBca[0]?.number);
      setFintechAccName(bankBca[0]?.name);
    } else if (bankFin.toUpperCase() === "MANDIRI") {
      const bankMandiri = fintechOwnedAcc.filter((item) => item.bank === "MANDIRI");
      setBankAbbrFin("ID_MANDIRI");
      setFintechAcc(bankMandiri[0]?.number);
      setFintechAccName(bankMandiri[0]?.name);
    } else if (bankFin.toUpperCase() === "BNI") {
      const bankBni = fintechOwnedAcc.filter((item) => item.bank === "BNI");
      setBankAbbrFin("ID_BNI");
      setFintechAcc(bankBni[0]?.number);
      setFintechAccName(bankBni[0]?.name);
    }
  }, [bankFin]);

  useEffect(() => {
    if (bankBroker.toUpperCase() === "BCA") {
      const bankBca = brokerOwnedAcc.filter((item) => item.bank === "BCA");
      setBankAbbrBroker("ID_BCA");
      setBrokerAcc(bankBca[0]?.number);
      setBrokerAccName(bankBca[0]?.name);
    } else if (bankBroker.toUpperCase() === "MANDIRI") {
      const bankMandiri = brokerOwnedAcc.filter((item) => item.bank === "MANDIRI");
      setBankAbbrBroker("ID_MANDIRI");
      setBrokerAcc(bankMandiri[0]?.number);
      setBrokerAccName(bankMandiri[0]?.name);
    } else if (bankBroker.toUpperCase() === "BNI") {
      const bankBni = brokerOwnedAcc.filter((item) => item.bank === "BNI");
      setBankAbbrBroker("ID_BNI");
      setBrokerAcc(bankBni[0]?.number);
      setBrokerAccName(bankBni[0]?.name);
    }
  }, [bankBroker]);

  return (
    <div>
      {dataDetail?.amount ? (
        <MDBox sx={style}>
          <Grid container direction="column" justifyContent="space-around" spacing={3}>
            <Grid item>
              <MDBox display="flex" justifyContent="space-between">
                <MDBox>
                  <Icon fontSize="large" sx={{ pt: 1, ml: 3 }} color="info">
                    group
                  </Icon>
                  <MDTypography ml={2} variant="title" fontWeight="medium">
                    Detail Settlement Request
                  </MDTypography>
                </MDBox>
                <Icon sx={{ cursor: "pointer" }} onClick={props.onClose} fontSize="large">
                  clear
                </Icon>
              </MDBox>
            </Grid>

            <Grid item>
              <MDBox mt={5} sx={{ width: "100%" }}>
                <Grid
                  width="100%"
                  container
                  direction="row"
                  justifyContent="space-around"
                  spacing={2}
                  mt={5}
                >
                  <Grid item width="750px">
                    <MDBox
                      justifyContent="space-between"
                      display="flex"
                      mb={3}
                      ml={1}
                      py={1}
                      px={4}
                      sx={{ borderRadius: "8px", border: "2px solid #e1e1e1" }}
                    >
                      <MDTypography fontSize="medium">Total Settlement Request</MDTypography>
                      <MDTypography fontSize="medium" fontWeight="bold">
                        {Number(dataDetail?.amount).toLocaleString("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        })}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox sx={{ width: "100%" }}>
                <Grid container direction="row" justifyContent="center" spacing={2}>
                  <Grid item>
                    <MDInput
                      id="borrower"
                      name="borrower"
                      label="Kontraktor / Borrower"
                      disabled
                      value={dataDetail?.company_name}
                      required
                      sx={{ width: 300 }}
                    />
                  </Grid>
                  <Grid item>
                    <MDInput
                      id="status"
                      name="status"
                      label="Status"
                      disabled
                      value={dataDetail?.status}
                      required
                      sx={{ width: 300 }}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="center" spacing={2} mt={1}>
                  <Grid item>
                    <MDInput
                      id="invoiceDate"
                      name="invoiceDate"
                      label="Tanggal Pengajuan Invoice"
                      disabled
                      value={dataDetail?.invoice_created_at}
                      required
                      sx={{ width: 300 }}
                    />
                  </Grid>
                  <Grid item>
                    <MDInput
                      id="requestDate"
                      name="requestDate"
                      label="Tanggal Settlement Request"
                      disabled
                      value={dataDetail?.settlement_created_at}
                      required
                      sx={{ width: 300 }}
                    />
                  </Grid>
                </Grid>
                {props.type === "financing" ? (
                  <>
                    <Grid container direction="row" justifyContent="center" spacing={2} mt={1}>
                      <Grid item>
                        <MDInput
                          id="max_percent"
                          name="max_percent"
                          label="Maksimal Persentase Pengajuan (%)"
                          disabled
                          value="80%"
                          placeholder="Isi Maksimal Persentase Pengajuan (%)"
                          sx={{ width: 300 }}
                        />
                      </Grid>
                      <Grid item sx={{ width: 316 }}>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" justifyContent="center" spacing={2} mt={1}>
                      <Grid item>
                        <MDInput
                          id="daily_interest"
                          name="daily_interest"
                          disabled
                          label="Bunga Harian (%)"
                          value={`${dataDetail?.daily_interest}%`}
                          placeholder="Isi Bunga Harian (%)"
                          sx={{ width: 300 }}
                        />
                      </Grid>
                      <Grid item>
                        <MDInput
                          id="monthly_interest"
                          name="monthly_interest"
                          label="Bunga Bulanan (%)"
                          disabled
                          value={`${dataDetail?.monthly_interest}%`}
                          placeholder="Isi Bunga Bulanan (%)"
                          required
                          sx={{ width: 300 }}
                        />
                      </Grid>
                    </Grid>

                    <Grid container direction="row" justifyContent="center" spacing={2} mt={1}>
                      <Grid item>
                        <MDInput
                          id="1_year_interest"
                          name="1_year_interest"
                          label="Bunga 1 Tahun (%)"
                          disabled
                          value={`${dataDetail?.yearly_interest}%`}
                          placeholder="Isi Bunga 1 Tahun (%)"
                          required
                          sx={{ width: 300 }}
                        />
                      </Grid>
                      <Grid item>
                        <MDInput
                          id="minimum_period_loan"
                          name="minimum_period_loan"
                          label="Durasi Pinjaman Minimum (hari)"
                          disabled
                          value={dataDetail?.minimum_loan_duration}
                          placeholder="Isi Durasi Pinjaman Minimum (hari)"
                          required
                          sx={{ width: 300 }}
                        />
                      </Grid>
                    </Grid>

                    <Grid container direction="row" justifyContent="center" spacing={4} mt={1}>
                      <Grid item>
                        <MDInput
                          id="penalty_monthly"
                          name="penalty_monthly"
                          label="Penalti per 30 hari (%)"
                          disabled
                          value={dataDetail?.monthly_penalty}
                          placeholder="Isi Penalti per 30 hari (%)"
                          required
                          sx={{ width: 300 }}
                        />
                      </Grid>
                      <Grid item sx={{ width: 316 }}>
                      </Grid>
                    </Grid>

                    {dataDetail?.profit_sharing_flag === true ? (
                      <Grid width="100%" container direction="row" justifyContent="center" mt={5}>
                        <Grid item width="750px">
                          <MDBox ml={1} mb={3}>
                            <MDTypography fontSize="medium" mb={1}>
                              Profit Sharing (Bagi Hasil)*
                            </MDTypography>
                            <Grid container direction="row" justifyContent="left" spacing={2} mt={1}>
                              <Grid item>
                                <MDInput
                                  id="profit_sharing_contractor"
                                  name="profit_sharing_contractor"
                                  label="Persentase Kontraktor/Borrower (%)"
                                  disabled
                                  value={dataDetail?.profit_sharing_contractor}
                                  placeholder="Persentase Keuntungan Kontraktor/Borrower"
                                  sx={{ width: 300 }}
                                />
                              </Grid>
                              <Grid item>
                                <MDInput
                                  id="profit_sharing_fintech"
                                  name="profit_sharing_fintech"
                                  disabled
                                  label="Persentase Investor (%)"
                                  value={dataDetail?.profit_sharing_fintech}
                                  placeholder="Persentase Keuntungan Investor (%)"
                                  sx={{ width: 300 }}
                                />
                              </Grid>
                            </Grid>
                          </MDBox>
                        </Grid>
                      </Grid>
                    ) : null}


                    <Grid width="100%" container direction="row" justifyContent="center" mt={5}>
                      <Grid item width="750px">
                        <MDBox ml={1} mb={3}>
                          <MDTypography fontSize="medium" mb={1}>
                            List Invoice
                          </MDTypography>
                          <LVListInvoiceSettlement
                            pageSize={10}
                            autoHeight
                            rowsPerPageOptions={[10]}
                            localeText="Tidak ada daftar invoice."
                            viewOnly
                            rows={props.invoiceData}
                          />
                        </MDBox>
                      </Grid>
                    </Grid>
                  </>
                ) : null}
                <Grid
                  container
                  direction="row"
                  justifyContent={dataDetail?.status === "requested" ? "center" : "start"}
                  spacing={3}
                  mt={1}
                  ml={dataDetail?.status === "requested" ? "" : 12}
                >
                  <Grid item>
                    <MDInput
                      id="return_nominal"
                      name="return_nominal"
                      label="Nominal Pengembalian ke Pendana"
                      value={fintechReturn}
                      onChange={(e) => setFintechReturn(e.target.value)}
                      placeholder="Isi Nominal Pengembalian ke Pendana"
                      required
                      type="number"
                      sx={{ width: dataDetail?.status === "requested" ? 230 : 300 }}
                      InputProps={{
                        startAdornment: "Rp",
                      }}
                      disabled={dataDetail?.status !== "requested"}
                    />
                  </Grid>
                  {dataDetail?.status === "requested" ? (
                    <Grid item>
                      <MDBox display="flex">
                        <Autocomplete
                          disablePortal
                          id="acc_bank"
                          options={bankList}
                          onChange={(e, v) => onChangeBankFintech(v)}
                          sx={{ width: 150, mr: 3, mt: -0.5 }}
                          // onBlur={onTipeBlur}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderInput={(params) => <TextField required {...params} label="Bank" />}
                          disabled={dataDetail?.status !== "requested"}
                        />
                        <MDInput
                          id="acc_number"
                          name="acc_number"
                          label="Nomer rekening"
                          value={parseInt(fintechAcc, 10)}
                          onChange={(e) => setFintechAcc(e.target.value)}
                          placeholder="Nomer rekening"
                          required
                          type="number"
                          sx={{ width: 150, mr: 3 }}
                          disabled={dataDetail?.status !== "requested"}
                        />
                        <MDInput
                          id="acc_name"
                          name="acc_name"
                          label="a/n"
                          value={fintechAccName}
                          onChange={(e) => setFintechAccName(e.target.value)}
                          placeholder="Nama pemilik rekening"
                          required
                          sx={{ width: 150, height: 54 }}
                          disabled={dataDetail?.status !== "requested"}
                        />
                      </MDBox>
                    </Grid>
                  ) : null}
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent={dataDetail?.status === "requested" ? "center" : "start"}
                  spacing={3}
                  mt={1}
                  ml={dataDetail?.status === "requested" ? "" : 12}
                >
                  <Grid item>
                    <MDInput
                      id="return_nominal_contractor"
                      name="return_nominal_contractor"
                      label="Nominal Pengembalian ke Kontraktor"
                      value={contractorReturn}
                      onChange={(e) => setContractorReturn(e.target.value)}
                      placeholder="Isi Nominal Pengembalian ke Kontraktor"
                      required
                      type="number"
                      sx={{ width: dataDetail?.status === "requested" ? 230 : 300 }}
                      InputProps={{
                        startAdornment: "Rp",
                      }}
                      disabled={dataDetail?.status !== "requested"}
                    />
                  </Grid>
                  {dataDetail?.status === "requested" ? (
                    <Grid item>
                      <MDBox display="flex">
                        <Autocomplete
                          disablePortal
                          id="acc_bank"
                          options={bankList}
                          onChange={(e, v) => onChangeBankBroker(v)}
                          sx={{ width: 150, mr: 3, mt: -0.5 }}
                          // onBlur={onTipeBlur}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderInput={(params) => <TextField required {...params} label="Bank" />}
                          disabled={dataDetail?.status !== "requested"}
                        />
                        <MDInput
                          id="acc_number"
                          name="acc_number"
                          label="Nomer rekening"
                          value={parseInt(brokerAcc, 10)}
                          onChange={(e) => setBrokerAcc(e.target.value)}
                          placeholder="Nomer rekening"
                          required
                          type="number"
                          sx={{ width: 150, mr: 3 }}
                          disabled={dataDetail?.status !== "requested"}
                        />
                        <MDInput
                          id="acc_name"
                          name="acc_name"
                          label="a/n"
                          value={brokerAccName}
                          onChange={(e) => setBrokerAccName(e.target.value)}
                          placeholder="Nama pemilik rekening"
                          required
                          sx={{ width: 150 }}
                          disabled={dataDetail?.status !== "requested"}
                        />
                      </MDBox>
                    </Grid>
                  ) : null}
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent={dataDetail?.status === "requested" ? "center" : "start"}
                  spacing={3}
                  mt={1}
                  ml={dataDetail?.status === "requested" ? "" : 12}
                >
                  <Grid item>
                    <MDInput
                      id="admin_fee"
                      name="admin_fee"
                      label="Nominal Biaya Admin"
                      value={adminFee}
                      onChange={(e) => setAdminFee(e.target.value)}
                      placeholder="Isi Nominal Biaya Admin"
                      required
                      type="number"
                      sx={{ width: dataDetail?.status === "requested" ? 230 : 300 }}
                      InputProps={{
                        startAdornment: "Rp",
                      }}
                      disabled={dataDetail?.status !== "requested"}
                    />
                  </Grid>
                  {dataDetail?.status === "requested" ? (
                    <Grid item>
                      <MDBox sx={{ width: 500 }} />
                    </Grid>
                  ) : null}
                </Grid>

                <LVSettlementTotalInformation
                  totalInfo={totalInfo}
                  amount={dataDetail?.amount}
                />


                {dataDetail?.status === "requested" ? (
                  <Grid container justifyContent="end" mt={5}>
                    <Grid item>
                      <MDBox sx={{ width: 300 }} />
                    </Grid>
                    <Grid item>
                      {error ? (
                        <MDTypography sx={{ fontSize: "10px", mb: 1 }} color="error">
                          Terjadi Kesalahan
                        </MDTypography>
                      ) : null}
                      <MDButton
                        variant="contained"
                        color="info"
                        disabled={notValid}
                        onClick={onAgree}
                      >
                        Submit
                      </MDButton>
                    </Grid>
                  </Grid>
                ) : null}
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      ) : null}
    </div>
  );
}

export default Settlement;
