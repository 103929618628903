import { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import LVFintechDataGrid from "components/LVFintechDataGrid";

import LVPenjualanDataGrid from "components/LVPenjualanDataGrid";
import ReactGA from "react-ga4";
import { createFinSORequest } from "../../actions/fintechAction";
import { signOut } from "../../actions/authAction";
import { emptyMessage } from "../../actions";
import { fetchOrderPenjualan, initOrderPenjualan } from "../../actions/orderPenjualanAction";
import { transformOrderPenjualan } from "../../common/Util";
import { Icon } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 700,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  overflow: "scroll",
  p: 4,
};

function PilihPendanaPenjualan(props) {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/invoice-financing/apply",
      title: "Pengajuan Dana Talang",
    });
  }, []);

  const user = JSON.parse(localStorage.getItem("user"));

  const fintech_id = parseInt(process.env.REACT_APP_DEFAULT_FINTECH_ID);

  const [top, setTop] = useState({
    id: -1,
    value: -1,
    label: "",
  });
  const [arrPenjualanId, setArrPenjualanId] = useState([]);
  const [pengajuan, setPengajuan] = useState(0);
  const [showError, setShowError] = useState({
    top: false,
    orderPenjualan: false,
    all: false,
    fail: false,
  });
  const [helperMsg, setHelperMsg] = useState({
    top: "Durasi pinjaman harus dipilih.",
    orderPenjualan: "Order Penjualan harus dipilih",
    all: "Masih terdapat informasi wajib yang kosong, silakan diperiksa kembali.",
    fail: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const message = useSelector((state) => state.message, shallowEqual);

  const listOrderPenjualan = useSelector((state) => state.listOrderPenjualan, shallowEqual);

  const topOptions = [
    {
      id: 0,
      value: 7,
      label: 'Jatuh Tempo 7 Hari'
    },
    {
      id: 1,
      value: 14,
      label: 'Jatuh Tempo 14 Hari'
    },
    {
      id: 2,
      value: 21,
      label: 'Jatuh Tempo 21 Hari'
    },
    {
      id: 3,
      value: 30,
      label: 'Jatuh Tempo 30 Hari'
    },
    {
      id: 4,
      value: 45,
      label: 'Jatuh Tempo 45 Hari'
    },
    {
      id: 5,
      value: 60,
      label: 'Jatuh Tempo 60 Hari'
    },
    {
      id: 6,
      value: 75,
      label: 'Jatuh Tempo 75 Hari'
    },
    {
      id: 7,
      value: 90,
      label: 'Jatuh Tempo 90 Hari'
    },
    {
      id: 8,
      value: 105,
      label: 'Jatuh Tempo 105 Hari'
    },
    {
      id: 9,
      value: 120,
      label: 'Jatuh Tempo 120 Hari'
    },
  ];

  useEffect(() => {
    if (props.datapengajuan === undefined) {
      dispatch(fetchOrderPenjualan());
    } else {
      setArrPenjualanId(props.datapengajuan.arrId);
    }
    return () => {
      if (props.datapengajuan === undefined) {
        dispatch(initOrderPenjualan());
      }
    };
  }, []);

  useEffect(() => {
    if (message.status !== "") {
      dispatch(emptyMessage());
      switch (message.status) {
        case "SUCCESS_NEW_FINANCING":
          props.onClose();
          navigate(`/pengajuan/purchase/${encodeURIComponent(message.message.financing_id)}`);
          return;
        case "FAIL_NEW_FINANCING":
          alert(`Gagal membuat pengajuan dana. Error: ${message.message}`);
          setHelperMsg({ ...helperMsg, fail: "Gagal Mengajukan Dana" });
          setShowError({ ...showError, fail: true });
          return;
        default:
      }
    }
  }, [message]);

  useEffect(() => {
    if (top.id !== -1 && arrPenjualanId.length !== 0) {
      setShowError({
        top: false,
        orderPenjualan: false,
        all: false,
      });
    }
  }, [top, arrPenjualanId]);

  const onPilihClick = () => {
    if (top.id !== -1 && arrPenjualanId.length !== 0) {

      dispatch(
        createFinSORequest({
          orderPenjualan: arrPenjualanId,
          daysTOP: top.value,
          fintechId: [fintech_id],
        })
      );

    } else {
      setShowError({
        top: top.id === -1,
        arrPenjualanId: arrPenjualanId.length === 0,
        all: true,
      });
    }
  };

  const onChangeTOP = (e, v) => {
    if (v === null) {
      setShowError({ ...showError, top: true });
    } else {
      setShowError({ ...showError, top: false });
      setTop(v);
    }
  };

  const renderOrderPenjualanView = () => {
    if (props.datapengajuan !== undefined) {
      return (
        <LVPenjualanDataGrid
          height={400}
          pageSize={5}
          rowsPerPageOptions={[5]}
          rows={props.datapengajuan.arrPenjualan}
          columnType={user?.company?.business_type}
          viewOnly
        />
      );
    }
    return (
      <LVPenjualanDataGrid
        height={400}
        pageSize={5}
        rowsPerPageOptions={[5]}
        viewOnly

        radioSelection
        rows={transformOrderPenjualan({
          orderPenjualan: listOrderPenjualan,
          businessType: user?.company?.business_type,
          status: ["sale"],
          statusFinancing: ["created"],
        })}
        columnType={user?.company?.business_type}
        onSelectionModelChange={(e) => {
          if (e.length !== 0) {
            setShowError({ ...showError, orderPenjualan: false });
          }
          let amount = 0;
          listOrderPenjualan.map((item) => {
            if (e.includes(item.id)) {
              amount += item.total;
            }
          });
          setPengajuan(amount);
          setArrPenjualanId(e);
        }}
      />
    );
  };

  const renderPengajuan = () => {
    if (props.datapengajuan !== undefined) {
      return Number(props.datapengajuan?.pengajuan).toLocaleString("id-ID", {
        style: "currency",
        currency: "IDR",
      });
    }
    return Number(pengajuan).toLocaleString("id-ID", { style: "currency", currency: "IDR" });
  };

  return (
    <div>
      <MDBox sx={style}>
        <Grid container direction="column" justifyContent="space-around">
          <MDBox display="flex">
            <Icon color="info" fontSize="large">
              check_circle_outline
            </Icon>
            <MDTypography ml={2} variant="title" fontWeight="medium">
              Pengajuan Dana Talang
            </MDTypography>
          </MDBox>
          <Grid item>
            <MDBox my={2}>
              {renderOrderPenjualanView()}
              <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                {showError.orderPenjualan ? helperMsg.orderPenjualan : ""}{" "}
              </MDTypography>
            </MDBox>
          </Grid>
          <MDBox display="flex" justifyContent="space-between">
            <MDBox borderRadius="xl" mr={1} width="50%" p={2} sx={{ border: "1px solid #e1e1e1" }}>
              <MDTypography color="black" pb={1} sx={{ fontSize: 12 }}>
                Silahkan pilih durasi pinjaman
              </MDTypography>
              <Autocomplete
                disablePortal
                id="select_top"
                options={topOptions}
                value={top}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                onChange={onChangeTOP}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Durasi Pinjaman" />}
              />
              <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                {showError.top ? helperMsg.top : ""}{" "}
              </MDTypography>
            </MDBox>
            <MDBox
              borderRadius="xl"
              ml={1}
              width="50%"
              p={2}
              bgColor="#F4F8FC"
              sx={{ border: "1px solid #0D6ECD" }}
            >
              <MDTypography mb={2} sx={{ fontSize: 12 }}>
                Total Ajuan
              </MDTypography>
              <MDTypography sx={{ fontSize: 16 }} fontWeight="bold">
                {renderPengajuan()}
              </MDTypography>
            </MDBox>
          </MDBox>

          <Grid item>
            <Grid container justifyContent="flex-end" alignItems="right" spacing={3} px={3} mt={1}>
              <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                {showError.all ? helperMsg.all : ""}{" "}
              </MDTypography>
              <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                {showError.fail ? helperMsg.fail : ""}{" "}
              </MDTypography>
              <Grid item>
                <MDButton
                  variant="contained"
                  color="info"
                  mt={2}
                  size="small"
                  onClick={onPilihClick}
                >
                  Ajukan
                </MDButton>
              </Grid>
              <Grid item>
                <MDButton
                  variant="contained"
                  color="error"
                  mt={2}
                  size="small"
                  onClick={props.onClose}
                >
                  Batal
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </div>
  );
}

export default PilihPendanaPenjualan;
