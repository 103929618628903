/* eslint-disable react/no-unstable-nested-components */
import { useState } from "react";
import { DataGrid, } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";

import MDBox from "components/MDBox";
import { transformRelationDocsArr } from "common/Util";

function LVRelationDocument(props) {
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

  const columns = [
    {
      field: "id",
    },
    {
      field: "no",
      headerName: "No.",
      headerAlign: "center",
      align: "center",
      width: 80,
      renderCell: (params) => <span>{params.api.getRowIndex(params.row.id) + 1}</span>,
    },
    {
      field: "name",
      headerName: "Nama Dokumen",
      headerAlign: "center",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 250,
    },
    {
      field: "data",
      type: "actions",
      headerName: "Tindakan",
      align: "center",
      width: 180,
      renderCell: (params) => {
        return (
          <a href={params.value} target="_blank" download>
            <MDBox sx={{ color: "#49a3f1", fontSize: "12px", fontWeight: "bold" }} size="small">
              Unduh
            </MDBox>
          </a>
        );
      },
    },
  ];

  const renderSX = () => {
    if (props.flat) {
      return {
        bgcolor: "rgba(255, 255, 255, 1)",
        borderRadius: "16px",
        fontSize: "small",
      };
    }
    return {
      bgcolor: "rgba(255, 255, 255, 1)",
      borderRadius: "16px",
      fontSize: "small",
      boxShadow: "10px 15px 27px -28px #1A73E8",
    };
  };

  return (
    <MDBox
      mt={5}
      sx={{
        height: 1250,
        width: "100%",
      }}
    >
      <DataGrid
        columns={columns}
        rows={transformRelationDocsArr(props.rows)}
        pageSize={24}
        rowsPerPageOptions={[24]}
        disableSelectionOnClick
        sx={renderSX()}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => {
          newModel.id = false;
          setColumnVisibilityModel(newModel);
        }}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </MDBox>
  );
}

export default LVRelationDocument;
