/* eslint-disable import/prefer-default-export */
import serverList from "../apis/serverList";
import axios from "axios";
import authHeader from "../apis/authHeader";

export const fetchUserList =
  (input) =>
    async (dispatch) => {
      const companyAPI = axios.create({
        baseURL: serverList.company,
        headers: authHeader(),
      });
      const response = await companyAPI
        .get(`/api/v1/admin/company/${input.type}/list`, {
          params: {
            page: input.page,
            order_by: input.orderBy,
            order: input.order,
            type: input.type,
            keyword: input.name,
            limit: input.limit,
          }
        })
        .catch((e) => {
          dispatch({
            type: "ERROR",
            payload: { status: "ERROR_FETCH_USERS", message: e.response.data.message },
          });
        });
      if (response !== undefined) {
        if (response.status === 401) {
          dispatch({
            type: "ERROR",
            payload: { status: "TOKEN_EXPIRE", message: response.data.message },
          });
        }
        let pagination = {};
        let arrAllHistory = [];
        let arrFinal = [];
        if (response.data.status === "OK") {
          arrAllHistory = response.data.data.result;
          pagination = response.data.data.pagination;
        }
        let no = 0;

        arrFinal = arrAllHistory.map((isi) => {
          let returnVal = {};
          no += 1;
          returnVal = {
            id: isi.id,
            name: isi.name,
            no,
          };
          return returnVal;
        });
        dispatch({ type: "FETCH_USER", payload: arrFinal });
        dispatch({
          type: "FETCH_USER_PAGINATION",
          payload: pagination,
        });
      }
    };
