import { useState, useEffect, useRef } from "react";

import Grid from "@mui/material/Grid";
import Modal from '@mui/material/Modal';

import NewCustomer from '../layouts/newcustomer';

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const CustomerModal = (props) => {

    return(
        <Modal
            {...props}
        >
            <div>
                <NewCustomer onClose={props.onClose} mainPage={props.mainPage}/>
            </div>
        </Modal>
    );
}

export default CustomerModal;