import React, { Component, useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from 'prop-types';
import { DataGrid, GridActionsCellItem, GridToolbarContainer, GridRowModes } from "@mui/x-data-grid";
import Tooltip from '@mui/material/Tooltip';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import moment from 'moment';

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { initProductList } from '../../actions';





const LVProductDataGrid = (props) => {

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
    actions: !props.viewOnly,
  });

  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const listProduct = useSelector(
    state => {
      return state.listProduct;
    },
    shallowEqual
  );


  const columns = [
    {
      field: 'id',
      headerName: 'No.',
      headerClassName: 'header-default',
      headerAlign: 'center',
      width: 50,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
    {
      field: 'name',
      headerName: 'Nama',
      headerClassName: 'header-default',
      headerAlign: 'center',
      width: 200,
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ''} >
          <span>{params.value}</span>
        </Tooltip>
      ),
      editable: true,
    },
    {
      field: 'remark',
      headerName: 'Keterangan',
      headerClassName: 'header-default',
      headerAlign: 'center',
      width: 150,
      editable: true,
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ''} >
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'transaction_date',
      headerName: 'Tanggal Mulai Transaksi',
      headerClassName: 'header-default',
      headerAlign: 'center',
      width: 200,
      type: 'date',
      editable: true,

    },
    {
      field: 'price',
      headerName: 'Harga',
      headerClassName: 'header-default',
      headerAlign: 'center',
      width: 150,
      editable: true,
      type: 'number',
      renderCell: (params) => {
        const valueFormatted = Number(params.value).toLocaleString("id-ID", { style: "currency", currency: "IDR" });
        return (
          <Tooltip title={valueFormatted ? valueFormatted : ''} >
            <span>{valueFormatted}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'quantity',
      headerName: 'Kuantitas',
      headerClassName: 'header-default',
      headerAlign: 'center',
      type: 'number',
      width: 100,
      editable: true,
    },
    {
      field: 'tax',
      headerName: 'Pajak',
      headerClassName: 'header-default',
      headerAlign: 'center',
      width: 150,
      editable: true,


    },


  ];

  const dispatch = useDispatch();

  useEffect(() => {
    if (!props.rows) {
      if (listProduct.length !== 0) {
        var i = 0;
        const transport = listProduct.detail.transport.map((item) => {
          i = i + 1;
          return {
            id: i,
            name: item.truck_type + ' ' + item.license_plate + ' ' + item.origin + ' ' + item.destination,
            price: item.price,
            quantity: item.quantity,
            transaction_date: item.departure_date + ' - ' + item.arrival_date,
            departure_date_iso: item.departure_date_iso,
            arrival_date_iso: item.arrival_date_iso,
            tax: item.tax,
            remark: item.remark
          };
        });
        const generic = listProduct.detail.generic.map((item) => {
          i = i + 1;
          return {
            id: i,
            name: item.name,
            unit: item.unit,
            price: item.price,
            quantity: item.quantity,
            transaction_date: item.transaction_date,
            tax: item.tax,
            remark: item.remark
          };
        });
        const listProductShow = [...transport, ...generic];
        setRows(listProductShow);
      }
    }
  }, [listProduct]);

  useEffect(() => {
    if (!props.rows) {
      dispatch(initProductList());
      setRows([]);
    } else {
      setRows(props.rows);
    }

    return () => {
      setRows([]);
      dispatch(initProductList());
    };
  }, []);

  useEffect(() => {
    if (props.rows) {
      setRows(props.rows);
    }
  }, [props.rows]);

  useEffect(() => {
    if (!props.viewOnly) {
      props.onProductUpdate(rows);
    }
  }, [rows]);


  const EditToolbar = ({ setRows, setRowModesModel }) => {
    if (!props.viewOnly) {
      const handleClick = () => {
        var id = randomNumberInRange(1, 1000);
        setRows((oldRows) => [...oldRows, { id, name: '', arrival_date_iso: '', departure_date_iso: '', price: '', quantity: '', price_total: '', remark: '', tax: '' }]);
        setRowModesModel((oldModel) => ({
          ...oldModel,
          [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
        }));
      };

      return (
        <GridToolbarContainer>
          <MDButton onClick={handleClick}>
            Tambah Baris
          </MDButton>
        </GridToolbarContainer>
      );
    } else {
      return (<div></div>)
    }
  }

  EditToolbar.propTypes = {
    setRowModesModel: PropTypes.func.isRequired,
    setRows: PropTypes.func.isRequired,
  };

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  return (
    <MDBox
      sx={{
        height: '100%',
        width: '100%',
      }}

    >
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        autoHeight
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        pageSize={5}
        rowsPerPageOptions={[5]}
        columnVisibilityModel={columnVisibilityModel}

        sx={{ bgcolor: 'rgba(255, 255, 255, 1)', borderRadius: '16px', fontSize: 'small' }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'id', sort: 'asc' }],
          },
        }}
        onColumnVisibilityModelChange={(newModel) => {
          newModel.id = false;
          setColumnVisibilityModel(newModel);
        }
        }
        components={{
          Toolbar: EditToolbar,
        }}
        componentsProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </MDBox>
  );
}

export default LVProductDataGrid;