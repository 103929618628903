import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';

import Modal from '@mui/material/Modal';
import Grid from "@mui/material/Grid";
import { MuiOtpInput } from 'mui-one-time-password-input'
import LVProductDataGrid from '../components/LVProductDataGrid';

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from 'components/MDButton';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    height: 300,
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
  };

const OTPModal = (props) => {
    const [otp, setOtp] = useState('')

    const handleChange = (newValue) => {
      setOtp(newValue)
    }
    return(
        <Modal
            {...props}
        >
            <MDBox sx={style}>
                <Grid container direction="column" alignItems="center" justifyContent="space-around" spacing={3}>
                    <Grid item>
                        <MDTypography variant="title" fontWeight="medium">
                            Verifikasi Nomor Handphone
                        </MDTypography>
                    </Grid>
                    <Grid item mt={4}>
                        <Grid container justifyContent="center">
                            <Grid item sx={{width: 200}}>
                                <MuiOtpInput value={otp} onChange={handleChange} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item alignItems="center">
                        <Grid container justifyContent="center">
                            <Grid item>
                                <MDTypography color="dark" sx={{fontSize:10}}>
                                    Tidak menerima kode OTP? 
                                </MDTypography>
                            </Grid>
                            <Grid item>
                                <Link to={""} >
                                    <MDTypography pl={0.5} sx={{fontSize:10}}>
                                        Klik untuk kirim ulang. 
                                    </MDTypography>
                                </Link>
                            </Grid>
                        </Grid>
                        
                        
                    </Grid>
                    <Grid item>
                        <MDButton variant="contained" color="info" mt={2} size="small" onClick={props.onClose}>
                            Verifikasi
                        </MDButton>
                    </Grid>
                </Grid>
                
            </MDBox>
        </Modal>
    );
}

export default OTPModal;