export default (state='',action) => {
    switch (action.type) {
       case 'SET_MENU_STATE':
            return action.payload;
        case 'INIT_MENU_STATE':
            return action.payload;
       default:
            return state;
     }

};