import React, { Component, useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridRowModes,
  GridCellParams,
} from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";

import moment from "moment";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { isValidProductTransport } from "common/Util";
import { initProductList } from "../../actions";
import MDTypography from "components/MDTypography";
import { calculateTaxValue } from "common/Util";
import { getTaxDetail } from "common/Util";
import { calculateTaxPphValue } from "common/Util";
import { getTaxPphList } from "common/Util";
import { getTaxList } from "common/Util";

function LVProductTransportDataGrid(props) {
  const [snackbar, setSnackbar] = React.useState(null);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
  });

  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [orderid, setOrderid] = useState(1);
  const [open, setOpen] = useState(false);

  const listProduct = useSelector((state) => state.listProduct, shallowEqual);

  const setDefaultPph = () => {
    rows.map((value, index) => {
      if (value["tax_pph_id"] === undefined) {
        rows[index]["tax_pph_id"] = 5;
      }
    })
  };


  const columns = [
    {
      field: "id",
      headerName: "No.",
      headerClassName: "header-default",
      headerAlign: "left",
      width: 50,
    },
    {
      field: "truck_type",
      headerName: "Tipe Truk",
      headerClassName: "header-default",
      headerAlign: "left",
      width: 200,
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      editable: true,
    },
    {
      field: "license_plate",
      headerName: "Nomor Polisi (Opsional)",
      headerClassName: "header-default",
      headerAlign: "left",
      width: 200,
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      editable: true,
    },
    {
      field: "origin",
      headerName: "Asal",
      headerClassName: "header-default",
      headerAlign: "left",
      width: 200,
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      editable: true,
    },
    {
      field: "destination",
      headerName: "Tujuan",
      headerClassName: "header-default",
      headerAlign: "left",
      width: 200,
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      editable: true,
    },
    {
      field: "remark",
      headerName: "Keterangan (Opsional)",
      headerClassName: "header-default",
      headerAlign: "left",
      width: 150,
      editable: true,
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "departure_date_iso",
      headerName: "Tanggal Berangkat",
      headerClassName: "header-default",
      headerAlign: "left",
      width: 200,
      type: "date",
      editable: true,
      valueFormatter: (params) =>
        moment(params.value).format("DD/MMM/YYYY"),
    },
    {
      field: "arrival_date_iso",
      headerName: "Tanggal Sampai",
      headerClassName: "header-default",
      headerAlign: "left",
      width: 200,
      type: "date",
      editable: true,
      valueFormatter: (params) =>
        moment(params.value).format("DD/MMM/YYYY"),
    },
    {
      field: "price",
      headerName: "Harga",
      headerClassName: "header-default",
      headerAlign: "left",
      width: 150,
      editable: true,
      type: "number",
      renderCell: (params) => {
        const valueFormatted = Number(params.value).toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
        });
        return (
          <Tooltip title={valueFormatted || ""}>
            <span>{valueFormatted}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "quantity",
      headerName: "Kuantitas",
      headerClassName: "header-default",
      headerAlign: "left",
      type: "number",
      width: 100,
      editable: true,
    },
    {
      field: "tax_id",
      headerName: "Pajak (PPN)",
      headerClassName: "header-default",
      headerAlign: "left",
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: getTaxList(),
      valueFormatter: ({ id: rowId, value, field, api }) => {
        const colDef = api.getColumn(field);
        const option = colDef.valueOptions.find((item) => item.value === value);

        if (option !== undefined) {
          return option.label;
        }
      },
    },
    {
      field: "tax_pph_id",
      headerName: "Pajak (PPh)",
      headerClassName: "header-default",
      headerAlign: "left",
      width: 200,
      editable: true,
      type: "singleSelect",
      valueOptions: getTaxPphList(),
      valueFormatter: ({ value, field, api }) => {
        const colDef = api.getColumn(field);
        const option = colDef.valueOptions.find((item) => item.value === value);
        if (option !== undefined) {
          return option.label;
        }
      },
    },
    {
      field: "pph_exclusive",
      headerName: "Pajak (PPh)",
      headerClassName: "header-default",
      headerAlign: "left",
      width: 150,
      editable: false,
      type: "number",
      valueGetter: (params) => {
        const priceTotal = params.row.quantity * params.row.price;
        let taxAmount = 0;
        let amount = 0;
        let pphAMount = 0;
        [amount, taxAmount] = calculateTaxValue(priceTotal, params.row.tax_id);
        [amount, pphAMount] = calculateTaxPphValue(amount, params.row.tax_pph_id);

        return Number(pphAMount).toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
        });
      },
    },
    {
      field: "price_total",
      headerName: "Total Harga Tanpa PPN",
      headerClassName: "header-default",
      headerAlign: "left",
      width: 170,
      type: "number",
      valueGetter: (params) => {
        const priceTotal = params.row.quantity * params.row.price;
        let taxAmount = 0;
        let amount = 0;

        [amount, taxAmount] = calculateTaxValue(priceTotal, params.row.tax_id);

        return Number(amount).toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
        });
      },
    },
    {
      field: "tax_amount",
      headerName: "PPN",
      headerClassName: "header-default",
      headerAlign: "left",
      width: 150,
      type: "number",
      valueGetter: (params) => {

        const priceTotal = params.row.quantity * params.row.price;
        let taxAmount = 0;
        let amount = 0;

        [amount, taxAmount] = calculateTaxValue(priceTotal, params.row.tax_id);
        return Number(taxAmount).toLocaleString("id-ID", { style: "currency", currency: "IDR" });
      },
    },
    {
      field: "all_price_total",
      headerName: "Total Harga",
      headerClassName: "header-default",
      headerAlign: "left",
      width: 150,
      type: "number",
      valueGetter: (params) => {
        const priceTotal = params.row.quantity * params.row.price;
        let taxAmount = 0;
        let amount = 0;

        [amount, taxAmount] = calculateTaxValue(priceTotal, params.row.tax_id);
        return Number(amount + taxAmount).toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
        });
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <Tooltip title="Simpan">
              <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(id)} />
            </Tooltip>,
            <Tooltip title="Batal">
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />
            </Tooltip>,
          ];
        }

        return [
          <Tooltip title="Ubah">
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />
          </Tooltip>,
          <Tooltip title="Hapus">
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />
          </Tooltip>,
        ];
      },
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    setDefaultPph();
  }, [rows]);

  useEffect(() => {
    if (!props.rows) {
      if (listProduct.detail !== undefined) {
        if (listProduct.detail.transport.length !== 0) {
          let i = orderid;
          const transport = listProduct.detail.transport.map((item) => {
            i += 1;
            return {
              id: i,
              ...item,
            };
          });
          setOrderid(i);
          setRows(transport);
        } else {
          setRows([]);
        }
      } else {
        setRows([]);
      }
    }
  }, [listProduct]);

  useEffect(() => {
    if (!props.rows) {
      dispatch(initProductList());
      setRows([]);
    } else {
      setRows(props.rows);
    }

    return () => {
      setRows([]);
      dispatch(initProductList());
    };
  }, []);

  useEffect(() => {
    if (props.rows) {
      setRows(props.rows);
    }
  }, [props.rows]);

  useEffect(() => {
    const tmpRows = rows;
    tmpRows.forEach((isi) => {
      const tmpPriceTotal = isi.price * isi.quantity;
      let taxAmount = 0;
      isi.ppn_exclusive = 0;
      isi.ppn_inclusive = 0;
      isi.price_total = 0;
      isi.price_ppn_total = 0;
      isi.pphExclusive = 0;

      let amount = 0;
      [amount, taxAmount] = calculateTaxValue(tmpPriceTotal, isi.tax_id);

      isi.price_total = amount;
      isi.price_ppn_total = amount + taxAmount;

      let ppnType = (getTaxDetail(isi.tax_id))["type"];
      if (ppnType == "exclusive") {
        isi.ppn_exclusive = taxAmount;
      }
      else if (ppnType == "inclusive") {
        isi.ppn_inclusive = taxAmount;
      }

      let taxPphAmount = 0;
      [amount, taxPphAmount] = calculateTaxPphValue(amount, isi.tax_pph_id);
      isi.pph_exclusive = taxPphAmount;
    });
    props.onProductUpdate(tmpRows);
  }, [rows]);

  function EditToolbar({ setRows, setRowModesModel }) {
    const handleClick = () => {
      const id = orderid + 1;
      setOrderid(orderid + 1);
      setRows((oldRows) => [
        ...oldRows,
        {
          id,
          isNew: true,
          truck_type: "",
          license_plate: "",
          origin: "",
          destination: "",
          arrival_date_iso: "",
          departure_date_iso: "",
          price: "",
          quantity: "",
          price_total: "",
          remark: "",
          tax: "",
          tax_id: 1,
          tax_pph_id: 1,
        },
      ]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "truck_type" },
      }));
    };

    return (
      <GridToolbarContainer>
        <MDBox display="flex" mx={2} my={3} width="100%" justifyContent="space-between">
          <MDTypography fontWeight="bold">Produk Transport</MDTypography>
          <MDButton variant="contained" color="info" onClick={handleClick}>
            <AddIcon />
            &nbsp;Tambah Produk Transport
          </MDButton>
        </MDBox>
      </GridToolbarContainer>
    );
  }

  EditToolbar.propTypes = {
    setRowModesModel: PropTypes.func.isRequired,
    setRows: PropTypes.func.isRequired,
  };

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    const editedRow = rows.find((row) => row.id === id);
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = useCallback(
    (newRow) => {
      const updatedRow = { ...newRow, isNew: false };
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      return updatedRow;

    },
    [rows]
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleProcessRowUpdateError = useCallback((error) => {
  }, []);

  return (
    <MDBox
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        autoHeight
        rowModesModel={rowModesModel}
        localeText={{ noRowsLabel: "Belum ada daftar produk." }}
        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        pageSize={5}
        rowsPerPageOptions={[5]}
        columnVisibilityModel={columnVisibilityModel}
        sx={{ bgcolor: "rgba(255, 255, 255, 1)", borderRadius: "16px", fontSize: "small" }}
        initialState={{
          sorting: {
            sortModel: [{ field: "id", sort: "asc" }],
          },
        }}
        onColumnVisibilityModelChange={(newModel) => {
          newModel.id = false;
          setColumnVisibilityModel(newModel);
        }}
        components={{
          Toolbar: EditToolbar,
        }}
        componentsProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        experimentalFeatures={{ newEditingApi: true }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Peringatan</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Informasi berikut wajib diisi: tipe truk, asal, tujuan, tanggal berangkat, tanggal
            sampai, harga jual, kuantitas, dan pajak.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose}>OK</MDButton>
        </DialogActions>
      </Dialog>
    </MDBox>
  );
}

export default LVProductTransportDataGrid;
