import { useState, useEffect, useRef } from "react";

import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";

import NewCustomer from "../layouts/newcustomer";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PartnerHistory from "layouts/editPartnerHistory";

const RiwayatModal = (props) => {
  return (
    <Modal {...props}>
      <div>
        <PartnerHistory
          onClose={props.onClose}
          type={props.type}
          onAjukan={props.onAjukan}
          relationId={props.relationId}
          id={props.id}
          npwp={props.npwp}
          name={props.name}
          admin={props.admin}
        />
      </div>
    </Modal>
  );
};

export default RiwayatModal;
