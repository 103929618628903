

export const initState = (newState) => {    
    return {
        type: 'INIT_MENU_STATE',
        payload: ''
      };
}

export const setActiveState = (newState) => {    
    return {
        type: 'SET_MENU_STATE',
        payload: newState
      };
}