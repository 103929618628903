export default (state = {}, action) => {
  switch (action.type) {
    case 'SUCCESS_GET_KYC_PROGRESS':
      return action.payload;
    case 'INIT_GET_KYC_PROGRESS':
      return [];
    default:
      return state;
  }

};