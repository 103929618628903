import axios from "axios";
import serverList from "../apis/serverList";
import authHeader from "../apis/authHeader";

export const fetchAvailablePendana = () => async (dispatch) => {
  let arrAllFintech = [];
  let arrFinal = [];
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI.get("/api/v1/companies/fintech/approved");
  if (response.data.status === "OK") {
    arrAllFintech = response.data.data;
  }
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }

  let no = 0;

  arrFinal = arrAllFintech.map((isi) => {
    let returnVal = {};
    let statusBaru = "";
    let typeBaru = "";
    no += 1;
    /*    result = arrFintechReg.filter((a)=> {
            return a.id === isi.fintech_id;
        });
        if (result.length === 0) {
            returnVal = {id: isi.fintech_id, name: isi.fintech_name, no, status: 'Belum Mendaftar'};
        } else {
            returnVal = {...result[0], no, status: 'Sudah Mendaftar'};
        } */

    switch (isi.status) {
      case "initial":
        statusBaru = "Belum Mendaftar";
        break;
      case "created":
        statusBaru = "Menunggu Persetujuan";
        break;
      case "approved":
        statusBaru = "Sudah Mendaftar";
        break;
      case "rejected":
        statusBaru = "Ditolak";
        break;
      default:
        statusBaru = isi.status;
    }
    switch (isi.type) {
      case "PT":
        typeBaru = "Perseroan Terbatas";
        break;
      default:
        typeBaru = isi.type;
    }
    returnVal = {
      id: isi.fintech_id,
      name: isi.fintech_name,
      type: typeBaru,
      no,
      status: statusBaru,
    };
    return returnVal;
  });
  dispatch({ type: "FETCH_AVAILABLE_PENDANA", payload: arrFinal });
};

export const fetchRelationPendana =
  ({ id, page }) =>
  async (dispatch) => {
    let arrAllFintech = [];
    let arrFinal = [];
    let pagination = {};
    const companyAPI = axios.create({
      baseURL: serverList.company,
      headers: authHeader(),
    });
    const data = {
      id,
      page,
      limit: 5,
    };
    const response = await companyAPI.get("/api/v1/company/fintech/relation/history", {
      params: data,
    });
    if (response.data.status === "OK") {
      arrAllFintech = response.data.data.result;
      pagination = response.data.data.pagination;
    }
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }

    let no = 0;

    arrFinal = arrAllFintech.map((isi) => {
      let returnVal = {};
      let typeBaru = "";
      no += 1;

      switch (isi.type) {
        case "PT":
          typeBaru = "Perseroan Terbatas";
          break;
        default:
          typeBaru = isi.type;
      }
      returnVal = {
        id: no,
        shipper_id: isi.transporter_shipper_id,
        fintech_id: isi.transporter_fintech_id,
        name: isi.name,
        type: typeBaru,
        availability: isi.availability,
        no,
      };
      return returnVal;
    });
    dispatch({ type: "FETCH_RELATION_PENDANA", payload: arrFinal });
    dispatch({ type: "FETCH_RELATION_PENDANA_PAGINATION", payload: pagination });
  };

export const fetchConnectedPendana =
  ({ id, page }) =>
  async (dispatch) => {
    let arrAllFintech = [];
    let arrFinal = [];
    let pagination = {};
    const companyAPI = axios.create({
      baseURL: serverList.company,
      headers: authHeader(),
    });
    const response = await companyAPI.get("/api/v1/admin/customer/fintech/connected", {
      params: {
        company_id: id,
        page,
        limit: 5,
      },
    });
    if (response.data.status === "OK") {
      arrAllFintech = response.data.data.result;
      pagination = response.data.data.pagination;
    }
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }

    let no = 0;

    arrFinal = arrAllFintech.map((isi) => {
      let returnVal = {};
      no += 1;
      returnVal = {
        id: isi.fintech_id,
        relation_id: isi.relation_id,
        name: isi.name,
        transporter_shipper_id: isi.transporter_shipper_id,
        transporter_fintech_id: isi.transporter_fintech_id,
        status: isi.status,
        no,
      };
      return returnVal;
    });
    dispatch({ type: "FETCH_RELATION_PENDANA", payload: arrFinal });
    dispatch({ type: "FETCH_RELATION_PENDANA_PAGINATION", payload: pagination });
  };

export const fetchAllpendana =
  ({ companyId, page }) =>
  async (dispatch) => {
    let arrAllFintech = [];
    let arrFinal = [];
    let pagination = {};
    const companyAPI = axios.create({
      baseURL: serverList.company,
      headers: authHeader(),
    });
    const response = await companyAPI.get("/api/v1/admin/company/fintech/list", {
      params: {
        company_id: companyId,
        page,
        limit: 5,
      },
    });
    if (response.data.status === "OK") {
      arrAllFintech = response.data.data.result;
      pagination = response.data.data.pagination;
    }
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }

    let no = 0;

    arrFinal = arrAllFintech.map((isi) => {
      let returnVal = {};
      let typeBaru = "";
      no += 1;

      switch (isi.type) {
        case "PT":
          typeBaru = "Perseroan Terbatas";
          break;
        default:
          typeBaru = isi.type;
      }
      returnVal = {
        id: isi.id,
        name: isi.name,
        type: typeBaru,
        availability: isi.status,
        no,
      };
      return returnVal;
    });
    dispatch({ type: "FETCH_RELATION_PENDANA", payload: arrFinal });
    dispatch({ type: "FETCH_RELATION_PENDANA_PAGINATION", payload: pagination });
  };

export const requestRegisterFintech =
  ({ transporter_id, fintech_id }) =>
  async (dispatch) => {
    const companyAPI = axios.create({
      baseURL: serverList.company,
      headers: authHeader(),
    });
    const response = await companyAPI.post("/api/v1/company/fintech/relation/request", {
      transporter_id,
      fintech_id,
    });
    if (response.data.status === "OK") {
      dispatch({
        type: "REQUEST_REGISTER_FINTECH",
        payload: { status: "REQUEST_REGISTER_FINTECH", message: { transporter_id, fintech_id } },
      });
    }
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
  };
