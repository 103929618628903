import axios from "axios";
import serverList from "../apis/serverList";
import authHeader from "../apis/authHeader";

export const fetchInvoices = () => async (dispatch) => {
  const invoiceAPI = axios.create({
    baseURL: serverList.invoice,
    headers: authHeader(),
  });
  const response = await invoiceAPI.get("/api/v1/invoices");
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  dispatch({ type: "FETCH_INVOICES", payload: response.data.data });
};

export const searchInvoices =
  ({
    id = [],
    financing_status = [],
    document_status = [],
    invoice_status = [],
    due_date = [],
    financing_id = [],
    invoicing_company_id = [],
  }) =>
    async (dispatch) => {
      const invoiceAPI = axios.create({
        baseURL: serverList.invoice,
        headers: authHeader(),
      });
      const arrStatus = [];
      arrStatus.push("unpaid");
      const response = await invoiceAPI.post("/api/v1/invoices/search", {
        id,
        financing_status,
        document_status,
        invoice_status,
        due_date,
      });
      if (response.status === 401) {
        dispatch({
          type: "ERROR",
          payload: { status: "TOKEN_EXPIRE", message: response.data.message },
        });
      }
      if (response.status === 200) {
        if (response.data.status === "OK") {
          dispatch({ type: "FETCH_INVOICES", payload: response.data.data });
        }
      }
    };

export const searchInvoicesPelunasan =
  ({
    invoiced_company_id = 0,
    financing_status = [],
    document_status = [],
    invoice_status = [],
    due_date = [],
  }) =>
    async (dispatch) => {
      const invoiceAPI = axios.create({
        baseURL: serverList.invoice,
        headers: authHeader(),
      });
      const response = await invoiceAPI.post("/api/v1/invoices/search", {
        invoiced_company_id,
        financing_status,
        invoice_status,
        document_status,
        due_date,
      });
      if (response.status === 401) {
        dispatch({
          type: "ERROR",
          payload: { status: "TOKEN_EXPIRE", message: response.data.message },
        });
      }
      if (response.status === 200) {
        if (response.data.status === "OK") {
          dispatch({ type: "FETCH_INVOICES", payload: response.data.data });
        }
      }
    };


export const searchInvoicesOrderFinancing =
  ({
    invoiced_company_id = 0,
    financing_status = [],
    document_status = [],
    invoice_status = [],
    due_date = [],
  }) =>
    async (dispatch) => {
      const invoiceAPI = axios.create({
        baseURL: serverList.invoice,
        headers: authHeader(),
      });
      const response = await invoiceAPI.post("/api/v1/invoices/order/financing/search", {
        invoiced_company_id,
        financing_status,
        invoice_status,
        document_status,
        due_date,
      });
      if (response.status === 401) {
        dispatch({
          type: "ERROR",
          payload: { status: "TOKEN_EXPIRE", message: response.data.message },
        });
      }
      if (response.status === 200) {
        if (response.data.status === "OK") {
          dispatch({ type: "FETCH_INVOICES", payload: response.data.data });
        }
      }
    };

export const initInvoices = () => {
  return {
    type: "INIT_INVOICES",
  };
};

export const initPelanggan = () => {
  return {
    type: "INIT_PELANGGAN",
  };
};

export const fetchPelanggan = () => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI.get("/api/v1/companies/customer/search");
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }

  const tempData = [];
  let no = 0;
  response.data.data.map((isi) => {
    no += 1;
    tempData.push({
      id: isi.id,
      no,
      value: isi.id,
      label: isi.name,
      npwp: isi.tin,
      pic_name: isi.pic_name,
      type: isi.type,
    });
  });

  dispatch({
    type: "FETCH_PELANGGAN",
    payload: tempData,
  });
};

export const initSearchVendor = () => {
  return {
    type: "INIT_SEARCH_VENDOR",
  };
};


export const fetchSearchVendor = () => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI.get("/api/v1/companies/vendor/search");
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }

  const tempData = [];
  let no = 0;
  response.data.data.map((isi) => {
    no += 1;
    tempData.push({
      id: isi.id,
      no,
      value: isi.id,
      label: isi.name,
      npwp: isi.tin,
      pic_name: isi.pic_name,
      type: isi.type,
    });
  });

  dispatch({
    type: "FETCH_SEARCH_VENDOR",
    payload: tempData,
  });
};
/* 
export const fetchPelanggan = ({company_id}) => async dispatch => {
    const companyAPI = axios.create({
        baseURL:  serverList.company, 
        headers:  authHeader()
      });
    const { data } = await companyAPI.get("/api/v1/companies/customer/search", {
        params: {
          company_id
        },
    });
 
    const tempData = [];
    data.data.map( (isi) => {
      tempData.push({id: isi.id, value: isi.id, label: isi.name, no});
    });
 
    dispatch({
        type: 'FETCH_PELANGGAN',
        payload: tempData
    });
}; */

export const fetchPphList = () => async (dispatch) => {
  const invoiceAPI = axios.create({
    baseURL: serverList.invoice,
    headers: authHeader(),
  });
  const response = await invoiceAPI.get("/api/v1/invoice/header/taxes");
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }

  const tempData = [];
  for (var i = 1; i <= Object.keys(response.data.data).length; i++) {
    tempData.push({ id: i, value: i, label: response.data.data[i] });
  }
  dispatch({
    type: "FETCH_PPHLIST",
    payload: tempData,
  });
};

export const initProductList = () => {
  return {
    type: "INIT_PRODUCT_LIST",
  };
};

export const postProductFile = (file) => async (dispatch) => {
  const invoiceAPI = axios.create({
    baseURL: serverList.invoice,
    headers: authHeader(),
  });
  var formData = new FormData();
  formData.append("file", file);
  const message = await invoiceAPI
    .post("/api/v1/invoice/detail/file", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .catch((e) => { });
  if (message.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }

  if (message.data.status === "OK") {
    dispatch({
      type: "FETCH_LIST_PRODUCT",
      payload: message.data.data,
    });
  } else {
    dispatch({
      type: "ERROR",
      payload: { status: "NOK", message: message.data.message },
    });
  }
};

export const emptyMessage = () => {
  return {
    type: "INIT_MESSAGE",
    payload: { status: "", message: "" },
  };
};

export const fetchFintechInvoiceList =
  ({ financing_id }) =>
    async (dispatch) => {
      const invoiceAPI = axios.create({
        baseURL: serverList.invoice,
        headers: authHeader(),
      });
      const response = await invoiceAPI
        .get("/api/v1/fintech/financing/invoices", {
          params: {
            financing_id,
          },
        })
        .catch((e) => { });
      if (response.status === 401) {
        dispatch({
          type: "ERROR",
          payload: { status: "TOKEN_EXPIRE", message: response.data.message },
        });
      }
      if (response.status === 200) {
        if (response.data.status === "OK") {
          dispatch({
            type: "FETCH_FINTECH_INVOICE_LIST",
            payload: response.data.data,
          });
        } else {
          dispatch({
            type: "ERROR",
            payload: {
              status: "FAIL_FETCH_FINTECH_INVOICE",
              message: "Fail to get fintech invoice list",
            },
          }).catch((e) => { });
          if (response.status === 200) {
            if (response.data.status === "OK") {
              dispatch({
                type: "FETCH_FINTECH_INVOICE_LIST",
                payload: response.data.data,
              });
            } else {
              dispatch({
                type: "ERROR",
                payload: {
                  status: "FAIL_FETCH_FINTECH_INVOICE",
                  message: "Fail to get fintech invoice list",
                },
              });
            }
          }
        }
      }
    };
