export default (state=[],action) => {
/*     const rows = [
        { id: 'INV/001', pelanggan: 'Unilever', statusDokumen: 'Belum Terkirim', statusInvoice: 'Draft', jumlah: '1000000' },
        { id: 'INV/002', pelanggan: 'Orang Tua', statusDokumen: 'Sudah Terkirim', statusInvoice: 'Unpaid', jumlah: '1000000' },
        { id: 'INV/003', pelanggan: 'ASIA Group', statusDokumen: 'Sudah Terkirim', statusInvoice: 'Overdue', jumlah: '1000000' },
        { id: 'INV/004', pelanggan: 'P&G', statusDokumen: 'Sudah Terkirim', statusInvoice: 'Draft', jumlah: '1000000' },
        { id: 'INV/005', pelanggan: 'Keramik', statusDokumen: 'Belum Terkirim', statusInvoice: 'Partialy Paid', jumlah: '1000000' },
        { id: 'INV/006', pelanggan: 'JAPFA', statusDokumen: 'Belum Terkirim', statusInvoice: 'Draft', jumlah: '1000000' },
        { id: 'INV/007', pelanggan: 'AHM', statusDokumen: 'Sudah Terkirim', statusInvoice: 'Draft', jumlah: '1000000' }
      ]; */
     switch (action.type) {
        case 'FETCH_INVOICES':
          return action.payload;
        case 'FETCH_FINTECH_INVOICE_LIST':
          return action.payload;
        case 'INIT_INVOICES':
          return state=[];
        default:
          return state;
      }

};