import React, { Component, useState, useEffect, useRef } from "react";
import { connect, useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import Radio from "@mui/material/Radio";

import MDBox from "components/MDBox";
import { fetchInvoices } from "../../actions";
import MDTypography from "components/MDTypography";

function LVInvoicePelunasanDataGrid(props) {
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: props.radioSelection === undefined ? false : props.radioSelection,
    statusParent_readable: props.columnType === "broker",
    statusDocument_readable: props.columnType === "broker",
    actions: !props.viewOnly,
  });

  const invoices = props.rows;
  const type = props.type;
  const [selected, setSelected] = useState([]);

  const columns = [
    {
      field: "",
      headerName: " ",
      width: 40,
      renderCell: (cellValues) => {
        if (props.radioSelection) {
          return (
            <Radio
              checked={selected.includes(
                type === "non-financing" ? cellValues.row.id : cellValues.row.financing_id
              )}
              onClick={() => onRadioChange(cellValues)}
              value={cellValues}
              name="radio-buttons"
              inputProps={{ "aria-label": { cellValues } }}
            />
          );
        }
      },
    },
    {
      field: "no",
      headerName: "No.",
      headerAlign: "left",
      align: "left",
      width: 50,
    },
    {
      field: "number",
      headerName: "No. Invoice",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Link
          to={`/detailinvoice/${encodeURIComponent(params.row.number)}/${encodeURIComponent(
            params.row.id
          )}`}
        >
          <MDTypography variant="body3">{params.value}</MDTypography>
        </Link>
      ),
      width: 150,
    },
    {
      field: "order_id",
      headerName: "ID Order Penjualan",
      headerAlign: "left",
      align: "left",
      width: 150,
    },
    {
      field: type === "non-financing" ? "id" : "financing_id",
      headerName: type === "non-financing" ? "Invoice ID" : "ID Financing",
      headerAlign: "left",
      align: "left",
      width: 150,
    },
    {
      field: "invoiced_company",
      headerName: "Pelanggan",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 150,
    },
    {
      field: "statusInvoice",
      headerName: "Status Invoice",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value.charAt(0).toUpperCase() + params.value.slice(1)}</span>
        </Tooltip>
      ),
      width: 150,
    },
    {
      field: "statusDocument_readable",
      headerName: "Status Dokumen",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 150,
    },
    {
      field: "statusFinancing_readable",
      headerName: props.columnType === "vendor" ? "Status QuickPay" : "Status Pencairan Invoice",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 180,
    },

    {
      field: "issued_date",
      headerName: "Terbit Invoice",
      headerAlign: "left",
      type: "date",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 130,
    },

    {
      field: "term_of_payment",
      headerName: "Jangka Waktu Pembayaran",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value} Hari</span>
        </Tooltip>
      ),
      width: 110,
    },
    {
      field: "due_date",
      headerName: "Jatuh Tempo",
      headerAlign: "left",
      type: "date",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 130,
    },
    {
      field: "subtotal",
      headerName: "Sub Total",
      headerAlign: "left",
      align: "left",
      type: "number",
      renderCell: (params) => {
        const valueFormatted = Number(params.value).toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
        });
        return (
          <Tooltip title={valueFormatted}>
            <span>{valueFormatted}</span>
          </Tooltip>
        );
      },
      width: 160,
    },
    {
      field: "total",
      headerName: "Total",
      headerAlign: "left",
      align: "left",
      type: "number",
      renderCell: (params) => {
        const valueFormatted = Number(params.value).toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
        });
        return (
          <Tooltip title={valueFormatted}>
            <span>{valueFormatted}</span>
          </Tooltip>
        );
      },
      width: 160,
    },
  ];

  const newdata = {
    columns,
    rows: props.rows,
  };

  const onRadioChange = (cellValues) => {
    if( type === "non-financing" ) {
      if (selected.includes(cellValues.row.id)) {
        let array = selected;
        array = array.filter((item) => item !== cellValues.row.id);
        setSelected(array);
        props.onSelectionModelChange(array);
      } else {
        props.onSelectionModelChange([...selected, cellValues.row.id]);
        setSelected([...selected, cellValues.row.id]);
      }
    } else {
      if (selected.includes(cellValues.row.financing_id)) {
        let array = selected;
        array = array.filter((item) => item !== cellValues.row.financing_id);
        setSelected(array);
        props.onSelectionModelChange(array);
      } else {
        props.onSelectionModelChange([...selected, cellValues.row.financing_id]);
        setSelected([...selected, cellValues.row.financing_id]);
      }
    }
  };

  return (
    <MDBox
      sx={{
        height: props.height,
        width: "100%",
      }}
    >
      <DataGrid
        {...newdata}
        pageSize={props.pageSize}
        rowsPerPageOptions={props.rowsPerPageOptions}
        editMode="row"
        checkboxSelection={props.checkboxSelection}
        disableSelectionOnClick
        autoHeight={props.autoHeight}
        localeText={{ noRowsLabel: props.localeText }}
        sx={{
          bgcolor: "rgba(255, 255, 255, 1)",
          borderRadius: "16px",
          fontSize: "small",
          boxShadow: props.flat ? "" : "10px 15px 27px -28px #1A73E8",
        }}
        columnVisibilityModel={columnVisibilityModel}
        isRowSelectable={(params) => params.row.statusInvoice === "unpaid"}
        onColumnVisibilityModelChange={(newModel) => {
          newModel.id = false;
          setColumnVisibilityModel(newModel);
        }}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </MDBox>
  );
}

export default LVInvoicePelunasanDataGrid;
