/* eslint-disable react/function-component-definition */
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import { emptyMessage, searchInvoicesPelunasan, searchInvoicesOrderFinancing, fetchInvoices } from "actions";
import { signOut } from "actions/authAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  TextField,
  Tooltip,
} from "@mui/material";

import LVInvoiceDataGrid from "components/LVInvoiceDataGrid";
import { transformInvoices } from "common/Util";
import { updateStatus } from "actions/detailInvoiceAction";
import { companyBalance, customerBalanceList } from "actions/paymentAction";
import LVInvoicePelunasanDataGrid from "components/LVInvoicePelunasanDataGrid";
import { paymentSettlementRequest, paymentSettlementRequestNonFinancing, paymentSettlementRequestOrderFinancing } from "actions/paymentAction";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 700,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  overflowY: "scroll",
  p: 4,
};

const DaftarPelunasanInvoice = (props) => {
  const listType = props.type;
  let financingType = "";
  if (listType === "invoice-financing" || listType === 'order-financing') {
    financingType = "financing";
  }
  else if (listType === "invoice-penjualan") {
    financingType = "non-financing";
  }

  const [arrInv, setArrInv] = useState([]);
  const [arrInquiry, setArrInquiry] = useState([]);
  const [pengajuan, setPengajuan] = useState(0);
  const [showError, setShowError] = useState({
    top: false,
    orderPenjualan: false,
    all: false,
    fail: false,
  });
  const [helperMsg, setHelperMsg] = useState({
    top: "Durasi pinjaman harus dipilih.",
    orderPenjualan: "Order Penjualan harus dipilih",
    all: "Masih terdapat informasi wajib yang kosong, silakan diperiksa kembali.",
    fail: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const message = useSelector((state) => state.message, shallowEqual);
  const anCustomerBalance = useSelector((state) => state.anCustomerBalance, shallowEqual);
  const anCustomerBalanceList = useSelector((state) => state.anCustomerBalanceList, shallowEqual);

  const invoices = useSelector((state) => state.invoices, shallowEqual);
  const [customer, setCustomer] = useState(null);
  const [openInquiryDialog, setOpenInquiryDialog] = useState(null);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(null);
  const [errorDialogMessage, setErrorDialogMessage] = useState("");
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    const dateNow = new Date();
    if (customer !== null) {
      if (listType === "invoice-financing") {
        dispatch(
          searchInvoicesPelunasan({
            invoice_status: ["unpaid"],
            financing_status: ["fund_transferred"],
            invoiced_company_id: customer,
          })
        );
      } else if (listType === "invoice-penjualan") {
        dispatch(
          searchInvoicesPelunasan({
            invoice_status: ["unpaid"],
            financing_status: ["created"],
            document_status: ["approved"],
            invoiced_company_id: customer,
          })
        );
      } else if (listType === "order-financing") {
        dispatch(
          searchInvoicesOrderFinancing({
            invoice_status: ["unpaid"],
            financing_status: ["fund_transferred", "approved"],
            invoiced_company_id: customer,
          })
        );
      }
    }
  }, [customer]);

  useEffect(() => {
    dispatch(companyBalance());
    dispatch(customerBalanceList());
  }, []);

  useEffect(() => {
    if (message.status !== "") {
      dispatch(emptyMessage());
      switch (message.status) {
        case "SUCCESS_REQUEST_SETTLEMENT":
          setOpenSuccessDialog(true);
          return;
        case "ERROR_REQUEST_SETTLEMENT":
          setErrorDialogMessage(message.message);
          return;
        default:
      }
    }
  }, [message]);

  const onPilihClick = () => {
    if (listType === "invoice-financing") {
      dispatch(paymentSettlementRequest(arrInv));
    } else if (listType === "order-financing") {
      dispatch(paymentSettlementRequestOrderFinancing(arrInv));
    } else if (listType === "invoice-penjualan") {
      dispatch(paymentSettlementRequestNonFinancing(arrInv));
    }
  };

  const data = [];

  const renderOrderPenjualanView = () => (
    <LVInvoicePelunasanDataGrid
      rows={customer ? transformInvoices(invoices) : data}
      height={400}
      pageSize={5}
      rowsPerPageOptions={[5]}
      viewOnly
      localeText={customer ? "Belum ada invoice penjualan." : "Belum memilih customer."}
      radioSelection
      type={financingType}
      onSelectionModelChange={(e) => {
        let amount = 0;
        let data = [];
        let dataInquiry = [];
        if (e.length >= 1) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }

        invoices.map((item) => {
          if (financingType == 'financing') {
            if (e.includes(item.financing_id)) {
              amount += item.total;
            }
          } else {
            if (e.includes(item.id)) {
              amount += item.total;
            }
          }
        });

        invoices.map((item) => {
          let matchedItem = 0;
          if (financingType == 'financing') {
            matchedItem = item.financing_id;
          }
          else {
            matchedItem = item.id;
          }

          if (e.includes(matchedItem)) {
            data.push({
              invoice_id: item.id,
              financing_id: item.financing_id,
              company_id: item.invoicing_company_id,
              partner_id: item.invoiced_company_id,
              amount: item.total,
            });
            dataInquiry.push({
              number: item.number,
              name: item.invoiced_company,
              amount: item.total,
            });
          }
        });

        setPengajuan(amount);
        setArrInv(data);
        setArrInquiry(dataInquiry);
      }}
    />
  );

  const renderPengajuan = () => {
    if (props.datapengajuan !== undefined) {
      return Number(props.datapengajuan?.pengajuan).toLocaleString("id-ID", {
        style: "currency",
        currency: "IDR",
      });
    }
    return Number(pengajuan).toLocaleString("id-ID", { style: "currency", currency: "IDR" });
  };

  const renderBalance = () => {
    if (anCustomerBalance !== undefined) {
      return Number(anCustomerBalance.balance).toLocaleString("id-ID", {
        style: "currency",
        currency: "IDR",
      });
    }
  };

  const onChangeCust = (e) => {
    setCustomer(e);
  };

  return (
    <div>
      <MDBox sx={style}>
        <Grid container direction="column" justifyContent="space-around">
          <MDBox display="flex">
            <Icon color="info" fontSize="large">
              check_circle_outline
            </Icon>
            <MDTypography ml={2} variant="title" fontWeight="medium">
              Pelunasan
            </MDTypography>
          </MDBox>
          <MDBox mt={4} display="flex" justifyContent="space-between">
            <MDBox
              borderRadius="xl"
              display="flex"
              justifyContent="space-between"
              width="100%"
              p={2}
              sx={{ border: "1px solid #e1e1e1" }}
            >
              <MDTypography sx={{ fontSize: 13, my: "auto", fontWeight: "medium" }}>
                Total Nominal Wallet
              </MDTypography>
              <MDTypography sx={{ fontSize: 16 }} fontWeight="bold">
                {renderBalance()}
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox mt={2}>
            <Autocomplete
              disablePortal
              id="customer_data"
              options={anCustomerBalanceList}
              onChange={(e, v) => onChangeCust(v.partner_id)}
              sx={{ width: "100%" }}
              isOptionEqualToValue={(option, value) => option.partner_id === value.partner_id}
              renderInput={(params) => <TextField required {...params} label="Pilih Customer" />}
            />
          </MDBox>
          <Grid item>
            <MDBox my={2}>
              {renderOrderPenjualanView()}
              <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                {showError.orderPenjualan ? helperMsg.orderPenjualan : ""}{" "}
              </MDTypography>
            </MDBox>
          </Grid>
          <MDBox display="flex" justifyContent="space-between">
            <MDBox
              borderRadius="xl"
              width="100%"
              bgColor="#F4F8FC"
              p={2}
              sx={{ border: "1px solid #0D6ECD" }}
            >
              <MDTypography mb={2} sx={{ fontSize: 12 }}>
                Total Pelunasan
              </MDTypography>
              <MDTypography sx={{ fontSize: 16 }} fontWeight="bold">
                {renderPengajuan()}
              </MDTypography>
            </MDBox>
          </MDBox>
          <Grid item>
            <Grid container justifyContent="flex-end" alignItems="right" spacing={2} px={3} mt={1}>
              <Grid item>
                <MDButton
                  variant="contained"
                  color="info"
                  mt={2}
                  size="small"
                  onClick={() => setOpenInquiryDialog(true)}
                  disabled={disabled}
                >
                  Lunasi
                </MDButton>
              </Grid>
              <Grid item>
                <MDButton
                  variant="outlined"
                  color="info"
                  mt={2}
                  size="small"
                  onClick={props.onClose}
                >
                  Tutup
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
          <Dialog
            open={openInquiryDialog}
            onClose={() => setOpenInquiryDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <MDBox mx={4} my={3}>
              <MDBox display="flex">
                <Icon color="info" fontSize="large">
                  error_outline_icon
                </Icon>
                <MDTypography ml={2} variant="title" fontWeight="medium">
                  Apakah anda yakin ingin melunasi invoice berikut?
                </MDTypography>
              </MDBox>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <MDBox mt={2} mb={1} display="flex" justifyContent="space-between">
                    {/* <MDTypography sx={{ width: "5%" }} fontWeight="bold" variant="body3">
                      No.
                    </MDTypography> */}
                    <MDTypography sx={{ width: "45%" }} fontWeight="bold" variant="body3">
                      Nomor Invoice
                    </MDTypography>
                    <MDTypography sx={{ width: "28%" }} fontWeight="bold" ml={1} variant="body3">
                      Nama Pelanggan
                    </MDTypography>
                    <MDTypography sx={{ width: "27%" }} fontWeight="bold" ml={1} variant="body3">
                      Nominal
                    </MDTypography>
                  </MDBox>
                  <MDBox maxHeight="100px" py={1} sx={{ overflowY: "auto" }}>
                    {arrInquiry.map((item, i) => (
                      <MDBox display="flex" justifyContent="space-between" key={i}>
                        {/* <MDTypography sx={{ width: "5%" }} variant="body3">
                          {i + 1}
                        </MDTypography> */}
                        <MDTypography sx={{ width: "45%", overflowX: "hidden" }} variant="body3">
                          <Tooltip title={item.number}>
                            <span>{item.number}</span>
                          </Tooltip>
                        </MDTypography>
                        <MDTypography
                          sx={{ width: "28%", overflowX: "hidden" }}
                          ml={1}
                          variant="body3"
                        >
                          <Tooltip title={item.name}>
                            <span>{item.name}</span>
                          </Tooltip>
                        </MDTypography>
                        <MDTypography
                          sx={{ width: "27%", overflowX: "hidden" }}
                          ml={1}
                          variant="body3"
                        >
                          <Tooltip
                            title={item.amount.toLocaleString("id-ID", {
                              style: "currency",
                              currency: "IDR",
                            })}
                          >
                            <span>
                              {item.amount.toLocaleString("id-ID", {
                                style: "currency",
                                currency: "IDR",
                              })}
                            </span>
                          </Tooltip>
                        </MDTypography>
                      </MDBox>
                    ))}
                  </MDBox>
                  <MDBox
                    borderRadius="xl"
                    width="100%"
                    bgColor="#F4F8FC"
                    px={2}
                    py={1}
                    mt={4}
                    sx={{ border: "1px solid #0D6ECD" }}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <MDTypography my="auto" sx={{ fontSize: 12 }}>
                      Total Pelunasan
                    </MDTypography>
                    <MDTypography sx={{ fontSize: 15 }} fontWeight="bold">
                      {renderPengajuan()}
                    </MDTypography>
                  </MDBox>
                </DialogContentText>
              </DialogContent>
              <MDTypography color="error" py={1} mr={2} sx={{ textAlign: "right", fontSize: 10 }}>
                {errorDialogMessage}
              </MDTypography>
              <DialogActions>
                <MDButton onClick={onPilihClick} variant="contained" color="info" size="small">
                  Lunasi
                </MDButton>
                <MDButton
                  onClick={() => setOpenInquiryDialog(false)}
                  variant="outlined"
                  color="error"
                  size="small"
                >
                  Batal
                </MDButton>
              </DialogActions>
            </MDBox>
          </Dialog>
          <Dialog
            open={openSuccessDialog}
            onClose={() => setOpenSuccessDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Berhasil!</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Pelunasan berhasil dilakukan.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MDButton onClick={props.onClose} variant="outlined" color="info" size="small">
                Tutup
              </MDButton>
            </DialogActions>
          </Dialog>
        </Grid>
      </MDBox>
    </div>
  );
};

export default DaftarPelunasanInvoice;
