/* eslint-disable default-case */

export const convertNumberToMonth = (number) => {

    const numberInt = parseInt(number) - 1;

    return [
        "januari", "februari", "maret", "april", "mei", "juni", "juli", "agustus", "september", "oktober", "november", "desember"
    ][numberInt];
}


export const convertMonthFormat = (inputString, prefixVar) => {
    if (inputString.startsWith(prefixVar + '_')) {
        const remainingString = inputString.substring(prefixVar.length + 1);
        const parts = remainingString.split('_');

        if (parts.length >= 2) {
            const monthNumber = parts[0];
            const year = parts[1];
            const extraPart = parts.slice(2).join('_');

            if (convertNumberToMonth(monthNumber)) {
                const monthAbbreviation = convertNumberToMonth(monthNumber);
                return `${prefixVar}_${monthAbbreviation}_${year}${extraPart ? '_' + extraPart : ''}`;
            }
        }
    }

    return inputString;
}
