/* eslint-disable arrow-body-style */
import { useEffect, useState } from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { fetchPelanggan } from "actions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchAvailablePendana } from "actions/daftarPendanaAction";
import LVPenggunaDataGrid from "components/LVPenggunaDataGrid";
import { fetchUserList } from "actions/userListAction";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { transformUserList } from "common/Util";

function LVPengguna(props) {
  const dispatch = useDispatch();
  const listUser = useSelector((state) => {
    return state.listUser;
  }, shallowEqual);
  const listUserPagination = useSelector((state) => {
    return state.listUserPagination;
  }, shallowEqual);

  const [previousName, setPreviousName] = useState("");
  const [data, setDataUse] = useState(listUser);
  const [type, setType] = useState("broker");
  const [loading, setLoading] = useState(false);

  const [queryParameter, setQueryParameter] = useState({
    type, page: 1, orderBy: "id", order: "desc", name: "", limit: 10
  })

  const resetFilter = () => {
    setQueryParameter({
      ...queryParameter,
      type, page: 1, orderBy: "id", order: "desc", name: "", limit: 10
    })
  }

  const loadingCloseHandler = (e) => {
    setLoading(false);
  }

  useEffect(() => {
    if (listUserPagination === "") {
      return;
    }
    listUserPagination.limit = parseInt(queryParameter.limit);
    try {
      let data = transformUserList(listUser, listUserPagination);
      setDataUse(data);
    } catch (e) {
      setLoading(false);
    }
    setLoading(false);

  }, [listUser, listUserPagination]);

  useEffect(() => {
    loadingCloseHandler();
  }, [data])

  useEffect(() => {
    resetFilter();
    setQueryParameter({ ...queryParameter, type: type });
  }, [type]);

  useEffect(() => {
    if (queryParameter.name != previousName) {
      setPreviousName(queryParameter.name);
      setQueryParameter({ ...queryParameter, page: 1 });
    }

    setLoading(true);
    if (type === "broker") {
      dispatch(fetchUserList(queryParameter));
    }
    if (type === "vendor") {
      dispatch(fetchUserList(queryParameter));
    }
    if (type === "fintech") {
      dispatch(fetchUserList(queryParameter));
    }
  }, [queryParameter]);

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={loadingCloseHandler}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox
        my={5}
        sx={{
          width: "100%",
        }}
      >
        <MDBox
          sx={{
            display: "flex",
            py: 2.5,
            px: 2,
            mb: 2,
            bgcolor: "rgba(255, 255, 255, 1)",
            borderRadius: "16px",
            border: "1px solid #e1e1e1",
            fontSize: "small",
            boxShadow: "10px 15px 27px -28px #1A73E8",
          }}
        >
          <MDBox onClick={() => setType("broker")} sx={{ cursor: "pointer" }}>
            <MDTypography
              variant="body3"
              sx={{
                mr: 4,
                pb: 0.5,
                fontWeight: "bold",
                borderBottom: type === "broker" ? "3px solid #0D6ECD" : "3px solid #ffffff",
              }}
            >
              Kontraktor / Broker
            </MDTypography>
          </MDBox>
          <MDBox onClick={() => setType("vendor")} sx={{ cursor: "pointer" }}>
            <MDTypography
              variant="body3"
              sx={{
                mr: 4,
                pb: 0.5,
                fontWeight: "bold",
                borderBottom: type === "vendor" ? "3px solid #0D6ECD" : "3px solid #ffffff",
              }}
            >
              Vendor
            </MDTypography>
          </MDBox>
          <MDBox onClick={() => setType("fintech")} sx={{ cursor: "pointer" }}>
            <MDTypography
              variant="body3"
              sx={{
                mr: 4,
                pb: 0.5,
                fontWeight: "bold",
                borderBottom: type === "fintech" ? "3px solid #0D6ECD" : "3px solid #ffffff",
              }}
            >
              Fintech / Lender
            </MDTypography>
          </MDBox>
        </MDBox>
        <LVPenggunaDataGrid
          firstPage={() => setQueryParameter({ ...queryParameter, page: 1 })}
          lastPage={() => setQueryParameter({ ...queryParameter, page: listUserPagination.total_pages })}
          totalPage={listUserPagination.total_pages}
          currentPage={queryParameter.page}
          selectPage={(i) => setQueryParameter({ ...queryParameter, page: parseInt(i.target.textContent) })}
          nextPage={() => setQueryParameter({ ...queryParameter, page: listUserPagination.next_page })}
          prevPage={() => setQueryParameter({ ...queryParameter, page: listUserPagination.prev_page })}
          order={(orderBy, order) => setQueryParameter({ ...queryParameter, orderBy, order })}
          rows={data}

          type={type}
          name={(name) => setQueryParameter({ ...queryParameter, name })}
          resetFilter={(e) => resetFilter(e)}
        />
      </MDBox>
    </div>
  );
}

export default LVPengguna;
