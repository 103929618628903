import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";

import validator from "validator";

import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { getCompany, getCustomerCompany, initCustomerCompany } from "actions/companyAction";
import { updateStatus, sendEmailInvoice, getInvoice } from "../../actions/detailInvoiceAction";
import { parseJwt, transformInvoices } from "../../common/Util";
import { emptyMessage, fetchInvoices, fetchPelanggan } from "../../actions";
import { customerBalanceList, generateVA } from "../../actions/paymentAction";
import LVInvoiceDataGrid from "components/LVInvoiceDataGrid";
import LVInvoiceDataGridList from "components/LVInvoiceDataGridList";
import { Icon } from "@mui/material";
import { invoiceHistory } from "actions/userInvoice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  // height: 665,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  overflow: "auto",
  p: 4,
};

function SendEmailBulk(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const [emailDari, setEmailDari] = useState("invoice@manajemeninvoice.com");
  const [emailKe, setEmailKe] = useState("");
  const [emailCC, setEmailCC] = useState("");
  const [company, setCompany] = useState("");
  const [arrInv, setArrInv] = useState([]);
  const [email, setSetEmail] = useState(0);
  const [customer, setCustomer] = useState(null);
  const [disabled, setDisabled] = useState(true);

  const dispatch = useDispatch("");
  const anUserInvoiceHistory = useSelector((state) => state.anUserInvoiceHistory, shallowEqual);
  const anUserInvoiceHistoryPagination = useSelector(
    (state) => state.anUserInvoiceHistoryPagination,
    shallowEqual
  );

  const anCompany = useSelector((state) => state.anCompany, shallowEqual);
  const anCustomerBalanceList = useSelector((state) => state.anCustomerBalanceList, shallowEqual);
  const anCustomerCompany = useSelector((state) => state.anCustomerCompany, shallowEqual);
  const listPelanggan = useSelector((state) => state.listPelanggan, shallowEqual);
  const message = useSelector((state) => state.message, shallowEqual);

  useEffect(() => {
    dispatch(getCompany());
    dispatch(fetchPelanggan());
    if (customer) {
      dispatch(getCustomerCompany(customer));
      dispatch(
        invoiceHistory({
          invoicedId: customer,
          limit: 5,
          invoiceStatus: "unpaid",
          documentStatus: "created",
          financingStatus: "created",
        })
      );
    }
  }, [customer]);

  useEffect(() => {
    if (anCompany.type && anCompany.name) {
      setCompany(`${anCompany.type} ${anCompany.name}`);
    }
  }, [anCompany]);

  useEffect(() => {
    if (Array.isArray(anCustomerCompany.pic)) {
      let financeFound = false;
      anCustomerCompany.pic.map((item) => {
        if (item.type.toLowerCase() === "finance") {
          setEmailKe(item.email);
          financeFound = true;
        }
      });
      if (!financeFound) {
        setEmailKe(anCustomerCompany.email);
      }
    }
  }, [anCustomerCompany]);

  useEffect(() => {
    if (message.status !== "") {
      dispatch(emptyMessage());
      switch (message.status) {
        case "SEND_EMAIL_SUCCESS":
          dispatch(getInvoice({ number: props.invoicenumber }));
          dispatch(
            invoiceHistory({
              invoicedId: customer,
              limit: 5,
              invoiceStatus: "unpaid",
              documentStatus: "created",
              financingStatus: "created",
            })
          );
          dispatch(fetchInvoices());
          props.onClose();

        default:
      }
    }
  }, [message]);

  const onChangeCust = (e) => {
    setCustomer(e);
  };

  const onKirim = () => {
    if (
      validator.isEmail(emailDari) &&
      emailDari !== "" &&
      validator.isEmail(emailKe) &&
      emailKe !== "" &&
      (validator.isEmail(emailCC) || emailCC === "")
    ) {
      setDisabled(true);
      dispatch(
        sendEmailInvoice({
          invoice_id: arrInv,
          recipient: [emailKe],
        })
      );
    }
  };
  return (
    <div>
      <MDBox sx={style}>
        <Grid container direction="column" justifyContent="space-around">
          <Grid item>
            <MDBox display="flex" justifyContent="space-between">
              <MDBox>
                <Icon fontSize="large" sx={{ pt: 1 }} color="info">
                  group
                </Icon>
                <MDTypography ml={2} variant="title" fontWeight="medium">
                  Kirim Invoice
                </MDTypography>
              </MDBox>
              <Icon sx={{ cursor: "pointer" }} onClick={props.onClose} fontSize="large">
                clear
              </Icon>
            </MDBox>
          </Grid>
        </Grid>
        <MDBox my={2} mt={5}>
          <Autocomplete
            disablePortal
            id="customer_data"
            options={listPelanggan}
            onChange={(e, v) => onChangeCust(v.id)}
            sx={{ width: "100%" }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => <TextField required {...params} label="Pilih Customer" />}
          />
        </MDBox>
        <LVInvoiceDataGridList
          rows={customer ? transformInvoices(anUserInvoiceHistory) : []}
          pageSize={5}
          rowsPerPageOptions={[5]}
          radioSelection
          autoHeight
          columnType={user?.company?.business_type}
          requestFinancing={user?.access_list?.includes("request_invoice_financing")}
          onSelectionModelChange={(e) => {
            let data = [];
            if (e.length >= 1) {
              setDisabled(false);
            } else {
              setDisabled(true);
            }
            props.rows.map((item) => {
              if (e.includes(item.id)) {
                data.push(item.id);
              }
            });
            setArrInv(data);
          }}
        />
        <Grid container justifyContent="end" mt={3}>
          <Grid item>
            <MDBox sx={{ width: 300 }} />
          </Grid>
          <Grid item>
            <MDButton variant="contained" color="info" disabled={disabled} onClick={onKirim}>
              Kirim Invoice
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </div>
  );
}

export default SendEmailBulk;
