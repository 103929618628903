export default (state=[],action) => {
    switch (action.type) {
       case 'FETCH_LIST_PRODUCT':
         return action.payload;
        case 'GET_INVOICE': 
         return action.payload;
       case 'INIT_PRODUCT_LIST':
         return state=[];
       default:
         return state;
     }
};