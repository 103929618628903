/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { emptyMessage } from "../../../actions";
import { signIn, signInOdoo } from "../../../actions/authAction";
import ReactGA from "react-ga4";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function Basic() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/signin", title: "Signin" });
  }, []);

  const [rememberMe, setRememberMe] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showError, setShowError] = useState({
    username: false,
    password: false,
  });
  const [helperMsg, setHelperMsg] = useState({
    username: "Email harus diisi",
    password: "Kata sandi harus diisi",
  });

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    if (username !== "") {
      setShowError({ ...showError, username: false });
    }
  }, [username]);

  useEffect(() => {
    if (password !== "") {
      setShowError({ ...showError, password: false });
    }
  }, [password]);

  const onUsernameBlur = () => {
    if (username.trim() === "") {
      setShowError({ ...showError, username: true });
    }
  };

  const onPasswordBlur = () => {
    if (password.trim() === "") {
      setShowError({ ...showError, password: true });
    }
  };

  const clickLogin = () => {
    if (username === "" || password === "") {
      setShowError({
        ...showError,
        username: username === "",
        password: password === "",
      });
    }
    if (location.pathname === "/oauth/signin") {
      const urlparams = new URLSearchParams(location.search);

      dispatch(
        signInOdoo({
          username,
          password,
          redirect_uri: urlparams.get("redirect_uri"),
          scope: urlparams.get("scope"),
          response_type: urlparams.get("response_type"),
          state: urlparams.get("state"),
          client_id: urlparams.get("client_id"),
        })
      );
    } else {
      localStorage.setItem("username", username);
      localStorage.setItem("password", password);
      dispatch(signIn({ username, password }));
    }

  };

  const authToken = useSelector((state) => {
    return state.authToken;
  }, shallowEqual);

  const message = useSelector((state) => {
    return state.message;
  }, shallowEqual);

  useEffect(() => {
    if (message.status !== "") {
      dispatch(emptyMessage());
      switch (message.status) {
        case "COMPANY_REGISTRATION":
          navigate(message.message);
          return;
        case "LOGIN_FAILED":
          setErrorMsg(message.message);
          return;
        default:
          return;
      }
    }
  }, [message]);

  return (
    <BasicLayout>
      <MDBox pt={20} pb={3} px={3} textAlign="center" my="auto">
        <MDTypography variant="h4" fontWeight="medium" color="black" mt={1}>
          Masuk
        </MDTypography>
        <MDTypography variant="h6" fontWeight="regular" color="black" mt={1} mb={5}>
          Selamat datang kembali di Kentara!
        </MDTypography>
        <MDBox component="form" role="form" textAlign="left">
          <MDBox mb={2}>
            <MDInput
              type="email"
              label="Email"
              fullWidth
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              onBlur={onUsernameBlur}
              error={showError.username}
            />
            <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
              {showError.username ? helperMsg.username : ""}{" "}
            </MDTypography>
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type={showPassword ? "text" : "password"}
              label="Kata Sandi"
              fullWidth
              value={password}
              onBlur={onPasswordBlur}
              onChange={(e) => setPassword(e.target.value)}
              error={showError.password}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
            />
            <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
              {showError.password ? helperMsg.password : ""}{" "}
            </MDTypography>
          </MDBox>
          <MDBox mt={4} mb={1}>
            <MDButton color="primary" fullWidth onClick={clickLogin}>
              masuk
            </MDButton>
            <MDTypography color="error" textAlign="center" px={1} mt={2} sx={{ fontSize: 10 }}>
              {errorMsg === "sql: no rows in result set"
                ? "This email is not registered"
                : errorMsg}
            </MDTypography>
          </MDBox>

          <MDBox mt={2} mb={1} textAlign="center">
            <MDTypography
              component={Link}
              to="/forget-password"
              variant="button"
              color="primary"
              fontWeight="medium"
            >
              Lupa password
            </MDTypography>
          </MDBox>

          <MDBox mt={2} textAlign="center">
            <MDTypography variant="button" color="text" fontWeight="regular">
              Belum punya akun?{" "}
            </MDTypography>
          </MDBox>

          <MDBox textAlign="center">
            <MDTypography
              component={Link}
              to="/signup"
              variant="button"
              color="primary"
              fontWeight="medium"
            >
              Daftar
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </BasicLayout>
  );
}

export default Basic;
