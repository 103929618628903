import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import LVFintechDataGrid from "components/LVFintechDataGrid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import LVInvoiceDataGrid from "components/LVInvoiceDataGrid";
import ReactGA from "react-ga4";
import { Icon } from "@mui/material";
import { fetchAvailablePendana } from "actions/daftarPendanaAction";
import { createFinRequest, fetchFintechList } from "../../actions/fintechAction";
import { searchInvoices, emptyMessage, initInvoices, fetchInvoices } from "../../actions";
import { transformInvoices } from "../../common/Util";
import LVDaftarFintech from "components/LVDaftarFintech";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 700,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  overflow: "scroll",
  p: 4,
};

function PilihPendana(props) {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/invoice-financing/apply",
      title: "Pengajuan Dana Talang",
    });
  }, []);

  const user = JSON.parse(localStorage.getItem("user"));
  const fintech_id = parseInt(process.env.REACT_APP_DEFAULT_FINTECH_ID);

  const [top, setTop] = useState({
    id: -1,
    value: -1,
    label: "",
  });

  const [fintech, setFintech] = useState({
    id: 0,
    value: 1,
    label: "Semua fintech",
  });

  const [arrInvId, setArrInvId] = useState([]);
  const [arrFinId, setArrFinId] = useState([]);
  const [pengajuan, setPengajuan] = useState(0);
  const [openInformasi, setOpenInformasi] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [showError, setShowError] = useState({
    top: false,
    fintech: false,
    invoice: false,
    all: false,
    fail: false,
  });
  const [helperMsg, setHelperMsg] = useState({
    top: "Durasi pinjaman harus dipilih.",
    fintech: "Fintech peminjam harus dipilih",
    invoice: "Invoice harus dipilih",
    all: "Masih terdapat informasi wajib yang kosong, silakan diperiksa kembali.",
    fail: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const message = useSelector((state) => state.message, shallowEqual);

  const invoices = useSelector((state) => state.invoices, shallowEqual);

  const listDaftarPendana = useSelector((state) => state.listDaftarPendana, shallowEqual);

  const topOptions = [
    {
      id: 0,
      value: 7,
      label: "Jatuh Tempo 7 Hari",
    },
    {
      id: 1,
      value: 14,
      label: "Jatuh Tempo 14 Hari",
    },
    {
      id: 2,
      value: 21,
      label: "Jatuh Tempo 21 Hari",
    },
    {
      id: 3,
      value: 30,
      label: "Jatuh Tempo 30 Hari",
    },
    {
      id: 4,
      value: 45,
      label: "Jatuh Tempo 45 Hari",
    },
    {
      id: 5,
      value: 60,
      label: "Jatuh Tempo 60 Hari",
    },
    {
      id: 6,
      value: 75,
      label: "Jatuh Tempo 75 Hari",
    },
    {
      id: 7,
      value: 90,
      label: "Jatuh Tempo 90 Hari",
    },
    {
      id: 8,
      value: 105,
      label: "Jatuh Tempo 105 Hari",
    },
    {
      id: 9,
      value: 120,
      label: "Jatuh Tempo 120 Hari",
    },
  ];

  const fintechOptions = [
    {
      id: 0,
      value: 1,
      label: "Semua fintech",
    },
    {
      id: 1,
      value: 2,
      label: "Hanya fintech rekomendasi",
    },
  ];

  useEffect(() => {
    dispatch(fetchAvailablePendana());
  }, []);

  useEffect(() => {
    if (props.datapengajuan === undefined) {
      const dateNow = new Date();
      dispatch(
        searchInvoices({
          financing_status: ["created"],
          invoice_status: ["unpaid"],
          document_status: ["approved"],
        })
      );
    } else {
      setArrInvId(props.datapengajuan.arrId);
    }
    return () => {
      if (props.datapengajuan === undefined) {
        dispatch(fetchInvoices());
      }
    };
  }, []);

  useEffect(() => {
    if (message.status !== "") {
      switch (message.status) {
        case "SUCCESS_NEW_FINANCING":
          if (!openInformasi) {
            dispatch(emptyMessage());
            props.onClose();
            navigate(`/pengajuan/invoice/${encodeURIComponent(message.message.financing_id)}`);
          }
          return;
        case "FAIL_NEW_FINANCING":
          alert(`Gagal membuat pengajuan dana. Error: ${message.message}`);
          setHelperMsg({ ...helperMsg, fail: "Gagal Mengajukan Dana" });
          setShowError({ ...showError, fail: true });
          return;
        default:
          dispatch(emptyMessage());
      }
    }
  }, [message]);

  useEffect(() => {
    if (top.id !== -1 && arrInvId.length !== 0 && arrFinId.length !== 0) {
      setShowError({
        top: false,
        invoice: false,
        all: false,
      });
    }
  }, [, top, arrInvId, arrFinId]);

  const handleCloseInformasi = () => {
    setOpenInformasi(false);
    if (message.status !== "") {
      dispatch(emptyMessage());
      switch (message.status) {
        case "SUCCESS_NEW_FINANCING":
          props.onClose();
          navigate(`/pengajuan/invoice/${encodeURIComponent(message.message.financing_id)}`);

        default:
      }
    }
  };

  const onPilihClick = () => {
    if (top.id !== -1 && arrInvId.length !== 0 && arrFinId.length !== 0) {
      dispatch(
        createFinRequest({
          arrInvoiceId: arrInvId,
          daysTOP: top.value,
          fintechId: arrFinId,
        })
      );

      if (props.datapengajuan !== undefined) {
        if (Array.isArray(props.datapengajuan.arrInv)) {
          if (props.datapengajuan.arrInv.length > 0) {
          }
        }
      }
    } else {
      setShowError({
        top: top.id === -1,
        fintech: arrFinId.length === 0,
        invoice: arrInvId.length === 0,
        all: true,
      });
    }
  };

  const onChangeTOP = (e, v) => {
    if (v === null) {
      setShowError({ ...showError, top: true });
    } else {
      setShowError({ ...showError, top: false });
      setTop(v);
    }
  };

  const onChangeFin = (e, v) => {
    if (v === null) {
      setShowError({ ...showError, top: true });
    } else {
      setShowError({ ...showError, top: false });
      setFintech(v);
    }
  };

  const renderInvoiceView = () => {
    if (props.datapengajuan !== undefined) {
      return (
        <LVInvoiceDataGrid
          rows={props.datapengajuan.arrInv}
          height={400}
          pageSize={5}
          rowsPerPageOptions={[5]}
          viewOnly
        />
      );
    }
    return (
      <LVInvoiceDataGrid
        rows={transformInvoices(invoices)}
        height={400}
        pageSize={5}
        rowsPerPageOptions={[5]}
        viewOnly
        radioSelection
        onSelectionModelChange={(e) => {
          if (e.length !== 0) {
            setShowError({ ...showError, invoice: false });
          }
          let amount = 0;
          invoices.map((item) => {
            if (e.includes(item.id)) {
              amount += item.subtotal;
            }
          });
          setPengajuan(amount);
          setArrInvId(e);
        }}
      />
    );
  };

  const renderFintechView = () => (
    <LVDaftarFintech
      rows={listDaftarPendana}
      onSelectionModelChange={(e) => {
        setArrFinId(e);
      }}
      radioSelection
    />
  );

  const renderPengajuan = () => {
    if (props.datapengajuan !== undefined) {
      return Number(props.datapengajuan.pengajuan).toLocaleString("id-ID", {
        style: "currency",
        currency: "IDR",
      });
    }
    return Number(pengajuan).toLocaleString("id-ID", { style: "currency", currency: "IDR" });
  };

  return (
    <div>
      <MDBox sx={style}>
        <Grid container direction="column" justifyContent="space-around">
          <MDBox display="flex">
            <Icon color="info" fontSize="large">
              check_circle_outline
            </Icon>
            <MDTypography ml={2} variant="title" fontWeight="medium">
              Pengajuan Dana Talang
            </MDTypography>
          </MDBox>
          <Grid item>
            <MDBox my={2}>
              {renderInvoiceView()}
              <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                {showError.invoice ? helperMsg.invoice : ""}{" "}
              </MDTypography>
            </MDBox>
          </Grid>
          <MDBox mb={2} sx={{ border: "1px solid #e1e1e1", borderRadius: "16px" }}>
            <MDBox
              borderRadius="xl"
              display="flex"
              justifyContent="space-between"
              mr={1}
              width="100%"
              p={2}
            >
              <MDTypography color="dark" my="auto" ml={2} pb={1} sx={{ fontSize: 14 }}>
                Silahkan pilih fintech
              </MDTypography>
              <Autocomplete
                disablePortal
                id="select_fintech"
                options={fintechOptions}
                value={fintech}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                onChange={onChangeFin}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="" />}
              />
            </MDBox>
            <MDBox m={2}>
              {renderFintechView()}
              <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                {showError.fintech ? helperMsg.fintech : ""}{" "}
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox display="flex" justifyContent="space-between">
            <MDBox borderRadius="xl" mr={1} width="50%" p={2} sx={{ border: "1px solid #e1e1e1" }}>
              <MDTypography color="dark" pb={1} sx={{ fontSize: 12 }}>
                Silahkan pilih durasi pinjaman
              </MDTypography>
              <Autocomplete
                disablePortal
                id="select_top"
                options={topOptions}
                value={top}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                onChange={onChangeTOP}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Durasi Pinjaman" />}
              />
              <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                {showError.top ? helperMsg.top : ""}{" "}
              </MDTypography>
            </MDBox>
            <MDBox
              borderRadius="xl"
              bgColor="#F4F8FC"
              ml={1}
              width="50%"
              p={2}
              sx={{ border: "1px solid #0D6ECD" }}
            >
              <MDTypography mb={2} sx={{ fontSize: 12 }}>
                Total Ajuan
              </MDTypography>
              <MDTypography sx={{ fontSize: 16 }} fontWeight="bold">
                {renderPengajuan()}
              </MDTypography>
            </MDBox>
          </MDBox>

          <Grid item>
            <Grid container justifyContent="flex-end" alignItems="right" spacing={3} px={3} mt={1}>
              <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                {showError.all ? helperMsg.all : ""}{" "}
              </MDTypography>
              <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                {showError.fail ? helperMsg.fail : ""}{" "}
              </MDTypography>
              <Grid item>
                <MDButton
                  variant="contained"
                  color="info"
                  mt={2}
                  size="small"
                  onClick={onPilihClick}
                >
                  Ajukan
                </MDButton>
              </Grid>
              <Grid item>
                <MDButton
                  variant="contained"
                  color="error"
                  mt={2}
                  size="small"
                  onClick={props.onClose}
                >
                  Batal
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          open={openInformasi}
          onClose={handleCloseInformasi}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Informasi</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={handleCloseInformasi}>OK</MDButton>
          </DialogActions>
        </Dialog>
      </MDBox>
    </div>
  );
}

export default PilihPendana;
