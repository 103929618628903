import React, { Component, useState, useEffect, useRef  } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded';
import Radio from '@mui/material/Radio';
import Tooltip from '@mui/material/Tooltip';
import MDBox from "components/MDBox";
import MDTypography from 'components/MDTypography';


const LVPembelianDataGrid = (props) => {
    
    const [columnVisibilityModel, setColumnVisibilityModel] =useState({
      id: props.radioSelection===undefined?false:props.radioSelection,
      actions: !props.viewOnly,      
    });

    const [selectionModel, setSelectionModel] = useState([]);
    const [selected, setSelected] = useState(null);
    
    
    const columns = [
      { 
          field: 'id', 
          headerName: ' ',
          width: 40,
          renderCell: (cellValues) => {
            if (props.radioSelection) {
              return (
                <Radio 
                  checked={cellValues.id === selected}
                  onChange={() => onRadioChange(cellValues.id)}
                  value={cellValues.id}
                  name="radio-buttons"
                  inputProps={{ 'aria-label': {cellValues} }}
                />
               )
            }
            
          }
      },
      { 
        field: 'no', 
        headerName: 'No.', 
        headerAlign: 'center',
        align:"center",
        width: 50,
    },
    { 
      field: 'number', 
      headerName: 'No. Order Pembelian', 
      headerAlign: 'center',
      align:"left",
      renderCell: (params) =>  (
        <Link to={"/detailorderpembelian/"+encodeURIComponent(params.row.id)} >
            <MDTypography variant="body3">{params.value}</MDTypography>
        </Link>
      ),
      width: 270,
  },
  { 
    field: 'project_name', 
    headerName: 'Nama Project', 
    headerAlign: 'center',
    align:"left",
    renderCell: (params) =>  (
      <Link to={"/detailorderpembelian/"+encodeURIComponent(params.row.id)} >
          <MDTypography variant="body3">{params.value}</MDTypography>
      </Link>
    ),
    width: 220,
},
    {
      field: 'status_readable',
      headerName: 'Status',
      headerAlign: 'center',
      align:"left",
      renderCell: (params) =>  (
        <Tooltip title={params.value?params.value:''} >
            <span>{params.value}</span>
        </Tooltip>
      ),
      width: 150,
    },
  /*   {
      field: 'statusFinancing_readable',
      headerName: 'Status Pendanaan',
      headerAlign: 'center',
      align:"left",
      renderCell: (params) =>  (
        <Tooltip title={params.value?params.value:''} >
            <span>{params.value}</span>
        </Tooltip>
      ),
      width: 150,
    }, */
      {
        field: 'vendor_company',
        headerName: 'Supplier',
        headerAlign: 'center',
        align:"left",
        renderCell: (params) =>  (
          <Tooltip title={params.value?params.value:''} >
              <span>{params.value}</span>
          </Tooltip>
        ),
        width: 150,
      },
      {
        field: 'creation_date',
        headerName: 'Tanggal Order',
        headerAlign: 'center',
        type: 'date',
        align:"left",
        renderCell: (params) =>  (
          <Tooltip title={params.value?params.value:''} >
              <span>{params.value}</span>
          </Tooltip>
        ),
        width: 110,
      },
/* 
      {
        field: 'purchase_person',
        headerName: 'Pegawai',
        headerAlign: 'center',
        align:"left",
        renderCell: (params) =>  (
          <Tooltip title={params.value?params.value:''} >
              <span>{params.value}</span>
          </Tooltip>
        ),
        width: 110,
      },   */   
      {
        field: 'total',
        headerName: 'Total',
        headerAlign: 'center',
        align:"left",
        type: 'number',
        renderCell: (params) => {
                const valueFormatted = Number(params.value).toLocaleString("id-ID", {style:"currency", currency:"IDR"});
                return (
                    <Tooltip title={valueFormatted} >
                        <span>{valueFormatted}</span>
                    </Tooltip>
                );
        },
        width: 160
      },
      
    ];

    const rows = [
        {id:1, no: '1', number:'SO1001', invoiced_company:'PT Surya Jaya', issued_date: '01-Januari-2023', term_of_payment:'15', subtotal: 1000000, total:1200000   },
        {id:2, no: '2', number:'SO1001', invoiced_company:'PT Surya Jaya', issued_date: '01-Januari-2023', term_of_payment:'15', subtotal: 1000000, total:1200000   },
        {id:3, no: '3', number:'SO1001', invoiced_company:'PT Surya Jaya', issued_date: '01-Januari-2023', term_of_payment:'15', subtotal: 1000000, total:1200000   },
    ]

    const newdata = {
      columns,
      rows: props.rows,  
    }

    const renderSX = () => {
      if (props.flat) {
        return {
          bgcolor: 'rgba(255, 255, 255, 1)', 
          borderRadius: '16px', 
          fontSize:'small', 
          //boxShadow: '10px 15px 27px -28px #1A73E8',
        }
      } else {
        return {
          bgcolor: 'rgba(255, 255, 255, 1)', 
          borderRadius: '16px', 
          fontSize:'small', 
          boxShadow: '10px 15px 27px -28px #1A73E8',
        }
      }
    }
    
    const onRadioChange = (val) => {
      props.onSelectionModelChange([val]);
      setSelectionModel([val]);
      setSelected(val);
    }

    return (
      <MDBox 
          //mt={3}  
          sx={{ 
              height: props.height, 
              width: '100%',          
          }} 
      
      >
          <DataGrid
              {...newdata}
              pageSize={props.pageSize}
              rowsPerPageOptions={props.rowsPerPageOptions}
              editMode="row"
              checkboxSelection={props.checkboxSelection}
              disableSelectionOnClick
              autoHeight={props.autoHeight}
              localeText={{ noRowsLabel: "Belum ada order pembelian." }}
              sx={renderSX()}
              onSelectionModelChange={(newSelectionModel) => {
                if (props.onSelectionModelChange){
                  props.onSelectionModelChange(newSelectionModel);
                }
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
              columnVisibilityModel={columnVisibilityModel}
              //isRowSelectable={(params)=>params.row.statusInvoice==='unpaid'}
              onColumnVisibilityModelChange={(newModel) => {
                  newModel.id = false;
                  //this.setState({ columnVisibilityModel: newModel });
                  setColumnVisibilityModel(newModel);
                }                     
              }
              experimentalFeatures={{ newEditingApi: true }}              
          />
      </MDBox>
  );
}

export default LVPembelianDataGrid;