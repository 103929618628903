/* eslint-disable no-nested-ternary */
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import validator from "validator";

import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import {
  createNewCustomer,
  deleteKYCDocument,
  fetchRelationDocs,
  getCompanyKYCStatus,
  saveRelationDocument,
} from "../../actions/companyAction";
import { Icon } from "@mui/material";
import LVFileInputMultiple from "components/LVFileInputMultiple";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "570px",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  overflowY: "scroll",
  p: 4,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <MDBox sx={{ p: 3 }}>{children}</MDBox>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function NewCustomer(props) {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [npwp, setNPWP] = useState("");
  const [namaPerusahaan, setNamaPerusahaan] = useState("");
  const [tipe, setTipe] = useState(null);
  const [tipeAbbr, setTipeAbbr] = useState(null);
  const [alamat, setAlamat] = useState("");
  const [telepon, setTelepon] = useState("");
  const [email, setEmail] = useState("");
  const [namaPIC, setNamaPIC] = useState("");
  const [teleponPIC, setTeleponPIC] = useState("");
  const [emailPIC, setEmailPIC] = useState("");

  const [showError, setShowError] = useState({
    npwp: false,
    tipe: false,
    namaPerusahaan: false,
    alamat: false,
    namaPIC: false,
    teleponPIC: false,
    validEmail: false,
    validTeleponPIC: false,
    validTelepon: false,
    validEmailPIC: false,
    all: false,
    fail: false,
    failDetail: false,
  });
  const [helperMsg, setHelperMsg] = useState({
    npwp: "Nomor NPWP harus diisi",
    tipe: "Badan Usaha harus dipilih",
    namaPerusahaan: "Nama Perusahaan harus diisi",
    alamat: "Alamat Perusahaan harus diisi",
    namaPIC: "Nama PIC harus diisi",
    teleponPIC: "Telepon PIC harus diisi",
    validTeleponPIC: "Format telepon tidak valid",
    validTelepon: "Format telepon tidak valid",
    validEmailPIC: "Format email tidak valid",
    validEmail: "Format email tidak valid",
    all: "Masih terdapat informasi wajib yang kosong, silakan diperiksa kembali.",
    fail: "Gagal membuat perusahaan baru. Cek apakah NPWP/nomor telepon/email pernah digunakan",
    failDetail: "",
  });

  const badan_usaha = [
    { id: 0, label: "Perseroan Terbatas (PT)", abbr: "PT" },
    { id: 1, label: "Commanditaire Vennootschap (CV)", abbr: "CV" },
    { id: 2, label: "Firma", abbr: "Firma" },
    { id: 3, label: "Perusahaan Perseorangan (PO)", abbr: "PO" },
    { id: 4, label: "BUMN: Perusahan Jawatan (Perjan)", abbr: "Perjan" },
    { id: 5, label: "BUMN: Perusahaan Umum (Perum)", abbr: "Perum" },
    { id: 6, label: "BUMN: Perusahaan Perseroan (Persero)", abbr: "Persero" },
    { id: 7, label: "BUMD", abbr: "BUMD" },
    { id: 8, label: "Perorangan", abbr: "Perorangan" },
    { id: 9, label: "Lainnya", abbr: "Lainnya" },
  ];

  const message = useSelector((state) => state.message, shallowEqual);

  useEffect(() => {
    if (
      !(
        npwp === "" ||
        namaPerusahaan === "" ||
        tipe === null ||
        namaPIC === "" ||
        alamat === "" ||
        teleponPIC === ""
      )
    ) {
      setShowError({ ...showError, all: false });
    }
  }, [npwp, namaPerusahaan, tipe, namaPIC, teleponPIC, alamat]);

  useEffect(() => {
    if (npwp !== "") {
      setShowError({ ...showError, npwp: false });
    }
  }, [npwp]);

  useEffect(() => {
    if (namaPerusahaan !== "") {
      setShowError({ ...showError, namaPerusahaan: false });
    }
  }, [namaPerusahaan]);

  useEffect(() => {
    if (namaPIC !== "") {
      setShowError({ ...showError, namaPIC: false });
    }
  }, [namaPIC]);

  useEffect(() => {
    if (teleponPIC !== "") {
      setShowError({ ...showError, teleponPIC: false });
    }
  }, [teleponPIC]);

  useEffect(() => {
    if (alamat !== "") {
      setShowError({ ...showError, alamat: false });
    }
  }, [alamat]);

  useEffect(() => {}, [telepon]);

  useEffect(() => {}, [email]);

  useEffect(() => {
    if (tipe !== null) {
      setShowError({ ...showError, tipe: false });
    }
  }, [tipe]);

  useEffect(() => {
    switch (message.status) {
      case "SUCCESS_NEW_CUSTOMER":
        if(props.mainPage) {
          location.reload();
        }
        props.onClose();
        return;
      case "ERROR":
        setHelperMsg({ ...helperMsg, failDetail: message.message });
        setShowError({
          ...showError,
          fail: true,
          failDetail: true,
        });

      default:
    }
  }, [message]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onAgree = () => {
    if (
      npwp === "" ||
      namaPerusahaan === "" ||
      tipe === null ||
      namaPIC === "" ||
      teleponPIC === "" ||
      alamat === "" ||
      emailPIC === "" ||
      (!validator.isEmail(emailPIC) ? emailPIC !== "" : emailPIC === "") ||
      (!validator.isMobilePhone(teleponPIC) ? teleponPIC !== "" : teleponPIC === "") ||
      (!validator.isMobilePhone(telepon) ? telepon !== "" : telepon === "") ||
      (!validator.isEmail(email) ? email !== "" : email === "")
    ) {
      setShowError({
        ...showError,
        npwp: npwp === "",
        tipe: tipe === null,
        namaPerusahaan: namaPerusahaan === "",
        namaPIC: namaPIC === "",
        teleponPIC: teleponPIC === "",
        emailPIC: emailPIC === "",
        validEmailPIC: !validator.isEmail(emailPIC) ? emailPIC !== "" : emailPIC === "",
        validTeleponPIC: !validator.isMobilePhone(teleponPIC)
          ? teleponPIC !== ""
          : teleponPIC === "",
        validTelepon: !validator.isMobilePhone(telepon) ? telepon !== "" : telepon === "",
        validEmail: !validator.isEmail(email) ? email !== "" : email === "",
        all: true,
      });
    } else {
      const comp = {
        name: namaPerusahaan,
        type: tipeAbbr,
        address: alamat,
        phone: telepon,
        email,
        tin: npwp,
        pic: [
          {
            name: namaPIC,
            type: "finance",
            phone: teleponPIC,
            email: emailPIC,
          },
        ],
      };
      dispatch(createNewCustomer(comp));
    }
  };

  const onChangeTipe = (select_tipe) => {
    if (select_tipe === null) {
      setTipe(null);
      setTipeAbbr(null);
    } else {
      setTipe(select_tipe.id);
      setTipeAbbr(select_tipe.abbr);
    }
  };

  const onNPWPBlur = () => {
    if (npwp.trim() === "") {
      setShowError({ ...showError, npwp: true });
    }
  };

  const onNamaBlur = () => {
    if (namaPerusahaan.trim() === "") {
      setShowError({ ...showError, namaPerusahaan: true });
    }
  };

  const onNamaPICBlur = () => {
    if (namaPIC.trim() === "") {
      setShowError({ ...showError, namaPIC: true });
    }
  };

  const onTelpPICBlur = () => {
    if (teleponPIC.trim() === "") {
      setShowError({ ...showError, teleponPIC: true });
    }
  };

  const onEmailPICBlur = () => {
    if (emailPIC.trim() === "") {
      setShowError({ ...showError, emailPIC: true });
    }
  };

  const onTelpBlur = () => {
    if (!(validator.isMobilePhone(telepon) || telepon.trim() === "")) {
      setShowError({ ...showError, validTelepon: true });
    } else {
      setShowError({ ...showError, validTelepon: false });
    }
  };

  const onEmailBlur = () => {
    if (!(validator.isEmail(email) || email.trim() === "")) {
      setShowError({ ...showError, validEmail: true });
    } else {
      setShowError({ ...showError, validEmail: false });
    }
  };

  const onTipeBlur = () => {
    if (tipe === null) {
      setShowError({ ...showError, tipe: true });
    }
  };

  const onAlamatBlur = () => {
    if (alamat.trim() === "") {
      setShowError({ ...showError, alamat: true });
    }
  };

  return (
    <div>
      <MDBox sx={style}>
        <Grid container direction="column" justifyContent="space-around" spacing={3}>
          <Grid item>
            <MDBox display="flex" justifyContent="space-between">
              <MDBox>
                <Icon fontSize="large" sx={{ pt: 1, ml: 3 }} color="info">
                  group
                </Icon>
                <MDTypography ml={2} variant="title" fontWeight="medium">
                  Buat Pelanggan Baru
                </MDTypography>
              </MDBox>
              <Icon sx={{ cursor: "pointer" }} onClick={props.onClose} fontSize="large">
                clear
              </Icon>
            </MDBox>
          </Grid>
          <Grid item>
            <MDBox sx={{ width: "100%" }}>
              <Grid container direction="row" justifyContent="space-around" spacing={2} mt={1}>
                <Grid item>
                  <MDInput
                    id="npwp"
                    name="npwp"
                    label="NPWP"
                    value={npwp}
                    onChange={(e) => setNPWP(e.target.value)}
                    onBlur={onNPWPBlur}
                    placeholder="Isi dengan NPWP milik pelanggan"
                    required
                    sx={{ width: 300 }}
                  />
                  <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                    {showError.npwp ? helperMsg.npwp : ""}{" "}
                  </MDTypography>
                </Grid>
                <Grid item>
                  <Autocomplete
                    disablePortal
                    id="badan_usaha"
                    options={badan_usaha}
                    onChange={(e, v) => onChangeTipe(v)}
                    sx={{ width: 300 }}
                    onBlur={onTipeBlur}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => <TextField required {...params} label="Badan Usaha" />}
                  />
                  <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                    {showError.tipe ? helperMsg.tipe : ""}{" "}
                  </MDTypography>
                </Grid>
              </Grid>
              <Grid container direction="row" justifyContent="space-around" spacing={2} mt={1}>
                <Grid item>
                  <MDInput
                    id="nama"
                    name="nama"
                    label="Nama Pelanggan"
                    value={namaPerusahaan}
                    onBlur={onNamaBlur}
                    onChange={(e) => setNamaPerusahaan(e.target.value)}
                    placeholder="Isi dengan nama perusahaan atau perorangan"
                    required
                    sx={{ width: 300 }}
                  />
                  <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                    {showError.namaPerusahaan ? helperMsg.namaPerusahaan : ""}{" "}
                  </MDTypography>
                </Grid>
                <Grid item>
                  <MDInput
                    id="alamat"
                    name="alamat"
                    label="Alamat Pelanggan"
                    value={alamat}
                    onBlur={onAlamatBlur}
                    onChange={(e) => setAlamat(e.target.value)}
                    placeholder="Isi dengan alamat pelanggan"
                    required
                    sx={{ width: 300 }}
                  />
                  <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                    {showError.alamat ? helperMsg.alamat : ""}{" "}
                  </MDTypography>
                </Grid>
              </Grid>
              <Grid container direction="row" justifyContent="space-around" spacing={2} mt={1}>
                <Grid item>
                  <MDInput
                    id="no_telp"
                    name="no_telp"
                    label="Nomor Telepon Pelanggan"
                    value={telepon}
                    onBlur={onTelpBlur}
                    onChange={(e) => setTelepon(e.target.value)}
                    placeholder="Isi dengan nomor telepon perusahaan"
                    sx={{ width: 300 }}
                  />
                  <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                    {showError.validTelepon ? helperMsg.validTelepon : ""}{" "}
                  </MDTypography>
                </Grid>
                <Grid item>
                  <MDInput
                    id="email"
                    name="email"
                    type="email"
                    label="Email Pelanggan"
                    value={email}
                    onBlur={onEmailBlur}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Isi dengan alamat email perusahaan"
                    sx={{ width: 300 }}
                  />
                  <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                    {showError.validEmail ? helperMsg.validEmail : ""}{" "}
                  </MDTypography>
                </Grid>
              </Grid>
              <Grid container direction="row" justifyContent="space-around" spacing={2} mt={1}>
                <Grid item>
                  <MDInput
                    id="nama_pic"
                    name="nama_pic"
                    label="Nama PIC"
                    value={namaPIC}
                    onBlur={onNamaPICBlur}
                    onChange={(e) => setNamaPIC(e.target.value)}
                    placeholder="Isi dengan nama PIC perusahaan"
                    required
                    sx={{ width: 300 }}
                  />
                  <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                    {showError.namaPIC ? helperMsg.namaPIC : ""}{" "}
                  </MDTypography>
                </Grid>
                <Grid item>
                  <MDInput
                    id="no_telp_pic"
                    name="no_telp_pic"
                    label="Nomor Telepon PIC"
                    value={teleponPIC}
                    onBlur={onTelpPICBlur}
                    onChange={(e) => setTeleponPIC(e.target.value)}
                    placeholder="Isi dengan nomor telepon PIC"
                    required
                    sx={{ width: 300 }}
                  />
                  <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                    {showError.teleponPIC ? helperMsg.teleponPIC : ""}{" "}
                  </MDTypography>
                  <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                    {showError.validTeleponPIC ? helperMsg.validTeleponPIC : ""}{" "}
                  </MDTypography>
                </Grid>
              </Grid>
              <Grid container justifyContent="space-around" spacing={2} mt={1}>
                <Grid item>
                  <MDInput
                    id="email_pic"
                    name="email_pic"
                    type="email"
                    label="Email PIC"
                    value={emailPIC}
                    onBlur={onEmailPICBlur}
                    onChange={(e) => setEmailPIC(e.target.value)}
                    placeholder="Isi dengan email PIC"
                    required
                    sx={{ width: 300 }}
                  />
                  <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                    {showError.validEmailPIC ? helperMsg.validEmailPIC : ""}{" "}
                  </MDTypography>
                </Grid>
                <Grid item>
                  <MDBox sx={{ width: 300 }}></MDBox>
                </Grid>
              </Grid>

              <Grid container justifyContent="end" mt={5}>
                <Grid item>
                  <MDBox sx={{ width: 300 }}></MDBox>
                </Grid>
                <MDTypography color="error" my={1} px={1} sx={{ fontSize: 10 }}>
                  {showError.all ? helperMsg.all : ""}{" "}
                </MDTypography>
                <MDTypography color="error" my={1} px={1} sx={{ fontSize: 10 }}>
                  {showError.fail ? helperMsg.fail : ""}{" "}
                </MDTypography>
                <MDTypography color="error" my={1} px={1} sx={{ fontSize: 10 }}>
                  {showError.failDetail ? helperMsg.failDetail : ""}{" "}
                </MDTypography>
                <Grid item>
                  <MDButton variant="contained" color="info" onClick={onAgree}>
                    Buat Pelanggan
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </div>
  );
}

export default NewCustomer;
