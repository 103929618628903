
import Modal from '@mui/material/Modal';
import Persetujuan from '../layouts/persetujuan';



const PersetujuanModal = (props) => {

    return(
        <Modal
            {...props}
        >
            <div>
                <Persetujuan onClose={props.onClose} idfinancing={props.idfinancing} rowfinancing={props.rowfinancing} />
            </div>
        </Modal>
    );
}

export default PersetujuanModal;