import Modal from "@mui/material/Modal";
import SettlementNonFinancing from "layouts/createSettlementRequestNonFinancing";

function SettlementNonFinancingModal(props) {
  return (
    <Modal {...props}>
      <div>
        <SettlementNonFinancing
          onClose={props?.onClose}
          onSubmit={props?.onSubmit}
          id={props?.id}
          type={props?.type}
          invoiceData={props?.invoiceData}
          data={props?.data}
          // invoiceDataPagination={props.invoiceDataPagination}
        />
      </div>
    </Modal>
  );
}

export default SettlementNonFinancingModal;
