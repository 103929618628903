import { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SimpleDashboardNavbar from "examples/Navbars/SimpleDashboardNavbar";

import { fetchKycPeminjam, initKycPeminjam } from '../../actions/kycPeminjamAction';
import { transformKycPeminjam } from '../../common/Util';
import LVPeminjamKycDataGrid from 'components/LVPeminjamKycDataGrid';

const KycPeminjam = () => {
    const [page, setPage] = useState(1);
    const [orderBy, setOrderBy] = useState('id');
    const [order, setOrder] = useState('desc');
    const [keyword, setKeyword] = useState('');
    const [limit, setLimit] = useState(10);

    const [keywordTimeout, setKeywordTimeout] = useState();


    const listKycPeminjam = useSelector(
        state => {
            return state.listKycPeminjam;
        },
        shallowEqual
    );

    const listKycPeminjamPagination = useSelector(
        state => {
            return state.listKycPeminjamPagination;
        },
        shallowEqual
    );

    const dispatch = useDispatch();

    const onChangeKeywordHandler = (e) => {
        let keyword = e.target.value;
        if (keyword.length >= 3) {
            clearTimeout(keywordTimeout);
            setKeywordTimeout(setTimeout(() => setKeyword(keyword), 500));
            return
        }

    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode == 13) {
            let keyword = e.target.value;
            setKeyword(keyword);
            return
        }
    }

    useEffect(() => {
    }, []);

    useEffect(() => {
        dispatch(fetchKycPeminjam({ page, orderBy, order, keyword, limit }));
        return () => {
            dispatch(initKycPeminjam());
        };
    }, [page, order, orderBy, keyword, limit]);

    return (
        <DashboardLayout>
            <SimpleDashboardNavbar name="KYC Peminjam" />
            <Grid container pt={3} justifyContent="space-between">
                <Grid item />
                <Grid item width="90%">
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <MDBox pr={2} my="auto">
                                <MDInput label="Cari nama peminjam..." sx={{ width: "300px" }}
                                    onChange={(e) => onChangeKeywordHandler(e)}
                                    onKeyDown={(e) => onKeyDownHandler(e)}
                                />
                            </MDBox>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <LVPeminjamKycDataGrid
                height={650}
                firstPage={() => setPage(1)}
                lastPage={() => setPage(listKycPeminjamPagination.total_pages)}
                totalPage={listKycPeminjamPagination.total_pages}
                currentPage={page}
                selectPage={(i) => setPage(parseInt(i.target.textContent))}
                nextPage={() => setPage(listKycPeminjamPagination.next_page)}
                prevPage={() => setPage(listKycPeminjamPagination.prev_page)}
                orderBy={(orderBy) => setOrderBy(orderBy)}
                order={(order) => setOrder(order)}
                checkboxSelection={false}
                radioSelection={false}
                rows={transformKycPeminjam(listKycPeminjam, {
                    ...listKycPeminjamPagination,
                    limit: parseInt(limit)
                })}
            />
        </DashboardLayout>
    );
}

export default KycPeminjam;