// prop-types is a library for typechecking of props.
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { ListItemButton, ListItemIcon } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import MDBox from "components/MDBox";

import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "examples/Sidebar/styles/sidenavCollapse";

import { useMaterialUIController } from "context";
import colorConfigs from "./styles/colorConfigs";

import { setActiveState } from "./action";

function SidebarItem({ item, active }) {
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;

  const dispatch = useDispatch();

  const setState = () => {
    dispatch(setActiveState(item.state));
  };

  return item.sidebarProps && item.path ? (
    <NavLink key={item.state} to={item.path}>
      <ListItem
        component="li"
        onClick={setState}

      >
        <MDBox
          // {...rest}
          sx={(theme) =>
            collapseItem(theme, {
              active,
              transparentSidenav,
              whiteSidenav,
              darkMode,
              sidenavColor,
            })
          }
        >
          <ListItemIcon

            sx={(theme) =>
              collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
            }
          >
            {typeof item.sidebarProps.icon === "string" ? (
              <Icon sx={(theme) => collapseIcon(theme, { active })}>{item.sidebarProps.icon}</Icon>
            ) : (
              item.sidebarProps.icon
            )}
          </ListItemIcon>
          <ListItemText
            primary={item.sidebarProps.displayText}
            sx={(theme) =>
              collapseText(theme, {
                miniSidenav,
                transparentSidenav,
                whiteSidenav,
                active,
              })
            }
          />
        </MDBox>
      </ListItem>
    </NavLink>
  ) : null;
}

export default SidebarItem;
