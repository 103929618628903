import { useState, useEffect } from 'react';
import { connect, useSelector, useDispatch, shallowEqual } from "react-redux";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SimpleDashboardNavbar from "examples/Navbars/SimpleDashboardNavbar";
import LVCalonPeminjam from 'components/LVCalonPeminjam';
import PersetujuanPeminjamModal from 'modals/persetujuanPeminjamModal';
import { fetchCalonPeminjam, fintechSearchBorrower, rejectCalonPeminjam } from '../../actions/calonPeminjamAction';
import { emptyMessage } from '../../actions';


const CalonPeminjam = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const [openApproveModal, setOpenApproveModal] = useState(false);
    const [idrelation, setidrelation] = useState(null);

    const dispatch = useDispatch();

    const [page, setPage] = useState(1);
    const [orderBy, setOrderBy] = useState('id');
    const [order, setOrder] = useState('desc');
    const [keyword, setKeyword] = useState('');
    const [limit, setLimit] = useState(10);
    const [status, setStatus] = useState(['created', 'approved']);

    const [keywordTimeout, setKeywordTimeout] = useState();

    const [queryParameter, setQueryParameter] = useState({
        page: 1, orderBy: "id", order: "desc", keyword: "", limit: 10,
        status: ['created', 'approved']
    })

    const [loading, setLoading] = useState(false);

    const resetFilter = () => {
        setQueryParameter({
            ...queryParameter,
            status: [], keyword: ""
        })
    }

    const listCalonPeminjam = useSelector(
        state => {
            return state.listCalonPeminjam;
        },
        shallowEqual
    );

    const listBorrowerPagination = useSelector(
        state => {
            return state.listCalonPeminjamPagination;
        },
        shallowEqual
    );

    const message = useSelector(
        state => {
            return state.message;
        },
        shallowEqual
    );

    useEffect(() => {
        dispatch(fintechSearchBorrower(queryParameter));
    }, [queryParameter]);

    useEffect(() => {
        dispatch(fintechSearchBorrower(queryParameter));
    }, []);

    useEffect(() => {
        if (message.status !== '') {
            dispatch(emptyMessage());
            switch (message.status) {
                case 'APPROVE_CALON_PEMINJAM':
                    setOpenApproveModal(false);
                    dispatch(fintechSearchBorrower({ status, page, orderBy, order, keyword, limit }));
                    return;
                case 'REJECT_CALON_PEMINJAM':
                    dispatch(fintechSearchBorrower({ status, page, orderBy, order, keyword, limit }));
                    return;
                default:
                    return;
            }
        }

    }, [message]);

    return (
        <DashboardLayout>
            <SimpleDashboardNavbar name="Calon Peminjam" />
            <LVCalonPeminjam
                firstPage={() => setQueryParameter({ ...queryParameter, page: 1 })}
                lastPage={() => setQueryParameter({ ...queryParameter, page: listBorrowerPagination.total_pages })}
                totalPage={listBorrowerPagination.total_pages}
                currentPage={queryParameter.page}
                selectPage={(i) => setQueryParameter({ ...queryParameter, page: parseInt(i.target.textContent) })}
                nextPage={() => setQueryParameter({ ...queryParameter, page: listBorrowerPagination.next_page })}
                prevPage={() => setQueryParameter({ ...queryParameter, page: listBorrowerPagination.prev_page })}
                order={(orderBy, order) => setQueryParameter({ ...queryParameter, orderBy, order })}

                keyword={(keyword) => setQueryParameter({ ...queryParameter, keyword })}
                resetFilter={(e) => resetFilter(e)}

                rows={listCalonPeminjam.map((isi) => ({ ...isi, id: isi.relation_id }))}
                pageSize={limit}
                onPersetujuan={(id) => {
                    setidrelation(id);
                    setOpenApproveModal(true);
                }
                }
                onPenolakan={(id) => {
                    dispatch(rejectCalonPeminjam({ id }))
                }}
                businessType={user.company.business_type}
            />
            <PersetujuanPeminjamModal
                open={openApproveModal}
                peminjam
                idrelation={idrelation}
                onClose={(e) => {
                    setOpenApproveModal(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            />
        </DashboardLayout>
    );
}

export default CalonPeminjam;