/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, matchPath } from "react-router-dom";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import App from "App";
import reducers from './reducers';
import thunk from 'redux-thunk';
import "./components/css/app.css";
// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
import EmailSuccess from "layouts/emailSuccess";
import EmailError from "layouts/emailError";
import RegisterSuccess from "layouts/registerSuccess";
import RegisterError from "layouts/registerError";

import ReactGA from "react-ga4";

ReactGA.initialize([
  {
    "trackingId": process.env.REACT_APP_GTM_TRACKING_ID,
  }
]);
 
const store = createStore(reducers, applyMiddleware(thunk));

const matchEmailSuccess = matchPath( {
  path: '/customer/invoice/approve/success',
  exact: true,
  strict: true,
}, location.pathname );

const matchEmailError = matchPath( {
  path: '/customer/invoice/approve/error',
  exact: true,
  strict: true,
}, location.pathname );

const matchRegisterSuccess = matchPath( {
  path: '/register/activate/success',
  exact: true,
  strict: true,
}, location.pathname );

const matchRegisterError = matchPath( {
  path: '/register/activate/error',
  exact: true,
  strict: true,
}, location.pathname );


if (matchEmailSuccess) {
  ReactDOM.render(
    <EmailSuccess></EmailSuccess>,
    document.getElementById("root")
  );
} else if (matchEmailError) {  
  ReactDOM.render(
    <EmailError></EmailError>,
    document.getElementById("root")
  );
} else if (matchRegisterSuccess) {
  ReactDOM.render(
    <RegisterSuccess></RegisterSuccess>,
    document.getElementById("root")
  );
} else if (matchRegisterError) {  
  ReactDOM.render(
    <RegisterError></RegisterError>,
    document.getElementById("root")
  );
} else {
  ReactDOM.render(
    <BrowserRouter>
      <MaterialUIControllerProvider>
         <Provider store={store}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <App />
            </LocalizationProvider>
          </Provider> 
      </MaterialUIControllerProvider>
    </BrowserRouter>,
    document.getElementById("root")
  );  
}
