import Modal from '@mui/material/Modal';
import PelunasanPengajuan from 'layouts/pelunasanPengajuan';

const PelunasanPengajuanModal = (props) => {

    return(
        <Modal
            {...props}
        >
            <div>
                <PelunasanPengajuan onClose={props.onClose} datapengajuan={props.datapengajuan}/>
            </div>
        </Modal>
    );
}

export default PelunasanPengajuanModal;