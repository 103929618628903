import { useState } from "react";
import { useLocation, useParams, useNavigate, Link } from "react-router-dom";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import Radio from "@mui/material/Radio";
import Tooltip from "@mui/material/Tooltip";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import PreviewIcon from "@mui/icons-material/Preview";
import AddIcon from "@mui/icons-material/Add";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import CustomFooter from "components/LVCustomFooter";
import Chip from '@mui/material/Chip';

function LVDaftarRelationFintech(props) {
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: props.viewOnly ? false : true, availability: props.viewOnly ? false : true });
  const [selectionModel, setSelectionModel] = useState([]);
  // const invoices = props.rows;
  const [selected, setSelected] = useState();

  const columns = [
    {
      field: "id",
      headerName: " ",
      width: 40,
      renderCell: (cellValues) => {
        if (props.radioSelection) {
          return (
            <Radio
              checked={cellValues.id === selected}
              onChange={() => onRadioChange(cellValues)}
              value={cellValues.id}
              name="radio-buttons"
              inputProps={{ "aria-label": { cellValues } }}
            />
          );
        }
      },
    },
    {
      field: "no",
      headerName: "No.",
      headerAlign: "center",
      align: "center",
      width: 50,
    },
    {
      field: "name",
      headerName: "Nama Pendana",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 250,
    },
    {
      field: "availability",
      headerName: "Status",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value === true || params.value === "true" ? "Belum Terhubung" : "Terhubung"}>
          {
            params.value === true || params.value === "true" ?
              (<Chip label="Belum Terhubung" color="primary"/>) :
              (<Chip label="Terhubung" variant="outlined" />)
          }
        </Tooltip>
      ),
      width: 200,
    },
    {
      field: "tkb",
      headerName: "TKB",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 80,
    },
  ];

  const onRadioChange = (cellValues) => {
    if (cellValues.row.availability === true || cellValues.row.availability === "true") {
      props.onSelectionModelChange([cellValues.row.fintech_id, cellValues.row.shipper_id, cellValues.row.id]);
      setSelectionModel([cellValues.id]);
      setSelected(cellValues.id);
    }
  };

  function CustomFooterProps() {
    return (
      <CustomFooter
        page={props.totalPage}
        selectPage={props.selectPage}
        nextPage={props.nextPage}
        prevPage={props.prevPage}
        currentPage={props.currentPage}
        firstPage={props.firstPage}
        lastPage={props.lastPage}
      />
    );
  }

  return (
    <MDBox
      mt={5}
      sx={{
        height: 400,
        width: "100%",
      }}
    >
      <DataGrid
        columns={columns}
        rows={props.rows}
        pageSize={5}
        rowsPerPageOptions={[5]}
        localeText={{ noRowsLabel: "Belum ada daftar pendana." }}
        checkboxSelection={props.checkboxSelection}
        disableSelectionOnClick
        sx={{
          bgcolor: "rgba(255, 255, 255, 1)",
          borderRadius: "16px",
          fontSize: "small",
          boxShadow: "10px 15px 27px -28px #1A73E8",
        }}
        selectionModel={selectionModel}
        onSelectionModelChange={(newSelectionModel) => {
          if (props.onSelectionModelChange) {
            props.onSelectionModelChange(newSelectionModel);
          }
          setSelectionModel(newSelectionModel);
        }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => {
          newModel.id = false;
          setColumnVisibilityModel(newModel);
        }}
        components={{ Footer: CustomFooterProps }}
      />
    </MDBox>
  );
}

export default LVDaftarRelationFintech;
