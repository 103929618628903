/* eslint-disable no-nested-ternary */
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SimpleDashboardNavbar from "examples/Navbars/SimpleDashboardNavbar";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { updatePassword, updateUserProfile, updateCompanyProfile } from "actions/authAction";
import { getUserDetail } from "actions/authAction";
import { useNavigate } from "react-router-dom";
import { getCompany } from "actions/companyAction";
import { Icon } from "@mui/material";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function EditProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const anCompany = useSelector((state) => state.anCompany);
  const anUser = useSelector((state) => state.anUser);

  const [layout, setLayout] = useState("pengguna");
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyNumber, setCompanyNumber] = useState("");
  const [broker, setBroker] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showError, setshowError] = useState({
    name: false,
    phone: false,
    email: false,
    companyName: false,
    companyNumber: false,
    borker: false,
    companyAddress: false,
    companyEmail: false,
    oldPassword: false,
    password: false,
    confirmPassword: false,
  });

  useEffect(() => {
    dispatch(getUserDetail());
    dispatch(getCompany());
  }, [layout]);

  useEffect(() => {
    setName(anUser?.name);
    setPhone(anUser?.phone);
    setEmail(anUser?.email);
    setOldPassword("");
    setPassword("");
    setConfirmPassword("");
  }, [anUser]);

  useEffect(() => {
    setCompanyName(anCompany?.name);
    setCompanyNumber(anCompany?.phone);
    setBroker(anCompany?.business_type);
    setCompanyAddress(anCompany?.address);
    setCompanyEmail(anCompany?.email);
    setOldPassword("");
    setPassword("");
    setConfirmPassword("");
  }, [anCompany]);

  const onNameBlur = () => {
    if (name.trim() === "") {
      // formik.setFieldValue('number', '');
      setName("");
      setshowError({ ...showError, name: true });
    }
  };
  const onPhoneBlur = () => {
    if (phone.trim() === "") {
      // formik.setFieldValue('number', '');
      setPhone("");
      setshowError({ ...showError, phone: true });
    }
  };
  const onEmailBlur = () => {
    if (email.trim() === "") {
      // formik.setFieldValue('number', '');
      setEmail("");
      setshowError({ ...showError, email: true });
    }
  };
  const onCompanyNameBlur = () => {
    if (companyName.trim() === "") {
      // formik.setFieldValue('number', '');
      setCompanyName("");
      setshowError({ ...showError, companyName: true });
    }
  };
  const onCompanyNumberBlur = () => {
    if (companyNumber.trim() === "") {
      // formik.setFieldValue('number', '');
      setCompanyNumber("");
      setshowError({ ...showError, companyNumber: true });
    }
  };
  const onBrokerBlur = () => {
    if (broker.trim() === "") {
      // formik.setFieldValue('number', '');
      setBroker("");
      setshowError({ ...showError, broker: true });
    }
  };
  const onCompanyAddressBlur = () => {
    if (companyAddress.trim() === "") {
      // formik.setFieldValue('number', '');
      setCompanyAddress("");
      setshowError({ ...showError, companyAddress: true });
    }
  };
  const onCompanyEmailBlur = () => {
    if (companyEmail.trim() === "") {
      // formik.setFieldValue('number', '');
      setCompanyEmail("");
      setshowError({ ...showError, companyEmail: true });
    }
  };
  const onOldPasswordBlur = () => {
    if (oldPassword.trim() === "") {
      // formik.setFieldValue('number', '');
      setOldPassword("");
      setshowError({ ...showError, oldPassword: true });
    }
  };
  const onPasswordBlur = () => {
    if (password.trim() === "") {
      // formik.setFieldValue('number', '');
      setPassword("");
      setshowError({ ...showError, password: true });
    }
  };
  const onConfirmPasswordBlur = () => {
    if (confirmPassword.trim() === "") {
      // formik.setFieldValue('number', '');
      setConfirmPassword("");
      setshowError({ ...showError, confirmPassword: true });
    }
  };

  const handleUpdate = () => {
    if (layout === "pengguna") {
      dispatch(
        updateUserProfile({
          name,
        })
      );
    }
    if (layout === "perusahaan") {
      dispatch(
        updateCompanyProfile({
          address: companyAddress,
        })
      );
    }
    if (layout === "password") {
      dispatch(
        updatePassword({
          oldPassword,
          password,
        })
      );
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const authToken = useSelector((state) => state.authToken);

  useEffect(() => {
    if (authToken.isEditSuccess) {
      setSuccess(true);
    }
    if (authToken.isEditSuccess === false) {
      setFailed(true);
    }
  }, [authToken]);

  useEffect(() => {
    setTimeout(() => {
      setSuccess(false);
      setFailed(false);
    }, 3000);
  }, [success]);

  const handleCancel = () => {
    navigate("/");
  };

  return (
    <DashboardLayout>
      <SimpleDashboardNavbar name="Pengaturan Profil" />
      <MDBox
        mt={5}
        bgColor="white"
        pr={5}
        py={8}
        shadow="md"
        borderRadius="xl"
        sx={{ position: "relative" }}
      >
        <MDBox
          right={0}
          top={-20}
          bgColor={success ? "info" : "error"}
          shadow="md"
          borderRadius="xl"
          p={2}
          display={success || failed ? "flex" : "none"}
          sx={{ position: "absolute", color: "#ffffff", fontSize: "14px" }}
        >
          <img src={success ? "/check.svg" : "/error.svg" } alt="" />
          <MDBox ml={2} sx={{ color: "#ffffff" }}>
            {success ? "Perubahan berhasil disimpan" : "Gagal membuat perubahan"}
          </MDBox>
        </MDBox>
        <MDBox display="flex">
          <MDBox
            display="flex"
            flexDirection="column"
            width="20%"
            mr={8}
            sx={{ borderRight: "2px solid #e1e1e1" }}
          >
            <div onClick={() => setLayout("pengguna")}>
              <MDBox
                display="flex"
                mb={5}
                sx={{
                  cursor: "pointer",
                  borderLeft: layout === "pengguna" ? "6px solid #1A73E8" : "6px solid #ffffff",
                }}
              >
                <MDBox mx={2} display="flex" alignItems="center" my="auto">
                  <img src="/user.svg" alt="" />
                </MDBox>
                <MDTypography variant="h6" fontWeight="regular">
                  Profil Pengguna
                </MDTypography>
              </MDBox>
            </div>
            <div onClick={() => setLayout("perusahaan")}>
              <MDBox
                display="flex"
                mb={5}
                sx={{
                  cursor: "pointer",
                  borderLeft: layout === "perusahaan" ? "6px solid #1A73E8" : "6px solid #ffffff",
                }}
              >
                <MDBox mx={2} display="flex" alignItems="center" my="auto">
                  <img src="/buildings-small.svg" alt="" />
                </MDBox>
                <MDTypography variant="h6" fontWeight="regular">
                  Profil Perusahaan
                </MDTypography>
              </MDBox>
            </div>
            <div onClick={() => setLayout("password")}>
              <MDBox
                display="flex"
                mb={5}
                sx={{
                  cursor: "pointer",
                  borderLeft: layout === "password" ? "6px solid #1A73E8" : "6px solid #ffffff",
                }}
              >
                <MDBox mx={2} display="flex" alignItems="center" my="auto">
                  <img src="/password.svg" alt="" />
                </MDBox>
                <MDTypography variant="h6" fontWeight="regular">
                  Ganti Password
                </MDTypography>
              </MDBox>
            </div>
          </MDBox>
          {layout === "pengguna" ? (
            <>
              <MDBox width="35%">
                <MDBox display="flex" mb={2} flexDirection="column">
                  <MDTypography mb={1} variant="body3">
                    Nama
                  </MDTypography>
                  <MDInput
                    id="name"
                    name="name"
                    placeholder="Isi dengan nama anda"
                    onChange={(event) => setName(event.target.value)}
                    value={name}
                    onBlur={onNameBlur}
                    sx={{ width: 300 }}
                    error={showError.name}
                    required
                  />
                </MDBox>
                <MDBox display="flex" mb={2} flexDirection="column">
                  <MDTypography mb={1} variant="body3">
                    Nomor Telepon
                  </MDTypography>
                  <MDInput
                    id="phone"
                    name="phone"
                    placeholder="Isi dengan nomor telepon anda"
                    onChange={(event) => setPhone(event.target.value)}
                    value={anUser?.phone}
                    onBlur={onPhoneBlur}
                    sx={{ width: 300 }}
                    error={showError.phone}
                    // required
                    disabled
                  />
                </MDBox>
                <MDBox display="flex" mb={2} flexDirection="column">
                  <MDTypography mb={1} variant="body3">
                    Email
                  </MDTypography>
                  <MDInput
                    id="email"
                    name="email"
                    placeholder="Isi dengan email anda"
                    onChange={(event) => setEmail(event.target.value)}
                    value={anUser?.email}
                    onBlur={onEmailBlur}
                    sx={{ width: 300 }}
                    error={showError.email}
                    // required
                    disabled
                  />
                </MDBox>
              </MDBox>
              {/* <MDBox width="35%">
                <MDBox display="flex" mb={2} flexDirection="column">
                  <MDTypography mb={1} variant="body3">
                    Alamat
                  </MDTypography>
                  <MDInput
                    id="address"
                    name="address"
                    placeholder="Isi dengan alamat anda"
                    onChange={(event) => setAddress(event.target.value)}
                    value={address}
                    onBlur={onAddressBlur}
                    sx={{ width: 300 }}
                    error={showError.address}
                    required
                  />
                </MDBox>
              </MDBox> */}
            </>
          ) : layout === "perusahaan" ? (
            <>
              <MDBox width="35%">
                <MDBox display="flex" mb={2} flexDirection="column">
                  <MDTypography mb={1} variant="body3">
                    Nama Perusahaan
                  </MDTypography>
                  <MDInput
                    id="companyName"
                    name="companyName"
                    placeholder="Isi dengan nama perusahaan anda"
                    onChange={(event) => setCompanyName(event.target.value)}
                    value={companyName}
                    onBlur={onCompanyNameBlur}
                    sx={{ width: 300 }}
                    error={showError.companyName}
                    // required
                    disabled
                  />
                </MDBox>
                <MDBox display="flex" mb={2} flexDirection="column">
                  <MDTypography mb={1} variant="body3">
                    Nomor telepon perusahaan
                  </MDTypography>
                  <MDInput
                    id="companyNumber"
                    name="companyNumber"
                    placeholder="Isi dengan Nomor telepon perusahaan anda"
                    onChange={(event) => setCompanyNumber(event.target.value)}
                    value={companyNumber}
                    onBlur={onCompanyNumberBlur}
                    sx={{ width: 300 }}
                    error={showError.companyNumber}
                    // required
                    disabled
                  />
                </MDBox>
                <MDBox display="flex" mb={2} flexDirection="column">
                  <MDTypography mb={1} variant="body3">
                    Email Perusahaan
                  </MDTypography>
                  <MDInput
                    id="companyEmail"
                    name="companyEmail"
                    placeholder="Isi dengan email perusahaan anda"
                    onChange={(event) => setCompanyEmail(event.target.value)}
                    value={companyEmail}
                    onBlur={onCompanyEmailBlur}
                    sx={{ width: 300 }}
                    error={showError.companyEmail}
                    // required
                    disabled
                  />
                </MDBox>
              </MDBox>
              <MDBox width="35%">
                <MDBox display="flex" mb={2} flexDirection="column">
                  <MDTypography mb={1} variant="body3">
                    Alamat Perusahaan
                  </MDTypography>
                  <MDInput
                    id="companyAddress"
                    name="companyAddress"
                    placeholder="Isi dengan alamat perusahaan anda"
                    onChange={(event) => setCompanyAddress(event.target.value)}
                    value={companyAddress}
                    onBlur={onCompanyAddressBlur}
                    sx={{ width: 300 }}
                    error={showError.companyAddress}
                    required
                  />
                </MDBox>
                <MDBox display="flex" mb={2} flexDirection="column">
                  <MDTypography mb={1} variant="body3">
                    Badan Usaha
                  </MDTypography>
                  <MDInput
                    id="broker"
                    name="broker"
                    placeholder="Isi dengan Badan usaha anda"
                    onChange={(event) => setBroker(event.target.value)}
                    value={broker}
                    onBlur={onBrokerBlur}
                    sx={{ width: 300 }}
                    error={showError.broker}
                    // required
                    disabled
                  />
                </MDBox>
              </MDBox>
            </>
          ) : layout === "password" ? (
            <MDBox width="35%">
              <MDBox display="flex" mb={2} flexDirection="column">
                <MDTypography mb={1} variant="body3">
                  Password Lama
                </MDTypography>
                <MDInput
                  id="oldPassword"
                  name="oldPassword"
                  type={showPassword ? "text" : "password"}
                  placeholder="Isi dengan Password Lama anda"
                  onChange={(event) => setOldPassword(event.target.value)}
                  value={oldPassword}
                  onBlur={onOldPasswordBlur}
                  sx={{ width: 300 }}
                  error={showError.oldPassword}
                  required
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                />
              </MDBox>
              <MDBox display="flex" mb={2} flexDirection="column">
                <MDTypography mb={1} variant="body3">
                  Password Baru
                </MDTypography>
                <MDInput
                  id="password"
                  name="password"
                  type={showNewPassword ? "text" : "password"}
                  placeholder="Isi dengan Password Baru anda"
                  onChange={(event) => setPassword(event.target.value)}
                  value={password}
                  onBlur={onPasswordBlur}
                  sx={{ width: 300 }}
                  error={showError.password}
                  required
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPassword}
                        onMouseDown={handleMouseDownNewPassword}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                />
              </MDBox>
              <MDBox display="flex" mb={2} flexDirection="column">
                <MDTypography mb={1} variant="body3">
                  Konfirmasi Password Baru
                </MDTypography>
                <MDInput
                  id="confirmPassword"
                  name="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="konfirmasi password baru"
                  onChange={(event) => setConfirmPassword(event.target.value)}
                  value={confirmPassword}
                  onBlur={onConfirmPasswordBlur}
                  sx={{ width: 300 }}
                  error={showError.confirmPassword}
                  required
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                />
              </MDBox>
            </MDBox>
          ) : null}
        </MDBox>
        <MDBox
          display="flex"
          px={3}
          py={1}
          borderRadius="xl"
          width="50%"
          mx="auto"
          mt={8}
          sx={{ border: "2px solid #0D6ECD" }}
        >
          <Icon color="info">infooutlinedicon</Icon>
          <MDTypography ml={1} sx={{ fontSize: "14px" }}>
            Ingin mengganti data perusahaan yang terkunci? Silahkan
          </MDTypography>
          <MDTypography ml={0.5} sx={{ fontSize: "14px", color: "#0D6ECD", fontWeight: "bold" }}>
            hubungi admin.
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="end" mt={8}>
          <MDBox>
            <MDButton variant="outlined" color="info" size="small" onClick={handleCancel}>
              Batalkan perubahan
            </MDButton>
          </MDBox>
          <MDBox ml={3}>
            <MDButton variant="contained" onClick={handleUpdate} color="info" size="small">
              Simpan perubahan
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default EditProfile;
