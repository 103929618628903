import serverList from "../apis/serverList";
import axios from "axios";
import authHeader from "../apis/authHeader";

export const fetchKycPeminjam = (input) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI.get("/api/v1/admin/companies/kyc/search", {
    params: {
      page: input.page,
      order_by: input.orderBy,
      order: input.order,
      keyword: input.keyword,
      limit: input.limit,
    },
  });
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  if (response.data.status === "OK") {
    dispatch({ type: "FETCH_KYC_PEMINJAM_LIST", payload: response.data.data.result });
    dispatch({ type: "FETCH_KYC_PEMINJAM_PAGINATION", payload: response.data.data.pagination });
  }
};

export const fetchCustomerDetail = (companyID) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI.get("/api/v1/fintech/company", {
    params: {
      id: companyID,
    },
  });
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  if (response.data.status === "OK") {
    dispatch({ type: "FETCH_CUSTOMER_DETAIL", payload: response.data.data });
  }
};

export const fetchCompanyDetail = (companyID) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI.get("/api/v1/fintech/company", {
    params: {
      id: companyID,
    },
  });
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  if (response.data.status === "OK") {
    dispatch({ type: "FETCH_COMPANY_DETAIL", payload: response.data.data });
  }
};

export const adminFetchCustomerDetail = (companyID) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI.get("/api/v1/admin/company", {
    params: {
      id: companyID,
    },
  });
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  if (response.data.status === "OK") {
    dispatch({ type: "FETCH_CUSTOMER_DETAIL", payload: response.data.data });
  }
};

export const initKycPeminjam = () => {
  return {
    type: "INIT_KYC_PEMINJAM_LIST",
  };
};

export const adminFetchKYCDocs = (company_id) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI
    .get("/api/v1/admin/company/kyc", {
      params: {
        company_id,
      },
    })
    .catch((e) => {});
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  if (response !== undefined) {
    if (response.data.status === "OK") {
      dispatch({ type: "FETCH_KYC_DOCS_ARRAY", payload: response.data.data });
    }
  }
};

export const fetchKYCDocs = (company_id) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI
    .get("/api/v1/fintech/company/kyc", {
      params: {
        company_id,
      },
    })
    .catch((e) => {});
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  if (response !== undefined) {
    if (response.data.status === "OK") {
      dispatch({ type: "FETCH_KYC_DOCS_ARRAY", payload: response.data.data });
    }
  }
};

export const approveKYCDocsAdmin = (id) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI
    .put("/api/v1/admin/company/kyc/document/approval", {
      id,
    })
    .catch((e) => {});
  if (response !== undefined) {
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
    if (response.data.status === "OK") {
      dispatch({
        type: "APPROVE_KYC_DOCUMENT",
        payload: { status: "APPROVE_KYC_DOCUMENT", message: id },
      });
    }
  }
};

export const rejectKYCDocsAdmin =
  ({ id, reason }) =>
  async (dispatch) => {
    const companyAPI = axios.create({
      baseURL: serverList.company,
      headers: authHeader(),
    });
    const response = await companyAPI
      .put("/api/v1/admin/company/kyc/document/rejection", {
        id,
        reason,
      })
      .catch((e) => {});
    if (response !== undefined) {
      if (response.status === 401) {
        dispatch({
          type: "ERROR",
          payload: { status: "TOKEN_EXPIRE", message: response.data.message },
        });
      }
      if (response.data.status === "OK") {
        dispatch({
          type: "REJECT_KYC_DOCUMENT",
          payload: { status: "REJECT_KYC_DOCUMENT", message: id },
        });
      }
    }
  };

export const approveKYCPhoneAdmin = (id) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI
    .put("/api/v1/admin/company/kyc/phone/approval", {
      id,
    })
    .catch((e) => {});
  if (response !== undefined) {
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
    if (response.data.status === "OK") {
      dispatch({
        type: "APPROVE_KYC_DOCUMENT",
        payload: { status: "APPROVE_KYC_DOCUMENT", message: id },
      });
    }
  }
};

export const rejectKYCPhoneAdmin =
  ({ id, reason }) =>
  async (dispatch) => {
    const companyAPI = axios.create({
      baseURL: serverList.company,
      headers: authHeader(),
    });
    const response = await companyAPI
      .put("/api/v1/admin/company/kyc/phone/rejection", {
        id,
        reason,
      })
      .catch((e) => {});
    if (response !== undefined) {
      if (response.status === 401) {
        dispatch({
          type: "ERROR",
          payload: { status: "TOKEN_EXPIRE", message: response.data.message },
        });
      }
      if (response.data.status === "OK") {
        dispatch({
          type: "REJECT_KYC_DOCUMENT",
          payload: { status: "REJECT_KYC_DOCUMENT", message: id },
        });
      }
    }
  };
