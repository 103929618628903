import axios from "axios";
import serverList from "../apis/serverList";
import authHeader from "../apis/authHeader";

export const initAnPengajuan = () => {
  return {
    type: "INIT_AN_FINANCING",
  };
};

export const getPengajuan =
  ({ request_id }) =>
  async (dispatch) => {
    const financingAPI = axios.create({
      baseURL: serverList.financing,
      headers: authHeader(),
    });
    const response = await financingAPI.get("/api/v1/financing", {
      params: {
        request_id,
      },
    });
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
    dispatch({ type: "GET_FINANCING", payload: response.data.data });
  };

export const getPengajuanQP =
  ({ request_id }) =>
  async (dispatch) => {
    const financingAPI = axios.create({
      baseURL: serverList.financing,
      headers: authHeader(),
    });
    const response = await financingAPI.get("/api/v1/financing/quickpay", {
      params: {
        request_id,
      },
    });
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
    dispatch({ type: "GET_FINANCING", payload: response.data.data });
  };

export const getPengajuanFintech =
  ({ request_id }) =>
  async (dispatch) => {
    const financingAPI = axios.create({
      baseURL: serverList.financing,
      headers: authHeader(),
    });
    const response = await financingAPI.get("/api/v1/fintech/financing", {
      params: {
        request_id,
      },
    });
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
    dispatch({ type: "GET_FINANCING", payload: response.data.data });
  };

export const getPengajuanPurchase =
  ({ request_id }) =>
  async (dispatch) => {
    const financingAPI = axios.create({
      baseURL: serverList.financing,
      headers: authHeader(),
    });
    const response = await financingAPI.get("/api/v1/financing/purchase", {
      params: {
        request_id,
      },
    });
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
    dispatch({ type: "GET_FINANCING", payload: response.data.data });
  };

export const getPengajuanPurchaseFintech =
  ({ request_id }) =>
  async (dispatch) => {
    const financingAPI = axios.create({
      baseURL: serverList.financing,
      headers: authHeader(),
    });
    const response = await financingAPI.get("/api/v1/fintech/financing/purchase/order", {
      params: {
        request_id,
      },
    });
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
    dispatch({ type: "GET_FINANCING", payload: response.data.data });
  };
