
import Modal from '@mui/material/Modal';
import PersetujuanPeminjam from '../layouts/persetujuanpeminjam';



const PersetujuanPeminjamModal = (props) => {

    return(
        <Modal
            {...props}
        >
            <div>
                <PersetujuanPeminjam onClose={props.onClose} peminjam={props.peminjam} idrelation={props.idrelation} relation={props.relation} />
            </div>
        </Modal>
    );
}

export default PersetujuanPeminjamModal;