
import Modal from '@mui/material/Modal';
import DaftarPelunasanInvoice from '../layouts/daftarPelunasanInvoice';



const DaftarPelunasanInvoiceModal = (props) => {

    return(
        <Modal
            {...props}
        >
            <div>
                <DaftarPelunasanInvoice onClose={props.onClose} type={props.type}/>
            </div>
        </Modal>
    );
}

export default DaftarPelunasanInvoiceModal;