import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";

import { useFormik } from "formik";
import moment from "moment";

import {
  postNewOrderPenjualan,
  getOrderPenjualan,
  putEditOrderPenjualan,
} from "actions/orderPenjualanAction";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SimpleDashboardNavbar from "examples/Navbars/SimpleDashboardNavbar";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import LVProductTransportDataGrid from "components/LVProductTransportDataGrid";
import LVProductGenericDataGrid from "components/LVProductGenericDataGrid";

import { getRandomInt, isValidProductTransport, isValidProductGeneric } from "common/Util";

import ReactGA from "react-ga4";
import CustomerModal from "../../modals/newCustomerModal";
import { signOut } from "../../actions/authAction";
import { fetchPelanggan, postProductFile, emptyMessage } from "../../actions";
import { Icon } from "@mui/material";

function onKeyDown(keyEvent) {

}

function NewOrderPenjualan() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/new-order-penjualan",
      title: "Buat Order Penjualan",
    });
  }, []);

  const user = JSON.parse(localStorage.getItem("user"));

  const [fileSelected, setFileSelected] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [total, setTotal] = useState(null);
  const [subTotal, setSubTotal] = useState(null);
  const [pajakExclusive, setPajakExclusive] = useState(null);
  const [pajakInclusive, setPajakInclusive] = useState(null);
  const [pajakPphExclusive, setPajakPphExclusive] = useState(null);
  const [projectName, setProjectName] = useState("");
  const [inputTOP, setInputTOP] = useState(0);
  const [textKeterangan, setTextKeterangan] = useState("");
  const [textSyaratKetentuan, setTextSyaratKetentuan] = useState("");
  const [selectCompany, setSelectCompany] = useState(null);
  const [listPelangganSelect, setListPelangganSelect] = useState([]);
  const [textDialog, setTextDialog] = useState({ header: "", content: "" });
  const [daftarProductTransport, setDaftarProductTransport] = useState([]);
  const [daftarProductGeneric, setDaftarProductGeneric] = useState([]);
  const [suratJalanLogo, setSuratJalanLogo] = useState(null);
  const [buktiKirimLogo, setBuktiKirimLogo] = useState(null);
  const [showError, setshowError] = useState({
    project_name: false,
    term_of_payment: false,
    select_company: false,
    listProduct: false,
    fieldProductTransport: false,
    fieldProductGeneric: false,
    all: false,
    fail: false,
  });
  const [openCustModal, setOpenCustModal] = useState(false);
  const [helperMsg, setHelperMsg] = useState({
    project_name: "Nama project harus diisi.",
    term_of_payment: "Tempo pembayaran tidak boleh 0 atau kosong.",
    select_company: "Pelanggan harus dipilih.",
    listProduct: "Product harus diisi.",
    fieldProductTransport:
      "Informasi berikut wajib diisi: tipe truk, asal, tujuan, tanggal berangkat, tanggal sampai, harga jual, kuantitas, dan pajak. Pastikan anda sudah menekan tombol Simpan untuk tiap baris.",
    fieldProductGeneric:
      "Informasi berikut wajib diisi: nama, tanggal mulai transaksi, harga jual, kuantitas, dan pajak. Pastikan anda sudah menekan tombol Simpan untuk tiap baris.",
    all: "Masih terdapat informasi wajib yang kosong, silakan diperiksa kembali.",
    fail: "",
  });
  const listPelanggan = useSelector((state) => state.listPelanggan, shallowEqual);

  const listProduct = useSelector((state) => state.listProduct, shallowEqual);
  const message = useSelector((state) => state.message, shallowEqual);
  const anInvoice = useSelector((state) => state.anInvoice, shallowEqual);
  const anOrderPenjualan = useSelector((state) => state.anOrderPenjualan, shallowEqual);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const formik = useFormik({
    initialValues: {
      issued_date: new Date(),
      select_company: null,
      tax: null,
      listJenis: [],
    },
    onSubmit: (values) => {



      let newForm = {};
      let idForm = {};
      if (params.id !== undefined) {
        idForm = {
          id: Number(params.id),
        };
      }
      newForm = {
        header: {
          ...idForm,
          number: `SO-${getRandomInt(1, 100000).toString()}`,
          project_name: projectName,
          customer_company: parseInt(values.select_company.id),
          issued_date: moment(values.issued_date).toISOString(),
          term_of_payment: Number(inputTOP),
        },
        information: [
          {
            type: "remark",
            content: textKeterangan,
          },
          {
            type: "terms_condition",
            content: textSyaratKetentuan,
          },
        ],
        detail: (() => {
          const productTransport = daftarProductTransport;
          const productGeneric = daftarProductGeneric;
          if (productTransport.length !== 0) {

            productTransport.forEach((element, index) => {
              element.departure_date = moment(element.departure_date_iso).format();
              element.arrival_date = moment(element.arrival_date_iso).format();

            });
          }
          if (productGeneric.length !== 0) {

            productGeneric.forEach((element, index) => {
              element.transaction_date = moment(element.transaction_date_iso).format();

            });
          }
          const detail = { transport: productTransport, generic: productGeneric };

          return detail;
        })(),
      };

      if (params.id !== undefined) {
        dispatch(putEditOrderPenjualan(newForm));
      } else {
        dispatch(postNewOrderPenjualan(newForm));
      }
    },
  });

  useEffect(() => {
    dispatch(fetchPelanggan());


    if (params.id !== undefined) {
      const res = decodeURIComponent(params.id);


      dispatch(getOrderPenjualan({ id: res }));
    } else {


    }
    document.getElementById("select_company").focus();


  }, []);

  useEffect(() => {

    if (params.id !== undefined) {
      if (anOrderPenjualan.header) {

        let select_company = {};
        select_company = {
          id: anOrderPenjualan.header.customer_company_id,
          value: anOrderPenjualan.header.customer_company_id,
          label: anOrderPenjualan.header.customer_company,
        };
        setSelectCompany(select_company);
        formik.setFieldValue("select_company", select_company);

        setProjectName(anOrderPenjualan.header.project_name);
        setInputTOP(anOrderPenjualan.header.term_of_payment);

        const tmpTglSO = new Date(anOrderPenjualan.header.issued_date_iso);
        formik.setFieldValue("issued_date", tmpTglSO);
      }
      if (anOrderPenjualan.information) {
        anOrderPenjualan.information.map((isi) => {
          if (isi.type === "remark") {
            setTextKeterangan(isi.content);
          } else if (isi.type === "terms_condition") {
            setTextSyaratKetentuan(isi.content);
          }
        });
      }
      if (anOrderPenjualan.detail !== undefined) {
        dispatch({ type: "FETCH_LIST_PRODUCT", payload: { detail: anOrderPenjualan?.detail } });
      }
    }
  }, [anOrderPenjualan]);


  useEffect(() => {
    setListPelangganSelect([...listPelanggan]);
  }, [listPelanggan]);

  useEffect(() => {
    if (listProduct.header !== undefined) {
      setTotal(listProduct.header.total);
      setSubTotal(listProduct.header.subtotal);
      setPajakExclusive(listProduct.header.tax);
      setPajakInclusive(listProduct.header.inclusive_tax_amount);
      setPajakPphExclusive(listProduct.header.tax_pph);

    }
  }, [listProduct]);

  useEffect(() => {


    if (message.status !== "") {
      dispatch(emptyMessage());
      switch (message.status) {
        case "SUCCESS_NEW_ORDER_PENJUALAN":
          navigate(`/detailorderpenjualan/${encodeURIComponent(message.message)}`);
          return;
        case "SUCCESS_EDIT_ORDER_PENJUALAN":
          navigate(`/detailorderpenjualan/${encodeURIComponent(message.message?.sale_id)}`);
          return;
        case "FAIL_NEW_ORDER_PENJUALAN":
          alert(`Gagal membuat order penjualan. Error: ${message.message}`);
          setshowError({ ...showError, fail: true });
          setHelperMsg({ ...helperMsg, fail: message.message });
          return;
        case "FAIL_EDIT_ORDER_PENJUALAN":
          alert(`Gagal edit order penjualan. Error: ${message.message}`);
          setshowError({ ...showError, fail: true });
          setHelperMsg({ ...helperMsg, fail: message.message });
          return;
        case "SUCCESS_NEW_CUSTOMER":
          var select_company = {};
          select_company = {
            id: message.message.company_id,
            value: message.message.company_id,
            label: message.message.name,
          };
          setSelectCompany(select_company);
          formik.setFieldValue("select_company", select_company);

        default:
      }
    }
  }, [message]);

  useEffect(() => {

    if (inputTOP !== undefined) {
      if (inputTOP !== "") {
        if (Number(inputTOP) <= 0) {
          setInputTOP(0);
        } else if (showError.term_of_payment) {
          setshowError({ ...showError, term_of_payment: false });
        }


      }
    }


  }, [inputTOP]);

  useEffect(() => {

    const tmpTglInv = new Date(formik.values.issued_date);


  }, [formik.values.issued_date]);

  useEffect(() => {
    if (showError.all) {

      if (
        !(
          projectName === "" ||
          inputTOP === "" ||
          Number(inputTOP) === 0 ||
          formik.values.select_company === null ||
          (daftarProductGeneric.length === 0 && daftarProductTransport.length === 0) ||
          !isValidProductTransport({ transport: daftarProductTransport }) ||
          !isValidProductGeneric({ generic: daftarProductGeneric })
        )
      ) {
        setshowError({
          project_name: false,
          term_of_payment: false,
          select_company: false,
          listProduct: false,
          fieldProductTransport: false,
          fieldProductGeneric: false,
          all: false,
        });

      }
    }
  }, [
    projectName,
    inputTOP,
    formik.values.select_company,
    daftarProductGeneric,
    daftarProductTransport,
  ]);


  useEffect(() => {
    if (projectName !== "") {
      if (showError.project_name) {
        setshowError({ ...showError, project_name: false });
      }
    }
  }, [projectName]);

  useEffect(() => {
    if (formik.values.select_company !== null) {
      setshowError({ ...showError, select_company: false });
    }
  }, [formik.values.select_company]);

  useEffect(() => {

    if (fileSelected !== null) {
      dispatch(postProductFile(fileSelected));
      document.getElementById("fileProduct").value = null;
      setFileSelected(null);
      setshowError({ ...showError, listProduct: false });
    }
  }, [fileSelected]);

  useEffect(() => {



    let totalTanpaPPN = 0;
    let totalDenganPPN = 0;
    let ppnExclusive = 0;
    let ppnInclusive = 0;
    var pphExclusive = 0;
    var pphInclusive = 0;
    daftarProductTransport?.map((isi) => {
      totalTanpaPPN += isi.price_total;
      totalDenganPPN += isi.price_ppn_total;
      ppnExclusive += isi.ppn_exclusive;
      ppnInclusive += isi.ppn_inclusive;
      pphExclusive += isi.pph_exclusive;
      pphInclusive += isi.pph_inclusive;
    });
    daftarProductGeneric?.map((isi) => {
      totalTanpaPPN += isi.price_total;
      totalDenganPPN += isi.price_ppn_total;
      ppnExclusive += isi.ppn_exclusive;
      ppnInclusive += isi.ppn_inclusive;
      pphExclusive += isi.pph_exclusive;
      pphInclusive += isi.pph_inclusive;
    });
    setSubTotal(totalTanpaPPN);
    setTotal(totalDenganPPN);
    setPajakExclusive(ppnExclusive);
    setPajakInclusive(ppnInclusive);
    setPajakPphExclusive(pphExclusive);

    if (!(daftarProductGeneric.length === 0 && daftarProductTransport.length === 0)) {
      setshowError({ ...showError, listProduct: false });
    }
  }, [daftarProductTransport, daftarProductGeneric]);

  useEffect(() => {
    if (daftarProductTransport !== null) {
      if (isValidProductTransport({ transport: daftarProductTransport })) {
        setshowError({ ...showError, fieldProductTransport: false });
      }
    }
  }, [daftarProductTransport]);

  useEffect(() => {
    if (daftarProductGeneric !== null) {
      if (isValidProductGeneric({ generic: daftarProductGeneric })) {
        setshowError({ ...showError, fieldProductGeneric: false });
      }
    }
  }, [daftarProductGeneric]);

  const onProjectNameBlur = () => {
    if (projectName.trim() === "") {

      setProjectName("");
      setshowError({ ...showError, project_name: true });
    }
  };

  const onTempoBayarBlur = () => {
    if (inputTOP === "") {
      setInputTOP(0);
      setshowError({ ...showError, term_of_payment: true });
    } else if (Number(inputTOP) === 0) {
      setshowError({ ...showError, term_of_payment: true });
    }
  };

  const onPelangganBlur = () => {
    if (formik.values.select_company === null) {
      setshowError({ ...showError, select_company: true });
    }

  };

  const handleClickOpen = () => {




    ReactGA.event({
      category: "Sales Order",
      action: "submit new Sales Order",
    });

    if (
      projectName === "" ||
      inputTOP === "" ||
      Number(inputTOP) === 0 ||
      formik.values.select_company === null ||
      (daftarProductGeneric.length === 0 && daftarProductTransport.length === 0) ||
      !isValidProductTransport({ transport: daftarProductTransport }) ||
      !isValidProductGeneric({ generic: daftarProductGeneric })
    ) {
      setshowError({
        project_name: projectName === "",
        term_of_payment: inputTOP === "" || Number(inputTOP) === 0,
        select_company: formik.values.select_company === null,
        listProduct: daftarProductGeneric.length === 0 && daftarProductTransport.length === 0,
        fieldProductTransport: !isValidProductTransport({ transport: daftarProductTransport }),
        fieldProductGeneric: !isValidProductGeneric({ generic: daftarProductGeneric }),
        all: true,
      });
    } else {
      if (params.id !== undefined) {
        setTextDialog({
          header: `Ubah order penjualan nomor ${anOrderPenjualan?.header?.number}?`,
          content:
            "Sebelum mengubah order penjualan, pastikan semua informasi telah terisi dengan benar.",
        });
      } else {
        setTextDialog({
          header: "Buat order penjualan baru?",
          content:
            "Sebelum membuat order penjualan, pastikan semua informasi telah terisi dengan benar.",
        });
      }
      setOpenDialog(true);
    }
  };

  const handleCloseAgree = () => {
    setOpenDialog(false);
    formik.submitForm();
  };

  const handleCloseCancel = () => {
    setOpenDialog(false);
  };

  const onChangeSelectCompany = (select_company) => {
    if (select_company.id === 0) {
      setOpenCustModal(true);
    } else {

      setSelectCompany(select_company);
      formik.setFieldValue("select_company", select_company);
    }
  };

  const onChangeInput = (event) => {

    setProjectName(event.target.value);

  };

  return (
    <DashboardLayout>
      <SimpleDashboardNavbar name="Buat Order Penjualan Baru" />
      <form onSubmit={formik.handleSubmit} onKeyDown={onKeyDown}>
        <Grid mt={5} container direction="column" justifyContent="space-between">
          <Grid
            item
            mt={4}
            py={2}
            mx={3}
            sx={{
              bgcolor: "rgba(255, 255, 255, 1)",
              borderRadius: "5px",
              boxShadow: "10px 15px 27px -28px #1A73E8",
            }}
          >
            <Grid container spacing={3} justifyContent="space-around" my={1}>
              <Grid item>
                <MDBox
                  sx={{
                    width: "100%",
                  }}
                  display="flex"
                >
                  <Autocomplete
                    disablePortal
                    id="select_company"
                    options={listPelangganSelect}
                    value={selectCompany}
                    onBlur={onPelangganBlur}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    onChange={(e, v) => onChangeSelectCompany(v)}
                    sx={{ width: 260 }}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        error={showError.select_company}
                        label="Pelanggan"
                      />
                    )}
                  />
                  <MDBox ml={3} my="auto">
                    <MDButton variant="outlined" onClick={() => setOpenCustModal(true)} color="info" size="regular">
                      <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                      &nbsp;Buat Pelanggan Baru
                    </MDButton>
                  </MDBox>
                </MDBox>
                <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                  {showError.select_company ? helperMsg.select_company : ""}{" "}
                </MDTypography>
                <CustomerModal
                  open={openCustModal}
                  onClose={(e) => setOpenCustModal(false)}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                />
              </Grid>
              <Grid item>
                <MDBox
                  sx={{
                    width: "100%",
                  }}
                >
                  <MDInput
                    id="projectname"
                    name="projectname"
                    label="Nama Project"
                    placeholder="Isi dengan nama project anda"
                    onChange={onChangeInput}
                    value={projectName}
                    onBlur={onProjectNameBlur}
                    sx={{ width: 500 }}
                    error={showError.project_name}
                    required
                  />
                  <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                    {showError.project_name ? helperMsg.project_name : ""}{" "}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
            <Grid container spacing={3} justifyContent="space-around" my={1}>
              <Grid item>
                <MDBox
                  sx={{
                    width: "100%",
                  }}
                >
                  <MDInput
                    id="term_of_payment"
                    name="term_of_payment"
                    label="Tempo Bayar dalam Hari"
                    onChange={(e) => setInputTOP(e.target.value)}
                    value={inputTOP}
                    type="number"
                    sx={{ width: 500 }}
                    error={showError.term_of_payment}
                    onBlur={onTempoBayarBlur}
                    required
                  />
                  <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                    {showError.term_of_payment ? helperMsg.term_of_payment : ""}{" "}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item>
                <MDBox
                  sx={{
                    width: "100%",
                  }}
                >
                  <DesktopDatePicker
                    label="Tanggal Transaksi"
                    inputFormat="dd/MM/yyyy"
                    onChange={(value) => formik.setFieldValue("issued_date", value, true)}
                    value={formik.values.issued_date}
                    renderInput={(params) => (
                      <TextField
                        required
                        error={showError.date}
                        id="issued_date"
                        name="issued_date"
                        sx={{ width: 500 }}
                        {...params}
                      />
                    )}
                  />
                  <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                    {showError.date ? helperMsg.date : ""}{" "}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
            <Grid container spacing={3} justifyContent="space-around" my={1}>
              <Grid item>
                <MDBox
                  sx={{
                    width: "100%",
                  }}
                >
                  <MDInput
                    id="keterangan"
                    name="keterangan"
                    multiline
                    onChange={(e) => setTextKeterangan(e.target.value)}
                    value={textKeterangan}
                    rows={5}
                    label="Keterangan"
                    placeholder="Isi dengan keterangan lain yang diperlukan"
                    sx={{ width: 500 }}
                  />
                </MDBox>
              </Grid>
              <Grid item>
                <MDBox
                  sx={{
                    width: "100%",
                  }}
                >
                  <MDInput
                    id="syaratKetentuan"
                    name="syaratKetentuan"
                    multiline
                    onChange={(e) => setTextSyaratKetentuan(e.target.value)}
                    value={textSyaratKetentuan}
                    rows={5}
                    label="Syarat dan Ketentuan"
                    placeholder="Isi dengan syarat dan ketentuan anda"
                    sx={{ width: 500 }}
                  />
                </MDBox>
              </Grid>
            </Grid>
            <Grid
              container
              py={2}
              justifyContent="space-around"
              direction="column"
              my={5}
              sx={{ borderTop: "2px dashed #e1e1e1" }}
            >
              <Grid item justifyContent="flex-start" alignItems="flex-start" px={4}>
                <MDBox>
                  <MDTypography variant="h6" fontWeight="medium">
                    {"Detail Order Penjualan (Optional)"}
                  </MDTypography>

                  <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                    {showError.listProduct ? helperMsg.listProduct : ""}{" "}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item mb={3} pb={3} sx={{ borderBottom: "2px dashed #e1e1e1" }}>
                <Grid item>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="space-between"
                    spacing={1}
                    px={4}
                    mt={3}
                  >
                    <MDBox display="flex" width="100%" justifyContent="space-between">
                      <MDBox
                        width="45%"
                        height="150px"
                        sx={{ bgcolor: "#F4F8FC", border: "2px solid #0D6ECD" }}
                        borderRadius="lg"
                        p={3}
                      >
                        <MDTypography fontWeight="bold" sx={{ fontSize: "14px", color: "#0D6ECD" }}>
                          Langkah 1
                        </MDTypography>
                        <MDTypography sx={{ fontSize: "12px" }}>
                          Untuk menambah detail, silahkan unduh template terlebih dahulu untuk
                          diisi.
                        </MDTypography>
                        <MDBox
                          display="flex"
                          mt={2}
                          justifyContent="flex-end"
                          alignItems="flex-end"
                        >
                          <a
                            download
                            href="https://invoice.dev.kentara.io/invoice/detail/template-invoice-detail.xlsx"
                          >
                            <MDButton variant="contained" color="info" mt={2} size="small">
                              <DownloadRoundedIcon />
                              &nbsp;Download Template
                            </MDButton>
                          </a>
                        </MDBox>
                      </MDBox>
                      <MDBox
                        width="45%"
                        height="150px"
                        sx={{ bgcolor: "#F4F8FC", border: "2px solid #0D6ECD" }}
                        borderRadius="lg"
                        p={3}
                      >
                        <MDTypography fontWeight="bold" sx={{ fontSize: "14px", color: "#0D6ECD" }}>
                          Langkah 2
                        </MDTypography>
                        <MDTypography sx={{ fontSize: "12px" }}>
                          Setelah template diisi, silahkan unggah berkas template tersebut.
                        </MDTypography>
                        <MDBox
                          display="flex"
                          mt={2}
                          justifyContent="flex-end"
                          alignItems="flex-end"
                        >
                          <MDButton
                            variant="contained"
                            color="info"
                            mt={2}
                            size="small"
                            component="label"
                          >
                            <MDBox sx={{ color: "#ffffff" }}>
                              <FileUploadRoundedIcon />
                              &nbsp;Upload Detail
                              <input
                                type="file"
                                id="fileProduct"
                                hidden
                                onChange={(e) => {
                                  setFileSelected(e.target.files[0]);
                                }}
                              />
                            </MDBox>
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <MDBox mt={2} mx={3}>
                  <LVProductTransportDataGrid
                    onProductUpdate={(prod) => {


                      setDaftarProductTransport(prod);
                    }}
                  />
                </MDBox>
                <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                  {showError.fieldProductTransport ? helperMsg.fieldProductTransport : ""}{" "}
                </MDTypography>
              </Grid>
              <Grid item>
                <MDBox mt={2} mx={3}>
                  <LVProductGenericDataGrid
                    onProductUpdate={(prod) => {


                      setDaftarProductGeneric(prod);
                    }}
                  />
                </MDBox>
                <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                  {showError.fieldProductGeneric ? helperMsg.fieldProductGeneric : ""}{" "}
                </MDTypography>
              </Grid>
            </Grid>
            <MDBox mx={4} mt={5} justifyContent="flex-end" display="flex" alignItems="flex-end">
              <MDBox
                sx={{ bgcolor: "#F4F8FC", border: "2px solid #0D6ECD" }}
                width="50%"
                borderRadius="lg"
              >
                <MDBox px={3} my={1}>
                  <MDBox
                    display="flex"
                    sx={{ borderBottom: "1px solid #0D6ECD" }}
                    justifyContent="space-between"
                  >
                    <MDBox>
                      <MDTypography
                        variant="overline"
                        fontWeight="regular"
                        sx={{ color: "#0D6ECD" }}
                      >
                        {/* Total Harga Tanpa PPN */}
                        Total Harga Tanpa Pajak
                      </MDTypography>
                    </MDBox>
                    <MDBox>
                      <MDTypography
                        variant="overline"
                        fontWeight="regular"
                        sx={{ color: "#0D6ECD" }}
                      >
                        {subTotal === null
                          ? 0
                          : Number(subTotal).toLocaleString("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          })}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox
                    display="flex"
                    sx={{ borderBottom: "1px solid #0D6ECD" }}
                    justifyContent="space-between"
                  >
                    <MDBox>
                      <MDTypography
                        variant="overline"
                        fontWeight="regular"
                        sx={{ color: "#0D6ECD" }}
                      >
                        PPN
                      </MDTypography>
                    </MDBox>

                    <MDBox>
                      <MDTypography
                        variant="overline"
                        fontWeight="regular"
                        sx={{ color: "#0D6ECD" }}
                      >
                        {pajakExclusive === null
                          ? 0
                          : Number(pajakExclusive + pajakInclusive).toLocaleString("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          })}
                      </MDTypography>
                    </MDBox>
                  </MDBox>

                  <MDBox
                    display="flex"
                    sx={{ borderBottom: "1px solid #0D6ECD" }}
                    justifyContent="space-between"
                  >
                    <MDBox>
                      <MDTypography
                        variant="overline"
                        fontWeight="regular"
                        sx={{ color: "#0D6ECD" }}
                      >
                        Total Harga Dengan PPN
                      </MDTypography>
                    </MDBox>

                    <MDBox>
                      <MDTypography
                        variant="overline"
                        fontWeight="regular"
                        sx={{ color: "#0D6ECD" }}
                      >
                        {pajakExclusive === null || pajakInclusive === null || subTotal === null
                          ? 0
                          : Number(subTotal + pajakExclusive + pajakInclusive).toLocaleString("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          })}
                      </MDTypography>
                    </MDBox>
                  </MDBox>

                  <MDBox
                    display="flex"
                    sx={{ borderBottom: "1px solid #0D6ECD" }}
                    justifyContent="space-between"
                  >
                    <MDBox>
                      <MDTypography
                        variant="overline"
                        fontWeight="regular"
                        sx={{ color: "#0D6ECD" }}
                      >
                        PPh
                      </MDTypography>
                    </MDBox>

                    <MDBox>
                      <MDTypography
                        variant="overline"
                        fontWeight="regular"
                        sx={{ color: "#0D6ECD" }}
                      >
                        {pajakPphExclusive === null
                          ? 0
                          : Number(-1 * pajakPphExclusive).toLocaleString("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          })}
                      </MDTypography>
                    </MDBox>
                  </MDBox>

                  <MDBox display="flex" justifyContent="space-between">
                    <MDBox>
                      <MDTypography
                        variant="overline"
                        fontWeight="bold"
                        sx={{ color: "#0D6ECD" }}
                      >
                        {/* Total Harga */}
                        Total Harga Setelah Pajak
                      </MDTypography>
                    </MDBox>
                    <MDBox>
                      <MDTypography
                        variant="overline"
                        fontWeight="bold"
                        sx={{ color: "#0D6ECD" }}
                      >
                        {total === null
                          ? 0
                          : Number(total - pajakPphExclusive).toLocaleString("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          })}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>

              </MDBox>
            </MDBox>



            <MDBox display="flex" justifyContent="end" mx={4} mt={3}>
              <MDButton
                variant="outlined"
                color="error"
                size="small"
                component={Link}
                to="/orderpenjualan"
                sx={{ mr: 2 }}
              >
                Batal
              </MDButton>
              <MDButton variant="contained" color="info" size="small" onClick={handleClickOpen}>
                Simpan
              </MDButton>
            </MDBox>
            <MDTypography color="error" py={1} px={1} sx={{ fontSize: 10 }}>
              {showError.all ? helperMsg.all : ""}{" "}
            </MDTypography>
            <MDTypography color="error" py={1} px={1} sx={{ fontSize: 10 }}>
              {showError.fail ? helperMsg.fail : ""}{" "}
            </MDTypography>
          </Grid>
        </Grid>
        <Dialog
          open={openDialog}
          onClose={handleCloseCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{textDialog.header}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {textDialog.content}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAgree} autoFocus>
              Lanjut
            </Button>
            <Button onClick={handleCloseCancel}>Batal</Button>
          </DialogActions>
        </Dialog>
      </form>
    </DashboardLayout>
  );
}

export default NewOrderPenjualan;
