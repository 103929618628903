import { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SimpleDashboardNavbar from "examples/Navbars/SimpleDashboardNavbar";
import LVKycDocument from "components/LVKycDocument";
import serverList from "apis/serverList";
import FintechModal from "modals/viewFintechModal";
import {
  fetchKYCDocs,
  adminFetchKYCDocs,
  fetchCustomerDetail,
  adminFetchCustomerDetail,
  approveKYCDocsAdmin,
  approveKYCPhoneAdmin,
  rejectKYCDocsAdmin,
  rejectKYCPhoneAdmin,
  fetchAssetMetadata
} from "../../actions/kycPeminjamAction";
import { emptyMessage } from "../../actions";
import {  transformKycDocsArray } from "../../common/Util";
import RejectKYCDocumentModal from "../../modals/rejectKYCDocumentModal";
import {
  Modal,
  Card,
  CircularProgress,
} from "@mui/material";
import { fintechGetTransporterKycFintechDocuments } from "actions/borrowerFintechAction";
import { fintechApproveFintechKycDoc } from "actions/borrowerFintechAction";
import { fintechRejectFintechKycDoc } from "actions/borrowerFintechAction";

function DetailKycPeminjam() {
  const user = JSON.parse(localStorage.getItem("user"));
  const company = user ? user.company : {};

  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [fintechModal, setFintechModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selected, setSelected] = useState({});

  const [reason, setReason] = useState("");

  const [downloadLoading, setDownloadLoading] = useState(false);

  const assetMetadata = useSelector((state) => state.kycAssetMetadata, shallowEqual);
  const assetMetadataLoading = useSelector((state) => state.kycAssetMetadataLoading, shallowEqual);
  const [previewModal, setPreviewModal] = useState(false);
  const [previewPath, setPreviewPath] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [type, setType] = useState("baseDocument");

  const [kycDocs, setKycDocs] = useState([]);

  const listKYCDocsArray = useSelector((state) => state.listKYCDocsArray, shallowEqual);
  const transporterFintechKycDocument = useSelector((state) => state.transporterFintechKycDocument.message,
    shallowEqual);

  const customerDetail = useSelector((state) => state.kycCompanyDetail, shallowEqual);

  const message = useSelector((state) => state.message, shallowEqual);

  const dispatch = useDispatch();
  const params = useParams();

  const id = decodeURIComponent(params.id);
  const status = decodeURIComponent(params.status);
  const batchDownloadLink = `${serverList.company}/api/v1/fintech/company/kyc/document/batch?authorization=${user?.access_token}&company_id=${id}`;

  const openDocumentModal = (name, path) => {
    setPreviewTitle(name);
    setPreviewPath(path);
    setPreviewModal(true);
  }

  const closeDocumentModal = () => {
    setPreviewPath("");
    setPreviewModal(false);
  }

  const getAssetMetadata = (assetID) => {
    dispatch(
      fetchAssetMetadata(assetID)
    );
  }

  useEffect(() => {

  }, [assetMetadata]);

  useEffect(() => {
    if (!transporterFintechKycDocument) { return; }
    setKycDocs(transporterFintechKycDocument);
  }, [transporterFintechKycDocument])

  useEffect(() => {
    if (!listKYCDocsArray) { return; }
    setKycDocs(listKYCDocsArray);
  }, [listKYCDocsArray])

  useEffect(() => {
    const id = decodeURIComponent(params.id);
    if (id !== null) {
      if (company?.business_type === "fintech") {
        dispatch(fetchKYCDocs(id));
        dispatch(fetchCustomerDetail(id));
      } else {
        dispatch(adminFetchKYCDocs(id));
        dispatch(adminFetchCustomerDetail(id));
      }
    }
  }, []);

  useEffect(() => {
    const id = decodeURIComponent(params.id);
    if (id !== null) {
      fintechFetchKycDocument();
    }
  }, [type]);

  const fintechFetchKycDocument = () => {
    if (company?.business_type === "fintech") {
      if (type == "baseDocument") {
        dispatch(fetchKYCDocs(id));
      } else if (type == "additionalDocument") {
        dispatch(fintechGetTransporterKycFintechDocuments(id));
      }
    }
  }

  const adminFetchKycDocument = () => {
    if (user?.role.includes("admin")) {
      dispatch(adminFetchKYCDocs(id));
    }
  }

  useEffect(() => { }, [customerDetail]);

  useEffect(() => {
    dispatch(emptyMessage());
    switch (message.status) {
      case "APPROVE_KYC_DOCUMENT":
        if (id !== null) {
          fintechFetchKycDocument();
          adminFetchKycDocument();
        }
        return;
      case "REJECT_KYC_DOCUMENT":
        if (id !== null) {
          fintechFetchKycDocument();
          adminFetchKycDocument();
        }
        return;
      case "SUCCESS_CREATE_CUSTOMER_RELATION":
        setFintechModal(false);
        setOpenDialog(true);
        adminFetchKycDocument();
      case "ERROR_CREATE_CUSTOMER_RELATION":
        return;
      default:
    }
  }, [message]);

  const onApprove = ({ id, name }) => {
    setSelected({ id, name });
    setOpenApproveDialog(true);
  };

  const onReject = ({ id, name }) => {
    setSelected({ id, name });
    setOpenRejectDialog(true);
  };

  const onDialogApproveAgree = () => {
    if (company?.business_type == "fintech") {
      dispatch(fintechApproveFintechKycDoc(Number(selected.id)));
    } else {
      if (selected.name === "Phone") {
        dispatch(approveKYCPhoneAdmin(Number(selected.id)));
      } else {
        dispatch(approveKYCDocsAdmin(Number(selected.id)));
      }
    }
    setOpenApproveDialog(false);
  };

  const onDialogRejectAgree = (reason) => {
    if (company?.business_type == "fintech") {
      dispatch(fintechRejectFintechKycDoc({ id: Number(selected.id), reason: reason }));
    } else {
      if (selected.name === "Phone") {
        dispatch(rejectKYCPhoneAdmin({ id: Number(selected.id), reason: reason }));
      } else {
        dispatch(rejectKYCDocsAdmin({ id: Number(selected.id), reason: reason }));
      }
    }
    setOpenRejectDialog(false);
  };

  const onClickHandler = (e) => {
    const url = downloadLoading ? "#" : batchDownloadLink;
    window.open(url, "_self");
    setDownloadLoading(true);
    setTimeout(() => {
      setDownloadLoading(false);
    }, 30000);
  };

  return (
    <DashboardLayout>
      <Modal
        open={previewModal}
        onClose={closeDocumentModal}
      >
        <Card sx={{
          px: 3,
          paddingTop: 3,
          margin: "auto",
          marginTop: 5,
          marginBottom: 3, width: '100%', maxWidth: "1200px", display: "flex",
        }}>
          <MDBox pb={4} textAlign="left" lineHeight={1.25} justifyContent="center" >
            <MDTypography mb={2} variant="h4" fontWeight="medium" textTransform="capitalize">
              {previewTitle}
            </MDTypography>
            <embed src={previewPath} width="100%" height="600px" />
          </MDBox>
        </Card>
      </Modal>
      <SimpleDashboardNavbar name="Detail Peminjam" />
      <Grid container direction="column" justifyContent="space-between" spacing={1} mt={5}>
        <Grid item ml={1} mr={3} mb={0}>
          <MDTypography variant="h4">
            {customerDetail.type} {customerDetail.name}
          </MDTypography>
        </Grid>
        <Grid item xs="12" mt={2}>
          <Grid container>
            <Grid item xs="auto">
              <MDBox onClick={() => setType("baseDocument")} sx={{ cursor: "pointer" }}>
                <MDTypography
                  variant="body3"
                  px={4}
                  py={2}
                  sx={{
                    mb: 2,
                    bgcolor: type === "baseDocument" ? "rgba(255, 255, 255, 1)" : "rgb(238, 238, 238)",
                    borderRadius: "16px",
                    border: "1px solid #e1e1e1",
                    fontWeight: "bold",
                  }}
                >
                  Dokumen Utama
                </MDTypography>
              </MDBox>
            </Grid>
            {(company?.business_type === "fintech") ? (
              <Grid item xs="auto" ml={1}>
                <MDBox onClick={() => setType("additionalDocument")} sx={{ cursor: "pointer" }}>
                  <MDTypography
                    variant="body3"
                    px={4}
                    py={2}
                    sx={{
                      mb: 2,
                      bgcolor: type === "additionalDocument" ? "rgba(255, 255, 255, 1)" : "rgb(238, 238, 238)",
                      borderRadius: "16px",
                      border: "1px solid #e1e1e1",
                      fontWeight: "bold",
                    }}
                  >
                    Dokumen Tambahan
                  </MDTypography>
                </MDBox>
              </Grid>) : <></>
            }
            <Grid item xs="auto" ml={4}
              justifyContent="flex-end">
              {company?.business_type === "fintech" ? (

                <MDBox pb={1}>
                  <MDButton
                    variant="contained"
                    color={downloadLoading ? "white" : "info"}
                    href={downloadLoading ? "#" : batchDownloadLink}
                    onClick={(e) => {
                      onClickHandler(e);
                    }}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>
                      {downloadLoading ? "downloading" : "download"}
                    </Icon>
                    {downloadLoading ? (
                      <>
                        <MDTypography ml={3} variant="body3" color="dark" fontWeight="bold">
                          SEDANG MENGUNDUH
                        </MDTypography>
                        <CircularProgress size={20} sx={{ ml: 2 }} />
                      </>
                    ) : (
                      <MDTypography ml={3} variant="body3" color="white" fontWeight="bold">
                        UNDUH SEMUA
                      </MDTypography>
                    )}
                  </MDButton>
                </MDBox>
              ) : (<></>
              )}

            </Grid>


          </Grid>
        </Grid>



        <Grid item sx={{ mt: 0 }}>
          <LVKycDocument
            mt={0}
            rows={transformKycDocsArray(kycDocs)}
            onApprove={onApprove}
            onReject={onReject}
            businessType={company?.business_type}
            onPreview={(name, path) => openDocumentModal(name, path)}
            onAssetInfo={(assetID) => getAssetMetadata(assetID)}
            documentType={type}
          />
        </Grid>
        <FintechModal
          open={fintechModal === true}
          kyc
          onClose={() => setFintechModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          id={id}
          transporterName={customerDetail.name}
        />
        {user?.role.includes("admin") && status === "Sudah Disetujui" ? (
          <Grid item>
            <MDBox
              mt={2}
              position="sticky"
              bottom={0}
              width="100%"
              height="65px"
              px={3}
              display="flex"
              justifyContent="end"
              sx={{
                bgcolor: "#ffffff",
                boxShadow: "10px 15px 27px -28px #1A73E8",
                borderRadius: "16px",
                border: "1px solid #e1e1e1",
              }}
            >
              <MDBox display="flex" my="auto" sx={{ justifyContent: "end" }}>
                <MDButton onClick={() => setFintechModal(true)} size="large" color="info">
                  Hubungkan
                </MDButton>
              </MDBox>
            </MDBox>
          </Grid>
        ) : null}
        <Dialog
          open={openApproveDialog}
          onClose={() => setOpenApproveDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Setujui Dokumen KYC</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Apakah anda yakin untuk menyetujui {selected.name}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton
              onClick={onDialogApproveAgree}
              variant="contained"
              color="info"
              size="small"
              autoFocus
            >
              Setujui
            </MDButton>
            <MDButton
              onClick={() => setOpenApproveDialog(false)}
              variant="contained"
              color="error"
              size="small"
            >
              Batal
            </MDButton>
          </DialogActions>
        </Dialog>
        <RejectKYCDocumentModal
          open={openRejectDialog}
          onClose={() => setOpenRejectDialog(false)}
          selected={selected}
          onReject={(reason) => onDialogRejectAgree(reason)}
        />
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Berhasil</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Kerjasama telah berhasil dihubungkan
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton
              onClick={() => setOpenDialog(false)}
              variant="contained"
              color="info"
              size="small"
            >
              OK
            </MDButton>
          </DialogActions>
        </Dialog>
      </Grid>
    </DashboardLayout >
  );
}

export default DetailKycPeminjam;
