/* eslint-disable import/prefer-default-export */
import axios from "axios";
import serverList from "../apis/serverList";
import authHeader from "../apis/authHeader";

export const getSalesStat = () => async (dispatch) => {
  const salesAPI = axios.create({
    baseURL: serverList.sales,
    headers: authHeader(),
  });
  const response = await salesAPI.get("/api/v1/sale/statistic?interval=weekly&period=6", {});
  dispatch({ type: "GET_SALES_STAT", payload: response.data.data });
  if (response !== undefined) {
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", response: response.data.message },
      });
    }
  }
};

export const getInvoiceStat = () => async (dispatch) => {
  const invoiceAPI = axios.create({
    baseURL: serverList.invoice,
    headers: authHeader(),
  });
  const response = await invoiceAPI.get("/api/v1/invoice/statistic?interval=weekly&period=6", {});
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  dispatch({ type: "GET_INVOICE_STAT", payload: response.data.data });
};

export const getFinancingStat = () => async (dispatch) => {
  const financingAPI = axios.create({
    baseURL: serverList.financing,
    headers: authHeader(),
  });
  const response = await financingAPI.get(
    "/api/v1/financing/statistic?interval=weekly&period=6",
    {}
  );
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  dispatch({ type: "GET_FINANCING_STAT", payload: response.data.data });
};

export const getInvoiceValue = () => async (dispatch) => {
  const invoiceAPI = axios.create({
    baseURL: serverList.invoice,
    headers: authHeader(),
  });
  const response = await invoiceAPI.get("/api/v1/invoice/value/statistic?interval=weekly&period=8", {});
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  dispatch({ type: "GET_INVOICE_VALUE", payload: response.data.data });
};


export const getSaleInvoiceDueSummary = () => async (dispatch) => {
  const invoiceAPI = axios.create({
    baseURL: serverList.invoice,
    headers: authHeader(),
  });
  const response = await invoiceAPI.get("/api/v1/invoice/sale/statistic/due/summary", {});
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  dispatch({ type: "GET_INVOICE_SALE_DUE_SUMMARY", payload: response.data.data });
};


export const getPurchaseInvoiceDueSummary = () => async (dispatch) => {
  const invoiceAPI = axios.create({
    baseURL: serverList.invoice,
    headers: authHeader(),
  });
  const response = await invoiceAPI.get("/api/v1/invoice/purchase/statistic/due/summary", {});
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  dispatch({ type: "GET_INVOICE_PURCHASE_DUE_SUMMARY", payload: response.data.data });
};


export const getSaleInvoiceDuePerCustomerSummary = () => async (dispatch) => {
  const invoiceAPI = axios.create({
    baseURL: serverList.invoice,
    headers: authHeader(),
  });
  const response = await invoiceAPI.get("/api/v1/invoice/sale/statistic/due/customer/summary", {});
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  dispatch({ type: "GET_INVOICE_SALE_DUE_PER_CUSTOMER_SUMMARY", payload: response.data.data });
};


export const getPurchaseInvoiceDuePerVendorSummary = () => async (dispatch) => {
  const invoiceAPI = axios.create({
    baseURL: serverList.invoice,
    headers: authHeader(),
  });
  const response = await invoiceAPI.get("/api/v1/invoice/purchase/statistic/due/vendor/summary", {});
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  dispatch({ type: "GET_INVOICE_PURCHASE_DUE_PER_VENDOR_SUMMARY", payload: response.data.data });
};
