/* eslint-disable no-plusplus */
const { Icon } = require("@mui/material");
const { default: MDBox } = require("components/MDBox");
const { default: MDTypography } = require("components/MDTypography");

function CustomFooter(props) {
  const data = [];

  let totalPageShow = 10;

  const totalPage = props.page;
  const currentPage = props.currentPage;


  if (totalPage < totalPageShow) {
    for (let i = 1; i <= totalPage; i++) {
      data.push(i);
    }
  }
  else if (totalPage > totalPageShow) {
    if (currentPage <= 1 + (totalPageShow / 2)) {
      for (let i = 1; i < totalPage && i < (totalPageShow - 1); i++) {
        data.push(i);
      }
      data.push(".");
      data.push(totalPage);
    }
    else if (currentPage >= (totalPageShow / 2) && currentPage <= totalPage - (totalPageShow / 2)) {
      data.push(1);
      data.push(".");

      for (let i = currentPage - (totalPageShow / 2) + 1; i < currentPage + (totalPageShow / 2); i++) {
        data.push(i);
      }
      data.push(".");
      data.push(totalPage);

    }
    else if (currentPage > totalPage - (totalPageShow / 2)) {
      data.push(1);
      data.push(".");
      for (let i = totalPage - (totalPageShow - 1); i <= totalPage; i++) {
        data.push(i);
      }
    }
  }

  return (
    <MDBox display="flex" mt={2} mb={3} justifyContent="center">
      <MDBox
        onClick={props.firstPage}
        justifyContent="center"
        mr={1}
        display="flex"
        sx={{ width: "35px", height: "30px", cursor: "pointer" }}
      >
        <Icon
          fontSize="small"
          sx={{ my: "auto" }}
          color={props.currentPage === 1 ? "dark" : "info"}
        >
          keyboard_double_arrow_left
        </Icon>
      </MDBox>
      <MDBox
        onClick={props.prevPage}
        justifyContent="center"
        mr={1}
        display="flex"
        sx={{ width: "35px", height: "30px", cursor: "pointer" }}
      >
        <Icon sx={{ my: "auto" }} color={props.currentPage === 1 ? "dark" : "info"}>
          arrow_back_ios
        </Icon>
      </MDBox>

      {

        data.map((item, i) =>
        (
          item !== "." ? (
            <MDBox
              onClick={props.selectPage}
              key={i}
              justifyContent="center"
              mr={1}
              display="flex"
              alignItems="center"
              sx={{
                width: "35px",
                height: "30px",
                cursor: "pointer",
                borderBottom: props.currentPage === item ? "2px solid #1A73E8" : "",
              }}
            >
              <MDTypography align="center" color="dark" sx={{ fontSize: "14px" }}>
                {item}
              </MDTypography>
            </MDBox>
          ) : (
            <MDBox
              justifyContent="center"
              mr={1}
              display="flex"
              alignItems="center"
              sx={{ width: "35px", height: "30px", cursor: "pointer" }}
            >
              <MDTypography align="center" color="dark" sx={{ fontSize: "14px" }}>
                ...
              </MDTypography>
            </MDBox>
          )
        )
        )
      }
      <MDBox
        onClick={props.nextPage}
        justifyContent="center"
        mr={1}
        display="flex"
        sx={{ width: "35px", height: "30px", cursor: "pointer" }}
      >
        <Icon sx={{ my: "auto" }} color={props.currentPage === props.page ? "dark" : "info"}>
          arrow_forward_ios
        </Icon>
      </MDBox>
      <MDBox
        onClick={props.lastPage}
        justifyContent="center"
        display="flex"
        sx={{ width: "35px", height: "30px", cursor: "pointer" }}
      >
        <Icon fontSize="small" sx={{ my: "auto" }} color={props.currentPage === props.page ? "dark" : "info"}>
          keyboard_double_arrow_right
        </Icon>
      </MDBox>
    </MDBox >
  );
}
export default CustomFooter;
