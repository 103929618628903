
import Modal from '@mui/material/Modal';
import QuickPay from 'layouts/quickpay';

const PilihPendanaModal = (props) => {

    return(
        <Modal
            {...props}
        >
            <div>
                <QuickPay onClose={props.onClose} datapengajuan={props.datapengajuan}/>
            </div>
        </Modal>
    );
}

export default PilihPendanaModal;