import { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useLocation, useParams, useNavigate, Link, matchPath } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SimpleDashboardNavbar from "examples/Navbars/SimpleDashboardNavbar";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import ListInfoCard from "examples/Cards/InfoCards/ListInfoCard";

import LVInvoiceDataGrid from "components/LVInvoiceDataGrid";
import LVPenjualanDataGrid from "components/LVPenjualanDataGrid";
import {
  getOrderPenjualan,
  fetchOrderPenjualan,
  initOrderPenjualan,
  searchOrderPenjualan,
} from "actions/orderPenjualanAction";
import LVKycDocument from "components/LVKycDocument";
import LVFileInput from "components/LVFileInput";
import { customerBalance } from "actions/paymentAction";
import PelunasanPengajuanModal from "modals/pelunasanPengajuanModal";
import MDProgress from "components/MDProgress";
import { fetchKYCDocs } from "../../actions/kycPeminjamAction";
import {
  getPengajuan,
  initAnPengajuan,
  getPengajuanPurchase,
  getPengajuanFintech,
  getPengajuanPurchaseFintech,
  getPengajuanQP,
} from "../../actions/detailPengajuanAction";
import { searchInvoices, initInvoices, fetchFintechInvoiceList, emptyMessage } from "../../actions";
import { rejectFinancing, approveFinancingQP } from "../../actions/fintechAction";
import {
  transformInvoices,
  transformOrderPenjualan,
  transformStatusPengajuan,
  transformKycDocsArray,
} from "../../common/Util";

import serverList from "../../apis/serverList";
import { invoiceHistory } from "actions/userInvoice";

function DetailPengajuan() {
  const user = JSON.parse(localStorage.getItem("user"));

  const [status, setStatus] = useState("");
  const [isPurchase, setIsPurchase] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogQP, setOpenDialogQP] = useState(false);
  const [openPelunasanPengajuan, setOpenPelunasanPengajuan] = useState(false);
  const [textKeterangan, setTextKeterangan] = useState("");
  const [proofOfTransfer, setProofOfTransfer] = useState(null);
  const [interestDuration, setInterestDuration] = useState(null);
  const [interestYearly, setInterestYearly] = useState(null);
  const [approvedTop, setApprovedTop] = useState(null);
  const [customerCompany, setCustomerCompany] = useState(null);
  const [customerCompanyId, setCustomerCompanyId] = useState(null);
  const [customerAvailableBalance, setCustomerAvailableBalance] = useState(null);

  const anPengajuan = useSelector((state) => state.anPengajuan, shallowEqual);

  const anOrderPenjualan = useSelector((state) => state.anOrderPenjualan, shallowEqual);

  const listOrderPenjualan = useSelector((state) => state.listOrderPenjualan, shallowEqual);

  const listKYCDocsArray = useSelector((state) => state.listKYCDocsArray, shallowEqual);

  const invoices = useSelector((state) => state.invoices, shallowEqual);

  const anCustomerBalance = useSelector((state) => state.anCustomerBalance, shallowEqual);

  const anUserInvoiceHistory = useSelector((state) => state.anUserInvoiceHistory, shallowEqual);

  const message = useSelector((state) => state.message, shallowEqual);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const params = useParams();

  const match = matchPath(
    {
      path: "/pengajuan/invoice/:request_id",
      exact: true,
      strict: true,
    },
    location.pathname
  );
  const matchFintech = matchPath(
    {
      path: "/pengajuan/invoice/fintech/:request_id",
      exact: true,
      strict: true,
    },
    location.pathname
  );
  const matchPurchase = matchPath(
    {
      path: "/pengajuan/purchase/:request_id",
      exact: true,
      strict: true,
    },
    location.pathname
  );
  const matchPurchaseFintech = matchPath(
    {
      path: "/pengajuan/purchase/fintech/:request_id",
      exact: true,
      strict: true,
    },
    location.pathname
  );
  const matchQP = matchPath(
    {
      path: "/pengajuan/quickpay/:request_id",
      exact: true,
      strict: true,
    },
    location.pathname
  );
  const matchQPFintech = matchPath(
    {
      path: "/pengajuan/quickpay/fintech/:request_id",
      exact: true,
      strict: true,
    },
    location.pathname
  );

  const rowsObjectPengajuanOrder = {
    "No. Pengajuan": anPengajuan?.number,
    Pelanggan: anPengajuan?.invoicing_company,
    "Tanggal Pengajuan": anPengajuan?.created_date,
    "Durasi Pinjaman": `${approvedTop} Hari`,
    "Bunga Durasi": `${interestDuration}%`,
    "Bunga Tahunan": `${interestYearly}%`,
    "Total Disetujui": Number(
      anPengajuan.approved_amount ? anPengajuan.approved_amount : 0
    ).toLocaleString("id-ID", { style: "currency", currency: "IDR" }),
    "Tanggal Jatuh Tempo": anPengajuan?.due_date,
    "Pengembalian Jatuh Tempo": Number(
      anPengajuan.approved_amount ? anPengajuan.approved_amount + anPengajuan.fee_on_top : 0
    ).toLocaleString("id-ID", { style: "currency", currency: "IDR" }),
    "Jumlah Bunga Per Hari Ini": Number(anPengajuan.fee ? anPengajuan.fee : 0).toLocaleString(
      "id-ID",
      { style: "currency", currency: "IDR" }
    ),
    "Pengembalian Per Hari Ini": Number(
      anPengajuan.approved_amount ? anPengajuan.approved_amount + anPengajuan.fee : 0
    ).toLocaleString("id-ID", { style: "currency", currency: "IDR" }),
    "Biaya Platform": Number(
      anPengajuan.platform_fee ? anPengajuan.platform_fee : 0
    ).toLocaleString("id-ID", { style: "currency", currency: "IDR" }),
  };

  const rowsObjectPengajuanOrderQP = {
    "No. Pengajuan": anPengajuan?.number,
    Pelanggan: anPengajuan?.invoicing_company,
    "Tanggal Pengajuan": anPengajuan?.created_date,
    "Durasi Pinjaman": `${approvedTop} Hari`,
    "Total Disetujui": Number(
      anPengajuan.approved_amount ? anPengajuan.approved_amount : 0
    ).toLocaleString("id-ID", { style: "currency", currency: "IDR" }),
  };

  const rowsObjectPengajuanOrderPurchase = {
    "No. Pengajuan": anPengajuan?.number,
    Pelanggan: anPengajuan?.invoicing_company,
    "Tanggal Pengajuan": anPengajuan?.created_date,
    "Durasi Pinjaman": `${approvedTop} Hari`,
    "Bunga Durasi": `${interestDuration}%`,
    "Bunga Tahunan": `${interestYearly}%`,
    "Total Disetujui": Number(
      anPengajuan.approved_amount ? anPengajuan.approved_amount : 0
    ).toLocaleString("id-ID", { style: "currency", currency: "IDR" }),
    'Tanggal Jatuh Tempo': anPengajuan?.due_date,
    'Pengembalian Jatuh Tempo': Number(anPengajuan.approved_amount ? anPengajuan.approved_amount + anPengajuan.fee_on_top : 0).toLocaleString("id-ID", { style: "currency", currency: "IDR" }),
    'Jumlah Bunga Per Hari Ini': Number(anPengajuan.fee ? anPengajuan.fee : 0).toLocaleString("id-ID", { style: "currency", currency: "IDR" }),
    'Pengembalian Per Hari Ini': Number(anPengajuan.approved_amount ? anPengajuan.approved_amount + anPengajuan.fee : 0).toLocaleString("id-ID", { style: "currency", currency: "IDR" }),
    "Biaya Platform": Number(
      anPengajuan.platform_fee ? anPengajuan.platform_fee : 0
    ).toLocaleString("id-ID", { style: "currency", currency: "IDR" }),

  };

  const rowsObjectPengajuanOrderFiltered = (() => {
    if (user?.company?.business_type !== "fintech") {
      return rowsObjectPengajuanOrder;
    } else {
      let tempObject = rowsObjectPengajuanOrder;
      delete tempObject["Biaya Platform"];
      return tempObject;
    }
  })();

  const rowsObjectPengajuanOrderPurchaseFiltered = (() => {
    if (user?.company?.business_type !== "fintech") {
      return rowsObjectPengajuanOrderPurchase;
    } else {
      let tempObject = rowsObjectPengajuanOrderPurchase;
      delete tempObject["Biaya Platform"];
      return tempObject;
    }
  })();

  useEffect(() => {
    const res = decodeURIComponent(params.request_id);

    setIsPurchase(matchPurchase || matchPurchaseFintech);
    if (res !== null) {
      if (matchPurchase) {
        dispatch(getPengajuanPurchase({ request_id: res }));
      } else if (match) {
        dispatch(getPengajuan({ request_id: res }));
      } else if (matchFintech) {
        dispatch(getPengajuanFintech({ request_id: res }));
      } else if (matchPurchaseFintech) {
        dispatch(getPengajuanPurchaseFintech({ request_id: res }));
      } else if (matchQP) {
        dispatch(getPengajuanQP({ request_id: res }));
      } else if (matchQPFintech) {
        dispatch(getPengajuanQP({ request_id: res }));
      }
    } else {
      navigate("/pengajuan");
    }

    return () => {
      dispatch(initAnPengajuan());
      dispatch(initInvoices());
      dispatch(initOrderPenjualan());
    };
  }, []);

  useEffect(() => {
    if (message.status !== "") {
      dispatch(emptyMessage());
      switch (message.status) {
        case "SUCCESS_PAY_INVOICE_FINANCING":
          setOpenPelunasanPengajuan(false);
          const res = decodeURIComponent(params.request_id);
          if (res !== null) {
            if (matchPurchase) {
              dispatch(getPengajuanPurchase({ request_id: res }));
            } else if (match) {
              dispatch(getPengajuan({ request_id: res }));
            } else if (matchFintech) {
              dispatch(getPengajuanFintech({ request_id: res }));
            } else if (matchPurchaseFintech) {
              dispatch(getPengajuanPurchaseFintech({ request_id: res }));
            } else if (matchQP) {
              dispatch(getPengajuanQP({ request_id: res }));
            } else if (matchQPFintech) {
              dispatch(getPengajuanQP({ request_id: res }));
            }
          } else {
            navigate("/pengajuan");
          }

        default:
      }
    }
  }, [message]);

  useEffect(() => {
    let typeDetail = "";
    if (match) {
      if (anPengajuan?.invoice_id?.length !== 0) {
        dispatch(
          searchInvoices({
            id: anPengajuan.invoice_id,
          })
        );
        typeDetail = "invoice";
      } else {
        dispatch(initInvoices());
      }
    } else if (matchFintech) {
      const res2 = decodeURIComponent(params.request_id);
      dispatch(fetchFintechInvoiceList({ financing_id: res2 }));
      typeDetail = "invoice";
    } else if (matchPurchase) {
      dispatch(fetchOrderPenjualan());
      typeDetail = "purchase";
    } else if (matchPurchaseFintech) {
      dispatch(
        searchOrderPenjualan({
          id: anPengajuan.sale_order_id,
          limit: 15,
          page: 1,
          order_by: "id desc",
        })
      );
      typeDetail = "purchase";
    } else if (matchQP || matchQPFintech) {
      if (anPengajuan?.invoice_id?.length !== 0) {
        dispatch(
          invoiceHistory({
            id: anPengajuan.invoice_id[0],
          })
        );
        typeDetail = "quickpay";
      } else {
        dispatch(initInvoices());
      }
    }
    if (Array.isArray(anPengajuan.document)) {
      anPengajuan.document.map((item) => {
        if (item.type.toLowerCase() === "proof_of_transfer") {
          setProofOfTransfer(serverList.financing + item.url);
        }
      });
    }
    if (anPengajuan.term) {
      setInterestDuration(anPengajuan.term.interest_base);
      setInterestYearly(anPengajuan.term.interest_annum);
      setApprovedTop(anPengajuan.term.term_of_payment);
    }
    setStatus(transformStatusPengajuan({ status: anPengajuan.status, type: typeDetail }));
    dispatch(fetchKYCDocs(anPengajuan.invoicing_company_id));

    if (anPengajuan?.sale_order_id) {
      dispatch(getOrderPenjualan({ id: anPengajuan?.sale_order_id[0] }));
    }

    return () => {
      if (matchPurchase || matchPurchaseFintech) {
        dispatch(initOrderPenjualan());
      }
    };
  }, [anPengajuan]);

  useEffect(() => {
    if (invoices.length !== 0) {
      setCustomerCompany(invoices[0].invoiced_company);
      setCustomerCompanyId(invoices[0].invoiced_company_id);
      dispatch(customerBalance(invoices[0].invoiced_company_id));
    }
  }, [invoices]);

  useEffect(() => {
    setCustomerCompany(anOrderPenjualan?.header?.customer_company);
    setCustomerCompanyId(anOrderPenjualan?.header?.customer_company_id);
    dispatch(customerBalance(anOrderPenjualan?.header?.customer_company_id));
  }, [anOrderPenjualan]);

  useEffect(() => {
    setCustomerAvailableBalance(anCustomerBalance?.balance);
  }, [anCustomerBalance]);

  const renderDataGrid = () => {
    if (isPurchase) {
      return (
        <LVPenjualanDataGrid
          height={400}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection={false}
          viewOnly
          flat
          rows={transformOrderPenjualan({
            orderPenjualan: listOrderPenjualan,
            businessType: user?.company?.business_type,
            arrId: anPengajuan?.sale_order_id,
          })}
          columnType={user?.company?.business_type}
        />
      );
    }
    return (
      <LVInvoiceDataGrid
        rows={transformInvoices(matchQPFintech ? anUserInvoiceHistory : invoices)}
        height={400}
        pageSize={5}
        rowsPerPageOptions={[5]}
        flat
        viewOnly
      />
    );
  };

  const renderQPApprove = () => {
    if (user?.access_list?.includes("approve_reject_qp") && matchQP) {
      if (anPengajuan?.status === "created") {
        return (
          <Grid item>
            <MDButton variant="outlined" color="info" size="small" onClick={onApprove}>
              Setujui
            </MDButton>
          </Grid>
        );
      }
    }
  };

  const renderQPReject = () => {
    if (user?.access_list?.includes("approve_reject_qp") && matchQP) {
      if (anPengajuan?.status === "created") {
        return (
          <Grid item>
            <MDButton variant="outlined" color="error" size="small" onClick={onReject}>
              Tolak
            </MDButton>
          </Grid>
        );
      }
    }
  };

  const onApprove = () => {
    setOpenDialogQP(true);
  };

  const onReject = () => {
    setOpenDialog(true);
  };

  const handleCloseAgree = () => {
    dispatch(rejectFinancing({ financing_id: anPengajuan?.id, reason: textKeterangan }));
    setOpenDialog(false);
  };

  const handleCloseAgreeQP = () => {
    dispatch(approveFinancingQP({ financing_id: anPengajuan?.id }));
    setOpenDialogQP(false);
  };

  const handleCloseCancel = () => {
    setOpenDialog(false);
  };

  const handleClickLunas = () => {
    setOpenPelunasanPengajuan(true);
  };

  const render_legality = () => {
    if (matchPurchaseFintech || matchQPFintech || matchFintech) {
      return (
        <Accordion sx={{ boxShadow: "10px 15px 27px -28px #1A73E8" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <MDTypography color="text" px={1} sx={{ fontSize: 14 }}>
              Data Legalitas Peminjam
            </MDTypography>
          </AccordionSummary>
          <AccordionDetails mt={1}>
            <MDBox sx={{ mt: -4 }}>
              <LVKycDocument flat rows={transformKycDocsArray(listKYCDocsArray)} />
            </MDBox>
          </AccordionDetails>
        </Accordion>
      );
    }
  };

  const render_lampiran = () => {
    if (proofOfTransfer !== null) {
      return (
        <Card sx={{ marginBottom: 2 }}>
          <MDBox p={2} mx={3} display="flex" justifyContent="center" />
          <MDBox pb={4} px={2} alignItems="center" textAlign="center" lineHeight={1.25}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              Lampiran
            </MDTypography>
            <Grid container spacing={2} justifyContent="space-around">
              <Grid item>
                <LVFileInput
                  srcImage={proofOfTransfer}
                  srcFile={proofOfTransfer}
                  height={200}
                  width={240}
                  fontSize={10}
                  id="bukti_transfer"
                  text1=""
                  text2=""
                  text3=""
                  title="Bukti Transfer"
                  titleFontSize={12}
                  accept=".pdf"
                  viewOnly
                />
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      );
    }
  };

  const render_lunas = () => {
    if (user?.access_list?.includes("close_invoice_financing")) {
      if (match) {
        if (anPengajuan?.status === "fund_transferred") {

        }
      } else if (matchPurchase) {

      }
    }

  };

  return (
    <>
      <DashboardLayout>
        <SimpleDashboardNavbar name="Detail Pengajuan" />
        <Grid container direction="column" spacing={3} mt={3}>
          <Grid item ml={1} mr={3}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Grid container spacing={1} justifyContent="space-around">
                  <Grid item>
                    <MDTypography variant="h4">
                      {anPengajuan.number ? anPengajuan.number : ""}
                    </MDTypography>
                  </Grid>
                  <Grid item textAlign="center" sx={{ margin: "auto" }}>
                    <MDBox
                      alignItems="center"
                      px={2}
                      py={1}
                      sx={{ bgcolor: "rgba(235, 235, 235, 1)", borderRadius: 2, height: "100%" }}
                    >
                      <MDTypography
                        color="text"
                        sx={{ fontSize: 12, margin: "auto" }}
                        fontWeight="medium"
                      >
                        {status}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={1} justifyContent="space-around">
                  {renderQPApprove()}
                  {renderQPReject()}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item ml={1} mr={3}>
            <MDBox bgColor="white" shadow="md" borderRadius="xl">
              <Grid container spacing={0}>

                <Grid item>
                  <Grid container spacing={0}>
                    <Grid item xs={12} md={6} xl={3}>
                      <DefaultInfoCard
                        iconHide
                        title={
                          anPengajuan?.invoicing_company?.length <= 16
                            ? anPengajuan?.invoicing_company
                            : `${anPengajuan?.invoicing_company?.substr(0, 12)}...`
                        }
                        titleTooltip={anPengajuan?.invoicing_company}
                        description="Pelanggan"
                        first
                      />
                    </Grid>
                    <Grid item xs={12} md={6} xl={3}>
                      <DefaultInfoCard
                        iconHide
                        title={anPengajuan?.created_date}
                        description="Tanggal Pengajuan"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} xl={3}>
                      <DefaultInfoCard
                        iconHide
                        title={`${anPengajuan?.term_of_payment} Hari`}
                        description="Durasi Pinjaman"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} xl={3}>
                      <DefaultInfoCard
                        iconHide
                        title={
                          Number(
                            anPengajuan.approved_amount ? anPengajuan.approved_amount : 0
                          ).toLocaleString("id-ID", { style: "currency", currency: "IDR" })
                            .length <= 16
                            ? Number(
                              anPengajuan.total ? anPengajuan.approved_amount : 0
                            ).toLocaleString("id-ID", { style: "currency", currency: "IDR" })
                            : `${Number(anPengajuan.approved_amount ? anPengajuan.total : 0)
                              .toLocaleString("id-ID", { style: "currency", currency: "IDR" })
                              .substr(0, 12)}...`
                        }
                        titleTooltip={Number(
                          anPengajuan.approved_amount ? anPengajuan.approved_amount : 0
                        ).toLocaleString("id-ID", { style: "currency", currency: "IDR" })}
                        description="Total Disetujui"
                        last
                      />
                    </Grid>

                    <Grid item xs={12} md={6} xl={3}>
                      <DefaultInfoCard
                        iconHide
                        title="100%"
                        description="Kelengkapan Legalitas"
                        first
                      />
                    </Grid>
                    <Grid item xs={12} md={6} xl={3}>
                      <DefaultInfoCard
                        iconHide
                        title="50%"
                        description="Kelengkapan Invoice"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} xl={3}>
                      <DefaultInfoCard
                        iconHide
                        title="10%"
                        description="Konfirmasi Pelanggan"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} xl={3}>
                      <DefaultInfoCard
                        iconHide
                        title="10%"
                        description="Konfirmasi GPS"
                        last
                      />
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>

          <Grid item ml={1} mr={3}>
            <Grid container spacing={3}>
              <Grid item alignItems="center" md={4}>
                <MDBox mb={2}>
                  <Card>
                    <MDBox textAlign="left" p={2}>
                      <MDTypography variant="h6" color="text" fontWeight="bold" mb={2}>
                        Skor Kelengkapan Data
                      </MDTypography>
                      <MDProgress value={80} color="info" variant="gradient" label={false} mb={1} />
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        Kelengkapan Data 80%
                      </MDTypography>
                    </MDBox>
                  </Card>
                </MDBox>
                {render_lampiran()}
                <Card>
                  <ListInfoCard
                    descriptionHide
                    title="Informasi Pengajuan"
                    info={
                      matchQP || matchQPFintech
                        ? rowsObjectPengajuanOrderQP
                        : matchPurchase || matchPurchaseFintech
                          ? rowsObjectPengajuanOrderPurchaseFiltered
                          : rowsObjectPengajuanOrderFiltered
                    }
                    shadow={false}
                  />
                </Card>
              </Grid>
              <Grid item alignItems="center" md={8}>
                <Accordion defaultExpanded sx={{ boxShadow: "10px 15px 27px -28px #1A73E8" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <MDTypography color="text" px={1} sx={{ fontSize: 14 }}>
                      Data {isPurchase ? "Order Penjualan" : "Invoice"} yang Diajukan{" "}
                    </MDTypography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MDBox>

                      {renderDataGrid()}
                    </MDBox>
                  </AccordionDetails>
                </Accordion>
                {/* {render_legality()} */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          open={openDialogQP}
          onClose={() => {
            setOpenDialogQP(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Setujui Pengajuan</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Apakah anda yakin untuk menyetujui pengajuan?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton
              onClick={handleCloseAgreeQP}
              variant="contained"
              color="info"
              size="small"
              autoFocus
            >
              Setujui
            </MDButton>
            <MDButton
              onClick={() => {
                setOpenDialogQP(false);
              }}
              variant="contained"
              color="error"
              size="small"
            >
              Batal
            </MDButton>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openDialog}
          onClose={handleCloseCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Tolak Pengajuan</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Apakah anda yakin untuk menolak pengajuan?
            </DialogContentText>
            <MDBox
              sx={{
                width: "100%",
              }}
              mt={2}
            >
              <MDInput
                id="keterangan"
                name="keterangan"
                multiline
                onChange={(e) => setTextKeterangan(e.target.value)}
                value={textKeterangan}
                rows={5}
                label="Alasan Penolakan"
                placeholder="Isi dengan alasan penolakan"
                fullWidth
              />
            </MDBox>
          </DialogContent>
          <DialogActions>
            <MDButton
              onClick={handleCloseAgree}
              variant="contained"
              color="error"
              size="small"
              autoFocus
            >
              Tolak
            </MDButton>
            <MDButton onClick={handleCloseCancel} variant="contained" color="info" size="small">
              Batal
            </MDButton>
          </DialogActions>
        </Dialog>
        <PelunasanPengajuanModal
          open={openPelunasanPengajuan}
          onClose={(e) => {
            setOpenPelunasanPengajuan(false);
          }}
          datapengajuan={{
            requestId: anPengajuan?.id,
            totalInvoice: anPengajuan?.total,
            totalPengajuan: anPengajuan?.approved_amount,
            fee: anPengajuan?.fee,
            company: customerCompany,
            companyId: customerCompanyId,
            balance: customerAvailableBalance,
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        />
      </DashboardLayout>
      {render_lunas()}
    </>
  );
}

export default DetailPengajuan;
