/* eslint-disable react/no-unstable-nested-components */
import { useState } from "react";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";

import { searchItem } from "common/Object"

import { convertRequestType } from "common/Util";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import CustomFooter from "components/LVCustomFooter";

function LVPersetujuanPaylaterDataGrid(props) {
  const user = JSON.parse(localStorage.getItem("user"));

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });


  const columns = [
    {
      field: "id",
    },
    {
      field: "no",
      headerName: "No.",
      headerAlign: "left",
      align: "left",
      width: 50,
      sortable: false,
    },
    {
      field: "number",
      headerName: "Nomor Pengajuan",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Link
          to={`/pengajuan/${encodeURIComponent(params.row.type)}/fintech/${encodeURIComponent(
            params.row.id
          )}`}
        >
          <MDTypography variant="body3">{params.value}</MDTypography>
        </Link>
      ),
      width: 150,
    },
    {
      field: "created_date",
      headerName: "Tanggal Pengajuan Dana",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 150,
    },
    {
      field: "type",
      headerName: "Tipe Pengajuan",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? convertRequestType(params.value) : ""}>
          <span>{convertRequestType(params.value)}</span>
        </Tooltip>
      ),
      width: 150,
    },
    {
      field: "invoicing_company",
      headerName: "Peminjam",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 150,
      sortable: false,
    },
    {
      field: "status_readable",
      headerName: "Status Pendanaan",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 180,
      sortable: false,
    },

    {
      field: "amount",
      headerName: "Total Pengajuan",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip
          title={
            params.value
              ? Number(params.value).toLocaleString("id-ID", { style: "currency", currency: "IDR" })
              : ""
          }
        >
          <span>
            {Number(params.value).toLocaleString("id-ID", { style: "currency", currency: "IDR" })}
          </span>
        </Tooltip>
      ),
      width: 150,
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Tindakan",
      sortable: false,
      align: "left",
      width: 200,
      getActions: (params) => {
        if (params.row.status) {
          if (
            params.row.status.toLowerCase() === "submitted" &&
            params.row.type === "purchase" &&
            user?.access_list?.includes("approve_reject_so_financing")
          ) {
            return [
              <MDBox display="flex" sx={{ justifyContent: "center" }}>
                <MDButton
                  sx={{ color: "#0D6ECD", fontSize: "12px", fontWeight: "bold" }}
                  onClick={(e, v) => {
                    props.onPersetujuan(params.row);
                  }}
                >
                  Setujui
                </MDButton>
                <MDButton
                  sx={{ color: "#F63939", fontSize: "12px", fontWeight: "bold" }}
                  onClick={(e) => {
                    props.onPenolakan(params.row.id, params.row.number);
                  }}
                >
                  Tolak
                </MDButton>
              </MDBox>,
            ];
          }
          if (
            params.row.status.toLowerCase() === "submitted" &&
            params.row.type === "invoice" &&
            user?.access_list?.includes("approve_reject_invoice_financing")
          ) {
            return [
              <MDBox display="flex" sx={{ justifyContent: "center" }}>
                <MDButton
                  sx={{ color: "#0D6ECD", fontSize: "12px", fontWeight: "bold" }}
                  onClick={(e, v) => {

                    props.onPersetujuan(params.row);
                  }}
                >
                  Setujui
                </MDButton>
                <MDButton
                  sx={{ color: "#F63939", fontSize: "12px", fontWeight: "bold" }}
                  onClick={(e) => {
                    props.onPenolakan(params.row.id, params.row.number);
                  }}
                >
                  Tolak
                </MDButton>
              </MDBox>,
            ];
          }
          if (
            params.row.status.toLowerCase() === "submitted" &&
            params.row.type === "quickpay" &&
            user?.access_list?.includes("approve_reject_qp")
          ) {
            return [
              <MDBox display="flex" sx={{ justifyContent: "center" }}>
                <MDButton
                  sx={{ color: "#0D6ECD", fontSize: "12px", fontWeight: "bold" }}
                  onClick={(e, v) => {

                    props.onPersetujuanQP(params.row);
                  }}
                >
                  Setujui
                </MDButton>
                <MDButton
                  sx={{ color: "#F63939", fontSize: "12px", fontWeight: "bold" }}
                  onClick={(e) => {
                    props.onPenolakan(params.row.id, params.row.number);
                  }}
                >
                  Tolak
                </MDButton>
              </MDBox>,
            ];
          }
          if (
            params.row.status.toLowerCase() === "approved" &&
            params.row.type === "invoice" &&
            user?.access_list?.includes("transfer_invoice_financing")
          ) {
            return [
              <MDBox display="flex" sx={{ justifyContent: "center" }}>
                <MDButton
                  sx={{ color: "#0D6ECD", fontSize: "12px", fontWeight: "bold" }}
                  onClick={(e) => props.onTransfer(params.row.id)}
                >
                  Transfer
                </MDButton>
              </MDBox>,
            ];
          }
          if (
            params.row.status.toLowerCase() === "approved" &&
            params.row.type === "quickpay" &&
            user?.access_list?.includes("payment_qp")
          ) {
            return [
              <MDBox display="flex" sx={{ justifyContent: "center" }}>
                <MDButton
                  sx={{ color: "#0D6ECD", fontSize: "12px", fontWeight: "bold" }}
                  onClick={(e) => props.onPaymentQP(params.row.id)}
                >
                  Transfer
                </MDButton>
              </MDBox>,
            ];
          }

          return [];
        }
      },
    },

  ];



  const [filterTimeout, setFiltertimeout] = useState();

  const filterHandlerDebouncing = (params) => {
    clearTimeout(filterTimeout);
    setFiltertimeout(setTimeout(() => filterHandler(params), 500));
  }

  const resetFilter = (params) => {
    props.resetFilter(params);
  }

  const filterHandler = (params) => {
    if (params.items[0] === undefined) {
      resetFilter();
      return;
    }
    let condition = params.items[0];

    if (condition.value === undefined || condition.value === "") {
      return;
    }

    if (condition.columnField === "status_readable") {
      searchStatus(condition.value);
    }

    if (condition.columnField === "type") {
      searchType(condition.value);
    }

    if (condition.columnField === "invoicing_company") {
      props.invoicingCompany(condition.value);
    }

  }

  const searchType = (keyword) => {
    let type = {
      "invoice": "invoice financing",
      "purchase": "order financing",
      "quickpay": "paylater",
    }
    let statusKeyword = searchItem(type, keyword);
    props.type(statusKeyword);
  }

  const searchStatus = (keyword) => {
    let status = {
      "created": "belum mengajukan",
      "submitted": "menunggu pendana",
      "approved": {
        "invoice": "menunggu transfer",
        "purchase": "aktif",
      },
      "fund_transferred": "dana ditransfer",
      "fund_paid": "lunas",
      "rejected": "ditolak"
    }
    let statusKeyword = searchItem(status, keyword);
    props.status(statusKeyword);
  }

  const mapSortingColumn = (column) => {
    let map = {
      "created_date": "created_at",
    }

    if (map.hasOwnProperty(column)) {
      return map[column];
    }
    else {
      return column
    }
  }

  const sortingHandler = (params) => {
    if (params.length === 0) {
      return;
    }

    props.order(mapSortingColumn(params[0].field), params[0].sort);
  }

  function CustomFooterProps() {
    return (
      <CustomFooter
        page={props.totalPage}
        selectPage={props.selectPage}
        nextPage={props.nextPage}
        prevPage={props.prevPage}
        currentPage={props.currentPage}
        firstPage={props.firstPage}
        lastPage={props.lastPage}
      />
    );
  }

  return (
    <MDBox
      sx={{
        height: props.height,
        width: "100%",
      }}
    >
      <DataGrid
        columns={columns}
        rows={props.rows}
        pageSize={props.pageSize}
        rowsPerPageOptions={props.rowsPerPageOptions}
        disableSelectionOnClick
        autoHeight={props.autoHeight}
        localeText={{ noRowsLabel: "Belum ada daftar pengajuan." }}
        sx={{
          bgcolor: "rgba(255, 255, 255, 1)",
          borderRadius: "16px",
          fontSize: "small",
          boxShadow: props.flat ? "" : "10px 15px 27px -28px #1A73E8",
        }}
        sortingMode="server"
        onSortModelChange={sortingHandler}

        filterMode="server"
        onFilterModelChange={filterHandlerDebouncing}

        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => {
          newModel.id = false;
          setColumnVisibilityModel(newModel);
        }}
        experimentalFeatures={{ newEditingApi: true }}
        components={{ Footer: CustomFooterProps }}

      />
    </MDBox>
  );
}

export default LVPersetujuanPaylaterDataGrid;
