export default
  (
    state = [], action

  ) => {
    switch (action.type) {
      case 'GET_INVOICE_CUSTOMER_DETAIL':
        return action.payload;
      default:
        return state;
    }

  };