import { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import Radio from '@mui/material/Radio';
import Tooltip from '@mui/material/Tooltip';
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import { fetchFintechList } from '../../actions/fintechAction';


const LVFintechDataGrid = (props) => {

    const [columnVisibilityModel, setColumnVisibilityModel] =useState({});
    const [selected, setSelected] = useState(null);
 
    const listFintech = useSelector(
        state => {
          return state.listFintech;
        },
        shallowEqual
    );

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(fetchFintechList());
    }, []); 

    useEffect(() => {
    },[listFintech]
    );


    const columns = [        
        {
            field: 'id',
            headerName: ' ',
            width: 40,
            renderCell: (cellValues) => {
              return (
                <Radio 
                  checked={cellValues.id === selected}
                  onChange={() => onRadioChange(cellValues.id)}
                  value={cellValues.id}
                  name="radio-buttons"
                  inputProps={{ 'aria-label': {cellValues} }}
                />
               )
            }
          }, 
      { 
        field: 'name', 
        headerName: 'Nama Fintech', 
        headerAlign: 'center',
        align:"left",
        renderCell: (params) =>  (
          <Tooltip title={params.value?params.value:''} >
              <span>{params.value}</span>
          </Tooltip>
        ),
        width: 150,
      },
      { 
        field: 'financing_amount', 
        headerName: 'Sisa Kredit Limit', 
        headerAlign: 'center',
        align:"left",
        renderCell: (params) =>  {
          const valueFormatted = Number(params.value).toLocaleString("id-ID", {style:"currency", currency:"IDR"});  
          return (
            <Tooltip title={params.value?valueFormatted:''} >
              <span>{valueFormatted}</span>
            </Tooltip>
          );
        },                          
        width: 150,
      },
      { 
        field: 'financing_percentage', 
        headerName: 'Persentase Pendanaan', 
        headerAlign: 'center',
        align:"left",
        renderCell: (params) =>  (
          <Tooltip title={params.value?params.value:''} >
              <span>{params.value}%</span>
          </Tooltip>
        ),
        width: 150,
      },
      { 
        field: 'interest_annum', 
        headerName: 'Bunga', 
        headerAlign: 'center',
        align:"left",
        renderCell: (params) =>  (
          <Tooltip title={params.value?params.value:''} >
              <span>{params.value}% per tahun</span>
          </Tooltip>
        ),
        width: 150,
      },
      { 
        field: 'other', 
        headerName: 'Biaya Lainnya', 
        headerAlign: 'center',
        align:"left",
        renderCell: (params) => {
          return (
            <div>
              <Grid container direction="column">
              {params.value.map((item,idx) => {
                var label = item.name.charAt(0).toUpperCase() + item.name.slice(1)
                var value = '';
                if (item.type==="percentage") {
                  value = item.value+"%";
                } else if (item.type==="amount") {
                  value = Number(item.value).toLocaleString("id-ID", {style:"currency", currency:"IDR"});  
                }
                else {
                  value = item.value;
                }                
                return (                
                    <Grid item key={idx}> {label}: {value} </Grid>                                    
                );                  
              })}
              </Grid>
            </div> 
          );
        },  
        width: 200,
      },
      { 
        field: 'kontrak', 
        headerName: 'Link Kontrak', 
        headerAlign: 'center',
        align:"left",
        renderCell: (params) =>  (
          <Tooltip title={params.value?params.value:''} >
              <span>{params.value}</span>
          </Tooltip>
        ),
        width: 150,
      },
    ];

    const rows = [
        {id:1, name:'Gandeng Tangan', limit: 'Rp 30.000.000,00', persentase: '80%', bunga:'24% per tahun', tenor: '30 hari', lainnya: '1% admin fee, 1% provision fee', kontrak: 'link' },
        {id:2, name:'Dana Mas', limit: 'Rp 30.000.000,00', persentase: '80%', bunga:'24% per tahun', tenor: '30 hari', lainnya: '1% admin fee, 1% provision fee', kontrak: 'link' },
        {id:3, name:'Maucash', limit: 'Rp 30.000.000,00', persentase: '80%', bunga:'24% per tahun', tenor: '30 hari', lainnya: '1% admin fee, 1% provision fee', kontrak: 'link' },
    ]

    const onRadioChange = (val) => {
        props.onFintechSelected(val);
        setSelected(val);
    }

    return (
        <MDBox 
            sx={{ 
                height: 400, 
                width: '100%',          
            }} 
        
        >
            <DataGrid
                columns={columns}
                rows={listFintech}
                pageSize={5}
                rowsPerPageOptions={[5]}
                //checkboxSelection
                disableSelectionOnClick
                sx={{
                  bgcolor: 'rgba(255, 255, 255, 1)', 
                  borderRadius: '16px', 
                  fontSize:'small',
                  boxShadow: '10px 15px 27px -28px #1A73E8',             
                }}        
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) => {
                    newModel.id = false;
                    this.setState({ columnVisibilityModel: newModel });
                    setColumnVisibilityModel(newModel);
                }                     
              }
              experimentalFeatures={{ newEditingApi: true }}        
            />
        </MDBox>
    );
};

export default LVFintechDataGrid;