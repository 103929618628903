import { useState, useEffect } from 'react';
import { connect, useSelector, useDispatch, shallowEqual } from "react-redux";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SimpleDashboardNavbar from "examples/Navbars/SimpleDashboardNavbar";
import LVPengguna from 'components/LVPengguna';

const DaftarPengguna = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
        };
    }, []);

    return (
        <DashboardLayout>
            <SimpleDashboardNavbar name="Daftar Pengguna" />
            <LVPengguna
                rows={[{ "id": 1 }]}
            />
        </DashboardLayout>
    );
}

export default DaftarPengguna;