import { useState, useEffect, useRef } from "react";
import { connect, useSelector, useDispatch, shallowEqual } from "react-redux";

import Grid from "@mui/material/Grid";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { emptyMessage } from 'actions';
import {
    approveFinancing, approveFinancingPurchase, getFintechCompRelation,
    getInvoiceTaxInformation
} from '../../actions/fintechAction';


const Persetujuan = (props) => {
    let totalTax = 0;

    const [top, setTop] = useState({
        id: -1,
        value: -1,
        label: ''
    });

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 725,

        height: 595,
        bgcolor: 'background.paper',
        borderRadius: 2,
        boxShadow: 24,
        overflow: "auto",
        p: 4,
    };

    const unit = [
        {
            "id": 1,
            "value": "percentage",
            "label": "(%) Persentase"
        },
        {
            "id": 2,
            "value": "fixed",
            "label": "(Rp) Angka Tetap"
        },

    ]

    const schemeOptions = [
        {
            "id": 1,
            "value": "conventional",
            "label": "Konvensional"
        },
        {
            "id": 2,
            "value": "profit_sharing",
            "label": "Bagi Hasil"
        },

    ]


    const [interestDurasi, setInterestDurasi] = useState(0);
    const [interestTahunan, setInterestTahunan] = useState(0);
    const [approvedAmount, setApprovedAmount] = useState(0);
    const [approvedAmountStr, setApprovedAmountStr] = useState(Number(0).toLocaleString("id-ID",
        {
            style: "currency",
            currency: "IDR",
        }
    ));
    const [isEditApprovedAmount, setisEditApprovedAmount] = useState(false);
    const [minimumPeriod, setMinimumPeriod] = useState(0);
    const [penaltiBulanan, setPenaltiBulanan] = useState(0);
    const [profitSharingContractor, setProfitSharingContractor] = useState(0);
    const [profitSharingFintech, setProfitSharingFintech] = useState(0);
    const [scheme, setScheme] = useState(schemeOptions[0]);
    const [profitSharing, setProfitSharing] = useState(false);
    const [adminFee, setAdminFee] = useState(0);
    const [adminFeeUnit, setAdminFeeUnit] = useState(0);

    const [otherFee, setOtherFee] = useState(0);
    const [otherFeeUnit, setOtherFeeUnit] = useState(unit[0]);

    const [monthlyAdminFee, setMonthlyAdminFee] = useState(0);
    const [monthlyAdminFeeUnit, setMonthlyAdminFeeUnit] = useState("");
    const [monthlyInstallment, setMonthlyInstallment] = useState(false);

    const [closingFee, setClosingFee] = useState(0);
    const [closingFeeUnit, setClosingFeeUnit] = useState(unit[0]);

    const onMonthlyAdminFeeUnitChange = (e, v) => {
        setMonthlyAdminFeeUnit(v);
    }

    const onMonthlyInstallmentChange = (e, v) => {
        setMonthlyInstallment(v);
    }
    const onOtherFeeUnitChange = (e, v) => {
        setOtherFeeUnit(v);
    }

    const onOtherFeeBlur = (param) => {
        if (otherFee !== "") {
            if (otherFee < 0) {
                otherFee(0);
            }
        }
    };

    const onOtherFeeFocus = (param) => {
    };

    const onClosingFeeUnitChange = (e, v) => {
        setClosingFeeUnit(v);
    }

    const onClosingFeeBlur = (param) => {
        if (closingFee !== "") {
            if (closingFee < 0) {
                setClosingFee(0);
            }
        }
    };

    const onClosingFeeFocus = (param) => {
    };

    const [showError, setShowError] = useState({
        top: false,
        interestDurasi: false,
        interestTahunan: false,
        approvedAmount: false,
        fail: false,
        all: false,
    });
    const [helperMsg, setHelperMsg] = useState({
        top: 'Durasi pinjaman harus dipilih.',
        interestDurasi: 'Bunga dalam masa pinjaman harus diisi',
        interestTahunan: 'Bunga tahunan harus diisi',
        approvedAmount: 'Jumlah harus diisi',
        all: 'Masih terdapat informasi wajib yang kosong.',
        fail: 'ERROR'
    });

    const dispatch = useDispatch();

    const fintechCompRelation = useSelector(
        state => {
            return state.fintechCompRelation;
        },
        shallowEqual

    );


    const setProfitSharingProportion = (contractor, fintech) => {
        if (contractor !== null) {
            setProfitSharingContractor(contractor);
            const fintechSharing = 100 - contractor;
            setProfitSharingFintech(fintechSharing);
        } else if (fintech !== null) {
            setProfitSharingFintech(fintech);
            const contractorSharing = 100 - fintech;
            setProfitSharingContractor(contractorSharing);
        }
    }



    const invoiceTaxInformation = useSelector(
        state => {
            return state.invoiceTaxInformation;
        },
        shallowEqual
    )

    const topOptions = [
        {
            id: 0,
            value: 7,
            label: 'Jatuh Tempo 7 Hari'
        },
        {
            id: 1,
            value: 14,
            label: 'Jatuh Tempo 14 Hari'
        },
        {
            id: 2,
            value: 21,
            label: 'Jatuh Tempo 21 Hari'
        },
        {
            id: 3,
            value: 30,
            label: 'Jatuh Tempo 30 Hari'
        },
        {
            id: 4,
            value: 45,
            label: 'Jatuh Tempo 45 Hari'
        },
        {
            id: 5,
            value: 60,
            label: 'Jatuh Tempo 60 Hari'
        },
        {
            id: 6,
            value: 75,
            label: 'Jatuh Tempo 75 Hari'
        },
        {
            id: 7,
            value: 90,
            label: 'Jatuh Tempo 90 Hari'
        },
        {
            id: 8,
            value: 105,
            label: 'Jatuh Tempo 105 Hari'
        },
        {
            id: 9,
            value: 120,
            label: 'Jatuh Tempo 120 Hari'
        },
    ];

    const message = useSelector(
        state => {
            return state.message;
        },
        shallowEqual
    );

    useEffect(() => {
        dispatch(getFintechCompRelation(props.rowfinancing.invoicing_company_id));
        dispatch(getInvoiceTaxInformation(props.rowfinancing.invoice_id))
    }, [])

    const calculateTax = (invoices) => {
        totalTax = 0;
        invoices.map((invoice) => {
            totalTax += parseFloat(invoice.header.inclusive_tax_amount)
        })
    }

    useEffect(() => {
        calculateTax(invoiceTaxInformation);
        setApprovedAmount(parseFloat(
            (props.rowfinancing.amount - totalTax) * fintechCompRelation.financing_percentage / 100).toFixed(0).toLocaleString("id-ID",
                {
                    style: "currency",
                    currency: "IDR",
                }
            ));

        setApprovedAmountStr(Number(approvedAmount).toLocaleString("id-ID",
            {
                style: "currency",
                currency: "IDR",
            }
        ))

        setInterestTahunan(fintechCompRelation.interest_annum);
        if (props.rowfinancing.term_of_payment <= 7) {
            setTop({
                id: 0,
                value: 7,
                label: 'Jatuh Tempo 7 Hari'
            },);
            setInterestDurasi(fintechCompRelation.interest_base * 7 / 30);
        } else if ((props.rowfinancing.term_of_payment > 7) && (props.rowfinancing.term_of_payment <= 14)) {
            setTop({
                id: 1,
                value: 14,
                label: 'Jatuh Tempo 14 Hari'
            },);
            setInterestDurasi(fintechCompRelation.interest_base * 14 / 30);
        } else if ((props.rowfinancing.term_of_payment > 14) && (props.rowfinancing.term_of_payment <= 21)) {
            setTop({
                id: 2,
                value: 21,
                label: 'Jatuh Tempo 21 Hari'
            });
            setInterestDurasi(fintechCompRelation.interest_base * 21 / 30);
        } else if ((props.rowfinancing.term_of_payment > 21) && (props.rowfinancing.term_of_payment <= 30)) {
            setTop({
                id: 3,
                value: 30,
                label: 'Jatuh Tempo 30 Hari'
            });
            setInterestDurasi(fintechCompRelation.interest_base);
        } else if ((props.rowfinancing.term_of_payment > 30) && (props.rowfinancing.term_of_payment <= 45)) {
            setTop({
                id: 4,
                value: 45,
                label: 'Jatuh Tempo 45 Hari'
            });
            setInterestDurasi(fintechCompRelation.interest_base * 45 / 30);
        } else if ((props.rowfinancing.term_of_payment > 45) && (props.rowfinancing.term_of_payment <= 60)) {
            setTop({
                id: 5,
                value: 60,
                label: 'Jatuh Tempo 60 Hari'
            });
            setInterestDurasi(fintechCompRelation.interest_base * 60 / 30);
        } else if ((props.rowfinancing.term_of_payment > 60) && (props.rowfinancing.term_of_payment <= 75)) {
            setTop({
                id: 6,
                value: 75,
                label: 'Jatuh Tempo 75 Hari'
            });
            setInterestDurasi(fintechCompRelation.interest_base * 75 / 30);
        } else if ((props.rowfinancing.term_of_payment > 75) && (props.rowfinancing.term_of_payment <= 90)) {
            setTop({
                id: 7,
                value: 90,
                label: 'Jatuh Tempo 90 Hari'
            });
            setInterestDurasi(fintechCompRelation.interest_base * 90 / 30);
        } else if ((props.rowfinancing.term_of_payment > 90) && (props.rowfinancing.term_of_payment <= 105)) {
            setTop({
                id: 8,
                value: 105,
                label: 'Jatuh Tempo 105 Hari'
            });
            setInterestDurasi(fintechCompRelation.interest_base * 105 / 30);
        } else if ((props.rowfinancing.term_of_payment > 105) && (props.rowfinancing.term_of_payment <= 120)) {
            setTop({
                id: 9,
                value: 120,
                label: 'Jatuh Tempo 120 Hari'
            });
            setInterestDurasi(fintechCompRelation.interest_base * 120 / 30);
        }

        if (fintechCompRelation.other) {
            fintechCompRelation.other.forEach((item) => {
                if (item.name === "minimum_period" || item.name === "minimum_duration") {
                    setMinimumPeriod(item.value);
                } else if (item.name === "penalty") {
                    setPenaltiBulanan(item.value);
                } else if (item.name === "admin_fee_percentage") {
                    setAdminFee(item.value);
                    setAdminFeeUnit(unit[0]);
                } else if (item.name === "admin_fee_fixed") {
                    setAdminFee(item.value);
                    setAdminFeeUnit(unit[1]);

                } else if (item.name === "other_fee_percentage") {
                    setOtherFee(item.value);
                    setOtherFeeUnit(unit[0]);
                } else if (item.name === "other_fee_fixed") {
                    setOtherFee(item.value);
                    setOtherFeeUnit(unit[1]);

                } else if (item.name === "monthly_fee_percentage") {
                    setMonthlyAdminFee(item.value);
                    setMonthlyAdminFeeUnit(unit[0]);
                } else if (item.name === "monthly_fee_fixed") {
                    setMonthlyAdminFee(item.value);
                    setMonthlyAdminFeeUnit(unit[1]);
                } else if (item.name === "monthly_installment") {
                    setMonthlyInstallment(item.value);

                } else if (item.name === "closing_fee_percentage") {
                    setClosingFee(item.value);
                    setClosingFeeUnit(unit[0]);
                } else if (item.name === "closing_fee_fixed") {
                    setClosingFee(item.value);
                    setClosingFeeUnit(unit[1]);
                }
            });
        }


    }, [fintechCompRelation, invoiceTaxInformation]);

    useEffect(() => {
        dispatch(emptyMessage());
        switch (message.status) {
            case 'APPROVE_FINANCING':
                props.onClose();
                return;
            case 'FAIL_APPROVE_FINANCING':
                setShowError(
                    {
                        ...showError,
                        fail: true
                    }
                );
                setHelperMsg({
                    ...helperMsg,
                    fail: translateErrorMessage(message.message)
                });
                return;
            case 'ERROR':
                setShowError(
                    {
                        ...showError,
                        fail: true
                    }
                );
                return;
            default:
                return;
        }
    }, [message]);


    useEffect(() => {
        if (interestDurasi > 0) {
            setShowError({ ...showError, interestDurasi: false });
        }
    }, [interestDurasi]);

    useEffect(() => {
        if (interestTahunan > 0) {
            setShowError({ ...showError, interestTahunan: false });
        }
    }, [interestTahunan]);

    useEffect(() => {
        if (
            (interestDurasi > 0) &&
            (interestTahunan > 0) &&
            (top.id !== -1)
        ) {
            setShowError({ interestTahunan: false, interestDurasi: false, all: false });
        }
    }, [interestDurasi, interestTahunan, top]);


    const onInterestOnTopChange = (e, v) => {
        let interestYearlyTemp = 12 * 30 * interestDurasi / top.value;
        setInterestTahunan(interestYearlyTemp.toFixed(2));
    }

    const onInterestYearlyChange = (e, v) => {
        let interestBaseTemp = (interestTahunan / (12 * 30)) * top.value;
        setInterestDurasi(interestBaseTemp.toFixed(2));
    }

    const onBlurTop = (e, v) => {
        onInterestOnTopChange();
    }

    const onAdminFeeUnitChange = (e, v) => {
        setAdminFeeUnit(v);
    }

    const onChangeTOP = (e, v) => {
        if (v === null) {
            setShowError({ ...showError, top: true });
        } else {
            setShowError({ ...showError, top: false });
            setTop(v);
        }

    }

    const onSchemeChange = (e, v) => {
        if (v.value === "profit_sharing") {
            setProfitSharing(true);
        } else {
            setProfitSharing(false);
        }
        setScheme(v);
    }

    const translateErrorMessage = (message) => {
        const NUMERIC_REGEXP = /[\d\.\,]+/g;
        const BASE_ERROR_MESSAGE = "Mohon maaf terjadi kesalahan, silahkan ulangi beberapa saat lagi";
        const BASE_HELP_MESSAGE = "Mohon maaf terjadi kesalahan, silahkan hubungi admin kami";
        if (message.includes("insufficient credit limit")) {
            let creditLimit = message.match(NUMERIC_REGEXP)
            return `Kredit Limit tidak mencukupi, tersisa Rp ${creditLimit[0]}`
        } else if (message.includes("fail to get credit limit financing")) {
            return BASE_HELP_MESSAGE
        } else if (message.includes("status change not valid")) {
            return BASE_HELP_MESSAGE
        } else {
            return BASE_ERROR_MESSAGE
        }
    }

    const onAgree = () => {
        if (
            (top.id !== -1) &&
            (interestDurasi > 0) &&
            (interestTahunan > 0)
        ) {

            var other = [];
            if (minimumPeriod > 0) {
                other = [...other, {
                    name: "minimum_period",
                    type: "number",
                    value: Number(minimumPeriod)
                }]
            }
            if (penaltiBulanan > 0) {
                other = [...other, {
                    name: "penalty",
                    type: "percentage",
                    value: Number(penaltiBulanan)
                }]
            }

            if (profitSharing === true) {
                other = [...other, {
                    name: "profit_sharing",
                    type: "number",
                    value: 1
                },
                {
                    name: "profit_sharing_contractor",
                    type: "percentage",
                    value: Number(profitSharingContractor)
                },
                {
                    name: "profit_sharing_fintech",
                    type: "percentage",
                    value: Number(profitSharingFintech)
                }]
            }

            if (adminFee > 0) {
                if (adminFeeUnit.value == "percentage") {
                    other = [
                        ...other,
                        {
                            name: "admin_fee_percentage",
                            type: "number",
                            value: Number(adminFee),
                        },
                    ];
                } else if (adminFeeUnit.value == "fixed") {
                    other = [
                        ...other,
                        {
                            name: "admin_fee_fixed",
                            type: "number",
                            value: Number(adminFee),
                        },
                    ];
                }
            }

            if (otherFee > 0) {
                if (otherFeeUnit.value == "percentage") {
                    other = [
                        ...other,
                        {
                            name: "other_fee_percentage",
                            type: "number",
                            value: Number(otherFee),
                        },
                    ];
                } else if (otherFeeUnit.value == "fixed") {
                    other = [
                        ...other,
                        {
                            name: "other_fee_fixed",
                            type: "number",
                            value: Number(otherFee),
                        },
                    ];
                }
            }

            if (closingFee > 0) {
                if (closingFeeUnit.value == "percentage") {
                    other = [
                        ...other,
                        {
                            name: "closing_fee_percentage",
                            type: "number",
                            value: Number(closingFee),
                        },
                    ];
                } else if (closingFeeUnit.value == "fixed") {
                    other = [
                        ...other,
                        {
                            name: "closing_fee_fixed",
                            type: "number",
                            value: Number(closingFee),
                        },
                    ];
                }
            }

            if (monthlyAdminFee > 0) {
                if (monthlyAdminFeeUnit.value == "percentage") {
                    other = [
                        ...other,
                        {
                            name: "monthly_fee_percentage",
                            type: "number",
                            value: Number(monthlyAdminFee),
                        },
                    ];
                } else if (monthlyAdminFeeUnit.value == "fixed") {
                    other = [
                        ...other,
                        {
                            name: "monthly_fee_fixed",
                            type: "number",
                            value: Number(monthlyAdminFee),
                        },
                    ];
                }
            }
            if (monthlyInstallment !== undefined) {
                other = [
                    ...other,
                    {
                        name: "monthly_installment",
                        type: "number",
                        value: monthlyInstallment ? 1 : 0,
                    },
                ];
            }

            const term = {
                financing_id: Number(props.idfinancing),
                term_of_payment: Number(top.value),
                interest_base: Number(interestDurasi),
                interest_annum: Number(interestTahunan),
                approved_amount: Number(approvedAmount),
                other: other
            };

            if (props.rowfinancing.type === 'invoice') {
                dispatch(approveFinancing(term));
            } else if (props.rowfinancing.type === 'purchase') {
                dispatch(approveFinancingPurchase(term));
            }

        } else {
            setShowError({
                top: top.id === -1,
                interestDurasi: interestDurasi <= 0,
                interestTahunan: interestTahunan <= 0,
                all: true,
            });
        }
    }

    const onInterestDurasiBlur = () => {
        onInterestOnTopChange();
        if (interestDurasi <= 0) {
            setShowError({ ...showError, interestDurasi: true });
        }
    }

    const onInterestTahunanBlur = () => {
        onInterestYearlyChange();
        if (interestTahunan <= 0) {
            setShowError({ ...showError, interestTahunan: true });
        }
    }

    const onApprovedAmountFocus = () => {
        setisEditApprovedAmount(true);
    }

    const onApprovedAmountBlur = () => {

        if (approvedAmount <= 0) {
            setShowError({ ...showError, approvedAmount: true });
        }

        setApprovedAmountStr(Number(approvedAmount).toLocaleString("id-ID",
            {
                style: "currency",
                currency: "IDR",
            }
        ))

        setisEditApprovedAmount(false);
    }

    const onMinimumPeriodBlur = () => {
        if (minimumPeriod <= 0) {
            setMinimumPeriod(0);
        }
    }

    const onPenaltiBulananBlur = () => {
        if (penaltiBulanan <= 0) {
            setPenaltiBulanan(0);
        }
    }

    const onProfitSharingContractorBlur = () => {
        if (profitSharingContractor <= 0) {
            setProfitSharingContractor(0);
        }
    }

    const onProfitSharingFintechBlur = () => {
        if (profitSharingFintech <= 0) {
            setProfitSharingFintech(0);
        }
    }

    const onAdminFeeBlur = () => {
        if (adminFee <= 0) {
            setAdminFee(0);
        }

        if (adminFee > 100) {
            setAdminFee(100);
        }
    }

    const onAdminFeeFocus = (param) => {
    };

    return (
        <div>
            <MDBox sx={style}>
                <Grid container direction="column" justifyContent="space-around" spacing={3}>
                    <Grid item>
                        <MDBox>
                            <MDTypography variant="title" fontWeight="medium">
                                Setujui Pengajuan
                            </MDTypography>
                        </MDBox>
                    </Grid>
                    <Grid item>
                        <MDBox sx={{ width: '100%' }}>
                            <Grid container direction="column" alignItems="left" justifyContent="space-around" spacing={2}>
                                <Grid item mb={2}>
                                    <Autocomplete
                                        disablePortal
                                        id="profit_sharing"
                                        options={schemeOptions}
                                        value={scheme}
                                        isOptionEqualToValue={(option, value) =>
                                            option.value === value.value
                                        }
                                        onChange={onSchemeChange}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} label="Skema" />}
                                    />
                                    <MDTypography color="error" sx={{ fontSize: 10 }}>{showError.top ? helperMsg.top : ''} </MDTypography>
                                </Grid>

                                {profitSharing === true ? (
                                    <>
                                        <Grid container direction="row" alignItems="left"
                                            spacing={2} p={2}>
                                            <Grid item>
                                                <MDInput
                                                    id="profit_sharing_contractor"
                                                    name="profit_sharing_contractor"
                                                    label="Bagi Hasil Kontraktor (%)"
                                                    value={profitSharingContractor}
                                                    type="number"
                                                    onChange={(e) => setProfitSharingProportion(e.target.value, null)}
                                                    onBlur={onProfitSharingContractorBlur}
                                                    placeholder="Bagi Hasil Untuk Kontraktor dalam persen"
                                                    required
                                                    sx={{ width: 300 }}
                                                />
                                                <MDTypography color="error" sx={{ fontSize: 10 }}>{showError.profitSharingContractor ? helperMsg.profitSharingContractor : ''} </MDTypography>
                                            </Grid>

                                            <Grid item>
                                                <MDInput
                                                    id="profit_sharing_fintech"
                                                    name="profit_sharing_fintech"
                                                    label="Bagi Hasil Fintech (%)"
                                                    value={profitSharingFintech}
                                                    type="number"
                                                    onChange={(e) => setProfitSharingProportion(null, e.target.value)}
                                                    onBlur={onProfitSharingFintechBlur}
                                                    placeholder="Bagi Hasil Untuk Fintech dalam persen"
                                                    required
                                                    sx={{ width: 300 }}
                                                />
                                                <MDTypography color="error" sx={{ fontSize: 10 }}>{showError.profitSharingFintech ? helperMsg.profitSharingFintech : ''} </MDTypography>
                                            </Grid>
                                        </Grid>
                                    </>) : ''}


                                <Grid item mb={2}>
                                    <Autocomplete
                                        disablePortal
                                        id="select_top"
                                        options={topOptions}
                                        value={top}
                                        isOptionEqualToValue={(option, value) =>
                                            option.value === value.value
                                        }
                                        onChange={onChangeTOP}
                                        onBlur={onBlurTop}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} label="Durasi Pinjaman" />}
                                    />
                                    <MDTypography color="error" sx={{ fontSize: 10 }}>{showError.top ? helperMsg.top : ''} </MDTypography>
                                </Grid>


                                {profitSharing !== true ? (
                                    <>
                                        <Grid container direction="row" alignItems="left"
                                            spacing={2} p={2}>
                                            <Grid item >
                                                <MDInput
                                                    id="interest_durasi"
                                                    name="interest_durasi"
                                                    label="Bunga Durasi Pinjaman (%)"
                                                    value={interestDurasi}
                                                    type="number"
                                                    onChange={(e) => setInterestDurasi(e.target.value)}
                                                    onBlur={onInterestDurasiBlur}
                                                    placeholder="Bunga yang disetujui selama durasi pinjaman"
                                                    required
                                                    sx={{ width: 300 }}
                                                />
                                                <MDTypography color="error" sx={{ fontSize: 10 }}>{showError.interestDurasi ? helperMsg.interestDurasi : ''} </MDTypography>
                                            </Grid>
                                            <Grid item>
                                                <MDInput
                                                    id="interest_tahunan"
                                                    name="interest_tahunan"
                                                    label="Bunga 1 Tahun (%)"
                                                    value={interestTahunan}
                                                    type="number"
                                                    onBlur={onInterestTahunanBlur}
                                                    onChange={(e) => setInterestTahunan(e.target.value)}
                                                    placeholder="Bunga per tahun "
                                                    required
                                                    sx={{ width: 300 }}
                                                />
                                                <MDTypography color="error" sx={{ fontSize: 10 }}>{showError.interestTahunan ? helperMsg.interestTahunan : ''} </MDTypography>
                                            </Grid>
                                        </Grid>
                                    </>) : ''}
                                {/* <Grid item mb={2} display={props.rowfinancing.type==='invoice'?'none':'grid'}> */}
                                <Grid item mb={2}>
                                    <MDInput
                                        id="approved_amount"
                                        name="approved_amount"
                                        label="Jumlah yang Disetujui"
                                        value={isEditApprovedAmount ? approvedAmount : approvedAmountStr}
                                        onFocus={onApprovedAmountFocus}
                                        onChange={(e) => setApprovedAmount(e.target.value)}
                                        onBlur={onApprovedAmountBlur}
                                        placeholder="Limit pengajuan untuk order penjualan"
                                        required
                                        sx={{ width: 300 }}
                                    />
                                    <MDTypography color="error" sx={{ fontSize: 10 }}>{showError.approvedAmount ? helperMsg.approvedAmount : ''} </MDTypography>
                                </Grid>
                                <Grid item mb={2}>
                                    <MDInput
                                        id="minimum_period"
                                        name="minimum_period"
                                        label="Durasi Pinjaman Minimum (hari)"
                                        value={minimumPeriod}
                                        type="number"
                                        onBlur={onMinimumPeriodBlur}
                                        onChange={(e) => setMinimumPeriod(e.target.value)}
                                        placeholder="Durasi pinjaman minimum"
                                        sx={{ width: 300 }}
                                    />
                                    <MDTypography color="error" sx={{ fontSize: 10 }}>{showError.interestTahunan ? helperMsg.interestTahunan : ''} </MDTypography>
                                </Grid>

                                {/* replaced with admin fee and admin fee unit field */}
                                {/* <Grid item mb={2}>
                                    <MDInput
                                        id="admin_fee"
                                        name="admin_fee"
                                        label="Biaya Admin (%)"
                                        value={adminFee}
                                        type="number"
                                        onBlur={onAdminFeeBlur}
                                        onChange={(e) => setAdminFee(e.target.value)}
                                        placeholder="Besaran biaya admin"
                                        sx={{ width: 300 }}
                                    />
                                    <MDTypography color="error" sx={{ fontSize: 10 }}>{showError.interestTahunan ? helperMsg.interestTahunan : ''} </MDTypography>
                                </Grid> */}


                                <Grid
                                    container
                                    spacing={2}
                                    p={2}
                                >
                                    <Grid item>
                                        <MDInput
                                            id="admin_fee"
                                            name="admin_fee"
                                            label="Biaya Admin/Provisi"
                                            value={adminFee}
                                            onChange={(e) => setAdminFee(e.target.value)}
                                            onBlur={onAdminFeeBlur}
                                            onFocus={onAdminFeeFocus}
                                            placeholder="Biaya Admin"
                                            sx={{ width: 300 }}
                                        />
                                    </Grid>
                                    <Grid item
                                    // mb={2}
                                    >
                                        <Autocomplete
                                            disablePortal
                                            id="platform_fee_unit"
                                            options={unit}
                                            value={adminFeeUnit}
                                            isOptionEqualToValue={(option, value) =>
                                                option.value === value.value
                                            }
                                            onChange={onAdminFeeUnitChange}
                                            sx={{ width: 300 }}
                                            height="44px"
                                            renderInput={(params) => <TextField {...params} label="Satuan Biaya Admin" />}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <MDBox sx={{ width: "100%", mt: 0 }}>
                                        <Grid
                                            container
                                            spacing={2}
                                        >
                                            <Grid item mb={2}
                                            // xs={12} md={6}
                                            >
                                                <MDInput
                                                    id="other_fee"
                                                    name="other_fee"
                                                    label="Biaya Lain"
                                                    value={otherFee}
                                                    onChange={(e) => setOtherFee(e.target.value)}
                                                    onBlur={onOtherFeeBlur}
                                                    onFocus={onOtherFeeFocus}
                                                    placeholder="Biaya Lain"
                                                    sx={{ width: 300 }}
                                                />
                                            </Grid>
                                            <Grid item mb={2}>
                                                <Autocomplete
                                                    disablePortal
                                                    id="platform_fee_unit"
                                                    options={unit}
                                                    value={otherFeeUnit}
                                                    isOptionEqualToValue={(option, value) =>
                                                        option.value === value.value
                                                    }
                                                    onChange={onOtherFeeUnitChange}
                                                    sx={{ width: 300, px: 0 }}
                                                    renderInput={(params) => <TextField {...params} label="Satuan Biaya Lain" />}
                                                />
                                            </Grid>

                                        </Grid>
                                    </MDBox>
                                </Grid>


                                <Grid item mb={2}>
                                    <MDInput
                                        id="penalti_bulanan"
                                        name="penalti_bulanan"
                                        label="Penalti per 30 hari (%)"
                                        value={penaltiBulanan}
                                        type="number"
                                        onBlur={onPenaltiBulananBlur}
                                        onChange={(e) => setPenaltiBulanan(e.target.value)}
                                        placeholder="Penalti tiap 30 hari "
                                        sx={{ width: 300 }}
                                    />
                                    <MDTypography color="error" sx={{ fontSize: 10 }}>{showError.interestTahunan ? helperMsg.interestTahunan : ''} </MDTypography>
                                </Grid>

                                <Grid item>

                                    <MDBox sx={{ width: "100%", mt: 0 }}>
                                        <Grid
                                            container
                                            spacing={2}
                                        >
                                            <Grid item
                                            // xs={12} md={6}
                                            >
                                                <MDInput
                                                    id="monthly_admin_fee"
                                                    name="monthly_admin_fee"
                                                    label="Biaya Admin Bulanan"
                                                    value={monthlyAdminFee}
                                                    onChange={(e) => setMonthlyAdminFee(e.target.value)}
                                                    // onBlur={onKreditLimitBlur}
                                                    // onFocus={onKreditLimitFocus}
                                                    placeholder="Biaya Admin"
                                                    sx={{ width: 300 }}
                                                />
                                            </Grid>
                                            <Grid item
                                                xs={12} md={6}
                                            >
                                                <Autocomplete
                                                    disablePortal
                                                    id="monthly_admin_fee_unit"
                                                    options={unit}
                                                    value={monthlyAdminFeeUnit}
                                                    isOptionEqualToValue={(option, value) =>
                                                        option.value === value.value
                                                    }
                                                    onChange={onMonthlyAdminFeeUnitChange}
                                                    sx={{ width: 300, px: 0 }}
                                                    renderInput={(params) => <TextField {...params} label="Satuan Biaya Admin" />}
                                                />
                                            </Grid>

                                            <Grid item mb={2}
                                            >
                                                <MDBox borderRadius="xl" sx={{ width: "100%", px: 1, pl: 2, border: "1px solid #e1e1e1" }}>

                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={<Checkbox
                                                                checked={monthlyInstallment}
                                                                onChange={onMonthlyInstallmentChange}
                                                            />}
                                                            label={<MDTypography color="dark" fontSize="12px" pb={0}>
                                                                cicil bunga per bulan
                                                            </MDTypography>}
                                                        />
                                                    </FormGroup>
                                                </MDBox>
                                            </Grid>
                                        </Grid>
                                    </MDBox>
                                </Grid>

                                <Grid item>
                                    <MDTypography color="grey" fontSize="12px" fontWeight="medium" pb={2}>
                                        Biaya Penutup
                                    </MDTypography>
                                    <MDBox sx={{ width: "100%", mt: 0 }}>
                                        <Grid
                                            container
                                            // direction="column"
                                            // alignItems="center"
                                            // justifyContent="space-around"
                                            spacing={2}
                                        >
                                            <Grid item mb={2} xs={12} md={6}>
                                                <MDInput
                                                    id="closing_fee"
                                                    name="closing_fee"
                                                    label="Biaya Admin"
                                                    value={closingFee}
                                                    onChange={(e) => setClosingFee(e.target.value)}
                                                    onBlur={onClosingFeeBlur}
                                                    onFocus={onClosingFeeFocus}
                                                    placeholder="Biaya Admin"
                                                    sx={{ width: 300 }}
                                                />
                                            </Grid>
                                            <Grid item mb={2}>
                                                <Autocomplete
                                                    disablePortal
                                                    id="closing_fee_unit"
                                                    options={unit}
                                                    value={closingFeeUnit}
                                                    isOptionEqualToValue={(option, value) =>
                                                        option.value === value.value
                                                    }
                                                    onChange={onClosingFeeUnitChange}
                                                    sx={{ width: 300, px: 0 }}
                                                    renderInput={(params) => <TextField {...params} label="Satuan Biaya Admin" />}
                                                />
                                            </Grid>

                                        </Grid>
                                    </MDBox>
                                </Grid>

                            </Grid>
                        </MDBox>
                    </Grid>
                </Grid >
                <Grid container direction="column" justifyContent="space-around" spacing={3}>
                    <Grid item>
                        <MDBox alignItems="left" px={0}>
                            <MDBox mt={1}>
                                <MDButton fullWidth variant="contained" color="info" size="small" onClick={onAgree}>
                                    Simpan
                                </MDButton>
                            </MDBox>
                            <MDBox mt={1}>
                                <MDButton fullWidth variant="outlined" color="info" size="small" onClick={props.onClose}>
                                    Batal
                                </MDButton>
                            </MDBox>
                            <MDTypography color="error" my={1} px={1} sx={{ fontSize: 10 }}>{showError.all ? helperMsg.all : ''} </MDTypography>
                            <MDTypography color="error" my={1} px={1} sx={{ fontSize: 10 }}>{showError.fail ? helperMsg.fail : ''} </MDTypography>
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox >
        </div >
    );
};

export default Persetujuan;