/* eslint-disable no-nested-ternary */

import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

import SimpleDashboardNavbar from "examples/Navbars/SimpleDashboardNavbar";

import { useEffect } from "react";
import ReactGA from "react-ga4";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getCompanyKYCStatus } from "actions/companyAction";
import { useNavigate } from "react-router-dom";
import { getInvoiceStat } from "actions/statisticAction";
import { getFinancingStat } from "actions/statisticAction";
import { getSalesStat } from "actions/statisticAction";
import { useState } from "react";
import moment from "moment";
import { getInvoiceValue } from "actions/statisticAction";
import LVInvoiceDueSummary from "components/lv-invoice-due-summary";
import { getSaleInvoiceDueSummary } from "actions/statisticAction";
import { getPurchaseInvoiceDueSummary } from "actions/statisticAction";
import LVInvoiceDueBreakdownSummary from "components/lv-invoice-due-breakdown-summary";
import { getSaleInvoiceDuePerCustomerSummary } from "actions/statisticAction";
import { getPurchaseInvoiceDuePerVendorSummary } from "actions/statisticAction";
import LVInvoiceDueSummaryCompound from "components/lv-invoice-due-summary-compound";

function Dashboard() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/dashboard", title: "Dashboard" });
  }, []);

  const enableInvoiceAgeDashboard = process.env.REACT_APP_ENABLE_INVOICE_AGE_DASHBOARD;

  const [salesStat, setSalesStat] = useState({});
  const [invoiceStat, setInvoiceStat] = useState({});
  const [invoiceValue, setInvoiceValue] = useState(null);
  const [financeStat, setFinanceStat] = useState({});

  const [invoiceSaleDueSummaryFlag, setInvoiceSaleDueSummaryFlag] = useState(false);
  const [invoicePurchaseDueSummaryFlag, setInvoicePurchaseDueSummaryFlag] = useState(false);

  const [invoiceSaleDuePerCustomerSummaryTransformed,
    setInvoiceSaleDuePerCustomerSummaryTransformed] = useState([]);
  const [invoicePurchaseDuePerVendorSummaryTransformed,
    setInvoicePurchaseDuePerVendorSummaryTransformed] = useState([]);


  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCompanyKYCStatus());
    dispatch(getSaleInvoiceDuePerCustomerSummary());
    dispatch(getPurchaseInvoiceDuePerVendorSummary());
  }, []);

  useEffect(() => {
    if (!salesStat) {
      dispatch(getSalesStat());
    }
    if (!invoiceStat) {
      dispatch(getInvoiceStat());
    }
    if (!invoiceValue) {
      dispatch(getInvoiceValue());
    }
    if (!financeStat) {
      dispatch(getFinancingStat());
    }

    if (enableInvoiceAgeDashboard) {
      if (!invoiceSaleDueSummaryFlag) {
        dispatch(getSaleInvoiceDueSummary());
      }

      if (!invoicePurchaseDueSummaryFlag) {
        dispatch(getPurchaseInvoiceDueSummary());
      }
    }
  }, [salesStat, invoiceStat, financeStat, invoiceValue]);


  const invoiceSaleDueSummary = useSelector((state) => state.invoiceSaleDueSummary, shallowEqual);
  const invoicePurchaseDueSummary = useSelector((state) => state.invoicePurchaseDueSummary, shallowEqual);
  const invoiceSaleDuePerCustomerSummary = useSelector((state) => state.invoiceSaleDuePerCustomerSummary, shallowEqual);
  const invoicePurchaseDuePerVendorSummary = useSelector((state) => state.invoicePurchaseDuePerVendorSummary, shallowEqual);

  const anInvoiceValue = useSelector((state) => state.anInvoiceValue, shallowEqual);
  const anInvoiceStat = useSelector((state) => state.anInvoiceStat, shallowEqual);
  const anFinanceStat = useSelector((state) => state.anFinanceStat, shallowEqual);
  const anSalesStat = useSelector((state) => state.anSalesStat, shallowEqual);
  const anCompanyKycStatus = useSelector((state) => state.anCompanyKycStatus, shallowEqual);
  const user = JSON.parse(localStorage.getItem("user"));
  const role = user?.role;

  const convertInvoiceDueBreakdownData = (data) => {
    if (!data || data.length <= 0) { return; }

    let newData = [];
    let counter = 0;

    Object.entries(data).map((value, _) => {
      newData[counter] = {};
      newData[counter]['id'] = counter + 1;
      newData[counter]['name'] = value[0];

      value[1].map((value) => {
        if (value.criteria == "overdue_7+") {
          newData[counter]['criteria_1'] = parseFloat(value.value);
        } else if (value.criteria == "overdue_0_7") {
          newData[counter]['criteria_2'] = parseFloat(value.value);
        } else if (value.criteria == "due_in_0_7") {
          newData[counter]['criteria_3'] = parseFloat(value.value);
        }
      })
      counter += 1;
    });

    const sortedData = [...newData].sort((a, b) => b.criteria_1 - a.criteria_1);
    counter = 0;
    sortedData.map((_, index) => {
      sortedData[index]["id"] = counter + 1;
      counter += 1;
    })

    return sortedData;
  }

  useEffect(() => {
    setInvoiceSaleDuePerCustomerSummaryTransformed(
      convertInvoiceDueBreakdownData(invoiceSaleDuePerCustomerSummary)
    );
  }, [invoiceSaleDuePerCustomerSummary]);

  useEffect(() => {
    setInvoicePurchaseDuePerVendorSummaryTransformed(
      convertInvoiceDueBreakdownData(invoicePurchaseDuePerVendorSummary)
    );
  }, [invoicePurchaseDuePerVendorSummary]);

  useEffect(() => {
    setInvoiceStat(anInvoiceStat?.data);
  }, [anInvoiceStat]);

  useEffect(() => {
    setFinanceStat(anFinanceStat?.data);
  }, [anFinanceStat]);

  useEffect(() => {
    setInvoiceValue(anInvoiceValue?.data);
  }, [anInvoiceValue]);

  useEffect(() => {
    if (anSalesStat !== undefined) {
      setSalesStat(anSalesStat.data);
    } else {
      setSalesStat({});
    }
  }, [anSalesStat]);

  useEffect(() => {
    if (invoiceSaleDueSummary !== undefined) {
      setInvoiceSaleDueSummaryFlag(true);
    } else {
      setInvoiceSaleDueSummaryFlag(false);
    }
  }, [invoiceSaleDueSummary]);

  useEffect(() => {
    if (invoicePurchaseDueSummary !== undefined) {
      setInvoicePurchaseDueSummaryFlag(true);
    } else {
      setInvoicePurchaseDueSummaryFlag(false);
    }
  }, [invoicePurchaseDueSummary]);

  return (
    <DashboardLayout>
      <SimpleDashboardNavbar />
      {anCompanyKycStatus.status !== "approved" && role?.includes("broker") ? (
        <MDBox
          display="flex"
          justifyContent="space-between"
          sx={{
            borderTop:
              anCompanyKycStatus.status === "rejected" ? "4px solid #F63939" : "4px solid #49a3f1",
          }}
          bgColor="white"
          mt={3}
          px={4}
          py={2}
          shadow="md"
          borderRadius="xl"
        >
          <MDTypography sx={{ fontSize: "12px" }} my="auto">
            {anCompanyKycStatus.status === "created"
              ? "Informasi perusahaan Anda belum terverifikasi. Silahkan lengkapi informasi perusahaan terlebih dahulu."
              : anCompanyKycStatus.status === "rejected"
                ? "Mohon maaf, informasi perusahaan Anda perlu diperbaiki sebelum dapat mengajukan pendanaan."
                : ""}
          </MDTypography>
          <MDButton
            variant="outlined"
            size="small"
            color="info"
            onClick={() => navigate("/registerborrow")}
          >
            {anCompanyKycStatus.status === "rejected"
              ? "Perbaiki Informasi Perusahaan"
              : "Lengkapi Informasi Perusahaan"}
          </MDButton>
        </MDBox>
      ) : null}
      <MDBox py={3}>
        <MDBox bgColor="white" p={0.5} shadow="md" borderRadius="xl">
          <Grid container spacing={0}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox>
                <ComplexStatisticsCard
                  color="dark"
                  icon="weekend"
                  title="Order Penjualan"
                  count={salesStat ? `${salesStat?.[0]?.Data}` : "-"}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Baru saja di-update",
                  }}
                  first
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox>
                <ComplexStatisticsCard
                  icon="leaderboard"
                  title="Jumlah Invoice"
                  count={invoiceStat ? `${invoiceStat?.[0]?.Data}` : "-"}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Baru saja di-update",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox>
                <ComplexStatisticsCard
                  color="success"
                  icon="store"
                  title="Total Besaran Invoice"
                  count={
                    invoiceValue ? `Rp ${invoiceValue?.[0]?.Data.toLocaleString("id-ID")}` : "Rp -"
                  }
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Baru saja di-update",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox>
                <ComplexStatisticsCard
                  color="primary"
                  icon="person_add"
                  title="Pembayaran Cepat"
                  count={financeStat ? `Rp ${financeStat?.[0]?.Data.toLocaleString("id-ID")}` : "Rp -"}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Baru saja di-update",
                  }}
                  last
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        {enableInvoiceAgeDashboard ? (
          <Grid container mt={2} mb={1} spacing={2}>
            <Grid item xs={6}>
              <LVInvoiceDueSummaryCompound
                title={"Pembayaran Dari Pelanggan"}
                data={invoiceSaleDueSummary.data}
                rowData={invoiceSaleDuePerCustomerSummaryTransformed}
              ></LVInvoiceDueSummaryCompound>
            </Grid>
            <Grid item xs={6}>
              <LVInvoiceDueSummaryCompound
                title={"Pembayaran Ke Vendor"}
                data={invoicePurchaseDueSummary.data}
                rowData={invoicePurchaseDuePerVendorSummaryTransformed}
              ></LVInvoiceDueSummaryCompound>
            </Grid>
          </Grid>) : <></>}

        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="Jumlah Order Penjualan Harian"
                  // description="Setelah Memperkenalkan Produk Baru"
                  date="baru update"
                  chart={salesStat}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="Besaran Invoice Bulanan"
                  description={
                    <>
                      {/* (<strong>+15%</strong>) pendapatan meningkat */}
                    </>
                  }
                  date="baru update"
                  chart={invoiceStat}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="Besaran Pembayaran Cepat Bulanan"
                  description={
                    <>
                      {/* (<strong>+15%</strong>) pendapatan meningkat */}
                    </>
                  }
                  date="baru update"
                  chart={financeStat}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        { }
      </MDBox>
      { }
    </DashboardLayout>
  );
}

export default Dashboard;
