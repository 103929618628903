export default (state=[],action) => {
         switch (action.type) {
            case 'FETCH_SEARCH_VENDOR':
              return action.payload;
            case 'INIT_SEARCH_VENDOR':
              return state=[];
            default:
              return state;
          }
    
    };