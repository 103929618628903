import React, { Component, useState, useEffect, useRef  } from 'react';
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import { checkToken } from '../../actions/authAction';

const LVAuthentication = ({children}) => {
  const authToken = useSelector(
    state => {
      return state.authToken;
    },
    shallowEqual
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!authToken.isLoggedIn) {
      navigate('/signin');
    }       
  },[]); 

  useEffect(() => {

    if (!authToken.isLoggedIn) {

      navigate('/signin');
    }else{
      navigate('/invoice');
    }       
  },[authToken]); 

  return (
    <div>{children}</div>
  );

};

export default LVAuthentication;
