export default 
(
    state={
        number:'',
        term_of_payment:'',
        invoicing_company_id: '',
        status:'',
        invoice_id:[],
        fintech_company_id:[],
      } 
      ,action

) => {
         switch (action.type) {
            case 'GET_FINANCING':
              return action.payload;
            case 'INIT_AN_FINANCING':
              return {
                number:'',
                term_of_payment:'',
                invoicing_company_id: '',
                status:'',
                invoice_id:[],
                fintech_company_id:[],
            };
            default:
              return state;
          }
    
    };