import serverList from "../apis/serverList";
import axios from "axios";
import authHeader from "../apis/authHeader";

export const fetchAssets = () => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI.get("/api/v1/company/assets").catch((e) => { });
  if (response !== undefined) {
    if (response.data.status === "OK") {
      var listAsset = {};
      response.data.data.map((item) => {
        listAsset[item.type] = { url: item.url, id: item.id };
      });
      dispatch({ type: "FETCH_ASSETS", payload: listAsset });
    }
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
  }
};


export const fetchVendorAssets = (vendor_id) => async (dispatch) => {

  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI.get(`/api/v1/company/vendor/assets`, {
    params: {
      vendor_id,
    }
  }).catch((e) => { });
  if (response !== undefined) {
    if (response.data.status === "OK") {
      var listAsset = {};
      response.data.data.map((item) => {
        listAsset[item.type] = { url: item.url, id: item.id };
      });
      dispatch({ type: "FETCH_ASSETS", payload: listAsset });
    }
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
  }
};

export const fetchKYCDocs = () => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI.get("/api/v1/company/kyc/document").catch((e) => { });
  if (response !== undefined) {
    if (response.data.status === "OK") {
      var listKYC = {};
      response.data.data.map((item) => {
        listKYC[item.type] = {
          url: item.url,
          id: item.id,
          status: item.status,
          data: item.data,
          reason: item.reason,
        };
      });
      dispatch({ type: "FETCH_KYC_DOCS", payload: listKYC });
    }
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
  }
};

export const saveAssets =
  ({ assetName, assetFile }) =>
    async (dispatch) => {
      var formData = new FormData();
      formData.append("type", assetName);
      formData.append("file", assetFile);

      const companyAPI = axios.create({
        baseURL: serverList.company,
        headers: authHeader(),
      });
      const message = await companyAPI
        .post("/api/v1/company/asset", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch((e) => { });
      if (message !== undefined) {
        if (message.data.status === "OK") {
          var listAsset = {};
          listAsset[assetName] = { id: message.data.data.id, url: message.data.data.url };
          dispatch({ type: "FETCH_ASSETS_ADD_NEW", payload: listAsset });
        }
        if (message.status === 401) {
          dispatch({
            type: "ERROR",
            payload: { status: "TOKEN_EXPIRE", message: message.data.message },
          });
        }
      }
    };

export const saveKYCDocument =
  ({ assetName, assetFile }) =>
    async (dispatch) => {
      var formData = new FormData();
      formData.append("type", assetName);
      formData.append("file", assetFile);

      const companyAPI = axios.create({
        baseURL: serverList.company,
        headers: authHeader(),
      });
      const message = await companyAPI
        .post("/api/v1/company/kyc/document", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch((e) => { });
      if (message !== undefined) {
        if (message.data.status === "OK") {
          dispatch({
            type: "SUCCESS_SAVE_KYC_DOCUMENT",
            payload: { status: "SUCCESS_SAVE_KYC_DOCUMENT", message: assetName },
          });
        }
        if (message.status === 401) {
          dispatch({
            type: "ERROR",
            payload: { status: "TOKEN_EXPIRE", message: message.data.message },
          });
        }
      }
    };

export const fetchAdminRelationDocs = (id, relationId) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI
    .get(`/ api / v1 / admin / relation / history / search ? company_id = ${id} & relation_id=${relationId}`)
    .catch((e) => { });
  if (response !== undefined) {
    if (response.data.status === "OK") {
      var listFile = {};
      response.data.data.result.map((item) => {
        listFile[item.type] = {
          id: item.id,
          relationId: item.relation_id,
          assetId: item.assetId,
          status: item.status,
          url: item.asset_url,
        };
      });
      dispatch({ type: "FETCH_RELATION_DOCS", payload: listFile });
    }
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
  }
};

export const fetchRelationDocs = () => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI.get("/api/v1/company/relation/history/search").catch((e) => { });
  if (response !== undefined) {
    if (response.data.status === "OK") {
      var listFile = {};
      response.data.data.result.map((item) => {
        listFile[item.type] = {
          id: item.id,
          relationId: item.relation_id,
          assetId: item.assetId,
          status: item.status,
          url: item.asset_url,
        };
      });
      dispatch({ type: "FETCH_RELATION_DOCS", payload: listFile });
    }
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
  }
};

export const saveRelationDocument =
  ({ type, file, id }) =>
    async (dispatch) => {
      var formData = new FormData();
      formData.append("type", type);
      formData.append("file", file);
      formData.append("customer_company_id", id);

      const companyAPI = axios.create({
        baseURL: serverList.company,
        headers: authHeader(),
      });
      const message = await companyAPI
        .post("/api/v1/company/relation/history", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch((e) => { });
      if (message !== undefined) {
        if (message.data.status === "OK") {
          dispatch({
            type: "SUCCESS_SAVE_RELATION_DOCUMENT",
            payload: { status: "SUCCESS_SAVE_RELATION_DOCUMENT", message: type },
          });
        }
        if (message.status === 401) {
          dispatch({
            type: "ERROR",
            payload: { status: "TOKEN_EXPIRE", message: message.data.message },
          });
        }
      }
    };

export const deleteRelationDocument = (id) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const message = await companyAPI
    .delete("/api/v1/company/relation/history", {
      data: {
        id,
      },
    })
    .catch((e) => { });
  if (message !== undefined) {
    if (message.data.status === "OK") {
      dispatch({
        type: "DELETE_RELATION_DOCUMENT",
        payload: { status: "DELETE_RELATION_DOCUMENT", message: id },
      });
    }
    if (message.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: message.data.message },
      });
    }
  }
};

export const saveKYCPhone = (phone) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const message = await companyAPI
    .post("/api/v1/company/kyc/phone", {
      phone,
    })
    .catch((e) => { });
  if (message !== undefined) {
    if (message.data.status === "OK") {
      dispatch({
        type: "SUCCESS_SAVE_KYC_DOCUMENT",
        payload: { status: "SUCCESS_SAVE_KYC_DOCUMENT", message: "phone" },
      });
    }
    if (message.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: message.data.message },
      });
    }
  }
};

export const deleteKYCDocument = (id) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const message = await companyAPI
    .delete("/api/v1/company/kyc/document", {
      data: {
        id,
      },
    })
    .catch((e) => { });
  if (message !== undefined) {
    if (message.data.status === "OK") {
      dispatch({
        type: "DELETE_KYC_DOCUMENT",
        payload: { status: "DELETE_KYC_DOCUMENT", message: id },
      });
    }
    if (message.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: message.data.message },
      });
    }
  }
};

export const replaceAssets =
  ({ id, assetName, assetFile }) =>
    async (dispatch) => {
      var formData = new FormData();
      formData.append("type", assetName);
      formData.append("file", assetFile);
      /* 
      var formDataDelete = new FormData();
      formDataDelete.append('id', id); */
      const companyAPI = axios.create({
        baseURL: serverList.company,
        headers: authHeader(),
      });
      const responseDelete = await companyAPI
        .delete("/api/v1/company/asset", {
          data: {
            id,
          },
        })
        .catch((e) => { });

      if (responseDelete !== undefined) {
        if (responseDelete.data.status === "OK") {
          const message = await companyAPI
            .post("/api/v1/company/asset", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .catch((e) => { });

          if (message !== undefined) {
            if (message.data.status === "OK") {
              var listAsset = {};
              listAsset[assetName] = { id: message.data.data.id, url: message.data.data.url };
              dispatch({ type: "FETCH_ASSETS_ADD_NEW", payload: listAsset });
            }
            if (message.status === 401) {
              dispatch({
                type: "ERROR",
                payload: { status: "TOKEN_EXPIRE", message: message.data.message },
              });
            }
          }
        }
      }
    };

export const createNewCustomer = (company) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI.post("/api/v1/company/customer", company).catch((e) => {
    dispatch({
      type: "ERROR",
      payload: { status: "ERROR", message: e.response.data.message },
    });
  });
  if (response !== undefined) {
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
    if (response.data.status === "OK") {
      dispatch({
        type: "SUCCESS_NEW_CUSTOMER",
        payload: { status: "SUCCESS_NEW_CUSTOMER", message: response.data.data },
      });
      const { data } = await companyAPI.get("/api/v1/companies/customer/search");
      const tempData = [];
      data.data.map((isi) => {
        tempData.push({
          id: isi.id,
          value: isi.id,
          label: isi.name,
          npwp: isi.tin,
          pic_name: isi.pic_name,
          type: isi.type,
        });
      });

      dispatch({
        type: "FETCH_PELANGGAN",
        payload: tempData,
      });
    } else {
      dispatch({
        type: "ERROR",
        payload: { status: "ERROR", message: response.data.message },
      });
    }
  }
};

export const registerCompany = (company) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI.post("/api/v1/user/company", company).catch((e) => {
    dispatch({
      type: "REGISTER_COMPANY_FAIL",
      payload: { status: "REGISTER_COMPANY_FAIL", message: e.response.data.message },
    });
  });
  if (response !== undefined) {
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
    if (response.data.status === "OK") {
      dispatch({
        type: "REGISTER_COMPANY_SUCCESS",
        payload: { status: "REGISTER_COMPANY_SUCCESS", message: response.data.data },
      });
    } else {
      dispatch({
        type: "REGISTER_COMPANY_FAIL",
        payload: { status: "REGISTER_COMPANY_FAIL", message: response.data.message },
      });
    }
  } else {
    dispatch({
      type: "REGISTER_COMPANY_FAIL",
      payload: { status: "REGISTER_COMPANY_FAIL", message: "Server not responding." },
    });
  }
};

export const getCompanyDetail = () => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI.get("/api/v1/company");
  if (response) {
    if (response.data.status === "OK") {
      dispatch({
        type: "GET_COMPANY_INFO",
        payload: response.data.data,
      });
    }
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
  }
};

export const getCompany = () => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI.get("/api/v1/company");
  if (response) {
    if (response.data.status === "OK") {
      dispatch({
        type: "GET_COMPANY_INFO",
        payload: response.data.data,
      });
    }
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
  }
};

export const getCustomerCompany = (company_id) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI.get("/api/v1/company/customer", {
    params: {
      company_id,
    },
  });
  if (response) {
    if (response.data.status === "OK") {
      dispatch({
        type: "GET_CUSTOMER_COMPANY_INFO",
        payload: response.data.data,
      });
    }
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
  }
};

export const initCustomerCompany = () => {
  return {
    type: "INIT_CUSTOMER_COMPANY_INFO",
  };
};

export const getCompanyKYCStatus = () => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI.get("/api/v1/company/kyc/status");
  if (response) {
    if (response.data.status === "OK") {
      dispatch({
        type: "GET_COMPANY_KYC_STATUS",
        payload: response.data.data,
      });
    }
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
  }
};

export const fetchBrokerAccount =
  ({ company_id }) =>
    async (dispatch) => {
      const companyAPI = axios.create({
        baseURL: serverList.company,
        headers: authHeader(),
      });
      const response = await companyAPI
        .get("/api/v1/admin/company/account", {
          params: {
            company_id,
          },
        })
        .catch((e) => { });
      if (response.status === 200) {
        if (response.data.status === "OK") {
          dispatch({
            type: "FETCH_BROKER_ACCOUNT",
            payload: response.data.data,
          });
        } else {
          dispatch({
            type: "ERROR",
            payload: {
              status: "FAIL_FETCH_COMPANY_ACCOUNT",
              message: "Fail to get company account",
            },
          });
        }
      }
      if (response.status === 401) {
        dispatch({
          type: "ERROR",
          payload: { status: "TOKEN_EXPIRE", message: response.data.message },
        });
      }
    };

export const fetchFintechAccount =
  ({ company_id }) =>
    async (dispatch) => {
      const companyAPI = axios.create({
        baseURL: serverList.company,
        headers: authHeader(),
      });
      const response = await companyAPI
        .get("/api/v1/admin/company/account", {
          params: {
            company_id,
          },
        })
        .catch((e) => { });
      if (response.status === 200) {
        if (response.data.status === "OK") {
          dispatch({
            type: "FETCH_FINTECH_ACCOUNT",
            payload: response.data.data,
          });
        } else {
          dispatch({
            type: "ERROR",
            payload: {
              status: "FAIL_FETCH_COMPANY_ACCOUNT",
              message: "Fail to get company account",
            },
          });
        }
      }
      if (response.status === 401) {
        dispatch({
          type: "ERROR",
          payload: { status: "TOKEN_EXPIRE", message: response.data.message },
        });
      }
    };



export const fetchCompanyAccount =
  () =>
    async (dispatch) => {
      const companyAPI = axios.create({
        baseURL: serverList.company,
        headers: authHeader(),
      });
      const response = await companyAPI
        .get("/api/v1/company/account", {
        })
        .catch((e) => { });
      if (response.status === 200) {
        if (response.data.status === "OK") {
          dispatch({
            type: "FETCH_COMPANY_ACCOUNT",
            payload: response.data.data,
          });
        } else {
          dispatch({
            type: "ERROR",
            payload: {
              status: "FAIL_FETCH_COMPANY_ACCOUNT",
              message: "Fail to get company account",
            },
          });
        }
      }
      if (response.status === 401) {
        dispatch({
          type: "ERROR",
          payload: { status: "TOKEN_EXPIRE", message: response.data.message },
        });
      }
    };

