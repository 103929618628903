/* eslint-disable arrow-body-style */
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";

import MDTypography from "components/MDTypography";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import CustomFooter from "components/LVCustomFooter";

function LVPenggunaDataGrid(props) {
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

  const columns = [
    {
      field: "id",
    },
    {
      field: "no",
      sortable: false,
      headerName: "No.",
      headerAlign: "center",
      align: "center",
      width: 50,
    },
    {
      field: "name",
      headerName: "Nama Pengguna",
      headerAlign: "center",
      align: "left",
      renderCell: (params) => <span>{params.value}</span>,
      width: 250,
    },
    {
      field: "action",
      headerName: "AKSI",
      headerAlign: "center",
      align: "left",
      sortable: false,
      renderCell: (params) => (
        <Link to={`/user/${encodeURIComponent(params.row.name)}/broker/${params.row.id}`}>
          <MDTypography
            variant="body3"
            align="center"
            sx={{ color: "#0D6ECD", fontWeight: "bold" }}
          >
            Lihat Detail
          </MDTypography>
        </Link>
      ),
      width: 150,
    },
  ];

  const columnsFintech = [
    {
      field: "id",
    },
    {
      field: "no",
      sortable: false,
      headerName: "No.",
      headerAlign: "center",
      align: "center",
      width: 50,
    },
    {
      field: "name",
      headerName: "Nama Pengguna",
      headerAlign: "center",
      align: "left",
      renderCell: (params) => <span>{params.value}</span>,
      width: 250,
    },
    {
      field: "action",
      headerName: "AKSI",
      headerAlign: "center",
      align: "left",
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Link to={`/user/${encodeURIComponent(params.row.name)}/user/${params.row.id}`}>
          <MDTypography
            variant="body3"
            align="center"
            sx={{ color: "#0D6ECD", fontWeight: "bold" }}
          >
            Lihat Detail
          </MDTypography>
        </Link>
      ),
      width: 150,
    },
  ];

  const [filterTimeout, setFiltertimeout] = useState();

  const filterHandlerDebouncing = (params) => {
    clearTimeout(filterTimeout);
    setFiltertimeout(setTimeout(() => filterHandler(params), 500));
  }

  const resetFilter = (params) => {
    props.resetFilter(params);
  }

  const filterHandler = (params) => {
    if (params.items[0] === undefined) {
      resetFilter();
      return;
    }
    let condition = params.items[0];

    if (condition.value === undefined || condition.value === "") {
      return;
    }

    if (condition.columnField === "name") {
      props.name(condition.value);
    }
  }



  const mapSortingColumn = (column) => {
    let map = {
      "created_date": "created_at",
    }

    if (map.hasOwnProperty(column)) {
      return map[column];
    }
    else {
      return column
    }
  }

  const sortingHandler = (params) => {
    if (params.length === 0) {
      return;
    }

    props.order(mapSortingColumn(params[0].field), params[0].sort);
  }

  function CustomFooterProps() {
    return (
      <CustomFooter
        page={props.totalPage}
        selectPage={props.selectPage}
        nextPage={props.nextPage}
        prevPage={props.prevPage}
        currentPage={props.currentPage}
        firstPage={props.firstPage}
        lastPage={props.lastPage}
      />
    );
  }

  return (
    <DataGrid
      columns={props.type === "broker" ? columns : columnsFintech}
      rows={props.rows}
      pageSize={10}
      autoHeight
      rowsPerPageOptions={[10]}
      disableSelectionOnClick
      localeText={{ noRowsLabel: "Belum ada pengguna." }}
      sx={{
        bgcolor: "rgba(255, 255, 255, 1)",
        borderRadius: "16px",
        fontSize: "small",
        boxShadow: "10px 15px 27px -28px #1A73E8",
        '& .MuiDataGrid-cell': {
          padding: '8px 16px',
        }
      }}

      sortingMode="server"
      onSortModelChange={sortingHandler}

      filterMode="server"
      onFilterModelChange={filterHandlerDebouncing}

      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={(newModel) => {
        newModel.id = false;
        setColumnVisibilityModel(newModel);
      }}
      experimentalFeatures={{ newEditingApi: true }}
      components={{ Footer: CustomFooterProps }}
    />
  );
}

export default LVPenggunaDataGrid;
