import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import Grid from "@mui/material/Grid";

import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { rejectKYCDocsAdmin, rejectKYCPhoneAdmin } from '../../actions/kycPeminjamAction';
import { fintechRejectFintechKycDoc } from "actions/borrowerFintechAction";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    height: 300,
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    overflow: "auto",
    p: 3,
};

const rejectKYCDocument = (props) => {

    const [reason, setReason] = useState("");
    return (
        <div>
            <MDBox sx={style}>
                <Grid container direction="column" justifyContent="space-around" spacing={3}>
                    <Grid item>
                        <MDTypography variant="title" fontWeight="medium">
                            Tolak Dokumen
                        </MDTypography>
                    </Grid>
                    <Grid item px={3}>
                        <MDInput
                            id="alasan"
                            name="alasan"
                            multiline
                            onChange={(e) => {
                                setReason(e.target.value);
                            }}
                            value={reason}
                            rows={5}
                            label="Alasan Penolakan"
                            placeholder="Foto tidak jelas/dokumen tidak sesuai"
                            sx={{ width: 300 }}
                        />
                    </Grid>
                    <Grid item>
                        <Grid container justifyContent="flex-end" alignItems="right" spacing={1} px={3} mt={1}>
                            <Grid item>
                                <MDButton variant="contained" color="error" mt={2} size="small" onClick={() => props.onReject(reason)}>
                                    Tolak
                                </MDButton>
                            </Grid>
                            <Grid item>
                                <MDButton variant="contained" color="info" mt={2} size="small" onClick={props.onClose}>
                                    Batal
                                </MDButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MDBox>
        </div>
    );

}

export default rejectKYCDocument;