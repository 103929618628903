/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation, useNavigate, Link } from "react-router-dom";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import LogoutIcon from "@mui/icons-material/Logout";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import NotificationItem from "examples/Items/NotificationItem";
import logo from "assets/images/Kentara_1.png";

// Custom styles for SimpleDashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/SimpleDashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import { Modal } from "@mui/material";
import MDButton from "components/MDButton";
import { parseJwt } from "../../../common/Util";

import { getUserDetail, signOut } from "../../../actions/authAction";
import { getCompany } from "../../../actions/companyAction";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};
function SimpleDashboardNavbar({ absolute, light, isMini, name }) {
  const [logoutModal, setLogoutModal] = useState(false);
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [userName, setUserName] = useState("");
  const [company, setCompany] = useState("");
  const route = useLocation().pathname.split("/").slice(1);

  const user = JSON.parse(localStorage.getItem("user"));

  const dispatchCompany = useDispatch();

  const anUser = useSelector((state) => state.anUser);

  useEffect(() => {
    if (user) {
      const decodedJwt = parseJwt(user.access_token);
      setUserName(decodedJwt.Username);
    }
    dispatchCompany(getUserDetail());
  }, []);

  useEffect(() => {
    if (anUser.name) {
      setCompany(anUser.name);
    }
  }, [anUser]);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const dispatchAction = useDispatch();
  const navigate = useNavigate();
  // Render the notifications menu
  const renderRegBorrow = () => {
    if (user?.access_list?.includes("register_borrow")) {
      return (
        <NotificationItem
          onClick={() => navigate("/registerborrow")}
          icon={<PriceCheckIcon color="#1A73E8" />}
          title="Informasi Perusahaan "
        />
      );
    }
    return <div />;

    /* if (user) {
      if (user.company) {
        if (user.company.business_type) {
          if (user.company.business_type === 'borrower' || user.company.business_type === 'broker') {
            return (
              <NotificationItem onClick={() => navigate("/registerborrow")} icon={<PriceCheckIcon />} title="Status Daftar Pengajuan Dana" />  
            );
          } else {
            return (
              <div></div>
            );
          }
        }
      }
      
    } else {
      return (
          <div></div>
        );
    } */
  };

  const logOut = () => {
    dispatchAction(signOut());
    navigate("/signin");
  };

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      {/* <MDTypography px={2} mt={1} fontWeight="medium" sx={{ fontSize: 14, textAlign: "center" }}>
        {company}
      </MDTypography>
      <MDTypography px={2} pb={1} sx={{ fontSize: 10, textAlign: "center" }}>
        {userName}
      </MDTypography> */}
      {renderRegBorrow()}
      <NotificationItem
        icon={<ManageAccountsIcon color="#1A73E8" />}
        title="Atur Profil"
        onClick={() => navigate("/edit-profile")}
      />
      <NotificationItem
        onClick={() => setLogoutModal(true)}
        icon={<LogoutIcon color="#1A73E8" />}
        title="Keluar"
      />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <MDBox ml={1.5} display="flex" justifyContent="space-between">
        <MDBox my="auto" display="flex">
          <span>
            <img src="/icon-page/dashboard.svg" alt="dashboard" />
          </span>
          <MDTypography fontWeight="medium" variant="h4" ml={1} textTransform="capitalize">
            {name ? name : "Dashboard"}
          </MDTypography>
        </MDBox>
        <Toolbar sx={(theme) => navbarContainer(theme)}>
          <MDBox
            color="inherit"
            mb={{ xs: 1, md: 0 }}
            sx={(theme) => navbarRow(theme, { isMini })}
          />
          {isMini ? null : (
            <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
              <MDBox color={light ? "white" : "inherit"}>
                {/*               <Link to="/invoice">
                  <IconButton sx={navbarIconButton} size="small" disableRipple>
                    <Icon sx={iconsStyle}>account_circle</Icon>
                  </IconButton>
                </Link> */}
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarMobileMenu}
                  onClick={handleMiniSidenav}
                >
                  <Icon sx={iconsStyle} fontSize="medium">
                    {miniSidenav ? "menu_open" : "menu"}
                  </Icon>
                </IconButton>
              </MDBox>
              <MDBox
                display="flex"
                justifyContent="space-between"
                bgColor="white"
                borderRadius="xl"
                px={3}
                py={1}
                sx={{
                  width: "300px",
                }}
              >
                <MDBox display="flex">
                  <img src="/profile.svg" alt="" />
                  <MDBox
                    my="auto"
                    width="auto"
                    ml={2}
                    mr={5}
                    fontSize="medium"
                    sx={{
                      width: "150px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {company}
                  </MDBox>
                </MDBox>
                <MDBox>
                  <IconButton
                    size="small"
                    disableRipple
                    color="inherit"
                    sx={navbarIconButton}
                    aria-controls="notification-menu"
                    aria-haspopup="true"
                    variant="contained"
                    onClick={handleOpenMenu}
                  >
                    <img src={openMenu === false ? "/CaretDown.svg" : "/CaretUp.svg"} alt="" />
                  </IconButton>
                </MDBox>
              </MDBox>
              {renderMenu()}
            </MDBox>
          )}
        </Toolbar>
        <Modal
          open={logoutModal}
          onClose={() => setLogoutModal(false)}
          // aria-labelledby="modal-modal-title"
          // aria-describedby="modal-modal-description"
        >
          <MDBox sx={style}>
            <MDBox display="flex" sx={{ width: "100%" }}>
              <MDTypography variant="h4" textAlign="center" sx={{ width: "100%" }}>
                Keluar
              </MDTypography>
            </MDBox>
            <MDBox display="flex" mt={2} sx={{ width: "100%" }}>
              <MDTypography textAlign="center" variant="body2" sx={{ width: "100%" }}>
                Yakin ingin keluar dari Kentara?
              </MDTypography>
            </MDBox>
            <MDBox mt={5}>
              <MDButton variant="contained" fullWidth color="info" onClick={logOut}>
                Keluar
              </MDButton>
            </MDBox>
            <MDBox mt={1}>
              <MDButton
                variant="outlined"
                mt={2}
                fullWidth
                color="info"
                onClick={() => setLogoutModal(false)}
              >
                Batal
              </MDButton>
            </MDBox>
          </MDBox>
        </Modal>
      </MDBox>
    </AppBar>
  );
}

// Setting default values for the props of SimpleDashboardNavbar
// SimpleDashboardNavbar.defaultProps = {
//   absolute: false,
//   light: false,
//   isMini: false,
// };

// Typechecking props for the SimpleDashboardNavbar
SimpleDashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default SimpleDashboardNavbar;
