import { useState, useEffect } from "react";
import { connect, useSelector, useDispatch, shallowEqual } from "react-redux";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SimpleDashboardNavbar from "examples/Navbars/SimpleDashboardNavbar";
import LVDetailPengguna from "components/LVDetailPengguna";
import { useParams } from "react-router-dom";
import MDBox from "components/MDBox";

const DetailPengguna = () => {
  const dispatch = useDispatch();
  const params = useParams();

  return (
    <DashboardLayout>
      <SimpleDashboardNavbar name="Detail Pengguna" />
      <LVDetailPengguna />
    </DashboardLayout>
  );
};

export default DetailPengguna;
