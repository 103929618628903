/* eslint-disable default-case */
import { valid } from "chroma-js";
import serverList from "../apis/serverList";

export const getTaxList = () => {
  return [
    { value: 1, label: "PPN 1% Exclusive" },
    { value: 2, label: "PPN 1% Inclusive" },
    { value: 3, label: "PPN 1.1% Exclusive" },
    { value: 4, label: "PPN 1.1% Inclusive" },
    { value: 5, label: "PPN 10% Exclusive" },
    { value: 6, label: "PPN 10% Inclusive" },
    { value: 7, label: "PPN 11% Exclusive" },
    { value: 8, label: "PPN 11% Inclusive" },
    { value: 9, label: "Tanpa PPN" },
  ];
}

export const convertTaxId = (tax) => {
  switch (tax) {
    case "PPN 1% Exclusive":
      return 1;
    case "PPN 1% Inclusive":
      return 2;
    case "PPN 1.1% Exclusive":
      return 3;
    case "PPN 1.1% Inclusive":
      return 4;
    case "PPN 10% Exclusive":
      return 5;
    case "PPN 10% Inclusive":
      return 6;
    case "PPN 11% Exclusive":
      return 7;
    case "PPN 11% Inclusive":
      return 8;
    case "Tanpa PPN":
      return 9;
    default:
      return 0;
  }
};

export const getTaxPphList = () => {
  return [
    { value: 1, label: "PPh 23 NPWP 2% Exclusive" },
    // { value: 2, label: "PPh 23 NPWP 2% Inclusive" },
    { value: 3, label: "PPh 23 Tanpa NPWP 4% Exclusive" },
    // { value: 4, label: "PPh 23 Tanpa NPWP 4% Inclusive" },
    { value: 5, label: "Tanpa PPh 23" },
  ]
}

export const convertTaxPphId = (tax) => {
  switch (tax) {
    case "PPh 23 NPWP 2% Exclusive":
      return 1;
    case "PPh 23 NPWP 2% Inclusive":
      return 2;
    case "PPh 23 Tanpa NPWP 4% Exclusive":
      return 3;
    case "PPh 23 Tanpa NPWP 4% Inclusive":
      return 4;
    case "Tanpa PPh 23":
      return 5;
    default:
      return 0;
  }
};

export const getTaxDetail = (taxID) => {
  let taxDetail = {
    1: { "value": 1, "type": "exclusive", },
    2: { "value": 1, "type": "inclusive", },
    3: { "value": 1.1, "type": "exclusive", },
    4: { "value": 1.1, "type": "inclusive", },
    5: { "value": 10, "type": "exclusive", },
    6: { "value": 10, "type": "inclusive", },
    7: { "value": 11, "type": "exclusive", },
    8: { "value": 11, "type": "inclusive", },
    9: { "value": 0, "type": "exclusive", },
  }
  if (taxDetail[taxID] !== null) {
    return taxDetail[taxID]
  } else {
    return { "value": 0, "type": "exclusive" }
  }
};

export const getTaxPphDetail = (taxID) => {
  let taxDetail = {
    1: { "value": 2, "type": "exclusive", },
    2: { "value": 2, "type": "inclusive", },
    3: { "value": 4, "type": "exclusive", },
    4: { "value": 4, "type": "inclusive", },
    5: { "value": 0, "type": "exclusive", },
  }

  if (taxDetail[taxID] !== null) {
    return taxDetail[taxID]
  } else {
    return { "value": 0, "type": "exclusive" }
  }
};

export const calculateTaxValue = (amount, taxID) => {
  let taxDetail = getTaxDetail(taxID);
  let taxAmount = 0;

  if (taxDetail["type"] === "exclusive") {
    taxAmount = amount * (taxDetail["value"] / 100);
    amount = amount;
  }
  else if (taxDetail["type"] === "inclusive") {
    amount = amount * 100 / (100 + taxDetail["value"]);
    taxAmount = amount * taxDetail["value"] / 100;
  }

  return [amount, taxAmount]
}

export const calculateTaxPphValue = (amount, taxPphID) => {
  let taxDetail = getTaxPphDetail(taxPphID);
  let taxAmount = 0;

  if (taxDetail["type"] === "exclusive") {
    taxAmount = amount * (taxDetail["value"] / 100);
    amount = amount;
  }
  else if (taxDetail["type"] === "inclusive") {
    amount = amount * 100 / (100 + taxDetail["value"]);
    taxAmount = amount * taxDetail["value"] / 100;
  }

  return [amount, taxAmount]
}



export const transformStatusDocument = (status) => {
  switch (status.toLowerCase()) {
    case "created":
      return "Belum Dikirim";
    case "delivered":
      return "Sudah Dikirim";
    case "approved":
      return "Disetujui Pelanggan";
    case "rejected":
      return "Ditolak Pelanggan";
    case "failed delivery":
      return "Pengiriman Gagal";
    default:
      return status;
  }
};

export const convertRequestType = (type) => {
  switch (type.toLowerCase()) {
    case "invoice":
      return "Invoice Financing";
    case "purchase":
      return "Order Financing";
    case "quickpay":
      return "PayLater";
    default:
      return type;
  }
};

export const transformStatusPengajuan = (item) => {
  var tmpstatus = "";

  if (item.status.toLowerCase() === "created") {
    tmpstatus = "Belum Mengajukan";
  } else if (item.status.toLowerCase() === "submitted") {
    if (item.type.toLowerCase() === "invoice") {
      tmpstatus = "Menunggu Pendana";
    } else if (item.type.toLowerCase() === "purchase") {
      tmpstatus = "Menunggu Pendana";
    } else if (item.type.toLowerCase() === "quickpay") {
      tmpstatus = "Menunggu Persetujuan";
    } else {
      tmpstatus = item.status;
    }
  } else if (item.status.toLowerCase() === "approved") {
    if (item.type.toLowerCase() === "invoice") {
      tmpstatus = "Menunggu Transfer";
    } else if (item.type.toLowerCase() === "purchase") {
      tmpstatus = "Aktif";
    } else if (item.type.toLowerCase() === "quickpay") {
      tmpstatus = "Disetujui";
    } else {
      tmpstatus = item.status;
    }
  } else if (item.status.toLowerCase() === "fund_transferred") {
    tmpstatus = "Dana Ditransfer";
  } else if (item.status.toLowerCase() === "fund_paid") {
    tmpstatus = "Lunas";
  } else if (item.status.toLowerCase() === "rejected") {
    tmpstatus = "Ditolak";
  } else {
    tmpstatus = item.status;
  }
  return tmpstatus;
};

export const transformPengajuan = (list, pagination) => {
  const showPengajuan = list.map((item, idx) => {
    var tmpstatus = transformStatusPengajuan(item);
    let baseCounter = (pagination.current_page - 1) * pagination.limit;
    if (item.due_date === "31 Desember 2100") {
      item.due_date = "N/A";
    }
    return { ...item, status_readable: tmpstatus, no: baseCounter + idx + 1 };
  });
  return showPengajuan;
};


export const transformUserList = (list, pagination) => {
  const showUserList = list.map((item, idx) => {
    let baseCounter = (pagination.current_page - 1) * pagination.limit;
    return { ...item, no: baseCounter + idx + 1 };
  });
  return showUserList;
};


export const transformStatusParent = (status) => {
  switch (status.toLowerCase()) {
    case "":
      return "Belum Diajukan";
    case "created":
      return "Belum Diajukan";
    case "submitted":
      return "Menunggu Pendana";
    case "approved":
      return "Aktif";
    case "rejected":
      return "Ditolak";
    default:
      return status;
  }
};

export const transformInvoices = (invoices) => {
  const showInvoices = invoices.map((isi, idx) => {
    var statusInvoice = "";
    var statusFinancing = "";
    var statusDocument = "";
    var statusDocumentReadable = "";
    var statusFinancingTemp = "";
    var statusParent = "";
    var statusParentReadable = "";
    var orderId = -1;
    if (isi.status) {
      isi.status.map((item) => {
        if (item.type.toLowerCase() === "invoice") {
          statusInvoice = item.status;
        } else if (item.type.toLowerCase() === "financing") {
          statusFinancing = item.status;
          statusFinancingTemp = transformStatusPengajuan({ status: item.status, type: "invoice" });
        } else if (item.type.toLowerCase() === "document") {
          statusDocument = item.status;
          statusDocumentReadable = transformStatusDocument(item.status);
        } else if (item.type.toLowerCase() === "parent_financing") {
          statusParent = item.status;
          statusParentReadable = transformStatusParent(item.status);
        }
      });
    }
    if (isi.sale_order_id === 0) {
      orderId = isi.purchase_order_id;
    } else {
      orderId = isi.sale_order_id;
    }
    const { status, ...tmpIsi } = isi;
    return {
      ...tmpIsi,
      no: idx + 1,
      id: isi.id,
      order_id: orderId,
      statusInvoice,
      statusFinancing,
      statusDocument,
      statusDocument_readable: statusDocumentReadable,
      statusFinancing_readable: statusFinancingTemp,
      statusParent,
      statusParent_readable: statusParentReadable,
    };
  });
  return showInvoices;
};

export const transformStatusOrderPenjualan = (isi) => {
  var statusTemp = isi.status;
  if (isi.status.toLowerCase() === "draft") {
    statusTemp = "Draft";
  } else if (isi.status.toLowerCase() === "sent") {
    statusTemp = "Penawaran Dikirim";
  } else if (isi.status.toLowerCase() === "sale" || isi.status.toLowerCase() === "purchase") {
    statusTemp = "Terbit";
  }
  return statusTemp;
};

export const getStatusOrderPenjualan = ({ statusObj, statusType }) => {
  var statusTmp = "";
  if (Array.isArray(statusObj)) {
    statusObj.map((item) => {
      if (item.type.toLowerCase() === statusType.toLowerCase()) {
        statusTmp = item.status;
      }
    });
  }
  return statusTmp;
};

export const getStatusOrderPembelian = ({ statusObj, statusType }) => {
  var statusTmp = "";
  if (Array.isArray(statusObj)) {
    statusObj.map((item) => {
      if (item.type.toLowerCase() === statusType.toLowerCase()) {
        statusTmp = item.status;
      }
    });
  }
  return statusTmp;
};

export const transformOrderPenjualan = ({
  orderPenjualan,
  businessType,
  status,
  arrId,
  statusFinancing,
}) => {
  var showOrderPenjualan = orderPenjualan.map((isi) => {
    var statusOP = "";
    var statusFinancing = "";
    if (Array.isArray(isi.status)) {
      isi.status.map((item) => {
        if (item.type.toLowerCase() === "sale" || item.type.toLowerCase() === "purchase") {
          statusOP = item.status;
        } else if (item.type.toLowerCase() === "financing") {
          statusFinancing = item.status;
        }
      });
    }
    const { status, ...tmpIsi } = isi;
    return { ...tmpIsi, status: statusOP, statusFinancing };
  });
  if (status) {
    showOrderPenjualan = showOrderPenjualan.filter((isi) => {
      return status.includes(isi.status);
    });
  }
  if (statusFinancing) {
    showOrderPenjualan = showOrderPenjualan.filter((isi) => {
      return statusFinancing.includes(isi.statusFinancing);
    });
  }
  if (arrId) {
    showOrderPenjualan = showOrderPenjualan.filter((isi) => {
      return arrId.includes(isi.id);
    });
  }
  const outputArray = showOrderPenjualan.map((isi, idx) => {
    var isi_pic = "";
    if (businessType === "broker") {
      isi_pic = isi.sales_person;
    } else if (businessType === "vendor") {
      isi_pic = isi.purchase_person;
    }
    var statusTemp = transformStatusOrderPenjualan(isi);
    var statusFinancingTemp = transformStatusPengajuan({
      status: isi.statusFinancing,
      type: "purchase",
    });
    //const {status, ...tmpIsi} = isi;
    return {
      ...isi,
      no: idx + 1,
      sale_order_id: isi.id,
      pic: isi.isi_pic,
      status_readable: statusTemp,
      statusFinancing_readable: statusFinancingTemp,
    };
  });
  return outputArray;
};

export const transformStatusOrderPembelian = (isi) => {
  var statusTemp = isi.status;
  if (isi.status.toLowerCase() === "draft") {
    statusTemp = "Draft";
  } else if (isi.status.toLowerCase() === "sent") {
    statusTemp = "Penawaran Dikirim";
  } else if (isi.status.toLowerCase() === "purchase") {
    statusTemp = "Terbit";
  }
  return statusTemp;
};

export const transformOrderPembelian = ({ orderPembelian }) => {
  var no = 0;
  var showOrderPembelian = orderPembelian.map((isi) => {
    var statusOPReadable = "";
    var statusOP = "";
    var statusFinancing = "";
    var statusFinancing_readable = "";
    no += 1;
    if (Array.isArray(isi.status)) {
      isi.status.map((item) => {
        if (item.type.toLowerCase() === "purchase") {
          statusOP = item.status;
          statusOPReadable = transformStatusOrderPembelian(item);
        } else if (item.type.toLowerCase() === "financing") {
          statusFinancing = item.status;
          statusFinancing_readable = transformStatusPengajuan(item);
        }
      });
    }
    const { status, ...tmpIsi } = isi;
    return {
      ...tmpIsi,
      no,
      status: statusOP,
      status_readable: statusOPReadable,
      statusFinancing,
      statusFinancing_readable,
    };
  });
  return showOrderPembelian;
};

export const transformProductPenjualan = (productPenjualan) => {
  const product = productPenjualan?.map((isi, idx) => {
    return {
      id: idx + 1,
      name: isi.product,
      price: isi.unit_price,
      price_total: isi.subtotal,
      quantity: isi.quantity,
      tax: isi.taxes,
      remark: isi.description,
      lead_time: isi.lead_time,
    };
  });
  return product;
};

export const transformProductPembelian = (productPembelian) => {
  const product = productPembelian?.map((isi, idx) => {
    return {
      id: idx + 1,
      name: isi.product,
      price: isi.unit_price,
      price_total: isi.subtotal,
      quantity: isi.quantity,
      tax: isi.taxes,
      remark: isi.description,
      lead_time: isi.lead_time,
    };
  });
  return product;
};

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const transformKycPeminjam = (rows, pagination) => {
  let baseCounter = (pagination.current_page - 1) * pagination.limit;
  var nomor = baseCounter;

  const tmpList = rows.map((isi) => {
    var completionStatus = "";
    var newStatus = "";
    nomor = nomor + 1;
    if (isi.status.toLowerCase() === "created") {
      if (Number(isi.document_count) === 11 && Number(isi.data_count) === 1) {
        completionStatus = "Komplet";
      } else {
        completionStatus = "Sebagian";
      }
      newStatus = "Belum Disetujui";
    } else {
      completionStatus = "Komplet";
      newStatus = "Sudah Disetujui";
    }
    const { status, ...tmpIsi } = isi;
    return {
      ...tmpIsi,
      id: isi.company_id,
      no: nomor,
      statusDokumen: completionStatus,
      status: newStatus,
    };
  });

  return tmpList;
};

export const transformStatusKyc = (status) => {
  var newStatus = "";
  if (status.toLowerCase() === "created") {
    newStatus = "Belum Disetujui";
  } else if (status.toLowerCase() === "approved") {
    newStatus = "Sudah Disetujui";
  } else if (status.toLowerCase() === "rejected") {
    newStatus = "Ditolak";
  } else {
    newStatus = status;
  }
  return newStatus;
};

export const addPaddingMonth = (month) => {
  return ('0' + (month + 1)).slice(-2);
}

export const getPreviousMonth = (month) => {
  const period = { months: -1 * month };
  const newDate = addPeriodToDate(new Date(), period)
  return {
    "month": addPaddingMonth(newDate.getMonth()),
    "monthName": getMonthName(newDate.getMonth()),
    "year": newDate.getFullYear()
  }
}

export const getPreviousYear = (year) => {
  const period = { years: -1 * year };
  const newDate = addPeriodToDate(new Date(), period)
  return {
    "month": addPaddingMonth(newDate.getMonth()),
    "monthName": getMonthName(newDate.getMonth()),
    "year": newDate.getFullYear()
  }
}

export const getCurrentMonth = () => {
  const newDate = new Date();
  return {
    "month": addPaddingMonth(newDate.getMonth()),
    "monthName": getMonthName(newDate.getMonth()),
    "year": newDate.getFullYear()
  }
}

export const getMonthName = (month) => {
  const months = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
  return months[month];
}

export const addPeriodToDate = (date, { years = 0, months = 0, days = 0, hours = 0, minutes = 0, seconds = 0 }) => {
  let new_date = new Date(date);

  new_date.setFullYear(new_date.getFullYear() + years);
  new_date.setMonth(new_date.getMonth() + months);
  new_date.setDate(new_date.getDate() + days);
  new_date.setHours(new_date.getHours() + hours);
  new_date.setMinutes(new_date.getMinutes() + minutes);
  new_date.setSeconds(new_date.getSeconds() + seconds);
  return new_date;
}


export const transformKycDocsArr = (rows) => {

  const tmpList = rows.map((isi) => {
    var namaDokumen = "";
    var newData = "";
    var newStatus = "";
    var nomor = 0;
    if (isi.status.toLowerCase() === "created") {
      newStatus = "Belum Disetujui";
    } else if (isi.status.toLowerCase() === "approved") {
      newStatus = "Sudah Disetujui";
    } else if (isi.status.toLowerCase() === "rejected") {
      newStatus = "Ditolak";
    } else {
      newStatus = isi.status;
    }

    let date = "";
    switch (isi.type.toLowerCase()) {
      case "phone":
        namaDokumen = "Nomor Telepon";
        newData = isi.data;
        nomor = 1;
        break;
      case "pic_ktp":
        namaDokumen = "KTP PIC";
        newData = serverList.company + isi.url;
        nomor = 2;
        break;
      case "pic_ktp_selfie":
        namaDokumen = "Selfie KTP PIC";
        newData = serverList.company + isi.url;
        nomor = 3;
        break;
      case "akta_pendirian":
        namaDokumen = "Akta Pendirian";
        newData = serverList.company + isi.url;
        nomor = 4;
        break;
      case "akta_pendirian_2":
        namaDokumen = "Akta Pendirian (2)";
        newData = serverList.company + isi.url;
        nomor = 5;
        break;
      case "akta_pendirian_3":
        namaDokumen = "Akta Pendirian (3)";
        newData = serverList.company + isi.url;
        nomor = 6;
        break;
      case "akta_pendirian_4":
        namaDokumen = "Akta Pendirian (4)";
        newData = serverList.company + isi.url;
        nomor = 7;
        break;
      case "akta_pendirian_5":
        namaDokumen = "Akta Pendirian (5)";
        newData = serverList.company + isi.url;
        nomor = 8;
        break;
      case "akta_pendirian_6":
        namaDokumen = "Akta Pendirian (6)";
        newData = serverList.company + isi.url;
        nomor = 9;
        break;
      case "akta_pendirian_sk":
        namaDokumen = "SK Kemenkumham Akta Pendirian";
        newData = serverList.company + isi.url;
        nomor = 10;
        break;
      case "akta_pendirian_sk_2":
        namaDokumen = "SK Kemenkumham Akta Pendirian (2)";
        newData = serverList.company + isi.url;
        nomor = 11;
        break;
      case "akta_pendirian_sk_3":
        namaDokumen = "SK Kemenkumham Akta Pendirian (3)";
        newData = serverList.company + isi.url;
        nomor = 12;
        break;
      case "akta_pendirian_sk_4":
        namaDokumen = "SK Kemenkumham Akta Pendirian (4)";
        newData = serverList.company + isi.url;
        nomor = 13;
        break;
      case "akta_pendirian_sk_5":
        namaDokumen = "SK Kemenkumham Akta Pendirian (5)";
        newData = serverList.company + isi.url;
        nomor = 14;
        break;
      case "akta_pendirian_sk_6":
        namaDokumen = "SK Kemenkumham Akta Pendirian (6)";
        newData = serverList.company + isi.url;
        nomor = 15;
        break;
      case "akta_perubahan":
        namaDokumen = "Akta Perubahan";
        newData = serverList.company + isi.url;
        nomor = 16;
        break;
      case "akta_perubahan_2":
        namaDokumen = "Akta Perubahan (2)";
        newData = serverList.company + isi.url;
        nomor = 17;
        break;
      case "akta_perubahan_3":
        namaDokumen = "Akta Perubahan (3)";
        newData = serverList.company + isi.url;
        nomor = 18;
        break;
      case "akta_perubahan_4":
        namaDokumen = "Akta Perubahan (4)";
        newData = serverList.company + isi.url;
        nomor = 19;
        break;
      case "akta_perubahan_5":
        namaDokumen = "Akta Perubahan (5)";
        newData = serverList.company + isi.url;
        nomor = 20;
        break;
      case "akta_perubahan_6":
        namaDokumen = "Akta Perubahan (6)";
        newData = serverList.company + isi.url;
        nomor = 21;
        break;
      case "akta_perubahan_sk":
        namaDokumen = "SK Kemenkumham Akta Perubahan";
        newData = serverList.company + isi.url;
        nomor = 22;
        break;
      case "akta_perubahan_sk_2":
        namaDokumen = "SK Kemenkumham Akta Perubahan (2)";
        newData = serverList.company + isi.url;
        nomor = 23;
        break;
      case "akta_perubahan_sk_3":
        namaDokumen = "SK Kemenkumham Akta Perubahan (3)";
        newData = serverList.company + isi.url;
        nomor = 24;
        break;
      case "akta_perubahan_sk_4":
        namaDokumen = "SK Kemenkumham Akta Perubahan (4)";
        newData = serverList.company + isi.url;
        nomor = 25;
        break;
      case "akta_perubahan_sk_5":
        namaDokumen = "SK Kemenkumham Akta Perubahan (5)";
        newData = serverList.company + isi.url;
        nomor = 26;
        break;
      case "akta_perubahan_sk_6":
        namaDokumen = "SK Kemenkumham Akta Perubahan (6)";
        newData = serverList.company + isi.url;
        nomor = 27;
        break;
      case "siup_nib":
        namaDokumen = "SIUP NIB";
        newData = serverList.company + isi.url;
        nomor = 28;
        break;
      case "siup_nib_2":
        namaDokumen = "SIUP NIB (2)";
        newData = serverList.company + isi.url;
        nomor = 29;
        break;
      case "siup_nib_3":
        namaDokumen = "SIUP NIB (3)";
        newData = serverList.company + isi.url;
        nomor = 30;
        break;
      case "siup_nib_4":
        namaDokumen = "SIUP NIB (4)";
        newData = serverList.company + isi.url;
        nomor = 31;
        break;
      case "siup_nib_5":
        namaDokumen = "SIUP NIB (5)";
        newData = serverList.company + isi.url;
        nomor = 32;
        break;
      case "siup_nib_6":
        namaDokumen = "SIUP NIB (6)";
        newData = serverList.company + isi.url;
        nomor = 33;
        break;
      case "npwp":
        namaDokumen = "NPWP";
        newData = serverList.company + isi.url;
        nomor = 34;
        break;
      case "npwp_2":
        namaDokumen = "NPWP (2)";
        newData = serverList.company + isi.url;
        nomor = 35;
        break;
      case "npwp_3":
        namaDokumen = "NPWP (3)";
        newData = serverList.company + isi.url;
        nomor = 36;
        break;
      case "npwp_4":
        namaDokumen = "NPWP (4)";
        newData = serverList.company + isi.url;
        nomor = 37;
        break;
      case "npwp_5":
        namaDokumen = "NPWP (5)";
        newData = serverList.company + isi.url;
        nomor = 38;
        break;
      case "npwp_6":
        namaDokumen = "NPWP (6)";
        newData = serverList.company + isi.url;
        nomor = 39;
        break;
      case "pengurus_ktp":
        namaDokumen = "KTP Pengurus";
        newData = serverList.company + isi.url;
        nomor = 40;
        break;
      case "pengurus_ktp_2":
        namaDokumen = "KTP Pengurus (2)";
        newData = serverList.company + isi.url;
        nomor = 41;
        break;
      case "pengurus_ktp_3":
        namaDokumen = "KTP Pengurus (3)";
        newData = serverList.company + isi.url;
        nomor = 42;
        break;
      case "pengurus_ktp_4":
        namaDokumen = "KTP Pengurus (4)";
        newData = serverList.company + isi.url;
        nomor = 43;
        break;
      case "pengurus_ktp_5":
        namaDokumen = "KTP Pengurus (5)";
        newData = serverList.company + isi.url;
        nomor = 44;
        break;
      case "pengurus_ktp_6":
        namaDokumen = "KTP Pengurus (6)";
        newData = serverList.company + isi.url;
        nomor = 45;
        break;
      case "direksi_ktp":
        namaDokumen = "KTP Direksi";
        newData = serverList.company + isi.url;
        nomor = 46;
        break;
      case "direksi_ktp_2":
        namaDokumen = "KTP Direksi 2";
        newData = serverList.company + isi.url;
        nomor = 47;
        break;
      case "direksi_ktp_3":
        namaDokumen = "KTP Direksi 3";
        newData = serverList.company + isi.url;
        nomor = 48;
        break;
      case "direksi_ktp_4":
        namaDokumen = "KTP Direksi 4";
        newData = serverList.company + isi.url;
        nomor = 49;
        break;
      case "direksi_ktp_5":
        namaDokumen = "KTP Direksi 5";
        newData = serverList.company + isi.url;
        nomor = 50;
        break;
      case "direksi_ktp_6":
        namaDokumen = "KTP Direksi 6";
        newData = serverList.company + isi.url;
        nomor = 51;
        break;
      case "direksi_npwp":
        namaDokumen = "NPWP Direksi";
        newData = serverList.company + isi.url;
        nomor = 52;
        break;
      case "direksi_npwp_2":
        namaDokumen = "NPWP Direksi (2)";
        newData = serverList.company + isi.url;
        nomor = 53;
        break;
      case "direksi_npwp_3":
        namaDokumen = "NPWP Direksi (3)";
        newData = serverList.company + isi.url;
        nomor = 54;
        break;
      case "direksi_npwp_4":
        namaDokumen = "NPWP Direksi (4)";
        newData = serverList.company + isi.url;
        nomor = 55;
        break;
      case "direksi_npwp_5":
        namaDokumen = "NPWP Direksi (5)";
        newData = serverList.company + isi.url;
        nomor = 56;
        break;
      case "direksi_npwp_6":
        namaDokumen = "NPWP Direksi (6)";
        newData = serverList.company + isi.url;
        nomor = 57;
        break;
      case "komisaris_ktp":
        namaDokumen = "KTP Komisaris";
        newData = serverList.company + isi.url;
        nomor = 58;
        break;
      case "komisaris_ktp_2":
        namaDokumen = "KTP Komisaris (2)";
        newData = serverList.company + isi.url;
        nomor = 59;
        break;
      case "komisaris_ktp_3":
        namaDokumen = "KTP Komisaris (3)";
        newData = serverList.company + isi.url;
        nomor = 60;
        break;
      case "komisaris_ktp_4":
        namaDokumen = "KTP Komisaris (4)";
        newData = serverList.company + isi.url;
        nomor = 61;
        break;
      case "komisaris_ktp_5":
        namaDokumen = "KTP Komisaris (5)";
        newData = serverList.company + isi.url;
        nomor = 62;
        break;
      case "komisaris_ktp_6":
        namaDokumen = "KTP Komisaris (6)";
        newData = serverList.company + isi.url;
        nomor = 63;
        break;
      case "komisaris_npwp":
        namaDokumen = "NPWP Komisaris";
        newData = serverList.company + isi.url;
        nomor = 64;
        break;
      case "komisaris_npwp_2":
        namaDokumen = "NPWP Komisaris (2)";
        newData = serverList.company + isi.url;
        nomor = 65;
        break;
      case "komisaris_npwp_3":
        namaDokumen = "NPWP Komisaris (3)";
        newData = serverList.company + isi.url;
        nomor = 66;
        break;
      case "komisaris_npwp_4":
        namaDokumen = "NPWP Komisaris (4)";
        newData = serverList.company + isi.url;
        nomor = 67;
        break;
      case "komisaris_npwp_5":
        namaDokumen = "NPWP Komisaris (5)";
        newData = serverList.company + isi.url;
        nomor = 68;
        break;
      case "komisaris_npwp_6":
        namaDokumen = "NPWP Komisaris (6)";
        newData = serverList.company + isi.url;
        nomor = 69;
        break;
      // case "laporan_keuangan":
      //   namaDokumen = "Laporan Keuangan Tahun Berjalan";
      //   newData = serverList.company + isi.url;
      //   nomor = 70;
      //   break;
      // case "laporan_keuangan_2":
      //   namaDokumen = "Laporan Keuangan Tahun Berjalan (2)";
      //   newData = serverList.company + isi.url;
      //   nomor = 71;
      //   break;
      // case "laporan_keuangan_3":
      //   namaDokumen = "Laporan Keuangan Tahun Berjalan (3)";
      //   newData = serverList.company + isi.url;
      //   nomor = 73;
      //   break;
      // case "laporan_keuangan_4":
      //   namaDokumen = "Laporan Keuangan Tahun Berjalan (4)";
      //   newData = serverList.company + isi.url;
      //   nomor = 74;
      //   break;
      // case "laporan_keuangan_5":
      //   namaDokumen = "Laporan Keuangan Tahun Berjalan (5)";
      //   newData = serverList.company + isi.url;
      //   nomor = 75;
      //   break;
      // case "laporan_keuangan_6":
      //   namaDokumen = "Laporan Keuangan Tahun Berjalan 6";
      //   newData = serverList.company + isi.url;
      //   nomor = 76;
      //   break;
      // case "laporan_keuangan_1t":
      //   namaDokumen = "Laporan Keuangan 1 Tahun Lalu";
      //   newData = serverList.company + isi.url;
      //   nomor = 77;
      //   break;
      // case "laporan_keuangan_1t_2":
      //   namaDokumen = "Laporan Keuangan 1 Tahun Lalu (2)";
      //   newData = serverList.company + isi.url;
      //   nomor = 78;
      //   break;
      // case "laporan_keuangan_1t_3":
      //   namaDokumen = "Laporan Keuangan 1 Tahun Lalu (3)";
      //   newData = serverList.company + isi.url;
      //   nomor = 79;
      //   break;
      // case "laporan_keuangan_1t_4":
      //   namaDokumen = "Laporan Keuangan 1 Tahun Lalu (4)";
      //   newData = serverList.company + isi.url;
      //   nomor = 80;
      //   break;
      // case "laporan_keuangan_1t_5":
      //   namaDokumen = "Laporan Keuangan 1 Tahun Lalu (5)";
      //   newData = serverList.company + isi.url;
      //   nomor = 81;
      //   break;
      // case "laporan_keuangan_1t_6":
      //   namaDokumen = "Laporan Keuangan 1 Tahun Lalu (6)";
      //   newData = serverList.company + isi.url;
      //   nomor = 82;
      //   break;
      // case "laporan_keuangan_2t":
      //   namaDokumen = "Laporan Keuangan 2 Tahun Lalu";
      //   newData = serverList.company + isi.url;
      //   nomor = 83;
      //   break;
      // case "laporan_keuangan_2t_2":
      //   namaDokumen = "Laporan Keuangan 2 Tahun Lalu (2)";
      //   newData = serverList.company + isi.url;
      //   nomor = 84;
      //   break;
      // case "laporan_keuangan_2t_3":
      //   namaDokumen = "Laporan Keuangan 2 Tahun Lalu (3)";
      //   newData = serverList.company + isi.url;
      //   nomor = 85;
      //   break;
      // case "laporan_keuangan_2t_4":
      //   namaDokumen = "Laporan Keuangan 2 Tahun Lalu (4)";
      //   newData = serverList.company + isi.url;
      //   nomor = 86;
      //   break;
      // case "laporan_keuangan_2t_5":
      //   namaDokumen = "Laporan Keuangan 2 Tahun Lalu (5)";
      //   newData = serverList.company + isi.url;
      //   nomor = 87;
      //   break;
      // case "laporan_keuangan_2t_6":
      //   namaDokumen = "Laporan Keuangan 2 Tahun Lalu (6)";
      //   newData = serverList.company + isi.url;
      //   nomor = 88;
      //   break;

      case "surat_pernyataan":
        namaDokumen = "Surat Pernyataan";
        newData = serverList.company + isi.url;
        nomor = 131;
        break;
      case "foto_usaha":
        namaDokumen = "Foto Usaha";
        newData = serverList.company + isi.url;
        nomor = 132;
        break;
      case "foto_usaha_2":
        namaDokumen = "Foto Usaha (2)";
        newData = serverList.company + isi.url;
        nomor = 133;
        break;
      case "foto_usaha_3":
        namaDokumen = "Foto Usaha (3)";
        newData = serverList.company + isi.url;
        nomor = 134;
        break;
      case "foto_usaha_4":
        namaDokumen = "Foto Usaha (4)";
        newData = serverList.company + isi.url;
        nomor = 135;
        break;
      case "foto_usaha_5":
        namaDokumen = "Foto Usaha (5)";
        newData = serverList.company + isi.url;
        nomor = 136;
        break;
      case "foto_usaha_6":
        namaDokumen = "Foto Usaha (6)";
        newData = serverList.company + isi.url;
        nomor = 137;
        break;
      default:
        namaDokumen = isi.type.toLowerCase();
        newData = "";
        nomor = 138;
        break;
    }

    let bankStatementPeriod = 6;
    let bankStatementAccount = 6;

    let financialReportPeriod = 2;
    let financialReportPage = 6;

    let startNumber = 89;
    let number = startNumber;

    for (let month = 0; month <= bankStatementPeriod; month++) {
      let currentMonth = getPreviousMonth(month);
      for (let account = 0; account < bankStatementAccount; account++) {
        let id = "";
        if (account == 0) {
          id = `rekening_koran_${currentMonth['month']}_${currentMonth['year']}`;

        } else {
          id = `rekening_koran_${currentMonth['month']}_${currentMonth['year']}_${account}`;
        }

        if (isi.type.toLowerCase() == id) {
          let sequence = (account == 0 ? "" : `(${account})`)
          namaDokumen = `Rekening Koran Bulan ${currentMonth["monthName"]} ${currentMonth["year"]} ${sequence}`;
          newData = serverList.company + isi.url;
          nomor = number;
          break;
        }
        number += 1;
      }
    }

    startNumber = 70;
    number = startNumber;

    for (let year = 0; year <= financialReportPeriod; year++) {
      let currentYear = getPreviousYear(year);
      for (let page = 0; page < financialReportPage; page++) {
        let id = "";
        if (page == 0) {
          id = `laporan_keuangan_${currentYear['year']}`;

        } else {
          id = `laporan_keuangan_${currentYear['year']}_${page}`;
        }

        if (isi.type.toLowerCase() == id) {
          let sequence = (page == 0 ? "" : `(${page})`)
          namaDokumen = `Laporan Keuangan ${currentYear["year"]} ${sequence}`;
          newData = serverList.company + isi.url;
          nomor = number;
          break;
        }
        number += 1;
      }
    }

    const { status, ...tmpIsi } = isi;
    return { ...tmpIsi, no: nomor, name: namaDokumen, status: newStatus, data: newData };
  });

  return tmpList.sort((a, b) => a.no - b.no);
};

export const transformRelationDocsArr = (rows) => {
  const tmpList = rows.map((isi) => {
    var namaDokumen = "";
    var newData = "";
    var nomor = 0;
    switch (isi.type.slice(0, 2)) {
      case "sp":
        switch (isi.type.slice(-1)) {
          case "1":
            namaDokumen = "SPK 1";
            newData = serverList.company + isi.asset_url;
            nomor = 1;
            break;
          case "2":
            namaDokumen = "SPK 2";
            newData = serverList.company + isi.asset_url;
            nomor = 2;
            break;
          case "3":
            namaDokumen = "SPK 3";
            newData = serverList.company + isi.asset_url;
            nomor = 3;
            break;
          case "4":
            namaDokumen = "SPK 4";
            newData = serverList.company + isi.asset_url;
            nomor = 4;
            break;
          case "5":
            namaDokumen = "SPK 5";
            newData = serverList.company + isi.asset_url;
            nomor = 5;
            break;
          case "6":
            namaDokumen = "SPK 6";
            newData = serverList.company + isi.asset_url;
            nomor = 6;
            break;
          default:
            namaDokumen = isi.type.toLowerCase();
            newData = "";
            nomor = 7;
            break;
        }
        break;
      case "po":
        switch (isi.type.slice(-1)) {
          case "1":
            namaDokumen = "PO 1";
            newData = serverList.company + isi.asset_url;
            nomor = 8;
            break;
          case "2":
            namaDokumen = "PO 2";
            newData = serverList.company + isi.asset_url;
            nomor = 9;
            break;
          case "3":
            namaDokumen = "PO 3";
            newData = serverList.company + isi.asset_url;
            nomor = 10;
            break;
          case "4":
            namaDokumen = "PO 4";
            newData = serverList.company + isi.asset_url;
            nomor = 11;
            break;
          case "5":
            namaDokumen = "PO 5";
            newData = serverList.company + isi.asset_url;
            nomor = 12;
            break;
          case "6":
            namaDokumen = "PO 6";
            newData = serverList.company + isi.asset_url;
            nomor = 13;
            break;
          default:
            namaDokumen = isi.type.toLowerCase();
            newData = "";
            nomor = 14;
            break;
        }
        break;
      case "in":
        switch (isi.type.slice(-1)) {
          case "1":
            namaDokumen = "Invoice 1";
            newData = serverList.company + isi.asset_url;
            nomor = 15;
            break;
          case "2":
            namaDokumen = "Invoice 2";
            newData = serverList.company + isi.asset_url;
            nomor = 16;
            break;
          case "3":
            namaDokumen = "Invoice 3";
            newData = serverList.company + isi.asset_url;
            nomor = 17;
            break;
          case "4":
            namaDokumen = "Invoice 4";
            newData = serverList.company + isi.asset_url;
            nomor = 18;
            break;
          case "5":
            namaDokumen = "Invoice 5";
            newData = serverList.company + isi.asset_url;
            nomor = 19;
            break;
          case "6":
            namaDokumen = "Invoice 6";
            newData = serverList.company + isi.asset_url;
            nomor = 20;
            break;
          default:
            namaDokumen = isi.type.toLowerCase();
            newData = "";
            nomor = 21;
            break;
        }
        break;
      default:
        namaDokumen = isi.type.toLowerCase();
        newData = "";
        nomor = 22;
        break;
    }
    const { status, ...tmpIsi } = isi;
    return { ...tmpIsi, no: nomor, name: namaDokumen, data: newData };
  });

  return tmpList.sort((a, b) => a.no - b.no);
};

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
};

export const isValidProductTransport = ({ transport }) => {
  var validTransport = true;

  if (transport.length !== 0) {
    transport.map((isi) => {
      validTransport =
        validTransport &&
        isi.truck_type !== "" &&
        isi.origin !== "" &&
        isi.destination !== "" &&
        isi.departure_date_iso !== "" &&
        isi.arrival_date_iso !== "" &&
        isi.price !== "" &&
        isi.quantity !== "" &&
        isi.tax_id !== "" &&
        isi.tax_pph_id !== "";
    });
  }

  return validTransport;
};

export const isValidProductTransportInvoice = ({ transport }) => {
  var validTransport = true;

  if (transport.length !== 0) {
    transport.map((isi) => {
      validTransport =
        validTransport &&
        isi.truck_type !== "" &&
        isi.license_plate !== "" &&
        isi.origin !== "" &&
        isi.destination !== "" &&
        isi.departure_date_iso !== "" &&
        isi.arrival_date_iso !== "" &&
        isi.price !== "" &&
        isi.quantity !== "" &&
        isi.tax_id !== "" &&
        isi.tax_pph_id !== "";
    });
  }

  return validTransport;
};

export const isValidProductGeneric = ({ generic }) => {
  var validGeneric = true;

  if (generic.length !== 0) {
    generic.map((isi) => {
      validGeneric =
        validGeneric &&
        isi.name !== "" &&
        isi.transaction_date_iso !== "" &&
        isi.price !== "" &&
        isi.quantity !== "" &&
        isi.tax_id !== "" &&
        isi.tax_pph_id !== "";
    });
  }

  return validGeneric;
};
