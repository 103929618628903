export default (state=[],action) => {
         switch (action.type) {
            case 'FETCH_PELANGGAN':
              return action.payload;
            case 'INIT_PELANGGAN':
              return state=[];
            default:
              return state;
          }
    
    };