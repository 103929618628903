import Modal from "@mui/material/Modal";
import SessionAlert from "layouts/sessionAlert";

function SessionModal(props) {
  return (
    <Modal {...props}>
      <div>
        <SessionAlert onClose={props.onClose} />
      </div>
    </Modal>
  );
}

export default SessionModal;
