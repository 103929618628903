/* eslint-disable react/no-unstable-nested-components */
import React, { Component, useState, useEffect, useRef } from "react";
import { connect, useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import CurrencyExchangeRoundedIcon from "@mui/icons-material/CurrencyExchangeRounded";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import PreviewIcon from "@mui/icons-material/Preview";
import DangerousIcon from "@mui/icons-material/Dangerous";
import StopIcon from "@mui/icons-material/Stop";
import EmailIcon from "@mui/icons-material/Email";
import Tooltip from "@mui/material/Tooltip";
import Radio from "@mui/material/Radio";

import MDBox from "components/MDBox";
import { fetchInvoices } from "../../actions";
import MDTypography from "components/MDTypography";

function LVSelfBillingInvoiceDataGrid(props) {
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: props.radioSelection === undefined ? false : props.radioSelection,
    statusParent_readable: props.columnType === "broker",
    statusDocument_readable: props.columnType === "broker",
    actions: !props.viewOnly,
  });

  const invoices = props.rows;
  const [selected, setSelected] = useState([]);

  const columns = [
    {
      field: "id",
      headerName: " ",
      width: 40,
      renderCell: (cellValues) => {
        if (props.radioSelection) {
          return (
            <Radio
              checked={selected.includes(cellValues.id)}
              onClick={() => onRadioChange(cellValues)}
              value={cellValues.id}
              name="radio-buttons"
              inputProps={{ "aria-label": { cellValues } }}
            />
          );
        }
      },
    },
    {
      field: "no",
      headerName: "No.",
      headerAlign: "left",
      align: "left",
      width: 50,
    },
    {
      field: "number",
      headerName: "No. Invoice",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Link
          to={`/self-billing-invoice/${encodeURIComponent(params.row.number)}/${encodeURIComponent(
            params.row.id
          )}`}
        >
          <MDTypography variant="body3">{params.value}</MDTypography>
        </Link>
      ),
      width: 150,
    },
    {
      field: "order_id",
      headerName: "ID Order Pembelian",
      headerAlign: "left",
      align: "left",
      width: 150,
    },
    {
      field: "invoicing_company",
      headerName: "Vendor",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 150,
    },
    {
      field: "statusInvoice",
      headerName: "Status Invoice",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value.charAt(0).toUpperCase() + params.value.slice(1)}</span>
        </Tooltip>
      ),
      width: 150,
    },
    {
      field: "statusDocument_readable",
      headerName: "Status Dokumen",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 150,
    },
    {
      field: "issued_date",
      headerName: "Terbit Invoice",
      headerAlign: "left",
      type: "date",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 110,
    },

    {
      field: "term_of_payment",
      headerName: "Jangka Waktu Pembayaran",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value} Hari</span>
        </Tooltip>
      ),
      width: 110,
    },
    {
      field: "due_date",
      headerName: "Jatuh Tempo",
      headerAlign: "left",
      type: "date",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 110,
    },
    {
      field: "subtotal",
      headerName: "Sub Total",
      headerAlign: "left",
      align: "left",
      type: "number",
      renderCell: (params) => {
        const valueFormatted = Number(params.value).toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
        });
        return (
          <Tooltip title={valueFormatted}>
            <span>{valueFormatted}</span>
          </Tooltip>
        );
      },
      width: 160,
    },
    {
      field: "total",
      headerName: "Total",
      headerAlign: "left",
      align: "left",
      type: "number",
      renderCell: (params) => {
        const valueFormatted = Number(params.value).toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
        });
        return (
          <Tooltip title={valueFormatted}>
            <span>{valueFormatted}</span>
          </Tooltip>
        );
      },
      width: 160,
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Tindakan",
      sortable: false,
      align: "center",
      width: 80,
      getActions: (params) => {
        if (params.row.statusInvoice) {
          if (params.row.statusInvoice === "draft") {
            return [


              <GridActionsCellItem
                icon={
                  <Tooltip title="Terbitkan Invoice">
                    <FileCopyIcon />
                  </Tooltip>
                }
                label="Terbitkan Invoice"
                onClick={onClickDetail(params)}
              />,

            ];
          }
          if (params.row.statusInvoice === "unpaid") {
            let allowAjukan = false;
            let tooltipText = "";
            if (params.row.due_date_iso) {
              const tmpDueDate = new Date(params.row.due_date_iso);
              if (props.columnType === "broker") {
                allowAjukan =
                  Date.now() < tmpDueDate &&
                  (params.row.statusFinancing.toLowerCase() === "created" ||
                    params.row.statusFinancing.toLowerCase() === "rejected") &&
                  props.requestFinancing &&
                  params.row.statusDocument.toLowerCase() === "approved";
              } else if (props.columnType === "vendor") {
                allowAjukan =
                  Date.now() < tmpDueDate &&
                  (params.row.statusFinancing.toLowerCase() === "created" ||
                    params.row.statusFinancing.toLowerCase() === "rejected") &&
                  props.requestFinancing;
              }

              if (!allowAjukan && params.row.statusFinancing.toLowerCase() === "submitted") {
                tooltipText = "Invoice sudah diajukan untuk pembayaran cepat.";
              } else if (!allowAjukan && params.row.statusFinancing.toLowerCase() === "approved") {
                tooltipText = "Invoice sudah disetujui untuk pembayaran cepat.";
              } else if (!allowAjukan && Date.now() > tmpDueDate) {
                tooltipText =
                  "Tanggal jatuh tempo invoice sudah lewat. Tidak dapat mengajukan pembayaran cepat.";
              } else if (!allowAjukan && params.row.statusDocument.toLowerCase() === "delivered") {
                tooltipText =
                  "Masih menunggu konfirmasi email dari pelanggan. Pengajuan pembayaran cepat dapat dilakukan setelah konfirmasi pelanggan.";
              } else if (!allowAjukan && !props.requestFinancing) {
                tooltipText = "Hubungi manager anda untuk pembayaran cepat invoice.";
              }
            } else {
              tooltipText =
                "Tanggal jatuh tempo tidak ditemukan. Hubungi help center untuk perbaikan data.";
            }

            if (allowAjukan) {
              return [
                <GridActionsCellItem
                  icon={
                    <Tooltip title="Pembayaran Cepat">
                      <CurrencyExchangeRoundedIcon />
                    </Tooltip>
                  }
                  label="Pembayaran Cepat"
                  onClick={() => {
                    if (selected.includes(params.row.id)) {
                      const newInvoices = invoices.filter((el) => selected.includes(el.id));
                      newInvoices.forEach((item, idx) => {
                        item.no = idx + 1;
                      });
                      let subtotal = 0;
                      newInvoices.map((item) => {
                        subtotal = item.subtotal + subtotal;
                      });
                      props.onPilihPendana({
                        arrId: selected,
                        arrInv: newInvoices,
                        pengajuan: subtotal,
                      });
                    } else {
                      const newInvoice = params.row;
                      newInvoice.no = 1;
                      const ajukanInvoice = {
                        arrId: [params.row.id],
                        arrInv: [newInvoice],
                        pengajuan: params.row.subtotal,
                      };
                      props.onPilihPendana(ajukanInvoice);
                    }
                  }}
                />,
              ];
            }
            if (params.row.statusDocument === "created" && props.columnType === "broker") {
              return [
                <GridActionsCellItem
                  icon={
                    <Tooltip title="Kirim Email">
                      <EmailIcon />
                    </Tooltip>
                  }
                  label="Kirim Email"
                  onClick={() => {
                    props.onSendEmail({
                      invoice_id: params.row.id,
                      invoice_number: params.row.number,
                      invoice_amount: params.row.total,
                      invoiced_company_id: params.row.invoiced_company_id,
                    });
                  }}
                />,
              ];
            }

            return [
              <GridActionsCellItem
                icon={
                  <Tooltip title={tooltipText}>
                    <StopIcon />
                  </Tooltip>
                }
                label=""

              />,
            ];
          }

          return [

          ];
        }
        return [

        ];
      },
    },


  ];


  const newdata = {
    columns,
    rows: props.rows,

  };
  const navigate = useNavigate();
  const dispatch = useDispatch();



  const onClickDetail = (params) => () => {
    navigate(`/detailinvoice/${encodeURIComponent(params.row.number)}`);
  };

  const onClickUbah = (params) => () => {
    navigate(`/editinvoice/${encodeURIComponent(params.row.number)}`);
  };

  const onRadioChange = (cellValues) => {
    if (selected.includes(cellValues.id)) {
      let array = selected;
      array = array.filter((item) => item !== cellValues.id);
      setSelected(array);
      props.onSelectionModelChange(array);
    } else {
      props.onSelectionModelChange([...selected, cellValues.id]);
      setSelected([...selected, cellValues.id]);
    }
  };

  return (
    <MDBox
      sx={{
        height: props.height,
        width: "100%",
      }}
    >
      <DataGrid
        {...newdata}
        pageSize={props.pageSize}
        rowsPerPageOptions={props.rowsPerPageOptions}
        editMode="row"
        checkboxSelection={props.checkboxSelection}
        disableSelectionOnClick
        autoHeight={props.autoHeight}
        localeText={{ noRowsLabel: "Belum ada invoice penjualan." }}
        sx={{
          bgcolor: "rgba(255, 255, 255, 1)",
          borderRadius: "16px",
          fontSize: "small",
          boxShadow: props.flat ? "" : "10px 15px 27px -28px #1A73E8",
        }}

        columnVisibilityModel={columnVisibilityModel}
        isRowSelectable={(params) => params.row.statusInvoice === "unpaid"}
        onColumnVisibilityModelChange={(newModel) => {
          newModel.id = false;
          setColumnVisibilityModel(newModel);
        }}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </MDBox>
  );
}

export default LVSelfBillingInvoiceDataGrid;
