import { useState } from 'react';
import { useLocation, useParams, useNavigate, Link } from "react-router-dom";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import Radio from '@mui/material/Radio';
import Tooltip from '@mui/material/Tooltip';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import PreviewIcon from '@mui/icons-material/Preview';

import { convertRequestType } from 'common/Util';

import MDBox from "components/MDBox";
import MDTypography from 'components/MDTypography';


const LVPengajuanDataGrid = (props) => {

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
    fintech_company: props.showFintech ? true : false,
    approved_amount: props.columnType !== 'vendor'
  },

  );
  const [selected, setSelected] = useState(null);

  const columns = [
    {
      field: 'id',
    },
    {
      field: 'no',
      headerName: 'No.',
      headerAlign: 'center',
      align: "center",
      width: 50,
    },
    {
      field: 'number',
      headerName: 'Nomor Pengajuan',
      headerAlign: 'center',
      align: "left",
      renderCell: (params) => (
        <Link to={"/pengajuan/" + encodeURIComponent(params.row.type) + "/" + encodeURIComponent(params.row.id)}>
          <MDTypography variant="body3">{params.value}</MDTypography>
        </Link>
      ),
      width: 160,
    },
    {
      field: 'created_date',
      headerName: 'Tanggal Pengajuan Dana',
      headerAlign: 'center',
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ''} >
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 150,
    },
    {
      field: 'type',
      headerName: 'Tipe Pengajuan',
      headerAlign: 'center',
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? convertRequestType(params.value) : ''} >
          <span>{convertRequestType(params.value)}</span>
        </Tooltip>
      ),
      width: 150,
    },
    {
      field: 'fintech_company',
      headerName: 'Pendana',
      headerAlign: 'center',
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value[0] ? params.value[0] : ''} >
          <span>{params.value[0]}</span>
        </Tooltip>
      ),
      width: 150,
    },
    {
      field: 'status_readable',
      headerName: props.columnType === 'vendor' ? 'Status QuickPay' : 'Status Pendanaan',
      headerAlign: 'center',
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ''} >
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 180,
    },

    {
      field: 'term_of_payment',
      headerName: 'Durasi Pinjaman',
      headerAlign: 'center',
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ''} >
          <span>{params.value} Hari</span>
        </Tooltip>
      ),
      width: 150,
    },
    {
      field: 'amount',
      headerName: 'Total Pengajuan',
      headerAlign: 'center',
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? Number(params.value).toLocaleString("id-ID", { style: "currency", currency: "IDR" }) : ''} >
          <span>{Number(params.value).toLocaleString("id-ID", { style: "currency", currency: "IDR" })}</span>
        </Tooltip>
      ),
      width: 150,
    },
    {
      field: 'approved_amount',
      headerName: 'Total Disetujui',
      headerAlign: 'center',
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? Number(params.value).toLocaleString("id-ID", { style: "currency", currency: "IDR" }) : ''} >
          <span>{Number(params.value).toLocaleString("id-ID", { style: "currency", currency: "IDR" })}</span>
        </Tooltip>
      ),
      width: 150,
    },

  ];


  return (
    <MDBox
      mt={3}
      sx={{
        height: 400,
        width: '100%',
      }}

    >
      <DataGrid
        columns={columns}
        rows={props.rows}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
        localeText={{ noRowsLabel: "Belum ada order financing." }}
        sx={{
          bgcolor: 'rgba(255, 255, 255, 1)',
          borderRadius: '16px',
          fontSize: 'small',
          boxShadow: '10px 15px 27px -28px #1A73E8',
        }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => {
          newModel.id = false;
          setColumnVisibilityModel(newModel);
        }
        }
        experimentalFeatures={{ newEditingApi: true }}
      />
    </MDBox>
  );
};

export default LVPengajuanDataGrid;