
import Modal from '@mui/material/Modal';
import PilihPendanaPenjualan from '../layouts/pilihPendanaPenjualan';

const PilihPendanaPenjualanModal = (props) => {

    return(
        <Modal
            {...props}
        >
            <div>
                <PilihPendanaPenjualan onClose={props.onClose} datapengajuan={props.datapengajuan}/>
            </div>
        </Modal>
    );
}

export default PilihPendanaPenjualanModal;