import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useLocation, useParams, useNavigate, Link } from "react-router-dom";

import Icon from "@mui/material/Icon";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Grid from "@mui/material/Grid";

import Card from "@mui/material/Card";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SimpleDashboardNavbar from "examples/Navbars/SimpleDashboardNavbar";
import MDBox from "components/MDBox";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import ListInfoCard from "examples/Cards/InfoCards/ListInfoCard";

import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import LVProductDataGrid from "components/LVProductDataGrid";
import LVInvoiceDataGrid from "components/LVInvoiceDataGrid";
import LVPembelianDataGrid from "components/LVPembelianDataGrid";
import LVPersetujuanDataGrid from "components/LVPersetujuanDataGrid";

import PilihPendanaPenjualanModal from "modals/pilihPendanaPenjualanModal";

import {
  updateSaleDocument,
  deleteSaleDocument,
  getOrderPenjualan,
  initAnOrderPenjualan,
  getOrderPenjualanVendor,
  updateStatusOrderPenjualan,
  getOrderPenjualanFintech,
} from "actions/orderPenjualanAction";
import {
  transformProductPenjualan,
  transformStatusOrderPenjualan,
  transformOrderPenjualan,
  getStatusOrderPenjualan,
  transformStatusPengajuan,
} from "common/Util";
import { initAnPengajuan } from "actions/detailPengajuanAction";
import LVFileInput from "components/LVFileInput";
import { getCreditLimitSO, initCreditLimitSummary } from "actions/fintechAction";
import { signOut } from "../../actions/authAction";
import { emptyMessage } from "../../actions";
import serverList from "../../apis/serverList";

function DetailOrderPenjualan() {
  const user = JSON.parse(localStorage.getItem("user"));

  const [transformRow, setTransformRow] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogTerbit, setOpenDialogTerbit] = useState(false);
  const [status, setStatus] = useState("");
  const [statusFinancing, setStatusFinancing] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  const [poPelanggan, setPoPelanggan] = useState(null);
  const [spkPelanggan, setSpkPelanggan] = useState(null);
  const [poPelangganId, setPoPelangganId] = useState(null);
  const [spkPelangganId, setSpkPelangganId] = useState(null);
  const [poPelangganLogo, setPoPelangganLogo] = useState(null);
  const [spkPelangganLogo, setSpkPelangganLogo] = useState(null);
  const [textKeterangan, setTextKeterangan] = useState("");
  const [textSyaratKetentuan, setTextSyaratKetentuan] = useState("");

  const anOrderPenjualan = useSelector((state) => state.anOrderPenjualan, shallowEqual);

  const anCreditLimitSummary = useSelector((state) => state.anCreditLimitSummary, shallowEqual);

  const message = useSelector((state) => state.message, shallowEqual);

  const dispatch = useDispatch();
  const params = useParams();

  const refs = {
    poPelanggan: useRef(),
    spkPelanggan: useRef(),
  };

  const rowsObjectBroker = {
    "No. Order Penjualan": anOrderPenjualan?.header?.number,
    "Nama Project": anOrderPenjualan?.header?.project_name,
    "Tgl. Order": anOrderPenjualan?.header?.issued_date,
    Status: transformStatusOrderPenjualan({ status }),
    "Status Pendanaan": transformStatusPengajuan({ status: statusFinancing, type: "purchase" }),
    Pelanggan: anOrderPenjualan?.header?.customer_company,
    // 'Penjual' : anOrderPenjualan?.header?.sale_person,
    "Term of Payment": `${anOrderPenjualan?.header?.term_of_payment} Hari`,
    "Sub Total": Number(anOrderPenjualan?.header?.subtotal).toLocaleString("id-ID", {
      style: "currency",
      currency: "IDR",
    }),
    Pajak: Number(anOrderPenjualan?.header?.tax_amount).toLocaleString("id-ID", {
      style: "currency",
      currency: "IDR",
    }),
    Total: Number(anOrderPenjualan?.header?.total).toLocaleString("id-ID", {
      style: "currency",
      currency: "IDR",
    }),
  };

  const rowsObjectVendor = {
    "No. Order Penjualan": anOrderPenjualan?.header?.number,
    "Nama Project": anOrderPenjualan?.header?.project_name,
    "Tgl. Order": anOrderPenjualan?.header?.issued_date,
    Status: transformStatusOrderPenjualan({ status }),
    // 'Status Pendanaan' : transformStatusPengajuan({status: statusFinancing, type: 'purchase'}),
    Pelanggan: anOrderPenjualan?.header?.customer_company,
    // 'Penjual' : anOrderPenjualan?.header?.sale_person,
    "Term of Payment": `${anOrderPenjualan?.header?.term_of_payment} Hari`,
    "Sub Total": Number(anOrderPenjualan?.header?.subtotal).toLocaleString("id-ID", {
      style: "currency",
      currency: "IDR",
    }),
    Pajak: Number(anOrderPenjualan?.header?.tax_amount).toLocaleString("id-ID", {
      style: "currency",
      currency: "IDR",
    }),
    Total: Number(anOrderPenjualan?.header?.total).toLocaleString("id-ID", {
      style: "currency",
      currency: "IDR",
    }),
  };

  const rowsObjectLimitKredit = {
    "Limit Kredit":
      anCreditLimitSummary.length === 0
        ? " - "
        : Number(anCreditLimitSummary?.credit_limit).toLocaleString("id-ID", {
            style: "currency",
            currency: "IDR",
          }),
    "Penggunaan Kredit":
      anCreditLimitSummary.length === 0
        ? " - "
        : Number(anCreditLimitSummary?.usage).toLocaleString("id-ID", {
            style: "currency",
            currency: "IDR",
          }),
    "Kredit Tersedia":
      anCreditLimitSummary.length === 0
        ? " - "
        : Number(anCreditLimitSummary?.remaining).toLocaleString("id-ID", {
            style: "currency",
            currency: "IDR",
          }),
  };

  const rows = [
    {
      id: "1",
      name: "CDD| Jakarta - Bekasi",
      transaction_date: "1 Januari 2023",
      price: 1000000,
      price_total: 1000000,
      quantity: "1",
      tax: "11%",
      remark: "Tidak ada pajak",
    },
  ];

  useEffect(() => {
    const id = decodeURIComponent(params.id);
    if (id !== null) {
      if (user?.company?.business_type === "vendor") {
        dispatch(getOrderPenjualanVendor({ id }));
      } else if (user?.company?.business_type === "fintech") {
        dispatch(getOrderPenjualanFintech({ id }));
      } else if (user?.company?.business_type === "broker") {
        dispatch(getOrderPenjualan({ id }));
        dispatch(getCreditLimitSO(id));
      }
    }
    return () => {
      dispatch(initAnOrderPenjualan());
      dispatch(initCreditLimitSummary());
    };
  }, []);

  useEffect(() => {
    let i = 0;
    let transport = [];
    if (anOrderPenjualan?.detail?.transport !== undefined) {
      transport = anOrderPenjualan.detail.transport.map((item) => {
        i += 1;
        return {
          id: i,
          name: `${item.truck_type} ${item.license_plate} ${item.origin} ${item.destination}`,
          price: item.price,
          quantity: item.quantity,
          transaction_date: `${item.departure_date} - ${item.arrival_date}`,
          departure_date_iso: item.departure_date_iso,
          arrival_date_iso: item.arrival_date_iso,
          tax: item.tax,
          remark: item.remark,
        };
      });
    }

    let generic = [];
    if (anOrderPenjualan?.detail?.generic !== undefined) {
      generic = anOrderPenjualan.detail.generic.map((item) => {
        i += 1;
        return {
          id: i,
          name: item.name,
          unit: item.unit,
          price: item.price,
          quantity: item.quantity,
          transaction_date: item.transaction_date,
          tax: item.tax,
          remark: item.remark,
        };
      });
    }

    const listProductShow = [...transport, ...generic];
    setTransformRow(listProductShow);
    setStatus(getStatusOrderPenjualan({ statusObj: anOrderPenjualan?.status, statusType: "sale" }));
    setStatusFinancing(
      getStatusOrderPenjualan({ statusObj: anOrderPenjualan?.status, statusType: "financing" })
    );

    if (anOrderPenjualan.document !== undefined) {
      let foundPoPelanggan = false;
      let foundSpkPelanggan = false;
      anOrderPenjualan.document.map((isi) => {
        if (isi.type === "po_pelanggan") {
          setPoPelanggan(serverList.sales + isi.url);
          setPoPelangganId(isi.id);
          setPoPelangganLogo("/pdf_logo_bw.jpg");
          foundPoPelanggan = true;
        } else if (isi.type === "spk_pelanggan") {
          setSpkPelanggan(serverList.sales + isi.url);
          setSpkPelangganId(isi.id);
          setSpkPelangganLogo("/pdf_logo_bw.jpg");
          foundSpkPelanggan = true;
        }
      });
      if (!foundPoPelanggan) {
        setPoPelanggan(null);
        setPoPelangganId(null);
        setPoPelangganLogo(null);
      }
      if (!foundSpkPelanggan) {
        setSpkPelanggan(null);
        setSpkPelangganId(null);
        setSpkPelangganLogo(null);
      }
    }
    if (anOrderPenjualan.information) {
      anOrderPenjualan.information.map((isi) => {
        if (isi.type === "remark") {
          setTextKeterangan(isi.content);
        } else if (isi.type === "terms_condition") {
          setTextSyaratKetentuan(isi.content);
        }
      });
    }
  }, [anOrderPenjualan]);

  useEffect(() => {
    if (message.status !== "") {
      dispatch(emptyMessage());
      switch (message.status) {
        case "FAIL_UPDATE_STATUS_ORDER_PENJUALAN":
          alert(`Gagal terbitkan order penjualan. Error: ${message.message}`);
          return;
        default:
      }
    }
  }, [message]);

  useEffect(() => {
  }, [anCreditLimitSummary]);

  const render_ajukan_financing = () => {
    if (user?.access_list?.includes("request_so_financing")) {
      if (status.toLowerCase() === "sale" && statusFinancing.toLowerCase() === "created") {
        return (
          <Grid item>
            <MDButton variant="outlined" color="info" size="small" onClick={handleClickPinjaman}>
              Aktifkan Pendanaan
            </MDButton>
          </Grid>
        );
      }
    }
  };

  const render_terbitkan = () => {
    if (user?.access_list?.includes("publish_so")) {
      if (status.toLowerCase() === "draft") {
        return (
          <MDButton variant="contained" color="info" size="medium" onClick={handleClickTerbitkan}>
            Terbitkan
          </MDButton>
        );
      }
    }
  };

  const render_ubah = () => {
    if (user?.access_list?.includes("edit_so")) {
      if (status.toLowerCase() === "draft") {
        const linkEdit = `/editorderpenjualan/${encodeURIComponent(params.id)}`;
        return (
          <MDButton variant="outlined" color="info" size="medium" component={Link} to={linkEdit}>
            Ubah
          </MDButton>
        );
      }
    }
  };

  const onChangePoPelanggan = (e) => {
    dispatch(
      updateSaleDocument({
        sale_order_id: anOrderPenjualan?.header?.id,
        type: "po_pelanggan",
        file: e.target.files[0],
      })
    );
    if (refs.poPelanggan.current !== undefined) {
      refs.poPelanggan.current.value = "";
    }
  };

  const onChangeSpkPelanggan = (e) => {
    dispatch(
      updateSaleDocument({
        sale_order_id: anOrderPenjualan?.header?.id,
        type: "spk_pelanggan",
        file: e.target.files[0],
      })
    );
    if (refs.spkPelanggan.current !== undefined) {
      refs.spkPelanggan.current.value = "";
    }
  };

  const handleClickPinjaman = () => {
    setOpenModal(true);
  };

  const handleClickTerbitkan = () => {
    setOpenDialogTerbit(true);
    setDialogTitle("Terbitkan Order Penjualan");
    setDialogMessage(
      "Untuk terbit, PO pelanggan atau SPK pelanggan wajib diunggah salah satunya. Setelah order penjualan terbit, informasi dalam order penjualan tidak dapat diubah lagi."
    );
    setOpenDialog(true);
  };

  const handleCloseAgree = () => {
    if (openDialogTerbit) {
      dispatch(
        updateStatusOrderPenjualan({
          arr_sale_order_id: [anOrderPenjualan.header?.id],
          type: "sale",
          status: "sale",
          sale_order_id: anOrderPenjualan.header?.id,
        })
      );
    }
    setOpenDialogTerbit(false);
    setDialogTitle("");
    setDialogMessage("");
    setOpenDialog(false);
  };

  const handleCloseCancel = () => {
    setOpenDialogTerbit(false);
    setDialogTitle("");
    setDialogMessage("");
    setOpenDialog(false);
  };

  const deleteSaleDocs = ({ document_id, sale_order_id }) => {
   
    if (document_id !== null) {
      dispatch(deleteSaleDocument({ document_id, sale_order_id }));
    }
  };

  const render_lampiran = () => {
    if (user?.access_list?.includes("update_attachment_so") && status.toLowerCase() === "draft") {
      return (
        <Grid container spacing={2} justifyContent="space-around">
          <Grid item>
            <LVFileInput
              srcImage={poPelangganLogo}
              srcFile={poPelanggan}
              height={200}
              width={240}
              fontSize={10}
              id="purchase_order"
              onChangeFile={onChangePoPelanggan}
              text1="Unggah PO dari Pelanggan"
              text2="Maksimal ukuran 5MB PDF"
              text3=""
              title="PO Pelanggan"
              titleFontSize={12}
              accept=".pdf"
              deleteFile={() =>
                deleteSaleDocs({
                  document_id: poPelangganId,
                  sale_order_id: anOrderPenjualan?.header?.id,
                })
              }
              refinput={refs.poPelanggan}
            />
          </Grid>
          <Grid item>
            <LVFileInput
              srcImage={spkPelangganLogo}
              srcFile={spkPelanggan}
              height={200}
              width={240}
              fontSize={10}
              id="bukti_kirim"
              onChangeFile={onChangeSpkPelanggan}
              text1="Unggah SPK Pelanggan"
              text2="Maksimal ukuran 5MB PDF"
              text3=""
              title="SPK Pelanggan"
              titleFontSize={12}
              accept=".pdf"
              deleteFile={() =>
                deleteSaleDocs({
                  document_id: spkPelangganId,
                  sale_order_id: anOrderPenjualan?.header?.id,
                })
              }
              refinput={refs.spkPelanggan}
            />
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container spacing={3} justifyContent="space-around">
        <Grid item>
          <LVFileInput
            srcImage={poPelangganLogo}
            srcFile={poPelanggan}
            height={200}
            width={240}
            fontSize={10}
            id="purchase_order"
            text1="Unggah PO dari Pelanggan"
            text2="Maksimal ukuran 5MB PDF"
            text3=""
            title="PO Pelanggan"
            titleFontSize={12}
            accept=".pdf"
            viewOnly
            refinput={refs.poPelanggan}
          />
        </Grid>
        <Grid item>
          <LVFileInput
            srcImage={spkPelangganLogo}
            srcFile={spkPelanggan}
            height={200}
            width={240}
            fontSize={10}
            id="bukti_kirim"
            text1="Unggah SPK Pelanggan"
            text2="Maksimal ukuran 5MB PDF"
            text3=""
            title="SPK Pelanggan"
            titleFontSize={12}
            viewOnly
            accept=".pdf"
            refinput={refs.spkPelanggan}
          />
        </Grid>
      </Grid>
    );
  };

  const render_req_if = () => {
    if (
      user?.access_list?.includes("request_invoice_financing") &&
      statusFinancing.toLowerCase() === "approved"
    ) {
      return (
        <Grid item>
          <MDBox mt={-1} mb={2}>
            <MDButton variant="outlined" color="info">
              <Icon sx={{ fontWeight: "bold" }}>add</Icon>
              &nbsp;Pengajuan Invoice Financing
            </MDButton>
          </MDBox>
        </Grid>
      );
    }
  };

  const render_new_invoice = () => {
    if (user?.access_list?.includes("new_invoice")) {
      const linkNewInvoice = `/newinvoice/${encodeURIComponent(
        anOrderPenjualan?.header?.customer_company_id
      )}/${encodeURIComponent(anOrderPenjualan?.header?.customer_company)}/${encodeURIComponent(
        anOrderPenjualan?.header?.id
      )}/${encodeURIComponent(anOrderPenjualan?.header?.number)}/${encodeURIComponent(
        anOrderPenjualan?.header?.project_name
      )}`;
      return (
        <Grid item>
          <MDBox mt={-1} mb={2}>
            <MDButton variant="outlined" color="info" component={Link} to={linkNewInvoice}>
              <Icon sx={{ fontWeight: "bold" }}>add</Icon>
              &nbsp;Invoice Baru
            </MDButton>
          </MDBox>
        </Grid>
      );
    }
  };

  const render_invoice = () => {
    if (status !== "") {
      if (status.toLowerCase() !== "draft") {
        return (
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <MDTypography color="text" px={1} sx={{ fontSize: 14 }}>
                {" "}
                Daftar Invoice Penjualan{" "}
              </MDTypography>
            </AccordionSummary>
            <AccordionDetails>
              <MDBox sx={{ mt: -1 }}>
                <Grid container spacing={3} justifyContent="space-between">
                  <Grid item>
                    <MDBox></MDBox>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={1} justifyContent="flex-end">
                      {render_req_if()}
                      {render_new_invoice()}
                    </Grid>
                  </Grid>
                </Grid>
                <MDBox>
                  <LVInvoiceDataGrid
                    rows={[]}
                    height="100%"
                    columnType={user?.company?.business_type}
                    requestFinancing={user?.access_list?.includes("request_invoice_financing")}
                    rowsPerPageOptions={[10]}
                    flat
                    autoHeight
                  />
                </MDBox>
              </MDBox>
            </AccordionDetails>
          </Accordion>
        );
      }
    }
  };

  const render_new_pembelian = () => {
    if (user?.access_list?.includes("new_po")) {
      const linkNewPembelian = `/neworderpembelian/${encodeURIComponent(
        anOrderPenjualan?.header?.id
      )}/${encodeURIComponent(anOrderPenjualan?.header?.number)}/${encodeURIComponent(
        anOrderPenjualan?.header?.project_name
      )}`;
      return (
        <Grid item>
          <MDBox mt={-1} mb={2}>
            <MDButton variant="outlined" color="info" component={Link} to={linkNewPembelian}>
              <Icon sx={{ fontWeight: "bold" }}>add</Icon>
              &nbsp;Order Pembelian Baru
            </MDButton>
          </MDBox>
        </Grid>
      );
    }
  };

  const render_pembelian = () => {
    if (status !== "") {
      if (status.toLowerCase() !== "draft") {
        return (
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <MDTypography color="text" px={1} sx={{ fontSize: 14 }}>
                {" "}
                Daftar Order Pembelian{" "}
              </MDTypography>
            </AccordionSummary>
            <AccordionDetails>
              <MDBox sx={{ mt: -1 }}>
                <Grid container spacing={3} justifyContent="space-between">
                  <Grid item>
                    <MDBox></MDBox>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={1} justifyContent="flex-end">
                      <Grid item>
                        <MDBox>
                        
                        </MDBox>
                      </Grid>
                      {render_new_pembelian()}
                    </Grid>
                  </Grid>
                </Grid>
                <MDBox>
                  <LVPembelianDataGrid
                    height="100%"
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    checkboxSelection={false}
                    radioSelection={false}
                    rows={[]}
                    flat
                    autoHeight
                  />
                </MDBox>
              </MDBox>
            </AccordionDetails>
          </Accordion>
        );
      }
    }
  };

  const render_persetujuan_qp = () => {
    if (statusFinancing !== "") {
      if (statusFinancing.toLowerCase() === "approved") {
        return (
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <MDTypography color="text" px={1} sx={{ fontSize: 14 }}>
                {" "}
                Persetujuan QuickPay{" "}
              </MDTypography>
            </AccordionSummary>
            <AccordionDetails>
              <MDBox sx={{ mt: -1 }}>
                <LVPersetujuanDataGrid height="100%" rows={[]} flat autoHeight />
              </MDBox>
            </AccordionDetails>
          </Accordion>
        );
      }
    }
  };

  return (
    <>
      <DashboardLayout>
        <SimpleDashboardNavbar name="Order Penjualan" />
        <Grid container direction="column" justifyContent="space-between" spacing={3}>
          <Grid item ml={1} mr={3}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Grid container spacing={1} justifyContent="space-around">
                  <Grid item>
                    <MDTypography variant="h4">
                      {anOrderPenjualan?.header?.number
                        ? anOrderPenjualan?.header?.number
                        : anOrderPenjualan?.header?.reference}
                    </MDTypography>
                  </Grid>
                  <Grid item textAlign="center" sx={{ margin: "auto" }}>
                    <MDBox
                      alignItems="center"
                      px={2}
                      py={1}
                      sx={{ bgcolor: "rgba(235, 235, 235, 1)", borderRadius: 2, height: "100%" }}
                    >
                      <MDTypography
                        color="text"
                        sx={{ fontSize: 12, margin: "auto" }}
                        fontWeight="medium"
                      >
                        {transformStatusOrderPenjualan({ status })}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Dialog
                  open={openDialog}
                  onClose={handleCloseCancel}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {dialogMessage}
                    </DialogContentText>
                    {render_lampiran()}
                  </DialogContent>
                  <DialogActions>
                    <MDButton
                      onClick={handleCloseAgree}
                      autoFocus
                      disabled={!(poPelangganId || spkPelangganId)}
                      color="info" variant="contained"
                    >
                      Setuju
                    </MDButton>
                    <MDButton color="error" variant="outlined" onClick={handleCloseCancel}>Batal</MDButton>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <MDBox bgColor="white" p={0.5} shadow="md" borderRadius="xl">
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} xl={2.4}>
                  <DefaultInfoCard
                    iconHide
                    title={anOrderPenjualan?.header?.customer_company}
                    description="Pelanggan"
                    first
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={2.4}>
                  <DefaultInfoCard
                    iconHide
                    title={anOrderPenjualan?.header?.issued_date}
                    description="Tanggal Order"
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={2.4}>
                  <DefaultInfoCard
                    iconHide
                    title={`${anOrderPenjualan?.header?.term_of_payment} Hari`}
                    description="Term of Payment"
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={2.4}>
                  <DefaultInfoCard
                    iconHide
                    title={Number(anOrderPenjualan?.header?.total).toLocaleString("id-ID", {
                      style: "currency",
                      currency: "IDR",
                    })}
                    description="Total"
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={2.4}>
                  <DefaultInfoCard
                    iconHide
                    anCreditLimitSummary
                    title={
                      anCreditLimitSummary.length === 0
                        ? " - "
                        : Number(anCreditLimitSummary?.remaining).toLocaleString("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          })
                    }
                    description="Kredit Tersedia"
                    last
                  />
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
          <Grid item>
            <Grid container spacing={3}>
              <Grid item alignItems="center" md={4}>
                <Card mb={2}>
                  <ListInfoCard
                    descriptionHide
                    title="Informasi Order Penjualan"
                    info={
                      user?.company?.business_type === "vendor"
                        ? rowsObjectVendor
                        : rowsObjectBroker
                    }
                    shadow={false}
                  />
                </Card>
                <Card mb={2} sx={{ marginTop: 2 }}>
                  <ListInfoCard
                    descriptionHide
                    title="Informasi Limit Kredit"
                    info={rowsObjectLimitKredit}
                    shadow={false}
                  />
                </Card>

                <Card sx={{ marginTop: 2 }}>
                  <MDBox p={2} mx={3} display="flex" justifyContent="center" />
                  <MDBox pb={4} px={2} alignItems="center" textAlign="center" lineHeight={1.25}>
                    <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      Lampiran
                    </MDTypography>
                    {render_lampiran()}
                  </MDBox>
                </Card>
                <Card sx={{ marginTop: 2 }}>
                  <DefaultInfoCard
                    iconHide
                    title="Keterangan"
                    description={
                      textKeterangan === "" ? "Keterangan tidak tersedia" : textKeterangan
                    }
                    shadow={false}
                  />
                </Card>
                <Card sx={{ marginTop: 2 }}>
                  <DefaultInfoCard
                    iconHide
                    title="Syarat dan Ketentuan"
                    description={
                      textSyaratKetentuan === ""
                        ? "Syarat dan ketentuan tidak tersedia"
                        : textSyaratKetentuan
                    }
                    shadow={false}
                  />
                </Card>
              </Grid>
              <Grid item md={8}>
                <Accordion defaultExpanded sx={{ boxShadow: "10px 15px 27px -28px #1A73E8" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <MDTypography color="text" px={1} sx={{ fontSize: 14 }}>
                      Produk dalam Order Penjualan{" "}
                    </MDTypography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MDBox sx={{ mt: -1 }}>
                      <LVProductDataGrid viewOnly rows={transformRow || []} />
                    </MDBox>
                  </AccordionDetails>
                </Accordion>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <PilihPendanaPenjualanModal
          open={openModal}
          datapengajuan={{
            arrId: [anOrderPenjualan?.header?.id],
            arrPenjualan: transformOrderPenjualan({
              orderPenjualan: [
                {
                  id: anOrderPenjualan?.header?.id,
                  creation_date: anOrderPenjualan?.header?.issued_date,
                  creation_date_iso: anOrderPenjualan?.header?.issued_date_iso,
                  number: anOrderPenjualan?.header?.number,
                  project_name: anOrderPenjualan?.header?.project_name,
                  status: anOrderPenjualan?.status,
                  customer_company: anOrderPenjualan?.header?.customer_company,
                  total: anOrderPenjualan?.header?.total,
                },
              ],
              businessType: user?.company?.business_type,
            }),
            pengajuan: anOrderPenjualan?.header?.total,
          }}
          onClose={(e) => {
            setOpenModal(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        />
      </DashboardLayout>
      {status.toLowerCase() === "draft" ? (
        <MDBox
          justifyContent="end"
          position="sticky"
          bottom={0}
          sx={{ bgcolor: "#ffffff" }}
          height="65px"
          width="100%"
          py={2}
          pr={3}
          display="flex"
        >
          <MDBox>{render_ubah()}</MDBox>
          <MDBox ml={3}>{render_terbitkan()}</MDBox>
        </MDBox>
      ) : null}
    </>
  );
}

export default DetailOrderPenjualan;
