
import { useState, useEffect } from "react";
import { connect, useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SimpleDashboardNavbar from "examples/Navbars/SimpleDashboardNavbar";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";

import LVPenjualanDataGrid from "components/LVPenjualanDataGrid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import list from "assets/theme/components/list";
import PilihPendanaPenjualanModal from "modals/pilihPendanaPenjualanModal";
import step from "assets/theme/components/stepper/step";
import ReactGA from "react-ga4";
import { transformOrderPenjualan } from "../../common/Util";
import { getCompanyKYCStatus } from "../../actions/companyAction";

import {
  fetchOrderPenjualan,
  fetchOrderPenjualanPage,
  fetchOrderPenjualanVendor,
  initOrderPenjualan,
} from "../../actions/orderPenjualanAction";
import MDInput from "components/MDInput";

function OrderPenjualan() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/order-penjualan", title: "Order Penjualan" });
  }, []);

  const user = JSON.parse(localStorage.getItem("user"));
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [dataPengajuan, setDataPengajuan] = useState({});

  const dispatch = useDispatch();

  const listOrderPenjualan = useSelector((state) => state.listOrderPenjualan, shallowEqual);

  const anCompanyKycStatus = useSelector((state) => state.anCompanyKycStatus, shallowEqual);

  useEffect(() => {
    if (user?.company?.business_type === "vendor") {
      dispatch(fetchOrderPenjualanVendor());
    } else {
      dispatch(fetchOrderPenjualanPage({ page }));
    }
    dispatch(getCompanyKYCStatus());
    return () => {
      dispatch(initOrderPenjualan());
    };
  }, [page]);

  useEffect(() => {

  }, [listOrderPenjualan]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const navigateToRegisterBorrow = () => {
    navigate("/registerborrow");
  };

  const render_order_penjualan_baru = () => {
    if (user?.access_list?.includes("new_so")) {
      return (
        <Grid item>
          <MDBox pt={2} pb={3}>
            <MDButton variant="contained" color="info" component={Link} to="/neworderpenjualan">
              <Icon sx={{ fontWeight: "bold" }}>add</Icon>
              &nbsp;Tambah Order Penjualan
            </MDButton>
          </MDBox>
        </Grid>
      );
    }
  };

  return (
    <div>
      <DashboardLayout>
        <SimpleDashboardNavbar name="Order Penjualan"/>
        <MDBox mt={5} bgColor="white" p={0.5} shadow="md" borderRadius="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="secondary"
                  icon="add_to_queue"
                  title="Baru"
                  count="N/A"
                  countVariant="h5"
                  percentage={{
                    color: "success",
                    amount: "N/A",
                    label: "dari minggu lalu",
                    type: "up",
                  }}
                  first
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="engineering"
                  title="Pengerjaan Berlangsung"
                  count="N/A"
                  countVariant="h5"
                  percentage={{
                    color: "success",
                    amount: "N/A",
                    label: "dari minggu lalu",
                    type: "up",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="receipt"
                  title="Pembuatan Invoice"
                  count="N/A"
                  countVariant="h5"
                  percentage={{
                    color: "primary",
                    amount: "N/A",
                    label: "dari minggu lalu",
                    type: "down",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="hourglass_bottom"
                  title="Menunggu Pembayaran"
                  count="N/A"
                  countVariant="h5"
                  percentage={{
                    color: "success",
                    amount: "N/A",
                    label: "dari minggu lalu",
                    type: "up",
                  }}
                  last
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item />
          <Grid item width="70%">
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <MDBox pt={2}>
                
                </MDBox>
              </Grid>
              <MDBox pr={2} py={3} my="auto">
                <MDInput label="Cari disemua kolom.." sx={{ width: "300px" }} />
              </MDBox>
              {render_order_penjualan_baru()}
            </Grid>
          </Grid>
        </Grid>
        <LVPenjualanDataGrid
          height={650}


          firstPage={() => setPage(1)}
          lastPage={() => setPage(8)}
          totalPage={8}
          currentPage={page}
          selectPage={(e) => setPage(parseInt(e.target.textContent))}
          nextPage={() => setPage(page + 1)}
          prevPage={() => setPage(page - 1)}
          checkboxSelection={false}
          radioSelection={false}
          viewOnly={user?.company?.business_type === "vendor"}
          requestFinancing={user?.access_list?.includes("request_so_financing")}
          rows={transformOrderPenjualan({
            orderPenjualan: listOrderPenjualan,
            businessType: user?.company?.business_type,
          })}
          columnType={user?.company?.business_type}
          onPilihPendana={(e) => {

            if (anCompanyKycStatus.status === "created") {
              handleClickOpen();
            } else if (anCompanyKycStatus.status === "approved") {
              setDataPengajuan(e);
              setOpenModal(true);
            } else {
              handleClickOpen();
            }
          }}
        />
        <PilihPendanaPenjualanModal
          open={openModal}
          datapengajuan={dataPengajuan}
          onClose={(e) => {

            setOpenModal(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        />
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Informasi</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Anda harus mendaftar pengajuan dana terlebih dahulu
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={navigateToRegisterBorrow} autoFocus>
              Daftar Pengajuan Dana
            </MDButton>
            <MDButton onClick={handleClose}>Batal</MDButton>
          </DialogActions>
        </Dialog>
      </DashboardLayout>
    </div>
  );
}

export default OrderPenjualan;
