/* eslint-disable no-nested-ternary */
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Icon } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

import { fetchRelationPendana } from "actions/daftarPendanaAction";
import LVDaftarRelationFintech from "components/LVDaftarRelationFintech";
import { createCustomerRelation } from "actions/calonPeminjamAction";
import { useParams } from "react-router-dom";
import { fetchAllpendana } from "actions/daftarPendanaAction";
import { createCustomerRelationKyc } from "actions/calonPeminjamAction";
import { fetchConnectedPendana } from "actions/daftarPendanaAction";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "650px",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  overflowY: "scroll",
  p: 4,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <MDBox sx={{ p: 3 }}>{children}</MDBox>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function SelectFintech(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const [arrFinId, setArrFinId] = useState([]);
  const [platformFee, setPlatformFee] = useState();
  const [page, setPage] = useState(1);
  const [error, setError] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [transporterName, setTransporterName] = useState(props.transporterName);
  const [fintechName, setFintechName] = useState("");

  const anFintechRelationList = useSelector((state) => state.anFintechRelationList, shallowEqual);
  const anFintechRelationListPagination = useSelector((state) => state.anFintechRelationListPagination, shallowEqual);

  const handleClickOpen = () => {
    if (platformFee == undefined) {
      setError("Platform Fee harus diisi");
      return
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };


  useEffect(() => {
    const id = props.relationId
    const companyId = decodeURIComponent(params.id);
    if (props.kyc) {
      dispatch(fetchAllpendana({ companyId, page }));
    } else if (props.viewOnly) {
      dispatch(fetchConnectedPendana({ id, page }));
    } else {
      dispatch(fetchRelationPendana({ id, page }));
    }
  }, [page]);


  useEffect(() => {
  }, [arrFinId]);

  const handleSubmit = () => {
    if (props.kyc) {
      dispatch(createCustomerRelationKyc(arrFinId[2], parseInt(params.id), parseFloat(platformFee)));
    } else {
      dispatch(createCustomerRelation(arrFinId));
    }
  };

  return (
    <div>
      <MDBox sx={style}>
        <Grid container direction="column" justifyContent="space-around" spacing={3}>
          <Grid item>
            <MDBox display="flex" justifyContent="space-between">
              <MDBox>
                <Icon fontSize="large" sx={{ pt: 1, ml: 3 }} color="info">
                  group
                </Icon>
                <MDTypography ml={2} variant="title" fontWeight="medium">
                  Daftar Pendana
                </MDTypography>
              </MDBox>
              <Icon sx={{ cursor: "pointer" }} onClick={props.onClose} fontSize="large">
                clear
              </Icon>
            </MDBox>
          </Grid>
          <Grid item>
            <MDBox sx={{ width: "100%" }}>
              <LVDaftarRelationFintech
                rows={anFintechRelationList}
                onSelectionModelChange={(ids) => {
                  const selectedRowsData = ids.map((id) => anFintechRelationList.find((row) => row.id === id));
                  console.log(selectedRowsData[2].name);
                  setFintechName(selectedRowsData[2].name);
                  setArrFinId(ids);
                }}
                radioSelection={props.viewOnly ? false : true}
                selectPage={(i) => setPage(parseInt(i.target.textContent))}
                lastPage={() => setPage(anFintechRelationListPagination.total_pages)}
                nextPage={() => setPage(anFintechRelationListPagination.next_page)}
                prevPage={() => setPage(anFintechRelationListPagination.prev_page)}
                totalPage={anFintechRelationListPagination.total_pages}
                firstPage={() => setPage(1)}
                currentPage={page}
                viewOnly={props.viewOnly}
                kyc={props.kyc}
              />
              {!props.viewOnly ? (
                <>
                  <MDBox display="flex" mt={3} justifyContent="space-between">
                    <MDBox borderRadius="xl" mr={1} width="50%" p={2} sx={{ border: "1px solid #e1e1e1" }}>
                      <MDTypography color="black" pb={1} sx={{ fontSize: 12 }}>
                        Silahkan isi besaran Platform Fee
                      </MDTypography>
                      <Grid container justifyContent="start" mt={0}>
                        <Grid item>
                          <MDInput
                            id="platform_fee"
                            name="platform_fee"
                            label="Platform Fee (%)"
                            value={platformFee}
                            required
                            onChange={(e) => {
                              setPlatformFee(e.target.value);
                            }}
                            sx={{ width: "100%" }}
                          />
                        </Grid>
                      </Grid>
                    </MDBox>
                  </MDBox>
                  <Grid container justifyContent="end" mt={0}>
                    <Grid item>
                      <MDButton variant="contained" color="info" onClick={() => handleClickOpen()}>
                        Submit
                      </MDButton>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="end" mt={2}>
                    <Grid item>
                      {error ? (
                        <MDTypography sx={{ fontSize: "10px", mb: 1 }} color="error">
                          {error}
                        </MDTypography>
                      ) : null}
                    </Grid>
                  </Grid>
                </>
              ) : null}
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Konfirmasi
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Hubungkan Company {transporterName} dengan Fintech {fintechName}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton variant="contained" color="error" onClick={handleCloseDialog}>Batal</MDButton>
          <MDButton variant="contained" color="info" onClick={handleSubmit} autoFocus>
            Lanjut
          </MDButton>
        </DialogActions>
      </Dialog>

    </div>
  );
}

export default SelectFintech;
