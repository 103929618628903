import React, { Component, useState, useEffect, useCallback } from "react";
import {
  DataGrid,
} from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function LVInvoiceDueBreakdownSummary(props) {

  const [rows, setRows] = useState([]);

  const numberOfRowsToShow = 3;
  const rowHeight = 52;
  const totalHeight = 32 + (rowHeight * (1 + numberOfRowsToShow));

  useEffect(() => {
    if (!props.data) { return }
    setRows(props.data);
  }, [props.data]);

  const columns = [
    {
      field: "id",
      headerName: "No.",
      headerAlign: "center",
      align: "center",
      width: 50,
      hidden: true,
    },
    {
      field: "name",
      headerName: "Nama",
      headerAlign: "center",
      align: "left",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      editable: true,
    },
    {
      field: "criteria_1",
      headerName: "Terlambat > 7 hari",
      headerAlign: "center",
      align: "right",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value ? `${params.value?.toLocaleString("id-ID")}` : "0"}>
          <span>
            {params.value ? `${params.value?.toLocaleString("id-ID")}` : "0"}
          </span>
        </Tooltip>
      ),
      editable: true,
    },
    {
      field: "criteria_2",
      headerName: "Terlambat < 7 hari",
      headerAlign: "center",
      align: "right",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value ? `${params.value?.toLocaleString("id-ID")}` : "0"}>
          <span>
            {params.value ? `${params.value?.toLocaleString("id-ID")}` : "0"}
          </span>
        </Tooltip>
      ),
      editable: true,
    },
    {
      field: "criteria_3",
      headerName: "Tenggat < 7 hari",
      headerAlign: "center",
      align: "right",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value ? `${params.value?.toLocaleString("id-ID")}` : "0"}>
          <span>
            {params.value ? `${params.value?.toLocaleString("id-ID")}` : "0"}
          </span>
        </Tooltip>
      ),
      editable: true,
    }
  ];

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} p="1rem"
        style={{ height: totalHeight, width: '100%' }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          hideFooter={true}
          rowHeight={rowHeight}
          columnHeaderHeight={0}
          // editMode="row"
          // autoHeight
          // rowModesModel={rowModesModel}
          // localeText={{ noRowsLabel: "Belum ada daftar produk." }}
          // onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
          // onRowEditStart={handleRowEditStart}
          // onRowEditStop={handleRowEditStop}
          // processRowUpdate={processRowUpdate}
          // onProcessRowUpdateError={handleProcessRowUpdateError}
          // pageSize={5}
          // rowsPerPageOptions={[5]}
          // columnVisibilityModel={columnVisibilityModel}
          sx={{ bgcolor: "rgba(255, 255, 255, 1)", borderRadius: "16px", fontSize: "small" }}
          initialState={{
            sorting: {
              sortModel: [{ field: "criteria_1", sort: "desc" }],
            },
          }}
        // onColumnVisibilityModelChange={(newModel) => {
        //   newModel.id = false;
        //   setColumnVisibilityModel(newModel);
        // }}
        // components={{
        //   Toolbar: EditToolbar,
        // }}
        // componentsProps={{
        //   toolbar: { setRows, setRowModesModel },
        // }}
        // experimentalFeatures={{ newEditingApi: true }}
        />
      </Grid>
      <Grid item>
        <Dialog
          // open={open}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Peringatan</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Informasi berikut wajib diisi: tipe truk, asal, tujuan, tanggal berangkat, tanggal
              sampai, harga jual, kuantitas, dan pajak.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {/* <MDButton onClick={handleClose}>OK</MDButton> */}
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
}

export default LVInvoiceDueBreakdownSummary;
