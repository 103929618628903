import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link, useNavigate, useParams } from 'react-router-dom';

import Grid from "@mui/material/Grid";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import LVFintechDataGrid from "components/LVFintechDataGrid";


import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import LVInvoiceDataGrid from "components/LVInvoiceDataGrid";
import { createQuickPay } from "../../actions/fintechAction"; 
import { searchInvoices, emptyMessage, initInvoices } from '../../actions';
import { transformInvoices } from '../../common/Util';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 650,
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    overflow:"scroll",
    p: 4,
  };
  
const QuickPay = (props) => {
    
    const [top, setTop] = useState({
        id:-1,
        value:-1,
        label:''
    });
    const [selected, setSelected] = useState(null);
    const [arrInvId, setArrInvId] = useState([]);
    const [pengajuan, setPengajuan] = useState(0);
    const [brokerId, setBrokerId] = useState(null);
    const [topDays, setTopDays] = useState(null);
    const [showError, setShowError] = useState({
        top: false,
        fintech: false, 
        invoice: false,       
        all: false,        
    });
    const [helperMsg, setHelperMsg] = useState({
        top: 'Durasi pinjaman harus dipilih.',
        fintech: 'Fintech peminjam harus dipilih',       
        invoice: 'Invoice harus dipilih', 
        all: 'Masih terdapat informasi wajib yang kosong, silakan diperiksa kembali.',       
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const message = useSelector(
        state => {
          return state.message;
        },
        shallowEqual
    );

    const invoices = useSelector(
        state => {
          return state.invoices;
        },
        shallowEqual
    );

    const topOptions = [
        {
            id: 0,
            value: 14,
            label: 'Jatuh Tempo 14 Hari'
        },
        {
            id: 1,
            value: 21,
            label: 'Jatuh Tempo 21 Hari'
        },
        {
            id: 1,
            value: 30,
            label: 'Jatuh Tempo 30 Hari'
        },
        {
            id: 2,
            value: 45,
            label: 'Jatuh Tempo 45 Hari'
        },
        {
            id: 3,
            value: 60,
            label: 'Jatuh Tempo 60 Hari'
        },
        {
            id: 4,
            value: 75,
            label: 'Jatuh Tempo 75 Hari'
        },
        {
            id: 5,
            value: 90,
            label: 'Jatuh Tempo 90 Hari'
        },
        {
            id: 6,
            value: 105,
            label: 'Jatuh Tempo 105 Hari'
        },
        {
            id: 7,
            value: 120,
            label: 'Jatuh Tempo 120 Hari'
        },
    ];

    useEffect(() => {
        if (props.datapengajuan === undefined) {
            const dateNow = new Date();            
            dispatch(searchInvoices(
                {
                    financing_status: ["created"],
                    invoice_status: ["unpaid"],
                    due_date: [
                        ">",
                        dateNow.toISOString()
                    ],
                }
            ))
        } else {
            setArrInvId(props.datapengajuan.arrId);
        }
        return () => {
            if (props.datapengajuan === undefined) {
                dispatch(initInvoices());
            }            
        };
      }, []);

    useEffect( () => {        
        if (selected !== null) {
            setShowError({...showError, fintech: false});
        }
    },[selected]
    );

    useEffect( () => {
        if (message.status !== '') {
            dispatch(emptyMessage());
            switch(message.status) {
                case 'SUCCESS_NEW_FINANCING':
                    props.onClose();
                    navigate('/pengajuan/quickpay/'+encodeURIComponent(message.message.financing_id));                    
                    return;                
                default:
                    return;
            }        
        }
        
    },[message]
    );

    useEffect(() => {
        if ((top.id !==-1) && (selected !== null) && (arrInvId.length !== 0)) {
            setShowError({
                top: false,
                fintech: false, 
                invoice: false,
                all: false});
        }
    },[selected,top, arrInvId]
    );

    const onPilihClick = () => {
        if ((arrInvId.length !== 0)) {
            dispatch(createQuickPay(
                {
                    arrInvoiceId: arrInvId,


                }
            ));            
        } else {
            setShowError({                                
                invoice: arrInvId.length === 0,
                all: true            
            });
        }
        
    }



    const renderInvoiceView = () => {
        if (props.datapengajuan !== undefined) {
            return (
                <LVInvoiceDataGrid 
                    rows={props.datapengajuan.arrInv}
                    height={400}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    viewOnly
                />
            );
        } else {
            return (
            <LVInvoiceDataGrid 
                rows={transformInvoices(invoices)}
                height={400}
                pageSize={5}
                rowsPerPageOptions={[5]}
                viewOnly

                radioSelection
                onSelectionModelChange={(e) => {
                    if (e.length !== 0) {
                        setShowError({...showError, invoice: false});
                    }
                    var amount = 0;
                    invoices.map((item)=> {
                        if (e.includes(item.id)) {
                            amount = amount + item.subtotal;
                        }
                    });
                    setPengajuan(amount);
                    setArrInvId(e);
                }                
                }
            />
            );
            
        }
        
    }

    const renderPengajuan = () => {
        if (props.datapengajuan !== undefined) {
            return (
                Number(props.datapengajuan.pengajuan).toLocaleString("id-ID", {style:"currency", currency:"IDR"})
            );
        } else {
            return (
                Number(pengajuan).toLocaleString("id-ID", {style:"currency", currency:"IDR"})
            );
        }
        
    }

    return (
        <div>
            <MDBox sx={style}>
                <Grid container direction="column" justifyContent="space-around">
                    <Grid item>
                        <MDTypography variant="title" fontWeight="medium">
                            Pengajuan Pembayaran Cepat
                        </MDTypography>
                    </Grid>
                    <Grid item>
                        <MDTypography my={2} sx={{ fontSize:14}} fontWeight="medium">
                            Pengajuan: {renderPengajuan()}
                        </MDTypography>

                        <MDTypography color="error" px={1} sx={{fontSize:10}}>{showError.top?helperMsg.top:''} </MDTypography>
                        <MDBox my={2}>
                            {renderInvoiceView()}
                            <MDTypography color="error" px={1} sx={{fontSize:10}}>{showError.invoice?helperMsg.invoice:''} </MDTypography>
                        </MDBox>                        
                    </Grid>
                  
                    <Grid item>
                        <Grid container justifyContent="flex-end" alignItems="right" spacing={3} px={3} mt={1}>
                            <MDTypography color="error" px={1} sx={{fontSize:10}}>{showError.all?helperMsg.all:''} </MDTypography>    
                                <Grid item>
                                <MDButton variant="contained" color="info" mt={2} size="small" onClick={onPilihClick}>
                                    Pilih
                                </MDButton>
                            </Grid>
                            <Grid item>
                                <MDButton variant="contained" color="error" mt={2} size="small" onClick={props.onClose}>
                                    Batal
                                </MDButton>
                            </Grid>                    
                        </Grid>
                    </Grid>
                </Grid>
                
                
            </MDBox>
        </div>
    );
};

export default QuickPay;