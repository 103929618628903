import React, { useState, useEffect, useRef } from "react";

import MDBox from "components/MDBox";
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { getFintechRequirement } from "actions/borrowerFintechAction";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SimpleDashboardNavbar from "examples/Navbars/SimpleDashboardNavbar";
import MDTypography from "components/MDTypography";
import LVFileInput from "components/LVFileInput";
import Grid from "@mui/material/Grid";
import { getFintechCompanyDetail, initFintechRequirement } from "actions/borrowerFintechAction";
import { useLocation } from 'react-router-dom';
import { saveTransporterFintechKycDocument } from "actions/borrowerFintechAction";
import { getTransporterFintechKycDocuments } from "actions/borrowerFintechAction";
import serverList from "apis/serverList";
import { deleteTransporterFintechKycDocument } from "actions/borrowerFintechAction";
import { convertNumberToMonth } from "common/month";
import { getTransporterFintechKycProgress } from "actions/borrowerFintechAction";
import { snakeCaseToSentenceCase } from "common/Object"

const FinancingRegistrationFintech = () => {

  const location = useLocation();
  const dispatch = useDispatch();

  const { id } = useParams();
  const [formData, setFormData] = useState({});
  const fileInputRefs = useRef({});

  const [isLoading, setIsLoading] = useState({});

  useEffect(() => {
    dispatch(initFintechRequirement(id));
  }, [location]);

  const [arrangedFintechRequirement, setArrangedFintechRequirement] = useState([]);
  // const [fintechRequirement, setFintechRequirement] = useState([]);

  const fintechRequirement = useSelector(
    state => {
      return state.fintechRequirement.message;
    },
    shallowEqual
  );

  const companyFintech = useSelector(
    state => {
      return state.companyFintech.message;
    },
    shallowEqual
  );

  const transporterFintechKycDocument = useSelector(
    state => {
      return state.transporterFintechKycDocument.message;
    },
    shallowEqual
  )

  const transporterFintechKycProgress = useSelector(
    state => {
      return state.transporterFintechKycProgress.message;
    },
    shallowEqual
  )

  const message = useSelector(
    state => {
      return state.message;
    },
    shallowEqual
  )

  useEffect(() => {
    if (message.status == "SUCCESS_SAVE_KYC_DOCUMENT") {
      dispatch(getTransporterFintechKycDocuments(id));
    }
    if (message.status == "SUCCESS_DELETE_KYC_DOCUMENT") {
      dispatch(getTransporterFintechKycDocuments(id));
    }
  }, [message]);

  useEffect(() => {
    setFormData({});
    Array.isArray(transporterFintechKycDocument) && transporterFintechKycDocument.map((item) => {
      setFormData((prev) => ({
        ...prev, [item.type]: {
          "srcFile": serverList.company + item.url,
          "srcImage": "pdf",
          "type": item.type,
          "id": item.id,
          "status": item.status,
        }
      }));
    });
    setIsLoading({});

  }, [transporterFintechKycDocument, fintechRequirement]);

  useEffect(() => {
    dispatch(getFintechRequirement(id));
    dispatch(getFintechCompanyDetail(id));
    dispatch(getTransporterFintechKycDocuments(id));
    dispatch(getTransporterFintechKycProgress(id));
  }, []);

  useEffect(() => {
    let arrangedFintechRequirement = arrangeFintechRequirement(fintechRequirement);
    setArrangedFintechRequirement(arrangedFintechRequirement);

  }, [fintechRequirement])

  const arrangeFintechRequirement = (fintechRequirement) => {
    if (!fintechRequirement) { return; }
    return fintechRequirement.sort();
  }

  const handleFieldChange = (fieldLabel, value) => {
    dispatch(saveTransporterFintechKycDocument(
      {
        "assetFile": value,
        "assetName": fieldLabel,
        "fintechID": companyFintech.id
      }
    ));
    setIsLoading((prev) => ({
      ...prev, [fieldLabel]: true
    }));
  };

  const onDeleteFile = (fieldLabel, id, fintechID) => {
    dispatch(deleteTransporterFintechKycDocument(id, fintechID));
    clearFileInput();
    setIsLoading((prev) => ({
      ...prev, [fieldLabel]: true
    }));
  };

  const handleSubmit = () => {
    return;
  };

  const getKycStatus = (fintechRequirement, transporterFintechKycProgress) => {
    if (!transporterFintechKycProgress || !fintechRequirement) {
      return;
    }

    let status = ``;
    if (transporterFintechKycProgress.document < 100 && fintechRequirement.length <= 0) {
      status = `Menunggu Persetujuan`;
    } else if (transporterFintechKycProgress.document < 100 && fintechRequirement.length > 0) {
      status = `Pengumpulan Dokumen`;
    } else if (transporterFintechKycProgress.document == 100 && transporterFintechKycProgress.approval < 100) {
      status = `Menunggu Persetujuan`;
    } else {
      status = `Sudah Disetujui`;
    }

    return status;
  }

  const getKycStatusNotification = (fintechRequirement, transporterFintechKycProgress) => {
    if (!transporterFintechKycProgress || !fintechRequirement) {
      return;
    }

    let status = ``;
    if (transporterFintechKycProgress.document < 100 && fintechRequirement.length <= 0) {
      status = `Tidak ada data tambahan yang dibutuhkan, sedang menunggu persetujuan`;
    } else if (transporterFintechKycProgress.document < 100 && fintechRequirement.length > 0) {
      status = `Masih terdapat data yang belum lengkap. Mohon unggah kelengkapan data di bawah ini.`;
    } else if (transporterFintechKycProgress.document == 100 && transporterFintechKycProgress.approval < 100) {
      status = `Menunggu Persetujuan`;
    } else {
      status = `Sudah Disetujui`;
    }

    return status;
  }




  useEffect(() => {

  }, [formData]);

  useEffect(() => {

  }, [companyFintech]);

  const clearFileInput = () => {
    if (!fileInputRefs.current) {
      return;
    }

    fileInputRefs.current = {};
    Object.keys(fileInputRefs.current).forEach((key) => {
      if (fileInputRefs.current[key] && fileInputRefs.current[key].value) {
        fileInputRefs.current[key].value = '';
      }
    });
  }

  const getStatusWord = (ref, status) => {
    if (!ref && status == "") {
      return "Silahkan Unggah Dokumen";
    }

    if (ref && (!status || status === "")) {
      return "Silahkan Unggah Dokumen";
    }

    if (status == "created") {
      return "Menunggu Persetujuan";
    } else if (status == "approved") {
      return "Sudah Disetujui";
    } else if (status == "rejected") {
      return "Ditolak";
    }
  }

  const convertFieldToName = (field) => {
    if (field.startsWith("financial_report")) {
      const year = field.match(/_([0-9]{4})/)[1];
      return `Financial Report ${year}`;
    }
    if (field.startsWith("bank_statement")) {
      const month = convertNumberToMonth(field.match(/_([0-9]{2})_/)[1]);
      const year = field.match(/_([0-9]{4})/)[1];
      return `Bank Statement ${month} ${year}`;
    }

    if (field.startsWith("ap_aging")) {
      // const month = convertNumberToMonth(field.match(/_([0-9]{2})_/)[1]);
      // const year = field.match(/_([0-9]{4})/)[1];
      return `AP Aging`;
    }

    if (field.startsWith("ar_aging")) {
      // const month = convertNumberToMonth(field.match(/_([0-9]{2})_/)[1]);
      // const year = field.match(/_([0-9]{4})/)[1];
      return `AR Aging`;
    }
    if (field.startsWith("agreement")) {
      return `Agreement`;
    }

    return snakeCaseToSentenceCase(field);
  }

  return (
    <DashboardLayout>
      <SimpleDashboardNavbar name={`Detail Pendanaan: ${companyFintech?.name}`} />
      <MDBox
        sx={{
          bgcolor: "rgba(255, 255, 255, 1)",
          borderRadius: 2,
          height: "100%",
          mt: 5,
          pt: 3,
          pb: 5,
          boxShadow: "10px 15px 27px -28px #1A73E8",
        }}
      >

        <MDBox
          pb={2}
          mb={2}
          px={5}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ borderBottom: "3px solid #e1e1e1" }}
        >
          <MDTypography sx={{ fontSize: "12px" }}>
            {getKycStatusNotification(fintechRequirement, transporterFintechKycProgress)}
          </MDTypography>
          <MDBox textAlign="right">
            <MDTypography variant="h6" fontWeight="bold">
              Status Pengajuan Pendanaan
            </MDTypography>
            <MDTypography
              sx={{
                fontSize: "12px",
                display: "inline-block",
                padding: "5px 10px",
                borderRadius: "12px",
                backgroundColor: "#E3F2FD",
                color: "#1E88E5",
                marginTop: "8px",
              }}
            >
              {getKycStatus(fintechRequirement, transporterFintechKycProgress)}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox display="flex" pl={5}>
          <MDBox
            width="15%"
            pr={3}
            pt={5}
            sx={{ borderRight: "2px solid #e0e0e0" }}
          >
            <MDBox
              display="flex"
              mb={5}
            >
              <MDTypography variant="h6" fontWeight="regular">
                Data Tambahan
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox width="85%" pr={3} pt={5} pl={5}>
            <Grid container spacing={2}>
              {arrangedFintechRequirement && arrangedFintechRequirement.length > 0 ? arrangedFintechRequirement.map((item) => (
                <Grid item sx={{ minWidth: "50%" }}
                  xs="auto"
                  // xs={12} 
                  // md={6} sm={12}
                  key={item}>
                  <Grid container>
                    <Grid item xs="auto"
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        height: '100%',
                      }}>
                      <LVFileInput
                        onChangeFile={(e) => handleFieldChange(item, e.target.files[0])}
                        srcImage={formData[item]?.srcImage || null}
                        srcFile={formData[item]?.srcFile || null}
                        height={200}
                        width={240}
                        fontSize={10}
                        id={formData[item]?.type || null}
                        type={formData[item]?.type || null}
                        text1="Unggah Lampiran"
                        text2="Maksimal ukuran 5MB PDF"
                        text3=""
                        title={convertFieldToName(item)}
                        titleFontSize={12}
                        accept=".pdf"
                        enableLoading={true}
                        isLoading={isLoading[item]}
                        refinput={(ref) => (fileInputRefs.current[item] = ref)}
                        deleteFile={() => onDeleteFile(item, formData[item]?.id, id)}
                      />
                    </Grid>
                    <Grid item ml={2}>
                      <MDBox
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'flex-end',
                          height: '100%',
                        }}>
                        <MDTypography
                          variant="body3"
                          px={4}
                          py={2}
                          mb={1}
                          sx={{
                            bgcolor: "rgb(255, 255, 255)",
                            borderRadius: "16px",
                            border: "1px solid #e1e1e1",
                            fontWeight: "bold",
                            fontSize: "12px"
                          }}
                        >
                          {getStatusWord(fileInputRefs.current[item], formData[item]?.status)}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                  </Grid>
                </Grid>
              )) :
                (<Grid item>
                  <MDTypography
                    sx={{ fontSize: "14px" }}>
                    Belum ada kebutuhan dokumen tambahan
                  </MDTypography>
                </Grid>)}
            </Grid>
          </MDBox>
        </MDBox>
      </MDBox>
    </DashboardLayout >
  );
}


export default FinancingRegistrationFintech;
