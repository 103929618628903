/* eslint-disable no-fallthrough */

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { resetPassword } from "actions/authAction";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { emptyMessage } from "actions";

function Basic() {
  const location = useLocation();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showError, setShowError] = useState({
    username: false,
    password: false,
  });
  const [errorMsg, setErrorMsg] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onConfirmPasswordBlur = () => {
    if (confirmPassword.trim() !== password) {
      setShowError({ ...showError, confirmPassword: true });
    }
  };

  useEffect(() => {
    if (confirmPassword !== "") {
      setShowError({ ...showError, confirmPassword: false });
    }
  }, [confirmPassword]);

  const onPasswordBlur = () => {
    if (password.trim() === "") {
      setShowError({ ...showError, password: true });
    }
  };

  useEffect(() => {
    if (password !== "") {
      setShowError({ ...showError, password: false });
    }
  }, [password]);

  const onSave = () => {
    const urlparams = new URLSearchParams(location.search);
    dispatch(
      resetPassword({
        password,
        token: urlparams.get("token"),
        email: urlparams.get("username"),
      })
    );
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const message = useSelector((state) => state.message, shallowEqual);

  useEffect(() => {
    if (message.status !== "") {
      dispatch(emptyMessage());
      switch (message.status) {
        case "APPROVE_UPDATE_PASSWORD":
          navigate("/signin");
          return;
        case "FAILED_UPDATE_PASSWORD":
          setErrorMsg(message.message);
          return;
        default:
          return;
      }
    }
  }, [message]);

  return (
    <BasicLayout>
      <MDBox pt={20} pb={3} px={3} textAlign="center">
        <MDTypography variant="h4" fontWeight="medium" color="dark" mt={5}>
          Buat Password
        </MDTypography>
        <MDTypography variant="h6" fontWeight="light" color="dark" mt={1} mb={5}>
          Silahkan masukkan password baru Anda.
        </MDTypography>
        <MDBox component="form" role="form" textAlign="left">
          <MDBox mb={2}>
            <MDInput
              type={showPassword ? "text" : "password"}
              label="Password"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onBlur={onPasswordBlur}
              error={showError.password}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type={showConfirmPassword ? "text" : "password"}
              label="Konfirmasi Password"
              fullWidth
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              onBlur={onConfirmPasswordBlur}
              error={showError.confirmPassword}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownConfirmPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
            />
          </MDBox>
          <MDBox mt={4} mb={1}>
            <MDTypography color="error" textAlign="center" px={1} mb={2} sx={{ fontSize: 10 }}>
              {errorMsg === "sql: no rows in result set"
                ? "This email is not registered"
                : errorMsg}
            </MDTypography>
            <MDButton color="primary" fullWidth onClick={onSave}>
              Simpan
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </BasicLayout>
  );
}

export default Basic;
