import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
// import withRouter from "./WithRouter";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

function AuthVerify(props) {
  const location = useLocation();

  useEffect(() => {
    const bufferToken = parseInt(process.env.REACT_APP_BUFFER_TOKEN, 10);
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      const decodedJwt = parseJwt(user.access_token);
      const expirationWithBuffer = decodedJwt.exp * 1000 - bufferToken * 60 * 1000;
      if (expirationWithBuffer < Date.now()) {
        props.openModal();
      }
    } else {

    }
  }, [location]);

  return <div />;
}
export default AuthVerify;
