/* eslint-disable import/prefer-default-export */
import axios from "axios";
import serverList from "../apis/serverList";
import authHeader from "../apis/authHeader";

export const invoiceHistory =
  ({
    id,
    invoicingId,
    financingId,
    page,
    limit,
    invoicedId,
    financingStatus,
    documentStatus,
    invoiceStatus,
  }) =>
  async (dispatch) => {
    let arrAllHistory = [];
    let arrFinal = [];
    let pagination = {};
    const invoiceAPI = axios.create({
      baseURL: serverList.invoice,
      headers: authHeader(),
    });

    const response = await invoiceAPI.get("/api/v1/invoices/search/pagination", {
      params: {
        id,
        invoicing_company_id: invoicingId,
        invoiced_company_id: invoicedId,
        financing_id: financingId,
        financing_status: financingStatus,
        document_status: documentStatus,
        invoice_status: invoiceStatus,
        limit: limit || 10,
        page,
      },
    });
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }

    if (response.data.status === "OK") {
      arrAllHistory = response.data.data.result;
      pagination = response.data.data.pagination;
    }

    let no = 0;

    arrFinal = arrAllHistory.map((isi) => {
      let returnVal = {};
      const { status } = isi;
      const invoiceStatus = (status.find((item) => item.type === "invoice") || {}).status;
      const documentStatus = (status.find((item) => item.type === "document") || {}).status;
      const financingStatus = (status.find((item) => item.type === "financing") || {}).status;
      no += 1;
      returnVal = {
        ...isi,
        no,
        id: isi.id,
        idInvoice: isi.number,
        idOrderPenjualan: isi.sale_order_id,
        customer: isi.invoiced_company,
        invoiceStatus,
        documentStatus,
        financingStatus,
        createdAt: isi.issued_date,
        rangeDate: isi.due_date,
        total: isi.total,
      };
      return returnVal;
    });

    dispatch({ type: "USER_INVOICE_HISTORY", payload: arrFinal });
    dispatch({ type: "USER_INVOICE_HISTORY_PAGINATION", payload: response.data.data.pagination });
  };
