import { useState, useEffect } from "react";
import { connect, useSelector, useDispatch, shallowEqual } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SimpleDashboardNavbar from "examples/Navbars/SimpleDashboardNavbar";
import LVRiwayatKerjasama from "components/LVRiwayatKerjasama";
import ReactGA from "react-ga4";
import { fetchAvailablePendana } from "../../actions/daftarPendanaAction";
import { getCompany, getCompanyKYCStatus } from "../../actions/companyAction";
import { fetchRiwayatKerjasama } from "actions/riwayatKerjasamaAction";
import { fetchCustomerDetail } from "actions/kycPeminjamAction";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { fetchFintechRiwayatKerjasama } from "actions/riwayatKerjasamaAction";
import PersetujuanPeminjamModal from "modals/persetujuanPeminjamModal";
import { rejectRiwayatKerjasama } from "actions/calonPeminjamAction";
import { emptyMessage } from "actions";

function RiwayatKerjasama() {
  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [idrelation, setidrelation] = useState(null);
  const [transporterId, setTransporterId] = useState(null);
  const [fintechName, setFintechName] = useState("");
  const [fintechId, setFintechId] = useState(null);



  const dispatch = useDispatch();
  const params = useParams();

  const message = useSelector((state) => {
    return state.message;
  }, shallowEqual);

  const anFintechCompanyRelation = useSelector(
    (state) => state.anFintechCompanyRelation,
    shallowEqual
  );
  const customerDetail = useSelector((state) => {
    return state.kycCompanyDetail;
  }, shallowEqual);

  useEffect(() => {
    dispatch(fetchFintechRiwayatKerjasama(params.id));
  }, []);

  const handleRegister = (e) => {
    setidrelation(e);
    setOpenApproveModal(true);
  };

  const handleReject = (e) => {
    setidrelation(e);
    setOpenRejectDialog(true);
  };

  const onDialogRejectAgree = () => {
    dispatch(rejectRiwayatKerjasama(idrelation));
  };

  useEffect(() => {
    const id = decodeURIComponent(params.id);
    if (id !== null) {
      dispatch(fetchCustomerDetail(id));
    }
  }, []);

  useEffect(() => {
    const id = decodeURIComponent(params.id);
    dispatch(emptyMessage());
    switch (message.status) {
      case "APPROVE_RIWAYAT_KERJASAMA":
        if (id !== null) {
          dispatch(fetchFintechRiwayatKerjasama(id));
          setOpenApproveModal(false);
        }
        return;
      case "REJECT_RIWAYAT_KERJASAMA":
        if (id !== null) {
          dispatch(fetchFintechRiwayatKerjasama(id));
          setOpenApproveModal(false);
        }
        return;
      default:
        return;
    }
  }, [message]);

  return (
    <DashboardLayout>
      <SimpleDashboardNavbar name="Riwayat Kerjasama" />
      <MDBox
        mt={3}
        mb={-2}
        p={2}
        sx={{
          bgcolor: "rgba(255, 255, 255, 1)",
          borderRadius: "16px",
          border: "1px solid #e1e1e1",
          fontSize: "small",
          boxShadow: "10px 15px 27px -28px #1A73E8",
        }}
      >
        <MDTypography sx={{ fontSize: 16, fontWeight: "bold" }}>
          {customerDetail.type} {customerDetail.name}
        </MDTypography>
      </MDBox>
      <LVRiwayatKerjasama
        rows={anFintechCompanyRelation}
        onReject={(e) => handleReject(e)}
        onRegister={(e) => handleRegister(e)}

        keyword={(keyword) => setQueryParameter({ ...queryParameter, keyword })}
        resetFilter={(e) => resetFilter(e)}
      />
      <PersetujuanPeminjamModal
        open={openApproveModal}
        relation
        idrelation={idrelation}
        onClose={(e) => {
          setOpenApproveModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      />
      <Dialog
        open={openRejectDialog}
        onClose={() => setOpenRejectDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Tolak Riwayat Kerjasama Peminjam?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Apakah Anda yakin ingin menolak riwayat kerjasama peminjam ini?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton
            onClick={() => setOpenRejectDialog(false)}
            variant="outlined"
            color="info"
            size="small"
          >
            Batal
          </MDButton>
          <MDButton
            onClick={onDialogRejectAgree}
            variant="contained"
            color="error"
            size="small"
            autoFocus
          >
            Tolak
          </MDButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default RiwayatKerjasama;
