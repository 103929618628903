import serverList from "../apis/serverList";
import axios from "axios";
import authHeader from "../apis/authHeader";
import invoiceAPI from "apis/invoice";

export const fetchFintechList = () => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI.get("/api/v1/companies/fintech/name");
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  if (response.data.status === "OK") {
    dispatch({ type: "FETCH_FINTECH_LIST", payload: response.data.data });
  }
};

export const createFinRequest =
  ({ arrInvoiceId, daysTOP, fintechId }) =>
    async (dispatch) => {
      const financingAPI = axios.create({
        baseURL: serverList.financing,
        headers: authHeader(),
      });
      const response = await financingAPI
        .post("/api/v1/financing", {
          invoice_id: arrInvoiceId,
          term_of_payment: daysTOP,
          fintech_id: fintechId,
        })
        .catch((e) => {
          dispatch({
            type: "ERROR",
            payload: { status: "FAIL_NEW_FINANCING", message: e },
          });
        });
      if (response !== undefined) {
        if (response.status === 200) {
          if (response.data.status === "OK") {
            dispatch({
              type: "CREATE_NEW_FINANCING",
              payload: { status: "SUCCESS_NEW_FINANCING", message: response.data.data },
            });
          } else {
            dispatch({
              type: "ERROR",
              payload: { status: "FAIL_NEW_FINANCING", message: "Fail to create financing" },
            });
          }
        } else if (message.status === 401) {
          dispatch({
            type: "ERROR",
            payload: { status: "TOKEN_EXPIRE", message: response.data.message },
          });
        } else {
          dispatch({
            type: "ERROR",
            payload: { status: "FAIL_NEW_FINANCING", message: response.data.message },
          });
        }
      }
    };

export const createFinSORequest =
  ({ orderPenjualan, daysTOP, fintechId }) =>
    async (dispatch) => {
      const financingAPI = axios.create({
        baseURL: serverList.financing,
        headers: authHeader(),
      });
      const response = await financingAPI
        .post("/api/v1/financing/purchase/order", {
          sale_order_id: orderPenjualan,
          term_of_payment: daysTOP,
          fintech_id: fintechId,
        })
        .catch((e) => {
          dispatch({
            type: "ERROR",
            payload: { status: "FAIL_NEW_FINANCING", message: e },
          });
        });
      if (response !== undefined) {
        if (response.status === 200) {
          if (response.data.status === "OK") {
            dispatch({
              type: "CREATE_NEW_FINANCING",
              payload: { status: "SUCCESS_NEW_FINANCING", message: response.data.data },
            });
          } else {
            dispatch({
              type: "ERROR",
              payload: { status: "FAIL_NEW_FINANCING", message: response.data.message },
            });
          }
        } else if (message.status === 401) {
          dispatch({
            type: "ERROR",
            payload: { status: "TOKEN_EXPIRE", message: response.data.message },
          });
        } else {
          dispatch({
            type: "ERROR",
            payload: { status: "FAIL_NEW_FINANCING", message: response.data.message },
          });
        }
      }
    };

export const createQuickPay =
  ({ arrInvoiceId, daysTOP, brokerId }) =>
    async (dispatch) => {
      const financingAPI = axios.create({
        baseURL: serverList.financing,
        headers: authHeader(),
      });
      const response = await financingAPI
        .post("/api/v1/financing/quickpay", {
          invoice_id: arrInvoiceId,
          term_of_payment: daysTOP,
          broker_company_id: brokerId,
        })
        .catch((e) => {
          dispatch({
            type: "ERROR",
            payload: { status: "FAIL_NEW_FINANCING", message: e },
          });
        });
      if (response !== undefined) {
        if (response.status === 200) {
          if (response.data.status === "OK") {
            dispatch({
              type: "CREATE_NEW_FINANCING",
              payload: { status: "SUCCESS_NEW_FINANCING", message: response.data.data },
            });
          } else {
            dispatch({
              type: "ERROR",
              payload: { status: "FAIL_NEW_FINANCING", message: response.data.message },
            });
          }
        } else if (message.status === 401) {
          dispatch({
            type: "ERROR",
            payload: { status: "TOKEN_EXPIRE", message: response.data.message },
          });
        } else {
          dispatch({
            type: "ERROR",
            payload: { status: "FAIL_NEW_FINANCING", message: response.data.message },
          });
        }
      }
    };

export const fetchPengajuanList = () => async (dispatch) => {
  const financingAPI = axios.create({
    baseURL: serverList.financing,
    headers: authHeader(),
  });
  const response = await financingAPI.get("/api/v1/financing/list").catch((e) => { });
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  if (response.status === 200) {
    if (response.data.status === "OK") {
      dispatch({
        type: "FETCH_PENGAJUAN",
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: "ERROR",
        payload: { status: "FAIL_FETCH_PENGAJUAN", message: "Fail to get financing list" },
      });
    }
  }
};

export const fetchPengajuanInvoiceList = () => async (dispatch) => {
  const financingAPI = axios.create({
    baseURL: serverList.financing,
    headers: authHeader(),
  });
  const response = await financingAPI.get("/api/v1/financing/invoice/list").catch((e) => { });
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  if (response.status === 200) {
    if (response.data.status === "OK") {
      dispatch({
        type: "FETCH_PENGAJUAN",
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: "ERROR",
        payload: { status: "FAIL_FETCH_PENGAJUAN", message: "Fail to get financing list" },
      });
    }
  }
};

export const fetchPengajuanOrderList = () => async (dispatch) => {
  const financingAPI = axios.create({
    baseURL: serverList.financing,
    headers: authHeader(),
  });
  const response = await financingAPI
    .get("/api/v1/financing/purchase/quickpay/list")
    .catch((e) => { });
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  if (response.status === 200) {
    if (response.data.status === "OK") {
      dispatch({
        type: "FETCH_PENGAJUAN",
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: "ERROR",
        payload: { status: "FAIL_FETCH_PENGAJUAN", message: "Fail to get financing list" },
      });
    }
  }
};

export const fetchPengajuanQPList = () => async (dispatch) => {
  const financingAPI = axios.create({
    baseURL: serverList.financing,
    headers: authHeader(),
  });
  const response = await financingAPI.get("/api/v1/supplier/financing/list").catch((e) => { });
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  if (response.status === 200) {
    if (response.data.status === "OK") {
      dispatch({
        type: "FETCH_PENGAJUAN",
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: "ERROR",
        payload: { status: "FAIL_FETCH_PENGAJUAN", message: "Fail to get financing list" },
      });
    }
  }
};

export const fetchPersetujuanList = () => async (dispatch) => {
  const financingAPI = axios.create({
    baseURL: serverList.financing,
    headers: authHeader(),
  });
  const response = await financingAPI.get("/api/v1/fintech/financing/list").catch((e) => { });
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  if (response.status === 200) {
    if (response.data.status === "OK") {
      dispatch({
        type: "FETCH_PERSETUJUAN",
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: "ERROR",
        payload: { status: "FAIL_FETCH_PERSETUJUAN", message: "Fail to get financing list" },
      });
    }
  }
};

export const fetchSearchFinancing = (input) => async (dispatch) => {
  const financingAPI = axios.create({
    baseURL: serverList.financing,
    headers: authHeader(),
  });
  const response = await financingAPI
    .get("/api/v1/fintech/financing/search", {
      params: {
        page: input.page,
        order_by: input.orderBy,
        order: input.order,
        keyword: input.keyword,
        limit: input.limit,
        status: input.status,
        term_of_payment: input.termOfPayment,
        type: input.type,
        invoicing_company: input.invoicingCompany,
      },
    })
    .catch((e) => { });
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  if (response.status === 200) {
    if (response.data.status === "OK") {
      dispatch({
        type: "FETCH_FINANCING_LIST",
        payload: response.data.data.result,
      });
      dispatch({
        type: "FETCH_FINANCING_LIST_PAGINATION",
        payload: response.data.data.pagination,
      });
    } else {
      dispatch({
        type: "ERROR",
        payload: { status: "FAIL_FETCH_PERSETUJUAN", message: "Fail to get financing list" },
      });
    }
  }
};

export const fetchPersetujuanQPList = () => async (dispatch) => {
  const financingAPI = axios.create({
    baseURL: serverList.financing,
    headers: authHeader(),
  });
  const response = await financingAPI.get("/api/v1/contractor/financing/list").catch((e) => { });
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  if (response.status === 200) {
    if (response.data.status === "OK") {
      dispatch({
        type: "FETCH_PERSETUJUAN",
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: "ERROR",
        payload: { status: "FAIL_FETCH_PERSETUJUAN", message: "Fail to get financing list" },
      });
    }
  }
};

export const fetchContractorSearchFinancing = (input) => async (dispatch) => {
  const financingAPI = axios.create({
    baseURL: serverList.financing,
    headers: authHeader(),
  });
  const response = await financingAPI
    .get("/api/v1/contractor/financing/search", {
      params: {
        page: input.page,
        order_by: input.orderBy,
        order: input.order,
        keyword: input.keyword,
        limit: input.limit,
        status: input.status,
        term_of_payment: input.termOfPayment,
        type: input.type,
        invoicing_company: input.invoicingCompany,
      },
    })
    .catch((e) => { });
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  if (response.status === 200) {
    if (response.data.status === "OK") {
      dispatch({
        type: "FETCH_FINANCING_LIST",
        payload: response.data.data.result,
      });
      dispatch({
        type: "FETCH_FINANCING_LIST_PAGINATION",
        payload: response.data.data.pagination,
      });
    } else {
      dispatch({
        type: "ERROR",
        payload: { status: "FAIL_FETCH_PERSETUJUAN", message: "Fail to get financing list" },
      });
    }
  }
};

export const fetchFintechSearchQuickpay = (input) => async (dispatch) => {
  const financingAPI = axios.create({
    baseURL: serverList.financing,
    headers: authHeader(),
  });
  const response = await financingAPI
    .get("/api/v1/fintech/financing/quickpay/search", {
      params: {
        page: input.page,
        order_by: input.orderBy,
        order: input.order,
        keyword: input.keyword,
        limit: input.limit,
        status: input.status,
        term_of_payment: input.termOfPayment,
        type: input.type,
        invoicing_company: input.invoicingCompany,
      },
    })
    .catch((e) => { });
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  if (response.status === 200) {
    if (response.data.status === "OK") {
      dispatch({
        type: "FETCH_FINANCING_LIST",
        payload: response.data.data.result,
      });
      dispatch({
        type: "FETCH_FINANCING_LIST_PAGINATION",
        payload: response.data.data.pagination,
      });
    } else {
      dispatch({
        type: "ERROR",
        payload: { status: "FAIL_FETCH_PERSETUJUAN", message: "Fail to get financing list" },
      });
    }
  }
};

export const approveFinancing =
  ({ financing_id, term_of_payment, interest_base, interest_annum, other, approved_amount }) =>
    async (dispatch) => {
      const financingAPI = axios.create({
        baseURL: serverList.financing,
        headers: authHeader(),
      });
      const response = await financingAPI
        .put("/api/v1/fintech/financing/approval", {
          financing_id: financing_id,
          term_of_payment: term_of_payment,
          interest_base: interest_base,
          interest_annum: interest_annum,
          approved_amount: approved_amount,
          other: other,
        })
        .catch((e) => {
          dispatch({
            type: "ERROR",
            payload: { status: "FAIL_APPROVE_FINANCING", message: e.response.data.message },
          });
        });


      if (response.status === 401) {
        dispatch({
          type: "ERROR",
          payload: { status: "TOKEN_EXPIRE", message: response.data.message },
        });
      }
      if (response.status === 200) {
        if (response.data.status === "OK") {
          dispatch({
            type: "APPROVE_FINANCING",
            payload: { status: "APPROVE_FINANCING", message: response.data.data },
          });
        } else {
          dispatch({
            type: "ERROR",
            payload: { status: "FAIL_APPROVE_FINANCING", message: e.response.data.message },
          });
        }
      }
    };

export const approveFinancingPurchase =
  ({ financing_id, term_of_payment, interest_base, interest_annum, other, approved_amount }) =>
    async (dispatch) => {
      const financingAPI = axios.create({
        baseURL: serverList.financing,
        headers: authHeader(),
      });
      const response = await financingAPI
        .put("/api/v1/fintech/financing/purchase/approval", {
          financing_id: financing_id,
          term_of_payment: term_of_payment,
          interest_base: interest_base,
          interest_annum: interest_annum,
          approved_amount: approved_amount,
          other: other,
        })
        .catch((e) => { });
      if (response.status === 401) {
        dispatch({
          type: "ERROR",
          payload: { status: "TOKEN_EXPIRE", message: response.data.message },
        });
      }
      if (response.status === 200) {
        if (response.data.status === "OK") {
          dispatch({
            type: "APPROVE_FINANCING",
            payload: { status: "APPROVE_FINANCING", message: response.data.data },
          });
        } else {
          dispatch({
            type: "ERROR",
            payload: { status: "FAIL_APPROVE_FINANCING", message: "Fail to approve financing" },
          });
        }
      }
    };

export const approveFinancingQP =
  ({ financing_id }) =>
    async (dispatch) => {
      const financingAPI = axios.create({
        baseURL: serverList.financing,
        headers: authHeader(),
      });
      const response = await financingAPI
        .put("/api/v1/broker/financing/quickpay/approval", {
          financing_id: financing_id,
        })
        .catch((e) => { });
      if (response.status === 401) {
        dispatch({
          type: "ERROR",
          payload: { status: "TOKEN_EXPIRE", message: response.data.message },
        });
      }
      if (response.status === 200) {
        if (response.data.status === "OK") {
          dispatch({
            type: "APPROVE_FINANCING",
            payload: { status: "APPROVE_FINANCING", message: response.data.data },
          });
        } else {
          dispatch({
            type: "ERROR",
            payload: { status: "FAIL_APPROVE_FINANCING", message: "Fail to approve financing" },
          });
        }
      }
    };

export const rejectFinancing =
  ({ financing_id, reason }) =>
    async (dispatch) => {
      const financingAPI = axios.create({
        baseURL: serverList.financing,
        headers: authHeader(),
      });
      const response = await financingAPI
        .put("/api/v1/fintech/financing/rejection", {
          financing_id: financing_id,
          reason: reason,
        })
        .catch((e) => { });
      if (response.status === 401) {
        dispatch({
          type: "ERROR",
          payload: { status: "TOKEN_EXPIRE", message: response.data.message },
        });
      }
      if (response.status === 200) {
        if (response.data.status === "OK") {
          dispatch({
            type: "REJECT_FINANCING",
            payload: { status: "REJECT_FINANCING", message: response.data.data },
          });
        } else {
          dispatch({
            type: "ERROR",
            payload: { status: "FAIL_REJECT_FINANCING", message: "Fail to reject financing" },
          });
        }
      }
    };

export const fundTransfer =
  ({ financing_id, file }) =>
    async (dispatch) => {
      const financingAPI = axios.create({
        baseURL: serverList.financing,
        headers: authHeader(),
      });
      var formData = new FormData();
      formData.append("file", file);
      formData.append("id", financing_id);
      const response = await financingAPI
        .put("/api/v1/fintech/financing/funding", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch((e) => { });
      if (response.status === 401) {
        dispatch({
          type: "ERROR",
          payload: { status: "TOKEN_EXPIRE", message: response.data.message },
        });
      }
      if (response.status === 200) {
        if (response.data.status === "OK") {
          dispatch({
            type: "FUND_TRANSFER",
            payload: { status: "FUND_TRANSFER", message: response.data.data },
          });
        } else {
          dispatch({
            type: "ERROR",
            payload: { status: "FAIL_FUND_TRANSFER", message: "Fail to transfer fund" },
          });
        }
      }
    };

export const fundTransferQP =
  ({ financing_id, file }) =>
    async (dispatch) => {
      const financingAPI = axios.create({
        baseURL: serverList.financing,
        headers: authHeader(),
      });
      var formData = new FormData();
      formData.append("file", file);
      formData.append("id", financing_id);
      const response = await financingAPI
        .put("/api/v1/fintech/quickpay/funding", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch((e) => { });
      if (response.status === 401) {
        dispatch({
          type: "ERROR",
          payload: { status: "TOKEN_EXPIRE", message: response.data.message },
        });
      }
      if (response.status === 200) {
        if (response.data.status === "OK") {
          dispatch({
            type: "FUND_TRANSFER",
            payload: { status: "FUND_TRANSFER", message: response.data.data },
          });
        } else {
          dispatch({
            type: "ERROR",
            payload: { status: "FAIL_FUND_TRANSFER", message: "Fail to transfer fund" },
          });
        }
      }
    };

export const getInvoiceTaxInformation = (invoice_ids) => async (dispatch) => {
  const invoiceAPI = axios.create({
    baseURL: serverList.invoice,
    headers: authHeader(),
  });
  const response = await invoiceAPI.get("/api/v1/invoice/information/tax", {
    params: {
      "number": invoice_ids,
    },
  });
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  if (response.status === 200) {
    if (response.data.status === "OK") {
      dispatch({ type: "GET_INVOICE_TAX_INFORMATION", payload: response.data.data });
    }
  }
};

export const getFintechCompRelation = (company_id) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI.get("/api/v1/fintech/company/relation", {
    params: {
      company_id,
    },
  });
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  if (response.status === 200) {
    if (response.data.status === "OK") {
      dispatch({ type: "GET_FINTECH_COMP_RELATION", payload: response.data.data });
    }
  }
};

export const getCreditLimitSO = (sale_order_id) => async (dispatch) => {
  const financingAPI = axios.create({
    baseURL: serverList.financing,
    headers: authHeader(),
  });

  const response = await financingAPI.get("/api/v1/financing/credit/limit/summary/sale", {
    params: {
      sale_order_id,
    },
  });
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  if (response.status === 200) {
    if (response.data.status === "OK") {
      dispatch({ type: "GET_CREDIT_LIMIT_SUMMARY", payload: response.data.data });
    }
  }
};

export const getCreditLimitCompany = () => async (dispatch) => {
  const financingAPI = axios.create({
    baseURL: serverList.financing,
    headers: authHeader(),
  });

  const response = await financingAPI.get("/api/v1/financing/company/credit/limit/usage");
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  if (response.status === 200) {
    if (response.data.status === "OK") {
      dispatch({ type: "GET_CREDIT_LIMIT_SUMMARY", payload: response.data.data });
    }
  }
};

export const payInvoiceFinancing = (financing_id) => async (dispatch) => {
  const financingAPI = axios.create({
    baseURL: serverList.financing,
    headers: authHeader(),
  });

  const response = await financingAPI.put("/api/v1/financing/invoice/payment", {
    id: financing_id,
  });
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  if (response.status === 200) {
    if (response.data.status === "OK") {
      dispatch({
        type: "PAY_INVOICE_FINANCING",
        payload: { status: "SUCCESS_PAY_INVOICE_FINANCING", message: response.data.data },
      });
    }
  }
};

export const initCreditLimitSummary = () => {
  return {
    type: "INIT_CREDIT_LIMIT_SUMMARY",
  };
};

export const initPersetujuanList = () => {
  return {
    type: "INIT_PERSETUJUAN",
  };
};

export const initPengajuanList = () => {
  return {
    type: "INIT_PENGAJUAN",
  };
};
