import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "examples/Sidebar/styles/sidenavCollapse";

import { useMaterialUIController } from "context";
import SidebarItem from "./SidebarItem";
import colorConfigs from "./styles/colorConfigs";

function SidebarItemCollapse({ item, active }) {
  const [open, setOpen] = useState(false);
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;

  const appState = ["dashboard"];
  const user = JSON.parse(localStorage.getItem("user"));

  const sidebarState = useSelector((state) => state.sidebarState, shallowEqual);

  useEffect(() => {
    if (active) {
      setOpen(true);
    }
  }, [sidebarState]);

  const renderRoutes = item.child?.map((route, index) => {

    let rightRole = false;
    let rightBusiness = false;
    if (user) {
      user.role?.map((item) => {
        if (route.role.includes(item)) {
          rightRole = true;
        }
      });
      if (user.company) {
        if (user.company.business_type) {
          route.business.map((item) => {
            if (user.company.business_type === item) {
              rightBusiness = true;
            }
          });
        }
      }
    }

    if (rightRole && rightBusiness) {
      if (route.sidebarProps) {
        if (route.child) {
          return <SidebarItemCollapse item={route} key={index} />;
        }
        return <SidebarItem item={route} key={index} active={route.state === sidebarState} />;
      }
      return null;
    }
    return null;


  });


  return item.sidebarProps ? (
    <>
      <ListItem
        component="li"
        onClick={() => setOpen(!open)}

      >
        <MDBox
          sx={(theme) =>
            collapseItem(theme, {
              active,
              transparentSidenav,
              whiteSidenav,
              darkMode,
              sidenavColor,
            })
          }
        >
          <ListItemIcon

            sx={(theme) =>
              collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
            }
          >
            {typeof item.sidebarProps.icon === "string" ? (
              <Icon sx={(theme) => collapseIcon(theme, { active })}>{item.sidebarProps.icon}</Icon>
            ) : (
              item.sidebarProps.icon
            )}
          </ListItemIcon>
          <ListItemText

            primary={item.sidebarProps.displayText}
            sx={(theme) =>
              collapseText(theme, {
                miniSidenav,
                transparentSidenav,
                whiteSidenav,
                active,
              })
            }
          />
          {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
        </MDBox>
      </ListItem>

      <Collapse in={open} timeout="auto">
        <List>

          {renderRoutes}
        </List>
      </Collapse>
    </>
  ) : null;
}

export default SidebarItemCollapse;
