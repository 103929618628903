import { useState, useEffect } from 'react';
import { connect, useSelector, useDispatch, shallowEqual } from "react-redux";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SimpleDashboardNavbar from "examples/Navbars/SimpleDashboardNavbar";
import LVPelanggan from 'components/LVPelanggan';
import { fetchPelanggan, initPelanggan } from '../../actions';
import ReactGA from "react-ga4";
import NewCustomer from 'layouts/newcustomer';
import MDButton from 'components/MDButton';
import CustomerModal from 'modals/newCustomerModal';


const Pelanggan = () => {
    const [openCustModal, setOpenCustModal] = useState(false)

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/pelanggan", title: "Pelanggan" });
    }, []);

    const dispatch = useDispatch();

    const listPelanggan = useSelector(
        state => {
            return state.listPelanggan;
        },
        shallowEqual
    );

    useEffect(() => {
        dispatch(fetchPelanggan());
        return () => {
            dispatch(initPelanggan());
        }
    }, []);


    return (
        <DashboardLayout>
            <SimpleDashboardNavbar name="Pelanggan"/>
            <MDBox display="flex" justifyContent="space-between">
                <MDBox></MDBox>
                <MDButton
                    sx={{ mt: 5 }}
                    variant="contained"
                    color="info"
                    onClick={(e) => setOpenCustModal(true)}
                >
                    Buat Pelanggan Baru
                </MDButton>
            </MDBox>
            <LVPelanggan
                rows={listPelanggan}
            />
            <CustomerModal
                open={openCustModal}
                onClose={(e) => setOpenCustModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                mainPage
            />
        </DashboardLayout>
    );
}

export default Pelanggan;