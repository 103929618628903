/* eslint-disable react/jsx-no-useless-fragment */
import { useState, useEffect } from "react";

import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { deleteKYCDocument } from "actions/companyAction";
import { useDispatch } from "react-redux";
import { deleteRelationDocument } from "actions/companyAction";
import { approveRiwayatKerjasamaAdmin } from "actions/calonPeminjamAction";
import { rejectRiwayatKerjasamaAdmin } from "actions/calonPeminjamAction";
import serverList from "apis/serverList";
import { CircularProgress } from "@mui/material";

function LVFileInputMultiple(props) {
  const [listFile, setListFile] = useState([]);

  useEffect(() => {
    setListFile(props.srcImage);
  }, [props.srcImage]);

  const dispatch = useDispatch();
  const deleteDocs = (id) => {
    if (props.relation === true) {
      dispatch(deleteRelationDocument(id));
    } else if (props.deleteFile !== undefined) {
      props.deleteFile(id);
    } else {
      dispatch(deleteKYCDocument(id));
    }
  };

  const rejectDocs = (id) => {
    dispatch(rejectRiwayatKerjasamaAdmin(id));
  };
  const approveDocs = (id) => {
    dispatch(approveRiwayatKerjasamaAdmin(id));
  };

  return (
    <div>
      <MDTypography
        color="dark"
        align="left"
        py={0}
        mt={4}
        mb={0}
        sx={{ fontSize: props.titleFontSize ? props.titleFontSize : 12 }}
        fontWeight="reguler"
      >
        {" "}
        {props.title}
      </MDTypography>
      <MDBox
        align="center"
        direction="column"
        alignItems="center"
        py={1}
        px={2}
        sx={{
          border: "1px solid #9DB7CD",
          borderRadius: 2,
          // borderWidth: 1,
          height: "230px",
          width: "300px",
        }}
      >
        <MDBox mb={2} pr={0.2} height={props.approvable ? "100%" : "70%"} sx={{ overflow: "auto" }}>
          {listFile.map((item, i) => (
            <div key={i}>
              <MDBox
                width="100%"
                mb={1}
                pr={1}
                justifyContent="space-between"
                borderRadius="md"
                sx={{ backgroundColor: item && item.status === "rejected" ? "#FF5C5C" : "" }}
                display={item === null ? "none" : "flex"}
              >
                <MDTypography
                  hidden={item === null}
                  color={item && item.status === "rejected" ? "white" : "info"}
                  px={1}
                  fontWeight="bold"
                  sx={{ fontSize: 14 }}
                  my="auto"
                >
                  <Icon
                    sx={{
                      fontWeight: "bold",
                      pt: 0.4,
                      fontSize: "#00000",
                      display: item && item.status === "rejected" ? "" : "none",
                    }}
                  >
                    close
                  </Icon>{" "}
                  {item !== null && 'title' in item ? item.title : `${props.id}_${i + 1}`}

                </MDTypography>
                <MDBox display="flex">
                  {props.approvable ? (
                    <>
                      <MDBox display="flex" sx={{ cursor: "pointer" }}>
                        <MDBox onClick={() => approveDocs(item.id)} mr={2} hidden={item?.status !== "created"}>
                          <MDTypography variant="h6" color="info" hidden={item === null}>
                            <Icon sx={{ fontWeight: "bold", pt: 0.3 }}>check</Icon>
                          </MDTypography>
                        </MDBox>
                        <MDBox onClick={() => rejectDocs(item.id)} mr={2} hidden={item?.status !== "created"}>
                          <MDTypography variant="h6" color="error" hidden={item === null}>
                            <Icon sx={{ fontWeight: "bold", pt: 0.3 }}>close</Icon>
                          </MDTypography>
                        </MDBox>
                        <a download target="_blank" href={item !== null ? serverList.company + item.url : null}>
                          <MDTypography
                            variant="h6"
                            color={item && item.status === "rejected" ? "white" : "secondary"}
                            hidden={listFile === null}
                          >
                            <Icon sx={{ fontWeight: "bold", pt: 0.3 }}>download</Icon>
                          </MDTypography>
                        </a>
                      </MDBox>
                    </>
                  ) : (
                    <>
                      <MDBox mr={1} onClick={() => deleteDocs(item.id)} sx={{ cursor: "pointer" }}>
                        <MDTypography
                          variant="h6"
                          color={item && item.status === "rejected" ? "white" : "secondary"}
                          hidden={item === null}
                        >
                          <Icon sx={{ fontWeight: "bold", pt: 0.3 }}>delete_outline</Icon>
                        </MDTypography>
                      </MDBox>
                      <MDBox display={item === null ? "none" : "block"} sx={{ cursor: "pointer" }}>
                        <a download target="_blank" href={item !== null ? serverList.company + item.url : null}>
                          <MDTypography
                            variant="h6"
                            color={item && item.status === "rejected" ? "white" : "secondary"}
                            hidden={listFile === null}
                          >
                            <Icon sx={{ fontWeight: "bold", pt: 0.3 }}>download</Icon>
                          </MDTypography>
                        </a>
                      </MDBox>
                    </>
                  )}
                </MDBox>
              </MDBox>
            </div>
          ))}
          {props.isLoading ? <CircularProgress /> : null}
        </MDBox>

        {!props.viewOnly ? (
          <MDBox
            align="center"
            direction="column"
            alignItems="center"
            // mx={2}
            mb={2}
            sx={{
              border: "2px solid #0D6ECD",
              borderRadius: 2,
              // borderWidth: 1,
              // height: "48px",
              // width: "300px",
            }}
          >
            <MDButton
              component="label"
              disabled={props.isLoading}
              sx={{
                // height: props.height - 2,
                my: "auto",
                py: 1,
                px: 2,
                width: "100%",
                position: "relative",
                overflow: "hidden",
                justifyContent: "space-between",
              }}
            >
              <input
                type="file"
                // id={props.id}
                // ref={props.refinput}
                hidden
                // disabled={listFile !== null || props.viewOnly}
                onChange={props.onChangeFile}
                accept={props.accept}
              />
              <MDBox
                width="100%"
                position="relative"
                sx={{ justifyContent: "space-between" }}
                display="flex"
              >
                <MDBox position="absolute" display="flex" height="100%">
                  <Icon color="info" fontSize="large" sx={{ my: "auto" }}>
                    upload
                  </Icon>
                </MDBox>
                <MDBox width="100%">
                  <MDTypography
                    // hidden={listFile !== null}
                    align="center"
                    color="info"
                    // px={1}
                    sx={{ fontSize: 14, width: "100%" }}
                    fontWeight="bold"
                  >
                    Unggah Berkas
                  </MDTypography>
                </MDBox>
              </MDBox>
            </MDButton>
          </MDBox>
        ) : null}
      </MDBox>
    </div>
  );
}

export default LVFileInputMultiple;
