
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SimpleDashboardNavbar from "examples/Navbars/SimpleDashboardNavbar";
const WelcomePage = () => {
    return (
        <div>
            <DashboardLayout>
                <SimpleDashboardNavbar />
            </DashboardLayout>
        </div>
    );

};

export default WelcomePage;