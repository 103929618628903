import axios from "axios";
import serverList from "../apis/serverList";
import authHeader from "../apis/authHeader";

export const fetchOrderPenjualan = () => async (dispatch) => {
  const salesAPI = axios.create({
    baseURL: serverList.sales,
    headers: authHeader(),
  });
  const response = await salesAPI.post("/api/v1/sales/search", {
    id: [],
    order_by: "id desc",
    limit: 10,
  });
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  if (response.data.status === "OK") {
    dispatch({ type: "FETCH_ORDER_PENJUALAN", payload: response.data.data });
  }
};

export const fetchOrderPenjualanPage =
  ({ page }) =>
    async (dispatch) => {
      const salesAPI = axios.create({
        baseURL: serverList.sales,
        headers: authHeader(),
      });
      const response = await salesAPI.post("/api/v1/sales/search", {
        id: [],
        page,
        order_by: "id desc",
        limit: 10,
      });
      if (response.status === 401) {
        dispatch({
          type: "ERROR",
          payload: { status: "TOKEN_EXPIRE", message: response.data.message },
        });
      }
      if (response.data.status === "OK") {
        dispatch({ type: "FETCH_ORDER_PENJUALAN", payload: response.data.data });
      }
    };

export const getOrderPenjualan =
  ({ id }) =>
    async (dispatch) => {
      const salesAPI = axios.create({
        baseURL: serverList.sales,
        headers: authHeader(),
      });
      const response = await salesAPI.get("/api/v1/sale", {
        params: {
          id,
        },
      });
      if (response.status === 401) {
        dispatch({
          type: "ERROR",
          payload: { status: "TOKEN_EXPIRE", message: response.data.message },
        });
      }
      if (response.data.status === "OK") {
        dispatch({ type: "GET_ORDER_PENJUALAN", payload: response.data.data });
      }
    };

export const searchOrderPenjualan =
  ({ id, limit, page, order_by }) =>
    async (dispatch) => {
      const salesAPI = axios.create({
        baseURL: serverList.sales,
        headers: authHeader(),
      });
      const response = await salesAPI.post("/api/v1/fintech/sales/search", {
        id,
        limit,
        page,
        order_by,
      });
      if (response.status === 401) {
        dispatch({
          type: "ERROR",
          payload: { status: "TOKEN_EXPIRE", message: response.data.message },
        });
      }
      if (response.data.status === "OK") {
        dispatch({ type: "FETCH_ORDER_PENJUALAN", payload: response.data.data });
      }
    };

export const fetchOrderPenjualanVendor = () => async (dispatch) => {
  const salesAPI = axios.create({
    baseURL: serverList.sales,
    headers: authHeader(),
  });
  const response = await salesAPI.post("/api/v1/vendor/sales/search", {
    id: [],
    limit: 15,
    page: 1,
    order_by: "id desc",
  });
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  if (response.data.status === "OK") {
    dispatch({ type: "FETCH_ORDER_PENJUALAN", payload: response.data.data });
  }
};

export const getOrderPenjualanVendor =
  ({ id }) =>
    async (dispatch) => {
      const salesAPI = axios.create({
        baseURL: serverList.sales,
        headers: authHeader(),
      });
      const response = await salesAPI.get("/api/v1/vendor/sale", {
        params: {
          id,
        },
      });
      if (response.status === 401) {
        dispatch({
          type: "ERROR",
          payload: { status: "TOKEN_EXPIRE", message: response.data.message },
        });
      }
      if (response.data.status === "OK") {
        dispatch({ type: "GET_ORDER_PENJUALAN", payload: response.data.data });
      }
    };

export const getOrderPenjualanFintech =
  ({ id }) =>
    async (dispatch) => {
      const salesAPI = axios.create({
        baseURL: serverList.sales,
        headers: authHeader(),
      });
      const response = await salesAPI.get("/api/v1/fintech/sale", {
        params: {
          id,
        },
      });
      if (response.status === 401) {
        dispatch({
          type: "ERROR",
          payload: { status: "TOKEN_EXPIRE", message: response.data.message },
        });
      }
      if (response.data.status === "OK") {
        dispatch({ type: "GET_ORDER_PENJUALAN", payload: response.data.data });
      }
    };

export const fetchCustomerOrderPenjualan =
  ({ customer_id }) =>
    async (dispatch) => {
      const salesAPI = axios.create({
        baseURL: serverList.sales,
        headers: authHeader(),
      });
      const response = await salesAPI.get("/api/v1/vendor/customer/sale/search", {
        params: {
          customer_id,
        },
      });
      if (response.status === 401) {
        dispatch({
          type: "ERROR",
          payload: { status: "TOKEN_EXPIRE", message: response.data.message },
        });
      }
      if (response.data.status === "OK") {
        dispatch({ type: "FETCH_CUSTOMER_ORDER_PENJUALAN", payload: response.data.data });
      }
    };

export const fetchCustomerOrderPenjualanCont =
  ({ customer_id }) =>
    async (dispatch) => {
      const salesAPI = axios.create({
        baseURL: serverList.sales,
        headers: authHeader(),
      });
      const response = await salesAPI.get("/api/v1/vendor/customer/sale/search", {
        params: {
          customer_id,
        },
      });
      if (response.status === 401) {
        dispatch({
          type: "ERROR",
          payload: { status: "TOKEN_EXPIRE", message: response.data.message },
        });
      }
      if (response.data.status === "OK") {
        dispatch({ type: "FETCH_CUSTOMER_ORDER_PENJUALAN", payload: response.data.data });
      }
    };


export const customerSearchVendorSaleOrder =
  ({ vendor_id }) =>
    async (dispatch) => {
      const salesAPI = axios.create({
        baseURL: serverList.sales,
        headers: authHeader(),
      });
      const response = await salesAPI.post("/api/v1/customer/vendor/sale/search", {
        vendor_id,
      });
      if (response.status === 401) {
        dispatch({
          type: "ERROR",
          payload: { status: "TOKEN_EXPIRE", message: response.data.message },
        });
      }
      if (response.data.status === "OK") {
        dispatch({ type: "FETCH_CUSTOMER_ORDER_PENJUALAN", payload: response.data.data });
      }
    };

export const searchCustomerOrderPenjualan =
  ({ customer_id, status }) =>
    async (dispatch) => {
      const salesAPI = axios.create({
        baseURL: serverList.sales,
        headers: authHeader(),
      });
      const response = await salesAPI.post("/api/v1/vendor/customer/sale/search", {
        customer_id,
        status,
      });
      if (response.status === 401) {
        dispatch({
          type: "ERROR",
          payload: { status: "TOKEN_EXPIRE", message: response.data.message },
        });
      }
      if (response.data.status === "OK") {
        dispatch({ type: "FETCH_CUSTOMER_ORDER_PENJUALAN", payload: response.data.data });
      }
    };

export const searchVendorSaleOrderByVendor =
  ({ vendor_id, status }) =>
    async (dispatch) => {
      const salesAPI = axios.create({
        baseURL: serverList.sales,
        headers: authHeader(),
      });
      const response = await salesAPI.post("/api/v1/customer/vendor/sale/search", {
        vendor_id,
        status,
      });
      if (response.status === 401) {
        dispatch({
          type: "ERROR",
          payload: { status: "TOKEN_EXPIRE", message: response.data.message },
        });
      }
      if (response.data.status === "OK") {
        dispatch({ type: "FETCH_CUSTOMER_ORDER_PENJUALAN", payload: response.data.data });
      }
    };

export const initCustomerOrderPenjualan = () => {
  return {
    type: "INIT_CUSTOMER_ORDER_PENJUALAN",
  };
};

export const initOrderPenjualan = () => {
  return {
    type: "INIT_ORDER_PENJUALAN",
  };
};

export const initAnOrderPenjualan = () => {
  return {
    type: "INIT_AN_ORDER_PENJUALAN",
  };
};

export const putEditOrderPenjualan = (DetailOrderPenjualan) => async (dispatch) => {
  const salesAPI = axios.create({
    baseURL: serverList.sales,
    headers: authHeader(),
  });

  const message = await salesAPI.put("/api/v1/sale", DetailOrderPenjualan).catch((e) => {

    dispatch({
      type: "ERROR",
      payload: { status: "FAIL_EDIT_ORDER_PENJUALAN", message: e.response?.data?.message },
    });
  });

  if (message !== undefined) {
    if (message.status === 200) {
      if (message.data.status === "OK") {
        dispatch({
          type: "SUCCESS_EDIT_ORDER_PENJUALAN",
          payload: { status: "SUCCESS_EDIT_ORDER_PENJUALAN", message: message.data.data },
        });
      } else {
        dispatch({
          type: "ERROR",
          payload: { status: "FAIL_EDIT_ORDER_PENJUALAN", message: message.data.message },
        });
      }
    } else if (message.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: message.data.message },
      });
    } else {
      dispatch({
        type: "ERROR",
        payload: { status: "FAIL_EDIT_ORDER_PENJUALAN", message: message.data.message },
      });
    }
  }
};

export const postNewOrderPenjualan = (DetailOrderPenjualan) => async (dispatch) => {
  const salesAPI = axios.create({
    baseURL: serverList.sales,
    headers: authHeader(),
  });

  const message = await salesAPI.post("/api/v1/sale", DetailOrderPenjualan).catch((e) => {

    dispatch({
      type: "ERROR",
      payload: { status: "FAIL_NEW_ORDER_PENJUALAN", message: e.response?.data?.message },
    });
  });

  if (message !== undefined) {
    if (message.status === 200) {
      if (message.data.status === "OK") {
        dispatch({
          type: "CREATE_NEW_ORDER_PENJUALAN",
          payload: { status: "SUCCESS_NEW_ORDER_PENJUALAN", message: message.data.data },
        });
      } else {
        dispatch({
          type: "ERROR",
          payload: { status: "FAIL_NEW_ORDER_PENJUALAN", message: message.data.message },
        });
      }
    } else if (message.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: message.data.message },
      });
    } else {
      dispatch({
        type: "ERROR",
        payload: { status: "FAIL_NEW_ORDER_PENJUALAN", message: message.data.message },
      });
    }
  }
};

export const updateStatusOrderPenjualan =
  ({ arr_sale_order_id, type, status, sale_order_id }) =>
    async (dispatch) => {
      const salesAPI = axios.create({
        baseURL: serverList.sales,
        headers: authHeader(),
      });
      const response = await salesAPI
        .put("/api/v1/sale/status", {
          sale_order_id: arr_sale_order_id,
          type,
          status,
        })
        .catch((e) => {
          dispatch({
            type: "ERROR",
            payload: { status: "FAIL_UPDATE_STATUS_ORDER_PENJUALAN", message: e },
          });
        });
      if (response !== undefined) {
        if (response.status === 200) {
          if (response.data.status === "OK") {
            const response2 = await salesAPI.get("/api/v1/sale", {
              params: {
                id: sale_order_id,
              },
            });
            if (response2.data.status === "OK") {
              dispatch({ type: "GET_ORDER_PENJUALAN", payload: response2.data.data });
            }
          }
        } else if (response.status === 401) {
          dispatch({
            type: "ERROR",
            payload: { status: "TOKEN_EXPIRE", message: message.data.message },
          });
        } else {
          dispatch({
            type: "ERROR",
            payload: { status: "FAIL_UPDATE_STATUS_ORDER_PENJUALAN", message: message.data.message },
          });
        }
      }
    };

export const updateSaleDocument =
  ({ sale_order_id, type, file }) =>
    async (dispatch) => {
      const salesAPI = axios.create({
        baseURL: serverList.sales,
        headers: authHeader(),
      });
      var formData = new FormData();
      formData.append("file", file);
      formData.append("sale_order_id", sale_order_id);
      formData.append("type", type);
      const response = await salesAPI.post("/api/v1/sale/document", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response !== undefined) {
        if (response.status === 401) {
          dispatch({
            type: "ERROR",
            payload: { status: "TOKEN_EXPIRE", message: response.data.message },
          });
        }
        if (response.data.status === "OK") {
          const response = await salesAPI.get("/api/v1/sale", {
            params: {
              id: sale_order_id,
            },
          });
          if (response.data.status === "OK") {
            dispatch({ type: "GET_ORDER_PENJUALAN", payload: response.data.data });
          }
        }
      }
    };

export const deleteSaleDocument =
  ({ document_id, sale_order_id }) =>
    async (dispatch) => {
      const salesAPI = axios.create({
        baseURL: serverList.sales,
        headers: authHeader(),
      });
      const response = await salesAPI.delete("/api/v1/sale/document", {
        data: {
          document_id,
        },
      });
      if (response !== undefined) {
        if (response.status === 401) {
          dispatch({
            type: "ERROR",
            payload: { status: "TOKEN_EXPIRE", message: response.data.message },
          });
        }
        if (response.data.status === "OK") {
          const response = await salesAPI.get("/api/v1/sale", {
            params: {
              id: sale_order_id,
            },
          });
          if (response.data.status === "OK") {
            dispatch({ type: "GET_ORDER_PENJUALAN", payload: response.data.data });
          }
        }
      }
    };
