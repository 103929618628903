import React, { Component, useState, useEffect, useRef } from "react";
import { connect, useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import CurrencyExchangeRoundedIcon from "@mui/icons-material/CurrencyExchangeRounded";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import PreviewIcon from "@mui/icons-material/Preview";
import DangerousIcon from "@mui/icons-material/Dangerous";
import StopIcon from "@mui/icons-material/Stop";
import EmailIcon from "@mui/icons-material/Email";
import Tooltip from "@mui/material/Tooltip";
import Radio from "@mui/material/Radio";

import MDBox from "components/MDBox";
import { fetchInvoices } from "../../actions";
import MDTypography from "components/MDTypography";

function LVListInvoiceSettlement(props) {
  const [columnVisibilityModel, setColumnVisibilityModel] = useState();

  const invoices = props.rows;

  const columns = [
    {
      field: "idInvoice",
      headerName: "No. Invoice",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <MDTypography variant="body3">{params.value}</MDTypography>
        </Tooltip>
      ),
      width: 200,
    },
    {
      field: "customer",
      headerName: "Nama Pelanggan",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <MDTypography variant="body3">{params.value}</MDTypography>
        </Tooltip>
      ),
    },
    {
      field: "total",
      headerName: "Nominal Invoice",
      headerAlign: "left",
      align: "left",
      type: "number",
      renderCell: (params) => {
        const valueFormatted = Number(params.value).toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
        });
        return (
          <Tooltip title={valueFormatted}>
            <span>{valueFormatted}</span>
          </Tooltip>
        );
      },
      width: 160,
    },
  ];

  const newdata = {
    columns,
    rows: invoices,
  };

  // function CustomFooterProps() {
  //   return (
  //     <CustomFooter
  //       page={props.totalPage}
  //       selectPage={props.selectPage}
  //       nextPage={props.nextPage}
  //       prevPage={props.prevPage}
  //       currentPage={props.currentPage}
  //       firstPage={props.firstPage}
  //       lastPage={props.lastPage}
  //     />
  //   );
  // }

  return (
    <MDBox
      sx={{
        height: props.height,
        width: "100%",
      }}
    >
      <DataGrid
        {...newdata}
        pageSize={props.pageSize}
        rowsPerPageOptions={props.rowsPerPageOptions}
        editMode="row"
        checkboxSelection={props.checkboxSelection}
        disableSelectionOnClick
        autoHeight={props.autoHeight}
        localeText={{ noRowsLabel: props.localeText }}
        sx={{
          bgcolor: "rgba(255, 255, 255, 1)",
          borderRadius: "16px",
          fontSize: "small",
          boxShadow: props.flat ? "" : "10px 15px 27px -28px #1A73E8",
        }}
        columnVisibilityModel={columnVisibilityModel}
        isRowSelectable={(params) => params.row.statusInvoice === "unpaid"}
        onColumnVisibilityModelChange={(newModel) => {
          newModel.id = false;
          setColumnVisibilityModel(newModel);
        }}
        experimentalFeatures={{ newEditingApi: true }}
        // hideFooterPagination
        // components={{ Footer: CustomFooterProps }}
      />
    </MDBox>
  );
}

export default LVListInvoiceSettlement;
