/* eslint-disable no-nested-ternary */

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import SimpleDashboardNavbar from "examples/Navbars/SimpleDashboardNavbar";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";

import { useEffect } from "react";
import ReactGA from "react-ga4";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getCompanyKYCStatus } from "actions/companyAction";
import { useNavigate } from "react-router-dom";
import { getInvoiceStat } from "actions/statisticAction";
import { getFinancingStat } from "actions/statisticAction";
import { getSalesStat } from "actions/statisticAction";
import { useState } from "react";
import moment from "moment";
import { getInvoiceValue } from "actions/statisticAction";

function Dashboard() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/dashboard", title: "Dashboard" });
  }, []);

  const [salesStat, setSalesStat] = useState(null);
  const [invoiceStat, setInvoiceStat] = useState(null);
  const [invoiceValue, setInvoiceValue] = useState(null);
  const [financeStat, setFinanceStat] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCompanyKYCStatus());
  }, []);

  useEffect(() => {
    if (!salesStat) {
      dispatch(getSalesStat());
    }
    if (!invoiceStat) {
      dispatch(getInvoiceStat());
    }
    if (!invoiceValue) {
      dispatch(getInvoiceValue());
    }
    if (!financeStat) {
      dispatch(getFinancingStat());
    }
  }, [salesStat, invoiceStat, financeStat, invoiceValue]);

  const anInvoiceValue = useSelector((state) => state.anInvoiceValue, shallowEqual);
  const anInvoiceStat = useSelector((state) => state.anInvoiceStat, shallowEqual);
  const anFinanceStat = useSelector((state) => state.anFinanceStat, shallowEqual);
  const anSalesStat = useSelector((state) => state.anSalesStat, shallowEqual);
  const anCompanyKycStatus = useSelector((state) => state.anCompanyKycStatus, shallowEqual);
  const user = JSON.parse(localStorage.getItem("user"));
  const role = user?.role;
  // const dateNow = new Date();

  useEffect(() => {
    setInvoiceStat(anInvoiceStat?.data);
  }, [anInvoiceStat]);

  useEffect(() => {
    setFinanceStat(anFinanceStat?.data);
  }, [anFinanceStat]);

  useEffect(() => {
    setInvoiceValue(anInvoiceValue?.data);
  }, [anInvoiceValue]);

  useEffect(() => {
    setSalesStat(anSalesStat?.data);
  }, [anSalesStat]);

  return (
    <DashboardLayout>
      <SimpleDashboardNavbar />
      {anCompanyKycStatus.status !== "approved" && role?.includes("broker") ? (
        <MDBox
          display="flex"
          justifyContent="space-between"
          sx={{
            borderTop:
              anCompanyKycStatus.status === "rejected" ? "4px solid #F63939" : "4px solid #49a3f1",
          }}
          bgColor="white"
          mt={3}
          px={4}
          py={2}
          shadow="md"
          borderRadius="xl"
        >
          <MDTypography sx={{ fontSize: "12px" }} my="auto">
            {anCompanyKycStatus.status === "created"
              ? "Informasi perusahaan Anda belum terverifikasi. Silahkan lengkapi informasi perusahaan terlebih dahulu."
              : anCompanyKycStatus.status === "rejected"
              ? "Mohon maaf, informasi perusahaan Anda perlu diperbaiki sebelum dapat mengajukan pendanaan."
              : ""}
          </MDTypography>
          <MDButton
            variant="outlined"
            size="small"
            color="info"
            onClick={() => navigate("/registerborrow")}
          >
            {anCompanyKycStatus.status === "rejected"
              ? "Perbaiki Informasi Perusahaan"
              : "Lengkapi Informasi Perusahaan"}
          </MDButton>
        </MDBox>
      ) : null}
      <MDBox py={3}>
        <MDBox bgColor="white" p={0.5} shadow="md" borderRadius="xl">
          <Grid container spacing={0}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox>
                <ComplexStatisticsCard
                  color="dark"
                  icon="weekend"
                  title="Order Penjualan"
                  count={salesStat ? `${salesStat?.[0]?.Data}` : "-"}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Baru saja di-update",
                  }}
                  first
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox>
                <ComplexStatisticsCard
                  icon="leaderboard"
                  title="Jumlah Invoice"
                  count={invoiceStat ? `${invoiceStat?.[0]?.Data}` : "-"}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Baru saja di-update",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox>
                <ComplexStatisticsCard
                  color="success"
                  icon="store"
                  title="Total Besaran Invoice"
                  count={
                    invoiceValue ? `Rp ${invoiceValue?.[0]?.Data.toLocaleString("id-ID")}` : "Rp -"
                  }
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Baru saja di-update",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox>
                <ComplexStatisticsCard
                  color="primary"
                  icon="person_add"
                  title="Pembayaran Cepat"
                  count={financeStat ? `Rp ${financeStat?.[0]?.Data.toLocaleString("id-ID")}` : "Rp -"}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: "Baru saja di-update",
                  }}
                  last
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="Jumlah Order Penjualan Harian"
                  description="Setelah Memperkenalkan Produk Baru"
                  date="baru update"
                  chart={salesStat}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="Besaran Invoice Bulanan"
                  description={
                    <>
                      (<strong>+15%</strong>) pendapatan meningkat
                    </>
                  }
                  date="baru update"
                  chart={invoiceStat}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="Besaran Pembayaran Cepat Bulanan"
                  description={
                    <>
                      (<strong>+15%</strong>) pendapatan meningkat
                    </>
                  }
                  date="baru update"
                  chart={financeStat}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        {}
      </MDBox>
      {}
    </DashboardLayout>
  );
}

export default Dashboard;
