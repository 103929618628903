import axios from "axios";
import serverList from "../apis/serverList";
import authHeader from "../apis/authHeader";

export const fetchOrderPembelian = () => async (dispatch) => {
  const salesAPI = axios.create({
    baseURL: serverList.sales,
    headers: authHeader(),
  });
  const response = await salesAPI.post("/api/v1/purchase/search", {});
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }
  if (response.data.status === "OK") {
    dispatch({ type: "FETCH_ORDER_PEMBELIAN", payload: response.data.data });
  }
};

export const initOrderPembelian = () => {
  return {
    type: "INIT_ORDER_PEMBELIAN",
  };
};

export const getOrderPembelian =
  ({ id }) =>
  async (dispatch) => {
    const salesAPI = axios.create({
      baseURL: serverList.sales,
      headers: authHeader(),
    });
    const response = await salesAPI.get("/api/v1/purchase", {
      params: {
        id,
      },
    });
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
    if (response.data.status === "OK") {
      dispatch({ type: "GET_ORDER_PEMBELIAN", payload: response.data.data });
    }
  };

export const initAnOrderPembelian = () => {
  return {
    type: "INIT_AN_ORDER_PEMBELIAN",
  };
};

export const postNewOrderPembelian = (DetailOrderPembelian) => async (dispatch) => {
  const salesAPI = axios.create({
    baseURL: serverList.sales,
    headers: authHeader(),
  });

  const message = await salesAPI.post("/api/v1/purchase", DetailOrderPembelian).catch((e) => {});

  if (message !== undefined) {
    if (message.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: message.data.message },
      });
    }
    if (message.data.status === "OK") {
      dispatch({
        type: "CREATE_NEW_ORDER_PEMBELIAN",
        payload: { status: "SUCCESS_NEW_ORDER_PEMBELIAN", message: message.data.data },
      });
    } else {
      dispatch({
        type: "ERROR",
        payload: { status: "FAIL_NEW_ORDER_PEMBELIAN", message: message.data.message },
      });
    }
  }
};

export const fetchVendor = () => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const { data } = await companyAPI.get("/api/v1/companies/vendor/name");

  if (data.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: data.data.message },
    });
  }

  const tempData = [];
  data.data.map((isi) => {
    tempData.push({ id: isi.id, value: isi.id, label: isi.name });
  });

  dispatch({
    type: "FETCH_VENDOR",
    payload: tempData,
  });
};

export const publishPurchaseOrder =
  ({ purchase_order_number }) =>
  async (dispatch) => {
    const salesAPI = axios.create({
      baseURL: serverList.sales,
      headers: authHeader(),
    });
    const response = await salesAPI.put("/api/v1/purchase/publish", {
      number: purchase_order_number,
    });
    if (response !== undefined) {
      if (response.status === 401) {
        dispatch({
          type: "ERROR",
          payload: { status: "TOKEN_EXPIRE", message: response.data.message },
        });
      }
      if (response.data.status === "OK") {
        const response = await salesAPI.get("/api/v1/purchase/number", {
          params: {
            number: purchase_order_number,
          },
        });
        if (response.data.status === "OK") {
          dispatch({ type: "GET_ORDER_PEMBELIAN", payload: response.data.data });
        }
      }
    }
  };
