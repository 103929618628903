/* eslint-disable arrow-body-style */
/* eslint-disable no-else-return */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */
import { useState } from "react";
import { useLocation, useParams, useNavigate, Link } from "react-router-dom";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import Radio from "@mui/material/Radio";
import Tooltip from "@mui/material/Tooltip";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import PreviewIcon from "@mui/icons-material/Preview";
import AddIcon from "@mui/icons-material/Add";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

function LVRiwayatKerjasama(props) {
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
  const params = useParams();
  const { id } = params;

  const columns = [
    {
      field: "id",
    },
    {
      field: "no",
      headerName: "No.",
      headerAlign: "center",
      align: "center",
      width: 50,
      renderCell: (params) => <span>{params.api.getRowIndex(params.row.id) + 1}</span>,
    },
    {
      field: "name",
      headerName: "Nama Pelanggan",
      headerAlign: "center",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 250,
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "left",
      renderCell: (params) => {
        if (params.value === "created") {
          return <span>Baru</span>;
        } else if (params.value === "approved") {
          return <span>Disetujui</span>;
        } else if (params.value === "rejected") {
          return <span>Ditolak</span>;
        }
      },
      width: 250,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Tindakan",
      headerAlign: "center",
      sortable: false,
      align: "left",
      width: 350,
      getActions: (params) => {
        return [
          <>
            <MDBox>
              <Link to={`/riwayat-kerjasama/${id}/customer/${params.row.id}/document/${encodeURIComponent(params.row.customer_fintech_relation_id)}`}>
                <MDButton sx={{ color: "#0D6ECD", fontSize: "12px", fontWeight: "bold", marginRight: "10px" }}>
                  Lihat Detail
                </MDButton>
              </Link>
              {params.row.status === "created" ? (
                <>
                  <MDButton
                    sx={{ color: "#0D6ECD", fontSize: "12px", fontWeight: "bold", marginRight: "10px" }}
                    onClick={() => props.onRegister(params.row.customer_fintech_relation_id)}
                  >
                    Setujui
                  </MDButton>
                  <MDButton
                    sx={{ color: "#F63939", fontSize: "12px", fontWeight: "bold", marginRight: "10px" }}
                    onClick={() => props.onReject(params.row.customer_fintech_relation_id)}
                  >
                    Tolak
                  </MDButton>
                </>
              ) : null}
            </MDBox>
          </>,
        ];
      },
    },
  ];

  return (
    <MDBox
      mt={5}
      sx={{
        height: 400,
        width: "100%",
      }}
    >
      <DataGrid
        columns={columns}
        rows={props.rows}
        pageSize={5}
        rowsPerPageOptions={[5]}
        localeText={{ noRowsLabel: "Belum ada riwayat kerjasama." }}

        // checkboxSelection
        disableSelectionOnClick
        sx={{
          bgcolor: "rgba(255, 255, 255, 1)",
          borderRadius: "16px",
          fontSize: "small",
          boxShadow: "10px 15px 27px -28px #1A73E8",
        }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => {
          newModel.id = false;
          // this.setState({ columnVisibilityModel: newModel });
          setColumnVisibilityModel(newModel);
        }}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </MDBox>
  );
}

export default LVRiwayatKerjasama;
