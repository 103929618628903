
import Modal from '@mui/material/Modal';
import PilihPendana from '../layouts/pilihpendana';

const PilihPendanaModal = (props) => {

    return(
        <Modal
            {...props}
        >
            <div>
                <PilihPendana onClose={props.onClose} datapengajuan={props.datapengajuan}/>
            </div>
        </Modal>
    );
}

export default PilihPendanaModal;