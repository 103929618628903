import { combineReducers } from "redux";
import invoicesReducer from "./invoicesReducer";
import pelangganReducer from "./pelangganReducer";
import pphListReducer from "./pphListReducer";
import listProductReducer from "./listProductReducer";
import messageReducer from "./messageReducer";
import getInvoiceReducer from "./getInvoiceReducer";
import companyAssetReducer from "./companyAssetReducer";
import authReducer from "./authReducer";
import fintechReducer from "./fintechReducer";
import pengajuanReducer from "./pengajuanReducer";
import getPengajuanReducer from "./getPengajuanReducer";
import persetujuanReducer from "./persetujuanReducer";
import kycDocsReducer from "./kycDocsReducer";
import getCompanyReducer from "./getCompanyReducer";
import kycPeminjamReducer from "./kycPeminjamReducer";
import kycPeminjamPaginationReducer from "./kycPeminjamPaginationReducer";
import kycDocsArrayReducer from "./kycDocsArrayReducer";
import getCompanyKycStatusReducer from "./getCompanyKycStatusReducer";
import getDaftarPendana from "./getDaftarPendana";
import calonPeminjamReducer from "./calonPeminjamReducer";
import calonPeminjamPaginationReducer from "./calonPeminjamPaginationReducer";
import virtualAccount from "./virtualAccountReducer";
import orderPenjualanReducer from "./orderPenjualanReducer";
import getOrderPenjualanReducer from "./getOrderPenjualanReducer";
import getFintechCompRelReducer from "./getFintechCompRelReducer";
import orderPembelianReducer from "./orderPembelianReducer";
import customerOrderPenjualanReducer from "./customerOrderPenjualanReducer";
import getOrderPembelianReducer from "./getOrderPembelianReducer";
import vendorReducer from "./vendorReducer";
import getCreditLimitReducer from "./getCreditLimitReducer";
import getCustomerCompanyReducer from "./getCustomerCompanyReducer";
import getCustomerBalance from "./getCustomerBalance";
import getSidebarState from "./sidebarReducer";
import getUserReducer from "./getUserReducer";
import getSalesStatReducer from "./getSalesStatReducer";
import getFinancingStatReducer from "./getFinancingStatReducer";
import getInvoiceStatReducer from "./getInvoiceStatReducer";
import getInvoiceValueReducer from "./getInvoiceValueReducer";
import kycCompanyDetail from "./kycCompanyDetail";

import financingList from "./financingListReducer"
import financingListPagination from "./financingListPaginationReducer"
import invoiceCustomerDetail from "./getInvoiceCustomerDetailReducer.js"

import invoiceApprovalDateReducer from "./invoiceApprovalDateReducer.js"
import getCompanyRelation from "./getCompanyRelation";
import relationDocsReducer from "./relationDocsReducer";
import getFintechCompanyRelation from "./getFintechCompanyRelation";
import getDaftarRelationPendana from "./getDaftarRelationPendana";
import userListReducer from "./userListReducer";
import userListPaginationReducer from "./userListPaginationReducer";
import createCustomerRelationReducer from "./createCustomerRelationReducer";
import getUserInvoiceHistory from "./getUserInvoiceHistory";
import getCompanyRelationpPagination from "./getCompanyRelationpPagination";
import getUserInvoiceHistoryPagination from "./getUserInvoiceHistoryPagination";
import getDaftarRelationPendanaPagination from "./getDaftarRelationPendanaPagination";
import relationDocsReducerFintech from "./relationDocsReducerFintech";
import fetchCompanyDetail from "./fetchCompanyDetail";
import getCustomerBalanceList from "./getCustomerBalanceList";
import getAdminSettlementRequest from "./getAdminSettlementRequest";
import getAdminSettlementRequestPagination from "./getAdminSettlementRequestPagination";
import getAdminSettlementDetailRequest from "./getAdminSettlementDetailRequest";
import brokerAccountReducer from "./brokerAccountReducer";
import fintechAccountReducer from "./fintechAccountReducer";
import getAdminSettlementRequestNonFinancingPagination from "./getAdminSettlementRequestNonFinancingPagination";
import getAdminSettlementRequestNonFinancing from "./getAdminSettlementRequestNonFinancing";
import getInvoiceTaxInformationReducer from "./getInvoiceTaxInformationReducer";

export default combineReducers({
  invoices: invoicesReducer,
  listPelanggan: pelangganReducer,
  listPph: pphListReducer,
  listProduct: listProductReducer,
  message: messageReducer,
  anInvoice: getInvoiceReducer,
  listAsset: companyAssetReducer,
  authToken: authReducer,
  listFintech: fintechReducer,
  listPengajuan: pengajuanReducer,
  anPengajuan: getPengajuanReducer,
  listPersetujuan: persetujuanReducer,
  listKYCDocs: kycDocsReducer,
  listRelationDocs: relationDocsReducer,
  listRelationFintechDocs: relationDocsReducerFintech,
  anCompany: getCompanyReducer,
  anInvoiceValue: getInvoiceValueReducer,
  anInvoiceStat: getInvoiceStatReducer,
  anFinanceStat: getFinancingStatReducer,
  anSalesStat: getSalesStatReducer,
  anUser: getUserReducer,
  listKycPeminjam: kycPeminjamReducer,
  listKycPeminjamPagination: kycPeminjamPaginationReducer,
  kycCompanyDetail: kycCompanyDetail,
  listKYCDocsArray: kycDocsArrayReducer,
  anCompanyKycStatus: getCompanyKycStatusReducer,
  listDaftarPendana: getDaftarPendana,
  listCalonPeminjam: calonPeminjamReducer,
  listCalonPeminjamPagination: calonPeminjamPaginationReducer,
  virtualAccount,
  listOrderPenjualan: orderPenjualanReducer,
  listOrderPembelian: orderPembelianReducer,
  anOrderPenjualan: getOrderPenjualanReducer,
  fintechCompRelation: getFintechCompRelReducer,
  listCustomerOP: customerOrderPenjualanReducer,
  anOrderPembelian: getOrderPembelianReducer,
  listVendor: vendorReducer,
  anCreditLimitSummary: getCreditLimitReducer,
  anCustomerCompany: getCustomerCompanyReducer,
  anCustomerBalance: getCustomerBalance,
  anCustomerBalanceList: getCustomerBalanceList,
  sidebarState: getSidebarState,
  financingList: financingList,
  financingListPagination: financingListPagination,
  invoiceCustomerDetail: invoiceCustomerDetail,
  invoiceApprovalDate: invoiceApprovalDateReducer,
  anCompanyRelation: getCompanyRelation,
  anCompanyRelationPagination: getCompanyRelationpPagination,
  anFintechCompanyRelation: getFintechCompanyRelation,
  anFintechRelationList: getDaftarRelationPendana,
  anFintechRelationListPagination: getDaftarRelationPendanaPagination,
  listUser: userListReducer,
  listUserPagination: userListPaginationReducer,
  createCustomerRelationAdmin: createCustomerRelationReducer,
  anUserInvoiceHistory: getUserInvoiceHistory,
  anUserInvoiceHistoryPagination: getUserInvoiceHistoryPagination,
  anCompanyDetail: fetchCompanyDetail,
  anAdminSettlementRequest: getAdminSettlementRequest,
  anAdminSettlementRequestNonFinancing: getAdminSettlementRequestNonFinancing,
  anAdminSettlementDetailRequest: getAdminSettlementDetailRequest,
  anAdminSettlementRequestPagination: getAdminSettlementRequestPagination,
  anAdminSettlementRequestNonFinancingPagination: getAdminSettlementRequestNonFinancingPagination,
  anBrokerAccount: brokerAccountReducer,
  anFintechAccount: fintechAccountReducer,
  invoiceTaxInformation: getInvoiceTaxInformationReducer,
});
