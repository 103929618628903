import { useState, useEffect } from "react";
import MDTypography from "components/MDTypography";

import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

function LVInvoiceDueSummary(props) {

  const [hashData, setHashData] = useState({})
  const [fetchTime, setFetchTime] = useState("baru saja diupdate")


  const dataList = [
    {
      label: "overdue_7+",
      title: "Terlambat > 7 hari"
    },
    {
      label: "overdue_0_7",
      title: "Terlambat < 7 hari"
    },
    {
      label: "due_in_0_7",
      title: "Tenggat < 7 hari"
    },
  ]


  useEffect(() => {
    if (!props.data) {
      return;
    }
    let hashData = {};
    props.data.map((item) => {
      hashData[item.criteria] = item.value;
    })

    setHashData(hashData);
  }, [props.data]);
  return (
    <Grid container spacing={0}>
      {dataList.map((item, index) => (
        <Grid item xs={4} md={4} lg={4}>
          <MDBox>
            <ComplexStatisticsCard
              fontCountSize="18px"
              color="dark"
              icon="weekend"
              title={item.title}
              count={hashData[item.label] ? `${hashData[item.label]?.toLocaleString("id-ID")}` : "0"}
              percentage={{
                color: "success",
                amount: "",
                label: fetchTime,
              }}
              last={(index + 1) % 3 == 0 ? true : false}
            />
          </MDBox>
        </Grid>
      ))}
    </Grid>
  )
}

export default LVInvoiceDueSummary;
