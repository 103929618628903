export default (state=[],action) => {
    switch (action.type) {
       case 'FETCH_PERSETUJUAN':
         return action.payload;
       case 'INIT_PERSETUJUAN':
          return state=[];
       default:
         return state;
     }

};