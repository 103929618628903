/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";

// Authentication pages components
import Footer from "layouts/authentication/components/Footer";

import borders from "assets/theme-dark/base/borders";

function BasicLayout({ image, children }) {
  const { borderRadius } = borders;
  return (
    <PageLayout>
      {/* <DefaultNavbar
        action={{
          type: "external",
          route: "https://creative-tim.com/product/material-dashboard-react",
          label: "free download",
          color: "dark",
        }}
      /> */}

      <MDBox width="100%" px={0} display="flex" height="100%" mx="auto" position="relative">
        <MDBox position="absolute" top={25} left={25}>
          <img src="/kentara-logo.svg" alt="kentara" />
        </MDBox>
        <Grid
          container
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
          my="auto"
        >
          <Grid item xs={11} sm={9} md={10} lg={9} xl={8}>
            {children}
          </Grid>
        </Grid>
        <MDBox
          // minHeight="100vh"
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
          position="relative"
          my="auto"
          display="flex"
        >
          <MDBox
            width="50%"
            height="95%"
            position="fixed"
            top={0}
            bottom={0}
            right={-20}
            borderRadius="25px"
            my="auto"
            sx={{
              backgroundImage: `url("/pattern.png")`,
              backgroundSize: "contain",
            }}
            display="flex"
          >
            <MDBox
              width="100%"
              height="85%"
              my="auto"
              ml={5}
              sx={{
                backgroundImage: `url("/dashboard-il.svg")`,
                backgroundSize: "cover",
                borderBottomLeftRadius: "10px",
                borderTopLeftRadius: "10px",
              }}
            />
          </MDBox>
        </MDBox>
      </MDBox>
    </PageLayout>
  );
}

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  // image: PropTypes.string.isRequired,
  image: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default BasicLayout;
