import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import CustomFooter from "components/LVCustomFooter";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import SettlementModal from "modals/settlementRequestModal";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { invoiceHistory } from "actions/userInvoice";
import { paymentSettlementRequestDetail } from "actions/paymentAction";
import { fetchBrokerAccount } from "actions/companyAction";
import { fetchFintechAccount } from "actions/companyAction";

function LVSettlementRequest(props) {
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ financing_id: false });
  const [show, setShow] = useState(false);
  const [id, setId] = useState(null);
  const [type, setType] = useState("");
  const dispatch = useDispatch();

  const handleShow = (financingId, status, companyId) => {
    setShow(true);
    setId(financingId);
    setType(status);
    dispatch(paymentSettlementRequestDetail({ financing_id: financingId }));
    dispatch(fetchBrokerAccount({ company_id: companyId }));
    dispatch(
      invoiceHistory({
        invoicingId: companyId,
        financingId,
      })
    );
  };

  const anAdminSettlementDetail = useSelector(
    (state) => state.anAdminSettlementDetailRequest,
    shallowEqual
  );

  useEffect(() => {
    if (!anAdminSettlementDetail.fintech_id == "") {
      dispatch(fetchFintechAccount({ company_id: anAdminSettlementDetail.fintech_id }));
    }
  }, [anAdminSettlementDetail]);

  const anUserInvoiceHistory = useSelector((state) => state.anUserInvoiceHistory, shallowEqual);
  // const anUserInvoiceHistoryPagination = useSelector((state) => state.anUserInvoiceHistoryPagination, shallowEqual);

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const columns = [
    {
      field: "financing_id",
    },
    {
      field: "no",
      headerName: "No.",
      headerAlign: "center",
      align: "center",
      width: 50,
      sortable: false,
    },
    {
      field: "financing_number",
      headerName: "No. Financing",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 150,
    },
    {
      field: "company_name",
      headerName: "Kontraktor / Borrower",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 200,
    },
    {
      field: "amount",
      headerName: "Nominal Settlement Request",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip
          title={
            params.value
              ? Number(params.value).toLocaleString("id-ID", { style: "currency", currency: "IDR" })
              : ""
          }
        >
          <span>
            {Number(params.value).toLocaleString("id-ID", { style: "currency", currency: "IDR" })}
          </span>
        </Tooltip>
      ),
      width: 200,
    },
    {
      field: "created_at",
      headerName: "Tanggal Settlement Request",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip
          title={params.value ? new Date(params.value).toLocaleDateString("en-GB", options) : ""}
        >
          <span>{new Date(params.value).toLocaleDateString("en-GB", options)}</span>
        </Tooltip>
      ),
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value ? params.value : ""}>
          <span>{params.value}</span>
        </Tooltip>
      ),
      width: 150,
    },
    {
      field: "type",
      headerName: "Jenis Financing",
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <Tooltip title={params.value === "non_financing" ? "Non Financing" : "Financing"}>
          <span>{params.value === "non_financing" ? "Non Financing" : "Financing"}</span>
        </Tooltip>
      ),
      width: 150,
    },
    {
      field: "action",
      headerName: "Aksi",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <MDButton
          onClick={() =>
            handleShow(params.row.financing_id, params.row.type, params.row.company_id)
          }
          sx={{ color: "#0D6ECD", fontSize: "12px", fontWeight: "bold" }}
        >
          Lihat Detail
        </MDButton>
      ),
      width: 150,
    },
  ];

  const newdata = {
    columns,
    rows: props.rows,
  };

  const renderSX = () => {
    if (props.flat) {
      return {
        bgcolor: "rgba(255, 255, 255, 1)",
        borderRadius: "16px",
        fontSize: "small",
      };
    }
    return {
      bgcolor: "rgba(255, 255, 255, 1)",
      borderRadius: "16px",
      fontSize: "small",
      boxShadow: "10px 15px 27px -28px #1A73E8",
    };
  };

  const sortingHandler = (params) => {
    if (params == []) {
      return;
    }
    props.orderBy(params[0].field);
    props.order(params[0].sort);
  };

  function CustomFooterProps() {
    return (
      <CustomFooter
        page={props.totalPage}
        selectPage={props.selectPage}
        nextPage={props.nextPage}
        prevPage={props.prevPage}
        currentPage={props.currentPage}
        firstPage={props.firstPage}
        lastPage={props.lastPage}
      />
    );
  }

  function getRowId(rows) {
    // return rows.financing_number;
    return rows.id;
  }

  return (
    <MDBox
      sx={{
        height: props.height,
        width: "100%",
      }}
    >
      <DataGrid
        {...newdata}
        columns={columns}
        getRowId={getRowId}
        rows={props.rows}
        autoHeight
        pageSize={10}
        rowsPerPageOptions={props.rowsPerPageOptions}
        editMode="row"
        checkboxSelection={props.checkboxSelection}
        localeText={{ noRowsLabel: "Belum ada data calon peminjam." }}
        disableSelectionOnClick
        sx={renderSX()}
        sortingMode="server"
        onSortModelChange={sortingHandler}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => {
          newModel.id = false;
          setColumnVisibilityModel(newModel);
        }}
        experimentalFeatures={{ newEditingApi: true }}
        hideFooterPagination
        components={{ Footer: CustomFooterProps }}
      />
      <SettlementModal
        open={show}
        onClose={() => setShow(false)}
        id={id}
        type={type}
        invoiceData={anUserInvoiceHistory}
        data={anAdminSettlementDetail}
      // invoiceDataPagination={anUserInvoiceHistoryPagination}
      />
    </MDBox>
  );
}

export default LVSettlementRequest;
