import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useLocation, useParams, useNavigate, Link } from "react-router-dom";

import Grid from "@mui/material/Grid";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Card from "@mui/material/Card";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SimpleDashboardNavbar from "examples/Navbars/SimpleDashboardNavbar";
import MDBox from "components/MDBox";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import ListInfoCard from "examples/Cards/InfoCards/ListInfoCard";

import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import LVProductDataGrid from "components/LVProductDataGrid";

import {
  getOrderPembelian,
  initAnOrderPembelian,
  publishPurchaseOrder,
} from "actions/orderPembelianAction";
import { getOrderPenjualan, initAnOrderPenjualan } from "actions/orderPenjualanAction";

import {
  transformProductPembelian,
  transformStatusOrderPembelian,
  getStatusOrderPembelian,
  transformOrderPenjualan,
  getStatusOrderPenjualan,
  transformStatusPengajuan,
} from "common/Util";
import { render } from "react-dom";
import PilihPendanaPenjualanModal from "modals/pilihPendanaPenjualanModal";

function DetailOrderPembelian() {
  const user = JSON.parse(localStorage.getItem("user"));

  const [transformRow, setTransformRow] = useState([]);
  const [status, setStatus] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogTerbit, setOpenDialogTerbit] = useState(false);
  const [statusFinancing, setStatusFinancing] = useState("");

  const anOrderPembelian = useSelector((state) => state.anOrderPembelian, shallowEqual);

  const anOrderPenjualan = useSelector((state) => state.anOrderPenjualan, shallowEqual);

  const dispatch = useDispatch();
  const params = useParams();

  const rowsObjectBroker = {
    "No. Order Pembelian": anOrderPembelian?.header?.number,
    "Nama Project": anOrderPembelian?.header?.project_name,
    "No. Order Penjualan": anOrderPenjualan?.header?.number,
    "ID Order Penjualan": anOrderPembelian?.header?.sale_order_id,
    "Tgl. Order": anOrderPembelian?.header?.issued_date,
    Status: transformStatusOrderPembelian({ status }),
    "Aktivasi PayLater": transformStatusPengajuan({ status: statusFinancing, type: "purchase" }),
    Supplier: anOrderPembelian?.header?.vendor_company,
    Penjual: anOrderPembelian?.header?.purchase_person,
    "Term of Payment": `${anOrderPembelian?.header?.term_of_payment} Hari`,
    "Sub Total": Number(anOrderPembelian?.header?.total).toLocaleString("id-ID", {
      style: "currency",
      currency: "IDR",
    }),
    Pajak: Number(anOrderPembelian?.header?.tax_amount).toLocaleString("id-ID", {
      style: "currency",
      currency: "IDR",
    }),
    Total: Number(anOrderPembelian?.header?.total).toLocaleString("id-ID", {
      style: "currency",
      currency: "IDR",
    }),
  };

  useEffect(() => {
    const id = decodeURIComponent(params.id);
    if (id !== null) {
      dispatch(getOrderPembelian({ id }));
    }
    return () => {
      dispatch(initAnOrderPembelian());
      dispatch(initAnOrderPenjualan());
    };
  }, []);

  useEffect(() => {
    let i = 0;
    let transport = [];
    if (anOrderPembelian?.detail?.transport !== undefined) {
      transport = anOrderPembelian.detail.transport.map((item) => {
        i += 1;
        return {
          id: i,
          name: `${item.truck_type} ${item.license_plate} ${item.origin} ${item.destination}`,
          price: item.price,
          quantity: item.quantity,
          transaction_date: `${item.departure_date} - ${item.arrival_date}`,
          departure_date_iso: item.departure_date_iso,
          arrival_date_iso: item.arrival_date_iso,
          tax: item.tax,
          remark: item.remark,
        };
      });
    }

    let generic = [];
    if (anOrderPembelian?.detail?.generic !== undefined) {
      generic = anOrderPembelian.detail.generic.map((item) => {
        i += 1;
        return {
          id: i,
          name: item.name,
          unit: item.unit,
          price: item.price,
          quantity: item.quantity,
          transaction_date: item.transaction_date,
          tax: item.tax,
          remark: item.remark,
        };
      });
    }

    const listProductShow = [...transport, ...generic];
    setTransformRow(listProductShow);
    dispatch(getOrderPenjualan({ id: anOrderPembelian?.header?.sale_order_id }));
    setStatus(
      getStatusOrderPembelian({ statusObj: anOrderPembelian?.status, statusType: "purchase" })
    );
  }, [anOrderPembelian]);

  useEffect(() => {
    setStatusFinancing(
      getStatusOrderPenjualan({ statusObj: anOrderPenjualan?.status, statusType: "financing" })
    );
  }, [anOrderPenjualan]);

  const render_terbitkan = () => {
    if (user?.access_list?.includes("publish_po")) {
      if (status.toLowerCase() === "draft") {
        return (
          <MDBox
            justifyContent="end"
            position="sticky"
            bottom={0}
            sx={{ bgcolor: "#ffffff" }}
            height="65px"
            width="100%"
            py={2}
            pr={3}
            display="flex"
          >
            <MDButton variant="contained" color="info" size="medium" onClick={handleClickTerbitkan}>
              Terbitkan
            </MDButton>
          </MDBox>
        );
      }
    }
  };

  const render_req_pl = () => {
    if (
      user?.access_list?.includes("request_so_financing") &&
      statusFinancing.toLowerCase() === "created" &&
      status.toLocaleLowerCase() === "purchase"
    ) {
      return (
        <MDBox
          justifyContent="end"
          position="sticky"
          bottom={0}
          sx={{ bgcolor: "#ffffff" }}
          height="65px"
          width="100%"
          py={2}
          pr={3}
          display="flex"
        >
          <MDButton variant="outlined" color="info" size="small" onClick={handleClickPL}>
            Aktivasi PayLater
          </MDButton>
        </MDBox>
      );
    }
  };

  const handleClickPL = () => {
    setOpenModal(true);
  };

  const handleClickTerbitkan = () => {
    setOpenDialogTerbit(true);
    setDialogTitle("Terbitkan Order Pembelian");
    setDialogMessage(
      "Apakah Anda yakin ingin menerbitkan order pembelian ini? Order pembelian yang sudah diterbitkan tidak dapat diubah lagi."
    );
    setOpenDialog(true);
  };

  const handleCloseAgree = () => {
    if (openDialogTerbit) {
      dispatch(publishPurchaseOrder({
        purchase_order_number: anOrderPembelian?.header?.number,
      })
      );
    }
    setOpenDialogTerbit(false);
    setDialogTitle("");
    setDialogMessage("");
    setOpenDialog(false);
  };

  const handleCloseCancel = () => {
    setOpenDialogTerbit(false);
    setDialogTitle("");
    setDialogMessage("");
    setOpenDialog(false);
  };

  return (
    <>
      <DashboardLayout>
        <SimpleDashboardNavbar name="Order Pembelian" />
        <Grid container direction="column" justifyContent="space-between" spacing={3}>
          <Grid item ml={1} mr={3}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Grid container spacing={1} justifyContent="space-around">
                  <Grid item>
                    <MDTypography variant="h4">{anOrderPembelian?.header?.number}</MDTypography>
                  </Grid>
                  <Grid item textAlign="center" sx={{ margin: "auto" }}>
                    <MDBox
                      alignItems="center"
                      px={2}
                      py={1}
                      sx={{ bgcolor: "rgba(235, 235, 235, 1)", borderRadius: 2, height: "100%" }}
                    >
                      <MDTypography
                        color="text"
                        sx={{ fontSize: 12, margin: "auto" }}
                        fontWeight="medium"
                      >
                        {transformStatusOrderPembelian({ status })}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
 
                <Dialog
                  open={openDialog}
                  onClose={handleCloseCancel}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <MDBox display="flex">
                    <MDBox ml={2} pt={2}>
                      <img src="/airplane-icon.svg" alt="" />
                    </MDBox>
                    <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
                  </MDBox>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {dialogMessage}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <MDButton variant="contained" color="info" onClick={handleCloseAgree}>
                      Setuju
                    </MDButton>
                    <MDButton variant="outlined" color="info" onClick={handleCloseCancel}>
                      Batal
                    </MDButton>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <MDBox bgColor="white" p={0.5} shadow="md" borderRadius="xl">
              <Grid container>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultInfoCard
                    // icon="account_balance"
                    iconHide
                    title={anOrderPembelian?.header?.vendor_company}
                    description="Supplier"
                    first
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultInfoCard
                    iconHide
                    title={anOrderPembelian?.header?.issued_date}
                    description="Tanggal Order"
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultInfoCard
                    iconHide
                    title={`${anOrderPembelian?.header?.term_of_payment} Hari`}
                    description="Term of Payment"
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultInfoCard
                    iconHide
                    title={Number(anOrderPembelian?.header?.total).toLocaleString("id-ID", {
                      style: "currency",
                      currency: "IDR",
                    })}
                    description="Total"
                    last
                  />
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
          <Grid item>
            <Grid container spacing={3}>
              <Grid item alignItems="center" md={3}>
                <Card mb={2}>
                  <ListInfoCard
                    descriptionHide
                    title="Informasi Order Pembelian"
                    info={rowsObjectBroker}
                    shadow={false}
                  />
                </Card>
              </Grid>
              <Grid item md={9}>
                <Accordion defaultExpanded sx={{ boxShadow: "10px 15px 27px -28px #1A73E8" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <MDTypography color="text" px={1} sx={{ fontSize: 14 }}>
                      Produk dalam Order Pembelian{" "}
                    </MDTypography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MDBox sx={{ mt: -1 }}>
                      <LVProductDataGrid viewOnly rows={transformRow || []} />
                    </MDBox>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <PilihPendanaPenjualanModal
          open={openModal}
          datapengajuan={{
            arrId: [anOrderPenjualan?.header?.id],
            arrPenjualan: transformOrderPenjualan({
              orderPenjualan: [
                {
                  id: anOrderPenjualan?.header?.id,
                  creation_date: anOrderPenjualan?.header?.issued_date,
                  creation_date_iso: anOrderPenjualan?.header?.issued_date_iso,
                  number: anOrderPenjualan?.header?.number,
                  project_name: anOrderPenjualan?.header?.project_name,
                  status: anOrderPenjualan?.status,
                  customer_company: anOrderPenjualan?.header?.customer_company,
                  total: anOrderPenjualan?.header?.total,
                },
              ],
              businessType: user?.company?.business_type,
            }),
            pengajuan: anOrderPenjualan?.header?.total,
          }}
          onClose={(e) => {
            setOpenModal(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        />
      </DashboardLayout>
      {render_terbitkan()}
      {render_req_pl()}
    </>
  );
}

export default DetailOrderPembelian;
