import Modal from "@mui/material/Modal";
import SendEmailBulk from "layouts/sendEmailBulk";

function SendEmailBulkModal(props) {
  return (
    <Modal {...props}>
      <div>
        <SendEmailBulk
          rows={props.rows}
          onClose={props.onClose}
        />
      </div>
    </Modal>
  );
}

export default SendEmailBulkModal;
