import { useState, useEffect } from "react";

import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { deleteKYCDocument } from "actions/companyAction";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";

function LVFileInputMultipleDisplay(props) {
  const [listFile, setListFile] = useState([]);

  useEffect(() => {
    setListFile(props.srcImage);
  }, [props.srcImage]);

  const dispatch = useDispatch();
  const deleteAssetKYCDocs = (kycId) => {
    dispatch(deleteKYCDocument(kycId));
  };

  return (
    <div>
      <MDTypography variant="body3">Foto Usaha</MDTypography>
      <MDBox
        display="flex"
        p={2}
        justifyContent={listFile !== [null, null, null, null, null, null] ? "start" : "center"}
        borderRadius="xl"
        height="200px"
        sx={{ border: "1px solid #9DB7CD" }}
      >
        {listFile !== [null, null, null, null, null, null, null] ? (
          <>
            {listFile.map((item, i) => (
              <div key={i}>
                <MDBox display="flex" display={item ? "block" : "none"}>
                  <MDBox
                    bgColor="#e1e1e1"
                    position="relative"
                    mr={1}
                    mb={1}
                    sx={{
                      width: "70px",
                      height: "70px",
                      bgColor: "#e1e1e1",
                      borderBottomLeftRadius: "12px",
                      borderBottomRightRadius: "12px",
                      borderTopRightRadius: "12px",
                      borderTopLeftRadius: "12px",
                      overflow: "hidden",
                    }}
                  >
                    <MDBox
                      width="100%"
                      height="100%"
                      sx={{
                        backgroundImage: item?.img ? `url(${item?.img})` : null,
                        backgroundSize: "cover",
                        borderBottomLeftRadius: "12px",
                        borderBottomRightRadius: "12px",
                        borderTopRightRadius: "12px",
                        borderTopLeftRadius: "12px",
                      }}
                    />
                    <MDBox
                      onClick={() => deleteAssetKYCDocs(item?.id)}
                      sx={{ cursor: "pointer" }}
                      position="absolute"
                      bottom={0}
                      right={0}
                      mr={1}
                    >
                      <MDTypography variant="h6" color="white">
                        <Icon sx={{ fontWeight: "bold" }}>delete_outline</Icon>
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </div>
            ))}
            <MDBox
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              mr={1}
              mb={1}
              sx={{
                width: "70px",
                height: "70px",
                bgColor: "#e1e1e1",
                border: "2px solid #0D6ECD",
                borderRadius: "xl",
              }}
            >
              <MDButton component="label">
                <Icon fontSize="large" color="info">
                  add
                </Icon>
                <input type="file" hidden onChange={props.onChangeFile} accept={props.accept} />
              </MDButton>
            </MDBox>
          </>
        ) : (
          <MDBox justifyContent="center" py="auto">
            <MDBox display="flex" justifyContent="center" mt={8}>
              <MDTypography textAlign="center" sx={{ textAlign: "center" }} variant="body3">
                Belum ada foto
              </MDTypography>
            </MDBox>
            <MDBox
              align="center"
              direction="column"
              alignItems="center"
              py={1}
              px={2}
              sx={{
                // border: "1px solid #9DB7CD",
                borderRadius: 2,
                // borderWidth: 1,
                height: "280px",
                width: "300px",
              }}
            >
              <MDBox
                align="center"
                direction="column"
                alignItems="center"
                // mx={2}
                mb={2}
                sx={{
                  border: "2px solid #0D6ECD",
                  borderRadius: 2,
                }}
              >
                <MDButton
                  component="label"
                  disabled={props.buttonDisabled}
                  sx={{
                    my: "auto",
                    py: 1,
                    px: 2,
                    width: "100%",
                    position: "relative",
                    overflow: "hidden",
                    justifyContent: "space-between",
                  }}
                >
                  <input type="file" hidden onChange={props.onChangeFile} accept={props.accept} />
                  <MDBox
                    width="100%"
                    position="relative"
                    sx={{ justifyContent: "space-between" }}
                    display="flex"
                  >
                    <MDBox position="absolute" display="flex" height="100%">
                      <Icon color="info" fontSize="large" sx={{ my: "auto" }}>
                        upload
                      </Icon>
                    </MDBox>
                    <MDBox width="100%">
                      <MDTypography
                        align="center"
                        color="info"
                        sx={{ fontSize: 14, width: "100%" }}
                        fontWeight="bold"
                      >
                        Unggah Berkas
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        )}
        {props.isLoading ? <CircularProgress /> : null}
      </MDBox>
    </div>
  );
}

export default LVFileInputMultipleDisplay;
