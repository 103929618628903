/* eslint-disable arrow-body-style */
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useLocation, useParams, useNavigate, Link } from "react-router-dom";


import { Modal } from "@mui/material";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Skeleton from "@mui/material/Skeleton";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SimpleDashboardNavbar from "examples/Navbars/SimpleDashboardNavbar";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import ListInfoCard from "examples/Cards/InfoCards/ListInfoCard";

import SendEmailModal from "modals/sendEmailModal";
import LVFileInput from "components/LVFileInput";
import QuickPayModal from "modals/quickPayModal";
import PilihPendanaModal from "modals/pilihPendanaModal";
import PelunasanInvoiceModal from "modals/pelunasanInvoiceModal";
import { customerBalance } from "actions/paymentAction";
import serverList from "../../apis/serverList";
import {
  getInvoice,
  getInvoiceFintech,
  updateStatus,
  initAnInvoice,
  updateDocument,
  updateInvoicePaymentDocument,
  deleteDocument,
  fintechGetInvoiceCustomerDetail,
  fintechGetInvoiceApprovalDate,
  getSelfBillingInvoice,
} from "../../actions/detailInvoiceAction";
import { generateSelfBillingVA, generateVA } from "../../actions/paymentAction";

import { getCompanyKYCStatus } from "../../actions/companyAction";
import InvoiceDetailModal from "../../modals/invoiceDetailModal";
import { transformStatusDocument, transformInvoices, transformOrderPenjualan } from "../../common/Util";
import LVPenjualanDataGrid from "components/LVPenjualanDataGrid";
import { searchOrderPenjualan } from "actions/orderPenjualanAction";

import LVInvoicePelunasan from "components/LVInvoicePelunasan";

function SelfBillingInvoiceDetail() {
  const [fileSuratJalan, setFileSuratJalan] = useState(null);
  const [fileBuktiKirim, setFileBuktiKirim] = useState(null);
  const [statusDokumen, setStatusDokumen] = useState("");
  const [statusDokumenOri, setStatusDokumenOri] = useState("");
  const [statusInvoice, setStatusInvoice] = useState("");
  const [statusFinancing, setStatusFinancing] = useState("");
  const [keterangan, setKeterangan] = useState("");
  const [syaratKetentuan, setSyaratKetentuan] = useState("");
  const [keteranganBawah, setKeteranganBawah] = useState("");
  const [keteranganTTD, setKeteranganTTD] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogTerbit, setOpenDialogTerbit] = useState(false);
  const [openDialogLunas, setOpenDialogLunas] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [openModalKirim, setOpenModalKirim] = useState(false);
  const [openModalQP, setOpenModalQP] = useState(false);
  const [openModalIF, setOpenModalIF] = useState(false);
  const [openPelunasanInvoice, setOpenPelunasanInvoice] = useState(false);
  const [fileInvoice, setFileInvoice] = useState("");
  const [loadPDF, setLoadPDF] = useState(false);
  const [suratJalan, setSuratJalan] = useState(null);
  const [suratJalanLogo, setSuratJalanLogo] = useState(null);
  const [suratJalanId, setSuratJalanId] = useState(null);
  const [buktiKirim, setBuktiKirim] = useState(null);
  const [buktiKirimLogo, setBuktiKirimLogo] = useState(null);
  const [buktiKirimId, setBuktiKirimId] = useState(null);
  const [lampiranBaruFile, setLampiranBaruFile] = useState(null);
  const [lampiranBaruLogo, setLampiranBaruLogo] = useState(null);
  const [lampiranBaru, setLampiranBaru] = useState(false);
  const [lampiranBaruInput, setLampiranBaruInput] = useState("");
  const [customerAvailableBalance, setCustomerAvailableBalance] = useState(null);

  const [sourcePaymentFile, setSourcePaymentFile] = useState(null);
  const [sourcePaymentImage, setSourcePaymentImage] = useState(null);

  const [pelunasanModal, setPelunasanModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const refs = {
    suratJalan: useRef(),
    buktiKirim: useRef(),
    lampiranBaru: useRef(),
  };

  const closePelunasanModal = () => {
    setPelunasanModal(false);
  }

  const message = useSelector(
    state => {
      return state.message;
    },
    shallowEqual
  );
  const invoicePaymentDocument = useSelector((state) => state.invoicePaymentDocument, shallowEqual);

  const anInvoice = useSelector((state) => state.anInvoice, shallowEqual);

  const virtualAccount = useSelector((state) => state.virtualAccount, shallowEqual);

  const anCustomerBalance = useSelector((state) => state.anCustomerBalance, shallowEqual);

  const anCompanyKycStatus = useSelector((state) => state.anCompanyKycStatus, shallowEqual);

  const invoiceCustomerDetail = useSelector((state) => state.invoiceCustomerDetail, shallowEqual);
  const invoiceApprovalDate = useSelector((state) => state.invoiceApprovalDate, shallowEqual);

  const user = JSON.parse(localStorage.getItem("user"));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = useParams();

  const onDeletePaymentFile = (e) => {
    setSourcePaymentFile(null);
    setSourcePaymentImage(null);
  }

  useEffect(() => {
    dispatch(getCompanyKYCStatus());
    if (user) {
      if (user?.company?.business_type === "fintech") {
        const id = decodeURIComponent(params.id);
        if (id !== null) {
          dispatch(getInvoiceFintech({ id }));
        } else {
          navigate("/pengajuanfin");
        }
      } else {
        const res = decodeURIComponent(params.number);
        if (res !== null) {
          dispatch(getSelfBillingInvoice({ number: res }));
        } else {
          navigate("/invoice");
        }
      }
    }
    return () => {
      dispatch(initAnInvoice());
    };
  }, []);

  useEffect(() => { }, [lampiranBaru]);

  useEffect(() => {
    if (anInvoice.header) {
      if (anInvoice.header.invoicing_company_id) {
        dispatch(generateSelfBillingVA(Number(anInvoice.header.invoicing_company_id)));
        dispatch(customerBalance(anInvoice.header.invoicing_company_id));
      }
    }
    if (anInvoice.status !== undefined) {
      anInvoice.status.map((isi) => {
        if (isi.type === "document") {
          setStatusDokumen(transformStatusDocument(isi.status));
          setStatusDokumenOri(isi.status);
        } else if (isi.type === "invoice") {
          setStatusInvoice(isi.status);
        } else if (isi.type === "financing") {
          setStatusFinancing(isi.status);
        }
      });
    }
    if (anInvoice.information !== undefined) {
      anInvoice.information.map((isi) => {
        if (isi.type === "remark") {
          setKeterangan(isi.content);
        } else if (isi.type === "terms_condition") {
          setSyaratKetentuan(isi.content);
        } else if (isi.type === "above_signature") {
          setKeteranganTTD(isi.content);
        } else if (isi.type === "below_signature") {
          setKeteranganBawah(isi.content);
        }
      });
    }
    if (anInvoice.document !== undefined) {
      let foundSuratJalan = false;
      let foundBuktiKirim = false;
      anInvoice.document.map((isi) => {
        if (isi.type === "invoice") {
          setFileInvoice(serverList.invoice + isi.url);
        } else if (isi.type === "surat_jalan") {
          setSuratJalan(serverList.invoice + isi.url);
          setSuratJalanId(isi.id);
          setSuratJalanLogo("/pdf_logo_bw.jpg");
          foundSuratJalan = true;
        } else if (isi.type === "bukti_kirim") {
          setBuktiKirim(serverList.invoice + isi.url);
          setBuktiKirimLogo("/pdf_logo_bw.jpg");
          setBuktiKirimId(isi.id);
          foundBuktiKirim = true;
        }
      });
      if (!foundSuratJalan) {
        setSuratJalan(null);
        setSuratJalanId(null);
        setSuratJalanLogo(null);
      }
      if (!foundBuktiKirim) {
        setBuktiKirim(null);
        setBuktiKirimLogo(null);
        setBuktiKirimId(null);
      }
    } else {
      setTimeout(() => {
        const res = decodeURIComponent(params.number);
        if (user) {
          if (user?.company?.business_type === "fintech") {
            const id = decodeURIComponent(params.id);
            if (id !== null) {
              dispatch(getInvoiceFintech({ id }));
            } else {
              navigate("/pengajuanfin");
            }
          } else {
            const res = decodeURIComponent(params.number);
            if (res !== null) {
              dispatch(getSelfBillingInvoice({ number: res }));
            } else {
              navigate("/invoice");
            }
          }
        }
      }, 3000);
    }
    if (anInvoice.header.invoiced_company_id !== undefined) {
      dispatch(fintechGetInvoiceCustomerDetail(anInvoice.header.invoiced_company_id));
      dispatch(fintechGetInvoiceApprovalDate(anInvoice.header.id));
    }
  }, [anInvoice]);

  useEffect(() => {
    if (invoicePaymentDocument.invoice_id !== null) {
      setPelunasanModal(false);
      // navigate(0); 
    }
  }, [invoicePaymentDocument])

  useEffect(() => {
    if (message.status !== "") {
      switch (message.status) {
        case "ERROR_UPDATE_INVOICE_PAYMENT_DOCUMENT":
          setErrorMessage(message.message);
          return;
        default:
          return;
      }
    }

  }, [message])

  useEffect(() => {
    setCustomerAvailableBalance(anCustomerBalance?.balance);
  }, [anCustomerBalance]);

  function createData(nama, delimiter, value) {
    return { nama, delimiter, value };
  }

  const rows = [
    createData("Nomor Invoice", ":", anInvoice.header ? anInvoice.header.number : ""),
    createData("Pelanggan", ":", anInvoice.header ? anInvoice.header.invoiced_company : ""),
    createData("Vendor", ":", anInvoice.header ? anInvoice.header.invoicing_company : ""),
    createData("Tanggal Terbit", ":", anInvoice.header ? anInvoice.header.issued_date : ""),
    createData("Term of Payment", ":", anInvoice.header ? anInvoice.header.term_of_payment : ""),
    createData("Tanggal Jatuh Tempo", ":", anInvoice.header ? anInvoice.header.due_date : ""),
    createData(
      "Sub Total",
      ":",
      anInvoice.header
        ? Number(anInvoice.header.subtotal).toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
        })
        : ""
    ),
    createData(
      "PPN di Luar Harga",
      ":",
      anInvoice.header
        ? Number(anInvoice.header.tax_amount).toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
        })
        : ""
    ),
    createData(
      "Total",
      ":",
      anInvoice.header
        ? Number(anInvoice.header.total).toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
        })
        : ""
    ),
    createData(
      "PPN di Dalam Harga",
      ":",
      anInvoice.header
        ? Number(anInvoice.header.inclusive_tax_amount).toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
        })
        : ""
    ),
    // createData("Tipe PPh", ":", anInvoice.header ? anInvoice.header.tax : ""),
    createData("Keterangan di Atas Tanda Tangan", ":", keteranganTTD),
    createData("Keterangan di Bawah Tanda Tangan", ":", keteranganBawah),
  ];

  const rowsObject = {
    "Nomor Invoice": anInvoice.header ? anInvoice.header.number : "",
    "ID Order Penjualan": anInvoice.header
      ? anInvoice.header.sale_order_id === 0
        ? anInvoice.header.purchase_order_id
        : anInvoice.header.sale_order_id
      : "",
    "Pelanggan": anInvoice.header ? anInvoice.header.invoiced_company : "",
    "Vendor": anInvoice.header ? anInvoice.header.invoicing_company : "",
    "Tanggal Terbit": anInvoice.header ? anInvoice.header.issued_date : "",
    "Term of Payment": anInvoice.header ? anInvoice.header.term_of_payment : "",
    "Tanggal Jatuh Tempo": anInvoice.header ? anInvoice.header.due_date : "",
    "Sub Total": anInvoice.header
      ? Number(anInvoice.header.subtotal).toLocaleString("id-ID", {
        style: "currency",
        currency: "IDR",
      })
      : "",
    "PPN di Luar Harga": anInvoice.header
      ? Number(anInvoice.header.tax_amount).toLocaleString("id-ID", {
        style: "currency",
        currency: "IDR",
      })
      : "",
    Total: anInvoice.header
      ? Number(anInvoice.header.total).toLocaleString("id-ID", {
        style: "currency",
        currency: "IDR",
      })
      : "",
    "PPN di Dalam Harga": anInvoice.header
      ? Number(anInvoice.header.inclusive_tax_amount).toLocaleString("id-ID", {
        style: "currency",
        currency: "IDR",
      })
      : "",
    // "Tipe PPh": anInvoice.header ? anInvoice.header.tax : "",
    "Keterangan di Atas Tanda Tangan": keteranganTTD,
    "Keterangan di Bawah Tanda Tangan": keteranganBawah,
    "Nomor Virtual Account": `${virtualAccount.bank_code} - ${virtualAccount.account_name} - ${virtualAccount.account_number}`,
  };

  const render_terbit = () => {
    if (user?.access_list?.includes("publish_invoice")) {
      if (statusInvoice === "Draft") {
        return (
          <MDButton
            sx={{ ml: 2 }}
            variant="contained"
            color="info"
            size="small"
            onClick={handleClickTerbit}
          >
            Terbitkan
          </MDButton>
        );
      }
    }
  };

  const render_ubah = () => {
    if (user?.access_list?.includes("edit_invoice")) {
      if (statusInvoice === "Draft") {
        const linkEdit = `/invoice-self-billing-edit/${encodeURIComponent(params.number)}`;
        return (
          <MDButton
            sx={{ ml: 2 }}
            variant="contained"
            color="info"
            size="small"
            component={Link}
            to={linkEdit}
          >
            Ubah
          </MDButton>
        );
      }
    }
  };

  const render_lunas = () => {
    if (user?.access_list?.includes("close_invoice")) {
      if (statusInvoice === "Unpaid") {
        return (
          <MDButton
            sx={{ ml: 2 }}
            variant="contained"
            color="info"
            size="small"
            // disabled
            onClick={handleClickLunas}
          >
            Pelunasan
          </MDButton>
        );
      }
    }
  };

  const render_ajukan_qp = () => {
    if (user?.access_list?.includes("request_qp")) {
      if (statusInvoice === "Unpaid") {
        return (
          <MDButton
            sx={{ ml: 2 }}
            variant="contained"
            color="info"
            size="small"
            onClick={handleClickQP}
          >
            Ajukan Invoice Financing
          </MDButton>
        );
      }
    }
  };

  const render_ajukan_if = () => {
    if (user?.access_list?.includes("request_invoice_financing")) {
      if (
        statusInvoice.toLowerCase() === "unpaid" &&
        statusDokumenOri.toLowerCase() === "approved" &&
        statusFinancing.toLowerCase() === "created"
      ) {
        return (
          <MDButton
            sx={{ ml: 2 }}
            variant="contained"
            color="info"
            size="small"
            onClick={onClickPendanaan}
          >
            Ajukan Invoice Financing
          </MDButton>
        );
      }
    }
  };


  const render_lampiran_baru = () => {
    if (lampiranBaru) {
      return (
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <MDInput
                id="lampiran_baru"
                name="lampiranBaruInput"
                label="Label Lampiran"
                placeholder="Isi dengan nama lampiran"
                onChange={onChangeInput}
                value={lampiranBaruInput}
                sx={{ width: 200 }}
                required
              />
            </Grid>
            <Grid item>
              <LVFileInput
                srcImage={lampiranBaruLogo}
                srcFile={lampiranBaruFile}
                height={200}
                width={240}
                fontSize={10}
                id="file_lampiran_baru"
                onChangeFile={onChangeLampiranBaru}
                text1="Unggah Lampiran"
                text2="Maksimal ukuran 5MB PDF"
                text3=""
                title=""
                titleFontSize={12}
                accept=".pdf"
                refinput={refs.lampiranBaru}
                buttonDisabled={lampiranBaruInput.trim() === ""}
              />
            </Grid>
          </Grid>
        </Grid>
      );
    }
  };

  const render_lampiran = () => {
    if (user?.access_list?.includes("update_attachment_invoice")) {
      return (
        <Grid container spacing={2} direction="column" alignItems="center">
          <Grid item>
            <LVFileInput
              srcImage={suratJalanLogo}
              srcFile={suratJalan}
              height={100}
              width={240}
              fontSize={10}
              id="surat_jalan"
              onChangeFile={onChangeSuratJalan}
              text1="Unggah Surat Jalan"
              text2="Maksimal ukuran 5MB PDF"
              text3=""
              title="Surat Jalan"
              titleFontSize={12}
              accept=".pdf"
              deleteFile={() =>
                deleteInvoiceDocs({ id: suratJalanId, number: anInvoice.header.number })
              }
              refinput={refs.suratJalan}
            />
          </Grid>
          <Grid item>
            <LVFileInput
              srcImage={buktiKirimLogo}
              srcFile={buktiKirim}
              height={100}
              width={240}
              fontSize={10}
              id="bukti_kirim"
              onChangeFile={onChangeBuktiKirim}
              text1="Unggah Bukti Kirim"
              text2="Maksimal ukuran 5MB PDF"
              text3=""
              title="Bukti Kirim"
              titleFontSize={12}
              accept=".pdf"
              deleteFile={() =>
                deleteInvoiceDocs({ id: buktiKirimId, number: anInvoice.header.number })
              }
              refinput={refs.buktiKirim}
            />
          </Grid>
          {anInvoice.document !== undefined ? (
            anInvoice.document.map((isi) => {
              if (
                isi.type !== "invoice" &&
                isi.type !== "surat_jalan" &&
                isi.type !== "bukti_kirim"
              ) {
                return (
                  <Grid item key={isi.id}>
                    <LVFileInput
                      srcImage="/pdf_logo_bw.jpg"
                      srcFile={serverList.invoice + isi.url}
                      height={200}
                      width={240}
                      fontSize={10}
                      id={isi.type}
                      onChangeFile={onChangeLampiranBaru}
                      text1="Unggah Lampiran"
                      text2="Maksimal ukuran 5MB PDF"
                      text3=""
                      title={isi.type.replace("_", " ")}
                      titleFontSize={12}
                      accept=".pdf"
                      deleteFile={() =>
                        deleteInvoiceDocs({ id: isi.id, number: anInvoice.header.number })
                      }
                      refinput={refs.buktiKirim}
                    />
                  </Grid>
                );
              }
            })
          ) : (
            <div />
          )}
          <Grid item>
            <MDButton
              disabled={lampiranBaru}
              variant="outlined"
              color="info"
              size="small"
              onClick={() => setLampiranBaru(true)}
            >
              Tambah Lampiran Lain
            </MDButton>
          </Grid>
          {render_lampiran_baru()}
        </Grid>
      );
    }
    return (
      <Grid container spacing={2} direction="column" alignItems="center">
        <Grid item>
          <LVFileInput
            srcImage={suratJalanLogo}
            srcFile={suratJalan}
            height={200}
            width={240}
            fontSize={10}
            id="surat_jalan"
            onChangeFile={onChangeSuratJalan}
            text1="Surat Jalan Tidak Tersedia"
            text2=""
            text3=""
            title="Surat Jalan"
            titleFontSize={12}
            accept=".pdf"
            viewOnly={!user?.access_list?.includes("update_attachment_invoice")}
            refinput={refs.suratJalan}
          />
        </Grid>
        <Grid item>
          <LVFileInput
            srcImage={buktiKirimLogo}
            srcFile={buktiKirim}
            height={200}
            width={240}
            fontSize={10}
            id="bukti_kirim"
            onChangeFile={onChangeBuktiKirim}
            text1="Bukti Kirim Tidak Tersedia"
            text2=""
            text3=""
            title="Bukti Kirim"
            titleFontSize={12}
            accept=".pdf"
            viewOnly={!user?.access_list?.includes("update_attachment_invoice")}
            refinput={refs.buktiKirim}
          />
        </Grid>
        {anInvoice.document !== undefined ? (
          anInvoice.document.map((isi) => {
            if (
              isi.type !== "invoice" &&
              isi.type !== "surat_jalan" &&
              isi.type !== "bukti_kirim"
            ) {
              return (
                <Grid item key={isi.id}>
                  <LVFileInput
                    srcImage="/pdf_logo_bw.jpg"
                    srcFile={serverList.invoice + isi.url}
                    height={200}
                    width={240}
                    fontSize={10}
                    id={isi.type}
                    onChangeFile={onChangeLampiranBaru}
                    text1="Unggah Lampiran"
                    text2="Maksimal ukuran 5MB PDF"
                    text3=""
                    title={isi.type.replace("_", " ")}
                    titleFontSize={12}
                    accept=".pdf"
                    viewOnly={!user?.access_list?.includes("update_attachment_invoice")}
                    refinput={refs.buktiKirim}
                  />
                </Grid>
              );
            }
          })
        ) : (
          <div />
        )}
      </Grid>
    );
  };

  const onChangeInput = (event) => {
    setLampiranBaruInput(event.target.value);
  };

  const handleClickQP = () => {
    setOpenModalQP(true);
  };

  const navigateToRegisterBorrow = () => {
    navigate("/registerborrow");
  };

  const handleClickIF = () => {
    setOpenModalIF(true);
  };

  const handleClickTerbit = () => {
    setOpenDialogTerbit(true);
    setDialogTitle("Terbitkan Invoice");
    setDialogMessage("Setelah invoice terbit, informasi dalam invoice tidak dapat diubah lagi.");
    setOpenDialog(true);
  };

  const handleClickLunas = () => {
    if (statusFinancing.toLowerCase() === "created") {
      setPelunasanModal(true);
    } else {
      setOpenPelunasanInvoice(true);
      setOpenDialogLunas(true);
    }
  };

  const handleCloseAgree = () => {
    if (openDialogTerbit) {
      dispatch(
        updateStatus({
          invoice_id: anInvoice.header.id,
          type: "invoice",
          status: "unpaid",
          number: anInvoice.header.number,
        })
      );
    } else if (openDialogLunas) {
      dispatch(
        updateStatus({
          invoice_id: anInvoice.header.id,
          type: "invoice",
          status: "paid",
          number: anInvoice.header.number,
        })
      );
    }
    setOpenDialogTerbit(false);
    setOpenDialogLunas(false);
    setDialogTitle("");
    setDialogMessage("");
    setOpenDialog(false);
  };

  const handleCloseCancel = () => {
    setOpenDialogTerbit(false);
    setOpenDialogLunas(false);
    setDialogTitle("");
    setDialogMessage("");
    setOpenDialog(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onOpenDetailModal = () => {
    if (user) {
      if (user?.company?.business_type === "fintech") {
        const id = decodeURIComponent(params.id);
        if (id !== null) {
          dispatch(getInvoiceFintech({ id }));
        } else {
          navigate("/pengajuanfin");
        }
      } else {
        const res = decodeURIComponent(params.number);
        if (res !== null) {
          dispatch(getInvoice({ number: res }));
        } else {
          navigate("/invoice");
        }
      }
    }
    setOpenModalDetail(true);
  };

  const onClickKirim = () => {
    setOpenModalKirim(true);
  };

  const onChangeLampiranBaru = (e) => {
    if (lampiranBaruInput.trim().toLowerCase() === "invoice") {
      dispatch(
        updateDocument({
          invoice_id: anInvoice.header.id,
          number: anInvoice.header.number,
          type: "Invoice_Tambahan",
          file: e.target.files[0],
        })
      );
    } else {
      dispatch(
        updateDocument({
          invoice_id: anInvoice.header.id,
          number: anInvoice.header.number,
          type: lampiranBaruInput.trim().replace(/ /g, "_"),
          file: e.target.files[0],
        })
      );
    }
    if (refs.lampiranBaru.current !== undefined) {
      refs.lampiranBaru.current.value = "";
    }
    setLampiranBaru(false);
    setLampiranBaruInput("");
    setLampiranBaruFile(null);
  };


  const onChangePelunasanFile = (e) => {
    setSourcePaymentFile(e.target.files[0]);
    setSourcePaymentImage('pdf');
    return
  }

  const onInvoicePaymentSubmit = (e) => {

    if (sourcePaymentFile === null) {
      setErrorMessage("Lampiran pelunasan invoice masih kosong");
      return
    }

    dispatch(
      updateInvoicePaymentDocument({
        invoice_id: anInvoice.header.id,
        file: sourcePaymentFile,
      })
    );
  }

  const onChangeSuratJalan = (e) => {
    dispatch(
      updateDocument({
        invoice_id: anInvoice.header.id,
        number: anInvoice.header.number,
        type: "surat_jalan",
        file: e.target.files[0],
      })
    );
    if (refs.suratJalan.current !== undefined) {
      refs.suratJalan.current.value = "";
    }
  };

  const onChangeBuktiKirim = (e) => {
    dispatch(
      updateDocument({
        invoice_id: anInvoice.header.id,
        number: anInvoice.header.number,
        type: "bukti_kirim",
        file: e.target.files[0],
      })
    );
    if (refs.suratJalan.current !== undefined) {
      refs.suratJalan.current.value = "";
    }
  };

  const deleteInvoiceDocs = ({ id, number }) => {
    if (id !== null) {
      dispatch(deleteDocument({ id, number }));
    }
  };

  const onClickPendanaan = () => {
    if (anCompanyKycStatus.status === "created") {
      handleClickOpen();
    } else if (anCompanyKycStatus.status === "approved") {
      if (user?.company?.business_type === "borrower") {
        handleClickIF();
      } else if (user?.company?.business_type === "broker") {
        handleClickIF();
      }
    } else {
      handleClickOpen();
    }
  };

  const renderCustomerDetail = (detail) => {
    if (detail.pic === undefined) {
      return;
    }
    if (detail.pic.length <= 0) {
      return;
    }
    return (
      <Card sx={{ marginBottom: 3 }}>
        <ListInfoCard
          descriptionHide
          title="Informasi Persetujuan"
          info={{
            "Nama PIC": detail.pic[0].name.toUpperCase(),
            "Email PIC": detail.pic[0].email.toLowerCase(),
            "Tanggal Disetujui Pelanggan": invoiceApprovalDate,
            Jabatan: "Finance Staff",
          }}
          shadow={false}
        />
      </Card>
    );
  };

  const renderEmailCopy = () => {
    let emailCopyPath =
      serverList.invoice +
      "/api/v1/invoice/email/copy?invoice_id=" +
      anInvoice.header.id +
      "&authorization=" +
      user.access_token;

    if (statusDokumenOri.toLowerCase() === "approved") {
      return (
        <Card sx={{ marginBottom: 3 }}>
          {/* <MDBox p={3} mx={3} display="flex" justifyContent="center" /> */}
          <MDBox py={3} px={2} textAlign="left" lineHeight={1.25}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              Email Konfirmasi
            </MDTypography>
            <MDBox borderRadius="xl" sx={{ width: "100%", mt: 1, px: 2, pt: 2, pb: 2, border: "1px solid #e1e1e1" }}>
              <embed src={emailCopyPath} width="100%" height="300px" />
            </MDBox>
          </MDBox>
        </Card>
      )
    }
    else {
      return (
        <Card sx={{ marginBottom: 3 }}>
          {/* <MDBox p={3} mx={3} display="flex" justifyContent="center" /> */}
          <MDBox py={3} px={2} textAlign="left" lineHeight={1.25}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              Email Konfirmasi
            </MDTypography>
            <div width="100%" height="300px" />
          </MDBox>
        </Card>
      );
    }
  };

  const listOrderPenjualan = useSelector((state) => state.listOrderPenjualan, shallowEqual);

  useEffect(() => {
    dispatch(
      searchOrderPenjualan({
        id: [anInvoice.header.sale_order_id],
        limit: 15,
        page: 1,
        order_by: "id desc",
      })
    );
  }, [anInvoice]);

  const renderDataGrid = () => {
    return (
      <LVPenjualanDataGrid
        height={400}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection={false}
        viewOnly
        flat
        rows={transformOrderPenjualan({
          orderPenjualan: listOrderPenjualan,
          businessType: user?.company?.business_type,
          arrId: [anInvoice.header.sale_order_id],
        })}
        columnType={user?.company?.business_type}
      />
    );
  };


  const renderPelunasanInvoice = () => {
    return <Modal
      open={pelunasanModal}
      onClose={closePelunasanModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={{
        margin: "auto",
        marginTop: 5, marginBottom: 3, width: '100%', maxWidth: "800px", display: "flex",
      }}>
        <LVInvoicePelunasan
          onCloseHandling={closePelunasanModal}
          onProcessHandling={onInvoicePaymentSubmit}
          onChangeFile={onChangePelunasanFile}
          srcImage={sourcePaymentImage}
          srcFile={sourcePaymentFile}
          deleteFile={onDeletePaymentFile}
          refinput={sourcePaymentFile}
          errorMessage={errorMessage}
        ></LVInvoicePelunasan>
      </Card>
    </Modal >
  }

  return (
    <>
      <DashboardLayout>
        <SimpleDashboardNavbar name="Detail Invoice" />
        <Grid mt={5} container direction="column" justifyContent="space-between" spacing={3}>
          <Grid item ml={1} mr={3}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Grid container spacing={1} justifyContent="space-around">
                  <Grid item>
                    <MDTypography variant="h4">
                      {anInvoice.header.number ? anInvoice.header.number : ""}
                    </MDTypography>
                  </Grid>
                  <Grid item textAlign="center" sx={{ margin: "auto" }}>
                    <MDBox
                      alignItems="center"
                      px={2}
                      py={1}
                      sx={{ bgcolor: "rgba(235, 235, 235, 1)", borderRadius: 2, height: "100%" }}
                    >
                      <MDTypography
                        color="text"
                        sx={{ fontSize: 12, margin: "auto" }}
                        fontWeight="medium"
                      >
                        {statusInvoice}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item textAlign="center" sx={{ margin: "auto" }}>
                    <MDBox
                      alignItems="center"
                      px={2}
                      py={1}
                      sx={{ bgcolor: "rgba(235, 235, 235, 1)", borderRadius: 2, height: "100%" }}
                    >
                      <MDTypography
                        color="text"
                        sx={{ fontSize: 12, margin: "auto" }}
                        fontWeight="medium"
                      >
                        {statusDokumen}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <QuickPayModal
                  open={openModalQP}
                  datapengajuan={{
                    arrId: [anInvoice.header.id],
                    arrInv: transformInvoices([anInvoice.header]),
                    pengajuan: anInvoice.header.total,
                  }}
                  onClose={(e) => {
                    setOpenModalQP(false);
                  }}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                />
                <PilihPendanaModal
                  open={openModalIF}
                  datapengajuan={{
                    arrId: [anInvoice.header.id],
                    arrInv: transformInvoices([anInvoice.header]),
                    pengajuan: anInvoice.header.total,
                  }}
                  onClose={(e) => {
                    setOpenModalIF(false);
                  }}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                />
                <Dialog
                  open={openDialog}
                  onClose={handleCloseCancel}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {dialogMessage}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <MDButton color="info" variant="contained" onClick={handleCloseAgree} autoFocus>
                      Setuju
                    </MDButton>
                    <MDButton color="error" variant="contained" onClick={handleCloseCancel}>
                      Batal
                    </MDButton>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <MDBox bgColor="white" p={0.5} shadow="md" borderRadius="xl">
              <Grid container>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultInfoCard
                    iconHide
                    title={anInvoice?.header?.invoiced_company}
                    description="Pelanggan"
                    first
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultInfoCard
                    iconHide
                    title={anInvoice?.header?.issued_date}
                    description="Tanggal Invoice"
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultInfoCard
                    iconHide
                    title={anInvoice?.header?.due_date}
                    description="Tanggal Jatuh Tempo"
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultInfoCard
                    iconHide
                    title={Number(anInvoice?.header?.total).toLocaleString("id-ID", {
                      style: "currency",
                      currency: "IDR",
                    })}
                    description="Total Tagihan"
                    last
                  />
                </Grid>
              </Grid>
            </MDBox>
          </Grid>

          <Grid item>
            <Grid container spacing={3}>
              <Grid item alignItems="center" md={5}>
                {renderCustomerDetail(invoiceCustomerDetail)}

                {renderEmailCopy()}

                <Card sx={{ marginBottom: 3 }}>
                  <MDBox p={2} mx={3} display="flex" justifyContent="center" />
                  <MDBox pb={4} px={2} textAlign="center" lineHeight={1.25}>
                    <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      Lampiran
                    </MDTypography>
                    {render_lampiran()}
                  </MDBox>
                </Card>

                <Card mb={2}>
                  <ListInfoCard
                    descriptionHide
                    title="Informasi Invoice"
                    info={rowsObject}
                    shadow={false}
                  />
                </Card>
                <Card sx={{ marginTop: 3 }}>
                  <MDBox p={2} mx={3} display="flex" justifyContent="center" />
                  <MDBox pb={4} px={2} textAlign="center" lineHeight={1.25}>
                    <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      Informasi Detail
                    </MDTypography>
                    <MDButton
                      variant="outlined"
                      color="info"
                      size="small"
                      onClick={onOpenDetailModal}
                      sx={{ marginTop: 3 }}
                    >
                      Lihat Detail
                    </MDButton>
                    <InvoiceDetailModal
                      open={openModalDetail}
                      onClose={(e) => setOpenModalDetail(false)}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    />
                  </MDBox>
                </Card>
              </Grid>
              <Grid item xs={12} md={7}>
                {fileInvoice === "" ? (
                  <Skeleton variant="rectangular" width="100%" height="820px" />
                ) : (
                  <embed src={fileInvoice} width="100%" height="820px" />
                )}
                <Card sx={{ marginTop: 3 }}>
                  <Accordion
                    defaultExpanded
                    sx={{
                      boxShadow: "10px 15px 27px -28px #1A73E8",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <MDTypography color="text" px={1} sx={{ fontSize: 14 }}>
                        Data Order Penjualan yang Diajukan.
                      </MDTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <MDBox>{renderDataGrid()}</MDBox>
                    </AccordionDetails>
                  </Accordion>
                </Card>
                <Card sx={{ marginTop: 3 }}>
                  <DefaultInfoCard
                    iconHide
                    title="Keterangan"
                    description={keterangan === "" ? "Keterangan tidak tersedia" : keterangan}
                  />
                </Card>
                <Card sx={{ marginTop: 3 }}>
                  <DefaultInfoCard
                    iconHide
                    title="Syarat dan Ketentuan"
                    description={
                      syaratKetentuan === ""
                        ? "Syarat dan ketentuan tidak tersedia"
                        : syaratKetentuan
                    }
                  />
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <PelunasanInvoiceModal
          open={openPelunasanInvoice}
          onClose={(e) => {
            setOpenPelunasanInvoice(false);
          }}
          datapengajuan={{
            requestId: anInvoice.financing?.id,
            totalInvoice: anInvoice.header
              ? anInvoice.header.tax === "PPh 23 NPWP 2%"
                ? Number(anInvoice.header.subtotal) * 0.98 + Number(anInvoice.header.tax_amount)
                : anInvoice.header.tax === "PPh 23 Tanpa NPWP 4%"
                  ? Number(anInvoice.header.subtotal) * 0.96 + Number(anInvoice.header.tax_amount)
                  : Number(anInvoice.header.total)
              : 0,
            company: anInvoice.header ? anInvoice.header.invoiced_company : "",
            balance: customerAvailableBalance,
            statusFinancing: anInvoice.status ? statusFinancing : "",
            invoiceId: anInvoice.header ? anInvoice.header.id : 0,
            invoiceNumber: anInvoice.header ? anInvoice.header.number : "",
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        />
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Informasi</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Anda harus mendaftar pengajuan dana terlebih dahulu
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton color="info" variant="contained" onClick={navigateToRegisterBorrow} autoFocus>
              Daftar Pengajuan Dana
            </MDButton>
            <MDButton color="error" variant="outlined" onClick={handleClose}>
              Batal
            </MDButton>
          </DialogActions>
        </Dialog>


      </DashboardLayout>
      {renderPelunasanInvoice()}
      <MDBox
        justifyContent="end"
        position="sticky"
        bottom={0}
        sx={{ bgcolor: "#ffffff" }}
        height="65px"
        width="100%"
        py={2}
        pr={3}
        display="flex"
      >
        {render_ubah()}
        {render_terbit()}
        {render_lunas()}
        {render_ajukan_qp()}
        {render_ajukan_if()}
      </MDBox>
    </>
  );
}

export default SelfBillingInvoiceDetail;
