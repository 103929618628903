
const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? { isLoggedIn: true, user, authHeader:{} }
  : { isLoggedIn: false, user: null, authHeader:{} };

export default (state = initialState, action) => {

  switch (action.type) {
    case 'SIGN_IN':
      const tmpAuthHeader = {Authorization: 'Bearer ' +  action.payload.access_token};
      return { ...state, isLoggedIn: true, user: action.payload, authHeader: tmpAuthHeader };
    case 'SIGN_IN_ODOO':
      return {...state, isLoggedInOdoo: true, redirect_uri: action.payload }
    case 'SIGN_OUT':
      return { ...state, isLoggedIn: false, user: null, authHeader: {} };
    case 'FORGET_SUCCESS':
      return { ...state, isForgetSuccess: true };
    case 'FORGET_FAILED':
      return { ...state, isForgetSuccess: false };
    case 'EDIT_SUCCESS':
      return { ...state, isEditSuccess: true };
    case 'EDIT_FAILED':
      return { ...state, isEditSuccess: false };
    default:
      return state;
  }
};