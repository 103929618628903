import { useState, useEffect } from "react";
import { connect, useSelector, useDispatch, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SimpleDashboardNavbar from "examples/Navbars/SimpleDashboardNavbar";
import MDTypography from "components/MDTypography";
import LVDaftarPendana from "components/LVDaftarPendana";
import ReactGA from "react-ga4";
import { fetchAvailablePendana, requestRegisterFintech } from "../../actions/daftarPendanaAction";
import { getCompany, getCompanyKYCStatus } from "../../actions/companyAction";

function DaftarPendana() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/pendana", title: "Pendana" });
  }, []);

  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [transporterId, setTransporterId] = useState(null);
  const [fintechName, setFintechName] = useState("");
  const [fintechId, setFintechId] = useState(null);

  const listDaftarPendana = useSelector((state) => state.listDaftarPendana, shallowEqual);

  const anCompany = useSelector((state) => state.anCompany, shallowEqual);

  const anCompanyKycStatus = useSelector((state) => state.anCompanyKycStatus, shallowEqual);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchAvailablePendana());
    dispatch(getCompany());
    dispatch(getCompanyKYCStatus());
  }, []);

  useEffect(() => {
    if (anCompany.id !== undefined) {
      setTransporterId(anCompany.id);
    }
  }, [anCompany]);

  const onRegister = (id, name) => {

    if (anCompanyKycStatus.status === "created") {
      handleClickOpen();
    } else if (anCompanyKycStatus.status === "approved") {
      setFintechName(name);
      setFintechId(id);
      setOpenApproveDialog(true);
    } else {
      handleClickOpen();
    }
  };

  const onDialogApproveAgree = () => {
    dispatch(
      requestRegisterFintech({
        transporter_id: Number(transporterId),
        fintech_id: Number(fintechId),
      })
    );
    setOpenApproveDialog(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const navigateToRegisterBorrow = () => {
    navigate("/registerborrow");
  };

  return (
    <DashboardLayout>
      <SimpleDashboardNavbar name="Daftar Pendana" />
      <LVDaftarPendana rows={listDaftarPendana} onRegister={onRegister} />
      <Dialog
        open={openApproveDialog}
        onClose={() => setOpenApproveDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Daftar ke Pendana</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Apakah anda yakin untuk mendaftar ke {fintechName}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton
            onClick={onDialogApproveAgree}
            variant="contained"
            color="info"
            size="small"
            autoFocus
          >
            Setuju
          </MDButton>
          <MDButton
            onClick={() => setOpenApproveDialog(false)}
            variant="contained"
            color="error"
            size="small"
          >
            Batal
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Informasi</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Anda harus mendaftar pengajuan dana terlebih dahulu
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton color="info" variant="contained" onClick={navigateToRegisterBorrow} autoFocus>
            Daftar Pengajuan Dana
          </MDButton>
          <MDButton color="error" variant="outlined" onClick={handleClose}>Batal</MDButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default DaftarPendana;
