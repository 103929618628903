/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import formik, { useFormik, useFormikContext, useField, Formik, Form, Field } from "formik";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import ReactGA from "react-ga4";
import { signInOdoo, signIn, forgetPassToken } from "actions/authAction";
import { Icon } from "@mui/material";

import { emptyMessage } from "../../../../actions";

function Basic() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/forget-password", title: "Forget Password" });
  }, []);

  const [successScreen, setSuccess] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setUsername] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showError, setShowError] = useState({
    username: false,
    password: false,
  });
  const [helperMsg, setHelperMsg] = useState({
    username: "Email harus diisi",
    password: "Kata sandi harus diisi",
  });

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    if (email !== "") {
      setShowError({ ...showError, email: false });
    }
  }, [email]);

  const onUsernameBlur = () => {
    if (email.trim() === "") {
      setShowError({ ...showError, email: true });
    }
  };

  const clickForget = () => {
    if (email === "") {
      setShowError({
        ...showError,
        username: email === "",
      });
    } else {
      dispatch(forgetPassToken({ email }));
    }
  };

  const authToken = useSelector((state) => state.authToken);

  useEffect(() => {
    if (authToken.isForgetSuccess) {
      setSuccess(true);
    } else setSuccess(false);
  }, [authToken]);

  return (
    <BasicLayout>
      <MDBox pt={20} pb={3} px={3} textAlign="center">
        <MDTypography
          variant="h6"
          display="flex"
          textAlign="left"
          fontWeight="regular"
          color="dark"
          mt={1}
          component={Link}
          to="/signin"
        >
          <ArrowBackIcon fontSize="medium" color="primary" />
          <MDTypography ml={1} variant="h6">
            Kembali
          </MDTypography>
        </MDTypography>
        {successScreen && (
          <>
            <MDTypography variant="h4" fontWeight="medium" color="dark" mt={5}>
              Instruksi Terkirim
            </MDTypography>
            <MDTypography variant="h6" fontWeight="light" color="dark" mt={1} mb={5}>
              Silahkan cek instruksi pengaturan ulang password di email Anda. Cek juga folder spam Anda.
            </MDTypography>
            <MDBox mt={4} mb={1}>
              <MDButton color="primary" fullWidth onClick={() => navigate("/signin")}>
                OK
              </MDButton>
            </MDBox>
          </>
        )}
        {!successScreen && (
          <>
            <MDTypography variant="h4" fontWeight="medium" color="dark" mt={5}>
              Lupa Password
            </MDTypography>
            <MDTypography variant="h6" fontWeight="light" color="dark" mt={1} mb={5}>
              Silahkan masukan email yang terdaftar, dan kami akan mengirimkan instruksi pengaturan
              ulang password.
            </MDTypography>
            <MDBox component="form" role="form" textAlign="left">
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="Email"
                  fullWidth
                  value={email}
                  onChange={(e) => setUsername(e.target.value)}
                  onBlur={onUsernameBlur}
                  error={showError.username}
                />
                <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                  {showError.username ? helperMsg.username : ""}{" "}
                </MDTypography>
                <MDTypography color="error" px={1} mt={1} sx={{ fontSize: 10 }}>
                  {errorMsg ? "Email Tidak ditemukan" : ""}
                </MDTypography>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton color="primary" fullWidth onClick={clickForget}>
                  Kirim Instruksi
                </MDButton>
              </MDBox>
            </MDBox>
          </>
        )}
      </MDBox>
    </BasicLayout>
  );
}

export default Basic;
