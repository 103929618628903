import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SimpleDashboardNavbar from "examples/Navbars/SimpleDashboardNavbar";
import { searchFinancingFintech } from "actions/borrowerFintechAction";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { initSearchFinancingFintech } from "actions/borrowerFintechAction";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import serverList from "apis/serverList";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

const FinancingCard = ({ name, alias, subTitle, image, kyc_progress, kyc_status, document_kyc_progress, onClick, description }) => {
  const getStatus = (kycStatus, kycProgress, kycDocumentProgress) => {
    let status = '';
    if (parseFloat(kycDocumentProgress) < 100) {
      status = "Pengumpulan Dokumen";
    } else if (parseFloat(kycProgress) < 100) {
      status = "Menunggu Persetujuan";
    } else if (kycStatus == "approved") {
      status = "Disetujui"
    } else {
      status = "Menunggu Persetujuan";
    }
    return status
  }

  return <Card sx={{
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: "100%", p: 0,
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: 'grey',
      cursor: 'pointer',
    },
  }} onClick={onClick} >
    <CardHeader
      title={
        <>
          <Typography variant="h6">{alias ? alias : name}</Typography>
          <Typography variant="subtitle2" color="text.secondary">
            {subTitle}
          </Typography>
        </>
      }
    />
    <CardMedia
      component="img"
      height="194"
      sx={{ width: "auto", objectFit: "contain" }}
      image={image ? serverList.company + image : ""}
      alt={name}
    />
    <CardContent>
      <MDBox display="flex" alignItems="center" mb={1}>
        <Typography
          variant="body2"
          sx={{
            display: "inline-block",
            padding: "5px 10px",
            borderRadius: "10%",
            backgroundColor: "#2196f3",
            color: "#fff",
            marginRight: 1,
          }}
        >
          {kyc_progress.toFixed(0)}%
        </Typography>
        <Typography variant="body2" color="text.secondary"
          sx={{
            // whiteSpace: 'nowrap',
            // overflowX: 'hidden',
            fontSize: '16px'
          }}>
          Status: {getStatus(kyc_status, kyc_progress, document_kyc_progress)}
        </Typography>
      </MDBox>
      <Typography variant="caption" color="text.secondary">
        {description}
      </Typography>
    </CardContent>
  </Card >
}


const FinancingRegistration = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const anCompanyKycStatus = useSelector((state) => state.anCompanyKycStatus, shallowEqual);

  const onClickFintech = (option) => {
    navigate(`/financing/registration/${option.company_id}`);
  }

  const [financingOptions, setFinancingOptions] = useState([]);
  // const [borrowerFintech, setBorrowerFintech] = useState([]);

  const borrowerFintech = useSelector(
    state => {
      return state.borrowerFintech;
    },
    shallowEqual
  );

  useEffect(() => {
  }, [financingOptions]);

  useEffect(() => {
    if (!borrowerFintech) { return; }
    setFinancingOptions(borrowerFintech.message?.result);
  }, [borrowerFintech]);



  useEffect(() => {
    dispatch(initSearchFinancingFintech());
    dispatch(searchFinancingFintech());
  }, []);


  return (
    <DashboardLayout>
      <SimpleDashboardNavbar name="Mengelola Pendanaan" />
      <MDBox mt={3} px={4} >
        <Grid container spacing={1}>
          {Array.isArray(financingOptions) && financingOptions.length > 0 ? financingOptions.map((option) => (
            < Grid item xs={12} sm={6} md={3} key={option.company_id} >
              <FinancingCard {...option} onClick={() => onClickFintech(option)} />
            </Grid>
          )) :
            <Grid item md={12}>
              <MDBox
                display="flex"
                justifyContent="space-between"
                sx={{
                  borderTop:
                    anCompanyKycStatus.status === "rejected" ? "4px solid #F63939" : "4px solid #49a3f1",
                }}
                bgColor="white"
                mt={3}
                px={4}
                py={2}
                shadow="md"
                borderRadius="xl"
              >
                <MDTypography sx={{ fontSize: "12px" }} my="auto">
                  Belum ada Fintech yang terhubung, silahkan hubungi tim Kentara
                </MDTypography>
              </MDBox>
            </Grid>}
        </Grid>
      </MDBox>
    </DashboardLayout >
  );
};

export default FinancingRegistration;
