import { useState, useEffect } from 'react';
import { connect, useSelector, useDispatch, shallowEqual } from "react-redux";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SimpleDashboardNavbar from "examples/Navbars/SimpleDashboardNavbar";
import LVVendor from 'components/LVVendor';
import { fetchSearchVendor, initPelanggan } from '../../actions';
import ReactGA from "react-ga4";
import MDButton from 'components/MDButton';
import VendorModal from 'modals/newVendorModal';


const Vendor = () => {
    const [openVendorModal, setOpenVendorModal] = useState(false)

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/vendor", title: "Vendor" });
    }, []);

    const dispatch = useDispatch();

    const listVendor = useSelector(
        state => {
            return state.searchVendor;
        },
        shallowEqual
    );

    useEffect(() => {
        dispatch(fetchSearchVendor());
        return () => {
            dispatch(initPelanggan());
        }
    }, []);


    return (
        <DashboardLayout>
            <SimpleDashboardNavbar name="Vendor" />
            <MDBox display="flex" justifyContent="space-between">
                <MDBox></MDBox>
                <MDButton
                    sx={{ mt: 5 }}
                    variant="contained"
                    color="info"
                    onClick={(e) => setOpenVendorModal(true)}
                >
                    Buat Vendor Baru
                </MDButton>
            </MDBox>
            <LVVendor
                rows={listVendor}
            />
            <VendorModal
                open={openVendorModal}
                onClose={(e) => setOpenVendorModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                mainPage
            />
        </DashboardLayout>
    );
}

export default Vendor;