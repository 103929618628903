/* eslint-disable no-inner-declarations */
/* eslint-disable prettier/prettier */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 React routes
import appRoutes from "appRoutes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images


import brandWhite from "assets/images/Kentara_3.png";
import brandDark from "assets/images/Kentara_3.png";

import AuthVerify from "./common/AuthVerify";
import { refreshToken, signOut } from './actions/authAction';
import Authentication from "common/Authentication";
import Sidebar from "examples/Sidebar/";
import SessionModal from "modals/sessionModal";
import { emptyMessage } from "actions";

//Testing Commit

export default function App() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [openAlert, setOpenAlert] = useState(false);
  const message = useSelector((state) => state.message, shallowEqual);

  const dispatchMessage = useDispatch();

  useEffect(() => {
    if (message.status !== "") {
      dispatchMessage(emptyMessage());
      switch (message.status) {
        case "TOKEN_EXPIRE":
          setOpenAlert(true);
        default:
      }
    }
  }, [message]);

  const dispatchAction = useDispatch();

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route, index) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component}
          // key={route.key} 
          key={index}
        />;
      }

      return null;
    });

  const getAppRoutes = (appRoutes) => {
    var returnRoutes = [];
    appRoutes.map((route, index) => {
      if (route.child) {
        returnRoutes = [...returnRoutes, ...route.child.map((item) => <Route exact path={item.path} element={item.element} key={item.key} />)]
      } else {
        if (route.path) {
          returnRoutes.push(<Route exact path={route.path} element={route.element}
            // key={route.key} 
            key={index}
          />)
        }
      }
    });
    return returnRoutes;
  }

  const getArrayAppRoutes = (appRoutes) => {
    var returnRoutes = [];
    appRoutes.map((route) => {
      if (route.child) {
        returnRoutes.concat(route.child);
        returnRoutes = [...returnRoutes, ...route.child];
      } else {
        if (route.path) {
          returnRoutes.push(route)
        }
      }
    });
    return returnRoutes;
  }

  const logOut = () => {
    dispatchAction(signOut());
  };

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return direction === "rtl" ? (
    <Authentication routes={getArrayAppRoutes(appRoutes)}>
      <CacheProvider value={rtlCache}>
        <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
          <CssBaseline />
          {layout === "dashboard" && (
            <>
              <Sidebar
                color={sidenavColor}
                brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                brandName=""
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <SessionModal open={openAlert} onClose={() => setOpenAlert(false)} />
            </>
          )}
          {layout === "vr" && <Configurator />}
          <Routes>
            {getAppRoutes(appRoutes)}
            <Route path="*" element={<Navigate to="/signin" />} />
          </Routes>
          <AuthVerify openModal={() => setOpenAlert(true)} />
        </ThemeProvider>
      </CacheProvider>
    </Authentication>

  ) : (
    <Authentication routes={getArrayAppRoutes(appRoutes)}>
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidebar
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName=""
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <SessionModal open={openAlert} onClose={() => setOpenAlert(false)} />
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes >
          {getAppRoutes(appRoutes)}
          <Route path="*" element={<Navigate to="/signin" />} />
        </Routes>
        <AuthVerify openModal={() => setOpenAlert(true)} />
      </ThemeProvider>
    </Authentication>
  );
}
