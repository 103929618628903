import serverList from "../apis/serverList";
import axios from "axios";
import authHeader from "../apis/authHeader";

export const createNewVendor = (company) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI.post("/api/v1/company/vendor", company).catch((e) => {
    dispatch({
      type: "ERROR",
      payload: { status: "ERROR", message: e.response.data.message },
    });
  });
  if (response !== undefined) {
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
    if (response.data.status === "OK") {
      dispatch({
        type: "SUCCESS_NEW_VENDOR",
        payload: { status: "SUCCESS_NEW_VENDOR", message: response.data.data },
      });
      const { data } = await companyAPI.get("/api/v1/companies/vendor/search");
      const tempData = [];
      data.data.map((isi) => {
        tempData.push({
          id: isi.id,
          value: isi.id,
          label: isi.name,
          npwp: isi.tin,
          pic_name: isi.pic_name,
          type: isi.type,
        });
      });

      dispatch({
        type: "FETCH_VENDOR",
        payload: tempData,
      });
    } else {
      dispatch({
        type: "ERROR",
        payload: { status: "ERROR", message: response.data.message },
      });
    }
  }
};