import { emptyMessage } from "actions";
import { signOut } from "actions/authAction";
import { refreshToken } from "actions/authAction";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  overflow: "auto",
  p: 4,
};

function SessionAlert(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  const message = useSelector((state) => state.message, shallowEqual);
  const dispatch = useDispatch();
  const logOut = () => {
    dispatch(signOut());
    props.onClose();
  };
  const refreshTokenFunc = () => {
    dispatch(refreshToken(user.refresh_token));
  };

  useEffect(() => {
    if (message.status !== "") {
      dispatch(emptyMessage());
      switch (message.status) {
        case "SUCCESS_REFRESH_TOKEN":
          window.location.reload();
          return;
        case "ERROR_REFRESH_TOKEN":
          logOut();
          return;
        default:
      }
    }
  }, [message]);

  return (
    <div>
      <MDBox sx={style}>
        <MDBox display="flex" justifyContent="center">
          <div>Sesi anda telah berakhir!</div>
        </MDBox>
        <MDBox display="flex" justifyContent="center" mt={5}>
          <img src="/alert.png" alt="" width={200} height={200} mx="auto" />
        </MDBox>
        <MDBox display="flex" justifyContent="center" mt={5}>
          <MDButton onClick={logOut} variant="outlined" fullWidth sx={{ mx: 3 }} color="info">
            Logout
          </MDButton>
          <MDButton
            onClick={refreshTokenFunc}
            variant="contained"
            fullWidth
            sx={{ mx: 3 }}
            color="info"
          >
            Perpanjang Sesi
          </MDButton>
        </MDBox>
      </MDBox>
    </div>
  );
}

export default SessionAlert;
