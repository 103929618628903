import { useState, useEffect } from "react";

import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

function LVFileInput(props) {
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (props.srcImage) {
      const extension = props.srcImage.split(".").pop().toLowerCase();
      switch (extension) {
        case "pdf":
          setImage("/pdf_logo_bw.jpg");
          break;
        case "zip":
          setImage("/compress_logo.png");
          break;
        case "rar":
          setImage("/compress_logo.png");
          break;
        case "7z":
          setImage("/compress_logo.png");
          break;
        default:
          setImage(props.srcImage);
      }
    }

    return () => {
      setImage(null);
    };
  });

  return (
    <div>
      <MDTypography
        color="dark"
        align="left"
        py={0}
        mt={4}
        mb={0}
        sx={{ fontSize: props.titleFontSize ? props.titleFontSize : 12 }}
        fontWeight="reguler"
      >
        {" "}
        {props.title}
      </MDTypography>
      <MDBox
        align="center"
        direction="column"
        alignItems="center"
        // mx={2}
        // mb={2}
        sx={{
          border: "1px solid #9DB7CD",
          borderRadius: 2,
          // height: "48px",
          width: "300px",
        }}
      >
        <MDButton
          component="label"
          disabled={props.buttonDisabled}
          sx={{
            // height: props.height - 2,
            // my: "auto",
            // py: 1,
            px: 2,
            width: "100%",
            position: "relative",
            overflow: "hidden",
            justifyContent: "space-between",
          }}
        >
          {/* <img
            hidden={props.srcImage === null}
            src={image}
            layout="fill"
            sx={{ width: "100%", height: "100%", objectFit: "fill" }}
          /> */}
          <input
            type="file"
            id={props.id}
            ref={props.refinput}
            hidden
            disabled={props.srcImage !== null || props.viewOnly}
            onChange={props.onChangeFile}
            accept={props.accept}
          />
          <MDBox
            width="100%"
            sx={{ justifyContent: "space-between" }}
            display={props.srcImage !== null ? "none" : "flex"}
          >
            <MDTypography
              hidden={props.srcImage !== null}
              align="left"
              color="dark"
              fontWeight="regular"
              // px={1}
              sx={{ fontSize: 14, width: "100%" }}
            >
              {" "}
              {props.text1
                ? props.text1.length <= 30
                  ? props.text1
                  : `${props.text1.substr(0, 29)}...`
                : ""}
            </MDTypography>
            <MDBox display={props.srcImage !== null ? "none" : "block"} sx={{ cursor: "pointer" }}>
              <img
                hidden={props.srcImage !== null}
                src="/upload.svg"
                layout="fill"
                sx={{ width: "100%", height: "100%", objectFit: "fill" }}
              />
            </MDBox>
          </MDBox>
          <MDBox width="100%" justifyContent="space-between" display={props.srcImage === null ? "none" : "flex"}>
            <MDTypography
              hidden={props.srcImage === null}
              color="info"
              px={1}
              fontWeight="bold"
              sx={{ fontSize: 14 }}
              my="auto"
            >
              {" "}
              {props.id}
            </MDTypography>
            <MDBox display="flex">
              <MDBox
                display={props.viewOnly ? "none" : "block"}
                onClick={props.deleteFile}
                sx={{ cursor: "pointer" }}
                mr={1}
              >
                <MDTypography variant="h6" color="secondary" hidden={props.srcImage === null}>
                  <Icon sx={{ fontWeight: "bold" }}>delete_outline</Icon>
                </MDTypography>
              </MDBox>
              <MDBox display={props.disableDownload ? "none" : "block"} sx={{ cursor: "pointer" }}>
                <a download href={props.srcFile ? props.srcFile : props.srcImage}>
                  <MDTypography variant="h6" color="secondary" hidden={props.srcImage === null}>
                    <Icon sx={{ fontWeight: "bold" }}>download</Icon>
                  </MDTypography>
                </a>
              </MDBox>
            </MDBox>
            {/* <MDTypography
              hidden={props.srcImage !== null}
              color="text"
              px={1}
              sx={{ fontSize: props.fontSize ? props.fontSize : 12 }}
            >
              {" "}
              {props.text2}{" "}
            </MDTypography>
            <MDTypography
              hidden={props.srcImage !== null}
              color="text"
              px={1}
              sx={{ fontSize: props.fontSize ? props.fontSize : 12 }}
            >
              {" "}
              {props.text3}{" "}
            </MDTypography> */}
          </MDBox>
        </MDButton>
      </MDBox>
      <Tooltip title={props.subtitle}>
        <MDTypography
          color={props.subtitle === "Ditolak - foto kurang jelas" ? "error" : props.subtitle === "Sudah Disetujui" ? "success" : "warning"}
          align="left"
          mt={1}
          sx={{ fontSize: props.subtitleFontSize ? props.subtitleFontSize : 12 }}
          fontWeight="regular"
        >
          {" "}
          {props.subtitle
            ? props.subtitle.length <= 40
              ? props.subtitle
              : `${props.subtitle.substr(0, 39)}...`
            : ""}
        </MDTypography>
      </Tooltip>
    </div>
  );
}

export default LVFileInput;
