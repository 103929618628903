import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import Modal from '@mui/material/Modal';
import Grid from "@mui/material/Grid";

import LVProductDataGrid from '../components/LVProductDataGrid';
import MDTypography from "components/MDTypography";

import MDBox from "components/MDBox";
import MDButton from 'components/MDButton';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 550,
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
};

const InvoiceDetailModal = (props) => {

    const listProduct = useSelector(
        state => {
            return state.listProduct;
        },
        shallowEqual
    );

    useEffect(() => {
        //dispatch(initProductList());
    }, []);

    return (
        <Modal
            {...props}
        >
            <MDBox sx={style}>
                <Grid container direction="column" justifyContent="space-around" spacing={3}>
                    <Grid item>
                        <LVProductDataGrid
                            viewOnly
                        />
                    </Grid>
                    <Grid item>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <MDButton onClick={props.onClose}>
                                    Tutup
                                </MDButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </MDBox>
        </Modal>
    );
}

export default InvoiceDetailModal;

