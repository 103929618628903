import { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { matchPath } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SimpleDashboardNavbar from "examples/Navbars/SimpleDashboardNavbar";
import LVPersetujuanDataGrid from "components/LVPersetujuanDataGrid";
import PersetujuanModal from "modals/persetujuanModal";
import FundTransferModal from "modals/fundTransferModal";
import ReactGA from "react-ga4";
import {
  fetchSearchFinancing,
  rejectFinancing,
  fetchContractorSearchFinancing,
  approveFinancingQP,
  fetchFintechSearchQuickpay,
  initPersetujuanList,
} from "../../actions/fintechAction";
import { transformPengajuan } from "../../common/Util";
import LVPersetujuanPaylaterDataGrid from "components/LVPersetujuanPaylaterDataGrid";

function PersetujuanPengajuan() {
  const [rows, setRows] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openFundModal, setOpenFundModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogQP, setOpenDialogQP] = useState(false);
  const [idfinancing, setIdFinancing] = useState(null);
  const [financingtype, setFinancingType] = useState(null);
  const [rowfinancing, setRowFinancing] = useState(null);
  const [numberFinancing, setNumberFinancing] = useState(null);
  const [textKeterangan, setTextKeterangan] = useState("");
  const [currentLink, setCurrentLink] = useState("");


  const [queryParameter, setQueryParameter] = useState({
    page: 1, orderBy: "id", order: "desc", keyword: "", limit: 10,
    status: [], type: [], invoicingCompany: "", termOfPayment: 0
  })

  const [keywordTimeout, setKeywordTimeout] = useState();

  const [page, setPage] = useState(1);

  const [loading, setLoading] = useState(false);

  const resetPaginationState = () => {
    setQueryParameter({
      page: 1, orderBy: "id", order: "desc", keyword: "", limit: 10,
      status: [], type: [], invoicingCompany: "", termOfPayment: 0
    })
  }

  const resetFilter = () => {
    setQueryParameter({
      ...queryParameter,
      status: [], type: [], invoicingCompany: "", termOfPayment: 0
    })
  }

  const financingList = useSelector(
    state => {
      return state.financingList;
    },
    shallowEqual
  );

  const financingListPagination = useSelector(
    state => {
      return state.financingListPagination;
    },
    shallowEqual
  );

  const message = useSelector((state) => state.message, shallowEqual);

  const matchFin = matchPath(
    {
      path: "/pengajuanfin",
      exact: true,
      strict: true,
    },
    location.pathname
  );

  const matchQP = matchPath(
    {
      path: "/pengajuanqp",
      exact: true,
      strict: true,
    },
    location.pathname
  );

  const matchPembayaranQP = matchPath(
    {
      path: "/pembayaranqp",
      exact: true,
      strict: true,
    },
    location.pathname
  );

  useEffect(() => {
  }, [queryParameter]);

  useEffect(() => {
    if (matchQP) {
      setCurrentLink("pengajuanqp");
    } else if (matchPembayaranQP) {
      setCurrentLink("pembayaranqp");
    } else if (matchFin) {
      setCurrentLink("pengajuanfin");
    }
  });


  const dispatch = useDispatch();

  const title = matchPembayaranQP
    ? "Pembayaran"
    : "Persetujuan".concat(" ", matchQP || matchPembayaranQP ? "PayLater" : "Pengajuan Dana");
  const url = ` ${matchPembayaranQP ? "Pembayaran" : "Persetujuan"}-${matchQP || matchPembayaranQP ? "PayLater" : "Pengajuan Dana"
    }`;
  const columnType = matchFin ? 'fintech' : 'default';

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", url, title });
  }, []);

  useEffect(() => {
    if (currentLink === "") {
      return;
    }

    resetPaginationState();
    setLoading(true);
    if (matchQP) {
      dispatch(fetchContractorSearchFinancing(queryParameter));
    } else if (matchPembayaranQP) {
      dispatch(fetchFintechSearchQuickpay(queryParameter));
    } else {
      dispatch(fetchSearchFinancing(queryParameter));
    }
  }, [currentLink]);

  useEffect(() => {
    financingListPagination.limit = parseInt(queryParameter.limit);

    try {
      setRows(transformPengajuan(financingList, financingListPagination));
    } catch (e) {
      setLoading(false);
    }
    setLoading(false);

  }, [financingList, financingListPagination]);

  useEffect(() => {
    if (currentLink === "") {
      return;
    }

    setLoading(true);
    if (matchQP) {
      dispatch(fetchContractorSearchFinancing(queryParameter));
    } else if (matchPembayaranQP) {
      dispatch(fetchFintechSearchQuickpay(queryParameter));
    } else {
      dispatch(fetchSearchFinancing(queryParameter));
    }
  }, [message, queryParameter]);

  const handleCloseAgree = () => {
    dispatch(rejectFinancing({ financing_id: idfinancing, reason: textKeterangan }));

    setLoading(true);
    if (matchQP) {
      dispatch(fetchContractorSearchFinancing(queryParameter));
    } else if (matchPembayaranQP) {
      dispatch(fetchFintechSearchQuickpay(queryParameter));
    } else {
      dispatch(fetchSearchFinancing(queryParameter));
    }
    setOpenDialog(false);
  };

  const handleCloseAgreeQP = () => {
    dispatch(approveFinancingQP({ financing_id: idfinancing }));
    setOpenDialogQP(false);
    setLoading(true);
    if (matchQP) {
      dispatch(fetchContractorSearchFinancing(queryParameter));
    } else if (matchPembayaranQP) {
      dispatch(fetchFintechSearchQuickpay(queryParameter));
    } else {
      dispatch(fetchSearchFinancing(queryParameter));
    }
  };

  const handleCloseCancel = () => {
    setOpenDialog(false);
  };

  const onChangeKeywordHandler = (e) => {
    let keyword = e.target.value;
    if (keyword.length >= 3) {
      clearTimeout(keywordTimeout);
      setKeywordTimeout(setTimeout(() => setKeyword(keyword), 500));
      return
    }
  }

  const onKeyDownHandler = (e) => {
    if (e.keyCode == 13) {
      let keyword = e.target.value;
      setKeyword(keyword);
      return
    }
  }

  const loadingCloseHandler = (e) => {
    setLoading(false);
  }

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={loadingCloseHandler}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DashboardLayout>
        <SimpleDashboardNavbar
          name={`${matchPembayaranQP ? "Pembayaran" : "Persetujuan"} ${matchQP || matchPembayaranQP ? "PayLater" : "Pengajuan Dana"
            }`}
        />
        <Grid container spacing={3} pt={5} justifyContent="space-between">
          <Grid item />
          <Grid item width="70%">
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <MDBox pr={2} my="auto">
                  <MDInput label="Cari nomor financing.." sx={{ width: "300px" }}
                    onChange={(e) => onChangeKeywordHandler(e)}
                    onKeyDown={(e) => onKeyDownHandler(e)} />
                </MDBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <MDBox mt={1}>
          {matchPembayaranQP ? (
            <LVPersetujuanPaylaterDataGrid
              firstPage={() => setQueryParameter({ ...queryParameter, page: 1 })}
              lastPage={() => setQueryParameter({ ...queryParameter, page: financingListPagination.total_pages })}
              totalPage={financingListPagination.total_pages}
              currentPage={queryParameter.page}
              selectPage={(i) => setQueryParameter({ ...queryParameter, page: parseInt(i.target.textContent) })}
              nextPage={() => setQueryParameter({ ...queryParameter, page: financingListPagination.next_page })}
              prevPage={() => setQueryParameter({ ...queryParameter, page: financingListPagination.prev_page })}
              order={(orderBy, order) => setQueryParameter({ ...queryParameter, orderBy, order })}

              status={(status) => setQueryParameter({ ...queryParameter, status })}
              type={(type) => setQueryParameter({ ...queryParameter, type })}
              invoicingCompany={(invoicingCompany) => setQueryParameter({ ...queryParameter, invoicingCompany })}
              termOfPayment={(termOfPayment) => setQueryParameter({ ...queryParameter, termOfPayment })}
              resetFilter={(e) => resetFilter(e)}

              height="100%"
              autoHeight
              rows={rows}
              columnType={columnType}
              onPersetujuan={(e) => {
                setIdFinancing(e.id);
                setRowFinancing(e);
                setOpenModal(true);
              }}
              onPenolakan={(id, number) => {
                setIdFinancing(id);
                setNumberFinancing(number);
                setOpenDialog(true);
              }}
              onPersetujuanQP={(e) => {
                setIdFinancing(e.id);
                setRowFinancing(e);
                setOpenDialogQP(true);
              }}
              onTransfer={(id) => {
                setIdFinancing(id);
                setFinancingType("invoice");
                setOpenFundModal(true);
              }}
              onPaymentQP={(id) => {
                setIdFinancing(id);
                setFinancingType("quickpay");
                setOpenFundModal(true);
              }}
            />
          ) : (
            <LVPersetujuanDataGrid
              firstPage={() => setQueryParameter({ ...queryParameter, page: 1 })}
              lastPage={() => setQueryParameter({ ...queryParameter, page: financingListPagination.total_pages })}
              totalPage={financingListPagination.total_pages}
              currentPage={queryParameter.page}
              selectPage={(i) => setQueryParameter({ ...queryParameter, page: parseInt(i.target.textContent) })}
              nextPage={() => setQueryParameter({ ...queryParameter, page: financingListPagination.next_page })}
              prevPage={() => setQueryParameter({ ...queryParameter, page: financingListPagination.prev_page })}
              order={(orderBy, order) => setQueryParameter({ ...queryParameter, orderBy, order })}

              status={(status) => setQueryParameter({ ...queryParameter, status })}
              type={(type) => setQueryParameter({ ...queryParameter, type })}
              invoicingCompany={(invoicingCompany) => setQueryParameter({ ...queryParameter, invoicingCompany })}
              termOfPayment={(termOfPayment) => setQueryParameter({ ...queryParameter, termOfPayment })}
              resetFilter={(e) => resetFilter(e)}

              height="100%"
              autoHeight
              rows={rows}
              columnType={columnType}
              onPersetujuan={(e) => {
                setIdFinancing(e.id);
                setRowFinancing(e);
                setOpenModal(true);
              }}
              onPenolakan={(id, number) => {
                setIdFinancing(id);
                setNumberFinancing(number);
                setOpenDialog(true);
              }}
              onPersetujuanQP={(e) => {
                setIdFinancing(e.id);
                setRowFinancing(e);
                setOpenDialogQP(true);
              }}
              onTransfer={(id) => {
                setIdFinancing(id);
                setFinancingType("invoice");
                setOpenFundModal(true);
              }}
              onPaymentQP={(id) => {
                setIdFinancing(id);
                setFinancingType("quickpay");
                setOpenFundModal(true);
              }}
            />
          )}
        </MDBox>

        <PersetujuanModal
          open={openModal}
          idfinancing={idfinancing}
          rowfinancing={rowfinancing}
          onClose={(e) => {
            setOpenModal(false);
            if (matchQP) {
              dispatch(fetchContractorSearchFinancing(queryParameter));
            } else if (matchPembayaranQP) {
              dispatch(fetchFintechSearchQuickpay(queryParameter));
            } else {
              dispatch(fetchSearchFinancing(queryParameter));
            }
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        />
        <FundTransferModal
          open={openFundModal}
          idfinancing={idfinancing}
          financingtype={financingtype}
          onClose={(e) => {
            setOpenFundModal(false);
            if (matchQP) {
              dispatch(fetchContractorSearchFinancing(queryParameter));
            } else if (matchPembayaranQP) {
              dispatch(fetchFintechSearchQuickpay(queryParameter));
            } else {
              dispatch(fetchSearchFinancing(queryParameter));
            }
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        />
        <Dialog
          open={openDialogQP}
          onClose={() => {
            setOpenDialogQP(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Setujui Pengajuan</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Apakah anda yakin untuk menyetujui pengajuan {numberFinancing}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton
              onClick={handleCloseAgreeQP}
              variant="contained"
              color="info"
              size="small"
              autoFocus
            >
              Setujui
            </MDButton>
            <MDButton
              onClick={() => {
                setOpenDialogQP(false);
              }}
              variant="contained"
              color="error"
              size="small"
            >
              Batal
            </MDButton>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openDialog}
          onClose={handleCloseCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Tolak Pengajuan</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Apakah anda yakin untuk menolak pengajuan {numberFinancing}?
            </DialogContentText>
            <MDBox
              sx={{
                width: "100%",
              }}
              mt={2}
            >
              <MDInput
                id="keterangan"
                name="keterangan"
                multiline
                onChange={(e) => setTextKeterangan(e.target.value)}
                value={textKeterangan}
                rows={5}
                label="Alasan Penolakan"
                placeholder="Isi dengan alasan penolakan"
                fullWidth

              />
            </MDBox>
          </DialogContent>
          <DialogActions>
            <MDButton
              onClick={handleCloseAgree}
              variant="contained"
              color="error"
              size="small"
              autoFocus
            >
              Tolak
            </MDButton>
            <MDButton onClick={handleCloseCancel} variant="contained" color="info" size="small">
              Batal
            </MDButton>
          </DialogActions>
        </Dialog>
      </DashboardLayout>
    </div>
  );
}

export default PersetujuanPengajuan;
