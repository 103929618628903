import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import Grid from "@mui/material/Grid";
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import { styled } from '@mui/material/styles';

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import LVPelunasanInvoiceDataGrid from "components/LVPelunasanInvoiceDataGrid";
import { updateStatus } from '../../actions/detailInvoiceAction';
import { payInvoiceFinancing } from "actions/fintechAction";
import {emptyMessage} from '../../actions/';
import { Icon } from "@mui/material";


const Input = styled(MuiInput)`
  width: 'auto';
`;

const PelunasanInvoice = (props) => {

    const [namaPelanggan, setNamaPelanggan] = useState('PT Test Awal');
    const [danaTersedia, setDanaTersedia] = useState(20000000);
    const [sisaTagihan, setSisaTagihan] = useState(21000000);
    const [pelunasan, setPelunasan] = useState(20000000);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,

        bgcolor: 'background.paper',
        borderRadius: 2,
        boxShadow: 24,
        overflow:"auto",
        p: 4,
    };
    
    const [showError, setShowError] = useState({  
        financing: false,         
        all: false,     
        fail: false,   
    });
    const [helperMsg, setHelperMsg] = useState({        
        financing: '',
        all: 'Masih terdapat informasi wajib yang kosong, silakan diperiksa kembali.',     
        fail: ''  
    });

    const dispatch = useDispatch('');

    const message = useSelector(
        state => {
          return state.message;
        },
        shallowEqual
    );

    useEffect(() => {        
        if (message.status !==''){
            dispatch(emptyMessage());
            switch(message.status) {
                case 'SUCCESS_PAY_INVOICE_FINANCING':                    
                    props.onClose();
                    return;                
                default:
                    return;
            }            
        }
 
    }, [message]); 

    const onSimpan = () => {
        dispatch(payInvoiceFinancing(props.datapengajuan.requestId));
        dispatch(updateStatus({
            invoice_id: props.datapengajuan.invoiceId,
            type: "invoice",
            status: "paid",
            number: props.datapengajuan.invoiceNumber,
        }
        ));
    }

    const handleSliderChange = (event, newValue) => {
        setPelunasan(newValue);
    };
    
    const handleInputChange = (event) => {
        setPelunasan(event.target.value === '' ? '' : Number(event.target.value));
    };

    const handleBlur = () => {
        if (pelunasan < 0) {
          setPelunasan(0);
        } else {
            if (sisaTagihan < danaTersedia) {
                if (pelunasan > sisaTagihan) 
                {
                    setPelunasan(sisaTagihan);
                }              
            } else {
                if (pelunasan > danaTersedia) 
                {
                    setPelunasan(danaTersedia);
                }    
            }
        }                 
      };
    
    
    return (
        <MDBox sx={style}>
                
                <MDBox justifyContent="space-around">
                    <MDBox display="flex">
                        <Icon color="info" fontSize="large">check_circle_outline</Icon>
                        <MDTypography ml={2} variant="title" fontWeight="medium">
                            Pelunasan Invoice
                        </MDTypography>
                    </MDBox>                   
                    <MDBox mt={5}>
                        <MDTypography variant="body2" fontWeight="regular">
                            Apakah Anda yakin ingin melunasi invoice ini?
                        </MDTypography>
                    </MDBox>  
                    <MDBox display="flex" justifyContent="space-between" mt={3}>
                        <MDTypography variant="body2" fontWeight="regular" sx={{ color: "#72737B"}}>
                             {props.datapengajuan.company}:
                        </MDTypography>                        
                        <MDTypography variant="body2" fontWeight="bold" sx={{textAlign: "right"}}>
                            {Number(props.datapengajuan.balance).toLocaleString("id-ID", {style:"currency", currency:"IDR"})}
                        </MDTypography>                        
                    </MDBox>             
                    <MDBox display="flex" justifyContent="space-between" mt={1}>
                        <MDTypography variant="body2" fontWeight="regular" sx={{ color: "#72737B"}}>
                            Total Besaran Invoice:
                        </MDTypography>                        
                        <MDTypography variant="body2" fontWeight="bold" sx={{textAlign: "right"}}>
                            {Number(props.datapengajuan.totalInvoice).toLocaleString("id-ID", {style:"currency", currency:"IDR"})}
                        </MDTypography>                        
                    </MDBox>
                    <MDBox mt={4}>
                        <MDBox display="flex" justifyContent="flex-end" alignItems="right" px={3} mt={1}>
                                <MDTypography color="error" px={1} sx={{fontSize:10}}>{showError.all?helperMsg.all:''} </MDTypography>    
                                <MDTypography color="error" px={1} sx={{fontSize:10}}>{showError.fail?helperMsg.fail:''} </MDTypography>
                                <MDTypography color="error" px={1} sx={{fontSize:10}}>{(props.datapengajuan.statusFinancing !=="Fund Transferred")?'Pelunasan dapat dilakukan setelah status pencairan invoice adalah \"Dana Ditransfer\"':(Number(props.datapengajuan.balance)<Number(props.datapengajuan.totalInvoice)?'Pelunasan dapat dilakukan apabila dana masuk tersedia lebih besar dari total besaran invoice':'')} </MDTypography> 
                            <MDBox mr={2}>
                                <MDButton variant="contained" color="info" mt={1} size="small" onClick={onSimpan} disabled={(props.datapengajuan.statusFinancing!=="Fund Transferred") || Number(props.datapengajuan.balance)<Number(props.datapengajuan.totalInvoice)}>
                                    Lunasi
                                </MDButton>
                            </MDBox>
                            <MDBox>
                                <MDButton variant="contained" color="error" mt={1} size="small" onClick={props.onClose}>
                                    Batal
                                </MDButton>
                            </MDBox>                    
                        </MDBox>
                    </MDBox>
                </MDBox>     
        </MDBox>
    );
}

export default PelunasanInvoice;