/* eslint-disable default-case */
import { valid } from "chroma-js";
import serverList from "../apis/serverList";
import { convertMonthFormat } from "./month";

export const toTitleCase = (str) => {
  return str
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const getTaxList = () => {
  return [
    { value: 1, label: "PPN 1% Exclusive" },
    { value: 2, label: "PPN 1% Inclusive" },
    { value: 3, label: "PPN 1.1% Exclusive" },
    { value: 4, label: "PPN 1.1% Inclusive" },
    { value: 5, label: "PPN 10% Exclusive" },
    { value: 6, label: "PPN 10% Inclusive" },
    { value: 7, label: "PPN 11% Exclusive" },
    { value: 8, label: "PPN 11% Inclusive" },
    { value: 9, label: "Tanpa PPN" },
  ];
}

export const convertTaxId = (tax) => {
  switch (tax) {
    case "PPN 1% Exclusive":
      return 1;
    case "PPN 1% Inclusive":
      return 2;
    case "PPN 1.1% Exclusive":
      return 3;
    case "PPN 1.1% Inclusive":
      return 4;
    case "PPN 10% Exclusive":
      return 5;
    case "PPN 10% Inclusive":
      return 6;
    case "PPN 11% Exclusive":
      return 7;
    case "PPN 11% Inclusive":
      return 8;
    case "Tanpa PPN":
      return 9;
    default:
      return 0;
  }
};

export const getTaxPphList = () => {
  return [
    { value: 1, label: "PPh 23 NPWP 2% Exclusive" },
    // { value: 2, label: "PPh 23 NPWP 2% Inclusive" },
    { value: 3, label: "PPh 23 Tanpa NPWP 4% Exclusive" },
    // { value: 4, label: "PPh 23 Tanpa NPWP 4% Inclusive" },
    { value: 5, label: "Tanpa PPh 23" },
  ]
}

export const convertTaxPphId = (tax) => {
  switch (tax) {
    case "PPh 23 NPWP 2% Exclusive":
      return 1;
    case "PPh 23 NPWP 2% Inclusive":
      return 2;
    case "PPh 23 Tanpa NPWP 4% Exclusive":
      return 3;
    case "PPh 23 Tanpa NPWP 4% Inclusive":
      return 4;
    case "Tanpa PPh 23":
      return 5;
    default:
      return 0;
  }
};

export const getTaxDetail = (taxID) => {
  let taxDetail = {
    1: { "value": 1, "type": "exclusive", },
    2: { "value": 1, "type": "inclusive", },
    3: { "value": 1.1, "type": "exclusive", },
    4: { "value": 1.1, "type": "inclusive", },
    5: { "value": 10, "type": "exclusive", },
    6: { "value": 10, "type": "inclusive", },
    7: { "value": 11, "type": "exclusive", },
    8: { "value": 11, "type": "inclusive", },
    9: { "value": 0, "type": "exclusive", },
  }
  if (taxDetail[taxID] !== null) {
    return taxDetail[taxID]
  } else {
    return { "value": 0, "type": "exclusive" }
  }
};

export const getTaxPphDetail = (taxID) => {
  let taxDetail = {
    1: { "value": 2, "type": "exclusive", },
    2: { "value": 2, "type": "inclusive", },
    3: { "value": 4, "type": "exclusive", },
    4: { "value": 4, "type": "inclusive", },
    5: { "value": 0, "type": "exclusive", },
  }

  if (taxDetail[taxID] !== null) {
    return taxDetail[taxID]
  } else {
    return { "value": 0, "type": "exclusive" }
  }
};

export const calculateTaxValue = (amount, taxID) => {
  let taxDetail = getTaxDetail(taxID);
  let taxAmount = 0;

  if (taxDetail["type"] === "exclusive") {
    taxAmount = amount * (taxDetail["value"] / 100);
    amount = amount;
  }
  else if (taxDetail["type"] === "inclusive") {
    amount = amount * 100 / (100 + taxDetail["value"]);
    taxAmount = amount * taxDetail["value"] / 100;
  }

  return [amount, taxAmount]
}

export const calculateTaxPphValue = (amount, taxPphID) => {
  let taxDetail = getTaxPphDetail(taxPphID);
  let taxAmount = 0;

  if (taxDetail["type"] === "exclusive") {
    taxAmount = amount * (taxDetail["value"] / 100);
    amount = amount;
  }
  else if (taxDetail["type"] === "inclusive") {
    amount = amount * 100 / (100 + taxDetail["value"]);
    taxAmount = amount * taxDetail["value"] / 100;
  }

  return [amount, taxAmount]
}



export const transformStatusDocument = (status) => {
  switch (status.toLowerCase()) {
    case "created":
      return "Belum Dikirim";
    case "delivered":
      return "Sudah Dikirim";
    case "approved":
      return "Disetujui Pelanggan";
    case "rejected":
      return "Ditolak Pelanggan";
    case "failed delivery":
      return "Pengiriman Gagal";
    default:
      return status;
  }
};

export const convertRequestType = (type) => {
  switch (type.toLowerCase()) {
    case "invoice":
      return "Invoice Financing";
    case "purchase":
      return "Order Financing";
    case "quickpay":
      return "PayLater";
    default:
      return type;
  }
};

export const transformStatusPengajuan = (item) => {
  var tmpstatus = "";

  if (item.status.toLowerCase() === "created") {
    tmpstatus = "Belum Mengajukan";
  } else if (item.status.toLowerCase() === "submitted") {
    if (item.type.toLowerCase() === "invoice") {
      tmpstatus = "Menunggu Pendana";
    } else if (item.type.toLowerCase() === "purchase") {
      tmpstatus = "Menunggu Pendana";
    } else if (item.type.toLowerCase() === "quickpay") {
      tmpstatus = "Menunggu Persetujuan";
    } else {
      tmpstatus = item.status;
    }
  } else if (item.status.toLowerCase() === "approved") {
    if (item.type.toLowerCase() === "invoice") {
      tmpstatus = "Menunggu Transfer";
    } else if (item.type.toLowerCase() === "purchase") {
      tmpstatus = "Aktif";
    } else if (item.type.toLowerCase() === "quickpay") {
      tmpstatus = "Disetujui";
    } else {
      tmpstatus = item.status;
    }
  } else if (item.status.toLowerCase() === "fund_transferred") {
    tmpstatus = "Dana Ditransfer";
  } else if (item.status.toLowerCase() === "fund_paid") {
    tmpstatus = "Lunas";
  } else if (item.status.toLowerCase() === "rejected") {
    tmpstatus = "Ditolak";
  } else {
    tmpstatus = item.status;
  }
  return tmpstatus;
};

export const transformPengajuan = (list, pagination) => {
  const showPengajuan = list.map((item, idx) => {
    var tmpstatus = transformStatusPengajuan(item);
    let baseCounter = (pagination.current_page - 1) * pagination.limit;
    if (item.due_date === "31 Desember 2100") {
      item.due_date = "N/A";
    }
    return { ...item, status_readable: tmpstatus, no: baseCounter + idx + 1 };
  });
  return showPengajuan;
};


export const transformUserList = (list, pagination) => {
  const showUserList = list.map((item, idx) => {
    let baseCounter = (pagination.current_page - 1) * pagination.limit;
    return { ...item, no: baseCounter + idx + 1 };
  });
  return showUserList;
};


export const transformStatusParent = (status) => {
  switch (status.toLowerCase()) {
    case "":
      return "Belum Diajukan";
    case "created":
      return "Belum Diajukan";
    case "submitted":
      return "Menunggu Pendana";
    case "approved":
      return "Aktif";
    case "rejected":
      return "Ditolak";
    default:
      return status;
  }
};

export const transformInvoices = (invoices) => {
  const showInvoices = invoices.map((isi, idx) => {
    var statusInvoice = "";
    var statusFinancing = "";
    var statusDocument = "";
    var statusDocumentReadable = "";
    var statusFinancingTemp = "";
    var statusParent = "";
    var statusParentReadable = "";
    var orderId = -1;
    if (isi.status) {
      isi.status.map((item) => {
        if (item.type.toLowerCase() === "invoice") {
          statusInvoice = item.status;
        } else if (item.type.toLowerCase() === "financing") {
          statusFinancing = item.status;
          statusFinancingTemp = transformStatusPengajuan({ status: item.status, type: "invoice" });
        } else if (item.type.toLowerCase() === "document") {
          statusDocument = item.status;
          statusDocumentReadable = transformStatusDocument(item.status);
        } else if (item.type.toLowerCase() === "parent_financing") {
          statusParent = item.status;
          statusParentReadable = transformStatusParent(item.status);
        }
      });
    }
    if (isi.sale_order_id === 0) {
      orderId = isi.purchase_order_id;
    } else {
      orderId = isi.sale_order_id;
    }
    const { status, ...tmpIsi } = isi;
    return {
      ...tmpIsi,
      no: idx + 1,
      id: isi.id,
      order_id: orderId,
      statusInvoice,
      statusFinancing,
      statusDocument,
      statusDocument_readable: statusDocumentReadable,
      statusFinancing_readable: statusFinancingTemp,
      statusParent,
      statusParent_readable: statusParentReadable,
    };
  });
  return showInvoices;
};

export const transformStatusOrderPenjualan = (isi) => {
  var statusTemp = isi.status;
  if (isi.status.toLowerCase() === "draft") {
    statusTemp = "Draft";
  } else if (isi.status.toLowerCase() === "sent") {
    statusTemp = "Penawaran Dikirim";
  } else if (isi.status.toLowerCase() === "sale" || isi.status.toLowerCase() === "purchase") {
    statusTemp = "Terbit";
  }
  return statusTemp;
};

export const getStatusOrderPenjualan = ({ statusObj, statusType }) => {
  var statusTmp = "";
  if (Array.isArray(statusObj)) {
    statusObj.map((item) => {
      if (item.type.toLowerCase() === statusType.toLowerCase()) {
        statusTmp = item.status;
      }
    });
  }
  return statusTmp;
};

export const getStatusOrderPembelian = ({ statusObj, statusType }) => {
  var statusTmp = "";
  if (Array.isArray(statusObj)) {
    statusObj.map((item) => {
      if (item.type.toLowerCase() === statusType.toLowerCase()) {
        statusTmp = item.status;
      }
    });
  }
  return statusTmp;
};

export const transformOrderPenjualan = ({
  orderPenjualan,
  businessType,
  status,
  arrId,
  statusFinancing,
}) => {
  var showOrderPenjualan = orderPenjualan.map((isi) => {
    var statusOP = "";
    var statusFinancing = "";
    if (Array.isArray(isi.status)) {
      isi.status.map((item) => {
        if (item.type.toLowerCase() === "sale" || item.type.toLowerCase() === "purchase") {
          statusOP = item.status;
        } else if (item.type.toLowerCase() === "financing") {
          statusFinancing = item.status;
        }
      });
    }
    const { status, ...tmpIsi } = isi;
    return { ...tmpIsi, status: statusOP, statusFinancing };
  });
  if (status) {
    showOrderPenjualan = showOrderPenjualan.filter((isi) => {
      return status.includes(isi.status);
    });
  }
  if (statusFinancing) {
    showOrderPenjualan = showOrderPenjualan.filter((isi) => {
      return statusFinancing.includes(isi.statusFinancing);
    });
  }
  if (arrId) {
    showOrderPenjualan = showOrderPenjualan.filter((isi) => {
      return arrId.includes(isi.id);
    });
  }
  const outputArray = showOrderPenjualan.map((isi, idx) => {
    var isi_pic = "";
    if (businessType === "broker") {
      isi_pic = isi.sales_person;
    } else if (businessType === "vendor") {
      isi_pic = isi.purchase_person;
    }
    var statusTemp = transformStatusOrderPenjualan(isi);
    var statusFinancingTemp = transformStatusPengajuan({
      status: isi.statusFinancing,
      type: "purchase",
    });
    //const {status, ...tmpIsi} = isi;
    return {
      ...isi,
      no: idx + 1,
      sale_order_id: isi.id,
      pic: isi.isi_pic,
      status_readable: statusTemp,
      statusFinancing_readable: statusFinancingTemp,
    };
  });
  return outputArray;
};

export const transformStatusOrderPembelian = (isi) => {
  var statusTemp = isi.status;
  if (isi.status.toLowerCase() === "draft") {
    statusTemp = "Draft";
  } else if (isi.status.toLowerCase() === "sent") {
    statusTemp = "Penawaran Dikirim";
  } else if (isi.status.toLowerCase() === "purchase") {
    statusTemp = "Terbit";
  }
  return statusTemp;
};

export const transformOrderPembelian = ({ orderPembelian }) => {
  var no = 0;
  var showOrderPembelian = orderPembelian.map((isi) => {
    var statusOPReadable = "";
    var statusOP = "";
    var statusFinancing = "";
    var statusFinancing_readable = "";
    no += 1;
    if (Array.isArray(isi.status)) {
      isi.status.map((item) => {
        if (item.type.toLowerCase() === "purchase") {
          statusOP = item.status;
          statusOPReadable = transformStatusOrderPembelian(item);
        } else if (item.type.toLowerCase() === "financing") {
          statusFinancing = item.status;
          statusFinancing_readable = transformStatusPengajuan(item);
        }
      });
    }
    const { status, ...tmpIsi } = isi;
    return {
      ...tmpIsi,
      no,
      status: statusOP,
      status_readable: statusOPReadable,
      statusFinancing,
      statusFinancing_readable,
    };
  });
  return showOrderPembelian;
};

export const transformProductPenjualan = (productPenjualan) => {
  const product = productPenjualan?.map((isi, idx) => {
    return {
      id: idx + 1,
      name: isi.product,
      price: isi.unit_price,
      price_total: isi.subtotal,
      quantity: isi.quantity,
      tax: isi.taxes,
      remark: isi.description,
      lead_time: isi.lead_time,
    };
  });
  return product;
};

export const transformProductPembelian = (productPembelian) => {
  const product = productPembelian?.map((isi, idx) => {
    return {
      id: idx + 1,
      name: isi.product,
      price: isi.unit_price,
      price_total: isi.subtotal,
      quantity: isi.quantity,
      tax: isi.taxes,
      remark: isi.description,
      lead_time: isi.lead_time,
    };
  });
  return product;
};

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const transformKycPeminjam = (rows, pagination) => {
  let baseCounter = (pagination.current_page - 1) * pagination.limit;
  var nomor = baseCounter;

  const tmpList = rows.map((isi) => {
    var completionStatus = "";
    var newStatus = "";
    nomor = nomor + 1;
    if (isi.status.toLowerCase() === "created") {
      if (Number(isi.document_count) === 11 && Number(isi.data_count) === 1) {
        completionStatus = "Komplet";
      } else {
        completionStatus = "Sebagian";
      }
      newStatus = "Belum Disetujui";
    } else {
      completionStatus = "Komplet";
      newStatus = "Sudah Disetujui";
    }
    const { status, ...tmpIsi } = isi;
    return {
      ...tmpIsi,
      id: isi.company_id,
      no: nomor,
      statusDokumen: completionStatus,
      status: newStatus,
    };
  });

  return tmpList;
};

export const transformStatusKyc = (status) => {
  var newStatus = "";
  if (status.toLowerCase() === "created") {
    newStatus = "Belum Disetujui";
  } else if (status.toLowerCase() === "approved") {
    newStatus = "Sudah Disetujui";
  } else if (status.toLowerCase() === "rejected") {
    newStatus = "Ditolak";
  } else {
    newStatus = status;
  }
  return newStatus;
};

export const addPaddingMonth = (month) => {
  return ('0' + (month + 1)).slice(-2);
}

export const getPreviousMonth = (month) => {
  const period = { months: -1 * month };
  const newDate = addPeriodToDate(new Date(), period)
  return {
    "month": addPaddingMonth(newDate.getMonth()),
    "monthName": getMonthName(newDate.getMonth()),
    "year": newDate.getFullYear()
  }
}

export const getPreviousYear = (year) => {
  const period = { years: -1 * year };
  const newDate = addPeriodToDate(new Date(), period)
  return {
    "month": addPaddingMonth(newDate.getMonth()),
    "monthName": getMonthName(newDate.getMonth()),
    "year": newDate.getFullYear()
  }
}

export const getCurrentMonth = () => {
  const newDate = new Date();
  return {
    "month": addPaddingMonth(newDate.getMonth()),
    "monthName": getMonthName(newDate.getMonth()),
    "year": newDate.getFullYear()
  }
}

export const getMonthName = (month) => {
  const months = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
  return months[month];
}

export const addPeriodToDate = (date, { years = 0, months = 0, days = 0, hours = 0, minutes = 0, seconds = 0 }) => {
  let new_date = new Date(date);

  new_date.setFullYear(new_date.getFullYear() + years);
  new_date.setMonth(new_date.getMonth() + months);
  new_date.setDate(new_date.getDate() + days);
  new_date.setHours(new_date.getHours() + hours);
  new_date.setMinutes(new_date.getMinutes() + minutes);
  new_date.setSeconds(new_date.getSeconds() + seconds);
  return new_date;
}

export const transformKycDocsArray = (rows) => {
  const sortedRows = [...rows].sort((a, b) => a.type.localeCompare(b.type));
  let number = 0;

  const tmpList = sortedRows.map((isi) => {
    var namaDokumen = "";
    var newData = "";
    var newStatus = "";
    number += 1;
    if (isi.status.toLowerCase() === "created") {
      newStatus = "Belum Disetujui";
    } else if (isi.status.toLowerCase() === "approved") {
      newStatus = "Sudah Disetujui";
    } else if (isi.status.toLowerCase() === "rejected") {
      newStatus = "Ditolak";
    } else {
      newStatus = isi.status;
    }

    if (isi.type.startsWith("bank_statement")) {
      namaDokumen = convertMonthFormat(isi.type, "bank_statement")
    } else {
      namaDokumen = isi.type;
    }

    namaDokumen = namaDokumen.replace(/_(\d{1,2})$/g, "_($1)");

    namaDokumen = toTitleCase(namaDokumen);
    const upperCaseWord = ["sk", "siup", "nib", "ktp", "npwp", "pic"];
    upperCaseWord.map((word, _) => {
      const regex = new RegExp(`\\b${word}\\b`, 'gi')
      namaDokumen = namaDokumen.replace(regex, word.toUpperCase());
    });

    if (isi.data != undefined) {
      newData = isi.data;
    } else if (isi.url != undefined) {
      newData = serverList.company + isi.url;
    }

    if (isi.id == 0) {
      const min = 1000000;
      const max = 10000000;
      isi.id = Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const { status, ...tmpIsi } = isi;
    return { ...tmpIsi, no: number, name: namaDokumen, sortingName: isi.type, status: newStatus, data: newData };
  });


  return tmpList.sort((a, b) => a.no - b.no);
};

export const transformRelationDocsArr = (rows) => {
  const tmpList = rows.map((isi) => {
    var namaDokumen = "";
    var newData = "";
    var nomor = 0;
    switch (isi.type.slice(0, 2)) {
      case "sp":
        switch (isi.type.slice(-1)) {
          case "1":
            namaDokumen = "SPK 1";
            newData = serverList.company + isi.asset_url;
            nomor = 1;
            break;
          case "2":
            namaDokumen = "SPK 2";
            newData = serverList.company + isi.asset_url;
            nomor = 2;
            break;
          case "3":
            namaDokumen = "SPK 3";
            newData = serverList.company + isi.asset_url;
            nomor = 3;
            break;
          case "4":
            namaDokumen = "SPK 4";
            newData = serverList.company + isi.asset_url;
            nomor = 4;
            break;
          case "5":
            namaDokumen = "SPK 5";
            newData = serverList.company + isi.asset_url;
            nomor = 5;
            break;
          case "6":
            namaDokumen = "SPK 6";
            newData = serverList.company + isi.asset_url;
            nomor = 6;
            break;
          default:
            namaDokumen = isi.type.toLowerCase();
            newData = "";
            nomor = 7;
            break;
        }
        break;
      case "po":
        switch (isi.type.slice(-1)) {
          case "1":
            namaDokumen = "PO 1";
            newData = serverList.company + isi.asset_url;
            nomor = 8;
            break;
          case "2":
            namaDokumen = "PO 2";
            newData = serverList.company + isi.asset_url;
            nomor = 9;
            break;
          case "3":
            namaDokumen = "PO 3";
            newData = serverList.company + isi.asset_url;
            nomor = 10;
            break;
          case "4":
            namaDokumen = "PO 4";
            newData = serverList.company + isi.asset_url;
            nomor = 11;
            break;
          case "5":
            namaDokumen = "PO 5";
            newData = serverList.company + isi.asset_url;
            nomor = 12;
            break;
          case "6":
            namaDokumen = "PO 6";
            newData = serverList.company + isi.asset_url;
            nomor = 13;
            break;
          default:
            namaDokumen = isi.type.toLowerCase();
            newData = "";
            nomor = 14;
            break;
        }
        break;
      case "in":
        switch (isi.type.slice(-1)) {
          case "1":
            namaDokumen = "Invoice 1";
            newData = serverList.company + isi.asset_url;
            nomor = 15;
            break;
          case "2":
            namaDokumen = "Invoice 2";
            newData = serverList.company + isi.asset_url;
            nomor = 16;
            break;
          case "3":
            namaDokumen = "Invoice 3";
            newData = serverList.company + isi.asset_url;
            nomor = 17;
            break;
          case "4":
            namaDokumen = "Invoice 4";
            newData = serverList.company + isi.asset_url;
            nomor = 18;
            break;
          case "5":
            namaDokumen = "Invoice 5";
            newData = serverList.company + isi.asset_url;
            nomor = 19;
            break;
          case "6":
            namaDokumen = "Invoice 6";
            newData = serverList.company + isi.asset_url;
            nomor = 20;
            break;
          default:
            namaDokumen = isi.type.toLowerCase();
            newData = "";
            nomor = 21;
            break;
        }
        break;
      default:
        namaDokumen = isi.type.toLowerCase();
        newData = "";
        nomor = 22;
        break;
    }
    const { status, ...tmpIsi } = isi;
    return { ...tmpIsi, no: nomor, name: namaDokumen, data: newData };
  });

  return tmpList.sort((a, b) => a.no - b.no);
};

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
};

export const isValidProductTransport = ({ transport }) => {
  var validTransport = true;

  if (transport.length !== 0) {
    transport.map((isi) => {
      validTransport =
        validTransport &&
        isi.truck_type !== "" &&
        isi.origin !== "" &&
        isi.destination !== "" &&
        isi.departure_date_iso !== "" &&
        isi.arrival_date_iso !== "" &&
        isi.price !== "" &&
        isi.quantity !== "" &&
        isi.tax_id !== "" &&
        isi.tax_pph_id !== "";
    });
  }

  return validTransport;
};

export const isValidProductTransportInvoice = ({ transport }) => {
  var validTransport = true;

  if (transport.length !== 0) {
    transport.map((isi) => {
      validTransport =
        validTransport &&
        isi.truck_type !== "" &&
        isi.license_plate !== "" &&
        isi.origin !== "" &&
        isi.destination !== "" &&
        isi.departure_date_iso !== "" &&
        isi.arrival_date_iso !== "" &&
        isi.price !== "" &&
        isi.quantity !== "" &&
        isi.tax_id !== "" &&
        isi.tax_pph_id !== "";
    });
  }

  return validTransport;
};

export const isValidProductGeneric = ({ generic }) => {
  var validGeneric = true;

  if (generic.length !== 0) {
    generic.map((isi) => {
      validGeneric =
        validGeneric &&
        isi.name !== "" &&
        isi.transaction_date_iso !== "" &&
        isi.price !== "" &&
        isi.quantity !== "" &&
        isi.tax_id !== "" &&
        isi.tax_pph_id !== "";
    });
  }

  return validGeneric;
};
