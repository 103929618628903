import {
    Modal,
    Card,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const LVDocumentPreviewModal = (props) => {
    <Modal
        open={previewModal}
        onClose={closeDocumentModal}
    // aria-labelledby="modal-modal-title"
    // aria-describedby="modal-modal-description"
    >
        <Card sx={{
            marginBottom: 3, width: '100%', maxWidth: "1200px", display: "flex",
        }}>
            <MDBox p={3} mx={3} display="flex" justifyContent="center" />
            <MDBox pb={4} px={2} textAlign="left" lineHeight={1.25} justifyContent="center" >
                <MDTypography mb={2} variant="h4" fontWeight="medium" textTransform="capitalize">
                    {previewTitle}
                </MDTypography>
                <embed src={previewPath} width="100%" height="600px" />
            </MDBox>
        </Card>
    </Modal >
};

export default LVDocumentPreviewModal;


