import serverList from "../apis/serverList";
import axios from "axios";
import authHeader from "../apis/authHeader";

export const initSearchFinancingFintech = () => {
  return {
    type: "INIT_SEARCH_FINANCING_FINTECH",
  };
};

export const searchFinancingFintech = () => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });

  const response = await companyAPI.get("/api/v1/financing/fintech/search", {
    params: {
      "limit": 100
    },
  }).catch((e) => {
    dispatch({
      type: "ERROR",
      payload: { status: "ERROR", message: e.response.data.message },
    });
  });
  if (response !== undefined) {
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
    if (response.data.status === "OK") {
      dispatch({
        type: "SUCCESS_SEARCH_FINANCING_FINTECH",
        payload: { status: "SUCCESS_SEARCH_FINANCING_FINTECH", message: response.data.data },
      });
    } else {
      dispatch({
        type: "ERROR",
        payload: { status: "ERROR", message: response.data.message },
      });
    }
  }
};


export const initFintechRequirement = () => {
  return {
    type: "INIT_GET_FINTECH_REQUIREMENT",
  };
};


export const getFintechRequirement = (company_id) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });

  const response = await companyAPI.get("/api/v1/financing/fintech/requirement", {
    params: {
      company_id
    },
  }).catch((e) => {
    dispatch({
      type: "ERROR",
      payload: { status: "ERROR", message: e.response.data.message },
    });
  });
  if (response !== undefined) {
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
    if (response.data.status === "OK") {
      dispatch({
        type: "SUCCESS_GET_FINTECH_REQUIREMENT",
        payload: { status: "SUCCESS_GET_FINTECH_REQUIREMENT", message: response.data.data },
      });
    } else {
      dispatch({
        type: "ERROR",
        payload: { status: "ERROR", message: response.data.message },
      });
    }
  }
};


export const getFintechCompanyDetail = (company_id) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });

  const response = await companyAPI.get("/api/v1/company/fintech", {
    params: {
      company_id
    },
  }).catch((e) => {
    dispatch({
      type: "ERROR",
      payload: { status: "ERROR", message: e.response.data.message },
    });
  });
  if (response !== undefined) {
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
    if (response.data.status === "OK") {
      dispatch({
        type: "SUCCESS_GET_FINTECH_DETAIL",
        payload: { status: "SUCCESS_GET_FINTECH_DETAIL", message: response.data.data },
      });
    } else {
      dispatch({
        type: "ERROR",
        payload: { status: "ERROR", message: response.data.message },
      });
    }
  }
};

export const saveTransporterFintechKycDocument =
  ({ assetName, assetFile, fintechID }) =>
    async (dispatch) => {
      var formData = new FormData();
      formData.append("fintech_id", fintechID);
      formData.append("type", assetName);
      formData.append("file", assetFile);

      const companyAPI = axios.create({
        baseURL: serverList.company,
        headers: authHeader(),
      });
      const message = await companyAPI
        .post("/api/v1/company/kyc/document/fintech", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch((e) => { });
      if (message !== undefined) {
        if (message.data.status === "OK") {
          dispatch({
            type: "SUCCESS_SAVE_KYC_DOCUMENT",
            payload: { status: "SUCCESS_SAVE_KYC_DOCUMENT", message: assetName },
          });
        }
        if (message.status === 401) {
          dispatch({
            type: "ERROR",
            payload: { status: "TOKEN_EXPIRE", message: message.data.message },
          });
        }
      }
    };


export const fintechGetTransporterKycFintechDocuments = (company_id) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });

  const response = await companyAPI.get("/api/v1/fintech/company/kyc/document/fintech", {
    params: {
      company_id,
    },
  }).catch((e) => {
    dispatch({
      type: "ERROR",
      payload: { status: "ERROR", message: e.response.data.message },
    });
  });
  if (response !== undefined) {
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
    if (response.data.status === "OK") {
      dispatch({
        type: "SUCCESS_GET_KYC_DOCUMENT",
        payload: { status: "SUCCESS_GET_KYC_DOCUMENT", message: response.data.data },
      });
    } else {
      dispatch({
        type: "ERROR",
        payload: { status: "ERROR", message: response.data.message },
      });
    }
  }
};

export const adminGetTransporterFintechKycDocuments = (company_id, fintech_id) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });

  const response = await companyAPI.get("/api/v1/admin/company/kyc/document/fintech", {
    params: {
      company_id,
      fintech_id
    },
  }).catch((e) => {
    dispatch({
      type: "ERROR",
      payload: { status: "ERROR", message: e.response.data.message },
    });
  });
  if (response !== undefined) {
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
    if (response.data.status === "OK") {
      dispatch({
        type: "SUCCESS_GET_KYC_DOCUMENT",
        payload: { status: "SUCCESS_GET_KYC_DOCUMENT", message: response.data.data },
      });
    } else {
      dispatch({
        type: "ERROR",
        payload: { status: "ERROR", message: response.data.message },
      });
    }
  }
};

export const getTransporterFintechKycDocuments = (fintech_id) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });

  const response = await companyAPI.get("/api/v1/company/kyc/document/fintech", {
    params: {
      fintech_id
    },
  }).catch((e) => {
    dispatch({
      type: "ERROR",
      payload: { status: "ERROR", message: e.response.data.message },
    });
  });
  if (response !== undefined) {
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
    if (response.data.status === "OK") {
      dispatch({
        type: "SUCCESS_GET_KYC_DOCUMENT",
        payload: { status: "SUCCESS_GET_KYC_DOCUMENT", message: response.data.data },
      });
    } else {
      dispatch({
        type: "ERROR",
        payload: { status: "ERROR", message: response.data.message },
      });
    }
  }
};



export const deleteTransporterFintechKycDocument =
  (id, fintechID) =>
    async (dispatch) => {
      const companyAPI = axios.create({
        baseURL: serverList.company,
        headers: authHeader(),
      });
      const message = await companyAPI
        .delete("/api/v1/company/kyc/document/fintech", {
          data:
          {
            id,
            fintech_id: parseInt(fintechID),
          },
        }

        )
        .catch((e) => { });
      if (message !== undefined) {
        if (message.data.status === "OK") {
          dispatch({
            type: "SUCCESS_DELETE_KYC_DOCUMENT",
            payload: { status: "SUCCESS_DELETE_KYC_DOCUMENT", message: message.data.message },
          });
        }
        if (message.status === 401) {
          dispatch({
            type: "ERROR",
            payload: { status: "TOKEN_EXPIRE", message: message.data.message },
          });
        }
      }
    };


export const getTransporterFintechKycProgress = (fintech_id) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });

  const response = await companyAPI.get("/api/v1/company/kyc/progress", {
    params: {
      fintech_id
    },
  }).catch((e) => {
    dispatch({
      type: "ERROR",
      payload: { status: "ERROR", message: e.response.data.message },
    });
  });
  if (response !== undefined) {
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
    if (response.data.status === "OK") {
      dispatch({
        type: "SUCCESS_GET_KYC_PROGRESS",
        payload: { status: "SUCCESS_GET_KYC_PROGRESS", message: response.data.data },
      });
    } else {
      dispatch({
        type: "ERROR",
        payload: { status: "ERROR", message: response.data.message },
      });
    }
  }
};


export const fintechApproveFintechKycDoc = (id) => async (dispatch) => {
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI
    .put("/api/v1/fintech/company/fintech/kyc/document/approval", {
      id,
    })
    .catch((e) => { });
  if (response !== undefined) {
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
    if (response.data.status === "OK") {
      dispatch({
        type: "APPROVE_KYC_DOCUMENT",
        payload: { status: "APPROVE_KYC_DOCUMENT", message: id },
      });
    }
  }
};

export const fintechRejectFintechKycDoc =
  ({ id, reason }) =>
    async (dispatch) => {
      const companyAPI = axios.create({
        baseURL: serverList.company,
        headers: authHeader(),
      });
      const response = await companyAPI
        .put("/api/v1/fintech/company/fintech/kyc/document/rejection", {
          id,
          reason,
        })
        .catch((e) => { });
      if (response !== undefined) {
        if (response.status === 401) {
          dispatch({
            type: "ERROR",
            payload: { status: "TOKEN_EXPIRE", message: response.data.message },
          });
        }
        if (response.data.status === "OK") {
          dispatch({
            type: "REJECT_KYC_DOCUMENT",
            payload: { status: "REJECT_KYC_DOCUMENT", message: id },
          });
        }
      }
    };
