import Modal from '@mui/material/Modal';
import FundTransfer from '../layouts/fundTransfer';

const FundTransferModal = (props) => {

    return(
        <Modal
            {...props}
        >
            <div>
                <FundTransfer onClose={props.onClose} idfinancing={props.idfinancing} financingtype={props.financingtype} />
            </div>
        </Modal>
    );
}

export default FundTransferModal;