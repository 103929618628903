import React, { Component, useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import LVFileInput from "components/LVFileInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

function LVInvoicePelunasan(props) {
  return (
    <MDBox
      Box
      sx={{
        height: props.height,
        width: "100%",
      }}

      py={4} px={4} textAlign="left" lineHeight={1.25} justifyContent="center" >
      <MDTypography pb={2} pt={0} variant="h4" fontWeight="medium" textTransform="capitalize">
        Pelunasan Invoice
      </MDTypography>
      <Grid container>
        <Grid item md={12}>
          <LVFileInput
            srcImage={props.srcImage}
            srcFile={props.srcFile}
            height={200}
            width={240}
            fontSize={10}
            id="bukti_pelunasan"
            onChangeFile={props.onChangeFile}
            disableDownload={true}
            text1="Unggah Bukti Pelunasan"
            text2="Maksimal ukuran 5MB PDF"
            text3=""
            title="Bukti Pelunasan"
            titleFontSize={12}
            accept=".pdf"
            deleteFile={props.deleteFile}
            refinput={props.refinput}
          />
        </Grid>
        <Grid item md={12}>
          <MDTypography color="error" py={1} mr={2} sx={{ textAlign: "right", fontSize: 10 }}>
            {props.errorMessage}
          </MDTypography>
          <MDBox display="flex" sx={{ justifyContent: "right" }}>
            <MDButton
              onClick={props.onProcessHandling}
              sx={{ ml: 2 }}
              variant="contained"
              color="info"
              size="small"
            >
              Lunasi
            </MDButton>
            <MDButton
              sx={{ ml: 2 }}
              onClick={props.onCloseHandling}
              variant="contained"
              color="error"
              size="small"
            >
              Batal
            </MDButton>

          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default LVInvoicePelunasan;
