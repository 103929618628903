import Modal from "@mui/material/Modal";
import Settlement from "layouts/createSettlementRequest";

function SettlementModal(props) {
  return (
    <Modal {...props}>
      <div>
        <Settlement
          onClose={props.onClose}
          onSubmit={props.onSubmit}
          id={props.id}
          type={props.type}
          invoiceData={props.invoiceData}
          data={props.data}
          // invoiceDataPagination={props.invoiceDataPagination}
        />
      </div>
    </Modal>
  );
}

export default SettlementModal;
