import Modal from '@mui/material/Modal';
import SendEmail from '../layouts/sendEmail';

const SendEmailModal = (props) => {

    return(
        <Modal
            {...props}
        >
            <div>
                <SendEmail 
                    onClose={props.onClose} 
                    invoiceid={props.invoiceid } 
                    invoicenumber={props.invoicenumber}
                    invoiceamount={props.invoiceamount}
                    customercompanyid={props.customercompanyid}
                    //vadata={props.vadata}
                />
            </div>
        </Modal>
    );
}

export default SendEmailModal;