/* eslint-disable no-nested-ternary */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import borders from "assets/theme-dark/base/borders";
import colors from "assets/theme-dark/base/colors";
function ComplexStatisticsCard({ color = "info", title, count, percentage = {
  color: "success",
  text: "",
  label: "",
}, icon, countVariant, last, fontLabelSize = '12px', fontCountSize = '24px' }) {
  const { borderWidth } = borders;
  const { white, gradients, grey, transparent } = colors;
  return (
    <MDBox
      sx={{
        border: "solid",
        borderWidth: "1px",
        borderLeft: "none",
        borderRight: last ? "none" : "",
        borderTop: "none",
        borderBottom: "none",
        borderColor: "#E1E1E1",
        width: "100%",
      }}
      my={2}
    >
      <MDBox display="flex" px={2}>
        <Icon fontSize="medium">{icon}</Icon>
        <Grid container>
          <Grid item xs={12}>
            <MDBox lineHeight={1.25} ml={2}>
              <MDTypography variant="button" fontWeight="light" color="text"
              >
                {title}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <MDBox lineHeight={1.25} ml={2}>
              <MDTypography
                variant={countVariant || "h4"}
                py={1}
                align="center"
                fontSize={fontCountSize}>{count}</MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={1} px={2}>
        <MDBox display="flex">
          <MDBox my="auto">
            {percentage.type === "up" ? (
              <img src="/icon-page/up-arrow.svg" alt="" />
            ) : percentage.type === "down" ? (
              <img src="/icon-page/down-arrow.svg" alt="" />
            ) : null}
          </MDBox>
          <MDBox>
            <MDTypography
              component="span"
              variant="button"
              // fontWeight="bold"
              ml={1}
              fontSize={fontLabelSize}
            >
              {percentage.amount}
              &nbsp;{percentage.label}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Typechecking props for the ComplexStatisticsCard
ComplexStatisticsCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  icon: PropTypes.node.isRequired,
};

export default ComplexStatisticsCard;
