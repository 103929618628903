import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { matchPath } from "react-router";

import { signInOdooToken } from "../actions/authAction";

function Authentication({ children, routes }) {
  const authToken = useSelector((state) => state.authToken);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem("user"));
  const role = user?.role;

  const [urlTarget, setUrlTarget] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  const redirectUri = searchParams.get('redirect_uri') || "";

  const userCheck = () => {
    if (!authToken.isLoggedIn) {
      if (location.pathname === "/oauth/signin") {
        navigate(location.pathname + location.search);
      } else if (location.pathname === "/signup") {
        navigate("/signup");
      } else if (location.pathname === "/forget-password") {
        navigate("/forget-password");
      } else if (location.pathname === "/password/reset") {
        navigate(location);
      } else if (location.pathname === "/company/registration") {
        navigate("/company/registration");
      } else {
        setUrlTarget(location.pathname);
        navigate(`/signin?redirect_uri=${urlTarget}`);
      }
    } else if (user) {
      if (location.pathname === "/oauth/signin") {
        if (user.redirect_uri) {
          window.location.href = user.redirect_uri;
        } else {
          const urlparams = new URLSearchParams(location.search);
          dispatch(
            signInOdooToken({
              access_token: user.access_token,
              redirect_uri: urlparams.get("redirect_uri"),
              scope: urlparams.get("scope"),
              response_type: urlparams.get("response_type"),
              state: urlparams.get("state"),
              client_id: urlparams.get("client_id"),
            })
          );
        }
      } else if (location.pathname === "/signup") {
        navigate("/");
      } else if (location.pathname === "/company/registration") {
        navigate("/");
      } else {
        let roleTemp = null;
        let businessTemp = null;
        routes.map(({ path, role, business }) => {
          const match = matchPath(
            {
              path,
              exact: true,
              strict: true,
            },
            location.pathname
          );
          if (match) {
            roleTemp = role;
            businessTemp = business;
          }
        });
        let rightRole = false;
        user.role?.map((item) => {
          if (roleTemp.includes(item)) {
            rightRole = true;
          }
        });
        let rightBusiness = false;
        if (user.company) {
          if (user.company.business_type) {
            rightBusiness = businessTemp.includes(user.company.business_type);
          }
        }
        if (!(rightRole && rightBusiness)) {

          navigate("/");
        }
      }
    } else {
      navigate("/signin");
    }
  };

  useEffect(() => {

  }, []);

  useEffect(() => {
    userCheck();
  }, [location.pathname]);


  useEffect(() => {

    if (location.pathname === "/oauth/signin") {
      if (authToken.isLoggedIn) {
        if (user.redirect_uri) {
          window.location.href = user.redirect_uri;
        } else {
          const urlparams = new URLSearchParams(location.search);
          dispatch(
            signInOdooToken({
              access_token: user.access_token,
              redirect_uri: urlparams.get("redirect_uri"),
              scope: urlparams.get("scope"),
              response_type: urlparams.get("response_type"),
              state: urlparams.get("state"),
              client_id: urlparams.get("client_id"),
            })
          );
        }
      } else {
        navigate(location.pathname + location.search);
      }
    } else if (location.pathname === "/signup") {
      if (authToken.isLoggedIn) {
        navigate("/");
      }
    } else if (location.pathname === "/company/registration") {
      if (authToken.isLoggedIn) {
        navigate("/");
      }
    } else if (!authToken.isLoggedIn && location.pathname !== "/password/reset") {
      navigate("/signin");
    } else if (location.pathname === "/signin") {

      if (role.includes("admin")) {
        if (redirectUri === "") {
          navigate("/kycpeminjam");
        } else {
          navigate(redirectUri);
        }
      } else if (role.includes("lender")) {
        if (redirectUri === "") {
          navigate("/pengajuanfin");
        } else {
          navigate(redirectUri);
        }
      } else {
        if (redirectUri === "") {
          navigate("/");
        } else {
          navigate(redirectUri);
        }
      }
    }
  }, [authToken.isLoggedIn]);



  return <div>{children}</div>;
}

export default Authentication;
