/* eslint-disable import/prefer-default-export */
import serverList from "../apis/serverList";
import axios from "axios";

export const createUser = (user_data) => async (dispatch) => {
  const authAPI = axios.create({
    baseURL: serverList.authentication,
  });

  const response = await authAPI
    .post("/api/v1/signup", {
      username: user_data.email,
      password: user_data.password,
      name: user_data.name,
      phone: user_data.phone,
    })
    .catch((e) => {
      if (e.response.status === 400) {
        dispatch({
          type: "SIGNUP_FAIL",
          payload: { status: "SIGNUP_FAIL", message: e.response.data.message },
        });
      }
    });

  if (response !== undefined) {
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
    if (response.status === 200) {
      dispatch({
        type: "SIGNUP_SUCCESS",
        payload: { status: "SIGNUP_SUCCESS", message: response.message },
      });
    } else {
      dispatch({
        type: "SIGNUP_FAIL",
        payload: { status: "SIGNUP_FAIL", message: response.message },
      });
    }
  } else {
    dispatch({
      type: "SIGNUP_FAIL",
      payload: { status: "SIGNUP_FAIL", message: "Tidak berhasil menghubungi server." },
    });
  }
};
