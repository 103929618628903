/* eslint-disable no-nested-ternary */
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import validator from "validator";

import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import CoverLayout from "layouts/authentication/components/CoverLayout";

import { registerCompany } from "../../actions/companyAction";
import { signIn } from "actions/authAction";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  height: 550,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  overflow: "scroll",
  p: 4,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <MDBox sx={{ p: 3 }}>{children}</MDBox>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function RegisterCompany(props) {
  const [value, setValue] = useState(0);
  const [role, setRole] = useState("broker");
  const [npwp, setNPWP] = useState("");
  const [namaPerusahaan, setNamaPerusahaan] = useState("");
  const [tipe, setTipe] = useState(null);
  const [tipeAbbr, setTipeAbbr] = useState(null);
  const [alamat, setAlamat] = useState("");
  const [telepon, setTelepon] = useState("");
  const [email, setEmail] = useState("");
  const [namaPIC, setNamaPIC] = useState("");
  const [teleponPIC, setTeleponPIC] = useState("");
  const [emailPIC, setEmailPIC] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [textDialog, setTextDialog] = useState({ header: "", content: "" });
  const [token, setToken] = useState("");
  const [showError, setShowError] = useState({
    npwp: false,
    tipe: false,
    namaPerusahaan: false,
    alamat: false,
    namaPIC: false,
    teleponPIC: false,
    validEmail: false,
    validTeleponPIC: false,
    validTelepon: false,
    validEmailPIC: false,
    all: false,
    fail: false,
    failDetail: false,
  });
  const [helperMsg, setHelperMsg] = useState({
    npwp: "Nomor NPWP harus diisi",
    tipe: "Badan Usaha harus dipilih",
    namaPerusahaan: "Nama Perusahaan harus diisi",
    alamat: "Alamat Perusahaan harus diisi",
    namaPIC: "Nama PIC harus diisi",
    teleponPIC: "Telepon PIC harus diisi",
    validTeleponPIC: "Format telepon tidak valid",
    validTelepon: "Format telepon tidak valid",
    validEmailPIC: "Format email tidak valid",
    validEmail: "Format email tidak valid",
    all: "Masih terdapat informasi wajib yang kosong, silakan diperiksa kembali.",
    fail: "Gagal membuat perusahaan baru. Cek apakah NPWP/nomor telepon/email pernah digunakan",
    failDetail: "",
  });

  const badan_usaha = [
    { id: 0, label: "Perseroan Terbatas (PT)", abbr: "PT" },
    { id: 1, label: "Commanditaire Vennootschap (CV)", abbr: "CV" },
    { id: 2, label: "Firma", abbr: "Firma" },
    { id: 3, label: "Perusahaan Perseorangan (PO)", abbr: "PO" },
    { id: 4, label: "BUMN: Perusahan Jawatan (Perjan)", abbr: "Perjan" },
    { id: 5, label: "BUMN: Perusahaan Umum (Perum)", abbr: "Perum" },
    { id: 6, label: "BUMN: Perusahaan Perseroan (Persero)", abbr: "Persero" },
    { id: 7, label: "BUMD", abbr: "BUMD" },
    { id: 8, label: "Perorangan", abbr: "Perorangan" },
    { id: 9, label: "Lainnya", abbr: "Lainnya" },
  ];

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const message = useSelector((state) => state.message, shallowEqual);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    setToken(urlParams.get("token"));
  }, []);

  useEffect(() => {
    if (
      !(
        npwp === "" ||
        namaPerusahaan === "" ||
        tipe === null ||
        namaPIC === "" ||
        alamat === "" ||
        teleponPIC === ""
      )
    ) {
      setShowError({ ...showError, all: false });
    }
  }, [npwp, namaPerusahaan, tipe, namaPIC, teleponPIC, alamat]);

  useEffect(() => {
    if (npwp !== "") {
      setShowError({ ...showError, npwp: false });
    }
  }, [npwp]);

  useEffect(() => {
    if (namaPerusahaan !== "") {
      setShowError({ ...showError, namaPerusahaan: false });
    }
  }, [namaPerusahaan]);

  useEffect(() => {
    if (namaPIC !== "") {
      setShowError({ ...showError, namaPIC: false });
    }
  }, [namaPIC]);

  useEffect(() => {
    if (teleponPIC !== "") {
      setShowError({ ...showError, teleponPIC: false });
    }

  }, [teleponPIC]);

  useEffect(() => {
    if (alamat !== "") {
      setShowError({ ...showError, alamat: false });
    }
  }, [alamat]);

  useEffect(() => {

  }, [telepon]);

  useEffect(() => {

  }, [email]);

  useEffect(() => {
    if (tipe !== null) {
      setShowError({ ...showError, tipe: false });
    }
  }, [tipe]);

  useEffect(() => {
    switch (message.status) {
      case "REGISTER_COMPANY_SUCCESS":
        setTextDialog({
          header: "Pendaftaran Berhasil",
          content: "Proses pendaftaran perusahaan berhasil.",
        });
        setOpenDialog(true);
        return;
      case "REGISTER_COMPANY_FAIL":
        setHelperMsg({ ...helperMsg, failDetail: message.message });
        setShowError({
          ...showError,
          fail: true,
          failDetail: true,
        });

      default:
    }
  }, [message]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloseCancel = () => {
    setOpenDialog(false);
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    if (textDialog.header === "Pendaftaran Berhasil") {
      dispatch(signIn({ username, password }));
    }
  };

  const onAgree = () => {
    if (
      npwp === "" ||
      namaPerusahaan === "" ||
      tipe === null ||
      alamat === "" ||
      (!validator.isMobilePhone(telepon) ? telepon !== "" : telepon === "") ||
      (!validator.isEmail(email) ? email !== "" : email === "")
    ) {
      setShowError({
        ...showError,
        npwp: npwp === "",
        tipe: tipe === null,
        namaPerusahaan: namaPerusahaan === "",
        validTelepon: !validator.isMobilePhone(telepon) ? telepon !== "" : telepon === "",
        validEmail: !validator.isEmail(email) ? email !== "" : email === "",
        all: true,
      });
    } else {
      const comp = {
        name: namaPerusahaan,
        type: tipeAbbr,
        address: alamat,
        phone: telepon,
        email,
        business_type: role,
        token,
        tin: npwp,
      };
      dispatch(registerCompany(comp));

    }
  };

  const onChangeTipe = (select_tipe) => {
    if (select_tipe === null) {
      setTipe(null);
      setTipeAbbr(null);
    } else {
      setTipe(select_tipe.id);
      setTipeAbbr(select_tipe.abbr);
    }
  };

  const onNPWPBlur = () => {
    if (npwp.trim() === "") {
      setShowError({ ...showError, npwp: true });
    }
  };

  const onNamaBlur = () => {
    if (namaPerusahaan.trim() === "") {
      setShowError({ ...showError, namaPerusahaan: true });
    }
  };

  const onNamaPICBlur = () => {
    if (namaPIC.trim() === "") {
      setShowError({ ...showError, namaPIC: true });
    }
  };

  const onTelpPICBlur = () => {
    if (teleponPIC.trim() === "") {
      setShowError({ ...showError, teleponPIC: true });
    }

  };

  const onEmailPICBlur = () => {
    if (emailPIC.trim() === "") {
      setShowError({ ...showError, emailPIC: true });
    }

  };

  const onTelpBlur = () => {
    if (!(validator.isMobilePhone(telepon) || telepon.trim() === "")) {
      setShowError({ ...showError, validTelepon: true });
    } else {
      setShowError({ ...showError, validTelepon: false });
    }
  };

  const onEmailBlur = () => {
    if (!(validator.isEmail(email) || email.trim() === "")) {
      setShowError({ ...showError, validEmail: true });
    } else {
      setShowError({ ...showError, validEmail: false });
    }
  };

  const onTipeBlur = () => {
    if (tipe === null) {
      setShowError({ ...showError, tipe: true });
    }
  };

  const onAlamatBlur = () => {
    if (alamat.trim() === "") {
      setShowError({ ...showError, alamat: true });
    }
  };

  return (
    <CoverLayout>
      <MDBox sx={style}>
        <Grid container direction="column" justifyContent="space-around" spacing={3}>
          <Grid item>
            <MDBox>
              <MDTypography variant="title" sx={{ color: "#0D6ECD" }} fontWeight="medium">
                Daftarkan Perusahaan
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item>
            <MDBox sx={{ width: "100%" }} mt={3}>
              <MDTypography pb={1} sx={{ fontSize: 12 }}>
                Daftar Sebagai
              </MDTypography>
              <MDBox mb={2} display="flex">
                <MDButton
                  sx={{ marginRight: 1 }}
                  variant={role === "broker" ? "contained" : "outlined"}
                  color={role === "broker" ? "info" : "dark"}
                  fullWidth
                  onClick={() => setRole("broker")}
                >
                  Kontraktor
                </MDButton>
                <MDButton
                  sx={{ marginLeft: 1 }}
                  variant={role === "vendor" ? "contained" : "outlined"}
                  color={role === "vendor" ? "info" : "dark"}
                  fullWidth
                  onClick={() => setRole("vendor")}
                >
                  Vendor
                </MDButton>
              </MDBox>
              <MDBox mb={2}>
                {role === "broker" ? (
                  <MDTypography sx={{ fontSize: "10px" }}>
                    <li>
                      Fungsi penuh dari pengelolaan order sampai pengajuan financing cocok untuk
                      trucking company dan forwarder.
                    </li>
                  </MDTypography>
                ) : role === "vendor" ? (
                  <MDTypography sx={{ fontSize: "10px" }}>
                    <li>Sudah bermitra dengan kontraktor di Kentara? silahkan pilih opsi ini.</li>
                  </MDTypography>
                ) : null}
              </MDBox>
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="space-around"
                spacing={2}
                mt={3}
              >
                <Grid item>
                  <MDInput
                    id="npwp"
                    name="npwp"
                    label="NPWP"
                    value={npwp}
                    onChange={(e) => setNPWP(e.target.value)}
                    onBlur={onNPWPBlur}
                    placeholder="Isi dengan NPWP milik pelanggan"
                    required
                    sx={{ width: 410 }}
                  />
                  <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                    {showError.npwp ? helperMsg.npwp : ""}{" "}
                  </MDTypography>
                </Grid>
                <Grid item>
                  <Autocomplete
                    disablePortal
                    id="badan_usaha"
                    options={badan_usaha}
                    onChange={(e, v) => onChangeTipe(v)}
                    sx={{ width: 410 }}
                    onBlur={onTipeBlur}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => <TextField required {...params} label="Badan Usaha" />}
                  />
                  <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                    {showError.tipe ? helperMsg.tipe : ""}{" "}
                  </MDTypography>
                </Grid>
                <Grid item>
                  <MDInput
                    id="nama"
                    name="nama"
                    label="Nama Perusahaan"
                    value={namaPerusahaan}
                    onBlur={onNamaBlur}
                    onChange={(e) => setNamaPerusahaan(e.target.value)}
                    placeholder="Isi dengan nama perusahaan atau perorangan"
                    required
                    sx={{ width: 410 }}
                  />
                  <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                    {showError.namaPerusahaan ? helperMsg.namaPerusahaan : ""}{" "}
                  </MDTypography>
                </Grid>
                <Grid item>
                  <MDInput
                    id="alamat"
                    name="alamat"
                    label="Alamat Perusahaan"
                    value={alamat}
                    onBlur={onAlamatBlur}
                    onChange={(e) => setAlamat(e.target.value)}
                    placeholder="Isi dengan alamat pelanggan"
                    required
                    sx={{ width: 410 }}
                  />
                  <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                    {showError.alamat ? helperMsg.alamat : ""}{" "}
                  </MDTypography>
                </Grid>
                <Grid item>
                  <MDInput
                    id="no_telp"
                    name="no_telp"
                    label="Nomor Telepon Perusahaan"
                    value={telepon}
                    onBlur={onTelpBlur}
                    onChange={(e) => setTelepon(e.target.value)}
                    placeholder="Isi dengan nomor telepon perusahaan"
                    sx={{ width: 410 }}
                  />
                  <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                    {showError.validTelepon ? helperMsg.validTelepon : ""}{" "}
                  </MDTypography>
                </Grid>
                <Grid item>
                  <MDInput
                    id="email"
                    name="email"
                    type="email"
                    label="Email Perusahaan"
                    value={email}
                    onBlur={onEmailBlur}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Isi dengan alamat email perusahaan"
                    sx={{ width: 410 }}
                  />
                  <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                    {showError.validEmail ? helperMsg.validEmail : ""}{" "}
                  </MDTypography>
                </Grid>

              </Grid>

            </MDBox>
          </Grid>
          <MDBox width="100%" pl={3} mt={4}>
            <MDButton variant="contained" color="info" fullWidth onClick={onAgree}>
              Daftarkan
            </MDButton>
          </MDBox>
          <MDTypography color="error" my={1} px={1} sx={{ fontSize: 10, textAlign: "center" }}>
            {showError.all ? helperMsg.all : ""}{" "}
          </MDTypography>
          <MDTypography color="error" my={1} px={1} sx={{ fontSize: 10, textAlign: "center" }}>
            {showError.fail ? helperMsg.fail : ""}{" "}
          </MDTypography>
          <MDTypography color="error" my={1} px={1} sx={{ fontSize: 10, textAlign: "center" }}>
            {showError.failDetail ? helperMsg.failDetail : ""}{" "}
          </MDTypography>

        </Grid>
      </MDBox>
      <Dialog
        open={openDialog}
        onClose={handleCloseCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{textDialog.header}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{textDialog.content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCancel}>Ok</Button>
        </DialogActions>
      </Dialog>
    </CoverLayout>
  );
}

export default RegisterCompany;
