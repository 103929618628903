/* eslint-disable import/prefer-default-export */
import axios from "axios";
import serverList from "../apis/serverList";
import authHeader from "../apis/authHeader";

export const fetchFintechRiwayatKerjasama = (id) => async (dispatch) => {
  let arrAllHistory = [];
  let arrFinal = [];
  const companyAPI = axios.create({
    baseURL: serverList.company,
    headers: authHeader(),
  });
  const response = await companyAPI.get(
    `/api/v1/fintech/company/relation/history/customer/search?company_id=${id}`
  );
  if (response.data.status === "OK") {
    arrAllHistory = response.data.data.result;
  }
  if (response.status === 401) {
    dispatch({
      type: "ERROR",
      payload: { status: "TOKEN_EXPIRE", message: response.data.message },
    });
  }

  let no = 0;

  arrFinal = arrAllHistory.map((isi) => {
    let returnVal = {};
    no += 1;
    returnVal = {
      id: isi.id,
      name: isi.name,
      no,
      status: isi.status,
      customer_fintech_relation_id: isi.customer_fintech_relation_id,
    };
    return returnVal;
  });
  dispatch({ type: "FETCH_FINTECH_COMPANY_RELATION", payload: arrFinal });
};

export const fetchRiwayatKerjasama =
  ({ id, page }) =>
  async (dispatch) => {
    let arrAllHistory = [];
    let arrFinal = [];
    let pagination = {};
    const companyAPI = axios.create({
      baseURL: serverList.company,
      headers: authHeader(),
    });
    const response = await companyAPI.get("/api/v1/company/admin/collaboration/history", {
      params: {
        company_id: id,
        limit: 10,
        page,
      },
    });
    if (response.status === 401) {
      dispatch({
        type: "ERROR",
        payload: { status: "TOKEN_EXPIRE", message: response.data.message },
      });
    }
    if (response.data.status === "OK") {
      arrAllHistory = response.data.data.result;
      pagination = response.data.data.pagination;
    }

    let no = 0;

    arrFinal = arrAllHistory.map((isi) => {
      let returnVal = {};
      no += 1;
      returnVal = {
        id: isi.id,
        label: isi.name,
        relationId: isi.relation_id,
        no,
        npwp: isi.npwp,
        type: isi.type,
        picName: isi.pic_name,
      };
      return returnVal;
    });
    dispatch({ type: "FETCH_COMPANY_RELATION", payload: arrFinal });
    dispatch({ type: "FETCH_COMPANY_RELATION_PAGINATION", payload: pagination });
  };
