/* eslint-disable no-else-return */
/* eslint-disable react/function-component-definition */
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Tooltip from "@mui/material/Tooltip";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";

import formik, { useFormik, useFormikContext, useField, Formik, Form, Field } from "formik";
import moment from "moment";

import serverList from "../../apis/serverList";
import {
  fetchPphList,
  postProductFile,
  emptyMessage,
  initProductList,
} from "../../actions";
import {
  createSelfBillingInvoice,
  updateSelfBillingInvoice,
} from "../../actions/createInvoiceAction";
import { fetchVendorAssets, saveAssets, replaceAssets } from "../../actions/companyAction";
import {
  getOrderPenjualan,
  getOrderPenjualanVendor,
  initCustomerOrderPenjualan,
  customerSearchVendorSaleOrder,

} from "actions/orderPenjualanAction";

import { getInvoice } from "../../actions/detailInvoiceAction";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SimpleDashboardNavbar from "examples/Navbars/SimpleDashboardNavbar";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import LVProductTransportDataGrid from "components/LVProductTransportDataGrid";
import LVProductGenericDataGrid from "components/LVProductGenericDataGrid";

import CustomerModal from "../../modals/newCustomerModal";
import { convertTaxId, isValidProductTransport, isValidProductGeneric } from "common/Util";

import ReactGA from "react-ga4";
import { fetchVendor } from "actions/orderPembelianAction";

function onKeyDown(keyEvent) {

}

const NewInvoice = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/new-self-billing-invoice", title: "Buat Invoice Pembelian Baru" });
  }, []);

  const user = JSON.parse(localStorage.getItem("user"));

  const [openCustModal, setOpenCustModal] = useState(false);
  const [fileSelected, setFileSelected] = useState(null);
  const [fileTTD, setFileTTD] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [logoImage, setLogoImage] = useState(null);
  const [signatureImage, setSignatureImage] = useState(null);
  const [total, setTotal] = useState(null);
  const [subTotal, setSubTotal] = useState(null);
  const [pajakExclusive, setPajakExclusive] = useState(null);
  const [pajakInclusive, setPajakInclusive] = useState(null);
  const [pajakPphExclusive, setPajakPphExclusive] = useState(null);
  const [pajakPphInclusive, setPajakPphInclusive] = useState(null);
  const [inputNumber, setInputNumber] = useState("");
  const [inputTOP, setInputTOP] = useState(0);
  const [textKeterangan, setTextKeterangan] = useState("");
  const [textSyaratKetentuan, setTextSyaratKetentuan] = useState("");
  const [textKetBawah, setTextKetBawah] = useState("");
  const [selectCompany, setSelectCompany] = useState(null);
  const [selectPph, setSelectPph] = useState(null);
  const [selectSONumber, setSelectSONumber] = useState(null);
  const [listSONumber, setListSONumber] = useState([]);
  const [textKetTTD, setTextKetTTD] = useState(moment(new Date()).format("DD MMM YYYY"));
  const [textDialog, setTextDialog] = useState({ header: "", content: "" });
  const [daftarProductTransport, setDaftarProductTransport] = useState([]);
  const [daftarProductGeneric, setDaftarProductGeneric] = useState([]);
  const [showError, setshowError] = useState({
    number: false,
    term_of_payment: false,
    select_company: false,
    // tax: false,
    listProduct: false,
    date: false,
    sonumber: false,
    fieldProductTransport: false,
    fieldProductGeneric: false,
    all: false,
    fail: false,
  });
  const [helperMsg, setHelperMsg] = useState({
    number: "Nomor invoice harus diisi.",
    term_of_payment: "Tempo pembayaran tidak boleh 0 atau kosong.",
    select_company: "Vendor harus dipilih.",
    // tax: "Jenis PPh harus dipilih.",
    date: "Tanggal jatuh tempo tidak boleh lebih cepat daripada tanggal terbit invoice.",
    listProduct: "Detail invoice harus diupload.",
    sonumber: "Nomor Order Penjualan harus dipilih.",
    fieldProductTransport:
      "Informasi berikut wajib diisi: tipe truk, asal, tujuan, tanggal berangkat, tanggal sampai, harga jual, kuantitas, dan pajak. Pastikan anda sudah menekan tombol Simpan untuk tiap baris.",
    fieldProductGeneric:
      "Informasi berikut wajib diisi: nama, tanggal mulai transaksi, harga jual, kuantitas, dan pajak. Pastikan anda sudah menekan tombol Simpan untuk tiap baris.",
    all: "Masih terdapat informasi wajib yang kosong, silakan diperiksa kembali.",
    fail: "",
  });
  const listVendor = useSelector((state) => state.listVendor, shallowEqual);

  const listPelanggan = useSelector((state) => {
    return state.listPelanggan;
  }, shallowEqual);
  const listPph = useSelector((state) => {
    return state.listPph;
  }, shallowEqual);
  const listProduct = useSelector((state) => {
    return state.listProduct;
  }, shallowEqual);
  const message = useSelector((state) => {
    return state.message;
  }, shallowEqual);
  const listAsset = useSelector((state) => {
    return state.listAsset;
  }, shallowEqual);
  const anInvoice = useSelector((state) => {
    return state.anInvoice;
  }, shallowEqual);

  const anOrderPenjualan = useSelector((state) => {
    return state.anOrderPenjualan;
  }, shallowEqual);

  const listCustomerOP = useSelector((state) => {
    return state.listCustomerOP;
  }, shallowEqual);

  const logoRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const formik = useFormik({
    initialValues: {
      issued_date: new Date(),
      due_date: new Date(),
      select_company: null,
      sonumber: null,
      tax: null,
      listJenis: [],
    },
    onSubmit: (values) => {

      var newForm = {};
      var idForm = {};
      if (params.number !== undefined) {
        idForm = {
          id: anInvoice.header.id,
        };
      }
      newForm = {
        header: {
          ...idForm,
          number: inputNumber,
          invoicing_company: parseInt(values.select_company.id),
          issued_date: moment(values.issued_date).toISOString(),
          term_of_payment: Number(inputTOP),
          due_date: moment(values.due_date).toISOString(),
          // tax_id: parseInt(values.tax.id),
        },
        information: [
          {
            type: "remark",
            content: textKeterangan,
          },
          {
            type: "terms_condition",
            content: textSyaratKetentuan,
          },
          {
            type: "above_signature",
            content: textKetTTD,
          },
          {
            type: "below_signature",
            content: textKetBawah,
          },
        ],
        detail: (() => {

          var productTransport = daftarProductTransport;
          var productGeneric = daftarProductGeneric;
          if (productTransport.length !== 0) {
            productTransport.forEach((element, index) => {
              element.departure_date = moment(element.departure_date_iso).format();
              element.arrival_date = moment(element.arrival_date_iso).format();
            });
          }
          if (productGeneric.length !== 0) {
            productGeneric.forEach((element, index) => {
              element.transaction_date = moment(element.transaction_date_iso).format();
            });
          }
          const detail = { transport: productTransport, generic: productGeneric };
          return detail;
        })(),
      };

      if (values.sonumber === null) {
        values.sonumber = {};
        values.sonumber.id = 0;
      }

      newForm.header.sale_order_id = parseInt(values.sonumber.id);
      if (params.number !== undefined) {
        dispatch(updateSelfBillingInvoice(newForm));
      } else {
        dispatch(createSelfBillingInvoice(newForm));
      }
    },
  });

  useEffect(() => {

    dispatch(initProductList());

    dispatch(fetchPphList());

    if (params.number !== undefined) {
      const res = decodeURIComponent(params.number);
      // dispatch(fetchPelanggan());

      dispatch(fetchVendor());

      dispatch(getInvoice({ number: res }));
    } else if (
      params.customerid !== undefined &&
      params.customername !== undefined &&
      params.soid !== undefined &&
      params.sonumber !== undefined &&
      params.projectname !== undefined
    ) {

      var select_company = {
        id: params.customerid,
        value: params.customername,
        label: params.customername,
      };
      setSelectCompany(select_company);
      formik.setFieldValue("select_company", select_company);
      var tmpSONumber = {
        id: params.soid,
        value: params.sonumber,
        label: params.sonumber + " - " + params.soid + " - " + params.projectname,
      };

      setSelectSONumber(tmpSONumber);
      setListSONumber([tmpSONumber]);
      formik.setFieldValue("sonumber", tmpSONumber);
    } else {
      dispatch(fetchVendor());
    }
    if (user?.company?.business_type === "vendor" || user?.company?.business_type === "broker") {
      document.getElementById("select_company").focus();
    } else {
      document.getElementById("number").focus();
    }
    return () => {
      dispatch(initProductList());
    };
  }, []);

  useEffect(() => {
    if (params.number !== undefined) {
      if (anInvoice.status !== undefined) {
        var statusInvoice = "";
        anInvoice.status.map((isi) => {
          if (isi.type === "invoice") {
            statusInvoice = isi.status;
          }
        });
        if (statusInvoice !== "Draft") {
          navigate("/invoice");
        }
      }
      if (anInvoice.header.number !== "") {
        setInputNumber(anInvoice.header.number);
        if (anInvoice.header.term_of_payment) {
          setInputTOP(anInvoice.header.term_of_payment);
        }
        const tmpTglInv = new Date(anInvoice.header.issued_date_iso);
        const tmpTglJT = new Date(anInvoice.header.due_date_iso);
        formik.setFieldValue("issued_date", tmpTglInv);
        formik.setFieldValue("due_date", tmpTglJT);
        var select_company = {};
        listPelanggan.map((item) => {
          if (item.label === anInvoice.header.invoiced_company) {
            select_company = {
              id: item.id,
              value: item.value,
              label: item.label,
            };
          }
        });
        setSelectCompany(select_company);
        formik.setFieldValue("select_company", select_company);
        if (user?.company?.business_type === "vendor") {
          dispatch(customerSearchVendorSaleOrder({ vendor_id: select_company.id }));
          dispatch(fetchVendorAssets(select_company.id));
        } else {
          dispatch(customerSearchVendorSaleOrder({ vendor_id: select_company.id }));
          dispatch(fetchVendorAssets(select_company.id));
        }
        anInvoice.information.map((isi) => {
          if (isi.type === "remark") {
            setTextKeterangan(isi.content);
          } else if (isi.type === "terms_condition") {
            setTextSyaratKetentuan(isi.content);
          } else if (isi.type === "above_signature") {
            setTextKetTTD(isi.content);
          } else if (isi.type === "below_signature") {
            setTextKetBawah(isi.content);
          }
        });

        if (user?.company?.business_type === "vendor") {
          dispatch(getOrderPenjualanVendor({ id: anInvoice.header.purchase_order_id }));
        } else {
          dispatch(getOrderPenjualan({ id: anInvoice.header.sale_order_id }));
        }
      }
    }
  }, [anInvoice]);


  useEffect(() => {
    formik.setFieldValue("listJenis", listPph, true);
  }, [listPph]);

  useEffect(() => {
    if (listAsset.logo !== undefined) {
      setLogoImage(serverList.company + listAsset.logo.url);
    }
    if (listAsset.signature !== undefined) {
      setSignatureImage(serverList.company + listAsset.signature.url);
    }
  }, [listAsset]);

  useEffect(() => {
    if (listProduct.header !== undefined) {
      setTotal(listProduct.header.total);
      setSubTotal(listProduct.header.subtotal);
      setPajakInclusive(listProduct.header.inclusive_tax_amount);

      setPajakPphExclusive(listProduct.header.tax_pph);
    }
  }, [listProduct]);

  useEffect(() => { }, [logoImage]);

  useEffect(() => {
    if (message.status !== "") {
      dispatch(emptyMessage());
      switch (message.status) {
        case "SUCCESS_NEW_INVOICE":
          navigate("/self-billing-invoice/" + encodeURIComponent(message.message));
          return;
        case "SUCCESS_UPDATE_INVOICE":
          navigate("/self-billing-invoice/" + encodeURIComponent(message.message));
          return;
        case "FAIL_NEW_INVOICE":
          alert(
            "Gagal membuat invoice. Periksa apakah nomor invoice sudah pernah dipakai. Error: " +
            message.message
          );
          setHelperMsg({ ...helperMsg, fail: message.message });
          setshowError({ ...showError, fail: true });
          return;
        case "SUCCESS_NEW_CUSTOMER":
          var select_company = {};
          select_company = {
            id: message.message.company_id,
            value: message.message.company_id,
            label: message.message.name,
          };
          setSelectCompany(select_company);
          formik.setFieldValue("select_company", select_company);
          return;
        default:
          return;
      }
    }
  }, [message]);

  useEffect(() => {
    var timeoutId = null;
    if (inputTOP !== undefined) {
      if (inputTOP !== "") {
        if (Number(inputTOP) <= 0) {
          setInputTOP(0);
        } else {
          if (showError.term_of_payment) {
            setshowError({ ...showError, term_of_payment: false });
          }
          timeoutId = setTimeout(() => {
            const tmpTglInv = new Date(formik.values.issued_date);
            const tmpTglJT = new Date(formik.values.issued_date);
            tmpTglJT.setDate(tmpTglInv.getDate() + Number(inputTOP));
            formik.setFieldValue("due_date", tmpTglJT);
          }, 1000);
        }
      }
    }

    return () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
    };
  }, [inputTOP]);

  useEffect(() => {
    const tmpTglInv = new Date(formik.values.issued_date);
    const tmpTglJT = new Date(formik.values.issued_date);
    tmpTglJT.setDate(tmpTglInv.getDate() + Number(inputTOP));
    formik.setFieldValue("due_date", tmpTglJT);

  }, [formik.values.issued_date]);

  useEffect(() => {
    if (showError.all) {
      if (user?.company?.business_type === "vendor") {
        const tmpTglInv = new Date(formik.values.issued_date);
        const tmpTglJT = new Date(formik.values.due_date);
        if (
          !(
            inputNumber === "" ||
            inputTOP === "" ||
            Number(inputTOP) === 0 ||
            formik.values.select_company === null ||
            // formik.values.tax === null ||
            formik.values.sonumber === null ||
            (daftarProductGeneric.length === 0 && daftarProductTransport.length === 0) ||
            tmpTglInv > tmpTglJT
          )
        ) {
          setshowError({ ...showError, all: false });
        }
      } else {
        const tmpTglInv = new Date(formik.values.issued_date);
        const tmpTglJT = new Date(formik.values.due_date);
        if (
          !(
            inputNumber === "" ||
            inputTOP === "" ||
            Number(inputTOP) === 0 ||
            formik.values.select_company === null ||
            // formik.values.tax === null ||
            (daftarProductGeneric.length === 0 && daftarProductTransport.length === 0) ||
            tmpTglInv > tmpTglJT
          )
        ) {
          setshowError({ ...showError, all: false });
        }
      }
    }
  }, [
    inputNumber,
    inputTOP,
    formik.values.select_company,
    daftarProductGeneric,
    daftarProductTransport,
    formik.values.issued_date,
    formik.values.due_date,
    formik.values.sonumber,
  ]);

  useEffect(() => {
    if (inputNumber !== "") {
      if (showError.number) {
        setshowError({ ...showError, number: false });
      }
    }
  }, [inputNumber]);

  useEffect(() => {
    if (formik.values.select_company !== null) {
      setshowError({ ...showError, select_company: false });
    }
  }, [formik.values.select_company]);

  useEffect(() => {
    if (formik.values.sonumber !== null) {
      setshowError({ ...showError, sonumber: false });
    }
  }, [formik.values.sonumber]);

  useEffect(() => {
    if (fileSelected !== null) {
      dispatch(postProductFile(fileSelected));
      document.getElementById("fileProduct").value = null;
      setFileSelected(null);
      setshowError({ ...showError, listProduct: false });
    }
  }, [fileSelected]);


  useEffect(() => {
    if (params.soid === undefined) {
      var tmplistSONumber = listCustomerOP.map((item) => {
        return {
          id: item.id,
          value: item.number,
          label: item.number + " - " + item.id + "  - " + item.project_name,
        };
      });
      setListSONumber(tmplistSONumber);
    }
  }, [listCustomerOP]);

  useEffect(() => {

    if (params.number !== undefined) {
      if (anOrderPenjualan.header !== undefined) {
        setSelectSONumber({
          id: anOrderPenjualan.header.id,
          value: anOrderPenjualan.header.number,
          label: anOrderPenjualan.header.number + " - " + anOrderPenjualan.header.project_name,
        });
        formik.setFieldValue("sonumber", selectSONumber);
      }
    } else {
      if (anOrderPenjualan.detail !== undefined) {
        dispatch({ type: "FETCH_LIST_PRODUCT", payload: { detail: anOrderPenjualan?.detail } });
      }
      if (anOrderPenjualan?.header?.term_of_payment) {
        setInputTOP(anOrderPenjualan?.header?.term_of_payment);
      }
      anOrderPenjualan?.information?.map((item) => {
        if (item.type === "remark") {
          setTextKeterangan(item.content);
        } else if (item.type === "terms_condition") {
          setTextSyaratKetentuan(item.content);
        }
      });
    }
  }, [anOrderPenjualan]);

  useEffect(() => {

    var totalTanpaPPN = 0;
    var totalDenganPPN = 0;
    var ppnExclusive = 0;
    var ppnInclusive = 0;
    var pphExclusive = 0;
    var pphInclusive = 0;
    daftarProductTransport?.map((isi) => {
      totalTanpaPPN += isi.price_total;
      totalDenganPPN += isi.price_ppn_total;
      ppnExclusive += isi.ppn_exclusive;
      ppnInclusive += isi.ppn_inclusive;
      pphExclusive += isi.pph_exclusive;
      pphInclusive += isi.pph_inclusive;
    });
    daftarProductGeneric?.map((isi) => {
      totalTanpaPPN += isi.price_total;
      totalDenganPPN += isi.price_ppn_total;
      ppnExclusive += isi.ppn_exclusive;
      ppnInclusive += isi.ppn_inclusive;
      pphExclusive += isi.pph_exclusive;
      pphInclusive += isi.pph_inclusive;
    });
    setSubTotal(totalTanpaPPN);
    setTotal(totalDenganPPN);
    setPajakExclusive(ppnExclusive);
    setPajakInclusive(ppnInclusive);

    setPajakPphExclusive(pphExclusive);
    setPajakPphInclusive(pphInclusive);

    if (!(daftarProductGeneric.length === 0 && daftarProductTransport.length === 0)) {
      setshowError({ ...showError, listProduct: false });
    }
  }, [daftarProductTransport, daftarProductGeneric]);

  useEffect(() => {
    if (daftarProductTransport !== null) {
      if (isValidProductTransport({ transport: daftarProductTransport })) {
        setshowError({ ...showError, fieldProductTransport: false });
      }
    }
  }, [daftarProductTransport]);

  useEffect(() => {
    if (daftarProductGeneric !== null) {
      if (isValidProductGeneric({ generic: daftarProductGeneric })) {
        setshowError({ ...showError, fieldProductGeneric: false });
      }
    }
  }, [daftarProductGeneric]);

  const onNoInvoiceBlur = () => {
    if (inputNumber.trim() === "") {
      setInputNumber("");
      setshowError({ ...showError, number: true });
    }
  };

  const onTempoBayarBlur = () => {

    if (inputTOP === "") {
      setInputTOP(0);
      setshowError({ ...showError, term_of_payment: true });
    } else if (Number(inputTOP) === 0) {
      setshowError({ ...showError, term_of_payment: true });
    }
  };

  const onPelangganBlur = () => {
    if (formik.values.select_company === null) {
      setshowError({ ...showError, select_company: true });
    }

  };

  // const onJenisPphBlur = () => {
  //   if (formik.values.tax === null) {
  //     setshowError({ ...showError, tax: true });
  //   }

  // };

  const onSONumberBlur = () => {
    if (formik.values.sonumber === null) {
      setshowError({ ...showError, sonumber: true });
    }

  };

  const handleClickOpen = () => {
    if (user?.company?.business_type === "vendor") {
      const tmpTglInv = new Date(formik.values.issued_date);
      const tmpTglJT = new Date(formik.values.due_date);
      if (
        inputNumber === "" ||
        inputTOP === "" ||
        Number(inputTOP) === 0 ||
        formik.values.select_company === null ||
        // formik.values.tax === null ||
        formik.values.sonumber === null ||
        (daftarProductGeneric.length === 0 && daftarProductTransport.length === 0) ||
        tmpTglInv > tmpTglJT ||
        !isValidProductTransport({ transport: daftarProductTransport }) ||
        !isValidProductGeneric({ generic: daftarProductGeneric })
      ) {
        setshowError({
          number: inputNumber === "",
          term_of_payment: inputTOP === "" || Number(inputTOP) === 0,
          select_company: formik.values.select_company === null,
          // tax: formik.values.tax === null,
          listProduct: daftarProductGeneric.length === 0 && daftarProductTransport.length === 0,
          date: tmpTglInv > tmpTglJT,
          sonumber: formik.values.sonumber === null,
          fieldProductTransport: !isValidProductTransport({ transport: daftarProductTransport }),
          fieldProductGeneric: !isValidProductGeneric({ generic: daftarProductGeneric }),
          all: true,
        });
      } else {
        if (params.number !== undefined) {
          setTextDialog({
            header: "Ubah invoice nomor " + params.number + "?",
            content:
              "Sebelum mengubah invoice, pastikan semua informasi telah terisi dengan benar.",
          });
        } else {
          setTextDialog({
            header: "Buat invoice baru?",
            content: "Sebelum membuat invoice, pastikan semua informasi telah terisi dengan benar.",
          });
        }
        setOpenDialog(true);
      }
    } else {
      const tmpTglInv = new Date(formik.values.issued_date);
      const tmpTglJT = new Date(formik.values.due_date);
      if (
        inputNumber === "" ||
        inputTOP === "" ||
        Number(inputTOP) === 0 ||
        formik.values.select_company === null ||
        // formik.values.tax === null ||
        (daftarProductGeneric.length === 0 && daftarProductTransport.length === 0) ||
        tmpTglInv > tmpTglJT ||
        !isValidProductTransport({ transport: daftarProductTransport }) ||
        !isValidProductGeneric({ generic: daftarProductGeneric })
      ) {
        setshowError({
          number: inputNumber === "",
          term_of_payment: inputTOP === "" || Number(inputTOP) === 0,
          select_company: formik.values.select_company === null,
          // tax: formik.values.tax === null,
          listProduct: daftarProductGeneric.length === 0 && daftarProductTransport.length === 0,
          date: tmpTglInv > tmpTglJT,
          fieldProductTransport: !isValidProductTransport({ transport: daftarProductTransport }),
          fieldProductGeneric: !isValidProductGeneric({ generic: daftarProductGeneric }),
          all: true,
        });
      } else {
        if (params.number !== undefined) {
          setTextDialog({
            header: "Ubah invoice nomor " + params.number + "?",
            content:
              "Sebelum mengubah invoice, pastikan semua informasi telah terisi dengan benar.",
          });
        } else {
          setTextDialog({
            header: "Buat invoice baru?",
            content: "Sebelum membuat invoice, pastikan semua informasi telah terisi dengan benar.",
          });
        }
        setOpenDialog(true);
      }
    }
  };

  const handleCloseAgree = () => {
    setOpenDialog(false);
    formik.submitForm();
  };

  const handleCloseCancel = () => {
    setOpenDialog(false);
  };

  const onChangeSelectCompany = (select_company) => {
    if (params.customerid === undefined) {
      setSelectSONumber(null);
      formik.setFieldValue("sonumber", null);
      dispatch(initCustomerOrderPenjualan());

      if (user?.company?.business_type === "vendor") {
        dispatch(customerSearchVendorSaleOrder({ vendor_id: select_company.id }));
        dispatch(fetchVendorAssets(select_company.id));
      } else {
        dispatch(customerSearchVendorSaleOrder({ vendor_id: select_company.id }));
        dispatch(fetchVendorAssets(select_company.id));
      }
      setSelectCompany(select_company);
      formik.setFieldValue("select_company", select_company);
    }
  };

  const onChangeSONumber = (sonumber) => {
    if (params.soid === undefined) {
      setSelectSONumber(sonumber);
      dispatch(initProductList());
      if (user?.company?.business_type === "vendor") {
        dispatch(getOrderPenjualanVendor({ id: sonumber.id }));
      } else {
        dispatch(getOrderPenjualan({ id: sonumber.id }));
      }

      formik.setFieldValue("sonumber", sonumber);
    }
  };

  const onChangeFileLogo = (e) => {
    if (listAsset.logo === undefined) {
      dispatch(saveAssets({ assetName: "logo", assetFile: e.target.files[0] }));
    } else {
      if (listAsset.logo.id !== undefined) {
        dispatch(
          replaceAssets({ id: listAsset.logo.id, assetName: "logo", assetFile: e.target.files[0] })
        );
      }
    }

  };

  const onChangeFileSignature = (e) => {
    if (listAsset.signature === undefined) {
      dispatch(saveAssets({ assetName: "signature", assetFile: e.target.files[0] }));
    } else {
      if (listAsset.signature.id !== undefined) {
        dispatch(
          replaceAssets({
            id: listAsset.signature.id,
            assetName: "signature",
            assetFile: e.target.files[0],
          })
        );
      }
    }

  };

  const onChangeInput = (event) => {
    setInputNumber(event.target.value);
  };

  const renderInput = () => {
    if (["vendor", "broker"].includes(user?.company?.business_type)) {
      return (
        <div>
          <Grid container spacing={3} px={4} justifyContent="flex-start" my={1}>
            <Grid item md={6} xs={12}>
              <MDBox
                sx={{
                  width: "100%",
                }}
              >
                <Autocomplete
                  disablePortal
                  id="select_company"
                  options={listVendor}
                  value={selectCompany}
                  onBlur={onPelangganBlur}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  onChange={(e, v) => onChangeSelectCompany(v)}
                  sx={{ width: 500 }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      error={showError.select_company}
                      label="Vendor"
                    />
                  )}
                />
                <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                  {showError.select_company ? helperMsg.select_company : ""}{" "}
                </MDTypography>
              </MDBox>
            </Grid>
            {/* <Grid item>
              <MDBox
                sx={{
                  width: "500px",
                }}
              />
            </Grid> */}
          </Grid>
          <Grid container spacing={3} px={4} justifyContent="flex-start" my={1}>
            <Grid item md={6} xs={12}>
              <MDBox
                sx={{
                  width: "100%",
                }}
              >
                <Autocomplete
                  disablePortal
                  id="noorderpenjualan"
                  options={listSONumber}
                  value={selectSONumber}
                  onBlur={onSONumberBlur}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(e, v) => onChangeSONumber(v)}
                  sx={{ width: 500 }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      error={showError.sonumber}
                      label="No. Order Penjualan"
                    />
                  )}
                />
                <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                  {showError.sonumber ? helperMsg.sonumber : ""}{" "}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item md={6} xs={12}>
              <MDBox
                sx={{
                  width: "100%",
                }}
              >
                <MDBox
                  sx={{
                    width: "500px",
                  }}
                />
                {/* <Autocomplete
                  disablePortal
                  id="tax"
                  options={listPph}
                  value={selectPph}
                  onBlur={onJenisPphBlur}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  onChange={(e, v) => onChangeTax(v)}
                  sx={{ width: 500 }}
                  renderInput={(params) => (
                    <TextField required {...params} error={showError.tax} label="Jenis PPh" />
                  )}
                /> */}
                <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                  {showError.tax ? helperMsg.tax : ""}{" "}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid >
          <Grid container spacing={3} px={4} justifyContent="flex-start" my={1}>
            <Grid item md={6} xs={12}>
              <MDBox
                sx={{
                  width: "100%",
                }}
              >
                <MDInput
                  id="number"
                  name="number"
                  label="No. Invoice"
                  placeholder="Isi dengan nomor invoice anda"
                  onChange={onChangeInput}
                  value={inputNumber}
                  onBlur={onNoInvoiceBlur}
                  sx={{ width: 500 }}
                  error={showError.number}
                  required
                />
                <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                  {showError.number ? helperMsg.number : ""}{" "}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item md={6} xs={12}>
              <MDBox
                sx={{
                  width: "100%",
                }}
              >
                <MDInput
                  id="term_of_payment"
                  name="term_of_payment"
                  label="Tempo Bayar dalam Hari"
                  onChange={(e) => setInputTOP(e.target.value)}
                  value={inputTOP}
                  type="number"
                  sx={{ width: 500 }}
                  error={showError.term_of_payment}
                  onBlur={onTempoBayarBlur}
                  required
                />
                <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                  {showError.term_of_payment ? helperMsg.term_of_payment : ""}{" "}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <Grid container spacing={3} px={4} justifyContent="flex-start" my={1}>
            <Grid item md={6} xs={12}>
              <MDBox
                sx={{
                  width: "100%",
                }}
              >
                <DesktopDatePicker
                  label="Tanggal Terbit Invoice"
                  inputFormat="dd/MM/yyyy"
                  onChange={(value) => formik.setFieldValue("issued_date", value, true)}
                  value={formik.values.issued_date}
                  renderInput={(params) => (
                    <TextField
                      required
                      error={showError.date}
                      id="issued_date"
                      name="issued_date"
                      sx={{ width: 500 }}
                      {...params}
                    />
                  )}
                />
                <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                  {showError.date ? helperMsg.date : ""}{" "}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item md={6} xs={12}>
              <MDBox
                sx={{
                  width: "100%",
                }}
              >
                <DesktopDatePicker
                  disabled
                  label="Tanggal Jatuh Tempo"
                  inputFormat="dd/MM/yyyy"
                  value={formik.values.due_date}
                  onChange={(value) => formik.setFieldValue("due_date", value, true)}
                  renderInput={(params) => (
                    <TextField
                      error={showError.date}
                      id="due_date"
                      name="due_date"
                      sx={{ width: 500 }}
                      {...params}
                    />
                  )}
                />
                <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                  {showError.date ? helperMsg.date : ""}{" "}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <Grid container spacing={3} px={4} justifyContent="flex-start" my={1}>
            <Grid item md={6} xs={12}>
              <MDInput
                id="keterangan"
                name="keterangan"
                multiline
                onChange={(e) => setTextKeterangan(e.target.value)}
                value={textKeterangan}
                rows={5}
                label="Keterangan"
                placeholder="Isi dengan keterangan lain yang diperlukan"
                sx={{ width: 500 }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <MDInput
                id="syaratKetentuan"
                name="syaratKetentuan"
                multiline
                onChange={(e) => setTextSyaratKetentuan(e.target.value)}
                value={textSyaratKetentuan}
                rows={5}
                label="Syarat dan Ketentuan"
                placeholder="Isi dengan syarat dan ketentuan anda"
                sx={{ width: 500 }}
              />
            </Grid>
          </Grid>
        </div >
      );
    } else {
      return (
        <div>
          <Grid container spacing={3} px={4} justifyContent="flex-start" my={1}>
            <Grid item md={6} xs={12}>
              <MDBox
                sx={{
                  width: "100%",
                }}
              >
                <MDInput
                  id="number"
                  name="number"
                  label="No. Invoice"
                  placeholder="Isi dengan nomor invoice anda"
                  onChange={onChangeInput}
                  value={inputNumber}
                  onBlur={onNoInvoiceBlur}
                  sx={{ width: 500 }}
                  error={showError.number}
                  required
                />
                <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                  {showError.number ? helperMsg.number : ""}{" "}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item md={6} xs={12}>
              <MDBox
                sx={{
                  width: "100%",
                }}
              >
                <DesktopDatePicker
                  label="Tanggal Terbit Invoice"
                  inputFormat="dd/MM/yyyy"
                  onChange={(value) => formik.setFieldValue("issued_date", value, true)}
                  value={formik.values.issued_date}
                  renderInput={(params) => (
                    <TextField
                      required
                      error={showError.date}
                      id="issued_date"
                      name="issued_date"
                      sx={{ width: 500 }}
                      {...params}
                    />
                  )}
                />
                <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                  {showError.date ? helperMsg.date : ""}{" "}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <Grid container spacing={3} px={4} justifyContent="flex-start" my={1}>
            <Grid item md={6} xs={12}>
              <MDBox
                sx={{
                  width: "100%",
                }}
              >
                <MDInput
                  id="term_of_payment"
                  name="term_of_payment"
                  label="Tempo Bayar dalam Hari"
                  onChange={(e) => setInputTOP(e.target.value)}
                  value={inputTOP}
                  type="number"
                  sx={{ width: 500 }}
                  error={showError.term_of_payment}
                  onBlur={onTempoBayarBlur}
                  required
                />
                <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                  {showError.term_of_payment ? helperMsg.term_of_payment : ""}{" "}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item md={6} xs={12}>
              <MDBox
                sx={{
                  width: "100%",
                }}
              >
                <DesktopDatePicker
                  disabled
                  label="Tanggal Jatuh Tempo"
                  inputFormat="dd/MM/yyyy"
                  value={formik.values.due_date}
                  onChange={(value) => formik.setFieldValue("due_date", value, true)}
                  renderInput={(params) => (
                    <TextField
                      error={showError.date}
                      id="due_date"
                      name="due_date"
                      sx={{ width: 500 }}
                      {...params}
                    />
                  )}
                />
                <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                  {showError.date ? helperMsg.date : ""}{" "}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <Grid container spacing={3} px={4} justifyContent="flex-start" my={1}>
            <Grid item md={6} xs={12}>
              <Grid container direction="column" spacing={2} alignItems="flex-end">
                <Grid item>
                  <MDBox
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Autocomplete
                      disablePortal
                      id="select_company"
                      options={listPelanggan}
                      value={selectCompany}
                      onBlur={onPelangganBlur}
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                      onChange={(e, v) => onChangeSelectCompany(v)}
                      sx={{ width: 500 }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          error={showError.select_company}
                          label="Vendor"
                        />
                      )}
                    />
                    <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                      {showError.select_company ? helperMsg.select_company : ""}{" "}
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item>
                  <MDButton
                    variant="outlined"
                    color="info"
                    mt={2}
                    size="small"
                    onClick={(e) => setOpenCustModal(true)}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;Vendor Baru
                  </MDButton>
                  <CustomerModal
                    open={openCustModal}
                    onClose={(e) => setOpenCustModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <MDBox
                sx={{
                  width: "500px",
                }}
              >
                {/* <Autocomplete
                  disablePortal
                  id="tax"
                  options={listPph}
                  value={selectPph}
                  onBlur={onJenisPphBlur}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  onChange={(e, v) => onChangeTax(v)}
                  sx={{ width: 500 }}
                  renderInput={(params) => (
                    <TextField required {...params} error={showError.tax} label="Jenis PPh" />
                  )}
                /> */}
                <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                  {showError.tax ? helperMsg.tax : ""}{" "}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </div>
      );
    }
  };

  return (
    <DashboardLayout>
      <SimpleDashboardNavbar name="Buat Invoice Pembelian Baru" />
      <form onSubmit={formik.handleSubmit} onKeyDown={onKeyDown}>
        <Grid container mt={5} container direction="column" justifyContent="space-between">
          <Grid
            item
            mt={4}
            pb={1}
            mx={4}
            sx={{
              bgcolor: "rgba(255, 255, 255, 1)",
              borderRadius: "5px",
              boxShadow: "10px 15px 27px -28px #1A73E8",
            }}
          >
            <Grid container spacing={3} px={2} justifyContent="space-around">
              <Grid item>

              </Grid>
              <Grid item>
                <MDBox
                  sx={{
                    width: "500px",
                  }}
                />
              </Grid>
            </Grid>

            {renderInput()}

            <Grid
              container
              py={2}
              justifyContent="space-around"
              direction="column"
              mt={5}
              sx={{ borderTop: "2px dashed #e1e1e1" }}
            >
              <Grid item justifyContent="flex-start" alignItems="flex-start" px={4}>
                <MDBox>
                  <MDTypography variant="h6" fontWeight="medium">
                    {"Detail Order Penjualan (Optional)"}
                  </MDTypography>
                  <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                    {showError.listProduct ? helperMsg.listProduct : ""}{" "}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item pb={3} sx={{ borderBottom: "2px dashed #e1e1e1" }}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="space-between"
                  spacing={1}
                  px={4}
                  mt={3}
                >
                  <Grid item md={6} xs={12}>
                    <MDBox
                      width="80%"
                      height="150px"
                      sx={{ bgcolor: "#F4F8FC", border: "2px solid #0D6ECD" }}
                      borderRadius="lg"
                      p={3}
                    >
                      <MDTypography fontWeight="bold" sx={{ fontSize: "14px", color: "#0D6ECD" }}>
                        Langkah 1
                      </MDTypography>
                      <MDTypography sx={{ fontSize: "12px" }}>
                        Untuk menambah detail, silahkan unduh template terlebih dahulu untuk
                        diisi.
                      </MDTypography>
                      <MDBox
                        display="flex"
                        mt={2}
                        justifyContent="flex-end"
                        alignItems="flex-end"
                      >
                        <a
                          download
                          href="https://invoice.dev.kentara.io/invoice/detail/template-invoice-detail.xlsx"
                        >
                          <MDButton variant="contained" color="info" mt={2} size="small">
                            <DownloadRoundedIcon />
                            &nbsp;Download Template
                          </MDButton>
                        </a>
                      </MDBox>
                    </MDBox>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <MDBox
                      width="80%"
                      height="150px"
                      sx={{ bgcolor: "#F4F8FC", border: "2px solid #0D6ECD" }}
                      borderRadius="lg"
                      p={3}
                    >
                      <MDTypography fontWeight="bold" sx={{ fontSize: "14px", color: "#0D6ECD" }}>
                        Langkah 2
                      </MDTypography>
                      <MDTypography sx={{ fontSize: "12px" }}>
                        Setelah template diisi, silahkan unggah berkas template tersebut.
                      </MDTypography>
                      <MDBox
                        display="flex"
                        mt={2}
                        justifyContent="flex-end"
                        alignItems="flex-end"
                      >
                        <MDButton
                          variant="contained"
                          color="info"
                          mt={2}
                          size="small"
                          component="label"
                        >
                          <MDBox sx={{ color: "#ffffff" }}>
                            <FileUploadRoundedIcon />
                            &nbsp;Upload Detail
                            <input
                              type="file"
                              id="fileProduct"
                              hidden
                              onChange={(e) => {
                                setFileSelected(e.target.files[0]);
                              }}
                            />
                          </MDBox>
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <MDBox mt={5} mx={3}>
                  <LVProductTransportDataGrid
                    onProductUpdate={(prod) => {
                      setDaftarProductTransport(prod);
                    }}
                  />
                </MDBox>
                <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                  {showError.fieldProductTransport ? helperMsg.fieldProductTransport : ""}{" "}
                </MDTypography>
              </Grid>
              <Grid item>
                <MDBox mt={2} mx={3}>
                  <LVProductGenericDataGrid
                    onProductUpdate={(prod) => {
                      setDaftarProductGeneric(prod);
                    }}
                  />
                </MDBox>
                <MDTypography color="error" px={1} sx={{ fontSize: 10 }}>
                  {showError.fieldProductGeneric ? helperMsg.fieldProductGeneric : ""}{" "}
                </MDTypography>
              </Grid>
            </Grid>

            <Grid
              item
              justifyContent="flex-end"
              alignItems="flex-end"
              px={3}
              mt={5}
            >
              <MDBox mt={5} justifyContent="flex-end" display="flex" alignItems="flex-end">
                <MDBox
                  sx={{ bgcolor: "#F4F8FC", border: "2px solid #0D6ECD" }}
                  width="50%"
                  borderRadius="lg"
                >
                  <MDBox px={3} my={1}>
                    <MDBox
                      display="flex"
                      sx={{ borderBottom: "1px solid #0D6ECD" }}
                      justifyContent="space-between"
                    >
                      <MDBox>
                        <MDTypography
                          variant="overline"
                          fontWeight="regular"
                          sx={{ color: "#0D6ECD" }}
                        >
                          {/* Total Harga Tanpa PPN */}
                          Total Harga Tanpa Pajak
                        </MDTypography>
                      </MDBox>
                      <MDBox>
                        <MDTypography
                          variant="overline"
                          fontWeight="regular"
                          sx={{ color: "#0D6ECD" }}
                        >
                          {subTotal === null
                            ? 0
                            : Number(subTotal).toLocaleString("id-ID", {
                              style: "currency",
                              currency: "IDR",
                            })}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox
                      display="flex"
                      sx={{ borderBottom: "1px solid #0D6ECD" }}
                      justifyContent="space-between"
                    >
                      <MDBox>
                        <MDTypography
                          variant="overline"
                          fontWeight="regular"
                          sx={{ color: "#0D6ECD" }}
                        >
                          PPN
                        </MDTypography>
                      </MDBox>

                      <MDBox>
                        <MDTypography
                          variant="overline"
                          fontWeight="regular"
                          sx={{ color: "#0D6ECD" }}
                        >
                          {pajakExclusive === null
                            ? 0
                            : Number(pajakExclusive + pajakInclusive).toLocaleString("id-ID", {
                              style: "currency",
                              currency: "IDR",
                            })}
                        </MDTypography>
                      </MDBox>
                    </MDBox>

                    <MDBox
                      display="flex"
                      sx={{ borderBottom: "1px solid #0D6ECD" }}
                      justifyContent="space-between"
                    >
                      <MDBox>
                        <MDTypography
                          variant="overline"
                          fontWeight="regular"
                          sx={{ color: "#0D6ECD" }}
                        >
                          Total Harga Dengan PPN
                        </MDTypography>
                      </MDBox>

                      <MDBox>
                        <MDTypography
                          variant="overline"
                          fontWeight="regular"
                          sx={{ color: "#0D6ECD" }}
                        >
                          {pajakExclusive === null || pajakInclusive === null || subTotal === null
                            ? 0
                            : Number(subTotal + pajakExclusive + pajakInclusive).toLocaleString("id-ID", {
                              style: "currency",
                              currency: "IDR",
                            })}
                        </MDTypography>
                      </MDBox>
                    </MDBox>

                    <MDBox
                      display="flex"
                      sx={{ borderBottom: "1px solid #0D6ECD" }}
                      justifyContent="space-between"
                    >
                      <MDBox>
                        <MDTypography
                          variant="overline"
                          fontWeight="regular"
                          sx={{ color: "#0D6ECD" }}
                        >
                          PPh
                        </MDTypography>
                      </MDBox>

                      <MDBox>
                        <MDTypography
                          variant="overline"
                          fontWeight="regular"
                          sx={{ color: "#0D6ECD" }}
                        >
                          {pajakPphExclusive === null
                            ? 0
                            : Number(-1 * pajakPphExclusive).toLocaleString("id-ID", {
                              style: "currency",
                              currency: "IDR",
                            })}
                        </MDTypography>
                      </MDBox>
                    </MDBox>

                    <MDBox display="flex" justifyContent="space-between">
                      <MDBox>
                        <MDTypography
                          variant="overline"
                          fontWeight="bold"
                          sx={{ color: "#0D6ECD" }}
                        >
                          {/* Total Harga */}
                          Total Harga Setelah Pajak
                        </MDTypography>
                      </MDBox>
                      <MDBox>
                        <MDTypography
                          variant="overline"
                          fontWeight="bold"
                          sx={{ color: "#0D6ECD" }}
                        >
                          {total === null
                            ? 0
                            : Number(total - pajakPphExclusive).toLocaleString("id-ID", {
                              style: "currency",
                              currency: "IDR",
                            })}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>

              <Grid container justifyContent="space-between">
                <Grid item>
                  <Grid container justifyContent="space-between" direction="column">

                  </Grid>
                </Grid>
                <Grid item alignItems="center" justifyContent="center">
                  <Grid container justifyContent="center" direction="column" alignItems="center">
                    <Grid item>
                      <MDBox
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          width: "100%",
                        }}
                        mt={5}
                      >
                        <MDInput
                          id="ketTandaTangan"
                          name="ketTandaTangan"
                          label="Keterangan di Atas Tanda Tangan"
                          onChange={(e) => setTextKetTTD(e.target.value)}
                          value={textKetTTD}
                          sx={{ width: 300 }}
                          inputProps={{ min: 0, style: { textAlign: "center" } }}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item alignItems="space-between" justifyContent="center">
                      <MDBox
                        align="center"
                        mx={2}
                        my={2}
                        sx={{
                          borderStyle: "dashed",
                          borderRadius: 1,
                          borderWidth: 1,
                          height: 200,
                          width: 240,
                        }}
                      >
                        <MDButton
                          component="label"
                          sx={{ height: 190, width: 230, position: "relative", overflow: "hidden" }}
                        >
                          <img
                            hidden={signatureImage === null}
                            src={signatureImage}
                            layout="fill"
                            sx={{ width: "100%", height: "100%" }}
                          />
                          <input type="file" id="fileTTD" hidden onChange={onChangeFileSignature} />
                          <MDBox direction="column">
                            <MDTypography
                              hidden={signatureImage !== null}
                              color="text"
                              px={1}
                              sx={{ fontSize: 8 }}
                            >
                              Upload Tanda Tangan{" "}
                            </MDTypography>
                            <MDTypography
                              hidden={signatureImage !== null}
                              color="text"
                              px={1}
                              sx={{ fontSize: 8 }}
                            >
                              Maksimal ukuran 5MB PNG atau JPEG{" "}
                            </MDTypography>
                            <MDTypography
                              hidden={signatureImage !== null}
                              color="text"
                              px={1}
                              sx={{ fontSize: 8 }}
                            >
                              Rekomendasi dimensi 240x200{" "}
                            </MDTypography>
                          </MDBox>
                        </MDButton>
                      </MDBox>
                    </Grid>
                    <Grid item>
                      <MDBox
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          width: "100%",
                        }}
                      >
                        <MDInput
                          id="ketBawahTTD"
                          name="ketBawahTTD"
                          label="Keterangan di Bawah Tanda Tangan"
                          onChange={(e) => setTextKetBawah(e.target.value)}
                          value={textKetBawah}
                          sx={{ width: 300 }}
                          inputProps={{ min: 0, style: { textAlign: "center" } }}
                        />
                      </MDBox>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="flex-end"
                spacing={3}
                px={3}
                mt={3}
              >
                <Grid item>
                  <MDButton
                    variant="contained"
                    color="info"
                    mt={2}
                    size="small"
                    onClick={handleClickOpen}
                  >
                    Simpan
                  </MDButton>
                </Grid>
                <Grid item>
                  <MDButton
                    variant="contained"
                    color="error"
                    mt={2}
                    size="small"
                    component={Link}
                    to="/invoice"
                  >
                    Batal
                  </MDButton>
                </Grid>
              </Grid>
              <MDTypography color="error" py={1} px={1} sx={{ fontSize: 10 }}>
                {showError.all ? helperMsg.all : ""}{" "}
              </MDTypography>
              <MDTypography color="error" py={1} px={1} sx={{ fontSize: 10 }}>
                {showError.fail ? helperMsg.fail : ""}{" "}
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          open={openDialog}
          onClose={handleCloseCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{textDialog.header}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {textDialog.content}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAgree} autoFocus>
              Lanjut
            </Button>
            <Button onClick={handleCloseCancel}>Batal</Button>
          </DialogActions>
        </Dialog>
        <CustomerModal
          open={openCustModal}
          onClose={(e) => setOpenCustModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        />
      </form>
    </DashboardLayout>
  );
};

export default NewInvoice;
