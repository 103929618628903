export default 
(
    state={
        header: {
        number:'',
        due_date:'',
        issued_date:'',
        invoiced_company: '',
        status:'',
        total:'',
        subtotal:'',
        tax:'',
        term_of_payment:''
      } 
    }
      ,action

) => {
         switch (action.type) {
            case 'GET_INVOICE':
              return action.payload;
            case 'INIT_AN_INVOICE':
              return {
                header: {
                number:'',
                due_date:'',
                issued_date:'',
                invoiced_company: '',
                status:'',
                total:'',
                subtotal:'',
                tax:'',
                term_of_payment:''
              } 
            };
            default:
              return state;
          }
    
    };