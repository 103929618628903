import { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SimpleDashboardNavbar from "examples/Navbars/SimpleDashboardNavbar";
import { fetchCustomerDetail } from "actions/kycPeminjamAction";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { fetchRelationDocsFintech } from "actions/calonPeminjamAction";
import LVRelationDocument from "components/LVRelationDocument";
import { fetchCompanyDetail } from "actions/kycPeminjamAction";

function DetailDocsRiwayatKerjasama() {
  const dispatch = useDispatch();
  const params = useParams();

  const listRelationFintechDocs = useSelector(
    (state) => state.listRelationFintechDocs,
    shallowEqual
  );
  const companyDetail = useSelector((state) => state.anCompanyDetail, shallowEqual);
  const customerDetail = useSelector((state) => state.kycCompanyDetail, shallowEqual);

  useEffect(() => {
    const customerFintechRelationId = decodeURIComponent(params.customerFintechRelationId);
    dispatch(fetchRelationDocsFintech(customerFintechRelationId));
  }, []);

  useEffect(() => {
    const companyId = decodeURIComponent(params.id);
    if (companyId !== null) {
      dispatch(fetchCompanyDetail(companyId));
    }
  }, []);
  
  useEffect(() => {
    const customerId = decodeURIComponent(params.customerId);
    if (customerId !== null) {
      dispatch(fetchCustomerDetail(customerId));
    }
  }, []);

  return (
    <DashboardLayout>
      <SimpleDashboardNavbar name="Riwayat Kerjasama" />
      <MDBox
        mt={3}
        mb={-2}
        p={2}
        sx={{
          bgcolor: "rgba(255, 255, 255, 1)",
          borderRadius: "16px",
          border: "1px solid #e1e1e1",
          fontSize: "small",
          boxShadow: "10px 15px 27px -28px #1A73E8",
        }}
      >
        <MDBox display="flex">
          <a href={`/riwayat-kerjasama/${params.id}`}>
            <MDTypography mr={2} sx={{ fontSize: 16, fontWeight: "bold" }}>
              {companyDetail.type} {companyDetail.name}
            </MDTypography>
          </a>
          <MDTypography mr={2} sx={{ fontSize: 16, fontWeight: "bold" }}>
            {">"}
          </MDTypography>
          <MDTypography mr={2} sx={{ fontSize: 16, fontWeight: "bold" }}>
            {customerDetail.type} {customerDetail.name}
          </MDTypography>
        </MDBox>
      </MDBox>
      <LVRelationDocument rows={listRelationFintechDocs} />
    </DashboardLayout>
  );
}

export default DetailDocsRiwayatKerjasama;
